/* istanbul ignore file */

import magnifying from 'assets/icons/magnifying.svg';
import { colors } from 'assets/styled/tokens/colors';
import styled from 'styled-components';

export const StyledSearchInput = styled.input`
  background: url(${magnifying}) no-repeat 96%, ${colors.white};
  background-size: 12px;
  border: 1px solid ${colors.whiteGray};
  font-size: 14px;
  line-height: 16px;
  padding: 5px 8px;
  width: 240px;
  height: 32px;
  border-radius: 4px;
  &::placeholder {
    color: ${colors.gray};
  }
`;
