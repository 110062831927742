/* istanbul ignore file */

import styled from 'styled-components';
import { fonts, colors } from 'assets/styled/tokens';
import { ModalGeneric } from 'components/ModalGeneric';
import { StyledModalGenericBody, StyledModalGenericClose } from 'components/ModalGeneric/styled';
import { Button } from 'components/Button';
import { ReactComponent as WarningIcon } from 'assets/icons/warning-icon-2.svg';

export const StyledWarningIcon = styled(WarningIcon)`
  path {
    fill: white !important;
  }
`;
export const EmailNotVerifiedWarningContainer = styled.section`
  display: flex;
  flex-direction: column;
  width: 100%;
  grid-column: 1 / -1;
  align-items: center;
  div {
    font-family: Blinker;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    margin-top: 16px;
    margin-bottom: 16px;
    text-align: center;

    color: #ec6655;
  }
  span {
    font-size: 16px;
    line-height: 19px;
    margin-bottom: 24px;
    text-align: center;

    color: #888888;
  }
  button > svg {
    margin-right: 8px;
  }
`;
export const GoBackButton = styled(Button)`
  left: 0;
  margin-right: 24px;
  background: #ffffff;
  border: 1px solid #e5e5e5;
  border-radius: 100px;
  height: 32px;
  width: 32px;
  box-sizing: border-box;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const Header = styled.div`
  display: flex;
  flex-direction: row;
  min-width: 100%;
  align-items: center;
  margin-bottom: 34px;
`;

export const SendOcurrenceEmailSuccessModal = styled(ModalGeneric)`
  h6 {
    color: ${colors.successGreen};
    margin-bottom: 16px;
  }
  p {
    width: 80%;
    color: ${colors.gray};
    text-align: center;
    margin-bottom: 24px;
    font-size: 16px;
    line-height: 19px;
  }

  ${StyledModalGenericClose} {
    margin-right: -8px;
  }
  ${StyledModalGenericBody} button {
    font-size: 14px;
    line-height: 16px;
    width: 96px;
  }
`;
export const SenderrorEmailSuccessModal = styled(ModalGeneric)`
  h6 {
    color: #ec6655;
    margin-bottom: 16px;
  }
  p {
    width: 80%;
    color: ${colors.gray};
    text-align: center;
    margin-bottom: 24px;
    font-size: 16px;
    line-height: 19px;
  }

  ${StyledModalGenericClose} {
    margin-right: -8px;
  }
  ${StyledModalGenericBody} button {
    font-size: 14px;
    line-height: 16px;
    width: 96px;
  }
`;
export const StyledRadio = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-bottom: 8px;
  font-family: ${fonts.family.text};
  font-size: 0.75rem;

  label {
    input {
      margin-right: 0px;
      cursor: pointer;
    }
  }
`;

export const StyleDivRadio = styled.span`
  display: flex;
`;

export const StyledFormRadio = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  font-family: ${fonts.family.text};
  font-size: 0.75rem;

  label {
    margin-left: 0.9375rem;

    &:first-child {
      margin-left: 0;
    }

    input {
      margin-right: 0.3125rem;
      cursor: pointer;
    }
  }
`;

export const StyledOnlyCurrentCorrectionStatus = styled.div`
  display: flex;
  align-items: center;

  label {
    width: max-content;

    p {
      margin: 0 0.25rem;
    }
  }
`;
export const StatusInfo = styled.div`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.3px;
  text-transform: uppercase;
  color: #1e1e1e;
  margin-left: 8px;
`;
export const StatusWarningInfo = styled.div`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.3px;
  text-transform: uppercase;
  color: #c25446;
  margin-left: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;
export const RedoReportButton = styled.button`
  border: 1px solid #e5e5e5 !important;
  border-radius: 4px !important;
  height: 32px !important;
  width: 32px !important;
  color: black !important;
  padding: 0;
  margin-left: 10px;
  svg {
    height: 32px !important;
    width: 32px !important;
    path {
      fill: #1e1e1e !important;
    }
  }
`;
