import { localStorageKeys } from 'constants/localStorage';
import { getLocalAuthStorage } from './localStorage';

export const getIsRefreshing = () => {
  let storage = getLocalAuthStorage();
  return storage.isRefreshing;
};

export const settIsRefreshing = bool => {
  let storage = getLocalAuthStorage();
  storage.isRefreshing = bool;
  localStorage.setItem(localStorageKeys.authContext, JSON.stringify(storage));
};
