const adapt = httpResponse => ({
  ...httpResponse,
  data: httpResponse.data.map(empresa => ({
    id: empresa.empresa,
    name: empresa.nomeFantasia,
    logoPath: empresa.logomarcaPath,
  })),
});

const getAllCompaniesAdapter = httpResponsePromise =>
  httpResponsePromise.then(
    resolve => adapt(resolve),
    reject => reject,
  );

export { getAllCompaniesAdapter };
