import React from 'react';
import TechnicianModalMarker from '../ReactMapbox/MapModalMarker/TechnicianModalMarker';
import { getFilteredTechnicians } from 'pages/Geolocalize/utils';

const TechnicianMarkers = ({ markers, isOfflineHidden, isOnlineHidden, searchTerm }) => {
  if (markers) {
    const filteredMarkers = markers.map(marker => {
      let technicians = marker.technicians;

      if (searchTerm !== '') {
        technicians = getFilteredTechnicians({ technicians, searchTerm });
      }

      if (isOfflineHidden) {
        technicians = technicians.filter(technician => technician.status !== 'offline');
      }

      if (isOnlineHidden) {
        technicians = technicians.filter(technician => technician.status !== 'online');
      }

      if (technicians.length === 0) {
        return null;
      }

      return { ...marker, technicians: technicians };
    });

    return filteredMarkers.map((marker, index) => marker && <TechnicianModalMarker key={index} marker={marker} />);
  }

  return null;
};
export default TechnicianMarkers;
