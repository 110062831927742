import styled from 'styled-components';

import chevronRight from 'assets/icons/chevron-right.svg';
import { colors } from 'assets/styled/tokens';

export const StyledBreacrumb = styled.div`
  padding: 16px 0;
`;

export const StyledBreacrumbList = styled.ul`
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: flex-start;

  li {
    position: relative;

    &:before {
      position: absolute;
      top: 50%;
      left: 16px;
      width: 8px;
      height: 8px;
      background: url(${chevronRight}) no-repeat center/contain;
      transform: translate(-50%, -50%);
    }

    & + li {
      padding-left: 32px;

      &:before {
        display: block;
      }
    }

    &:last-child {
      a {
        text-decoration: none;
        pointer-events: none;
      }
    }
  }

  a {
    display: block;
    font-weight: 500;
    color: ${colors.gray};
    text-decoration: underline;
    font-size: 0.75rem;
    line-height: 1rem;

    &.home {
      width: 16px;
      height: 16px;
      font-size: 0;
      color: transparent;
      text-indent: -9999px;

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }
`;
