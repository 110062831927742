const { css } = require('styled-components');

const medium = () => css`
  padding: 9px 12px;
  font-size: 12px;
  line-height: 14px;
  border: 1px solid invisible;
  border-radius: 4px;
`;

export { medium };
