import { DefaultSelect } from 'assets/styled/sheets/components/Forms';
import { colors } from 'assets/styled/tokens';
import styled from 'styled-components';

export const StyledPaginationControlsContainer = styled.div`
  display: flex;
  margin-left: auto;
  align-items: center;
  white-space: nowrap;
`;
export const StyledPaginationButton = styled.button`
  border: 1px solid ${colors.whiteGray};
  border-radius: 4px;
  width: 24px;
  margin: 0 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 24px;
  &:hover {
    background: ${colors.whiteGray};
  }
`;
export const StyledItemsPerPageSelect = styled.select`
  ${DefaultSelect};
  width: auto;
  margin-left: 8px;
  padding: 0 8px;
  padding-right: 19px;
  color: ${colors.gray};
  background-position: right 7px center;
  margin-right: 24px;
  &:active,
  :focus {
    border-color: ${colors.whiteGray};
  }
`;
export const StyledItemsPerPageContainer = styled.div`
  display: flex;
  margin-left: 21px;
  color: ${colors.carbon};
  font-weight: 500;
  font-size: 12px;
`;
export const StyledITotalItemsContainer = styled.div`
  color: ${colors.gray};
  font-weight: 400;
  font-size: 12px;
`;
