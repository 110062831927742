import styled from 'styled-components';

export const ImageUploadContainer = styled.div`
  position: relative;
  max-width: 468px;
  width: 288px;
  height: 110px;
  border: ${props => (props.borderDashed ? '2px dashed #cccccc' : '2px solid #cccccc')};
  border-radius: 3px;
  padding: 20px;
  text-align: center;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const Wrapper = styled.div`
  margin: 0 10px;
`;
export const InputLabel = styled.div`
  font-family: Roboto;
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  text-align: left;
  color: #1e1e1e;
`;
export const Input = styled.input`
  display: none;
`;

export const Label = styled.label`
  font-family: Roboto;
  position: absolute;
  color: #444444;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
  font-weight: bold;
  display: block;
  width: 100%;
  font-size: 13px;
`;

export const SubLabel = styled.span`
  font-weight: normal;
  display: block;
  cursor: pointer;
  font-size: 12px;
  color: #888888;
`;

export const PreviewImage = styled.img`
  width: 292px;
  height: 106px;
  display: ${({ previewUrl }) => (previewUrl ? 'block' : 'none')};
`;
export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
  max-width: 468px;
`;

export const ButtonTrash = styled.button`
  background-color: #fff;
  color: white;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  margin-left: 5px;
  border-radius: 3px;
`;
export const Button = styled.button`
  background-color: #f38a00;
  color: white;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  margin-left: 5px;
  border-radius: 3px;
`;
