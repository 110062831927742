const adapt = httpResponse => {
  return {
    ...httpResponse,
  };
};

const forgotAdapter = httpResponsePromise =>
  httpResponsePromise
    .then(
      resolve => adapt(resolve),
      reject => adapt(reject),
    )
    .catch(err => {
      return err;
    });

export { forgotAdapter };
