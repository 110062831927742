import React, { useState, useEffect, useRef } from 'react';

import { StyledRadio } from './styled';
import InputTooltip from '../InputTooltip';

const Radio = React.forwardRef((props, ref) => {
  const node = useRef();
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);
  const [isMounted, setIsMounted] = useState(false);
  const handleClickOutside = e => {
    if (node.current?.contains(e.target)) {
      // inside click
      return;
    }
    // outside click
    setIsTooltipVisible(false);
  };
  useEffect(() => {
    setIsMounted(true);
    if (isTooltipVisible && isMounted) {
      document.addEventListener('mousedown', handleClickOutside);

      setTimeout(() => setIsTooltipVisible(false), 3000);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      setIsMounted(false);
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [props.disabled, isTooltipVisible, isMounted]);
  return (
    <StyledRadio
      disabled={props.disabled}
      className={props.className}
      onClick={() => setIsTooltipVisible(prevState => !prevState)}
    >
      {isMounted && (
        <InputTooltip
          isVisible={props.disabled && isTooltipVisible}
          message={'Confirme seu e-mail para habilitar.'}
          radio
        />
      )}
      {props.text}
      <input
        {...props}
        data-cy={props.id}
        type="radio"
        disabled={props.disabled}
        ref={ref}
        className=" "
        value={props.value || ''}
      />

      <span />
    </StyledRadio>
  );
});

export { Radio };
