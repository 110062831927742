import React, { useContext, useState } from 'react';
import { GlobalComponentsContext } from 'context/GlobalComponentsContext';
import { Loading } from 'components/Loading';
import { PageWrapper } from 'components/PageWrapper';
//import { Menu } from 'components/Menu';
import { MobileNavbar } from 'components/MobileNavbar';
import { HeaderFiltersPage } from 'containers/HeaderFiltersPage';
import { colors } from 'assets/styled/tokens';
import { Header, HeaderName, SearchBar, OldReportsButton, HeaderRigth, ReportsPageContainer } from './styled';
import { ReportBox } from 'components/ReportBox';
import activitiesReportPreview from 'assets/images/activities-report-preview.png';
import ocurrenceReportPreview from 'assets/images/ocurrences-report-preview.png';
import pmocReportPreview from 'assets/images/pmoc-preview.png';
import mbtfReportPreview from 'assets/images/mbtf-preview.png';
import qrCodesReportPreview from 'assets/images/qr-codes-report-preview.png';
import calendarPreview from 'assets/images/calendarPreview.png';
import { URLS } from 'constants/urls';
import { NewMenu } from 'components/Menu/NewMenu';

//import { FiltersContext } from 'context/GlobalFiltersContext';

const ReportsPage = ({ pathname }) => {
  const { globalComponentsContext } = useContext(GlobalComponentsContext);
  const [search, setSearch] = useState('');
  const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);
  const { isLoading } = globalComponentsContext;

  ///const { headerFiltersContext } = useContext(FiltersContext);
  //const { headerFilters } = headerFiltersContext;
  const reportsAll = [
    {
      title: 'Calendário de Atividades',
      description: `Apresenta o calendário mensal, destacando as datas de programação e execução das atividades agendadas.`,
      knowMoreLink:
        'https://leankeep.movidesk.com/kb/pt-br/article/461903/relatorio-calendario-de-atividades?ticketId=&q=',
      reportPreview: calendarPreview,
      reportLink: '/relatorios/calendario-atividades',
      newReport: true,
    },
    {
      title: 'QR Codes',
      description: `Relatório para impressão de etiquetas QR codes de equipamentos cadastrados na sua empresa.`,
      knowMoreLink: 'https://leankeep.movidesk.com/kb/pt-br/article/399117/relatorio-de-qr-code?ticketId=&q=',
      reportPreview: qrCodesReportPreview,
      reportLink: '/relatorios/qr-codes',
      newReport: true,
    },
    {
      title: 'Atividades',
      description: `Com dados completos e detalhados sobre a programação e execução de atividades. O PDF é ideal para análise visual e o Excel permite o manuseio.`,
      knowMoreLink: 'https://leankeep.movidesk.com/kb/pt-br/article/376042/relatorio-de-atividades?ticketId=&q=',
      reportPreview: activitiesReportPreview,
      reportLink: '/relatorios/atividades',
      newReport: true,
    },
    {
      title: 'Ocorrências e Correções',
      description: `Com dados de ocorrências e suas correções, o PDF é ideal para análise visual, com fotos e assinaturas e o Excel permite o manuseio.`,
      knowMoreLink: `https://leankeep.movidesk.com/kb/article/306358/relatorio-de-ocorrencias-e-correcoes?
                     ticketId=&q=relat%C3%B3rio%20de%20ocorr%C3%AAncias`,
      reportPreview: ocurrenceReportPreview,
      reportLink: '/relatorios/ocorrencia',
      newReport: false,
    },
    {
      title: 'PMOC',
      description: `Relatório com dados de atividades e ativos, em conformidade com as exigências da ABNT e da Lei Federal 13.589/18.`,
      knowMoreLink: 'https://leankeep.movidesk.com/kb/article/97096/como-gerar-o-pmoc-no-leankeep?ticketId=&q=pmoc',
      reportPreview: pmocReportPreview,
      reportLink: '/relatorios/pmoc',
      newReport: false,
    },
    {
      title: 'MTBF, MTTR e Disponibilidade',
      description: `Relatório em Excel com indicadores de performance de equipamentos, com base nos dados informados em ocorrências.`,
      knowMoreLink:
        'https://leankeep.movidesk.com/kb/article/233762/relatorio-mtbf-mttr-e-disponibilidade?ticketId=&q=mtbf',
      reportPreview: mbtfReportPreview,
      reportLink: '/relatorios/disponibilidade',
      newReport: false,
    },
  ];
  const goToOldReportsPage = e => {
    e.preventDefault();
    window.open(URLS.oldReports);
  };
  const handleSearch = e => {
    setSearch(e.target.value);
  };
  const reportsFiltered =
    search !== ''
      ? reportsAll.filter(report => report.title.toLocaleLowerCase().includes(search.toLowerCase()))
      : reportsAll;
  return (
    <>
      {isLoading && <Loading />}
      <PageWrapper background={colors.pampas}>
        <MobileNavbar />
        {/* <Menu /> */}
        <NewMenu setIsSubMenuOpen={setIsSubMenuOpen} />
        <HeaderFiltersPage location={pathname} isSubMenuOpen={isSubMenuOpen}>
          <Header>
            <HeaderName>Relatórios</HeaderName>
            <HeaderRigth>
              <SearchBar placeholder={'Buscar relatório'} value={search} onChange={handleSearch} />
              <OldReportsButton onClick={e => goToOldReportsPage(e)}>
                <svg width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M2.1875 9.23077H0.4375C0.191406 9.23077 0 9.46154 0 9.69231V11.5385C0 11.7981 0.191406 12 0.4375 12H2.1875C2.40625 12 2.625 11.7981 2.625 11.5385V9.69231C2.625 9.46154 2.40625 9.23077 2.1875 9.23077ZM2.1875 0H0.4375C0.191406 0 0 0.230769 0 0.461538V2.30769C0 2.56731 0.191406 2.76923 0.4375 2.76923H2.1875C2.40625 2.76923 2.625 2.56731 2.625 2.30769V0.461538C2.625 0.230769 2.40625 0 2.1875 0ZM2.1875 4.61539H0.4375C0.191406 4.61539 0 4.84615 0 5.07692V6.92308C0 7.18269 0.191406 7.38462 0.4375 7.38462H2.1875C2.40625 7.38462 2.625 7.18269 2.625 6.92308V5.07692C2.625 4.84615 2.40625 4.61539 2.1875 4.61539ZM13.5625 9.69231H4.8125C4.56641 9.69231 4.375 9.92308 4.375 10.1538V11.0769C4.375 11.3365 4.56641 11.5385 4.8125 11.5385H13.5625C13.7812 11.5385 14 11.3365 14 11.0769V10.1538C14 9.92308 13.7812 9.69231 13.5625 9.69231ZM13.5625 0.461538H4.8125C4.56641 0.461538 4.375 0.692308 4.375 0.923077V1.84615C4.375 2.10577 4.56641 2.30769 4.8125 2.30769H13.5625C13.7812 2.30769 14 2.10577 14 1.84615V0.923077C14 0.692308 13.7812 0.461538 13.5625 0.461538ZM13.5625 5.07692H4.8125C4.56641 5.07692 4.375 5.30769 4.375 5.53846V6.46154C4.375 6.72115 4.56641 6.92308 4.8125 6.92308H13.5625C13.7812 6.92308 14 6.72115 14 6.46154V5.53846C14 5.30769 13.7812 5.07692 13.5625 5.07692Z"
                    fill="#f38a00"
                  />
                </svg>
                VER RELATÓRIOS ANTIGOS
              </OldReportsButton>
            </HeaderRigth>
          </Header>
          <ReportsPageContainer>
            {reportsFiltered.map(report => (
              <ReportBox
                key={report.title}
                title={report.title}
                description={report.description}
                knowMoreLink={report.knowMoreLink}
                reportPreview={report.reportPreview}
                reportLink={report.reportLink}
                newReport={report.newReport}
              />
            ))}
          </ReportsPageContainer>
        </HeaderFiltersPage>
      </PageWrapper>
    </>
  );
};

export { ReportsPage };
