import styled from 'styled-components';

import { DefaultLabel } from 'assets/styled/sheets/components/Forms';
import { colors } from 'assets/styled/tokens';

export const StyledSwitchWrapper = styled.div`
  width: ${props => props.hasMaxContent && 'max-content'};

  padding: 0.288rem 0.625rem;
  cursor: pointer;
`;

export const StyledLabel = styled.label`
  ${DefaultLabel}
  display: flex;
  align-items: center;
  cursor: pointer;
  color: #888888;
`;

export const StyledInput = styled.input``;

export const StyledSwitch = styled.div`
  position: relative;
  display: inline-block;
  width: 1.25rem;
  height: 0.75rem;
  cursor: pointer;
  margin-left: ${props => props.hasMaxContent && '0.5rem'};
  margin-right: 5px;

  span {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 100px;
    background-color: ${colors.lightGray};
    transition: background-color ease-in-out 210ms;

    &:before {
      position: absolute;
      top: 0.125rem;
      left: 0.125rem;
      display: block;
      width: 0.5rem;
      height: 0.5rem;
      border-radius: 50%;
      background-color: white;
      transition: transform ease-in-out 210ms;
    }
  }

  input {
    top: 0;
    left: 0;
    width: 0;
    height: 0;
    opacity: 0;

    &:checked {
      & + span {
        background-color: ${colors.primary};

        &:before {
          transform: translateX(8px);
        }
      }
    }
  }
`;
