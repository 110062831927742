/* istanbul ignore file */

import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { colors, devices, fonts } from 'assets/styled/tokens';
import { Button } from 'components/Button';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  @media ${devices.tabletLandscape} {
    transform: translateY(-60px);
  }
`;

export const StyledImg = styled.img`
  display: flex;
  width: 105px;
  height: 98px;
  margin-top: auto;
  margin-bottom: 5px;
  align-content: center;
`;

export const StyledError = styled.div`
  font-family: ${fonts.family.title};
  font-weight: 800;
  font-size: 21px;
  font-style: normal;
  line-height: 24px;
  color: ${colors.bittersweet};
`;

export const StyledErrorMessage = styled.div`
  margin-top: 25px;
  font-family: ${fonts.family.title};
  font-weight: 700;
  font-style: normal;
  font-size: 24px;
  line-height: 29.26px;
  text-align: center;
  color: ${colors.tundora};
`;

export const StyledErrorMessage419 = styled.div`
  margin-top: 25px;
  font-family: ${fonts.family.title};
  font-weight: 700;
  font-style: normal;
  font-size: 24px;
  line-height: 29.26px;
  text-align: center;
  color: ${colors.bittersweet};
`;

export const StyledErrorMessageSub = styled.div`
  width: 325px;
  margin-top: 7px;
  margin-bottom: 46px;
  font-family: ${fonts.family.text};
  font-weight: 400;
  font-style: normal;
  font-size: 18px;
  line-height: 21.09px;
  text-align: center;
  color: ${colors.gray};

  @media screen and (min-width: 768px) {
    width: 525px;
  }
`;

export const StyledLink = styled.a`
  text-decoration: none;
  color: ${colors.white};
`;

export const StyledLinkIn = styled(Link)`
  text-decoration: none;
  color: ${colors.white};
`;

export const StyledButtonRow = styled.div`
  margin-bottom: auto;
`;

export const StyledRefreshPageButton = styled(Button)`
  border-color: ${colors.lightGray};
  background-color: transparent;
  color: ${colors.tundora};

  margin-right: 15px;
`;
