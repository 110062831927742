import React, { useState, useEffect, createContext, useContext } from 'react';

import { localStorageKeys } from 'constants/localStorage';

import { initialState } from './initialState';
import { useCookies } from 'react-cookie';
import { URLS } from 'constants/urls';

const GlobalFiltersContext = createContext(initialState);
GlobalFiltersContext.displayName = 'GlobalFiltersContext';

const FiltersProvider = ({ children }) => {
  const [state, setState] = useState(initialState);
  const [, setCookie, removeCookie] = useCookies();

  useEffect(() => {
    localStorage.setItem(localStorageKeys.headerFiltersContext, JSON.stringify(state));
    let activeHeaderFilters = state.headerFilters;

    Object.keys(activeHeaderFilters).forEach(filterName => {
      if (!activeHeaderFilters[filterName]) {
        removeCookie(filterName, { path: '/', domain: URLS.cookieDomain });
      } else {
        setCookie(
          filterName,
          { id: String(activeHeaderFilters[filterName].id), name: '' },
          {
            path: '/',
            domain: URLS.cookieDomain,
          },
        );
      }
    });
  }, [removeCookie, setCookie, state]);

  return (
    <GlobalFiltersContext.Provider
      value={{
        headerFiltersContext: state,
        setFiltersContext: setState,
      }}
    >
      {children}
    </GlobalFiltersContext.Provider>
  );
};

export const useHeaderFilters = () => useContext(GlobalFiltersContext);

export { GlobalFiltersContext as FiltersContext, FiltersProvider as HeaderFiltersProvider, initialState };
