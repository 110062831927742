import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { previewFactory } from './previewFactory';

import { StyledPreview, StyledPreviewTitle, StyledPreviewDescription, StyledPreviewContainer } from './styled';

const Preview = props => {
  const { reportName, reportType, params } = props;

  const [previewListSize, setPreviewListSize] = useState(0);

  useEffect(() => {
    const currentSize = previewFactory({
      reportName,
      reportType,
      params,
    })?.length;

    setPreviewListSize(() => currentSize);
  }, [params, previewListSize, reportName, reportType]);

  return (
    <StyledPreview>
      <StyledPreviewTitle>Pré-visualização do layout</StyledPreviewTitle>
      <StyledPreviewDescription>
        Veja a estrutura do relatório de acordo com os filtros aplicados:
      </StyledPreviewDescription>
      <StyledPreviewContainer size={previewListSize}>
        {previewFactory({ reportName, reportType, params })?.map((thumbnail, index) => (
          <img key={`preview-${index}`} src={thumbnail} alt="Pré-visualização" />
        ))}
      </StyledPreviewContainer>
    </StyledPreview>
  );
};

Preview.propTypes = {
  reportName: PropTypes.string,
  reportType: PropTypes.string,
  params: PropTypes.object,
};

export { Preview };
