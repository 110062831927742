import { AxiosReports, AxiosGlobalFilters } from '../config';
import qs from 'qs';

const FiltersService = {
  getActivityResponsibles: ({ companyId, unitId }) => {
    if (companyId && unitId) {
      return AxiosGlobalFilters({
        method: 'get',
        url: `/v1/responsaveistecnicos`,
        params: {
          unidadeId: unitId,
        },
        headers: {
          EmpresaId: companyId,
          UnidadeId: unitId,
        },
      });
    }
  },
  getActivityResponsiblesV2: ({ companyId, unitIds, unitGroupIds, unitSubgroupIds }) => {
    return AxiosReports({
      method: 'post',
      url: `/filters/${companyId}/activity-responsibles`,
      data: {
        unitIds,
        unitGroupIds,
        unitSubgroupIds,
      },
    });
  },
  getResponsibleARTCertificateDocuments: ({ companyId, unitId }) => {
    let ART_TYPE = 34;
    if (companyId && unitId) {
      return AxiosGlobalFilters({
        method: 'get',
        url: `/v1/documentos`,
        params: {
          unidadeId: unitId,
          tipoDocumentoId: ART_TYPE,
        },
        headers: {
          EmpresaId: companyId,
          UnidadeId: unitId,
        },
      });
    }
  },
  getResponsibleTRTCertificateDocuments: ({ companyId, unitId }) => {
    let TRT_TYPE = 35;
    if (companyId && unitId) {
      return AxiosGlobalFilters({
        method: 'get',
        url: `/v1/documentos`,
        params: {
          unidadeId: unitId,
          tipoDocumentoId: TRT_TYPE,
        },
        headers: {
          EmpresaId: companyId,
          UnidadeId: unitId,
        },
      });
    }
  },
  getDocuments: ({ companyId, unitId }) => {
    if (companyId && unitId) {
      return AxiosGlobalFilters({
        method: 'get',
        url: `/v1/documentos`,
        params: {
          unidadeId: unitId,
        },
        headers: {
          EmpresaId: companyId,
          UnidadeId: unitId,
        },
      });
    }
  },
  getSystems: ({ companyId, unitIds, unitGroupIds, unitSubgroupIds }) => {
    if (companyId) {
      return AxiosGlobalFilters({
        method: 'get',
        url: `/V1/sistemas`,
        params: {
          unidadeId: unitIds,
          GrupoUnidadeId: unitGroupIds,
          SubGrupoUnidadeId: unitSubgroupIds,
        },
        headers: {
          empresaId: companyId,
        },
      });
    }
  },
  getPMOCActivitiesPlans: ({ companyId, unitId, systemIds }) => {
    return AxiosGlobalFilters({
      method: 'get',
      url: `/v1/planosatividades/ativos`,
      params: {
        empresaId: companyId,
        sistemasIds: systemIds,
        unidadeId: unitId,
      },
      paramsSerializer: params => {
        return qs.stringify(params, { arrayFormat: 'repeat' });
      },
    });
  },
  getPMOCActivitiesPlansCount: ({ companyId, unitId = undefined }) => {
    return AxiosGlobalFilters({
      method: 'get',
      url: `/v1/planosatividades/ativos`,
      params: {
        empresaId: companyId,
        unidadeId: unitId,
      },
    });
  },
  getAuditActivities: ({ companyId, unitIds, unitGroupIds, unitSubgroupIds }) => {
    if (companyId) {
      return AxiosReports({
        method: 'post',
        url: `/filters/${companyId}/audit-activities`,
        data: {
          unitIds,
          unitGroupIds,
          unitSubgroupIds,
        },
      });
    }
  },
  getOcurrenceStatus: ({ companyId }) => {
    if (companyId) {
      return AxiosReports({
        method: 'post',
        url: `/filters/${companyId}/occurrence-status`,
      });
    }
  },
  getStates: ({ companyId, unitGroupIds, unitSubgroupIds }) => {
    if (companyId) {
      return AxiosReports({
        method: 'post',
        url: `/filters/${companyId}/states`,
        data: {
          unitGroupIds,
          unitSubgroupIds,
        },
      });
    }
  },
  getOcurrenceNumbers: ({ companyId }) => {
    if (companyId) {
      return AxiosReports({
        method: 'post',
        url: `/filters/${companyId}/occurrence-numbers`,
      });
    }
  },
  getCorrectionStatus: ({ companyId, occurrenceStatusIds }) => {
    if (companyId) {
      return AxiosReports({
        method: 'post',
        url: `/filters/${companyId}/correction-status`,
        data: {
          occurrenceStatusIds,
        },
      });
    }
  },
  getUnitGroups: ({ companyId }) => {
    if (companyId) {
      return AxiosGlobalFilters({
        // method: 'post',
        // url: `/filters/${companyId}/unit-groups`,
        // data: {
        //   ids,
        // },
        method: 'get',
        url: '/v1/gruposUnidades/ativos',
        headers: {
          empresaId: companyId,
        },
      });
    }
  },
  getUnitSubgroups: ({ companyId, unitGroupIds }) => {
    let grupoUnidade;
    if (typeof unitGroupIds === 'number') {
      grupoUnidade = unitGroupIds;
    } else if (typeof unitGroupIds === 'object') {
      grupoUnidade = unitGroupIds[0];
    }
    if (companyId) {
      return AxiosGlobalFilters({
        // method: 'post',
        // url: `/filters/${companyId}/unit-subgroups`,
        // data: {
        //   ids,
        //   unitGroupIds,
        // },
        method: 'get',
        url: '/v1/subgruposunidades/ativos',
        params: {
          GrupoUnidadeId: grupoUnidade,
          EmpresaId: companyId,
        },
        headers: {
          EmpresaId: companyId,
        },
      });
    }
  },
  getUnits: ({ companyId, unitSubgroupIds, stateIds, unitGroupIds }) => {
    let subGrupo;
    let grupoUnidade;
    if (typeof unitGroupIds === 'number') {
      grupoUnidade = unitGroupIds;
    } else if (typeof unitGroupIds === 'object') {
      grupoUnidade = unitGroupIds[0];
    }
    if (typeof unitSubgroupIds === 'number') {
      subGrupo = unitSubgroupIds;
    } else if (typeof unitSubgroupIds === 'object') {
      subGrupo = unitSubgroupIds[0];
    } else {
      subGrupo = null;
    }
    if (companyId) {
      return AxiosGlobalFilters({
        // method: 'post',
        // url: `/filters/${companyId}/units`,
        // data: {
        //   ids,
        //   unitGroupIds,
        //   unitSubgroupIds,
        //   stateIds,
        // },
        method: 'get',
        url: '/v1/unidades/ativas',
        params: {
          grupoUnidadeId: grupoUnidade,
          empresaId: companyId,
          subGrupoUnidadeId: subGrupo,
          estadoId: stateIds,
        },
        headers: {
          EmpresaId: companyId,
        },
      });
    }
  },
  getOccurrenceStatus: ({ companyId }) => {
    if (companyId) {
      return AxiosReports({
        method: 'post',
        url: `/filters/${companyId}/occurrence-status`,
      });
    }
  },
  getAreas: ({ companyId, ids, unitIds, providerIds, areaGroupIds, areaSubgroupIds }) => {
    if (companyId) {
      return AxiosReports({
        method: 'post',
        url: `/filters/${companyId}/areas`,
        data: {
          ids,
          unitIds,
          providerIds,
          areaGroupIds,
          areaSubgroupIds,
        },
      });
    }
  },
  getAreaGroups: ({ companyId, unitIds, providerIds, areaIds, areaSubgroupIds }) => {
    if (companyId) {
      return AxiosReports({
        method: 'post',
        url: `/filters/${companyId}/area-groups`,
        data: { unitIds, providerIds, areaIds, areaSubgroupIds },
      });
    }
  },
  getAreaSubgroups: ({ companyId, unitIds, providerIds, areaIds, areaGroupIds }) => {
    if (companyId) {
      return AxiosReports({
        method: 'post',
        url: `/filters/${companyId}/area-subgroups`,
        data: {
          unitIds,
          providerIds,
          areaIds,
          areaGroupIds,
        },
      });
    }
  },
  getEquipmentTypes: ({ companyId }) => {
    if (companyId) {
      return AxiosReports({
        method: 'post',
        url: `/filters/${companyId}/equipment-types`,
      });
    }
  },
  getEquipmentTypesAvailibility: ({ EmpresaId, UnidadeId }) => {
    return AxiosGlobalFilters({
      method: 'get',
      url: `/v1/tiposequipamentos`,
      headers: {
        EmpresaId,
        UnidadeId,
      },
    });
  },
  getEquipmentGroup: ({ empresaId, unidadeId }) => {
    return AxiosGlobalFilters({
      method: 'get',
      url: `/v1/gruposEquipamentos/ativos?empresaId=${empresaId}&unidadeId=${unidadeId}`,
    });
  },
  getEquipments: ({
    companyId,
    unitIds,
    providerIds,
    areaGroupIds,
    areaSubgroupIds,
    areaIds,
    equipmentTypeIds,
    equipamentGroupId,
    companySystemsId,
  }) => {
    if (companyId) {
      return AxiosReports({
        method: 'post',
        url: `/filters/${companyId}/equipments`,
        data: {
          unitIds,
          providerIds,
          areaGroupIds,
          areaSubgroupIds,
          areaIds,
          equipmentTypeIds,
          equipamentGroupId,
          companySystemsId,
        },
      });
    }
  },

  getSlas: ({ companyId, unitIds }) => {
    if (companyId) {
      return AxiosReports({
        method: 'post',
        url: `/filters/${companyId}/slas`,
        data: {
          unitIds,
        },
      });
    }
  },
  getPriorities: ({ companyId, unitIds }) => {
    if (companyId) {
      return AxiosReports({
        method: 'post',
        url: `/filters/${companyId}/priorities`,
        data: {
          unitIds,
        },
      });
    }
  },
  getDepartments: ({ companyId, unitIds }) => {
    if (companyId) {
      return AxiosReports({
        method: 'post',
        url: `/filters/${companyId}/departments`,
        data: {
          unitIds,
        },
      });
    }
  },
  getCompanyDepartments: ({ companyId }) => {
    if (companyId) {
      return AxiosGlobalFilters({
        method: 'get',
        url: `/v1/setores`,
        headers: {
          EmpresaId: companyId,
        },
      });
    }
  },
  getMaterials: ({ companyId }) => {
    if (companyId) {
      return AxiosGlobalFilters({
        method: 'get',
        url: `/v1/materiais`,
        headers: {
          EmpresaId: companyId,
        },
      });
    }
  },
  getCategories: ({ companyId }) => {
    if (companyId) {
      return AxiosGlobalFilters({
        method: 'get',
        url: `/v1/categorias`,
        headers: {
          EmpresaId: companyId,
        },
      });
    }
  },
  getMeasurementUnits: ({ companyId }) => {
    if (companyId) {
      return AxiosGlobalFilters({
        method: 'get',
        url: `/v1/UnidadesMedicoes`,
        headers: {
          EmpresaId: companyId,
        },
      });
    }
  },
  getMeasurementUnit: ({ companyId, id }) => {
    if (companyId) {
      return AxiosGlobalFilters({
        method: 'get',
        url: `/v1/UnidadesMedicoes?UnidadeMedicao=${id}`,
        headers: {
          EmpresaId: companyId,
        },
      });
    }
  },
  getProviders: ({ companyId, unitIds }) => {
    if (companyId) {
      return AxiosReports({
        method: 'post',
        url: `/filters/${companyId}/providers`,
        data: {
          unitIds: [unitIds],
        },
      });
    }
  },
  getUsers: ({ companyId, unitIds, providerIds }) => {
    if (companyId) {
      return AxiosReports({
        method: 'post',
        url: `/filters/${companyId}/users`,
        data: {
          unitIds,
          providerIds,
        },
      });
    }
  },
};

export { FiltersService };
