import styled from 'styled-components';
import { colors } from 'assets/styled/tokens';

export const StyledSubCategory = styled.ul`
  background-color: ${colors.pampas};
  position: absolute;
`;
export const StyledSubCategoryTitle = styled.h2`
  font-family: Roboto;
  font-size: 14px;
  font-weight: 700;
  text-align: center;
  color: #1e1e1e;
  margin-bottom: 25px;
`;
export const CloseArrowContainer = styled.div`
  width: 100%;
  padding: 15px;
  div {
    border: 1px solid #e5e5e5;
    border-radius: 100%;
    width: 24px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    :hover {
      cursor: pointer;
    }
  }
`;
