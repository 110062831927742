import { ReactComponent as ExcelIcon } from 'assets/icons/excel-file.svg';
import { ReactComponent as AttentionBlueIcon } from './assets/attention-blue-icon.svg';
import * as Sentry from '@sentry/react';
import { colors } from 'assets/styled/tokens';
import { Select } from 'components/Form/subcomponents/Select';
import { Loading } from 'components/Loading';
//import { Menu } from 'components/Menu';
import { MobileNavbar } from 'components/MobileNavbar';
import { PageWrapper } from 'components/PageWrapper';
import { Toast } from 'components/Toast';
import { GlobalComponentsContext } from 'context/GlobalComponentsContext';
import { FiltersContext } from 'context/GlobalFiltersContext';
import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useFilterFields } from 'utils/hooks/useFilterFields';
import { HeaderFiltersPage } from 'containers/HeaderFiltersPage';
import MultiSelect from 'components/Form/subcomponents/MultiSelect';
import { createToast } from 'utils/createToast';
import {
  UPDATE_MARKERS,
  TECHNICIANS_TAB,
  TOGGLE_ROUTES_REPORT_MODAL,
  TOGGLE_UNIT_MARKERS_FILTERS_MODAL,
  UPDATE_SIDEBAR_ROUTE_SUBVIEW_TECHNICIAN,
  UPDATE_UNIT_MARKERS_FILTER,
  UPDATE_UNIT_MARKERS_FILTER_FIELDS,
  HIDE_TEAM_DETAILS,
} from './constants';
import { useMarkers } from 'pages/Geolocalize/queries';
import { useGeolocalizeStore } from './store';

import {
  PageContainer,
  StyledClearUnitsFilterButton,
  StyledDateRangePicker,
  StyledDownloadRoutesReportExcelButton,
  StyledFilterUnitsButton,
  StyledHorizontalLine,
  StyledMapContainer,
  StyledRoutesReportModal,
  StyledRoutesReportModalFormGroup,
  StyledStateCityRow,
  StyledUnitFiltersButtonRow,
  StyledUnitFiltersModal,
  StyledUnitFiltersModalFormGroup,
  StyledRouteReportFeedbackModal,
  ReportLimitationContainer,
  LimitationsHeader,
  LimitationsList,
  ErrorText,
  //StyledRoutesReportModalTitleSuccess,
  //StyledRoutesReportModalTitleError,
} from './styled';
import { Button } from 'components/Button';
import FiltersSidebar from './subcomponents/FiltersSidebar';
import ReactMapbox from './subcomponents/ReactMapbox';
import TabsHeader from './subcomponents/TabsHeader';
import UnitMarkers from './subcomponents/UnitMarkers';
import TechnicianMarkers from './subcomponents/TechnicianMarkers';
import { handleUnitsFiltersClear } from './utils';
import { getLocalAuthStorage } from 'utils/localStorage';
import { GeolocalizeService } from 'services/reports';
import { NewMenu } from 'components/Menu/NewMenu';

// *reference: https://www.leighhalliday.com/mapbox-clustering

// * THE FOLLOWING MARKER MOCKS MUST HAVE SAME STRUCTURE

const Geolocalize = props => {
  const companyId = props.companyId || '4157';
  const authStorage = getLocalAuthStorage();
  const { token } = authStorage;
  const [state, dispatch] = useGeolocalizeStore();
  const { globalComponentsContext } = useContext(GlobalComponentsContext);
  const { headerFiltersContext } = useContext(FiltersContext);
  const { headerFilters } = headerFiltersContext;
  const { isLoading: isGlobalLoading } = globalComponentsContext;
  const [activeTab, setActiveTab] = useState(undefined);
  const [routeReportFeedbackModalOpen, setRouteReportFeedbackModalOpen] = useState(false);
  const [toastList, setToastList] = useState([]);
  const mmddyyyy = new Date(Date.now()).toLocaleString().split(',')[0];
  const initialDateValue = mmddyyyy + ' - ' + mmddyyyy;
  const initialSelectionRange = {
    startDate: new Date(),
    endDate: new Date(),
    key: 'selection',
  };
  // TODO finish  selection range picker functionality
  const [selectionRange] = useState(initialSelectionRange);
  const [reportModalForm, setReportModalForm] = useState({
    technicians: '',
    initialDate: mmddyyyy,
    endDate: mmddyyyy,
  });

  const {
    status: markersStatus,
    data: markersData,
    error: markersError,
    isFetching: markersIsFetching,
  } = useMarkers(companyId, headerFilters?.units?.id, token);

  useEffect(() => {
    dispatch({
      type: UPDATE_MARKERS,
      payload: {
        data: markersData,
        error: markersError,
        status: markersStatus,
        isFetching: markersIsFetching,
      },
    });
  }, [dispatch, markersData, markersError, markersStatus, markersIsFetching]);

  const handleTabChange = (e, tabType) => {
    e.preventDefault();
    if (tabType !== TECHNICIANS_TAB) {
      dispatch({
        type: UPDATE_SIDEBAR_ROUTE_SUBVIEW_TECHNICIAN,
        payload: undefined,
      });
    }

    dispatch({ type: HIDE_TEAM_DETAILS });
    setActiveTab(tabType);
  };

  // TODO
  const handleDateRangeChange = range => {
    const splittedDate = range.split('/');

    setReportModalForm({
      ...reportModalForm,
      initialDate: splittedDate[0],
      endDate: splittedDate[1],
    });
  };

  const handleTechniciansListForReports = techniciansMarkers => {
    if (!techniciansMarkers) return;

    let fieldOptions = [];

    techniciansMarkers.forEach(technicianMarker => {
      technicianMarker.technicians.length > 0 &&
        technicianMarker.technicians.forEach(technician => {
          fieldOptions.push({
            label: technician.name,
            value: technician.id,
          });
        });
    });

    return fieldOptions;
  };

  const handleUpdateFields = fieldsData => {
    dispatch({
      type: UPDATE_UNIT_MARKERS_FILTER_FIELDS,
      payload: {
        ...state.unitMarkersFiltersFields,
        ...fieldsData,
      },
    });
  };

  useFilterFields({
    handleUpdateFields,
    companyId,
    unitId: state.unitMarkersFilters.unitId,
    unitGroupId: state.unitMarkersFilters.unitGroupId,
    unitSubgroupId: state.unitMarkersFilters.unitSubgroupId,
    stateId: state.unitMarkersFilters.stateId,
  });

  // Initialize map effect
  const isUnitClustersActive = useMemo(() => {
    return state.unitMarkers?.data && state.viewport.zoom < 10;
  }, [state]);
  const isTechniciansActive = useMemo(() => {
    return !isUnitClustersActive;
  }, [isUnitClustersActive]);

  useEffect(() => {
    if (state.sidebarRouteSubviewTechnician !== undefined && !activeTab) {
      setActiveTab(TECHNICIANS_TAB);
    }
  }, [activeTab, state.sidebarRouteSubviewTechnician]);
  const [tecInputError, setTecInputError] = useState(false);
  const [dateInputError, setDateInputError] = useState(false);
  const checkFilterValues = ({ tecnicos, dataInicio, dataFim }) => {
    const novaDataInicio = new Date(dataInicio);
    const novaDataFim = new Date(dataFim);
    const totalDeDias = Math.floor((novaDataFim - novaDataInicio) / (24 * 60 * 60 * 1000));
    const dataAtual = new Date();
    const maisDe60Dias = Math.floor((dataAtual - novaDataInicio) / (24 * 60 * 60 * 1000));
    if (tecnicos.length > 10 && (totalDeDias > 31 || maisDe60Dias > 60)) {
      setTecInputError(true);
      setDateInputError(true);
      return false;
    }
    if (tecnicos.length > 10) {
      setTecInputError(true);
      return false;
    }
    if (totalDeDias > 31 || maisDe60Dias > 60) {
      setDateInputError(true);
      return false;
    }
    return true;
  };
  const handleReportDownload = async () => {
    try {
      setTecInputError(false);
      setDateInputError(false);
      const validInputs = checkFilterValues({
        tecnicos: reportModalForm.technicians,
        dataInicio: reportModalForm.initialDate,
        dataFim: reportModalForm.endDate,
      });
      if (!validInputs) {
        return;
      }
      setToastList(prevState => [
        ...prevState,
        createToast({
          type: 'loading',
          message: 'Gerando arquivo...',
        }),
      ]);
      const res = await GeolocalizeService.getRouteReport({
        tecnicos: reportModalForm.technicians.map(tec => Number(tec)),
        empresa: companyId,
        dataInicio: reportModalForm.initialDate,
        dataFim: reportModalForm.endDate,
        token,
      });
      const { data } = res;
      if (!data) {
        return setToastList(() => [
          createToast({
            type: 'error',
            message: 'Não há dados para os filtros selecionados ',
          }),
        ]);
      }
      const blob = new Blob([data], {
        type: 'application/json',
      });

      const downloadLink = document.createElement('a');
      downloadLink.href = URL.createObjectURL(blob);
      downloadLink.download = `relatorio-de-rotas-${new Date().getTime()}.xlsx`;
      downloadLink.click();
      Sentry.captureMessage('Relatório Disponibilidade excel baixado');

      setToastList(prevState => [
        ...prevState,
        createToast({
          type: 'success',
          message: 'Seu relatório foi exportado!',
        }),
      ]);
    } catch (e) {
      setToastList(() => [
        createToast({
          type: 'error',
          message: 'Não há dados para os filtros selecionados ',
        }),
      ]);
    }
  };

  const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);
  return (
    <>
      {isGlobalLoading && <Loading />}
      <PageWrapper background={colors.pampas}>
        <MobileNavbar />
        {/* <Menu /> */}
        <NewMenu setIsSubMenuOpen={setIsSubMenuOpen} />
        <HeaderFiltersPage noMargin isSubMenuOpen={isSubMenuOpen}>
          <PageContainer>
            <TabsHeader isCollapsed={!activeTab} activeTab={activeTab} handleTabChange={handleTabChange} />
            <FiltersSidebar activeTab={activeTab} setToastList={setToastList} handleTabChange={handleTabChange} />
            <StyledMapContainer isFullscreen={!activeTab}>
              {state.unitMarkers?.data && (
                <ReactMapbox
                  isUnitClustersActive={isUnitClustersActive}
                  unitMarkersData={state.filteredMarkers ? state.filteredMarkers : state.unitMarkers.data}
                >
                  {!isUnitClustersActive && (
                    <UnitMarkers
                      markers={state.filteredMarkers ? state.filteredMarkers : state.unitMarkers.data}
                      searchTerm={state.unitMarkersFilters.unitSearchTerm}
                    />
                  )}

                  {isTechniciansActive && markersData?.technicianMarkers && (
                    <TechnicianMarkers
                      isOfflineHidden={state.technicianMarkers.isOfflineHidden}
                      isOnlineHidden={state.technicianMarkers.isOnlineHidden}
                      searchTerm={state.technicianMarkers.searchTerm}
                      markers={markersData.technicianMarkers}
                    />
                  )}
                </ReactMapbox>
              )}
            </StyledMapContainer>
            <StyledRoutesReportModal
              style={{
                width: '360px',
                height: 'auto',
                justifyContent: 'flex-start',
              }}
              open={state.isDownloadRouteReportsModalActive}
              hasHeader
              title="Relatório de rotas"
              closeModal={() => dispatch({ type: TOGGLE_ROUTES_REPORT_MODAL })}
            >
              <StyledHorizontalLine />
              <ReportLimitationContainer>
                <LimitationsHeader>
                  <AttentionBlueIcon /> Limitações do relatório:
                </LimitationsHeader>
                <ul>
                  <LimitationsList>Seleção de até 10 técnicos</LimitationsList>
                  <LimitationsList>Data/Período de até 31 dias</LimitationsList>
                  <LimitationsList>Os dados disponíveis são de até 60 dias atrás</LimitationsList>
                </ul>
              </ReportLimitationContainer>
              <StyledRoutesReportModalFormGroup label="Técnico(s)" isRequired>
                {/* <Input
                  type="text"
                  autoComplete="off"
                  name="search"
                  size="medium"
                  value={reportModalForm.technicians}
                  placeholder="Pesquisar"
                  onChange={e =>
                    setReportModalForm({
                      ...reportModalForm,
                      technicians: e.target.value,
                    })
                  }
                /> */}

                <MultiSelect
                  size="small"
                  placeholder="Selecione"
                  options={handleTechniciansListForReports(markersData?.technicianMarkers)}
                  onChange={value => {
                    setReportModalForm({
                      ...reportModalForm,
                      technicians: value,
                    });
                  }}
                  error={tecInputError}
                />
                {tecInputError && (
                  <ErrorText>O número de técnicos selecionados excede as limitações do relatório</ErrorText>
                )}
              </StyledRoutesReportModalFormGroup>
              <StyledRoutesReportModalFormGroup label="Data/Período" isRequired>
                <StyledDateRangePicker
                  onChange={handleDateRangeChange}
                  placeholder={initialDateValue}
                  ref={useRef()}
                  ranges={[selectionRange]}
                  required={dateInputError}
                />
                {dateInputError && (
                  <ErrorText>O período selecionado excede as limitações do relatório (listadas acima).</ErrorText>
                )}
              </StyledRoutesReportModalFormGroup>

              <StyledDownloadRoutesReportExcelButton onClick={handleReportDownload}>
                <ExcelIcon />
                Exportar relatório em Excell
              </StyledDownloadRoutesReportExcelButton>
            </StyledRoutesReportModal>

            <StyledRouteReportFeedbackModal
              open={routeReportFeedbackModalOpen}
              style={{
                width: '554px',
                maxWidth: 'none',
              }}
              closeModal={() => setRouteReportFeedbackModalOpen(false)}
            >
              {/* {routeReportFeedbackType === 'success' && (
                <>
                  <EmailSuccessIcon />
                  <StyledRoutesReportModalTitleSuccess>
                    Enviaremos seu relatório por e-mail
                  </StyledRoutesReportModalTitleSuccess>
                  <p style={{ color: '#888888', textAlign: 'center', marginBottom: '24px' }}>
                    Estamos gerando o seu relatório e isso pode demorar um pouco. Assim que estiver pronto, enviaremos
                    para o e-mail cadastrado em sua conta. Se não o encontrar, verifique também sua caixa de spam.
                  </p>
                </>
              )}

              {routeReportFeedbackType === 'error' && (
                <>
                  <EmailErrorIcon />
                  <StyledRoutesReportModalTitleError>
                    Para exportar o relatório é necessário ter um e-mail válido cadastrado no seu perfil.
                  </StyledRoutesReportModalTitleError>
                  <p style={{ color: '#888888', textAlign: 'center', marginBottom: '24px' }}>
                    Se for preciso, peça a ajuda de um administrador ou fale conosco.
                  </p>
                </>
              )} */}
              <Button onClick={() => setRouteReportFeedbackModalOpen(false)}>Entendi</Button>
            </StyledRouteReportFeedbackModal>
            <StyledUnitFiltersModal
              open={state.isUnitsFiltersModalActive}
              hasHeader
              title="Filtros"
              closeModal={() => dispatch({ type: TOGGLE_UNIT_MARKERS_FILTERS_MODAL })}
            >
              <StyledHorizontalLine />
              <StyledUnitFiltersModalFormGroup label="Grupo de unidades">
                <Select
                  size="medium"
                  placeholder="Seleção opcional"
                  options={state.unitMarkersFiltersFields.unitGroups}
                  value={state.unitMarkersFilters.unitGroupId}
                  onChange={value => {
                    dispatch({ type: UPDATE_UNIT_MARKERS_FILTER, payload: { name: 'unitGroupId', value: value } });
                  }}
                />
              </StyledUnitFiltersModalFormGroup>
              <StyledUnitFiltersModalFormGroup label="Subgrupo de unidades">
                <Select
                  size="medium"
                  placeholder="Seleção opcional"
                  options={state.unitMarkersFiltersFields.unitSubgroups}
                  value={state.unitMarkersFilters.unitSubgroupId}
                  onChange={value => {
                    dispatch({ type: UPDATE_UNIT_MARKERS_FILTER, payload: { name: 'unitSubgroupId', value: value } });
                  }}
                />
              </StyledUnitFiltersModalFormGroup>
              <StyledStateCityRow>
                <StyledUnitFiltersModalFormGroup label="Estado">
                  <Select
                    size="medium"
                    placeholder="Seleção opcional"
                    options={state.unitMarkersFiltersFields.states}
                    value={state.unitMarkersFilters.stateId}
                    onChange={value => {
                      dispatch({ type: UPDATE_UNIT_MARKERS_FILTER, payload: { name: 'stateId', value: value } });
                    }}
                  />
                </StyledUnitFiltersModalFormGroup>
                <StyledUnitFiltersModalFormGroup label="Cidade">
                  <Select
                    size="medium"
                    placeholder="Seleção opcional"
                    options={state.unitMarkersFiltersFields.cities}
                    value={state.unitMarkersFilters.cityId}
                    onChange={value => {
                      dispatch({ type: UPDATE_UNIT_MARKERS_FILTER, payload: { name: 'cityId', value: value } });
                    }}
                  />
                </StyledUnitFiltersModalFormGroup>
              </StyledStateCityRow>

              <StyledUnitFiltersModalFormGroup label="Unidade(s) de manutenção">
                <Select
                  size="medium"
                  placeholder="Seleção opcional"
                  options={state.unitMarkersFiltersFields.units}
                  value={state.unitMarkersFilters.unitId}
                  onChange={value => {
                    dispatch({ type: UPDATE_UNIT_MARKERS_FILTER, payload: { name: 'unitId', value: value } });
                  }}
                />
              </StyledUnitFiltersModalFormGroup>
              <StyledUnitFiltersButtonRow>
                <StyledClearUnitsFilterButton theme="outline" onClick={e => handleUnitsFiltersClear(e, dispatch)}>
                  Limpar filtros
                </StyledClearUnitsFilterButton>
                <StyledFilterUnitsButton theme="primary">Filtrar unidades</StyledFilterUnitsButton>
              </StyledUnitFiltersButtonRow>
            </StyledUnitFiltersModal>
          </PageContainer>
        </HeaderFiltersPage>
      </PageWrapper>
      <Toast toastList={toastList} autoDelete dismissTime={5000} />
    </>
  );
};

export { Geolocalize };
