import React from 'react';

import { StyledTextarea, StyledError, StyledIcon } from './styled';

const Textarea = React.forwardRef(({ size = 'small', icon, 'data-cy': dataCy, error, errormessage, ...props }, ref) => {
  return (
    <>
      <StyledTextarea
        {...props}
        error={error}
        data-cy={dataCy}
        ref={ref}
        size={size}
        disabled={props.disabled}
        value={props.value || ''}
        spellcheck="false"
      />
      {icon && <StyledIcon src={icon} inputSize={size} />}

      <StyledError>
        <p>{error && errormessage}</p>
      </StyledError>
    </>
  );
});

export { Textarea };
