import React from 'react';
import { StyledLoading } from './styled';

import leankeepLogo from 'assets/icons/leankeep/leankeep-facility-logo.svg';

export const Loading = () => (
  <StyledLoading>
    <img src={leankeepLogo} alt="Leankeep" />
  </StyledLoading>
);
