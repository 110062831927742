import { AxiosGlobalFilters } from '../config';
import { getAllCompaniesAdapter } from 'adapters/companies/';

const CompanyService = {
  getAll: () =>
    getAllCompaniesAdapter(
      AxiosGlobalFilters({
        method: 'get',
        url: `/v1/empresas/ativas`,
      }),
    ),
};

export { CompanyService };
