import { colors } from 'assets/styled/tokens';
import { defaultTransition } from 'assets/styled/tokens/transitions';
import styled from 'styled-components';

export const StyledInputTooltip = styled.div`
  position: relative;

  span {
    font-size: 12px;
    line-height: 14px;
    text-align: center;
    border: 1px solid ${colors.carbon};
    background: ${colors.carbon} !important;
    border-radius: 0.1875rem !important;
    color: #fff;
    position: absolute !important;
    //top: -2.3rem;
    top: ${props => (props.radio ? '-1rem' : '-2.3rem')} !important;
    margin: auto;
    margin-left: ${props => (props.radio ? '-4rem' : 'auto')};
    left: 0;
    right: 0;
    padding: 0.5rem;
    z-index: 1;
    width: 230px !important;
    height: 1.85rem !important;
    //width: fit-content;
    visibility: ${props => (props.isVisible ? 'visible' : 'hidden')};
    opacity: ${props => (props.isVisible ? 1 : 0)};
    ${defaultTransition};
    &:before {
      content: '';
      display: block;
      position: absolute !important;
      bottom: -0.1875rem;
      width: 0.625rem;
      height: 0.625rem;
      //width: fit-content;
      //height: fit-content;
      border-bottom: 0.1875rem solid transparent;
      border-right: 0.1875rem solid transparent;
      background: ${colors.carbon};
      left: 45%;
      transform: rotate(45deg);
    }
  }
`;
