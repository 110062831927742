import { colors } from 'assets/styled/tokens';
import styled from 'styled-components';

export const StyledTextarea = styled.div`
  width: ${props => props.width};
  p {
    font-family: Roboto;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: #888888;
    margin-bottom: 5px;
    b {
      color: #1e1e1e;
    }
    div {
      display: inline;
      color: ${colors.primary};
    }
  }
`;
export const TextAreaInput = styled.textarea`
  height: ${props => props.height};
  width: ${props => props.width};
  border-color: ${props => (props.errorColor ? '#C25446' : '#e5e5e5')};
  border-radius: 4px;
  resize: none;
  font-family: Roboto;
  padding: 7px;
  color: #888888;
`;
