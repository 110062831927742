import React, { useState } from 'react';

import {
  StyledChartContainer,
  StyledHeader,
  StyledContainerHeader,
  StyledContentWrapper,
  StyledTitle,
  TabItem,
  TabItemBorder,
  StyledMain,
  StyledContainerMain,
} from './styled';

export const ChartContainerTabs = props => {
  const { activeTab, tabs } = props;
  const [active, setActive] = useState(activeTab);
  return (
    <StyledChartContainer>
      <StyledHeader>
        <StyledContainerHeader>
          <StyledContentWrapper justifyContent="flex-start" alignItems="center" gridColumnStart={2}>
            <StyledTitle>
              {tabs.map(item => (
                <TabItem key={item.id} active={active === item.id} onClick={() => setActive(item.id)}>
                  {item.title}
                  <TabItemBorder active={active === item.id} />
                </TabItem>
              ))}
            </StyledTitle>
          </StyledContentWrapper>
        </StyledContainerHeader>
      </StyledHeader>
      <StyledMain>
        <StyledContainerMain>{tabs[active].content}</StyledContainerMain>
      </StyledMain>
    </StyledChartContainer>
  );
};
