import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Controller } from 'react-hook-form';

import { colors } from 'assets/styled/tokens';
import { ReactComponent as CalendarIcon } from 'assets/icons/calendar.svg';
import { ReactComponent as ToastWarningIcon } from 'assets/icons/toast-warning-icon.svg';

import { FiltersService } from 'services/reports';

import { Radio } from 'components/Form/subcomponents/Radio';
import GeneralPlanApplicationsTable from 'components/Tables/GeneralPlanApplication';
import ApplicationTableUnitCell from 'components/Tables/GeneralPlanApplication/ApplicationTableUnitCell';
import ApplicationTableActionCell from 'components/Tables/GeneralPlanApplication/ApplicationTableActionCell';
import ApplicationTableExecutedByProviderCell from 'components/Tables/GeneralPlanApplication/ApplicationTableExecutedByProviderCell';
import ApplicationTableExecutedByProviderHeader from 'components/Tables/GeneralPlanApplication/ApplicationTableExecutedByProviderHeader';
import {
  StyledExecutedByProviderHeader,
  StyledRadioGroup,
  StyledUnitsMultiSelect,
  StyledAreaTypesSelect,
  ApplyToAreaEquipTypeHeader,
  ApplicationFormUnitSubHeader,
  FormApplicationStep1ButtonRow,
  AdvanceApplicationStep1Button,
  StyledApplicationForm,
  StyledReturnFormStepButton,
  SchedulePlanButton,
  StyledRadio,
  StyledEquipmentTypeSelect,
  StyledAddApplicationButton,
  StyledAddUnitsButton,
  StyledAddUnitsMultiSelect,
  StyledFormGroup,
  StyledAddUnitsModalHeader,
  StyledAddUnitsModalHeaderUnderline,
  StyledAddUnitsModal,
  StyledRightArrow,
  StyledClearProvidersModalButton,
  StyledClearProvidersModalButtons,
  StyledModalCancelButton,
  StyledClearProvidersModalParagraph,
} from './styled';
import { ApplicationFormUnitSubHeader as EditApplicationFormUnitSubHeader } from '../EditGeneralPlanPage/styled';
import { GeneralPlansService } from 'services/reports/endpoints/GeneralPlansService';
import { createErrorToast, createGenericErrorToast, createSuccessToast, createTrashToast } from 'utils/createToast';
import { ModalGeneric } from 'components/ModalGeneric';

function FormApplicationStep({
  companyId,
  setToastList,
  planId,
  createToast,
  setIsSchedulePlanSuccess,
  getValues,
  setValue,
  register,
  control,
  returnFormStep,
  isActive,
  errors,
  setGlobalComponentsContext,
}) {
  const [isExecutedByProvider, setIsExecutedByProvider] = useState(false);
  const [isClearProvidersModalActive, setIsClearProvidersModalActive] = useState(false);
  const [applicationType, setApplicationType] = useState(false);
  // eslint-disable-nextLine
  const [isStep1, setIsStep1] = useState(true);
  const [applicationsTablePageIndex, setApplicationsTablePageIndex] = useState(0);
  const [applicationsTableTotalCount, setApplicationsTableTotalCount] = useState(0);
  const [applicationsTablePageSize, setApplicationsTablePageSize] = useState(5);
  const [applicationsTablePageCount, setApplicationsTablePageCount] = useState(0);
  const [isStep1FormDirty, setIsStep1FormDirty] = useState(false);
  const [isAddApplicationModalActive, setIsAddApplicationModalActive] = useState(false);
  const [isAddUnitsModalFormDirty, setIsAddUnitsModalFormDirty] = useState(false);
  const [isApplicationFormDirty, setIsApplicationFormDirty] = useState(false);
  const [deleteUnitId, setDeleteUnitId] = useState(undefined);
  // eslint-disable-next-line
  const [sortBy, setSortBy] = useState(false);
  const [applicationsPaginatedTableData, setApplicationsPaginatedTableData] = useState([]);
  const [fields, setFields] = useState({
    units: [],
    providers: [],
  });
  const handleClearProviders = async () => {
    const pageSize = 100;
    let isPaginationResultsFull = false,
      activePage = 1;
    let applications = [];
    try {
      while (isPaginationResultsFull === false) {
        let { data } = await GeneralPlansService.getSites({
          companyId,
          planId,
          pageIndex: activePage,
          pageSize: pageSize,
        });
        applications = applications.concat(data);
        if (data.length < pageSize) {
          isPaginationResultsFull = true;
        } else {
          activePage += 1;
        }
      }
      applications.forEach(async application => {
        let applicationId = application.site;
        await GeneralPlansService.updateSitesProvider({
          companyId: companyId,
          planId: planId,
          applicationId: applicationId,
          provider: null,
        });
      });
      setIsExecutedByProvider(false);
    } catch (e) {
      createGenericErrorToast(setToastList);
    } finally {
      setIsClearProvidersModalActive(false);
    }
  };

  const handleUpdateTableData = useCallback(async () => {
    return await Promise.all([
      GeneralPlansService.getSites({
        companyId,
        planId,
        pageIndex: applicationsTablePageIndex + 1,
        pageSize: applicationsTablePageSize,
      }),
      GeneralPlansService.getSitesCount({
        companyId,
        planId,
      }),
    ]).then(values => {
      let formattedApplicationsData = values[0].data.map(application => {
        return {
          unit: { id: application.site, label: application.siteNome },
          executedByProvider: {
            unitId: application.site,
            id: application.fornecedor,
            label: application.fornecedorNome,
            planId: planId,
            companyId: companyId,
          },
          edit: { id: application.site },
        };
      });

      setApplicationsTableTotalCount(values[1].data);

      setApplicationsPaginatedTableData(formattedApplicationsData);
      return values[0].data.map(application => application.site);
    });
  }, [applicationsTablePageIndex, applicationsTablePageSize, companyId, planId]);

  const handleRemoveApplication = useCallback(
    id => {
      GeneralPlansService.deleteSite({
        planId,
        companyId,
        applicationId: id,
      })
        .then(() => {
          handleUpdateTableData();
          createTrashToast(setToastList, 'Unidade de manutenção excluída.');
        })
        .catch(() => {
          createGenericErrorToast(setToastList);
        })
        .finally(() => {
          setDeleteUnitId(undefined);
        });
    },
    [companyId, planId, setToastList, handleUpdateTableData],
  );
  const handleAdvanceStep1 = () => {
    setIsStep1FormDirty(true);
    if (!getValues('units') || getValues('units').length === 0) {
      setToastList(prevState => [
        ...prevState,
        createToast({
          type: 'error',
          message: 'Há campos obrigatórios não preenchidos.',
        }),
      ]);
    } else {
      handleUpdateTableData();
      setIsStep1(false);
    }
  };

  const columns = useMemo(
    () => [
      {
        Header: 'Unidade de manutenção',
        accessor: 'unit',
        sortType: 'alphanumeric',
        disableSortBy: true,
        Cell: props => <ApplicationTableUnitCell {...props} />,
      },
      {
        Header: props => (
          <ApplicationTableExecutedByProviderHeader
            setIsExecutedByProvider={setIsExecutedByProvider}
            isExecutedByProvider={isExecutedByProvider}
            setIsClearProvidersModalActive={setIsClearProvidersModalActive}
            {...props}
          />
        ),
        accessor: 'executedByProvider',
        disableSortBy: true,

        Cell: props => (
          <ApplicationTableExecutedByProviderCell
            isExecutedByProvider={isExecutedByProvider}
            setIsExecutedByProvider={setIsExecutedByProvider}
            setToastList={setToastList}
            companyId={companyId}
            {...props}
          />
        ),
      },

      {
        Header: 'Ação',
        accessor: 'edit',
        disableSortBy: true,
        Cell: props => (
          <ApplicationTableActionCell deleteUnitId={deleteUnitId} setDeleteUnitId={setDeleteUnitId} {...props} />
        ),
      },
    ],
    [isExecutedByProvider, setToastList, companyId, deleteUnitId],
  );

  const populateFieldsStrategy = useCallback(async () => {
    if (companyId && planId) {
      return await Promise.all([
        FiltersService.getUnits({
          companyId,
        }),
        FiltersService.getEquipmentTypes({
          companyId,
        }),
        GeneralPlansService.getApplicationType({ companyId, planId }),
      ]).then(values => ({
        units: values[0].data.map(({ nome, site }) => ({
          label: nome,
          value: site,
        })),

        equipmentTypes: values[1].data.map(({ name, id }) => ({
          label: name,
          value: id,
        })),
        applicationType: values[2].data,
      }));
    }
  }, [companyId, planId]);

  const populateFields = useCallback(async () => {
    try {
      const fieldsData = await populateFieldsStrategy();
      if (fieldsData.applicationType.isSite) {
        setApplicationType(false);
      } else if (fieldsData.applicationType.tipoArea) {
        setApplicationType('area');
        setValue('areaType', fieldsData.applicationType.tipoArea);
      } else if (fieldsData.applicationType.linhaProdutoEmpresa) {
        setApplicationType('equipment');
        setValue('equipmentType', fieldsData.applicationType.linhaProdutoEmpresa);
      }
      setFields(prevState => ({
        ...prevState,
        ...fieldsData,
      }));
    } catch (err) {
      console.error(err);
      createGenericErrorToast(setToastList);
    }
  }, [populateFieldsStrategy, setToastList, setValue]);

  const fetchData = useCallback(async () => {
    setGlobalComponentsContext(prevState => ({
      ...prevState,
      isLoading: true,
    }));
    // await cleanFields();
    await populateFields();
    setGlobalComponentsContext(prevState => ({
      ...prevState,
      isLoading: false,
    }));
  }, [populateFields, setGlobalComponentsContext]);

  const handleSchedulePlan = async () => {
    setIsApplicationFormDirty(true);
    let invalidFieldsCount = 0;
    if (applicationType === 'area' && !getValues('areaType')) {
      invalidFieldsCount++;
    } else if (applicationType === 'equipment' && !getValues('equipmentType')) {
      invalidFieldsCount++;
    }
    if (isExecutedByProvider) {
      let invalidSites = await getInvalidSites();
      invalidFieldsCount += invalidSites ? invalidSites.length : 0;
    }
    if (invalidFieldsCount === 0) {
      // pending backend
      setGlobalComponentsContext(prevState => ({
        ...prevState,
        isLoading: true,
      }));
      try {
        const { data } = await GeneralPlansService.schedulePlan({
          planId,
          companyId,
        });

        if (data === 'Plano gerado e enviado para fila de agendamento.') {
          setIsSchedulePlanSuccess(true);
        } else {
          throw new Error(data);
        }
      } catch (e) {
        console.error(e);
        createGenericErrorToast(setToastList);
      } finally {
        setGlobalComponentsContext(prevState => ({
          ...prevState,
          isLoading: false,
        }));
      }
    } else {
      createErrorToast(
        setToastList,
        invalidFieldsCount === 1
          ? 'Um campo obrigatório não foi preenchido.'
          : 'Há campos obrigatórios não preenchidos.',
      );
    }
  };

  const getInvalidSites = async () => {
    try {
      const res = await GeneralPlansService.validateSites({
        planId,
        companyId,
      });
      return res.data.data;
    } catch (e) {
      createGenericErrorToast(setToastList);
      return e;
    }
  };
  const handleAddApplications = () => {
    setIsAddUnitsModalFormDirty(true);
    let isFormValid = false;

    if (getValues('addUnits')?.length > 0) {
      isFormValid = true;
    }

    if (isFormValid)
      GeneralPlansService.updateSites({
        companyId: companyId,
        applications: getValues('addUnits').map(id => {
          return { site: parseInt(id) };
        }),
        planId: planId,
      })
        .then(() => {
          handleUpdateTableData();
          createSuccessToast(setToastList, 'Unidade(s) adicionada(s).');

          setIsAddApplicationModalActive(false);
        })
        .catch(() => {
          // add error function
          createGenericErrorToast(setToastList);
        })
        .finally(() => {
          setIsAddUnitsModalFormDirty(false);
        });
  };
  useEffect(() => {
    if (isActive) {
      fetchData();
    }
  }, [errors, fetchData, isActive]);
  const handleUpdateApplicationType = useCallback(
    (type, value) => {
      GeneralPlansService.updateApplicationType({
        companyId,
        planId,
        areaType: type === 'areaType' ? value : null,
        equipmentType: type === 'equipmentType' ? value : null,
      });
    },
    [companyId, planId],
  );

  useEffect(() => {
    if (deleteUnitId) {
      handleRemoveApplication(deleteUnitId);
    }
  }, [deleteUnitId, handleRemoveApplication]);

  useEffect(() => {
    if (isActive && !isStep1) {
      handleUpdateTableData().then(res => {
        if (res.length > 0) {
          setValue('units', res);
        }
      });
    }
    if (!isExecutedByProvider) {
    }
    if (applicationsPaginatedTableData.length > 0) {
      setIsStep1(false);
    }
  }, [
    applicationsPaginatedTableData.length,
    applicationsTablePageIndex,
    applicationsTablePageSize,
    isExecutedByProvider,
    companyId,
    isStep1,
    setValue,
    planId,
    isActive,
    handleUpdateTableData,
  ]);

  return (
    <StyledApplicationForm isStep1={isStep1} isActive={isActive}>
      {isStep1 ? (
        <>
          <ApplicationFormUnitSubHeader>
            Unidades de manutenção
            <span>(onde as atividades vão ser realizadas)</span>
          </ApplicationFormUnitSubHeader>

          <Controller
            render={props => (
              <StyledUnitsMultiSelect
                isStep1={isStep1}
                {...props}
                error={isStep1FormDirty && !getValues('units')?.length > 0}
                errormessage="Campo obrigatório"
                size="small"
                placeholder="Seleção obrigatória"
                options={fields?.units}
                onChange={value => {
                  props.onChange(value);
                  GeneralPlansService.updateSites({
                    companyId: companyId,
                    applications: value.map(id => {
                      return { site: parseInt(id) };
                    }),
                    planId: planId,
                  });
                }}
              />
            )}
            name={`units`}
            control={control}
            rules={{ required: true }}
          />
        </>
      ) : (
        <EditApplicationFormUnitSubHeader>
          Unidades de manutenção
          <StyledAddApplicationButton size="small" onClick={() => setIsAddApplicationModalActive(true)} type="submit">
            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M1 6H11" stroke="white" strokeWidth="2" strokeLinecap="round" />
              <path d="M6 1V11" stroke="white" strokeWidth="2" strokeLinecap="round" />
            </svg>
            Adicionar unidades
          </StyledAddApplicationButton>
        </EditApplicationFormUnitSubHeader>
      )}

      {isStep1 ? (
        <>
          <StyledExecutedByProviderHeader>
            <h4>Executado por fornecedor?</h4>
            <span>
              <StyledRadio
                text="Sim"
                name={`executedByProvider`}
                onChange={() => {
                  setIsExecutedByProvider(true);
                }}
                value="yes"
                checked={isExecutedByProvider}
                // onClick={(e) => setIsSupplierActive(true)}
              />
              <StyledRadio
                text="Não"
                type="radio"
                name={`executedByProvider`}
                onChange={() => {
                  setIsExecutedByProvider(false);
                }}
                checked={!isExecutedByProvider}
                value="no"
              />
            </span>
          </StyledExecutedByProviderHeader>
        </>
      ) : (
        <>
          {applicationsPaginatedTableData?.length > 0 && (
            <GeneralPlanApplicationsTable
              columns={columns}
              totalCount={applicationsTableTotalCount}
              pageSize={applicationsTablePageSize}
              setSortBy={setSortBy}
              pageIndex={applicationsTablePageIndex}
              pageCount={applicationsTablePageCount}
              setPageCount={setApplicationsTablePageCount}
              setPageSize={setApplicationsTablePageSize}
              setPageIndex={setApplicationsTablePageIndex}
              isApplicationFormDirty={isApplicationFormDirty}
              data={applicationsPaginatedTableData}
              setToastList={setToastList}
            />
          )}
          <ApplyToAreaEquipTypeHeader>
            Aplicar também a um tipo de Área ou Equipamento?
            <StyledRadioGroup>
              <Radio
                text="Não"
                name="applicationType"
                type="radio"
                value="none"
                ref={register()}
                onChange={() => {}}
                onClick={() => {
                  handleUpdateApplicationType();
                  setValue('areaType', null);
                  setValue('equipmentType', null);
                  setApplicationType(false);
                }}
                checked={applicationType === false}
              />
              <Radio
                text="Tipo de Área"
                name="applicationType"
                type="radio"
                value="areaType"
                onChange={() => setApplicationType('area')}
                checked={applicationType === 'area'}
                ref={register()}
              />
              <Radio
                text="Tipo de Equipamento"
                type="radio"
                name="applicationType"
                value="equipmentTypes"
                onChange={() => setApplicationType('equipment')}
                checked={applicationType === 'equipment'}
                ref={register()}
              />
              {applicationType === 'area' && (
                <>
                  <StyledRightArrow
                    width="7"
                    height="12"
                    viewBox="0 0 7 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1 1L6 6L1 11"
                      stroke="#BBBBBB"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </StyledRightArrow>

                  <Controller
                    render={props => (
                      <StyledAreaTypesSelect
                        {...props}
                        size="small"
                        isRequired
                        placeholder="Selecione um tipo de área"
                        headers={{ EmpresaId: companyId }}
                        name="areaType"
                        error={isApplicationFormDirty && !getValues('areaType')}
                        // params={{ planoGeral: 3 }}
                        value={getValues('areaType') || fields?.applicationType?.tipoArea}
                        onSelect={({ value }) => {
                          handleUpdateApplicationType('areaType', value);
                        }}
                        adapter={data => {
                          return data.map(areaType => {
                            return {
                              label: areaType.nome,
                              value: areaType.tipoArea,
                            };
                          });
                        }}
                        url="/v1/tiposareas"
                      />
                    )}
                    name="areaType"
                    control={control}
                  />
                </>
              )}
              {applicationType === 'equipment' && (
                <>
                  <StyledRightArrow
                    width="7"
                    height="12"
                    viewBox="0 0 7 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1 1L6 6L1 11"
                      stroke="#BBBBBB"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </StyledRightArrow>

                  <Controller
                    render={props => (
                      <StyledEquipmentTypeSelect
                        {...props}
                        name="equipmentType"
                        size="small"
                        isRequired
                        placeholder="Selecione um tipo de equipamento"
                        error={isApplicationFormDirty && !getValues('equipmentType')}
                        options={fields?.equipmentTypes}
                        value={getValues('equipmentType') || fields?.applicationType?.linhaProdutoEmpresa}
                        onSelect={({ value }) => {
                          handleUpdateApplicationType('equipmentType', value);
                        }}
                      />
                    )}
                    name="equipmentType"
                    control={control}
                  />
                </>
              )}
            </StyledRadioGroup>
          </ApplyToAreaEquipTypeHeader>
        </>
      )}
      <FormApplicationStep1ButtonRow>
        <StyledReturnFormStepButton onClick={() => returnFormStep()} form="newPlan" type="submit">
          <svg width="12" height="10" viewBox="0 0 12 10" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11 5H1" stroke={colors.carbon} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            <path
              d="M5 1L1 5L5 9"
              stroke={colors.carbon}
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          Voltar
        </StyledReturnFormStepButton>
        {isStep1 ? (
          <AdvanceApplicationStep1Button onClick={() => handleAdvanceStep1()} form="newPlan" type="submit">
            Avançar
            <svg width="12" height="10" viewBox="0 0 12 10" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M1 5H11" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
              <path d="M7 1L11 5L7 9" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
          </AdvanceApplicationStep1Button>
        ) : (
          <SchedulePlanButton onClick={() => handleSchedulePlan()}>
            <CalendarIcon />
            Agendar plano
          </SchedulePlanButton>
        )}
      </FormApplicationStep1ButtonRow>
      <StyledAddUnitsModal
        open={isAddApplicationModalActive}
        closeModal={() => setIsAddApplicationModalActive(false)}
        size={{ maxWidth: '521px' }}
      >
        <StyledAddUnitsModalHeader>Adicionar Unidades de Manutenção</StyledAddUnitsModalHeader>
        <StyledAddUnitsModalHeaderUnderline />
        <StyledFormGroup label="Unidades de Manutenção " isRequired>
          <Controller
            render={props => (
              <StyledAddUnitsMultiSelect
                {...props}
                error={isAddUnitsModalFormDirty && !getValues('addUnits')?.length > 0}
                errormessage="Campo obrigatório"
                isRequired
                size="small"
                placeholder="Seleção obrigatória"
                options={fields?.units}
              />
            )}
            name={`addUnits`}
            control={control}
            rules={{ required: true }}
          />
        </StyledFormGroup>
        <StyledAddUnitsButton onClick={() => handleAddApplications()}>
          <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="6" cy="6" r="6" fill="white" />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M5.53651 8.81249C5.28206 9.06246 4.85747 9.06246 4.60302 8.81249L2.19023 6.4422C1.93659 6.19303 1.93659 5.77838 2.19023 5.5292L2.71349 5.01517C2.96778 4.76536 3.37748 4.7652 3.63196 5.0147L5.07616 6.4209L8.36756 3.18747C8.622 2.93751 9.03206 2.93751 9.2865 3.18747L9.80976 3.70151C10.0634 3.95069 10.0634 4.36534 9.80976 4.61451L5.53651 8.81249Z"
              fill={colors.primary}
            />
          </svg>
          Adicionar
        </StyledAddUnitsButton>
      </StyledAddUnitsModal>
      <ModalGeneric
        size={{ width: '469px' }}
        open={isClearProvidersModalActive}
        hasHeader
        icon={<ToastWarningIcon />}
        title="Inativar fornecedores?"
        closeModal={() => setIsClearProvidersModalActive(false)}
      >
        <StyledClearProvidersModalParagraph>
          Os campos de fornecedores preenchidos serão apagados.
        </StyledClearProvidersModalParagraph>
        <StyledClearProvidersModalButtons>
          <StyledModalCancelButton type="button" onClick={() => setIsClearProvidersModalActive(false)}>
            Cancelar
          </StyledModalCancelButton>
          <StyledClearProvidersModalButton type="button" onClick={() => handleClearProviders()}>
            Inativar mesmo assim
          </StyledClearProvidersModalButton>
        </StyledClearProvidersModalButtons>
      </ModalGeneric>
    </StyledApplicationForm>
  );
}

export default FormApplicationStep;
