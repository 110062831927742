/* istanbul ignore file */

import styled from 'styled-components';

import { medias } from 'assets/styled/tokens';

export const GridColumn = styled.div`
  width: 100%;
  display: inline-grid;
  align-items: baseline;
  grid-template-columns: repeat(${props => (props.size ? props.size : '1')}, 1fr);
  column-gap: ${props => props.columnGap && props.columnGap};
  row-gap: ${props => props.rowGap && props.rowGap};
  :nth-child(1),
  :nth-child(2),
  :nth-child(3) {
    margin-bottom: 16px;
  }
  :last-child {
    margin-bottom: 0;
  }
  @media screen and (max-width: ${medias.tablet}) {
    display: grid;
    grid-template-columns: repeat(1, 1fr) !important;
    column-gap: 0;
  }
`;
