/* istanbul ignore file */

const size = {
  mobile: '375px',
  tabletPortrait: '600px',
  tabletLandscape: '960px',
  screen: '1024px',
  screenWide: '1920px',
};

const devices = {
  mobile: `(min-width: ${size.mobile})`,
  tabletPortrait: `(min-width: ${size.tabletPortrait})`,
  tabletLandscape: `(min-width: ${size.tabletLandscape})`,
  screen: `(min-width: ${size.screen})`,
  screenWide: `(min-width: ${size.screenWide})`,
};

export { devices };
