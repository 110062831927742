import React, { useEffect, useState } from 'react';
import { ReactComponent as NotificationIcon } from 'assets/icons/notification.svg';
import MagnifyingIcon from 'assets/icons/magnifying.svg';
import {
  ORDER_UNIT_MARKERS_BY_ACTIVITY_COUNT,
  ORDER_UNIT_MARKERS_BY_ALPHABET,
  ORDER_UNIT_MARKERS_BY_OCCURRENCE_COUNT,
  TOGGLE_HIDE_UNITS_WITHOUT_OCCURRENCES,
  UPDATE_FILTERED_UNIT_MARKERS,
  UPDATE_UNIT_MARKERS_FILTER,
  UNITS_WITH_OCCURRENCES_SUBFILTER,
  UPDATE_VIEWPORT,
} from 'pages/Geolocalize/constants';
import { useGeolocalizeStore } from 'pages/Geolocalize/store';
import { StyledGrayStrongText } from 'pages/Geolocalize/styled';
import { Select } from 'components/Form/subcomponents/Select';
import UnitCard from './UnitCard';

import {
  StyledCheckboxDropdown,
  StyledPendingOccurrencesFilter,
  StyledPendingOcurrencesDropdownFormGroup,
  StyledSearchUnitsInput,
  StyledUnitsCount,
  OrderByUnitsSelect,
  StyledUnitsViewContainer,
  StyledUnitsViewControls,
  StyledUnitsViewHeader,
  StyledUnitsViewList,
} from './styled';

import { StyledFlexWrapper } from 'utils/helpers/css';
import { createGenericErrorToast } from 'utils/createToast';
import ViewLoadingContainer from '../ViewLoadingContainer';
// import {
//   FETCH_UNIT_MARKERS_ERROR,
//   FETCH_UNIT_MARKERS_SUCCESS,
//   UNIT_MARKERS_TAB
// } from "../../constants";
const orderBySelectOptions = [
  { value: ORDER_UNIT_MARKERS_BY_ACTIVITY_COUNT, label: 'Atividades' },
  { value: ORDER_UNIT_MARKERS_BY_OCCURRENCE_COUNT, label: 'Ocorrências' },
  { value: ORDER_UNIT_MARKERS_BY_ALPHABET, label: 'Ordem alfabetica' },
];
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const UnitsView = ({ setToastList }) => {
  const [state, dispatch] = useGeolocalizeStore();
  const [isOrderUnitsByDropdownOpen, setIsOrderUnitByDropdownOpen] = useState(false);
  const [units, setUnits] = useState(state.filteredMarkers ? state.filteredMarkers : state.unitMarkers.data);

  const handleOrderByUnitsSelectClick = () => {
    setIsOrderUnitByDropdownOpen(!isOrderUnitsByDropdownOpen);
  };

  const setViewport = nextViewport => {
    dispatch({ type: UPDATE_VIEWPORT, payload: nextViewport });
  };

  const orderUnits = orderType => {
    // Sort is applyied without generating a new array. So, we need to copy the original array
    // in order to actually "change" the state.
    let orderedUnits = [...units];

    switch (orderType) {
      case 'orderUnitsByActivityCount':
        orderedUnits.sort((a, b) => a?.unit?.activitiesCount - b?.unit?.activitiesCount);

        if (orderedUnits.length > 0) setUnits(orderedUnits);
        break;
      case 'orderUnitsByOccurencesCount':
        const unitsWithOccurences = orderedUnits.filter(
          item => item?.unit?.occurrences && item?.unit?.occurrences.length > 0,
        );

        unitsWithOccurences.sort((a, b) => {
          const firstItemOccurrencesCount = a?.unit?.occurrences[0].analysed + a?.unit?.occurrences[0].notAnalysed;
          const secondItemOccurrencesCount = b?.unit?.occurrences[0].analysed + b?.unit?.occurrences[0].notAnalysed;

          return firstItemOccurrencesCount - secondItemOccurrencesCount;
        });

        if (unitsWithOccurences.length > 0) setUnits(unitsWithOccurences);

        break;
      case 'orderUnitsByAlphabet':
        orderedUnits.sort((a, b) => a?.unit?.name.localeCompare(b?.unit?.name));

        if (orderedUnits.length > 0) setUnits(orderedUnits);
        break;
      default:
        orderedUnits.sort((a, b) => a?.unit?.activitiesCount - b?.unit?.activitiesCount);

        if (orderedUnits.length > 0) setUnits(orderedUnits);
        break;
    }
  };

  const handleOccurrencesSubfilterSelection = value => {
    dispatch({ type: UNITS_WITH_OCCURRENCES_SUBFILTER, payload: value });
  };

  useEffect(() => {
    dispatch({ type: UPDATE_FILTERED_UNIT_MARKERS });
  }, [
    dispatch,
    state.unitMarkersFilters.isUnitsWithoutOccurrencesHidden,
    state.unitMarkersFilters.unitsWithOccurrencesSubfilter,
    state.unitMarkersFilters.unitSearchTerm,
  ]);

  useEffect(() => {
    setUnits(state.filteredMarkers);
  }, [state.filteredMarkers]);

  useEffect(() => {
    orderUnits();
    // eslint-disable-next-line
  }, []);

  if (state.unitMarkers.error) {
    createGenericErrorToast(setToastList);
    return null;
  }

  if (!state.unitMarkers.data) {
    return <ViewLoadingContainer />;
  }

  if (units) {
    return (
      <StyledUnitsViewContainer>
        <StyledUnitsViewControls>
          <StyledSearchUnitsInput
            type="text"
            autoComplete="off"
            size="small"
            placeholder="Buscar unidade"
            icon={MagnifyingIcon}
            dataCy={`search-input}`}
            onChange={e => {
              dispatch({
                type: UPDATE_UNIT_MARKERS_FILTER,
                payload: { name: 'unitSearchTerm', value: e.target.value },
              });
            }}
          />
        </StyledUnitsViewControls>
        <StyledCheckboxDropdown
          name="toggleHideUnitsWithOccurrences"
          handleChange={() => dispatch({ type: TOGGLE_HIDE_UNITS_WITHOUT_OCCURRENCES })}
          dropdownComponents={
            <>
              <StyledPendingOcurrencesDropdownFormGroup label="Status das ocorrências">
                <Select
                  size="small"
                  placeholder="Seleção opcional"
                  options={[
                    { value: 1, label: 'Analisada' },
                    { value: 2, label: 'Não analisada' },
                  ]}
                  value={state.unitMarkersFilters.unitsWithOccurrencesSubfilter || ''}
                  onChange={val => handleOccurrencesSubfilterSelection(val)}
                />
              </StyledPendingOcurrencesDropdownFormGroup>

              {/* <StyledPendingOcurrencesDropdownFormGroup label="Prioridade das ocorrências">
                <Select
                  size="small"
                  placeholder="Seleção opcional"
                  options={state.unitMarkersFiltersFields?.priorities}
                />
              </StyledPendingOcurrencesDropdownFormGroup> */}
            </>
          }
          label={
            <StyledPendingOccurrencesFilter>
              Exibir apenas unidades com
              <NotificationIcon />
              <span>ocorrências</span>
            </StyledPendingOccurrencesFilter>
          }
        />
        <StyledUnitsViewHeader>
          <StyledUnitsCount>Unidades ({units.length})</StyledUnitsCount>
          <StyledFlexWrapper alignItems="center">
            <StyledGrayStrongText>Ordenar por:</StyledGrayStrongText>
            {
              // TODO: Hoist to reusabled checkbox dropdown
            }

            <OrderByUnitsSelect
              onClick={handleOrderByUnitsSelectClick}
              isOpen={isOrderUnitsByDropdownOpen}
              onChange={event => orderUnits(event.target.value)}
              name="ordernar-por-unidades"
            >
              {orderBySelectOptions.map(option => (
                <option key={option.label} value={option.value}>
                  {option.label}
                </option>
              ))}
            </OrderByUnitsSelect>
          </StyledFlexWrapper>
        </StyledUnitsViewHeader>
        <StyledUnitsViewList>
          {units.map(data => {
            const unitViewport = {
              latitude: data.latitude,
              longitude: data.longitude,
              zoom: 12,
            };

            // const { id, name, unitGroupName, activitiesCount, occurrences } = data.unit;
            return (
              <UnitCard
                {...{
                  id: data.unit?.id,
                  name: data.unit?.name,
                  unitGroupName: data.company,
                  activitiesCount: data.unit?.activitiesCount,
                  occurrences: data.unit?.occurrences,
                }}
                onClick={() => setViewport(unitViewport)}
              />
            );
          })}
        </StyledUnitsViewList>
      </StyledUnitsViewContainer>
    );
  }
};

export default UnitsView;
