import styled, { css } from 'styled-components';

import { colors, medias } from 'assets/styled/tokens';
import { Button } from 'components/Button';

export const NewCleanFiltersButton = styled(Button)`
  background-color: #ffffff;
  width: 156px;
  height: 32px;
  border: 1px solid #e5e5e5;
  border-radius: 4px;
  color: #c25446;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 12px 0 12px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
`;
export const NewCleanFiltersButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 0 95px 0 95px;
  @media (min-width: ${medias.desktopSm}) {
    margin: 0 24px 0 82px;
  }
  @media (min-width: ${medias.desktopMd}) {
    margin: 0 82px 0 82px;
  }
  @media (min-width: ${medias.desktopMd}) {
    margin: 0 95px 0 95px;
  }
`;

export const StyledReportContainer = styled.div`
  display: block;
  background-color: ${colors.white};
  border-radius: 6px;
  box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.1);

  display: grid;
  grid-template-areas:
    'header header'
    'main main'
    'footer footer';
  grid-template-columns: 1fr;
`;

const StyledContainerGrid = css`
  display: inline-grid;
  grid-template-columns: fit-content(100%);
  column-gap: 24px;
  align-items: center;
`;

export const StyledHeader = styled.header`
  grid-area: header;
  width: 100%;
  border: 0 solid ${colors.whiteGray};
  border-bottom-width: 1px;

  @media screen and (min-width: ${medias.desktopMd}) {
    justify-self: center;
  }
`;

export const StyledContainerHeader = styled.div`
  display: flex;
  padding: 0 20px;

  div {
    button {
      &:first-child {
        transition: all 0.2s;
        &:hover {
          color: ${colors.white};
          background: ${colors.bittersweet};
          border-color: ${colors.bittersweet};
        }
        svg {
          margin-right: 8px;
        }
      }
    }
  }

  svg {
    overflow: visible;
    cursor: pointer;
  }
`;

export const StyledContentWrapper = styled.div`
  width: 100%;
  display: ${props => props.display || 'flex'};
  flex-direction: ${props => props.flexDirection || 'row'};
  justify-content: ${props => props.justifyContent};
  align-items: ${props => props.alignItems};
  grid-column-start: ${props => props.gridColumnStart};
  gap: ${props => props.gap};
`;

export const StyledTitle = styled.h3`
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  font-family: Blinker, sans-serif;
  font-size: 18px;
  color: ${colors.primary};
  margin-left: 16px;
  margin-top: 18px;
  margin-bottom: 0px;
`;

export const TabItem = styled.div`
  font-size: 24px;
  font-family: Blinker, sans-serif;
  font-weight: 700;
  line-height: 21px;
  cursor: pointer;
  border: 0;
  outline: 0;
  color: ${colors.mediumGray};
  margin-right: 24px;
  display: inline-flex;
  flex-direction: column;
  align-items: space-between;

  ${({ active }) =>
    active &&
    `
    color: ${colors.primary};
  `}

  :hover {
    color: inherit !important;
  }
`;

export const TabItemBorder = styled.div`
  margin-top: 18px;
  background: ${colors.white};
  border-radius: 4px 4px 0 0;
  width: 100%;
  height: 2px;

  ${({ active }) =>
    active &&
    `
    background: ${colors.primary};
  `}
`;

export const StyledMain = styled.div`
  grid-area: main;
  justify-self: center;
  padding: 40px 0;
  ${props =>
    props.propertyContent === 'tabela' &&
    css`
      width: 100%;
      padding: 40px;
    `}
`;

export const StyledContainerMain = styled.div`
  ${StyledContainerGrid};

  @media screen and (min-width: ${medias.desktopSm}) {
    grid-template-columns: 0 460px 4px 460px 0;
  }
  @media screen and (min-width: ${medias.desktopMd}) {
    grid-template-columns: 0 40% 4% 46% 0;
  }

  @media screen and (min-width: ${medias.desktopMd}) {
    grid-template-columns: 72px 395px 72px 460px 72px;
  }

  ${props =>
    props.propertyContent === 'tabela' &&
    css`
      display: initial;
      width: 100%;
    `}
`;

export const StyledFooter = styled.footer`
  grid-area: footer;
  padding: 22px 10px;
  border-top: 1px solid ${colors.whiteGray};
`;

export const StyledContainerFooter = styled.div`
  ${StyledContainerGrid};
  justify-content: center;

  @media screen and (min-width: ${medias.tablet}) {
    grid-template-columns: 400px 520px;
  }

  @media screen and (min-width: ${medias.desktopMd}) {
  }
`;

export const StyledActions = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  & > div {
    margin-right: 16px;
  }

  & > button {
    min-width: 153px;
  }
`;

// export const StyledCurrentFilterActive = styled.p`
//   font-weight: 500;
//   font-size: 0.75rem;
//   color: ${colors.tundora};

//   span {
//     position: relative;
//     font-size: 12px;
//     line-height: 16px;
//     margin-left: 8px;
//     color: ${colors.gray};

//     border: 1px solid ${colors.gray};
//     border-radius: 3px;
//     padding: 4px 24px 4px 8px;

//     svg {
//       position: absolute;
//       top: 8px;
//       right: 8px;
//       display: block;
//       width: 8px;
//       height: 8px;
//       cursor: pointer;
//     }
//   }
// `;
