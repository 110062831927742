import React, { useContext, useState, useEffect } from 'react';
import { FiltersContext } from 'context/GlobalFiltersContext';
import { GlobalComponentsContext } from 'context/GlobalComponentsContext';
import { AuthContext } from 'context/AuthContext';
import { HeaderFiltersPage } from 'containers/HeaderFiltersPage';
import { PageWrapper } from 'components/PageWrapper';
import { MobileNavbar } from 'components/MobileNavbar';
//import { Menu } from 'components/Menu';
import { colors } from 'assets/styled/tokens';
import { NewMenu } from 'components/Menu/NewMenu';
import { Loading, Toast } from 'components';
import {
  CategoryHeader,
  CategorySubHeader,
  CategoryInfo,
  CategoryItemContainer,
  CategoryItemTitle,
  CategoryTitle,
  ConfigOptionsContainer,
  ConfigPageWrapper,
  Header,
  HeaderName,
  CategoryHeaderTitle,
  SaveButton,
} from './styled';
import { Switch } from './SubComponents/Switch';
import { useForm } from 'react-hook-form';
import { NotificationConfig } from 'services/main/endpoints/Notificationservice';
import { createGenericErrorToast, createSuccessToast } from 'utils/createToast';

export const PushNotification = ({ pathname }) => {
  const { authContext } = useContext(AuthContext);
  const { register, handleSubmit } = useForm();
  const { token } = authContext;
  const { globalComponentsContext, setGlobalComponentsContext } = useContext(GlobalComponentsContext);
  const { isLoading } = globalComponentsContext;
  const { headerFiltersContext } = useContext(FiltersContext);
  const { headerFilters } = headerFiltersContext;
  const [toastList, setToastList] = useState([]);
  const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);
  const [isAllSelectedOccurrence, setIsAllSelectedOccurrence] = useState(false);
  const [isOnlyExecutorSelectedOccurrence, setIsOnlyExecutorSelectedOccurrence] = useState(false);
  const [isAllSelectedCorrection, setIsAllSelectedCorrection] = useState(false);
  const [isOnlyExecutorSelectedCorrection, setIsOnlyExecutorSelectedCorrection] = useState(false);
  const fetchData = async () => {
    setGlobalComponentsContext(prevState => ({
      ...prevState,
      isLoading: true,
    }));
    const configObj = {
      token,
      EmpresaId: headerFilters?.companies?.id,
      UnidadId: headerFilters?.units?.id,
    };
    try {
      const response = await NotificationConfig.getConfig(configObj);
      const { data } = response;
      if (data) {
        if (data.notificacaoOperacionalCorrecao === 0) {
          setIsAllSelectedCorrection(false);
          setIsOnlyExecutorSelectedCorrection(false);
        } else if (data.notificacaoOperacionalCorrecao === 1) {
          setIsAllSelectedCorrection(true);
          setIsOnlyExecutorSelectedCorrection(false);
        } else {
          setIsAllSelectedCorrection(false);
          setIsOnlyExecutorSelectedCorrection(true);
        }
        if (data.notificacaoOperacionalOcorrencia === 0) {
          setIsAllSelectedOccurrence(false);
          setIsOnlyExecutorSelectedOccurrence(false);
        } else if (data.notificacaoOperacionalOcorrencia === 1) {
          setIsAllSelectedOccurrence(true);
          setIsOnlyExecutorSelectedOccurrence(false);
        } else {
          setIsAllSelectedOccurrence(false);
          setIsOnlyExecutorSelectedOccurrence(true);
        }
      }
      return;
    } catch (e) {
      createGenericErrorToast(setToastList);
      return;
    } finally {
      setGlobalComponentsContext(prevState => ({
        ...prevState,
        isLoading: false,
      }));
    }
  };
  useEffect(() => {
    if (headerFilters?.companies) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [headerFilters]);
  const handleSwitchChangeOccurrence = switchType => {
    if (switchType === 'ocurrences.allOccurrence') {
      setIsAllSelectedOccurrence(!isAllSelectedOccurrence);
      setIsOnlyExecutorSelectedOccurrence(false);
    } else if (switchType === 'ocurrences.onlyExecutorOccurrence') {
      setIsAllSelectedOccurrence(false);
      setIsOnlyExecutorSelectedOccurrence(!isOnlyExecutorSelectedOccurrence);
    }
  };
  const handleSwitchChangeCorrections = switchType => {
    if (switchType === 'corrections.allCorrections') {
      setIsAllSelectedCorrection(!isAllSelectedCorrection);
      setIsOnlyExecutorSelectedCorrection(false);
    } else if (switchType === 'corrections.onlyExecutorCorrection') {
      setIsAllSelectedCorrection(false);
      setIsOnlyExecutorSelectedCorrection(!isOnlyExecutorSelectedCorrection);
    }
  };
  const onSubmit = async values => {
    const configObj = {
      configOcorrencia: values.ocurrences.allOccurrence ? 1 : values.ocurrences.onlyExecutorOccurrence ? 2 : 0,
      configCorrecao: values.corrections.allCorrections ? 1 : values.corrections.onlyExecutorCorrection ? 2 : 0,
      token,
      EmpresaId: headerFilters?.companies?.id,
      UnidadId: headerFilters?.units?.id,
    };
    try {
      const response = await NotificationConfig.pushconfig(configObj);
      const { data } = response;
      if (data.message === 'Configurações atualizadas com sucesso') {
        createSuccessToast(setToastList, 'Configuração salva.');
      }
      return;
    } catch (e) {
      createGenericErrorToast(setToastList);
      return;
    }
  };
  return (
    <>
      {isLoading && <Loading />}
      <PageWrapper background={colors.pampas}>
        <MobileNavbar />
        <NewMenu setIsSubMenuOpen={setIsSubMenuOpen} />
        <HeaderFiltersPage location={pathname} isSubMenuOpen={isSubMenuOpen}>
          <Header>
            <HeaderName>Notificações via Push</HeaderName>
          </Header>
          <ConfigPageWrapper>
            <CategoryHeader>
              <CategoryHeaderTitle>Configurar</CategoryHeaderTitle>
              <SaveButton onClick={handleSubmit(onSubmit)}>Salvar</SaveButton>
            </CategoryHeader>
            <CategorySubHeader>
              <CategoryTitle>Usuários Operacionais</CategoryTitle>
              <CategoryInfo>
                Configure como seus usuários operacionais devem receber os avisos via Push no Aplicativo:
              </CategoryInfo>
            </CategorySubHeader>
            <ConfigOptionsContainer>
              <CategoryItemContainer>
                <CategoryItemTitle>Ocorrências - Receber notificações de:</CategoryItemTitle>
                <Switch
                  label="Todas as novas ocorrências das unidades que o usuário operacional possui acesso."
                  name="ocurrences.allOccurrence"
                  isSelected={isAllSelectedOccurrence}
                  handleSwitchChange={handleSwitchChangeOccurrence}
                  ref={register()}
                />
                <Switch
                  label="Apenas de ocorrências nas quais o usuário operacional é executor."
                  name="ocurrences.onlyExecutorOccurrence"
                  isSelected={isOnlyExecutorSelectedOccurrence}
                  handleSwitchChange={handleSwitchChangeOccurrence}
                  ref={register()}
                />
              </CategoryItemContainer>
              <CategoryItemContainer>
                <CategoryItemTitle>Correções - Receber notificações de:</CategoryItemTitle>
                <Switch
                  label="Todas as novas correções das unidades que o usuário operacional possui acesso."
                  name="corrections.allCorrections"
                  isSelected={isAllSelectedCorrection}
                  handleSwitchChange={handleSwitchChangeCorrections}
                  ref={register()}
                />
                <Switch
                  label="Apenas de correções nas quais o usuário operacional é executor."
                  name="corrections.onlyExecutorCorrection"
                  isSelected={isOnlyExecutorSelectedCorrection}
                  handleSwitchChange={handleSwitchChangeCorrections}
                  ref={register()}
                />
              </CategoryItemContainer>
            </ConfigOptionsContainer>
          </ConfigPageWrapper>
        </HeaderFiltersPage>
      </PageWrapper>
      <Toast toastList={toastList} autoDelete dismissTime={5000} />
    </>
  );
};
