import styled from 'styled-components';

import selectDropdownSvg from 'assets/icons/select-dropdown.svg';
import uncheckedIconSvg from 'assets/icons/unchecked-icon.svg';
import checkedIconSvg from 'assets/icons/checked-icon.svg';
import partialCheckedIconSvg from 'assets/icons/partial-checked-icon.svg';

export const DropdownWrapper = styled.div<{ isOpened?: boolean }>`
  display: inline-block;
  cursor: pointer;
  position: relative;
  z-index: 99;
`;

export const DropdownChildren = styled.div<{ isOpened?: boolean }>`
  background: #fff;
  padding: 12px;
  position: relative;
  z-index: 11;

  &::after {
    content: '';
    display: inline-block;
    background: url(${selectDropdownSvg}) no-repeat center/contain;
    display: inline-block;
    height: 8px;
    width: 8px;
    margin-left: 6px;
  }
`;

export const DropdownContent = styled.div<{ isOpened?: boolean }>`
  background: #fff;
  border-radius: 0px 0px 4px 4px;
  border: 1px solid #e5e5e5;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.2);
  display: ${({ isOpened = false }) => (isOpened ? 'block' : 'none')};
  left: 0;
  min-width: 170px;
  position: absolute;
  z-index: 10;
  top: 100%;
`;

export const DropdownContentInner = styled.div`
  background: #fff;
  border-radius: 4px;
  box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.25);
  color: #888;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  margin-top: 2px;
`;

export const DropdownMenu = styled.div``;

export const DropdownMenuItem = styled.div<{ isSelected?: boolean }>`
  background-color: #fff;
  padding: 6px 8px;

  &:hover {
    background-color: #f5f3f1;
  }
`;

export const DropdownMenuIconCheck = styled.span<{ isSelected?: boolean; isPartialSelected?: boolean }>`
  width: 12px;
  height: 12px;
  display: inline-block;
  margin-right: 8px;
  background: ${({ isSelected = false, isPartialSelected = false }) =>
      isSelected
        ? `url(${checkedIconSvg})`
        : isPartialSelected
        ? `url(${partialCheckedIconSvg})`
        : `url(${uncheckedIconSvg})`}
    no-repeat center/contain;
`;
