import { colors } from 'assets/styled/tokens';
import { Button } from 'components/Button';
import { StyledSearchInput } from 'components/StyledUtils/inputs';
import { StyledTechniciansCount } from 'pages/Geolocalize/styled';
import styled from 'styled-components';

import { ViewContainer } from '../styled';

export const StyledTechniciansViewContainer = styled(ViewContainer)`
  font-size: 12px;
  line-height: 14px;
`;
export const StyledSearchTechniciansInput = styled(StyledSearchInput)`
  //width: 213px;
  width: 100%;
`;

export const StyledTechniciansViewControls = styled.div`
  display: flex;
`;

export const StyledTechniciansViewList = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 32px;
  margin-bottom: 54px;
`;
export const StyledOnlineTechnicians = styled.div`
  h4 {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  button {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  ${StyledTechniciansCount} {
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: ${colors.darkPrimary};
    margin-left: 8px;
  }
`;
export const StyledOfflineTechnicians = styled.div`
  margin-top: 24px;
  button {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  h4 {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  ${StyledTechniciansCount} {
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: ${colors.gray};
    margin-left: 8px;
  }
`;
export const StyledExcelRoutesDownloadButton = styled(Button)`
  //display: none;
  padding: 8px 12px 8px;
  margin-right: 13px;
  margin-top: 0;
  span {
    font-size: 14px;
    font-weight: 400;
    color: white;
    margin-left: 8px;
  }
`;

export const StyledBackButtonContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 24px;
`;

export const StyledArrowLeft = styled.span`
  background-color: ${colors.white};
  border: 1px solid ${colors.whiteGray};
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  margin-right: 16px;
  cursor: pointer;
  svg {
  }
  path {
    stroke: ${colors.carbon};
  }
`;

export const StyledTeamName = styled.h2`
  font-family: 'Roboto';
  font-size: 18px;
  font-weight: 500;
`;
