/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import * as React from 'react';

import { useBoolean, useDebounce } from 'hooks';
import { fetcher } from 'services';
import { LkpChart } from 'components/Charts/Chart';
import {
  ChartsParamsType,
  ChartActivitiesOccurrencesType,
  ChartSeriesType,
  ChartUpdate,
  UpdateParamsType,
} from 'components/Charts/types';
import { COMPARATIVE_WEEKS_OPTIONS } from './options';
import { LkpChartTopFilter } from '../Filters';
import { HeaderFiltersType } from 'context/GlobalFiltersContext/initialState';
import { DashModal, DashModalContent, DashModalHeader, DashModalInner, DashModalItems, DashRadio } from '../styled';
import { Button } from 'components/Button';
import { getErrorMessageByStatusCode, getOnlyHours, getOnlyHoursYaxis, minutesToHours } from 'utils';
import { useParams } from 'react-router-dom';
import { createToast } from '../createToast';

const DEFAULT_SERIES = [
  {
    name: 'Corretivas',
    data: [0],
  },
  {
    name: 'Outras corretivas',
    data: [0],
  },
  {
    name: 'Preventivas',
    data: [0],
  },
];

export interface LkpChartActivitiesOccurrencesProps {
  headerFilters: HeaderFiltersType;
  setToastList: any;
}

export const LkpChartActivitiesOccurrences = ({
  headerFilters,
  setToastList,
}: LkpChartActivitiesOccurrencesProps): React.ReactElement => {
  const { dateFrom, dateTo } = useParams<{ dateFrom: string; dateTo: string }>();
  const [data, setData] = React.useState<Array<ChartActivitiesOccurrencesType>>([]);
  const [series, setSeries] = React.useState<Array<ChartSeriesType>>(DEFAULT_SERIES);
  const [period, setPeriod] = React.useState<Array<string>>([]);
  const [errorMessage, setErrorMessage] = React.useState<string>();
  const [paramsSystems, setParamsSystems] = React.useState<string>('');
  const [params, setParams] = React.useState<ChartsParamsType>({
    Empresa: headerFilters?.companies?.id || 0,
    Sites: headerFilters?.units?.id,
    exibirMes: false,
    Inicio: dateFrom,
    Fim: dateTo,
  });
  const [updateParamns, setUpdateParamns] = React.useState<UpdateParamsType>({
    Empresa: headerFilters?.companies?.id || 0,
    Inicio: dateFrom,
    Fim: dateTo,
  });
  const [lastUpdateMessage, setLastUpdateMessage] = React.useState();
  const { value: isChartPercent, setTrue: setChartPercent, setFalse: setChartHours } = useBoolean();
  const { value: isLoading, setValue: setLoading } = useBoolean();
  const { value: isModalOpen, setTrue: setModalOpen, setFalse: setModalClose } = useBoolean();
  const { value: showMonth, setValue: setShowMonth } = useBoolean();
  const { value: isError, setValue: setIsError } = useBoolean();

  const debouncedStartValue = useDebounce<string>(params?.Inicio || '', 500);
  const debouncedEnvValue = useDebounce<string>(params?.Fim || '', 500);
  const debouncedParamsSystemsValue = useDebounce<string>(paramsSystems || '', 500);

  React.useEffect(() => {
    (async () => {
      await fetchChartData();
      await LastUpdate();
    })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showMonth, debouncedStartValue, debouncedEnvValue, debouncedParamsSystemsValue]);

  React.useEffect(() => {
    if (!!data) {
      mountPeriod(data);
      mountSeries(data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);
  const LastUpdate = async () => {
    const _httpPath2 = `/ultima-atualizacao-dm-horas-tecnicas-por-sistema?Empresa=${updateParamns.Empresa}&Inicio=${updateParamns.Inicio}&Fim=${updateParamns.Fim}`;
    const result = await fetcher.get(_httpPath2, 'dashboard').catch(err => {
      const message = getErrorMessageByStatusCode(err, _httpPath2);
      setErrorMessage(message);
      setIsError(true);
    });
    setLastUpdateMessage(result);
    const newToast = createToast({
      type: 'updating',
      message: 'Atualizando dados. Aguarde alguns minutos e recarregue a página para atualizar o gráfico.',
    });
    setToastList(prevState => [...prevState, newToast]);
  };
  const UpdateChart = async () => {
    const _httpPath1 = `/atualizar-dm-horas-tecnicas-por-sistema?Empresa=${updateParamns.Empresa}&Inicio=${updateParamns.Inicio}&Fim=${updateParamns.Fim}`;
    await fetcher.get<ChartUpdate>(_httpPath1, 'dashboard').catch(err => {
      const message = getErrorMessageByStatusCode(err, _httpPath1);
      setErrorMessage(message);
      setIsError(true);
    });
    await LastUpdate();
  };
  const fetchChartData = async () => {
    setLoading(true);

    let _httpPath = '/horas-preventivas-corretivas-por-periodo';
    if (!!paramsSystems) _httpPath = `${_httpPath}?${paramsSystems}`;

    const result = await fetcher
      .get<Array<ChartActivitiesOccurrencesType>>(_httpPath, 'dashboard', { params })
      .catch(error => {
        const message = getErrorMessageByStatusCode(error, _httpPath);
        setErrorMessage(message);
        setIsError(true);
      });

    if (result) setData(result);
    setLoading(false);
  };

  const handleSelectSystems = (selectedSystems: string) => {
    setParamsSystems(selectedSystems);
  };

  const handleChangeStartDate = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value: Inicio } = event.target;
    setParams({ ...params, Inicio });
    setUpdateParamns({ ...params, Inicio });
  };

  const handleChangeEndDate = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value: Fim } = event.target;
    setParams({ ...params, Fim });
    setUpdateParamns({ ...params, Fim });
  };

  const mountSeries = (data: Array<ChartActivitiesOccurrencesType>) => {
    const _series = DEFAULT_SERIES.map(series => {
      let _seriesData = series.data;

      switch (series.name) {
        case 'Corretivas':
          _seriesData = data.map(({ horasCorretivas, porcentagemCorretivas }) => {
            if (isChartPercent) return porcentagemCorretivas || 0;
            return horasCorretivas || 0;
          });
          break;
        case 'Outras corretivas':
          _seriesData = data.map(({ horasOutras, porcentagemOutras }) => {
            if (isChartPercent) return porcentagemOutras || 0;
            return horasOutras || 0;
          });
          break;
        case 'Preventivas':
          _seriesData = data.map(({ horasPreventivas, porcentagemPreventivas }) => {
            if (isChartPercent) return porcentagemPreventivas || 0;
            return horasPreventivas || 0;
          });
          break;
      }

      return { name: series.name, data: _seriesData };
    });

    setSeries(_series);
  };

  const mountPeriod = (data: Array<ChartActivitiesOccurrencesType>) => {
    const _period = data.map(({ periodo }) => periodo || '');

    setPeriod(_period);
  };

  const handleChangeMonth = (event: React.MouseEvent<HTMLButtonElement>) => {
    const { value } = event.currentTarget;
    const exibirMes = value === 'month';
    setParams({ ...params, exibirMes });
    setShowMonth(exibirMes);
  };

  return (
    <>
      <LkpChart
        title="Horas Técnicas Investidas - Atividades x Ocorrências"
        companyId={headerFilters?.companies?.id || 0}
        isTecHour={true}
        series={series}
        //width={1700}
        options={{
          ...COMPARATIVE_WEEKS_OPTIONS,
          chart: {
            type: 'bar',
            stacked: true,
            stackType: isChartPercent ? '100%' : undefined,
            height: 350,
            toolbar: {
              show: false,
            },
          },
          yaxis: {
            ...COMPARATIVE_WEEKS_OPTIONS.yaxis,

            labels: {
              formatter: function (val) {
                return `${getOnlyHoursYaxis(val)}h`;
              },
            },
          },
          plotOptions: {
            bar: {
              horizontal: false,
              columnWidth: '55%',
            },
          },
          colors: ['#E8871A', '#804A0E', '#00C0C7'],
          xaxis: { categories: period },
          dataLabels: {
            enabled: true,
            formatter: function (value) {
              if (isChartPercent) return `${parseFloat(`${value}`).toFixed(0)}%`;
              return `${getOnlyHours(+value)}h`;
            },
            dropShadow: {
              enabled: true,
              top: 1.5,
              left: 3.5,
              blur: 2.5,
              color: '#000',
              opacity: 0.45,
            },
          },
          tooltip: {
            y: {
              formatter: function (value) {
                if (isChartPercent) return `${value}%`;
                return minutesToHours(+value);
              },
            },
          },
        }}
        error={isError}
        errorMessage={errorMessage}
        loading={isLoading}
        onChartTryAgain={fetchChartData}
        chartUpdate={UpdateChart}
        lastUpdateMessage={lastUpdateMessage}
        lastUpdate={LastUpdate}
        onSettingsClick={() => {
          setModalOpen();
        }}
        filter={
          <LkpChartTopFilter
            onChangeStartDate={handleChangeStartDate}
            onChangeEndDate={handleChangeEndDate}
            onChangeMonth={handleChangeMonth}
            showMonth={showMonth}
            onSelectSystems={handleSelectSystems}
          />
        }
      />
      <DashModal size={{ width: '456px' }} open={isModalOpen} closeModal={() => setModalClose()}>
        <DashModalInner>
          <DashModalHeader>
            <h3>Configuração do Gráfico</h3>
          </DashModalHeader>
          <DashModalContent>
            <span>Valores exibidos:</span>
            <DashModalItems role="menu">
              <span
                onClick={() => {
                  fetchChartData();
                  setChartHours();
                }}
              >
                <DashRadio checked={!isChartPercent} /> Quantidade de horas
              </span>
              <span
                onClick={() => {
                  fetchChartData();
                  setChartPercent();
                }}
              >
                <DashRadio checked={isChartPercent} /> Porcentagem de horas
              </span>
            </DashModalItems>
            <Button>
              <span onClick={() => setModalClose()}>Aplicar</span>
            </Button>
          </DashModalContent>
        </DashModalInner>
      </DashModal>
    </>
  );
};
