const { useQuery } = require('react-query');
const { GeolocalizeService } = require('services/reports');
// import { mockResponse } from './mocks';

function useMarkers(companyId, unitId, token) {
  let params = { Empresa: companyId ?? '4157' };

  if (unitId) {
    params = {
      ...params,
      Site: unitId,
    };
  }
  return useQuery(
    'markers',
    async () => {
      const res = await GeolocalizeService.getMarkers(params, token);
      return res.data;
      // return mockResponse;
    },
    {
      refetchInterval: 300000,
      refetchOnWindowFocus: true,
    },
  );
}
function useTechniciansRoute(companyId, routeId) {
  return useQuery('route', async () => {
    const res = await GeolocalizeService.getRoute({ routeId, companyId });
    return res.data;
  });
}

function useTeams(companyId) {
  return useQuery('teams', async () => {
    const res = await GeolocalizeService.getTeams({ companyId });
    return res.data;
  });
}

function useRoutesReports({ technicianId, companyId, initialDate, endDate }) {
  const params = {
    id: technicianId,
    empresa: companyId,
    dataInicio: initialDate,
    dataFim: endDate,
  };

  return useQuery(
    'routeReport',
    async () => {
      const res = await GeolocalizeService.getRouteReport(params);
      return res.data;
    },
    {
      enabled: false,
    },
  );
}

// function useOcurrencesPriorities(unitId) {
//   return useQuery('teams', async () => {
//     const res = await GeolocalizeService.getOcurrencesPriorities({ unitId });
//     return res.data;
//   });
// }
export { useTechniciansRoute, useMarkers, useTeams, useRoutesReports };
