import UnitModalMarker from '../ReactMapbox/MapModalMarker/UnitModalMarker';

const UnitMarkers = ({ markers }) => {
  if (markers) {
    return markers.map((marker, index) => {
      return <UnitModalMarker key={index} marker={marker} />;
    });
  } else {
    return null;
  }
};
export default UnitMarkers;
