import checkIcon from 'assets/icons/check.svg';
import selectDropdown from 'assets/icons/select-dropdown.svg';
import { colors } from 'assets/styled/tokens';
import styled, { css } from 'styled-components';
const inputCheckedCss = css`
  background: ${colors.primary};
  border-color: ${colors.primary};

  &::after {
    content: '';
    display: block;
    width: 0.625rem;
    height: 0.625rem;
    background: url(${checkIcon}) no-repeat center/contain;
  }
`;
export const StyledCheckboxDropdownContainer = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid ${colors.whiteGray};
  border-radius: 4px;
  background: white;
`;
export const StyledCheckboxRowContainer = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  width: 100%;
  padding: 8px 0 8px 12px;
  border-bottom: ${({ isDropdownOpen }) => (isDropdownOpen ? `1px solid ${colors.whiteGray}` : `none`)};
  svg:last-child {
    margin-left: auto;
    padding: 8px;
  }
`;
export const StyledToggleDropdownButton = styled.button`
  width: 40px;
  height: 32px;
  cursor: pointer;
  margin-left: auto;
  background: ${props => `url(${selectDropdown}), ${props.disabled ? colors.pampas : 'white'}`};
  background-size: ${({ dropdownDisabled }) => (dropdownDisabled ? '0' : '8px 4px')};
  background-position: center;
  background-repeat: no-repeat;
  transform: ${({ isFlipped }) => (isFlipped ? 'rotate(180deg)' : 'rotate(0deg)')};
`;
export const StyledCheckboxDropdown = styled.div`
  display: inline-flex;

  span {
    width: 12px;
    height: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${colors.white};
    border: 2px solid ${colors.silver};
    border-radius: 3px;
    ${({ isChecked }) => isChecked && inputCheckedCss}
  }
`;
export const StyledCheckboxDropdownLabel = styled.label`
  margin-left: 8px;
  font-size: 14px;
  line-height: 16px;
  color: ${colors.gray};
`;

export const StyledCheckboxWrapper = styled.div`
  width: 100%;
  position: absolute;
  left: 0;
  right: 0;

  display: flex;
`;
export const StyledCheckboxInput = styled.input`
  opacity: 0;
  position: absolute;
  z-index: 6;
  cursor: pointer;
`;
export const StyledDropdownContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${({ isDropdownOpen }) => (isDropdownOpen ? `16px 12px` : `0`)};
  > div:first-child {
    margin-top: 0;
  }
`;
