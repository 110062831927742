const adapt = httpResponse => ({
  ...httpResponse,
  data: httpResponse.data.map(cidade => ({
    id: cidade.cidadeId,
    name: cidade.nome,
    state: cidade.estado && {
      id: cidade.estado.estadoId,
      name: cidade.estado.nome,
      initials: cidade.estado.sigla,
      countryId: cidade.estado.paisId,
    },
  })),
});

const getAllCitiesAdapter = httpResponsePromise =>
  httpResponsePromise.then(
    resolve => adapt(resolve),
    reject => reject,
  );

export { getAllCitiesAdapter };
