import { StyledOnlineOfflineEllipse } from '../../../../styled';
import { StyledFlexColumn, StyledFlexRow } from 'components/StyledUtils/flex';
// import { UPDATE_SIDEBAR_ROUTE_SUBVIEW_TECHNICIAN } from 'pages/Geolocalize/constants';
// import { useGeolocalizeStore } from 'pages/Geolocalize/store';
import {
  StyledTechnicianLocation,
  StyledTechnicianName,
  StyledTechnicianStatus,
  StyledSingleTechnicianStatusWrapper,
  StyledWhatsappContactButton,
  StyledWhatsappIconComponent,
} from 'pages/Geolocalize/styled';
import React from 'react';
import { StyledFlexWrapper } from 'utils/helpers/css';

import { StyledTechnicianHorizontalLine } from '../styled';
import { StyledTechnicianModalContainer, StyledTechnicianMarkerModal } from './styled';
import TechnicianAvatar from '../../TechnicianAvatar';
import BatteryCharge from 'components/Battery/BatteryCharge';
import { StyledAvatarColumn } from 'pages/Geolocalize/subcomponents/FiltersSidebar/TechniciansView/TechnicianCard/styled';
const TechnicianModalContent = ({
  marker,
  isSingleTechnicianPin,
  singleTechnician,
  latestLocalization,
  isEveryTechnicianOffline,
}) => {
  const isSingleTechnicianOnline = isSingleTechnicianPin && !isEveryTechnicianOffline;
  // const [, dispatch] = useGeolocalizeStore();

  return (
    <StyledTechnicianMarkerModal isSingleTechnicianPin={isSingleTechnicianPin}>
      {!isSingleTechnicianPin ? (
        <>
          <StyledTechnicianLocation>
            Última localização: <span>{latestLocalization}</span>
          </StyledTechnicianLocation>
          <StyledTechnicianHorizontalLine />
          {marker.technicians.map(technician => {
            const isTechnicianOnline = technician.status === 'online';
            return (
              <StyledTechnicianModalContainer key={marker.id}>
                <StyledFlexWrapper>
                  <StyledAvatarColumn>
                    <TechnicianAvatar technician={technician} />
                    <StyledTechnicianStatus isOnline={isTechnicianOnline}></StyledTechnicianStatus>
                    <BatteryCharge battery={technician.battery} />
                  </StyledAvatarColumn>
                  <StyledFlexColumn style={{ width: '100%' }}>
                    <StyledTechnicianName>
                      <StyledOnlineOfflineEllipse status={technician.status} /> {technician.name}
                    </StyledTechnicianName>
                    <StyledFlexRow style={{ marginTop: '9px' }}>
                      {technician.number && (
                        <StyledWhatsappContactButton
                          href={`https://api.whatsapp.com/send?1=pt_BR&phone=55${technician.number.replaceAll(
                            '-',
                            '',
                          )}`}
                          target="_blank"
                        >
                          <StyledWhatsappIconComponent />
                          {technician.number}
                        </StyledWhatsappContactButton>
                      )}
                      {/* <StyledViewRouteModalButton
                        onClick={() => {
                          const mockTechnician = { ...technician, id: 1 };
                          dispatch({
                            type: UPDATE_SIDEBAR_ROUTE_SUBVIEW_TECHNICIAN,
                            payload: mockTechnician,
                          });
                        }}
                      >
                        Ver rota
                      </StyledViewRouteModalButton> */}
                    </StyledFlexRow>
                  </StyledFlexColumn>
                </StyledFlexWrapper>
              </StyledTechnicianModalContainer>
            );
          })}
        </>
      ) : (
        <>
          <StyledTechnicianModalContainer key={marker.id}>
            <StyledFlexWrapper flexDirection="column">
              <StyledFlexWrapper style={{ marginRight: '9px', marginTop: '-2px' }}>
                <TechnicianAvatar technician={singleTechnician} />
                <StyledFlexWrapper flexDirection="column">
                  <StyledTechnicianName>{singleTechnician.name}</StyledTechnicianName>
                  <StyledSingleTechnicianStatusWrapper>
                    <StyledTechnicianStatus isOnline={isSingleTechnicianOnline}>
                      <StyledOnlineOfflineEllipse status={singleTechnician.status} /> {singleTechnician.status}{' '}
                    </StyledTechnicianStatus>
                    {!isEveryTechnicianOffline && <BatteryCharge battery={singleTechnician.battery} />}
                  </StyledSingleTechnicianStatusWrapper>
                </StyledFlexWrapper>
              </StyledFlexWrapper>
              <StyledTechnicianHorizontalLine />

              <StyledFlexWrapper flexDirection="column" style={{ width: '100%' }}>
                <StyledTechnicianLocation>
                  Última localização: <span>{latestLocalization}</span>
                </StyledTechnicianLocation>
                <StyledFlexRow style={{ marginTop: '9px' }}>
                  {singleTechnician.number && (
                    <StyledWhatsappContactButton
                      href={`https://api.whatsapp.com/send?1=pt_BR&phone=55${singleTechnician.number.replaceAll(
                        '-',
                        '',
                      )}`}
                      target="_blank"
                    >
                      <StyledWhatsappIconComponent />
                      {singleTechnician.number}
                    </StyledWhatsappContactButton>
                  )}
                  {/* <StyledViewRouteModalButton
                    onClick={() => {
                      const mockTechnician = { ...singleTechnician, id: 1 };
                      dispatch({
                        type: UPDATE_SIDEBAR_ROUTE_SUBVIEW_TECHNICIAN,
                        payload: mockTechnician,
                      });
                    }}
                  >
                    Ver rota
                  </StyledViewRouteModalButton> */}
                </StyledFlexRow>
              </StyledFlexWrapper>
            </StyledFlexWrapper>
          </StyledTechnicianModalContainer>
        </>
      )}
    </StyledTechnicianMarkerModal>
  );
};
export default TechnicianModalContent;
