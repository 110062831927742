//import { getLocalAuthStorage } from 'utils/localStorage';

function getCookie(name) {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(';').shift();
}
const addBearerTokenInReq = config => {
  const token = getCookie('authToken');
  config.headers.Authorization = token ? `Bearer ${token}` : '';
  return config;
};

export { addBearerTokenInReq };
