import { AxiosReportLas, AxiosGlobalFilters } from '../config';
import qs from 'qs';

const ActivitiesServices = {
  validateLogin: login => {
    return AxiosReportLas({
      method: 'post',
      url: `/v1/Relatorios/ValidarEmailUsuario?login=${login}`,
    });
  },
  getActivitiesPlans: ({ companyId, unitId, systemIds /*inactivePlans */ }) => {
    return AxiosGlobalFilters({
      method: 'get',
      url: `/v1/planosatividades/ativos`,
      params: {
        empresaId: companyId,
        sistemasIds: systemIds,
        unidadeId: unitId,
      },
      paramsSerializer: params => {
        return qs.stringify(params, { arrayFormat: 'repeat' });
      },
    });
  },
  sendExcelByEmail: ({
    login,
    site,
    listaSites,
    empresa,
    dataInicial,
    dataFinal,
    sistemas,
    planoAtividades,
    status,

    planosInativos,
  }) => {
    return AxiosReportLas({
      method: 'post',
      url: '/v1/Relatorios/RelatorioAtividade',
      data: {
        login,
        site,
        empresa,
        dataInicial,
        dataFinal,
        sistemas,
        planoAtividades,
        status,
        listaSites,
        planosInativos,
      },
      headers: {
        'Content-Type': 'application/json',

        EmpresaId: empresa,
      },
    });
  },
  sendPDFByEmail: ({
    login,
    naoAgruparFotos,
    unidadeManutencao,
    empresa,
    dataPrevistaInicial,
    dataPrevistaFinal,
    dataRealizadaInicial,
    dataRealizadaFinal,
    sistemas,
    custos,
    materiaisUtilizados,
    assinaturas,
    medicoes,
    justificativaAtividadesNaoRealizadas,
    fotos,
    planoAtividades,
    status,
    umaAplicacaoPagina,
    fornecedores,
    executores,
    gruposAreas,
    subGrupoAreas,
    areas,
    tipoEquipamentos,
    planosInativos,
    equipmentos,
  }) => {
    return AxiosReportLas({
      method: 'post',
      url: '/v1/Relatorios/RelatorioAtividadepdf',
      data: {
        login,
        email: login,
        naoAgruparFotos,
        unidadeManutencao,
        empresa,
        dataPrevistaInicial,
        dataPrevistaFinal,
        dataRealizadaInicial,
        dataRealizadaFinal,
        sistemas,
        custos,
        materiaisUtilizados,
        assinaturas,
        medicoes,
        justificativaAtividadesNaoRealizadas,
        fotos,
        planoAtividades,
        status,
        fornecedores,
        executores,
        gruposAreas,
        subGrupoAreas,
        areas,
        tipoEquipamentos,
        planosInativos,
        equipmentos,
        umaAplicacaoPagina,
      },
      headers: {
        'Content-Type': 'application/json',
        EmpresaId: empresa,
      },
    });
  },
  sendPDFSummaryByEmail: ({
    login,
    unidadeManutencao,
    naoAgruparFotos,
    empresa,
    dataPrevistaInicial,
    dataPrevistaFinal,
    dataRealizadaInicial,
    dataRealizadaFinal,
    sistemas,
    custos,
    materiaisUtilizados,
    assinaturas,
    medicoes,
    justificativaAtividadesNaoRealizadas,
    fotos,
    planoAtividades,
    status,

    fornecedores,
    executores,
    gruposAreas,
    subGrupoAreas,
    areas,
    tipoEquipamentos,
    equipmentos,
    planosInativos,
  }) => {
    return AxiosReportLas({
      method: 'post',
      url: '/v1/Relatorios/RelatorioAtividadeResumido',
      data: {
        login,
        email: login,
        naoAgruparFotos,
        unidadeManutencao,
        empresa,
        dataPrevistaInicial,
        dataPrevistaFinal,
        dataRealizadaInicial,
        dataRealizadaFinal,
        sistemas,
        custos,
        materiaisUtilizados,
        assinaturas,
        medicoes,
        justificativaAtividadesNaoRealizadas,
        fotos,
        planoAtividades,
        status,
        fornecedores,
        executores,
        gruposAreas,
        subGrupoAreas,
        areas,
        tipoEquipamentos,
        equipmentos,
        planosInativos,
      },
      headers: {
        'Content-Type': 'application/json',

        EmpresaId: empresa,
      },
    });
  },
};

export { ActivitiesServices };
