import styled from 'styled-components';

import { DefaultRow } from 'assets/styled/sheets/components/Forms';

export const StyledFieldRow = styled.div`
  ${DefaultRow}
  display: flex;
  justify-content: center;
  margin-left: ${props => (props.marginSides ? props.marginSides : '0')};
  margin-right: ${props => (props.marginSides ? props.marginSides : '0')};
  margin-top: ${props => (props.marginTop ? props.marginTop : '0')};
`;
