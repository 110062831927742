import { previewAuditReportFactory } from './auditReport';
import { previewOccurrenceReportFactory } from './occurrenceReport';

const previewFactory = ({ reportName, reportType, params }) => {
  switch (reportName) {
    case 'audit':
      return previewAuditReportFactory({ reportType, params });
    case 'occurrence':
      return previewOccurrenceReportFactory({ reportType, params });
    default:
      return [];
  }
};

export { previewFactory };
