import React from 'react';
import PropTypes from 'prop-types';

import { StyledFieldRow } from './styled';

const FieldRow = props => {
  const { marginTop, marginBottom, children } = props;

  return (
    <StyledFieldRow marginTop={marginTop} marginBottom={marginBottom} {...props}>
      {children}
    </StyledFieldRow>
  );
};

FieldRow.propTypes = {
  marginTop: PropTypes.string,
  marginBottom: PropTypes.string,
};

export { FieldRow };
