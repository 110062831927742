import {
  UPDATE_VIEWPORT,
  UPDATE_FILTERED_UNIT_MARKERS,
  UPDATE_UNIT_MARKERS_FILTER,
  CLEAR_UNIT_MARKERS_FILTERS,
  UPDATE_UNIT_MARKERS_FILTER_FIELDS,
  TOGGLE_UNIT_MARKERS_FILTERS_MODAL,
  ORDER_UNIT_MARKERS_BY_ACTIVITY_COUNT,
  UPDATE_MARKERS,
  TOGGLE_ROUTES_REPORT_MODAL,
  TOGGLE_IS_OFFLINE_TECHNICIANS_HIDDEN,
  TOGGLE_IS_ONLINE_TECHNICIANS_HIDDEN,
  UPDATE_TECHNICIAN_MARKERS_SEARCH_TERM,
  UPDATE_SIDEBAR_ROUTE_SUBVIEW_TECHNICIAN,
  TOGGLE_HIDE_UNITS_WITHOUT_OCCURRENCES,
  UNITS_WITH_OCCURRENCES_SUBFILTER,
  SHOW_TEAM_DETAILS,
  HIDE_TEAM_DETAILS,
  UPDATE_TEAM_MARKERS_SEARCH_TERM,
} from './constants';
import { getFilteredUnitMarkers } from './utils';
// TODO add missing initial state values
export const initialState = {
  viewport: {
    latitude: -25.480877,
    longitude: -49.304424,
    zoom: 12,
  },
  isLoading: false,
  isDownloadRouteReportsModalActive: false,
  sidebarRouteSubviewTechnician: undefined,
  isUnitsFiltersModalActive: false,
  unitMarkers: undefined,
  unitMarkersFiltersFields: {
    unitGroups: undefined,
    orderUnitsBy: ORDER_UNIT_MARKERS_BY_ACTIVITY_COUNT,
    // Analisada, Não analisada e Solucionada
    occurrenceStatuses: ['1', '2', '3'],
  },
  unitMarkersFilters: {
    unitGroupId: undefined,
    unitSearchTerm: undefined,
    isUnitsWithoutOccurrencesHidden: false,
    unitsWithOccurrencesSubfilter: '',
  },
  technicianMarkers: {
    searchTerm: '',
    isOnlineHidden: false,
    isOfflineHidden: false,
    isFetching: false,
    error: undefined,
    data: [],
  },
  teamMarkers: {
    searchTerm: '',
    teamDetails: {
      showTeamDetails: false,
      teamData: undefined,
    },
    data: [],
  },
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_VIEWPORT:
      return {
        ...state,
        viewport: {
          ...state.viewport,
          ...action.payload,
        },
      };
    case UPDATE_MARKERS:
      const newData = action.payload.data;
      const newTechnicianMarkers = newData?.technicianMarkers;
      const newUnitMarkers = newData?.units;
      const newTeamsMarkers = newData?.teams;

      const nextState = { ...state };
      const extras = { error: action.payload.error, status: action.payload.status };

      nextState.technicianMarkers = {
        ...state.technicianMarkers,
        ...extras,
        data: newTechnicianMarkers,
      };
      nextState.unitMarkers = {
        ...state.unitMarkers,
        ...extras,
        data: newUnitMarkers,
      };
      nextState.teamMarkers = {
        ...state.teamMarkers,
        ...extras,
        data: newTeamsMarkers,
      };

      return nextState;

    case TOGGLE_IS_OFFLINE_TECHNICIANS_HIDDEN:
      return {
        ...state,
        technicianMarkers: {
          ...state.technicianMarkers,
          isOfflineHidden: !state.technicianMarkers.isOfflineHidden,
        },
      };

    case TOGGLE_IS_ONLINE_TECHNICIANS_HIDDEN:
      return {
        ...state,
        technicianMarkers: {
          ...state.technicianMarkers,
          isOnlineHidden: !state.technicianMarkers.isOnlineHidden,
        },
      };
    case UPDATE_TECHNICIAN_MARKERS_SEARCH_TERM:
      return {
        ...state,
        technicianMarkers: {
          ...state.technicianMarkers,
          searchTerm: action.payload,
        },
      };
    case UPDATE_SIDEBAR_ROUTE_SUBVIEW_TECHNICIAN:
      return {
        ...state,
        sidebarRouteSubviewTechnician: action.payload,
      };
    case TOGGLE_ROUTES_REPORT_MODAL:
      return {
        ...state,
        isDownloadRouteReportsModalActive: !state.isDownloadRouteReportsModalActive,
      };
    case TOGGLE_UNIT_MARKERS_FILTERS_MODAL:
      return {
        ...state,
        isUnitsFiltersModalActive: !state.isUnitsFiltersModalActive,
      };
    case UPDATE_FILTERED_UNIT_MARKERS:
      const filteredMarkers = getFilteredUnitMarkers({
        unitMarkers: state.unitMarkers,
        unitMarkersFilters: state.unitMarkersFilters,
      });

      return {
        ...state,
        filteredMarkers: filteredMarkers,
      };

    case TOGGLE_HIDE_UNITS_WITHOUT_OCCURRENCES:
      return {
        ...state,
        unitMarkersFilters: {
          ...state.unitMarkersFilters,
          isUnitsWithoutOccurrencesHidden: !state.unitMarkersFilters.isUnitsWithoutOccurrencesHidden,
          unitsWithOccurrencesSubfilter: '',
        },
      };
    case UNITS_WITH_OCCURRENCES_SUBFILTER:
      return {
        ...state,
        unitMarkersFilters: {
          ...state.unitMarkersFilters,
          unitsWithOccurrencesSubfilter: action.payload,
        },
      };
    case UPDATE_UNIT_MARKERS_FILTER_FIELDS:
      return {
        ...state,
        unitMarkersFiltersFields: action.payload,
      };
    case UPDATE_UNIT_MARKERS_FILTER:
      return {
        ...state,
        unitMarkersFilters: {
          ...state.unitMarkersFilters,
          [action.payload.name]: action.payload.value,
        },
      };
    case SHOW_TEAM_DETAILS:
      return {
        ...state,
        teamMarkers: {
          ...state.teamMarkers,
          teamDetails: {
            showTeamDetails: true,
            teamData: action.payload,
          },
        },
      };
    case HIDE_TEAM_DETAILS:
      return {
        ...state,
        teamMarkers: {
          ...state.teamMarkers,
          searchTerm: '',
          teamDetails: {
            showTeamDetails: false,
            teamData: undefined,
          },
        },
        technicianMarkers: {
          ...state.technicianMarkers,
          searchTerm: '',
        },
        unitMarkersFilters: {
          ...state.unitMarkersFilters,
          unitSearchTerm: undefined,
        },
      };
    case UPDATE_TEAM_MARKERS_SEARCH_TERM:
      return {
        ...state,
        teamMarkers: {
          ...state.teamMarkers,
          searchTerm: action.payload,
        },
      };
    case CLEAR_UNIT_MARKERS_FILTERS:
      return {
        ...state,
        unitMarkersFilters: {},
      };
    default:
      throw new Error();
  }
};
