import React from 'react';
import { Marker } from '@urbica/react-map-gl';

const MapMarker = ({
  id,
  latitude,
  longitude,
  onClick = () => {},
  className,
  children,
  offsetLeft = 0,
  offsetTop = 0,
}) => {
  return (
    <Marker
      key={id}
      onClick={onClick}
      latitude={latitude}
      longitude={longitude}
      className={className}
      offsetLeft={offsetLeft}
      captureScroll={true}
      offsetTop={offsetTop}
    >
      {children}
    </Marker>
  );
};

export default React.memo(MapMarker);
