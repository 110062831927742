import React from 'react';
import { useParams } from 'react-router-dom';
import { useBoolean } from 'hooks';
import { fetcher } from 'services';
import { getErrorMessageByStatusCode } from 'utils';
import { LkpChart } from 'components/Charts/Chart';
import { ChartsParamsType } from 'components/Charts/types';
import { LkpChartTopFilter } from 'components/Charts/Filters';
import { HeaderFiltersType } from 'context/GlobalFiltersContext/initialState';
export interface LkpChartNumberOccurrencesByPriorityProps {
  headerFilters: HeaderFiltersType;
}
const LkpChartNumberOccurrencesByPriority: React.FC<LkpChartNumberOccurrencesByPriorityProps> = ({ headerFilters }) => {
  const { value: isLoading, setValue: setLoading } = useBoolean();
  const { dateFrom, dateTo } = useParams<{ dateFrom: string; dateTo: string }>();
  const { value: isError, setValue: setIsError } = useBoolean();
  const [series, setSeries] = React.useState<any[]>([]);
  const [labels, setLabels] = React.useState<any[]>([]);
  const [errorMessage, setErrorMessage] = React.useState<string>();
  const [paramsSystems] = React.useState<string>('');
  const [params, setParams] = React.useState<ChartsParamsType>({
    Empresa: headerFilters?.companies?.id || 0,
    Sites: headerFilters?.units?.id,
    Inicio: dateFrom,
    Fim: dateTo,
  });
  const handleChangeStartDate = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value: Inicio } = event.target;
    setParams({ ...params, Inicio });
  };
  const handleChangeEndDate = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value: Fim } = event.target;
    setParams({ ...params, Fim });
  };
  const fetchChartData = async () => {
    setLoading(true);
    let _httpPath = '/ocorrencias-por-prioridade-por-periodo';
    if (!!paramsSystems) _httpPath = `${_httpPath}?Sistemas=${paramsSystems}`;
    const result = await fetcher.get<any[]>(_httpPath, 'dashboard', { params }).catch(error => {
      const message = getErrorMessageByStatusCode(error, _httpPath);
      setErrorMessage(message);
      setIsError(true);
    });
    if (result) {
      setLabels(
        result.map(({ nome }) => {
          if (!nome) return 'Sem prioridade';
          return nome;
        }),
      );
      setSeries(result.map(({ quantidade }) => (typeof quantidade === 'string' ? parseInt(quantidade) : 0)));
    }
    setLoading(false);
  };
  React.useEffect(() => {
    (async () => {
      await fetchChartData();
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <LkpChart
      title="Quantidade de ocorrências cadastradas por prioridade"
      type="donut"
      companyId={headerFilters?.companies?.id || 0}
      series={series}
      error={isError}
      //width={830}
      errorMessage={errorMessage}
      loading={isLoading}
      options={{
        labels,
        colors: ['#00C0C7', '#5144D3', '#E8871A', '#DA3490', '#9089FA', '#47E26F'],
        dataLabels: {
          formatter: function (value, { seriesIndex, w }) {
            return w.config.series[seriesIndex];
          },
        },
      }}
      filter={<LkpChartTopFilter onChangeStartDate={handleChangeStartDate} onChangeEndDate={handleChangeEndDate} />}
    ></LkpChart>
  );
};
export { LkpChartNumberOccurrencesByPriority };
