import styled from 'styled-components';
import { colors, fonts } from 'assets/styled/tokens';
import { FormGroup } from 'components/Form/subcomponents/FormGroup';
import { StyledInputTooltip } from 'components/Form/subcomponents/InputTooltip/styled';

export const StyledExecutedByProviderHeader = styled.div`
  display: inline-flex;
  align-items: center;
  flex-direction: row;
  white-space: nowrap;
  font-size: 12px;
  line-height: 14px;
`;
export const StyledExecutedByProviderFormGroup = styled(FormGroup)`
  display: flex;
  /* align tooltip */

  ${StyledInputTooltip} {
    transform: translate(-110px, -4px);
  }
  label {
    margin-bottom: 0;
    font-weight: 400;
    color: ${colors.gray};
    margin-left: 9px;
    line-height: 14px;
    font-family: ${fonts.family.text};
  }
  label:nth-child(1) {
    color: ${colors.carbon};
    font-weight: 500;
    margin-left: 0;
    font-size: 12px;
  }
  label:nth-child(2) {
    margin-right: 16px;
    margin-left: 24px;
  }
`;
export const StyledApplicationType = styled.span`
  font-weight: 400;
  color: ${colors.gray};
`;
