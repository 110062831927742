import * as React from 'react';

import { HeaderFiltersType } from 'context/GlobalFiltersContext/initialState';
import { LkpChart } from '../Chart';
import { getStackedChartOptions } from './options';
import { useBoolean, useDebounce } from 'hooks';
import { ChartSeriesType, ChartsParamsType } from '../types';
import { ChartComparativeBetweenWeeksType } from './types';
import { fetcher } from 'services';
import { LkpChartTopFilter } from '../Filters';
import { isNumber } from 'fp-ts/lib/number';
import { getErrorMessageByStatusCode } from 'utils';
import { useParams } from 'react-router-dom';

export interface LkpChartComparativeBetweenWeeksProps {
  headerFilters: HeaderFiltersType;
}

const HTTP_PATH = '/entrada-saida-ocorrencias-por-periodo';

const DEFAULT_SERIES = [
  { name: 'Entraram', data: [] },
  { name: 'Saíram', data: [] },
];

export function LkpChartComparativeBetweenWeeks({
  headerFilters,
}: LkpChartComparativeBetweenWeeksProps): React.ReactElement {
  const { value: isLoading, setValue: setLoading } = useBoolean();
  const { value: isError, setValue: setIsError } = useBoolean();
  const [errorMessage, setErrorMessage] = React.useState<string>();
  const { dateFrom, dateTo } = useParams<{ dateFrom: string; dateTo: string }>();

  const [series, setSeries] = React.useState<Array<ChartSeriesType>>(DEFAULT_SERIES);
  const [period, setPeriod] = React.useState<Array<string>>([]);
  const [params, setParams] = React.useState<ChartsParamsType>({
    Empresa: headerFilters?.companies?.id || 0,
    Sites: headerFilters?.units?.id,
    Inicio: dateFrom,
    Fim: dateTo,
  });

  const debouncedStartValue = useDebounce<string>(params?.Inicio || '', 500);
  const debouncedEnvValue = useDebounce<string>(params?.Fim || '', 500);

  React.useEffect(() => {
    (async () => {
      await fetchChartData();
    })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedStartValue, debouncedEnvValue]);

  const fetchChartData = async () => {
    setLoading(true);

    const result = await fetcher
      .get<Array<ChartComparativeBetweenWeeksType>>(HTTP_PATH, 'dashboard', { params })
      .catch(error => {
        const message = getErrorMessageByStatusCode(error, HTTP_PATH);
        setErrorMessage(message);
        setIsError(true);
      });

    if (result) {
      const _entries = result.map(({ entrada }) => (isNumber(Number(entrada)) ? Number(entrada) : 0));
      const _exities = result.map(({ saida }) => (isNumber(Number(saida)) ? Number(saida) : 0));
      const _series = [
        { name: 'Entraram', data: _entries },
        { name: 'Saíram', data: _exities },
      ];

      setSeries(_series);
      setPeriod(result.map(({ periodo }) => periodo || ''));
    }

    setLoading(false);
  };

  const handleChangeStartDate = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value: Inicio } = event.target;
    setParams({ ...params, Inicio });
  };

  const handleChangeEndDate = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value: Fim } = event.target;
    setParams({ ...params, Fim });
  };

  return (
    <React.Fragment>
      <LkpChart
        title="Entrada e Saída de Ocorrências comparativo entre semanas"
        companyId={headerFilters?.companies?.id || 0}
        series={series}
        //width={1700}
        options={getStackedChartOptions(period, {
          chart: {
            type: 'bar',
            height: 350,
            toolbar: {
              show: false,
            },
          },
        })}
        error={isError}
        errorMessage={errorMessage}
        onChartTryAgain={fetchChartData}
        loading={isLoading}
        filter={<LkpChartTopFilter onChangeStartDate={handleChangeStartDate} onChangeEndDate={handleChangeEndDate} />}
      />
    </React.Fragment>
  );
}
