import { colors, medias } from 'assets/styled/tokens';
import styled, { css } from 'styled-components';
import { Button } from 'components/Button';

export const CleanFiltersButton = styled(Button)`
  margin-right: 20px;
  margin-bottom: 16px;
  margin-top: 16px;
`;

export const StyledReportContainer = styled.div`
  display: block;
  background-color: ${colors.white};
  border-radius: 6px;
  box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.1);

  display: grid;
  grid-template-areas:
    'header header'
    'main main'
    'footer footer';
  grid-template-columns: 1fr;
`;

export const StyledHeader = styled.header`
  grid-area: header;
  width: 100%;
  border: 0 solid ${colors.whiteGray};
  border-bottom-width: 1px;

  @media screen and (min-width: ${medias.desktopMd}) {
    justify-self: center;
  }
`;

export const StyledMain = styled.div`
  grid-area: main;
  justify-self: center;
  padding: 30px 0 40px;
`;

export const StyledFooter = styled.footer`
  grid-area: footer;
  padding: 22px 10px;
  border-top: 1px solid ${colors.whiteGray};
`;

const StyledContainerGrid = css`
  display: inline-grid;
  grid-template-columns: fit-content(100%);
  column-gap: 24px;
  align-items: center;
`;

export const StyledContainerHeader = styled.div`
  ${StyledContainerGrid};
  justify-content: flex-end;
  grid-template-columns: 0 1fr 0 1fr 0;

  div {
    button {
      &:first-child {
        transition: all 0.2s;
        &:hover {
          color: ${colors.white};
          background: ${colors.bittersweet};
          border-color: ${colors.bittersweet};
        }
        svg {
          margin-right: 8px;
        }
      }
    }
  }

  svg {
    overflow: visible;
    cursor: pointer;
  }

  @media screen and (min-width: ${medias.tablet}) {
    width: 100%;
    grid-template-columns: 0 1fr 0 1fr 0;
  }

  @media screen and (min-width: ${medias.desktopMd}) {
    column-gap: 0;
    //grid-template-columns: 72px 400px 72px 505px 72px;
  }
`;

export const StyledContainerMain = styled.div`
  ${StyledContainerGrid};

  @media screen and (min-width: ${medias.desktopSm}) {
    grid-template-columns: 0 40% 4% 46% 0;
  }

  @media screen and (min-width: ${medias.desktopMd}) {
    //grid-template-columns: 72px 395px 72px 460px 72px;
  }
`;

export const StyledContainerFooter = styled.div`
  ${StyledContainerGrid};
  justify-content: center;

  @media screen and (min-width: ${medias.tablet}) {
    grid-template-columns: 400px 520px;
  }

  @media screen and (min-width: ${medias.desktopMd}) {
  }
`;

export const StyledContentWrapper = styled.div`
  width: 100%;
  display: ${props => props.display || 'flex'};
  flex-direction: ${props => props.flexDirection || 'row'};
  justify-content: ${props => props.justifyContent};
  align-items: ${props => props.alignItems};
  grid-column-start: ${props => props.gridColumnStart};
`;

export const StyledTitle = styled.h3`
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  font-family: 'Montserrat';
  font-size: 1rem;
  color: ${colors.primary};
  margin-left: 16px;
  margin-top: 18px;
  margin-bottom: 18px;
`;

export const StyledActions = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  & > div {
    margin-right: 16px;
  }

  & > button {
    min-width: 153px;
  }
`;

export const StyledCurrentFilterActive = styled.p`
  font-weight: 500;
  font-size: 0.75rem;
  color: ${colors.tundora};

  span {
    position: relative;
    font-size: 12px;
    line-height: 16px;
    margin-left: 8px;
    color: ${colors.gray};

    border: 1px solid ${colors.gray};
    border-radius: 3px;
    padding: 4px 24px 4px 8px;

    svg {
      position: absolute;
      top: 8px;
      right: 8px;
      display: block;
      width: 8px;
      height: 8px;
      cursor: pointer;
    }
  }
`;
