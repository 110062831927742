import styled from 'styled-components';

import { Link } from 'react-router-dom';
import { Button } from 'components/Button';
import TabHeader from 'components/TabHeader';
import { colors } from 'assets/styled/tokens';

export const StyledTabHeader = styled(TabHeader)`
  padding: 1rem 24px 0;
`;
export const StyledTableContainer = styled.main`
  display: flex;
  box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.1);
  background: white;
  flex-direction: column;
  border-radius: 8px;
`;
export const StyledTableInnerContainer = styled.div`
  width: 100%;
  padding: 24px;
`;
export const StyledAddPlanButtonWrapper = styled(Link)`
  margin-bottom: 16px;
`;
export const StyledAddPlanButton = styled(Button)`
  svg {
    margin-right: 8px;
  }
`;
export const StyledEmptyPlansContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  > svg {
    margin-top: 16px;
  }
`;
export const StyledEmptyPlansHeader = styled.h2`
  font-size: 18px;
  font-weight: 600;
  line-height: 21px;
  color: ${colors.mediumGray};
  margin-top: 16px;
  margin-bottom: 24px;
`;

export const StyledEmptyPlansAddPlanButton = styled(StyledAddPlanButton)`
  height: 32px;
  margin-bottom: 24px;
  font-weight: 500;
  font-size: 12px;
  a {
    color: white;
  }
`;
export const StyledNewPlanPageContainer = styled.div``;
