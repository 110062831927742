import axios from 'axios';
import createAuthRefreshInterceptor from 'axios-auth-refresh';
import { refreshAuthLogic } from 'services/main';
import { addBearerTokenInReq } from 'services/main/interceptors/addBearerTokenInReq';

const AxiosReports = axios.create({
  baseURL: process.env.REACT_APP_REPORTS_API,
  //baseURL: process.env.REACT_APP_LIGHTHOUSE_FILTERS_API,
});

const AxiosReportLas = axios.create({
  baseURL: process.env.REACT_APP_LAS,
});

const AxiosGlobalFilters = axios.create({
  baseURL: process.env.REACT_APP_LIGHTHOUSE_FILTERS_API,
});

AxiosGlobalFilters.interceptors.request.use(addBearerTokenInReq);
AxiosReports.interceptors.request.use(addBearerTokenInReq);
AxiosReportLas.interceptors.request.use(addBearerTokenInReq);
createAuthRefreshInterceptor(AxiosGlobalFilters, refreshAuthLogic);
createAuthRefreshInterceptor(AxiosReports, refreshAuthLogic);
createAuthRefreshInterceptor(AxiosReportLas, refreshAuthLogic);

export { AxiosReports, AxiosGlobalFilters, AxiosReportLas };
