import * as React from 'react';
import slugify from 'slugify';
import { LkpDropdown } from 'components/Dropdown';
import { toggleAllItems, toggleItem } from './helpers';
import { LkpDropdownMenuItemProps } from 'components/Dropdown/Menu';
import { useHeaderFilters } from 'context';

export interface LkpFilterSystemsProps {
  onSelectSystems?: (systemsParams: string) => void; // systemsParams = 'Sistemas=123&Sistemas=123&Sistemas=123'
}

const DEFAULT_ITEMS: Array<LkpDropdownMenuItemProps> = [
  { id: '__SelecionarTodos', label: 'Selecionar todos', selectAll: true, selected: false },
];

export function LkpFilterSystems({ onSelectSystems }: LkpFilterSystemsProps): React.ReactElement {
  const [localSystems, setItemsSystems] = React.useState<Array<LkpDropdownMenuItemProps>>([]);
  const [selectedSystems, setSelectedSystems] = React.useState<Array<LkpDropdownMenuItemProps>>([]);
  const {
    headerFiltersContext: {
      headerFilters: { systems: filterSystems },
    },
  } = useHeaderFilters();

  React.useEffect(() => {
    if (filterSystems) {
      const newItems: Array<LkpDropdownMenuItemProps> = filterSystems.map((system: LkpDropdownMenuItemProps) => ({
        ...system,
        id: slugify(`${system.nome} ${system.sistemaEmpresa}`, {
          replacement: '__',
          lower: true,
        }),
        label: system.nome,
        selected: false,
      }));

      setItemsSystems([...DEFAULT_ITEMS, ...newItems]);
    }
  }, [filterSystems]);

  React.useEffect(() => {
    if (onSelectSystems && localSystems && localSystems.length > 0) {
      let _systems = localSystems
        .filter(({ id }) => id !== '__SelecionarTodos')
        .filter(({ selected }) => !!selected)
        .map(({ sistemaEmpresa }) => {
          return [sistemaEmpresa];
        })
        .join('&Sistemas=');

      if (_systems) _systems = `Sistemas=${_systems}`;
      onSelectSystems(_systems);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localSystems]);

  const handleToogleAllSystems = (selected = true) => {
    const _newItems = toggleAllItems({ items: localSystems, toggle: selected });

    setItemsSystems(_newItems);
    setSelectedSystems(_newItems.filter(item => item.selected));
  };

  const handleSelectSystem = (itemId: string) => {
    let _newItems: Array<LkpDropdownMenuItemProps> = [];
    let _selectedItems: Array<LkpDropdownMenuItemProps> = [];

    if (localSystems) {
      _newItems = toggleItem({ items: localSystems, toggleItem: itemId });
      _selectedItems = _newItems.filter(item => item.selected);
    }

    setItemsSystems(_newItems);
    setSelectedSystems(_selectedItems);
  };

  return (
    <React.Fragment>
      {onSelectSystems && localSystems && localSystems.length > 1 && (
        <LkpDropdown onChangeItem={handleSelectSystem} onToggleAll={handleToogleAllSystems} items={localSystems}>
          {selectedSystems.length <= 0 && `Todos os sistemas`}
          {selectedSystems.length === 1 && `1 Sistema`}
          {selectedSystems.length > 1 &&
            `${
              selectedSystems.length === localSystems.length ? selectedSystems.length - 1 : selectedSystems.length
            } Sistemas`}
        </LkpDropdown>
      )}
    </React.Fragment>
  );
}
