import React, { useState, useContext, useCallback, useEffect, forwardRef } from 'react';
import { useCookies } from 'react-cookie';

import { GlobalComponentsContext } from 'context/GlobalComponentsContext';
import { FiltersContext, initialState } from 'context/GlobalFiltersContext';

import { Button } from 'components/Button';
import { FilterModal } from 'components/FilterModal';
import { FilterBadge } from 'components/FilterBadge';

import { getDependentFilters } from 'components/HeaderFilters/getDependentFilters';

import { AuthService, HeaderFilterService } from 'services/main';

import {
  companies,
  unitGroups,
  unitSubgroups,
  units,
  states,
  cities,
  // teams,
  providers,
  filtersToUnmark,
} from './constants';

import { StyledFiltersContainer, StyledButtonFilter, StyledFilterColumn } from './styled';
import { localStorageKeys } from 'constants/localStorage';
import { getLocalAuthStorage } from 'utils/localStorage';
import { URLS } from 'constants/urls';
import { useHistory } from 'react-router-dom';
import jwt from 'jwt-decode';
const HeaderFilters = forwardRef((props, ref) => {
  const { globalComponentsContext, setGlobalComponentsContext } = useContext(GlobalComponentsContext);
  const { headerFiltersContext, setFiltersContext } = useContext(FiltersContext);
  // TODO HOIST THIS INTO CONSTANT
  const [cookies, setCookie, removeCookie] = useCookies([
    'companies',
    'units',
    'unitGroups',
    'unitSubgroups',
    'states',
    'cities',
    'providers',
  ]);

  const { isFiltersOpen } = globalComponentsContext;
  const { headerFilters } = headerFiltersContext;

  const [currentFilter, setCurrentFilter] = useState(null);

  const [modalOptions, setModalOptions] = useState({
    open: false,
    propertyKey: null,
  });

  const { user, token } = getLocalAuthStorage();

  const isCommonUser = user?.permissionType === `user`;

  useEffect(() => {
    const handleClickOutside = e => {
      if (ref.current && ref.current.contains(e.target)) {
        // inside click
        return;
      }
      setTimeout(() => {
        setGlobalComponentsContext({
          ...globalComponentsContext,
          isFiltersOpen: !isFiltersOpen,
        });
      }, 300);
    };

    if (isFiltersOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [setGlobalComponentsContext, globalComponentsContext, isFiltersOpen, ref]);

  const closeModal = () => {
    setModalOptions({
      open: false,
    });
  };

  const onSelectFilter = async itemSelected => {
    const activeFilters = headerFilters;
    filtersToUnmark[currentFilter].forEach(filter => {
      activeFilters[filter] = false;
    });
    const dependentsFilter = await getDependentFilters({
      filterType: currentFilter,
      filterResponse: itemSelected,
    });
    if (dependentsFilter && dependentsFilter.length > 0) {
      dependentsFilter.forEach(df => {
        activeFilters[df.filter] = df.value;
      });
    }

    setCookie(
      currentFilter,
      {
        id: String(itemSelected.id),
        name: '',
      },
      { path: '/', domain: URLS.cookieDomain },
    );

    activeFilters[currentFilter] = { ...itemSelected };

    const getToken = JSON.parse(localStorage.getItem(localStorageKeys.authContext));
    if (activeFilters[companies] && activeFilters[units] && units === currentFilter) {
      setCurrentFilter(null);
      await AuthService.refreshSession({
        empresaId: activeFilters[companies].id,
        unidadeId: activeFilters[units].id,
        token: getToken.token,
      });
    }
    setFiltersContext({
      ...headerFiltersContext,
      headerFilters: activeFilters,
    });
    closeModal();

    //window.location.reload();
  };

  const removeFilters = useCallback(
    filtersToUnmark => {
      const activeFilters = headerFilters;
      filtersToUnmark.forEach(filter => {
        removeCookie(filter, { path: '/' });
        activeFilters[filter] = false;
      });

      setFiltersContext({
        ...headerFiltersContext,
        headerFilters: activeFilters,
      });
      //window.location.reload();
    },
    [headerFilters, headerFiltersContext, removeCookie, setFiltersContext],
  );

  const getModalOptionsData = async () => {
    try {
      const request = HeaderFilterService[currentFilter] || false;
      const { data } = await request({
        empresaId: headerFilters?.companies?.id,
        grupoUnidadeId: headerFilters?.unitGroups?.id,
        subGrupoUnidadeId: headerFilters?.unitSubgroups?.id,
        unidadeId: headerFilters?.units?.id,
        estadoId: headerFilters?.states?.id,
        cidadeId: headerFilters?.cities?.id,
        // equipeId: headerFilters?.teams?.id,
        fornecedorId: headerFilters?.providers?.id,
      });
      return data;
    } catch (err) {
      return;
    }
  };
  const history = useHistory();
  const getCompanyName = async id => {
    return new Promise(async (resolve, reject) => {
      HeaderFilterService.company(id)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  };

  // TODO REFACTOR FOLLOWING CODE
  const updateHeaderFiltersByCookies = useCallback(async () => {
    const getUnitName = async (companyId, unitId) => {
      return new Promise(async (resolve, reject) => {
        HeaderFilterService.unit(companyId, unitId)
          .then(res => {
            resolve(res.data);
          })
          .catch(err => {
            reject(err);
          });
      });
    };
    const { comps } = jwt(token);
    const data = JSON.parse(comps);
    let savedCompany = parseInt(cookies?.companies?.id) || data[0]?.Id;
    //let savedCompany = cookies.companies;
    let savedUnit = parseInt(cookies?.units?.id); /*|| data[0]?.Units[0];*/

    const savedCookieCompanyId = savedCompany ? savedCompany : undefined;
    //const savedCookieCompanyId = savedCompany ? parseInt(savedCompany.id) : undefined;
    const savedCookieUnitId = savedUnit ? savedUnit : undefined;
    let updatedHeaderFilters = headerFiltersContext?.headerFilters;
    const isCompanyUpdatePending =
      savedCookieCompanyId && savedCookieCompanyId !== headerFiltersContext?.headerFilters?.companies?.id;
    const isUnitUpdatePending =
      savedCookieCompanyId && savedCookieUnitId && savedCookieUnitId !== headerFiltersContext?.headerFilters?.units?.id;

    try {
      if (!savedCookieCompanyId) {
        updatedHeaderFilters.companies = false;
      } else if (isCompanyUpdatePending) {
        const fetchedCompany = await getCompanyName(savedCookieCompanyId);
        updatedHeaderFilters.companies = {
          id: savedCookieCompanyId,
          name: fetchedCompany.nomeFantasia,
          logoPath: fetchedCompany.logomarcaPath,
        };
      }
      if (!savedCookieUnitId) {
        updatedHeaderFilters.units = false;
      } else if (isUnitUpdatePending) {
        const fetchedUnit = await getUnitName(savedCookieCompanyId, savedCookieUnitId);
        updatedHeaderFilters.units = {
          id: savedCookieUnitId,
          name: fetchedUnit.nome,
          logoPath: fetchedUnit.logomarcaPath,
        };
      }
      setFiltersContext(headerFiltersContext => ({
        ...headerFiltersContext,
        headerFilters: {
          ...headerFiltersContext?.headerFilters,
          ...updatedHeaderFilters,
        },
      }));
    } catch (err) {
      history.push('/logout');
      setFiltersContext(initialState);
    }
    // eslint-disable-next-line
  }, []);
  // use saved header filters effect

  useEffect(() => {
    updateHeaderFiltersByCookies();
  }, [updateHeaderFiltersByCookies]);
  return (
    <div ref={ref}>
      <StyledFiltersContainer isOpen={isFiltersOpen}>
        <StyledButtonFilter>
          <Button
            theme="white"
            size="small"
            data-cy="toggle-filters-button"
            onClick={() =>
              setGlobalComponentsContext({
                ...globalComponentsContext,
                isFiltersOpen: !isFiltersOpen,
              })
            }
          >
            {isFiltersOpen ? '-' : '+'} Filtros
          </Button>
        </StyledButtonFilter>

        <StyledFilterColumn>
          <FilterBadge
            label="Empresa"
            currentValue={headerFilters?.companies?.name}
            data={headerFilters?.companies?.id}
            disableOpen={isCommonUser}
            disableDelete={true}
            id="select-company"
            onClick={() => {
              setCurrentFilter(() => companies);
              setModalOptions(() => ({
                title: 'Selecionar - empresas',
                open: true,
                propertyKey: 'name',
              }));
            }}
            isOpen={isFiltersOpen}
          />

          <FilterBadge
            label="Grupos de Unidades de Manutenção"
            currentValue={headerFilters?.unitGroups?.name}
            data={headerFilters?.unitGroups?.id}
            onClick={() => {
              setCurrentFilter(() => unitGroups);
              setModalOptions(() => ({
                title: 'Selecionar - grupos',
                open: true,
                propertyKey: 'name',
              }));
            }}
            onClickX={() => removeFilters(filtersToUnmark.unitGroups)}
            isOpen={isFiltersOpen}
          />

          <FilterBadge
            label="Subgrupos de Unidades"
            currentValue={headerFilters?.unitSubgroups?.name}
            data={headerFilters?.unitSubgroups?.id}
            onClick={() => {
              setCurrentFilter(() => unitSubgroups);
              setModalOptions(() => ({
                title: 'Selecionar - subgrupos',
                open: true,
                propertyKey: 'name',
              }));
            }}
            onClickX={() => removeFilters(filtersToUnmark.unitSubgroups)}
            isOpen={isFiltersOpen}
          />

          <FilterBadge
            label="Unidades de Manutenção"
            currentValue={headerFilters?.units?.name}
            id="select-maintenance-unit"
            data={headerFilters?.units?.id}
            onClick={() => {
              setCurrentFilter(() => units);
              setModalOptions(() => ({
                title: 'Selecionar - unidades',
                open: true,
                propertyKey: 'name',
              }));
            }}
            onClickX={() => removeFilters(filtersToUnmark.units)}
            isOpen={isFiltersOpen}
          />
        </StyledFilterColumn>

        <StyledFilterColumn>
          <FilterBadge
            label="Estado"
            currentValue={headerFilters?.states?.name}
            data={headerFilters?.states?.id}
            onClick={() => {
              setCurrentFilter(() => states);
              setModalOptions(() => ({
                title: 'Selecionar - estados',
                open: true,
                propertyKey: 'name',
              }));
            }}
            onClickX={() => removeFilters(filtersToUnmark.states)}
            isOpen={isFiltersOpen}
          />

          <FilterBadge
            label="Cidade"
            currentValue={headerFilters?.cities?.name}
            data={headerFilters?.cities?.id}
            onClick={() => {
              setCurrentFilter(() => cities);
              setModalOptions(() => ({
                title: 'Selecionar - cidades',
                open: true,
                propertyKey: 'name',
              }));
            }}
            onClickX={() => removeFilters(filtersToUnmark.cities)}
            isOpen={isFiltersOpen}
          />

          {/* <FilterBadge
            label="Equipe"
            currentValue={headerFilters?.teams?.name}
            data={headerFilters?.teams?.id}
            onClick={() => {
              setCurrentFilter(() => teams);
              setModalOptions(() => ({
                title: 'Selecionar - equipes',
                open: true,
                propertyKey: 'name'
              }));
            }}
            onClickX={() => removeFilters(filtersToUnmark.teams)}
            isOpen={isFiltersOpen}
          /> */}

          <FilterBadge
            label="Fornecedores"
            currentValue={headerFilters?.providers?.name}
            data={headerFilters?.providers?.id}
            // TODO  see with team (?)
            // disableDelete={headerFiltersRules.userIsProvider}
            // disableOpen={headerFiltersRules.userIsProvider}
            onClick={() => {
              setCurrentFilter(() => providers);
              setModalOptions(() => ({
                title: 'Selecionar - fornecedores',
                open: true,
                propertyKey: 'name',
              }));
            }}
            onClickX={() => removeFilters(filtersToUnmark.providers)}
            isOpen={isFiltersOpen}
          />
        </StyledFilterColumn>
      </StyledFiltersContainer>
      {modalOptions.open && (
        <FilterModal
          id="headers-filters-modal"
          options={modalOptions}
          closeModal={closeModal}
          onSelect={onSelectFilter}
          getData={getModalOptionsData}
        />
      )}
    </div>
  );
});

export { HeaderFilters };
