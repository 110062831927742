import React from 'react';
import { StyledSubCategoryItem } from './styled';

export const SubCategoryItem = ({ children, disabled, ...props }) => {
  return (
    <StyledSubCategoryItem disabled={disabled} {...props}>
      {children}
    </StyledSubCategoryItem>
  );
};
