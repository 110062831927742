import { colors } from 'assets/styled/tokens';

const { css } = require('styled-components');

const outline = () => css`
  background: ${colors.white};
  border-color: ${colors.primary};
  color: ${colors.primary};
`;
const primary = () => css`
  background: ${colors.primary};
  border-color: ${colors.white};
  color: ${colors.white};
`;

export { outline, primary };
