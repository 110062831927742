const adapt = httpResponse => ({
  ...httpResponse,
  data: httpResponse.data.map(subgrupo => ({
    id: subgrupo.subGrupoUnidadeId,
    name: subgrupo.nome,
    unitGroup: subgrupo.grupoUnidade && {
      id: subgrupo.grupoUnidade.grupoUnidadeId,
      name: subgrupo.grupoUnidade.nome,
    },
  })),
});

const getAllUnitSubgroupsAdapter = httpResponsePromise =>
  httpResponsePromise.then(
    resolve => adapt(resolve),
    reject => reject,
  );

export { getAllUnitSubgroupsAdapter };
