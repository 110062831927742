import { ReactComponent as BatteryIconComponent } from 'assets/icons/battery.svg';
import { ReactComponent as WhatsappIconComponent } from 'assets/icons/whatsapp.svg';
import { colors } from 'assets/styled/tokens';
import { StyledExtraTechniciansCount } from 'pages/Geolocalize/styled';
import styled from 'styled-components';

export const StyledTeamCard = styled.button`
  display: flex;
  flex-direction: column;
  background: white;
  align-items: center;
  justify-content: center;
  border: 1px solid ${colors.whiteGray};
  padding: 14px 16px 10px 16px;
  margin: 6px 0;
  border-radius: 4px;
  &:first-child {
    margin-top: 0;
  }
  &:hover,
  &:focus {
    border-color: #cccccc;
  }
`;

export const StyledBatteryIconComponent = styled(BatteryIconComponent)`
  margin-top: 7px;
`;

export const StyledTeamName = styled.div`
  display: flex;
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  margin-bottom: 16px;
  margin-top: 1px;
`;

export const StyledTeamTechnicians = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 5px;
  ${StyledExtraTechniciansCount} {
    margin-top: 0;
    width: 32px;
    height: 32px;
    margin-top: 1px;
    background: #eae8e5;
    border: none;
    color: ${colors.gray};
  }
  div {
    margin-right: -4.1px;
  }
`;
export const StyledTeamStatus = styled.div`
  font-weight: 400;
  margin-left: 5px;
`;

export const StyledTeamInfoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 8px;
  * {
    color: ${colors.gray};
  }
  > svg {
    margin: 0 8px;
  }
`;
export const StyledOnlineTeamTechniciansTab = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  button {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  span {
    display: flex;
    font-weight: 500;
    font-size: 14px;
    line-height: 15px;
    color: ${colors.darkPrimary};
    margin-left: 4px;
  }
`;
export const StyledOfflineTechniciansTab = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  button {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  span {
    display: flex;
    font-weight: 500;
    font-size: 14px;
    line-height: 15px;
    color: ${colors.gray};
    margin-left: 4px;
  }
`;

export const StyledViewTeamOnMapButton = styled.button`
  display: flex;
  justify-content: center;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: ${colors.darkPrimary};
  box-sizing: border-box;
  border-top: 1px solid ${colors.whiteGray};
  padding-top: 9px;
  margin: 0 -16px 0 -16px;
`;

export const StyledWhatsappIconComponent = styled(WhatsappIconComponent)`
  margin-left: 4px;
`;
