import * as React from 'react';
import { DropdownMenu, DropdownMenuIconCheck, DropdownMenuItem } from './styled';
import { SystemsType } from 'pages/Dashboard/types';
import { useBoolean } from 'hooks';

export interface LkpDropdownMenuItemProps extends Partial<SystemsType> {
  id: string;
  label: string;
  selectAll?: boolean;
  selected?: boolean;
  partialSelected?: boolean;
  link?: string;
  iconLeft?: React.ReactNode;
  iconRight?: React.ReactNode;
}

export interface LkpDropdownMenuProps {
  items: Array<LkpDropdownMenuItemProps>;
  onChangeItem?: (itemId: string, isSelected?: boolean) => void;
  onToggleAll?: (isAllSelected: boolean) => void;
}

export function LkpDropdownMenu({ items, onChangeItem, onToggleAll }: LkpDropdownMenuProps): React.ReactElement {
  const { value: isAllSelected, toggle: toggleAllSelected } = useBoolean();

  return (
    <DropdownMenu>
      {items.map(({ id, label, selected = false, partialSelected = false }, i) => (
        <DropdownMenuItem
          key={id}
          onClick={() => {
            if (onToggleAll && i === 0) {
              toggleAllSelected();
              onToggleAll(!isAllSelected);
            }
            if (onChangeItem && i !== 0) onChangeItem(id, !selected);
          }}
          isSelected={selected}
        >
          <DropdownMenuIconCheck isSelected={selected} isPartialSelected={partialSelected} />
          {label}
        </DropdownMenuItem>
      ))}
    </DropdownMenu>
  );
}
