import React from 'react';
import PropTypes from 'prop-types';

import { StyledInput } from '../Input/styled';

import { StyledSwitchWrapper, StyledLabel, StyledSwitch } from './styled';

const Switch = React.forwardRef((props, ref) => {
  const { label, size = 'small', $hasMaxContent, 'data-cy': dataCy } = props;
  return (
    <StyledSwitchWrapper data-cy={dataCy} hasMaxContent={$hasMaxContent}>
      <StyledLabel size={size}>
        {label}
        <StyledSwitch hasMaxContent={$hasMaxContent}>
          {/* fix: remove destructured and define necessary props */}
          {/* remove duplicate data-cy from hidden checkbox due to destructure */}
          <StyledInput ref={ref} type="checkbox" {...props} data-cy="" />
          <span />
        </StyledSwitch>
      </StyledLabel>
    </StyledSwitchWrapper>
  );
});

Switch.propTypes = {
  label: PropTypes.string,
  $hasMaxContent: PropTypes.bool,
  'data-cy': PropTypes.string,
};

Switch.defaultProps = {
  label: '',
  $hasMaxContent: false,
  'data-cy': '',
};

export { Switch };
