import styled from 'styled-components';
import { growOnHover } from 'assets/styled/tokens/transitions';

import { buttonSizeFactory } from './buttonSizeFactory';
import { buttonThemeFactory } from './ButtonThemeFactory';

export const StyledButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid transparent;
  border-radius: 3px;
  font-weight: 500;
  text-align: center;
  text-decoration: none;

  ${growOnHover}
  ${props => buttonThemeFactory(props.theme)};
  ${props => buttonSizeFactory(props.size)};
`;
