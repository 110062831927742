import styled from 'styled-components';

import { medias } from 'assets/styled/tokens';

export const StyledReportPage = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  margin: 0 auto;
  background: ${props => props.background || 'transparent'};

  display: flex;

  @media screen and (max-width: ${medias.desktopSm}) {
    flex-direction: column;
  }
`;
