import React from 'react';
import PropTypes from 'prop-types';

const Conditional = props => {
  const { when, children } = props;
  return <>{when && children}</>;
};

Conditional.propTypes = {
  when: PropTypes.bool,
  children: PropTypes.node,
};

export default Conditional;
