import React from 'react';
import { HeaderName } from './styled';

interface IPageHeaderProps {
  title: string;
}

export const PageHeader: React.FC<IPageHeaderProps> = ({ title }) => {
  return <HeaderName>{title}</HeaderName>;
};
