import styled from 'styled-components';

import { fonts, colors } from 'assets/styled/tokens';
import closeIcon from 'assets/icons/close.svg';

export const StyledBlackLayer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  transition: all 0.3s ease-in-out;
`;

export const StyledModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  border-radius: 6px 6px 0 0;
  background: ${colors.white};
  line-height: 20px;
`;

export const StyledModalTitle = styled.h4`
  font-family: ${fonts.family.title};
  font-size: 16px;
  color: ${colors.primary};
`;

export const StyledModalClose = styled.button`
  position: relative;
  margin: 0;
  padding: 0;
  border: 0;
  width: 14px;
  height: 14px;
  background: transparent url(${closeIcon}) no-repeat center/contain;
  border: none;
  border-radius: 2px;
  font-size: 0;
  color: transparent;
  text-indent: -9999px;
  filter: invert(40%) sepia(82%) saturate(0%) hue-rotate(195deg) brightness(104%) contrast(83%);
`;

export const StyledModalInfo = styled.div`
  width: 504px;
  max-height: 382px;
  min-height: 350px;
  height: 100%;
  border-radius: 6px;
  background-color: white;
  box-shadow: 0px 1px 18px rgba(0, 0, 0, 0.3);
`;

export const StyledModalList = styled.ul`
  display: block;
  width: 100%;
  max-height: 260px;
  overflow: auto;

  &::-webkit-scrollbar {
    width: 10px;
    height: 80%;
  }

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    width: 15px;
    height: 15px;
    border: 1px solid black;
  }

  &::-webkit-scrollbar-thumb {
    background: ${colors.silver3};
    border-radius: 100px;
    height: 160px;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: ${colors.silver2};
  }
`;

export const StyledModalItem = styled.li`
  display: block;
  width: 100%;
  position: relative;
`;

export const StyledModalOption = styled.button`
  display: block;
  width: 100%;
  padding: 7px 16px;
  text-align: left;
  color: ${colors.gray};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  &:hover {
    background: ${colors.pampas};
  }

  &:focus {
    box-shadow: none;
  }
`;

export const StyledSearchBar = styled.div`
  position: relative;
  padding: 0px 20px 10px;
`;

export const EmptyList = styled.div`
  width: 100%;
  height: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StyledTrashIcon = styled.img`
  height: 12px;
  width: 10px;
  position: absolute;
  top: 12px;
  right: 35px;
  filter: invert(40%) sepia(82%) saturate(0%) hue-rotate(195deg) brightness(104%) contrast(83%);

  &:hover {
    cursor: pointer;
  }
`;
