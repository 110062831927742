import styled from 'styled-components';
import { Button } from 'components/Button';
import { colors, medias, fonts } from 'assets/styled/tokens';
import { ModalGeneric } from 'components/ModalGeneric';
import { StyledModalGenericBody, StyledModalGenericClose } from 'components/ModalGeneric/styled';

export const PeriodWarning = styled.div`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.3px;
  color: #888888;
`;

export const StyledFormRadio = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  font-family: ${fonts.family.text};
  font-size: 0.75rem;

  label {
    margin-left: 0.9375rem;

    &:first-child {
      margin-left: 0;
    }

    input {
      margin-right: 0.3125rem;
      cursor: pointer;
    }
  }
`;

export const DownloadButton = styled.button`
  color: ${colors.primary};
  text-transform: uppercase;
  text-decoration: underline;
  margin-left: 10px;
  padding: 0;
  text-align: start;
`;
export const EmailNotVerifiedWarningContainer = styled.section`
  display: flex;
  flex-direction: column;
  width: 100%;
  grid-column: 1 / -1;
  align-items: center;
  div {
    font-family: Blinker;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    margin-top: 16px;
    margin-bottom: 16px;
    text-align: center;

    color: #ec6655;
  }
  span {
    font-size: 16px;
    line-height: 19px;
    margin-bottom: 24px;
    text-align: center;

    color: #888888;
  }
  button > svg {
    margin-right: 8px;
  }
`;
export const SendEmailModal = styled(ModalGeneric)`
  h6 {
    color: ${props => (props.success ? colors.successGreen : '#EC6655')};
    margin-bottom: 16px;
  }
  p {
    color: ${colors.gray};
    text-align: center;
    margin-bottom: 24px;
    font-size: 16px;
    line-height: 19px;
  }

  ${StyledModalGenericClose} {
    margin-right: -8px;
  }
  ${StyledModalGenericBody} button {
    font-size: 14px;
    line-height: 16px;
    width: 96px;
  }
`;
export const AtentionModal = styled(ModalGeneric)`
  h6 {
    color: ${colors.primary};
    margin-bottom: 16px;
  }
  p {
    color: ${colors.gray};
    text-align: center;
    margin-bottom: 24px;
    font-size: 16px;
    line-height: 19px;
  }

  ${StyledModalGenericClose} {
    margin-right: -8px;
  }
  ${StyledModalGenericBody} button {
    font-size: 14px;
    line-height: 16px;
    width: 96px;
  }
`;
export const RequiredMessage = styled.div`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.3px;
  color: #c25446;
`;
export const GoBackButton = styled(Button)`
  left: 0;
  margin-right: 24px;
  background: #ffffff;
  border: 1px solid #e5e5e5;
  border-radius: 100px;
  height: 32px;
  width: 32px;
  box-sizing: border-box;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const KnowMoreButton = styled(Button)`
  box-sizing: border-box;
  position: relative;
  right: 0;
  margin-left: auto;
  width: 127px;
  height: 32px;
  background-color: #fff;
  color: ${colors.black};
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  border: 1px solid #e5e5e5;
  border-radius: 4px;
  padding: 9px 13px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  min-width: 100%;
  align-items: center;
  margin-bottom: 34px;
`;

export const HeaderName = styled.h1`
  font-size: 32px;
  font-family: Blinker, sans-serif;
  font-weight: 700;
`;
export const StyledFooter = styled.footer`
  padding-top: 48px;
  padding-bottom: 8px;
  width: 460px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StyledContainerFooter = styled.div`
  justify-content: center;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  @media screen and (min-width: ${medias.tablet}) {
    grid-template-columns: 400px 520px;
  }

  @media screen and (min-width: ${medias.desktopMd}) {
  }
`;

export const StyledContentWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const StyledActions = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  & > div {
    margin-right: 16px;
  }

  & > button {
    min-width: 153px;
  }
`;

export const ExportButton = styled(Button)`
  width: 100%;
  height: 48px;
  font-size: 14px;
`;

export const StatusInfo = styled.div`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.3px;
  text-transform: uppercase;
  color: #1e1e1e;
  margin-left: 8px;
`;
