import thumbnail1 from './assets/thumbnail-1.svg';
import thumbnail2 from './assets/thumbnail-2.svg';
import thumbnail3 from './assets/thumbnail-3.svg';

const previewAuditReportExcelFactory = ({ showAuditOccurrence, showCompare }) => {
  const result = [];
  result.push(thumbnail1);

  if (showAuditOccurrence) {
    result.push(thumbnail2);
  }

  if (showCompare) {
    result.push(thumbnail3);
  }

  return result;
};

export { previewAuditReportExcelFactory };
