import MagnifyingIcon from 'assets/icons/magnifying.svg';
import { useGeolocalizeStore } from 'pages/Geolocalize/store';
import ViewLoadingContainer from '../ViewLoadingContainer';

import {
  StyledSearchTeamsInput,
  StyledTeamsCount,
  StyledTeamsViewContainer,
  StyledTeamsViewControls,
  StyledTeamsViewHeader,
  StyledTeamsViewList,
} from './styled';
import TeamCard from './TeamCard';

import { UPDATE_TEAM_MARKERS_SEARCH_TERM } from 'pages/Geolocalize/constants';
import { getFilteredTeams } from 'pages/Geolocalize/utils';

const TeamsView = () => {
  const [state, dispatch] = useGeolocalizeStore();

  const teamMarkersState = state.teamMarkers;
  const teamMarkers = teamMarkersState.data;

  if (!teamMarkers) {
    return <ViewLoadingContainer />;
  }

  const filteredTeams = getFilteredTeams({ teams: teamMarkers, searchTerm: teamMarkersState.searchTerm });

  if (filteredTeams) {
    return (
      <StyledTeamsViewContainer>
        <StyledTeamsViewControls>
          <StyledSearchTeamsInput
            type="text"
            autoComplete="off"
            name="search"
            size="small"
            placeholder="Buscar equipe"
            icon={MagnifyingIcon}
            dataCy={`search-input}`}
            onChange={e => {
              e.persist();

              const searchTerm = e.target.value;
              dispatch({ type: UPDATE_TEAM_MARKERS_SEARCH_TERM, payload: searchTerm });
            }}
          />
        </StyledTeamsViewControls>

        <StyledTeamsViewHeader>
          <StyledTeamsCount>Equipes ({filteredTeams.length}) </StyledTeamsCount>
        </StyledTeamsViewHeader>
        <StyledTeamsViewList>
          {filteredTeams.map(({ name, technicians }) => {
            if (technicians.length > 0) {
              return (
                <TeamCard
                  {...{
                    name,
                    technicians,
                  }}
                />
              );
            }
          })}
        </StyledTeamsViewList>
      </StyledTeamsViewContainer>
    );
  }
};

export default TeamsView;
