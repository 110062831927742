import * as React from 'react';

import { useBoolean, useDebounce } from 'hooks';
import { fetcher } from 'services';
import { HeaderFiltersType } from 'context/GlobalFiltersContext/initialState';

import { ChartSeriesType, ChartsParamsType } from '../types';
import { ChartSDAIFailuresAnalysisByPeriodType } from './types';
import { LkpChart } from '../Chart';
import { getStackedChartOptions } from './options';
import { LkpChartTopFilter } from '../Filters';
import { getErrorMessageByStatusCode, groupBy } from 'utils';
import { isNumber } from 'fp-ts/lib/number';
import { useParams } from 'react-router-dom';

export interface LkpChartSDAIFailuresAnalysisByPeriodProps {
  headerFilters: HeaderFiltersType;
  httpPath?: string;
  title?: string;
}

const DEFAULT_SERIES = [
  { name: 'Elétrico', data: [] },
  { name: 'Civil', data: [] },
  { name: 'Mecânico', data: [] },
  { name: 'Climatização', data: [] },
  { name: 'Refrigeração', data: [] },
  { name: 'Mobiliário', data: [] },
  { name: 'Áudio e video', data: [] },
  { name: 'Hidráulico', data: [] },
  { name: 'Limpeza', data: [] },
  { name: 'CFTV', data: [] },
  { name: 'SDAI', data: [] },
];

export function LkpChartSDAIFailuresAnalysisByPeriod({
  headerFilters,
  httpPath = '',
  title,
}: LkpChartSDAIFailuresAnalysisByPeriodProps): React.ReactElement {
  const { value: isLoading, setValue: setLoading } = useBoolean();
  const { value: isError, setValue: setIsError } = useBoolean();
  const [errorMessage, setErrorMessage] = React.useState<string>();
  const { dateFrom, dateTo } = useParams<{ dateFrom: string; dateTo: string }>();

  const [series, setSeries] = React.useState<Array<ChartSeriesType>>(DEFAULT_SERIES);
  const [period, setPeriod] = React.useState<Array<string>>([]);
  const { value: showMonth, setValue: setShowMonth } = useBoolean();
  const [params, setParams] = React.useState<ChartsParamsType>({
    Empresa: headerFilters?.companies?.id || 0,
    Sites: headerFilters?.units?.id,
    exibirMes: false,
    Inicio: dateFrom,
    Fim: dateTo,
  });

  const debouncedStartValue = useDebounce<string>(params?.Inicio || '', 500);
  const debouncedEnvValue = useDebounce<string>(params?.Fim || '', 500);

  React.useEffect(() => {
    (async () => {
      await fetchChartData();
    })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showMonth, debouncedStartValue, debouncedEnvValue]);

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  const fetchChartData = async () => {
    setLoading(true);

    const _httpPath = httpPath;
    const result = await fetcher
      .get<Array<ChartSDAIFailuresAnalysisByPeriodType>>(_httpPath, 'dashboard', { params })
      .catch(error => {
        const message = getErrorMessageByStatusCode(error, _httpPath);
        setErrorMessage(message);
        setIsError(true);
      });

    if (result) {
      const _groupName = groupBy<ChartSDAIFailuresAnalysisByPeriodType>(result, d => {
        const formattedName = d.nome.toLowerCase().replace('?', ''); // If the API remove the '?' in the end of name, we should remove the replace
        return `Falhas em ${capitalizeFirstLetter(formattedName)}`;
      });
      const _series = _groupName.map(({ name, data: d }) => {
        const data = d.map(({ quantidade }) => (isNumber(Number(quantidade)) ? Number(quantidade) : 0));
        return { name, data };
      });
      setSeries(_series);

      const _groupPeriodo = groupBy<ChartSDAIFailuresAnalysisByPeriodType>(result, d => d.periodo);
      setPeriod(_groupPeriodo.map(period => period?.name));
    }

    setLoading(false);
  };

  const handleChangeStartDate = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value: Inicio } = event.target;
    setParams({ ...params, Inicio });
  };

  const handleChangeEndDate = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value: Fim } = event.target;
    setParams({ ...params, Fim });
  };

  const handleChangeMonth = (event: React.MouseEvent<HTMLButtonElement>) => {
    const { value } = event.currentTarget;
    const exibirMes = value === 'month';
    setParams({ ...params, exibirMes });
    setShowMonth(exibirMes);
  };

  return (
    <LkpChart
      title={title}
      companyId={headerFilters?.companies?.id || 0}
      series={series}
      options={getStackedChartOptions(period, {
        legend: {
          position: 'right',
        },
        colors: ['#DA3490', '#9089FA', '#47E26F'],
      })}
      loading={isLoading}
      error={isError}
      errorMessage={errorMessage}
      onChartTryAgain={fetchChartData}
      filter={
        <LkpChartTopFilter
          onChangeStartDate={handleChangeStartDate}
          onChangeEndDate={handleChangeEndDate}
          onChangeMonth={handleChangeMonth}
          showMonth={showMonth}
        />
      }
    />
  );
}
