import {
  Container,
  StyledError,
  StyledErrorMessage,
  StyledErrorMessageSub,
  StyledLinkIn,
  StyledImg,
  ButtonHome,
} from './styled';
import conectionErrorIcon from 'assets/icons/conection-error-icon.svg';

export const ConectionError = () => {
  return (
    <Container>
      <StyledImg src={conectionErrorIcon} />
      <StyledError>ERRO: ERR_CONNECTION_RESET</StyledError>
      <StyledErrorMessage>
        Recomendamos que você tente reiniciar o modem para restabelecer a conexão.
      </StyledErrorMessage>
      <StyledErrorMessageSub>
        Se o problema persistir, sugerimos entrar em contato com o suporte técnico de sua equipe de Tecnologia da
        Informação (TI). Se precisar de ajuda, fique a vontade para{' '}
        <a href="https://leankeep.movidesk.com/kb/form/2343/fale-conosco" target="_blank">
          entrar em contato conosco
        </a>
      </StyledErrorMessageSub>
      <ButtonHome>
        <StyledLinkIn to="/">voltar à homepage</StyledLinkIn>
      </ButtonHome>
    </Container>
  );
};
