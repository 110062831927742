import React from 'react';
import PropTypes from 'prop-types';

import { StyledCheckbox, StyledInput } from './styled';

const Checkbox = React.forwardRef((props, ref) => {
  const { text, className } = props;
  return (
    <StyledCheckbox className={className ? className : ''}>
      <StyledInput type="checkbox" ref={ref} {...props} value={props.value || ''} />
      <span></span>
      <p>{text}</p>
    </StyledCheckbox>
  );
});

Checkbox.propTypes = {
  text: PropTypes.string,
};

Checkbox.defaultProps = {
  text: '',
};

export { Checkbox };
