import React, { useEffect, useState } from 'react';
import { StyledApplicationTableActionCellContainer, StyledOption, StyledOptionsContainer } from './styled';

import { ReactComponent as TripleCircle } from 'assets/icons/triple-circle.svg';

function ApplicationTableActionCell(props) {
  const [activeValue, setActiveValue] = useState('none');
  const handleAction = action => {
    setActiveValue(action);
    if (action === 'none') {
    } else if (action === 'delete') {
      props.setDeleteUnitId(props.cell.value.id);
    }
  };
  useEffect(() => {
    if (!props.deleteUnitId) {
      setActiveValue('none');
    }
  }, [props.deleteUnitId]);
  return (
    <StyledApplicationTableActionCellContainer>
      <TripleCircle />
      <StyledOptionsContainer
        value={activeValue}
        onChange={e => {
          e.persist();
          handleAction(e.target.value);
          console.error(e);
        }}
      >
        <StyledOption style={{ display: 'none' }} value="none"></StyledOption>
        {/* <StyledOption value="edit">Editar</StyledOption> */}
        <StyledOption value="delete">Excluir</StyledOption>
      </StyledOptionsContainer>
    </StyledApplicationTableActionCellContainer>
  );
}

export default ApplicationTableActionCell;
