import { Radio } from 'components/Form/subcomponents/Radio';
import React from 'react';
import { StyledExecutedByProviderHeader, StyledExecutedByProviderFormGroup } from './styled';

function ApplicationTableExecutedByProviderHeader({
  setIsExecutedByProvider,
  setIsClearProvidersModalActive,
  disabled,
  isExecutedByProvider,
}) {
  return (
    <StyledExecutedByProviderHeader>
      <StyledExecutedByProviderFormGroup
        disabled={disabled}
        label="Executado por fornecedor:"
        showTooltip={disabled}
        tooltipMessage="Não editável após o agendamento"
      >
        <Radio
          text="Sim"
          name={`executedByProvider`}
          disabled={disabled}
          onChange={e => {
            e.preventDefault();
            setIsExecutedByProvider(true);
          }}
          value="yes"
          checked={isExecutedByProvider}
          // onClick={(e) => setIsSupplierActive(true)}
        />
        <Radio
          text="Não"
          type="radio"
          disabled={disabled}
          name={`executedByProvider`}
          onChange={e => {
            e.preventDefault();
            setIsClearProvidersModalActive(true);
          }}
          checked={!isExecutedByProvider}
          value="no"
        />
      </StyledExecutedByProviderFormGroup>
    </StyledExecutedByProviderHeader>
  );
}

export default ApplicationTableExecutedByProviderHeader;
