import * as React from 'react';
import { AlertIcon, AlertInner, AlertMessage, AlertWrapper, VariantsType } from './style';

export interface LkpAlertProps {
  children: React.ReactNode;
  icon?: React.ReactNode;
  variant?: VariantsType;
  dontShowAgain?: boolean;
  onClose?: () => void;
}

export function LkpAlert({ children, icon, variant = 'default' }: LkpAlertProps): React.ReactElement {
  return (
    <AlertWrapper variant={variant}>
      <AlertInner>
        {icon && <AlertIcon>{icon}</AlertIcon>}
        <AlertMessage>{children}</AlertMessage>
      </AlertInner>
    </AlertWrapper>
  );
}
