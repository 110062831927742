const adaptReportsApiOptions = array => {
  return array.map(({ nome, site }) => ({
    label: nome,
    value: site,
  }));
};
const adaptReportsApiOptionsSubgroups = array => {
  return array.map(({ nome, subGrupoUnidadeId }) => ({
    label: nome,
    value: subGrupoUnidadeId,
  }));
};
export { adaptReportsApiOptions, adaptReportsApiOptionsSubgroups };
