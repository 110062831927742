import React from 'react';
import { StyledBatteryOutlineSvg, StyledBatteryWrapper, StyledDynamicBatteryAmount } from './styled';

const Battery = ({ percentage }) => {
  return (
    <StyledBatteryWrapper>
      <StyledDynamicBatteryAmount percentage={percentage} />
      <StyledBatteryOutlineSvg
        percentage={percentage}
        width="16"
        height="8"
        viewBox="0 0 16 8"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M13.6002 1.6V3.2H14.4002V4.8H13.6002V6.4H1.60017V1.6H13.6002ZM14.0002 0H1.20017C0.881913 0 0.576688 0.126428 0.351644 0.351472C0.126601 0.576516 0.000172453 0.88174 0.000172453 1.2V6.8C-0.00253468 6.95833 0.0266548 7.11559 0.086002 7.2624C0.145349 7.40922 0.23364 7.54258 0.345614 7.65456C0.457588 7.76653 0.590955 7.85482 0.737769 7.91417C0.884583 7.97352 1.04184 8.00271 1.20017 8H14.0002C14.3184 8 14.6237 7.87357 14.8487 7.64853C15.0737 7.42348 15.2002 7.11826 15.2002 6.8V6.4H15.4002C15.4793 6.40135 15.558 6.38676 15.6314 6.35709C15.7048 6.32741 15.7715 6.28327 15.8275 6.22728C15.8834 6.17129 15.9276 6.10461 15.9573 6.0312C15.9869 5.95779 16.0015 5.87917 16.0002 5.8V2.2C15.9976 2.04167 15.9336 1.89055 15.8216 1.77858C15.7096 1.66662 15.5585 1.60257 15.4002 1.6H15.2002V1.2C15.195 0.883347 15.0669 0.581105 14.843 0.357168C14.6191 0.133231 14.3168 0.00514984 14.0002 0V0Z" />
      </StyledBatteryOutlineSvg>
    </StyledBatteryWrapper>
  );
};

export default Battery;
