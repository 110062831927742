import styled from 'styled-components';

import { colors } from 'assets/styled/tokens';

import closeIcon from './assets/close.svg';

import { ReactComponent as Spinner } from './assets/spinner.svg';
import { spinAnimationCss } from 'utils/helpers/css';

export const StyledModalGeneric = styled.div`
  align-items: center;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-top: ${props => (props.position === 'top' ? '60px' : 0)};
  justify-content: ${props => (props.position === 'top' ? 'start' : 'center')};
  left: 0;
  opacity: ${props => (props.open ? '1' : '0')};
  position: fixed;
  top: 0;
  touch-action: ${props => (props.open ? 'auto' : 'none')};
  transition: all 0.3s ease-in-out;
  visibility: ${props => (props.open ? 'visible' : 'hidden')};
  width: 100%;
  z-index: 1100;
`;

export const StyledModalGenericHeader = styled.header`
  align-items: flex-start;
  background-color: ${colors.white};
  border-radius: 0.375rem 0.375rem 0 0;
  display: flex;
  justify-content: space-between;
`;
export const StyledModalGenericHeaderContainer = styled.h6`
  color: ${colors.primary};
  display: flex;
  flex-direction: column;
  font-family: Blinker;
  font-size: 21px;
  font-weight: 700;
  justify-content: flex-start;
  letter-spacing: 0.02em;
  line-height: 25px;
  margin-bottom: 20px;
  text-align: center;
`;

export const StyledModalSubtitle = styled.p`
  color: ${colors.gray};
  font-size: 12px;
  font-weight: 500;
  margin-bottom: 3px;
`;

export const StyledModalGenericLoadingContainer = styled.div`
  align-items: center;
  background-color: white;
  border-radius: 0.375rem;
  box-shadow: 0 0.0625rem 1.125rem rgba(0, 0, 0, 0.3);
  display: flex;
  flex-direction: column;
  font-size: 16px;
  height: 268px;
  justify-content: center;
  overflow: hidden;
  padding: 24px;
  position: relative;
  width: 362px;
  z-index: 3;
`;
export const StyledModalGenericLoadingSpinner = styled(Spinner)`
  margin-bottom: 16px;
  ${spinAnimationCss};
`;
export const StyledModalGenericLoadingHeader = styled.h4`
  font-size: 21px;
  line-height: 25px;
  color: ${colors.primary};
`;
export const StyledModalGenericIcon = styled.figure`
  margin-top: 0;
  margin-bottom: 8px;
`;

export const StyledModalGenericBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

export const StyledModalGenericClose = styled.button`
  background: white url(${closeIcon}) no-repeat center/14px 14px;
  border-radius: 0.125rem;
  border: 0;
  border: none;
  color: transparent;
  font-size: 0;
  height: 32px;
  margin: 0;
  padding: 9px;
  position: absolute;
  right: 16px;
  top: 16px;
  width: 32px;
`;

export const StyledModalGenericWrapper = styled.div`
  align-items: center;
  background-color: white;
  border-radius: 0.375rem;
  box-shadow: 0 0.0625rem 1.125rem rgba(0, 0, 0, 0.3);
  display: flex;
  flex-direction: column;
  font-size: 16px;
  justify-content: center;
  max-height: ${props => (props?.size?.height ? props.size.height : '512px')};
  max-width: ${props => (props?.size?.width ? props.size.width : '524px')};
  overflow: scroll;
  padding: 24px;
  position: relative;
  width: 100%;
`;
