import React, { forwardRef, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import Conditional from 'components/Conditional';
import CleanInputIcon from 'components/Form/shared/CleanInputIcon';
import Checkbox from './subcomponents/Checkbox';

import { Input } from '../Input';

import MagnifyingIcon from './assets/magnifying.svg';

import {
  StyledSelectWrapper,
  StyledSelect,
  StyledSearchBar,
  StyledDropdownContent,
  StyledDropdownItems,
  StyledDropdownItem,
} from './styled';
import { StyledError } from '../FormGroup/styled';

const MultiSelect = forwardRef(props => {
  const {
    size,
    placeholder,
    disabled,
    error,
    errormessage,
    onChange,
    className,
    'data-cy': dataCy,
    options = [],
    required,
  } = props;
  // const [newOptions, setNewOptions] = useState([]);
  // useEffect(() => {
  //   if (name === 'extraDocuments') {
  //     let othersOptions = [];
  //     othersOptions = options.filter(option => option.value?.includes('pdf'));
  //     setNewOptions(othersOptions);
  //   } else {
  //     setNewOptions(options);
  //   }
  // }, [name, options]);
  const [isOpen, setIsOpen] = useState(false);
  const [selecteds, setSelecteds] = useState([]);
  const [filterWord, setFilterWord] = useState('');
  const optionsMemoized = useMemo(() => options || [], [options]);
  const multiSelectRef = useRef(null);
  const handleClickOutside = useCallback(
    e => {
      if (multiSelectRef.current.contains(e.target)) {
        // inside click
        return;
      } else {
      }
      // outside click
      setIsOpen(false);
    },
    [multiSelectRef],
  );

  const handleChange = () => {
    if (disabled) {
      return;
    }

    setIsOpen(prevState => !prevState);
  };

  const filterOptionsByWord = useCallback(
    option => {
      if (!filterWord) {
        return option;
      }

      const wordMatches = `${option.label}`.toLowerCase().includes(filterWord);

      if (wordMatches) {
        return option;
      }

      return null;
    },
    [filterWord],
  );

  const handleAllCheck = useCallback(
    e => {
      e.persist();
      if (e.target.checked) {
        const newState = optionsMemoized.map(option => `${option.value}`);
        setSelecteds(newState);
        onChange && onChange(newState);
      } else {
        setSelecteds([]);
        onChange && onChange([]);
      }
    },
    [onChange, optionsMemoized],
  );

  const handleCheck = useCallback(
    e => {
      e.persist();

      if (e.target.checked) {
        setSelecteds([...selecteds, `${e.target.value}`]);
        onChange && onChange([...selecteds, `${e.target.value}`]);
      } else {
        const newState = selecteds.filter(item => `${item}` !== `${e.target.value}`);
        setSelecteds(newState);
        onChange && onChange(newState);
      }
    },
    [onChange, selecteds],
  );

  const handlePlaceholder = () => {
    if ((disabled && selecteds.length <= 0) || options.length <= 0) {
      return 'Seleção desabilitada';
    }
    if (options.length > 0 && selecteds.length > 0) {
      if (selecteds.length === 1) {
        return `${optionsMemoized.find(option => `${option.value}` === `${selecteds[0]}`).label}`;
      }
      return `${selecteds.length} itens selecionados`;
    }

    return placeholder;
  };

  const handleFilterWord = useCallback(e => setFilterWord(e.target.value), [setFilterWord]);

  const filterIdOptions = useMemo(
    () => optionsMemoized.filter(option => filterOptionsByWord(option)),
    [filterOptionsByWord, optionsMemoized],
  );

  const mapOptions = useCallback(
    ({ label, value }, index) => (
      <StyledDropdownItem key={`${index}-${value}`}>
        <Checkbox
          label={label}
          value={value || ''}
          data-cy={`${dataCy}-option`}
          isChecked={selecteds.includes(`${value}`)}
          onChange={handleCheck}
        />
      </StyledDropdownItem>
    ),
    [handleCheck, selecteds, dataCy],
  );
  // eslint-disable-next-line
  const checkboxList = useCallback(filterIdOptions.map(mapOptions), [filterIdOptions, mapOptions]);

  useEffect(() => {
    if (props.value && props.value.length > 0) {
      setSelecteds(props.value.map(item => `${item}`));
    } else {
      setSelecteds([]);
    }
  }, [props.value]);

  useEffect(() => {}, [optionsMemoized]);

  useEffect(() => {
    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    setFilterWord('');

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [handleClickOutside, isOpen]);

  return (
    <StyledSelectWrapper className={className} ref={multiSelectRef}>
      <StyledSelect
        readOnly
        error={error}
        type="text"
        data-cy={`toggle-${dataCy}`}
        size={size}
        placeholder={handlePlaceholder()}
        disabled={disabled || optionsMemoized.length <= 0}
        onClick={handleChange}
        required={required}
      />
      <Conditional when={!disabled && optionsMemoized?.length > 0 && selecteds?.length > 0}>
        <CleanInputIcon
          size={size}
          onClick={() => {
            setSelecteds([]);
            onChange && onChange([]);
          }}
        />
      </Conditional>

      <Conditional when={isOpen}>
        <StyledDropdownContent isOpen={isOpen}>
          <StyledDropdownItem>
            <StyledSearchBar>
              <Input
                type="text"
                autoComplete="off"
                name="search"
                size="small"
                placeholder="Pesquisar"
                icon={MagnifyingIcon}
                value={filterWord || ''}
                onChange={handleFilterWord}
              />
            </StyledSearchBar>
          </StyledDropdownItem>
          <StyledDropdownItems>
            <StyledDropdownItem>
              <Checkbox
                name="select-all"
                label="Selecionar todos"
                value="all"
                isCheckboxAll={true}
                isChecked={selecteds && selecteds.length > 0}
                isAllSelected={selecteds.length === optionsMemoized.length}
                onChange={handleAllCheck}
              />
            </StyledDropdownItem>
            {checkboxList}
          </StyledDropdownItems>
        </StyledDropdownContent>
      </Conditional>
      <StyledError>
        <p>{error && errormessage}</p>
      </StyledError>
    </StyledSelectWrapper>
  );
});

MultiSelect.propTypes = {
  size: PropTypes.string,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  options: PropTypes.array.isRequired,
};

MultiSelect.defaultProps = {
  size: 'medium',
  className: '',
  placeholder: '',
  disabled: false,
  options: [],
};

export default MultiSelect;
