import { colors } from 'assets/styled/tokens';
import styled from 'styled-components';

export const StyledInformativeTooltip = styled.span`
  position: relative;

  width: 12px;
  height: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 24px;

  span.OpenTooltip {
    top: -2.7rem;
  }

  span {
    border: 1px solid ${colors.carbon};
    color: #fff;
    font-size: 12px;
    font-weight: 400;
    line-height: 13px;
    background: ${colors.carbon};
    border-radius: 4px;
    position: absolute;
    top: -50px;
    padding: 8px 8px 10px;
    width: 268px;
    margin-left: 256px;
    text-align: start;
    transform: translateX(-45%);
    display: none;

    &:before {
      content: '';
      display: block;
      position: absolute;
      bottom: -0.1875rem;
      width: 0.625rem;
      height: 0.625rem;
      border-bottom: 0.1875rem solid transparent;
      border-right: 0.1875rem solid transparent;
      background: ${colors.carbon};
      left: 45%;
      transform: rotate(45deg);
    }
  }

  &:hover > span {
    display: block;
  }
`;
