import styled, { css } from 'styled-components';

import { colors, medias } from 'assets/styled/tokens';

import closeIcon from 'assets/icons/close-gray.svg';
import plusIcon from 'assets/icons/plus.svg';

const ChipStyle = css`
  position: relative;
  display: inline-flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: flex-start;
  padding: 5px 10px 6px 10px;
  border: 1px solid ${colors.lightGray};
  border-radius: 100px;
  color: ${colors.gray};
  cursor: pointer;
  margin: 0px 5px 0px 5px;
`;

export const StyledFilter = styled.div`
  line-height: 13px;
  ${props =>
    !props.hasData &&
    `
      ${StyledFilterChip} {
        display: none;
      }

      ${StyledFilterLabel} {
        ${ChipStyle};
        background-color: ${colors.westar};
      }
    `}

  ${props =>
    props.isOpen
      ? css``
      : css`
          ${StyledFilterLabel} {
            display: none;
          }
        `}
`;

export const StyledFilterLabel = styled.label`
  display: inline-block;
  margin-right: 10px;
  font-size: 0.6875rem;

  button {
    width: 14px;
    height: 14px;
    margin-left: 3px;
    padding: 0;
    background: transparent url(${plusIcon}) no-repeat center/10px 10px;
    color: transparent;

    &:focus {
      box-shadow: none;
    }
  }
`;

export const StyledFilterChip = styled.div`
  ${ChipStyle};

  span {
    font-size: 0.6875rem;
    line-height: 13px;
    display: block;
    max-width: 200px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  button {
    width: 14px;
    height: 14px;
    padding: 0;
    color: transparent;
    margin-left: 3px;
    background: transparent url(${closeIcon}) no-repeat center/8px 8px;
    display: ${props => props.disableOpen && 'none'};
  }

  cursor: ${props => props.disableOpen && 'default'};

  @media screen and (max-width: ${medias.tablet}) {
    display: inline-block;
  }
`;
