import React from 'react';
import PropTypes from 'prop-types';

import trashIcon from 'assets/icons/trash.svg';

import { StyledModalList, StyledModalItem, StyledModalOption, StyledTrashIcon } from './styled';

const FilterOptions = props => {
  const { hasDeleteBtn, options, onSelect, onDelete } = props;
  return (
    <StyledModalList>
      {options &&
        options.map((option, index) => {
          const label = option.name;

          return (
            <StyledModalItem key={index}>
              <StyledModalOption onClick={() => onSelect({ option })}>{label}</StyledModalOption>
              {hasDeleteBtn && <StyledTrashIcon src={trashIcon} onClick={() => onDelete({ option })} />}
            </StyledModalItem>
          );
        })}
    </StyledModalList>
  );
};

FilterOptions.proptype = {
  hasDeleteBtn: PropTypes.bool,
  options: PropTypes.array,
  onSelect: PropTypes.func,
  onDelete: PropTypes.func,
};

export { FilterOptions };
