import React from 'react';

import { StyledTabHeaderContainer, StyledTabsContainer, RoundedBottomBorder, StyledTabItem } from './styled';

function TabHeader({ header, children, className }) {
  return (
    <StyledTabHeaderContainer className={className ? className : ''}>
      <StyledTabsContainer>
        <StyledTabItem>
          {header}
          <RoundedBottomBorder />
        </StyledTabItem>
      </StyledTabsContainer>
      {children}
    </StyledTabHeaderContainer>
  );
}

export default TabHeader;
