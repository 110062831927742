import styled from 'styled-components';
import { medias } from 'assets/styled/tokens';
export const TransparentBgPhotos = styled.div`
  background: rgba(0, 0, 0, 0.7);
  //opacity: 0.5;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  padding: 24px 0;
  z-index: 6;
`;
export const ModalPhotos = styled.div`
  background-color: #ffffff;
  //padding: 20px;
  width: 92%;
  height: 100%;
  border-radius: 4px;
  box-sizing: border-box;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  @media (min-width: ${medias.desktopSm}) {
    height: 588px;
    width: 480px;
  }
`;
export const ModalHeader = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  font-family: Blinker;
  font-size: 24px;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: 0.5px;
  text-align: left;
  color: #1e1e1e;
  height: 64px;
`;
export const PhotosContainer = styled.div``;

export const CloseModalButton = styled.button`
  border: none;
`;
