import { outline } from './themes/outline';
import { lightOutline } from './themes/lightOutline';
import { primary } from './themes/primary';
import { white } from './themes/white';
import { whiteOutline } from './themes/whiteOutline';

const buttonThemeFactory = theme => {
  switch (theme) {
    case 'primary':
      return primary();
    case 'white':
      return white();
    case 'outline':
      return outline();
    case 'lightOutline':
      return lightOutline();
    case 'whiteOutline':
      return whiteOutline();
    default:
      return primary();
  }
};

export { buttonThemeFactory };
