import { devices } from 'assets/styled/tokens';
import styled from 'styled-components';

export const DashBoardContainer = styled.div`
  box-shadow: 0px 1px 8px 0px #0000001a;
  background-color: #ffffff;
  min-width: 515px;
  border-radius: 8px;
  height: 532px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 24px;
  hr {
    border: 1px solid #e5e5e5;
    width: 95%;
    margin: 0;
    box-sizing: border-box;
  }
  @media ${devices.screen} {
    width: 515px;
  }
  @media (min-width: 1360px) {
    width: 555px;
  }
  @media (min-width: 1440px) {
    width: 585px;
  }
  @media (min-width: 1500px) {
    width: 605px;
  }
  @media (min-width: 1600px) {
    width: 670px;
  }
  @media (min-width: 1700px) {
    width: 755px;
  }
  @media ${devices.screenWide} {
    width: 49%;
  }
`;

export const DashboardInnerContainer = styled.div`
  width: 70%;
  height: 48%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  :nth-child(3) {
    padding-bottom: 20px;
  }
  h1 {
    font-family: Blinker;
    font-size: 32px;
    font-weight: 700;
    line-height: 36px;
    letter-spacing: 0.5px;
    text-align: left;
  }
  h2 {
    font-family: Roboto;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.30000001192092896px;
    text-align: center;
    color: #888888;
  }
  @media ${devices.screen} {
    width: 100%;
  }
  @media (min-width: 1380px) {
  }
  @media (min-width: 1500px) {
  }
  @media (min-width: 1600px) {
  }
  @media (min-width: 1700px) {
  }
  @media ${devices.screenWide} {
  }
`;
export const CirclesContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  @media ${devices.screen} {
    width: 70%;
    justify-content: space-evenly;
  }
  @media (min-width: 1380px) {
    width: 60%;
    justify-content: space-evenly;
  }
  @media (min-width: 1500px) {
    width: 55%;
    justify-content: space-evenly;
  }
  @media (min-width: 1600px) {
    width: 50%;
    justify-content: space-evenly;
  }
  @media (min-width: 1700px) {
  }
  @media ${devices.screenWide} {
    width: 272px;
  }
`;
export const TitleContaniner = styled.div``;

export const DashButton = styled.button`
  border: 1px solid #f38a00;
  border-radius: 4px;
  width: 189px;
  height: 40px;
  color: #f38a00;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 0.5px;
  text-align: left;
  display: flex;
  background-color: #f5f3f1;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 24px;
  right: 24px;
  :hover {
    color: #fff;
    background-color: #f38a00;
  }
`;
