import React from 'react';
import { StyledActivitiesTableStartDateCell, StyledCellDescription } from './styled';
function ActivitiesTableStartDateCell(props) {
  return (
    <StyledActivitiesTableStartDateCell>
      <StyledCellDescription>{props.cell.value}</StyledCellDescription>
    </StyledActivitiesTableStartDateCell>
  );
}

export default ActivitiesTableStartDateCell;
