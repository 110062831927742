import styled from 'styled-components';

import { colors, medias } from 'assets/styled/tokens';
import { Button } from 'components/Button';

export const StyledMenuMobile = styled.div`
  display: none;
  max-height: 48px;
  height: 100%;
  width: 100%;
  background: ${colors.white};
  box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.1);
  padding: 8px 16px;
  position: sticky;
  top: 0;
  z-index: 99;

  @media screen and (max-width: ${medias.desktopSm}) {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`;

export const StyledLogo = styled.img`
  height: auto;
  max-height: 20px;
  width: auto;

  @media screen and (max-width: ${medias.mobile}) {
    width: auto;
    height: auto;
  }
`;

export const StyledMenuButton = styled(Button)``;

export const StyledFilterButton = styled(Button)`
  @media screen and (min-width: ${medias.tablet}) {
    visibility: hidden;
  }
`;

export const StyledHamburguer = styled.img`
  height: 16px;
  width: 16px;
  margin-right: 7px;
  cursor: pointer;
`;

export const StyledBackgroundEffect = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(34, 34, 34, 0.2);
  z-index: 10;
  cursor: pointer;
  display: ${props => !props.isActive && 'none'};

  @media screen and (min-width: ${medias.desktopSm}) {
    display: none;
  }
`;
