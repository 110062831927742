import styled from 'styled-components';
import { colors, fonts, medias } from 'assets/styled/tokens';

export const StyledModalBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 32px 10px;

  img {
    max-width: 360px;
    max-height: 266px;
    margin: 0 0 32px;
  }

  h6 {
    font-family: ${fonts.family.text};
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 19px;
    text-align: center;
    margin-bottom: 16px;
    color: ${colors.tundora};
  }

  span {
    font-family: ${fonts.family.text};
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    text-align: center;
    margin-bottom: 32px;
    color: ${colors.gray};
  }

  button {
    padding: 7px 32px;
    border-radius: 3px;
    font-family: ${fonts.family.text};
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 14px;
    text-align: center;
    color: ${colors.white};
  }

  @media screen and (min-width: ${medias.tablet}) {
    padding-right: 25px;
    padding-left: 25px;
  }
`;
