import { localStorageKeys } from 'constants/localStorage';
import { skeleton as authContextInitialState } from 'context/AuthContext/initialState';

export const getLocalAuthStorage = () => {
  // return parsed local auth storage or initial state if storage hasnt been set
  let storage;
  try {
    storage = JSON.parse(localStorage.getItem(localStorageKeys.authContext));
    if (storage === null) {
      throw new Error('null storage');
    }
  } catch (e) {
    storage = authContextInitialState;
  }
  return storage;
};
