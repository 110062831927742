import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { devices } from 'assets/styled/tokens/devices';
import tooltipArrow from 'assets/icons/tooltip-arrow.svg';
import { ReactComponent as TooltipIcon } from 'assets/icons/tooltip-icon.svg';
const TooltipText = styled.span`
  visibility: hidden;
  width: 160px;
  text-align: left;
  background-color: #666666;
  color: #fff;
  border-radius: 6px;
  padding: 8px;
  position: absolute;
  z-index: 1;
  bottom: 42px;
  left: 24%;
  margin-left: -75px;
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;

  &:after {
    content: url(${tooltipArrow});
    display: inline-flex;
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -3px;
    border-width: 3px;
    width: 1px;
    overflow: hidden;
    border-style: solid;
    border-color: #666666 transparent transparent transparent;
  }
  @media ${devices.tabletLandscape} {
    margin-left: -137px;
    width: 291px;
  }
`;

const TooltipContainer = styled.div`
  position: relative;
  display: inline-flex;
  svg {
    margin-top: auto;
    margin-bottom: auto;
  }
  :hover span {
    visibility: visible;
  }
`;

function Tooltip({ text, className = '' }) {
  return (
    <TooltipContainer className={className}>
      <TooltipIcon />
      <TooltipText>{text}</TooltipText>
    </TooltipContainer>
  );
}

Tooltip.propTypes = {
  text: PropTypes.string.isRequired,
  className: PropTypes.string,
};

export { Tooltip };
