import { NavLink } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { StyledSubCategory } from '../Subcategory/styled';

export const SubMenuContainer = styled.div``;
export const StyledCategoryItem = styled.li`
  display: flex;
  justify-content: center;
  ${props =>
    props.isOpen
      ? css`
          ${StyledSubCategory} {
            width: 112px;
            height: 100vh;
            visibility: visible;
            background-color: #ffffff;
            opacity: 1;
            display: block;
            left: 88px;
            top: -98px;
            padding-top: 50px;
            box-shadow: rgba(0, 0, 0, 0.15) 0px 1px 8px 0px;
          }
          ${StyledCategoryButton} {
            a {
              div {
                background-color: ${props => (props.disabled ? '#fff' : props.isActive ? '#FCE3C2' : '#f5f3f1')};
              }
            }
            span {
              div {
                background-color: ${props => (props.disabled ? '#fff' : props.isActive ? '#FCE3C2' : '#f5f3f1')};
              }
            }
          }
        `
      : css`
          ${StyledSubCategory} {
            visibility: hidden;
            opacity: 0;
          }
          ${StyledCategoryButton} {
            a {
              div {
                background-color: ${props => (props.isActive ? '#FCE3C2' : '#fff')};
              }
            }
            span {
              div {
                background-color: ${props => (props.isActive ? '#FCE3C2' : '#fff')};
              }
            }
          }
        `}
`;

export const StyledCategoryButton = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: left;
  font-family: 'Roboto', sans-serif;
  font-weight: 700;
  font-size: 10px;
  text-align: center;
  background-color: #fff;
  //height: auto;
  //width: auto;

  a {
    pointer-events: ${props => (props.disabled ? 'none' : 'all')};
    display: flex;
    justify-content: center;
    align-items: ${props => (props.isChildren ? 'left' : 'center')};
    flex-direction: column;
    font-size: ${props => (props.isChildren ? '12px' : '10px')};
    text-align: left;
    word-wrap: break-word;
    text-decoration: none;
    padding: 0 10px;
    line-height: normal;
    margin-bottom: ${props => (props.isChildren ? '25px' : '10px')};
    color: ${props =>
      props.disabled ? '#cccccc' : props.isChildren ? '#888888' : props.isActive ? '#f38a00' : '#1E1E1E'};
    :hover {
      color: ${props =>
        props.disabled ? '#cccccc' : props.isActive ? '#FCE3C2' : props.isChildren ? '#f38a00' : '#1E1E1E'};
    }
    div {
      cursor: pointer;
      width: 32px;
      height: 32px;
      border-radius: 8px;
      background-color: ${props => (props.isActive ? '#FCE3C2' : '#fff')};
      display: flex;
      justify-content: center;
      align-items: center;
      :hover {
        background-color: ${props => (props.disabled ? '#fff' : '#f5f3f1')};
      }
      svg {
        path {
          fill: ${props => (props.disabled ? '#cccccc' : props.isActive ? '#f38a00' : '#AAAAAA')};
        }
      }
    }
  }
  span {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-size: ${props => (props.isChildren ? '12px' : '10px')};
    color: #1e1e1e;
    line-height: normal;
    margin-bottom: 10px;
    div {
      cursor: pointer;
      width: 32px;
      height: 32px;
      border-radius: 8px;
      background-color: ${props => (props.isActive ? '#FCE3C2' : '#fff')};
      display: flex;
      justify-content: center;
      align-items: center;
      :hover {
        background-color: ${props => (props.disabled ? '#fff' : '#f5f3f1')};
      }
      svg {
        path {
          fill: ${props => (props.disabled ? '#cccccc' : props.isActive ? '#f38a00' : '#AAAAAA')};
        }
      }
    }
  }
  @media (max-width: 1367px) {
    width: ${props => (props.isChildren ? '100%' : '48px !important')};
    height: 48px !important;
  }
`;

export const StyledNavLink = styled(NavLink)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;
