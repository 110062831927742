const checkIfSubMenuHasPermissionTrue = (item, type) => {
  if (item.subMenu && item.subMenu.length > 0) {
    const permission = item.subMenu.some(subItem => subItem.acesso === true);
    return !permission;
  }
  if (item.nome === 'Geolocalização' && type === 2) {
    return false;
  }
  return !item.acesso;
};
export const menuAdmin = (data, type) => {
  const menu = {
    first: data[0]?.menu?.map(item => {
      const menuItem = {
        label: item.nome,
        link: item.nome === 'Home' ? '/home' : item.url,
        disabled: checkIfSubMenuHasPermissionTrue(item, type),
        isExternal: true,
      };

      if (item.subMenu && item.subMenu.length > 0) {
        menuItem.children = item.subMenu.map(subItem => ({
          label: subItem.nome,
          link: subItem.url,
          disabled: !subItem.acesso,
          isExternal: true,
        }));
      }

      return menuItem;
    }),
  };

  return menu;
};
