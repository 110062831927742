import * as React from 'react';
import { useParams } from 'react-router-dom';
import { format, sub, parseISO, isValid } from 'date-fns';
import { useBoolean } from 'hooks';
import {
  LkpChartComparativeSystemBetweenMonths,
  LkpChartActivitiesOccurrences,
  LkpChartActivitiesOccurrencesPeriod,
  LkpChartComparisonBetweenWeeks,
  LkpChartNumberOfOccurrencesRegisteredPerWeek,
  LkpChartOccurrencesByFixStatus,
  //Menu,
  MobileNavbar,
  PageWrapper,
  LkpChartComparativeBetweenWeeks,
  LkpChartOccurrencesRequiredBySystem,
  Loading,
  LkpFilterDate,
  LkpChartNumberOccurrencesByPriority,
  Toast,
} from 'components';
import { HeaderFiltersPage } from 'containers/HeaderFiltersPage';
import { ChartContainerTabs } from 'components/Charts/ChartContainerTab';
import { useHeaderFilters } from 'context/GlobalFiltersContext';
import { ReactComponent as OldChartIcon } from 'assets/icons/pizza-chart-icon.svg';
import { DashColumn, DashFullGrid, DashHeader, DashWrapper, OldChartButton } from './styled';
import { HeaderFiltersType } from 'context/GlobalFiltersContext/initialState';
import { LkpChartNumberOfOccurrencesPerSystem } from 'components/Charts/NumberOfOccurrences/PerSystem';
import { fetcher } from 'services';
import { SystemsType } from '../types';
import { URLS } from 'constants/urls';
import { NewMenu } from 'components/Menu/NewMenu';

export function DashboardGeneralPage(): React.ReactElement {
  const { dateFrom, dateTo } = useParams<{ dateFrom: string; dateTo: string }>();
  const { value: isLoading, setValue: setLoading } = useBoolean(true);
  const { headerFiltersContext, setFiltersContext } = useHeaderFilters();
  const [startDate, setStartDate] = React.useState<string>();
  const [endDate, setEndDate] = React.useState<string>();
  const [toastList, setToastList] = React.useState([]);
  const [filters] = React.useState<HeaderFiltersType>(headerFiltersContext?.headerFilters);

  React.useEffect(() => {
    let _date = dateFrom ? parseISO(dateFrom) : new Date();
    if (!isValid(_date)) _date = new Date();
    const _endDate = dateTo || format(_date, 'yyyy-MM-dd');
    const _startDate = dateFrom || format(sub(_date, { months: 1 }), 'yyyy-MM-dd');
    // if (token) {
    //   const { comps }: any = jwt(token);
    //   if (comps) {
    //     const [company] = JSON.parse(comps);
    //     if (company.id === 4969) {
    //       window.location.href = `/dashboard/${_startDate}/${_endDate}`;
    //     }
    //   }
    // }
    if (!dateFrom || !dateTo) {
      window.location.href = `/dashboard-geral/${_startDate}/${_endDate}`;
    }

    if (dateFrom) setStartDate(dateFrom);
    if (dateTo) setEndDate(dateTo);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateFrom, dateTo]);

  React.useEffect(() => {
    setLoading(true);

    (async () => {
      const { headerFilters, ...restFilters } = headerFiltersContext;
      const systems = await fetcher
        .get<Array<SystemsType>>(`/v1/sistemas`, 'lighthouse', {}, { empresaId: filters?.companies?.id || 0 })
        .catch(() => setLoading(false));

      if (systems) {
        setFiltersContext({
          ...restFilters,
          headerFilters: { ...headerFilters, systems },
        });
      }
    })();

    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangeStartDate = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value: _startDate } = event.target;
    setStartDate(_startDate);
    const _endDate = dateTo || format(new Date(), 'yyyy-MM-dd');
    window.location.href = `/dashboard-geral/${_startDate}/${_endDate}`;
  };

  const handleChangeEndDate = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (startDate) {
      const { value: _endDate } = event.target;
      setEndDate(_endDate);
      window.location.href = `/dashboard-geral/${startDate}/${_endDate}`;
    }
  };
  const tabs = [
    {
      id: 0,
      title: 'Horas Técnicas',
      content: (
        <>
          <DashFullGrid>
            <DashColumn>
              {/* Horas Técnicas Investidas - Atividades x Ocorrências por período */}
              <LkpChartActivitiesOccurrencesPeriod headerFilters={filters} setToastList={setToastList} />
            </DashColumn>
          </DashFullGrid>
          <DashFullGrid>
            <DashColumn>
              {/* Horas Técnicas Investidas - Atividades x Ocorrências */}
              <LkpChartActivitiesOccurrences headerFilters={filters} setToastList={setToastList} />
            </DashColumn>
          </DashFullGrid>
          <DashFullGrid>
            <DashColumn>
              {/* Horas Técnicas Investidas por sistema comparativo entre meses */}
              <LkpChartComparativeSystemBetweenMonths headerFilters={filters} setToastList={setToastList} />
            </DashColumn>
          </DashFullGrid>
        </>
      ),
      propertyContent: 'graficos de horas tecnicas',
    },
    {
      id: 1,
      title: 'Sistemas',
      content: (
        <>
          <DashFullGrid>
            <DashColumn>
              {/* Entraram - Ocorrências necessárias por Sistema */}
              <LkpChartOccurrencesRequiredBySystem
                headerFilters={filters}
                title="Entraram - Ocorrências necessárias por Sistema "
                httpPath="/entrada-ocorrencias-por-sistema-periodo"
              />
            </DashColumn>
          </DashFullGrid>
          <DashFullGrid>
            <DashColumn>
              {/* Saíram - Ocorrências necessárias por Sistema */}
              <LkpChartOccurrencesRequiredBySystem
                headerFilters={filters}
                title="Saíram - Ocorrências necessárias por Sistema"
                httpPath="/saida-ocorrencias-por-sistema-periodo"
              />
            </DashColumn>
          </DashFullGrid>
          <DashFullGrid>
            <DashColumn>
              {/* Quantidade de ocorrências por sistema */}
              <LkpChartNumberOfOccurrencesPerSystem headerFilters={filters} />
            </DashColumn>
          </DashFullGrid>
        </>
      ),
      propertyContent: 'graficos de sistemas',
    },
    {
      id: 2,
      title: 'Ocorrências e Atividades',
      content: (
        <>
          <DashFullGrid>
            <DashColumn>
              {/* Atividades - Comparativo entre semanas */}
              <LkpChartComparisonBetweenWeeks headerFilters={filters} />
            </DashColumn>
          </DashFullGrid>
          <DashFullGrid>
            <DashColumn>
              {/* Quantidade de ocorrências cadastradas por semana */}
              <LkpChartNumberOfOccurrencesRegisteredPerWeek headerFilters={filters} />
            </DashColumn>
          </DashFullGrid>
          <DashFullGrid>
            <DashColumn>
              {/* Entrada e Saída de Ocorrências comparativo entre semanas */}
              <LkpChartComparativeBetweenWeeks headerFilters={filters} />
            </DashColumn>
          </DashFullGrid>
          <DashFullGrid>
            <DashColumn>
              {/* Quantidade de ocorrências cadastradas por prioridade */}
              <LkpChartNumberOccurrencesByPriority headerFilters={filters} />
            </DashColumn>
          </DashFullGrid>
          <DashFullGrid>
            <DashColumn>
              {/* Ocorrências por status de correção */}
              <LkpChartOccurrencesByFixStatus headerFilters={filters} />
            </DashColumn>
          </DashFullGrid>
        </>
      ),
      propertyContent: 'graficos de ocorrências e atividades',
    },
  ];
  const handleGoToOldCharts = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    window.open(URLS.oldDashboard);
  };
  const activeTab = React.useState(tabs[0]);

  const [isSubMenuOpen, setIsSubMenuOpen] = React.useState(false);
  const propsNewMenu = {
    setIsSubMenuOpen,
  };
  return (
    <>
      <PageWrapper>
        <MobileNavbar />
        {/* <Menu /> */}
        <NewMenu {...propsNewMenu} />
        <HeaderFiltersPage monitoringPage={false} isSubMenuOpen={isSubMenuOpen}>
          {isLoading && <Loading />}
          <DashWrapper isLoading={isLoading}>
            <DashHeader>
              <h1>Dashboard</h1>
              <LkpFilterDate
                onChangeStartDate={handleChangeStartDate}
                onChangeEndDate={handleChangeEndDate}
                defaultStartDate={startDate}
                defaultEndDate={endDate}
              />
              <OldChartButton onClick={(e: { preventDefault: () => void }) => handleGoToOldCharts(e)}>
                {' '}
                <OldChartIcon /> VER GRÁFICOS ANTIGOS
              </OldChartButton>
            </DashHeader>
            <ChartContainerTabs activeTab={activeTab[0].id} tabs={tabs} />
          </DashWrapper>
        </HeaderFiltersPage>
      </PageWrapper>
      <Toast toastList={toastList} autoDelete dismissTime={5000} />
    </>
  );
}
