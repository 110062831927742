import styled from 'styled-components';

import { colors } from 'assets/styled/tokens';

export const StyledLineSeparator = styled.div`
  position: relative;
  width: 100%;
  height: 100%;

  &:before {
    position: absolute;
    left: 50%;
    width: 1px;
    height: 100%;
    background-color: ${colors.whiteGray};
    display: ${props => (props.show ? 'block' : 'none')};
  }
`;
