import { MobileNavbar, PageWrapper } from 'components';
import { NewMenu } from 'components/Menu/NewMenu';
import React, { useState } from 'react';
import { Redirect, useLocation, useHistory } from 'react-router-dom';
import { getLocalAuthStorage } from 'utils/localStorage';
import { MainContentWrapper } from './styled';

const Index = () => {
  const { isAuthenticated, token } = getLocalAuthStorage();
  const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);
  const queryString = useLocation().search;
  var searchToken = new URLSearchParams(queryString).get('token');
  const history = useHistory();
  if (isAuthenticated && token) {
    if (process.env.NODE_ENV === 'development') {
      return (
        <PageWrapper>
          <div style={{ marginRight: 30 }}>
            <MobileNavbar />
            {/* <Menu /> */}
            <NewMenu setIsSubMenuOpen={setIsSubMenuOpen} />
          </div>
          <MainContentWrapper isSubMenuOpen={isSubMenuOpen} style={{ paddingTop: 30 }}>
            <h1>ENV: {process.env.NODE_ENV}</h1>
            <div style={{ display: 'flex', width: '100%', justifyContent: 'space-evenly' }}>
              <div style={{ width: '48%', background: 'red' }}>teste</div>
              <div style={{ width: '48%', background: 'blue' }}>teste</div>
            </div>
          </MainContentWrapper>
        </PageWrapper>
      );
    }

    // redirect only in production and homolog
    if (searchToken === undefined || searchToken === null || searchToken === '') {
      searchToken = token;
    }
    //window.location.href = `${process.env.REACT_APP_LEGADO}/Security/SignIn.aspx?${searchToken}`;
    history.push('/home');
    return <></>;
  }

  return <Redirect to="/login" />;
};

export { Index };
