import BatteryCharge from 'components/Battery/BatteryCharge';
import { StyledFlexColumn, StyledFlexRow } from 'components/StyledUtils/flex';
import { UPDATE_VIEWPORT } from 'pages/Geolocalize/constants';
import { useGeolocalizeStore } from 'pages/Geolocalize/store';
import {
  StyledOnlineOfflineEllipse,
  StyledSeparatorEllipse,
  StyledTechnicianLocation,
  StyledTechnicianName,
  StyledTechnicianStatus,
  // StyledViewRouteButton,
  StyledWhatsappContactButton,
  StyledWhatsappIconComponent,
} from 'pages/Geolocalize/styled';
import TechnicianAvatar from 'pages/Geolocalize/subcomponents/ReactMapbox/TechnicianAvatar';
import React from 'react';

import { StyledAvatarColumn, StyledTechnicianCard, StyledTechnicianInfoContainer } from './styled';

const TechnicianCard = ({ technician }) => {
  const [, dispatch] = useGeolocalizeStore();
  const handleTechnicianCardClick = () => {
    dispatch({
      type: UPDATE_VIEWPORT,
      payload: { latitude: technician.latitude, longitude: technician.longitude, zoom: 14 },
    });
  };

  return (
    <StyledTechnicianCard onClick={handleTechnicianCardClick} key={technician.id}>
      <StyledAvatarColumn>
        <TechnicianAvatar technician={technician} />
        {technician.status === 'online' && <BatteryCharge battery={technician.battery} />}
      </StyledAvatarColumn>

      <StyledFlexColumn>
        <StyledTechnicianName>{technician.name}</StyledTechnicianName>
        <StyledTechnicianInfoContainer>
          <StyledOnlineOfflineEllipse status={technician.status} />
          <StyledTechnicianStatus isOnline={technician.status === 'online'}>
            {technician.status === 'online' ? 'Online' : 'Offline'}
          </StyledTechnicianStatus>
          <StyledSeparatorEllipse width="4" height="4" viewBox="0 0 4 4" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="2" cy="2" r="2" fill="#AAAAAA" />
          </StyledSeparatorEllipse>

          <StyledTechnicianLocation>
            {technician.status === 'online' ? `Última localização:` : `Desde:`}
            <span>{technician.status === 'online' ? technician.time : technician.time + ` de ` + technician.date}</span>
          </StyledTechnicianLocation>
        </StyledTechnicianInfoContainer>
        <StyledFlexRow>
          {technician.number && (
            <StyledWhatsappContactButton
              href={`https://api.whatsapp.com/send?1=pt_BR&phone=55${technician.number.replaceAll('-', '')}`}
              target="_blank"
            >
              <StyledWhatsappIconComponent />
              {technician.number}
            </StyledWhatsappContactButton>
          )}
          {/* <StyledViewRouteButton
            onClick={() =>
              dispatch({
                type: UPDATE_SIDEBAR_ROUTE_SUBVIEW_TECHNICIAN,
                payload: technician,
              })
            }
          >
            Ver rota
          </StyledViewRouteButton> */}
        </StyledFlexRow>
      </StyledFlexColumn>
    </StyledTechnicianCard>
  );
};

export default TechnicianCard;
