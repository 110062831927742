import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { AuthService } from 'services/main/endpoints/AuthService';

import { useForm } from 'react-hook-form';
import { Button } from 'components/Button';
import { Input } from 'components/Form/subcomponents/Input';
import { ModalGeneric } from 'components/ModalGeneric';

import {
  BodyContainer,
  StyledWelcome,
  StyledForm,
  StyledPasswordChangedParagraph,
  StyledFormPass,
  StyledFormGroup,
  StyledFormFooter,
  StyledLeankeepLogo,
  StyledError,
  StyledErrorToast,
  StyledSupportEmail,
  StyledModalBody,
} from './styled';

const ResetPassword = () => {
  const { register, errors, handleSubmit, getValues } = useForm({
    mode: 'all',
  });
  const { token } = useParams();
  const [hasResetPasswordExpired, setHasResetPasswordExpired] = useState(false);
  const [hasResetPasswordFailed, setHasResetPasswordFailed] = useState(false);
  const [error, setError] = useState(undefined);
  const [passwordSuccess, setPasswordSuccess] = useState(false);

  const onSubmit = async values => {
    if (values.newPassword !== values.passwordConfirmation) {
      setError('Escolha outra senha');
    }
    try {
      await AuthService.resetPassword({
        password: values.newPassword,
        token: token,
      });

      setPasswordSuccess(true);
    } catch (error) {
      if (!hasResetPasswordExpired) {
        setHasResetPasswordFailed(true);
      }
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        await AuthService.validateResetPasswordToken({
          passwordResetToken: token,
        });
        setHasResetPasswordExpired(false);
      } catch (error) {
        setHasResetPasswordExpired(true);
      }
    };
    fetchData();
  }, [token]);

  return (
    <BodyContainer>
      <StyledForm onSubmit={handleSubmit(onSubmit)}>
        <StyledLeankeepLogo width="165" height="44" viewBox="0 0 165 44" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M163.261 3.42844L158.068 0.464929C157.542 0.160394 156.944 0 156.335 0C155.727 0 155.129 0.160394 154.602 0.464929L149.41 3.42844C148.881 3.72962 148.442 4.16475 148.136 4.68974C147.831 5.21473 147.67 5.8109 147.67 6.41782V12.3319C147.67 12.9405 147.831 13.5383 148.136 14.0653C148.442 14.5923 148.881 15.0299 149.41 15.3342L154.602 18.2848C155.129 18.5893 155.727 18.7497 156.335 18.7497C156.944 18.7497 157.542 18.5893 158.068 18.2848L163.261 15.3342C163.79 15.0299 164.229 14.5923 164.534 14.0653C164.84 13.5383 165.001 12.9405 165.001 12.3319V6.41782C165.001 5.8109 164.84 5.21473 164.535 4.68974C164.229 4.16475 163.79 3.72962 163.261 3.42844ZM156.381 3.33785C156.638 3.33785 156.889 3.41375 157.102 3.55594C157.316 3.69814 157.482 3.90026 157.58 4.13672C157.678 4.37319 157.704 4.63339 157.654 4.88442C157.604 5.13545 157.48 5.36604 157.299 5.54703C157.117 5.72801 156.886 5.85126 156.634 5.9012C156.382 5.95113 156.121 5.9255 155.884 5.82756C155.647 5.72961 155.444 5.56374 155.301 5.35092C155.159 5.13811 155.083 4.88791 155.083 4.63196C155.083 4.28874 155.219 3.95957 155.463 3.71688C155.706 3.47419 156.036 3.33785 156.381 3.33785ZM152.045 6.79312H160.73V8.52722H152.045V6.79312ZM159.6 14.2601L156.472 11.1672C156.464 11.1538 156.452 11.1427 156.439 11.135C156.425 11.1273 156.409 11.1233 156.394 11.1233C156.378 11.1233 156.363 11.1273 156.349 11.135C156.335 11.1427 156.324 11.1538 156.316 11.1672L153.187 14.2472L151.629 13.3413L155.083 9.9378C155.43 9.5995 155.896 9.41006 156.381 9.41006C156.866 9.41006 157.332 9.5995 157.679 9.9378L161.119 13.3413L159.6 14.2601Z"
            fill="#1E1E1E"
          />
          <path
            d="M4.92086 32.8833C5.15427 33.0985 5.42824 33.2654 5.72686 33.3742C6.02548 33.4831 6.3428 33.5317 6.66044 33.5174H15.3324V37.7232H6.0373C5.21052 37.7341 4.39118 37.5663 3.63565 37.2315C2.86817 36.8405 2.18119 36.3094 1.61047 35.6656C1.14661 35.1372 0.76978 34.5388 0.494027 33.8927C0.155158 33.0315 -0.0123932 32.1129 0.000713628 31.188V15.7363H4.3886V31.188C4.35423 31.4919 4.38381 31.7997 4.47545 32.0915C4.5671 32.3834 4.7188 32.6531 4.92086 32.8833Z"
            fill="#1E1E1E"
          />
          <path
            d="M17.186 25.6914C17.1748 24.8032 17.3471 23.9222 17.6923 23.1032C18.0039 22.3367 18.4427 21.628 18.9905 21.0068C19.5417 20.4085 20.2027 19.9209 20.9378 19.5703C21.6191 19.2294 22.3695 19.0479 23.1317 19.0397H27.2989C28.2024 19.0172 29.0965 19.2267 29.8953 19.648C30.61 20.0509 31.2367 20.5921 31.7387 21.2397C32.2379 21.8671 32.6325 22.5707 32.9071 23.3232C33.1791 24.0386 33.3198 24.797 33.3225 25.562V30.2985H21.47V31.2949C21.4343 31.64 21.483 31.9886 21.6118 32.3109C21.7407 32.6332 21.9459 32.9195 22.21 33.1455C22.7761 33.5375 23.4558 33.733 24.1443 33.702H32.6734V37.7784H23.1966C22.3868 37.7867 21.585 37.6189 20.8469 37.2866C20.1301 36.9803 19.4782 36.5411 18.9256 35.9925C18.3686 35.4125 17.928 34.7316 17.6274 33.9867C17.3046 33.1623 17.1459 32.2832 17.16 31.3985L17.186 25.6914ZM21.535 26.7914H29.1034V26.6103C29.1424 25.7649 28.8854 24.9323 28.3764 24.255C28.1462 23.9537 27.8469 23.7119 27.5035 23.5497C27.1602 23.3876 26.7829 23.3099 26.4032 23.3232H24.1054C23.7328 23.3194 23.3657 23.4131 23.0409 23.595C22.7292 23.7785 22.4559 24.0201 22.236 24.3067C22.0074 24.6232 21.828 24.9722 21.7037 25.342C21.5702 25.7603 21.5045 26.1972 21.509 26.6361L21.535 26.7914Z"
            fill="#1E1E1E"
          />
          <path
            d="M36.3217 24.964C36.3974 24.2014 36.5764 23.4527 36.8539 22.7381C37.1472 22.0311 37.59 21.3955 38.1521 20.8746C38.7286 20.3125 39.413 19.8723 40.1643 19.5805C41.05 19.2398 41.9933 19.0729 42.9425 19.0887H45.1364C46.1205 19.0663 47.0982 19.2516 48.0054 19.6323C48.776 19.9733 49.4693 20.4663 50.0436 21.0817C50.5753 21.6738 50.9855 22.364 51.2509 23.1134C51.5102 23.8587 51.6461 24.6411 51.6533 25.4299V37.6592H41.2678C40.523 37.6721 39.7853 37.5126 39.1128 37.1933C38.4991 36.9125 37.9543 36.5018 37.516 35.9898C37.0458 35.4676 36.6957 34.8492 36.4904 34.178C36.2789 33.4683 36.174 32.7313 36.1789 31.991C36.1748 31.2699 36.2888 30.5531 36.5164 29.8686C36.7108 29.2441 37.0336 28.6668 37.4641 28.1734C37.8916 27.6858 38.4242 27.301 39.0219 27.0475C39.6827 26.7522 40.4009 26.6063 41.125 26.6204H47.3823V26.2581C47.3851 25.8497 47.346 25.4421 47.2654 25.0416C47.1992 24.6941 47.0623 24.3637 46.863 24.071C46.6362 23.7793 46.3296 23.5591 45.9802 23.4369C45.487 23.2743 44.9668 23.2083 44.4484 23.2428H43.5396C42.8166 23.1884 42.0928 23.3363 41.4495 23.6699C41.0153 23.9804 40.7141 24.4423 40.6057 24.964H36.3217ZM40.0994 32.0427C40.0632 32.5376 40.1959 33.0302 40.4759 33.4404C40.576 33.5776 40.7066 33.69 40.8575 33.7685C41.0084 33.8471 41.1755 33.8898 41.3457 33.8933H47.3823V30.1792H41.3457C41.1746 30.1852 41.0071 30.2303 40.8563 30.3111C40.7055 30.3919 40.5753 30.5062 40.4759 30.6451C40.2013 31.0578 40.0692 31.5485 40.0994 32.0427Z"
            fill="#1E1E1E"
          />
          <path
            d="M59.7694 23.3199V37.6586H55.4854V19.0882H64.4169C65.3965 19.0696 66.3692 19.2547 67.2729 19.6317C68.0338 19.956 68.7201 20.4322 69.2894 21.0309C69.8587 21.6296 70.2991 22.3381 70.5833 23.1129C70.8529 23.8559 70.9934 24.6392 70.9987 25.4293V37.6586H66.7147V26.3093C66.7216 25.9014 66.6868 25.4938 66.6109 25.0929C66.5478 24.7422 66.4058 24.4103 66.1954 24.1223C65.9607 23.8332 65.6509 23.6138 65.2997 23.4882C64.809 23.3349 64.2932 23.2778 63.7808 23.3199H59.7694Z"
            fill="#1E1E1E"
          />
          <path
            d="M93.9884 25.6881C93.9739 24.7982 94.151 23.9157 94.5077 23.0998C94.8193 22.3333 95.2581 21.6246 95.8059 21.0034C96.3524 20.4055 97.0091 19.9178 97.7402 19.5669C98.4225 19.2286 99.1722 19.0472 99.9342 19.0363H104.166C105.07 19.0103 105.965 19.22 106.763 19.6446C107.468 20.045 108.083 20.587 108.567 21.2363C109.076 21.8574 109.471 22.5626 109.736 23.3198C110.01 24.035 110.155 24.793 110.164 25.5586V30.2951H98.3114V31.2915C98.2741 31.6356 98.3208 31.9835 98.4473 32.3057C98.5739 32.6279 98.7767 32.9148 99.0384 33.1421C99.6053 33.5324 100.284 33.7277 100.973 33.6986H109.502V37.775H100.025C99.2193 37.7848 98.4213 37.6169 97.6883 37.2833C96.968 36.9833 96.3149 36.5434 95.767 35.9892C95.2056 35.4126 94.7643 34.7308 94.4688 33.9833C94.1353 33.1616 93.9719 32.2813 93.9884 31.3951V25.6881ZM98.3374 26.7881H105.919V26.6069C105.951 25.7623 105.695 24.9318 105.192 24.2516C104.959 23.9501 104.657 23.7083 104.312 23.5463C103.966 23.3842 103.587 23.3066 103.206 23.3198H100.947C100.574 23.3142 100.206 23.408 99.8822 23.5916C99.567 23.7702 99.2929 24.0127 99.0774 24.3034C98.844 24.6169 98.6641 24.9667 98.5451 25.3386C98.4072 25.7562 98.3371 26.1931 98.3374 26.6328V26.7881Z"
            fill="#1E1E1E"
          />
          <path
            d="M113.255 25.6914C113.244 24.8032 113.416 23.9222 113.761 23.1032C114.073 22.3367 114.512 21.628 115.06 21.0068C115.608 20.4056 116.27 19.9175 117.007 19.5703C117.688 19.2294 118.439 19.0479 119.201 19.0397H123.368C124.271 19.0172 125.166 19.2267 125.964 19.648C126.684 20.0495 127.315 20.5908 127.821 21.2397C128.32 21.8671 128.714 22.5707 128.989 23.3232C129.255 24.0404 129.395 24.7976 129.405 25.562V30.2985H117.552V31.2949C117.518 31.6387 117.566 31.9856 117.693 32.3073C117.819 32.6289 118.02 32.9162 118.279 33.1455C118.851 33.5359 119.534 33.7311 120.226 33.702H128.755V37.7784H119.279C118.469 37.7867 117.667 37.6189 116.929 37.2866C116.212 36.9803 115.56 36.5411 115.008 35.9925C114.451 35.4125 114.01 34.7316 113.709 33.9867C113.387 33.1623 113.228 32.2832 113.242 31.3985L113.255 25.6914ZM117.604 26.7914H125.172V26.6103C125.211 25.7649 124.954 24.9323 124.445 24.255C124.215 23.9537 123.916 23.7119 123.573 23.5497C123.229 23.3876 122.852 23.3099 122.472 23.3232H120.2C119.828 23.3194 119.461 23.4131 119.136 23.595C118.824 23.7785 118.551 24.0201 118.331 24.3067C118.102 24.6232 117.923 24.9722 117.799 25.342C117.665 25.7603 117.599 26.1972 117.604 26.6361V26.7914Z"
            fill="#1E1E1E"
          />
          <path
            d="M140.892 19.0879C141.871 19.0725 142.844 19.2392 143.761 19.5797C144.57 19.8843 145.308 20.3511 145.929 20.9514C146.511 21.5514 146.954 22.2715 147.227 23.0608C147.562 23.9586 147.725 24.9112 147.708 25.869V30.8643C147.721 31.8175 147.558 32.7651 147.227 33.6595C146.955 34.4606 146.512 35.1934 145.929 35.8078C145.315 36.4166 144.575 36.8848 143.761 37.1795C142.841 37.5093 141.869 37.6715 140.892 37.6583H139.724C138.726 37.6686 137.736 37.493 136.803 37.1407V43.9995H132.558V19.0879H140.892ZM136.803 23.2808V30.7219C136.786 31.0816 136.841 31.441 136.966 31.7789C137.091 32.1168 137.283 32.4262 137.53 32.689C137.772 32.9446 138.067 33.1451 138.394 33.2769C138.721 33.4086 139.073 33.4685 139.425 33.4525H140.892C141.252 33.4668 141.611 33.4064 141.946 33.2749C142.281 33.1435 142.586 32.9439 142.839 32.689C143.094 32.4299 143.293 32.1219 143.425 31.7838C143.556 31.4457 143.618 31.0844 143.605 30.7219V25.9855C143.621 25.6245 143.562 25.2642 143.43 24.9276C143.298 24.5911 143.097 24.2858 142.839 24.0314C142.582 23.7825 142.277 23.5878 141.942 23.4588C141.608 23.3299 141.25 23.2693 140.892 23.2808H136.803Z"
            fill="#1E1E1E"
          />
          <path
            d="M79.5014 30.1513V37.7865H74.9707V18.9443H79.5014V26.4113H81.1501L87.0049 19.009H92.4833L85.0187 28.0678L92.8079 37.7477H87.2905L81.2799 30.1125L79.5014 30.1513Z"
            fill="#1E1E1E"
          />
        </StyledLeankeepLogo>

        {!passwordSuccess ? (
          <>
            <StyledWelcome>
              <h3>Redefinir senha</h3>
              <span>Sua nova senha deve ser de 6 a 20 caracteres, com pelo menos uma letra e um número.</span>
            </StyledWelcome>
            <StyledFormPass>
              <StyledFormGroup label="Nova Senha" isPassword>
                <Input
                  type="password"
                  name="newPassword"
                  size="medium"
                  error={errors?.newPassword}
                  errormessage="Escolha uma senha de 6 a 20 caracteres com letras e números"
                  id="newPassword"
                  isPassword
                  placeholder="&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;"
                  ref={register({
                    pattern: {
                      value: /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{6,20}$/,
                      message: 'Sua senha deve conter, entre 6 e 20 caracteres, uma letra e um número', // JS only: <p>error message</p> TS only support string
                    },
                  })}
                />
              </StyledFormGroup>
              <StyledFormGroup label="Confirmar Senha" isPassword>
                <Input
                  type="password"
                  name="passwordConfirmation"
                  size="medium"
                  error={errors?.passwordConfirmation}
                  errormessage={'Senhas não coincidem'}
                  placeholder="&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;"
                  isPassword
                  ref={register({
                    validate: {
                      matchesPreviousPassword: value => {
                        const { newPassword } = getValues();
                        return newPassword === value;
                      },
                    },
                  })}
                />
                {errors.passwordConfirmation && (
                  <StyledError>
                    <p>{errors.passwordConfirmation.message}</p>
                  </StyledError>
                )}
              </StyledFormGroup>
              <StyledFormFooter>
                <Button type="submit">Enviar</Button>
              </StyledFormFooter>
            </StyledFormPass>
          </>
        ) : (
          <StyledWelcome>
            <h3>Senha atualizada!</h3>
            <StyledPasswordChangedParagraph>Ótimo, sua nova senha foi definida.</StyledPasswordChangedParagraph>
          </StyledWelcome>
        )}
        <span>
          Dúvidas entre em contato pelo{' '}
          <StyledSupportEmail href="mailto:suporte@leankeep.com.br?subject=Duvida">
            suporte@leankeep.com.br
          </StyledSupportEmail>
        </span>
        <StyledErrorToast isActive={error}>
          <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M17.802 13.7796L10.2965 0.738867C9.73362 -0.23059 8.2638 -0.261863 7.70089 0.738867L0.195417 13.7796C-0.367494 14.7491 0.351781 16 1.50888 16H16.4886C17.6456 16 18.3649 14.7804 17.802 13.7796ZM9.01435 11.0589C9.79617 11.0589 10.4529 11.7156 10.4529 12.4974C10.4529 13.3105 9.79617 13.936 9.01435 13.936C8.20126 13.936 7.5758 13.3105 7.5758 12.4974C7.5758 11.7156 8.20126 11.0589 9.01435 11.0589ZM7.63835 5.89888C7.60707 5.67997 7.79471 5.49233 8.01362 5.49233H9.98381C10.2027 5.49233 10.3904 5.67997 10.3591 5.89888L10.1402 10.152C10.1089 10.3709 9.95253 10.496 9.7649 10.496H8.23253C8.04489 10.496 7.88853 10.3709 7.85726 10.152L7.63835 5.89888Z"
              fill="white"
            />
          </svg>

          <p>{error}</p>
          <svg
            onClick={() => setError(undefined)}
            width="12"
            height="12"
            viewBox="0 0 12 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10.9961 10.999L0.998117 0.999978"
              stroke="white"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M1 10.999L10.998 0.999978"
              stroke="white"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </StyledErrorToast>
      </StyledForm>
      <ModalGeneric
        open={hasResetPasswordExpired}
        closeModal={() => {
          setHasResetPasswordExpired(false);
          window.location.replace('/login');
        }}
      >
        <StyledModalBody>
          <svg width="80" height="88" viewBox="0 0 80 88" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M1.75436e-05 30.1139V57.8454C-0.00416577 60.7046 0.739864 63.5143 2.15712 65.9914C3.57437 68.4686 5.61477 70.5256 8.07267 71.9552L31.9273 85.8209C34.3817 87.2485 37.1659 88 40 88C42.8341 88 45.6183 87.2485 48.0727 85.8209L71.9273 71.9552C74.3852 70.5256 76.4256 68.4686 77.8429 65.9914C79.2601 63.5143 80.0042 60.7046 80 57.8454V30.1139C79.9971 27.2617 79.2497 24.4603 77.8328 21.9909C76.4159 19.5216 74.3793 17.471 71.9273 16.0448L48.0727 2.17907C45.6183 0.751537 42.8341 0 40 0C37.1659 0 34.3817 0.751537 31.9273 2.17907L8.07267 16.2481C5.65006 17.6568 3.63231 19.6755 2.21715 22.1062C0.802002 24.5368 0.0379999 27.2962 1.75436e-05 30.1139Z"
              fill="#FFDBD6"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M55.2448 34.21L45.5035 43.9513L55.2439 53.7891L55.2524 53.797C56.2492 54.7171 56.2492 56.2415 55.2524 57.1616L55.2448 57.1686L53.1686 59.2448L53.1616 59.2524C52.2415 60.2492 50.7171 60.2492 49.797 59.2524L49.79 59.2448L40.0487 49.5035L30.2109 59.2439L30.203 59.2524C29.2829 60.2492 27.7585 60.2492 26.8384 59.2524L26.8314 59.2448L24.7552 57.1686L24.7476 57.1616C23.7508 56.2415 23.7508 54.7171 24.7476 53.797L24.7561 53.7891L34.4965 43.9513L24.7552 34.21L24.7476 34.203C23.7508 33.2829 23.7508 31.7585 24.7476 30.8384L24.7552 30.8314L26.8314 28.7552L26.8384 28.7476C27.7585 27.7508 29.2829 27.7508 30.203 28.7476L30.2109 28.7561L40.0487 38.4965L49.79 28.7552L49.797 28.7476C50.7171 27.7508 52.2415 27.7508 53.1616 28.7476L53.1686 28.7552L55.2448 30.8314L55.2524 30.8384C56.2492 31.7585 56.2492 33.2829 55.2524 34.203L55.2448 34.21Z"
              fill="#EC6655"
            />
          </svg>

          <h6>Sua solicitação de redefinição de senha expirou</h6>
          <span>Faça uma nova solicitação na página de login, por favor.</span>
          <Button type="button" onClick={() => window.location.replace('/login')}>
            Entendi
          </Button>
        </StyledModalBody>
      </ModalGeneric>
      <ModalGeneric
        open={hasResetPasswordFailed}
        closeModal={() => {
          setHasResetPasswordFailed(false);
        }}
      >
        <StyledModalBody>
          <svg width="80" height="88" viewBox="0 0 80 88" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M1.75436e-05 30.1139V57.8454C-0.00416577 60.7046 0.739864 63.5143 2.15712 65.9914C3.57437 68.4686 5.61477 70.5256 8.07267 71.9552L31.9273 85.8209C34.3817 87.2485 37.1659 88 40 88C42.8341 88 45.6183 87.2485 48.0727 85.8209L71.9273 71.9552C74.3852 70.5256 76.4256 68.4686 77.8429 65.9914C79.2601 63.5143 80.0042 60.7046 80 57.8454V30.1139C79.9971 27.2617 79.2497 24.4603 77.8328 21.9909C76.4159 19.5216 74.3793 17.471 71.9273 16.0448L48.0727 2.17907C45.6183 0.751537 42.8341 0 40 0C37.1659 0 34.3817 0.751537 31.9273 2.17907L8.07267 16.2481C5.65006 17.6568 3.63231 19.6755 2.21715 22.1062C0.802002 24.5368 0.0379999 27.2962 1.75436e-05 30.1139Z"
              fill="#FFDBD6"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M55.2448 34.21L45.5035 43.9513L55.2439 53.7891L55.2524 53.797C56.2492 54.7171 56.2492 56.2415 55.2524 57.1616L55.2448 57.1686L53.1686 59.2448L53.1616 59.2524C52.2415 60.2492 50.7171 60.2492 49.797 59.2524L49.79 59.2448L40.0487 49.5035L30.2109 59.2439L30.203 59.2524C29.2829 60.2492 27.7585 60.2492 26.8384 59.2524L26.8314 59.2448L24.7552 57.1686L24.7476 57.1616C23.7508 56.2415 23.7508 54.7171 24.7476 53.797L24.7561 53.7891L34.4965 43.9513L24.7552 34.21L24.7476 34.203C23.7508 33.2829 23.7508 31.7585 24.7476 30.8384L24.7552 30.8314L26.8314 28.7552L26.8384 28.7476C27.7585 27.7508 29.2829 27.7508 30.203 28.7476L30.2109 28.7561L40.0487 38.4965L49.79 28.7552L49.797 28.7476C50.7171 27.7508 52.2415 27.7508 53.1616 28.7476L53.1686 28.7552L55.2448 30.8314L55.2524 30.8384C56.2492 31.7585 56.2492 33.2829 55.2524 34.203L55.2448 34.21Z"
              fill="#EC6655"
            />
          </svg>

          <h6>Ops! Algo deu errado</h6>
          <span>Verifique sua conexão com a internet e tente novamente, por favor.</span>
          <Button type="button" onClick={() => window.location.replace('/login')}>
            Entendi
          </Button>
        </StyledModalBody>
      </ModalGeneric>
    </BodyContainer>
  );
};

export { ResetPassword };
