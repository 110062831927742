import thumbnail1a from './assets/thumbnail-1-a.svg';
import thumbnail1b from './assets/thumbnail-1-b.svg';
import thumbnail1c from './assets/thumbnail-1-c.svg';
import thumbnail1d from './assets/thumbnail-1-d.svg';
import thumbnail2a from './assets/thumbnail-2-a.svg';
import thumbnail2b from './assets/thumbnail-2-b.svg';
import thumbnail3 from './assets/thumbnail-3.svg';

const getFirstThumbnail = ({ showPicture, showSignature }) => {
  if (showPicture && !showSignature) {
    return thumbnail1b;
  }

  if (!showPicture && showSignature) {
    return thumbnail1c;
  }

  if (showPicture && showSignature) {
    return thumbnail1d;
  }

  return thumbnail1a;
};

const getSecondThumbnail = ({ showAuditOccurrence, showAuditOccurrencePicture }) => {
  if (showAuditOccurrence && !showAuditOccurrencePicture) {
    return thumbnail2a;
  }

  if (showAuditOccurrence && showAuditOccurrencePicture) {
    return thumbnail2b;
  }
};

const getThirdThumbnail = showCompare => {
  if (showCompare) {
    return thumbnail3;
  }
};

const previewAuditReportPdfFactory = ({
  showPicture,
  showSignature,
  showAuditOccurrence,
  showAuditOccurrencePicture,
  showCompare,
}) => {
  const result = [];

  const firstThumbnail = getFirstThumbnail({
    showPicture,
    showSignature,
  });

  result.push(firstThumbnail);

  const secondThumbnail = getSecondThumbnail({
    showAuditOccurrence,
    showAuditOccurrencePicture,
  });

  if (secondThumbnail) {
    result.push(secondThumbnail);
  }

  const thirdThumbnail = getThirdThumbnail(showCompare);

  if (thirdThumbnail) {
    result.push(thirdThumbnail);
  }

  return result;
};

export { previewAuditReportPdfFactory };
