import styled from 'styled-components';
import { fonts, colors } from 'assets/styled/tokens';

export const StyledPreview = styled.div`
  display: block;
  margin-top: 15px;
  width: 100%;

  @media screen and (max-width: 860px) {
    margin-bottom: 15px;
  }
`;

export const StyledPreviewTitle = styled.h6`
  display: inline-block;
  margin: 0;
  font-family: ${fonts.family.title};
  font-size: 16px;
  color: ${colors.primary};
`;

export const StyledPreviewDescription = styled.p`
  display: inline-block;
  margin: 0;
  font-family: ${fonts.family.text};
  font-size: 12px;
  color: ${colors.gray};
`;

export const StyledPreviewContainer = styled.div`
  margin-top: 10px;
  padding: ${props => (props.size > 1 ? ' 8px 1px 8px 8px' : ' 8px 8px 8px 8px')};
  max-height: 280px;
  background-color: ${colors.mediumGray};
  border: 1px solid ${colors.silver3};
  border-radius: 3px;
  overflow-y: auto;

  img {
    margin-top: 8px;

    &:first-child {
      margin-top: 0;
    }
  }

  &::-webkit-scrollbar {
    width: 15px;

    &:before {
      content: '';
      margin: 20px;
    }
  }

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    width: 15px;
  }

  &::-webkit-scrollbar-track {
    margin: 3px;
  }

  &::-webkit-scrollbar-thumb {
    background: ${colors.silver3};
    border: 5px solid ${colors.mediumGray};
    border-radius: 100px;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: ${colors.silver3};
  }
`;
