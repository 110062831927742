import styled from 'styled-components';

import { inputStyleFactory } from 'components/Form/shared/inputStyleFactory';

import { DefaultSelect } from 'assets/styled/sheets/components/Forms';
import { colors, fonts } from 'assets/styled/tokens';
import PaginationRange from 'components/Tables/PaginationRange';
import { spinAnimationCss } from 'utils/helpers/css';

export const StyledPaginatedSelectWrapper = styled.div`
  position: relative;
  display: ${({ isActive }) => (isActive ? 'flex' : 'none')};
  flex-direction: column;
`;

export const StyledSpinner = styled.svg`
  position: absolute;
  right: 50%;
  top: 50%;
  z-index: 5;
  ${spinAnimationCss};
`;
export const StyledPaginatedSelect = styled.input`
  ${DefaultSelect};
  ${props => inputStyleFactory(props.size)};
  position: relative;
  display: inline-block;
  width: 100%;
  cursor: pointer;
  color: ${colors.gray};
  cursor: ${props => props.disabled && 'not-allowed'};
  border-color: ${props => props.error && colors.bittersweet};

  &:active {
    pointer-events: ${props => props.disabled && 'none'};
  }

  &::placeholder {
    color: ${props => props.disabled && colors.mediumGray};
  }

  &:focus {
    border-color: ${props => props.disabled && colors.whiteGray};
  }
`;
export const StyledPaginationRange = styled(PaginationRange)`
  position: absolute;
  bottom: 0;
  background: ${colors.alabaster};
`;
export const StyledError = styled.div`
  display: block;
  transform: translateY(2px);
  font-size: 12px;
  margin-left: 2px;
  transition: all 300ms ease;
  p {
    line-height: initial;
    color: ${colors.darkRed};
  }
`;
export const StyledDropdownContentWrapper = styled.section`
  overflow: auto;
  position: relative;
  max-height: 400px;
  &::-webkit-scrollbar {
    width: 20px;
    &:before {
      content: '';
      margin: 20px;
    }
  }

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    width: 15px;
  }

  &::-webkit-scrollbar-track {
    margin: 3px;
  }

  &::-webkit-scrollbar-thumb {
    height: 20px;

    background: ${colors.silver3};
    border: 5px solid ${colors.alabaster};
    border-radius: 100px;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: ${colors.silver3};
  }
`;
export const StyledDropdownContent = styled.ul`
  position: absolute;
  background-color: ${colors.alabaster};
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 2;
  display: ${props => (props.isOpen ? 'block' : 'none')};
  width: 100%;
  ul {
    font-size: 13px;
    border-top: 1px solid ${colors.whiteGray};
    width: 100%;
    padding: 8px 6px;
    li {
      margin: 0 1px;
    }
  }
`;

export const DropdownOption = styled.input.attrs({
  type: 'button',
})`
  width: 0;
  height: 0;
  font-size: 12px;
  line-height: 16px;
  text-align: start;
  border: none;
  background: transparent;
  cursor: pointer;
`;

export const StyledDropdownItem = styled.li`
  font-family: ${fonts.family.text};
  &:hover {
    background: ${colors.desertStorm};
  }
  cursor: pointer;
  font-size: 12px;
  padding: 8px;
  line-height: 16px;
`;

export const StyledSearchBar = styled.div`
  position: relative;
  margin: 8px 0;
  padding: 1px 6px;
  input {
    height: 24px;
  }
  img {
  }
`;
