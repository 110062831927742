import styled from 'styled-components';
import { medias } from 'assets/styled/tokens';
export const Header = styled.header`
  background-color: #1e1e1e;
  height: 280px;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  @media (min-width: ${medias.desktopSm}) {
    height: 200px;
    padding: 0 10%;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }
`;
export const TitleContainer = styled.div`
  height: 80%;
  width: 90%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 10px 20px 0 20px;
  @media (min-width: ${medias.desktopSm}) {
    height: 80%;
    width: 50%;
    display: flex;
    //justify-content: flex-end;
    align-items: center;
    padding: 20px 50px 20px 0;
  }
`;
export const Title = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  svg {
    height: 56px;
    width: 249px;
  }
  @media (min-width: ${medias.desktopSm}) {
    height: 80%;
    width: 84%;
    flex-direction: row;
    align-items: flex-end;
    svg {
      height: 72px;
      width: 320px;
    }
  }
`;
export const CompanyLogo = styled.img`
  height: 72px;
  width: 123px;
  border-radius: 5px;
  margin-top: 40px;
  @media (min-width: ${medias.desktopSm}) {
    margin-left: 40px;
    margin-top: 0;
  }
`;
export const MenuContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 25px 20px;
  width: 100%;
  @media (min-width: ${medias.desktopSm}) {
    height: 80%;
    width: 50%;
    flex-direction: row;
    justify-content: flex-end;
    padding: 20px 20px 20px 0;
  }
`;
