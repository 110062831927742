const { css } = require('styled-components');

const extraSmall = () => css`
  height: 20px;
  padding: 0.125rem 0.438rem;
  font-size: 0.75rem;
  line-height: 14px;
  border: 1px solid invisible;
  border-radius: 3px;
`;

export { extraSmall };
