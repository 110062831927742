/* istanbul ignore file */

import styled from 'styled-components';
import { devices } from 'assets/styled/tokens';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  @media ${devices.tabletLandscape} {
    transform: translateY(-60px);
  }
`;
