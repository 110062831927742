import * as React from 'react';

import { LkpDropdown } from 'components/Dropdown';
import {
  ChartFilterDate,
  ChartFilterDropdown,
  ChartFilterDropdownInner,
  ChartFilterDropdownItem,
  ChartFilterInner,
  ChartFilterWrapper,
} from './styled';
import { LkpFilterSystems } from './FilterSystems';
import { LkpFilterOccurrences } from './FilterOccurrences';
import { LkpFilterFixStatus } from './FilterFixStatus';
import { useParams } from 'react-router-dom';
import { format, isValid, parseISO } from 'date-fns';

export interface LkpChartFilterProps {
  onChangeStartDate?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onChangeEndDate?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onChangeMonth?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  showMonth?: boolean;
  defaultStartDate?: string;
  defaultEndDate?: string;
  onSelectSystems?: (systemsParams: string) => void; // systemsParams = 'Sistemas=123&Sistemas=123&Sistemas=123'
  onSelectOccurrences?: (statusParams: string) => void; // statusParams = 'status=123&status=123&status=123'
  onSelectStatus?: (statusParams: string) => void;
}

export const LkpChartTopFilter = ({
  onChangeMonth,
  showMonth = false,
  onSelectSystems,
  onSelectOccurrences,
  onSelectStatus,
}: LkpChartFilterProps): React.ReactElement => {
  const { dateFrom, dateTo } = useParams<{ dateFrom: string; dateTo: string }>();
  const [startDate, setStartDate] = React.useState<string>();
  const [endDate, setEndDate] = React.useState<string>();

  React.useEffect(() => {
    const _startDate = parseISO(dateFrom);
    const _endDate = parseISO(dateTo);

    if (isValid(_startDate)) setStartDate(format(_startDate, 'dd/MM/yyyy'));
    if (isValid(_endDate)) setEndDate(format(_endDate, 'dd/MM/yyyy'));
  }, [dateFrom, dateTo]);

  return (
    <ChartFilterWrapper>
      <ChartFilterInner>
        <ChartFilterDate>
          {startDate && <span>{startDate}</span>}
          {endDate && <span>{endDate}</span>}
        </ChartFilterDate>
        {onChangeMonth && (
          <>
            <LkpDropdown
              content={
                <ChartFilterDropdown>
                  <ChartFilterDropdownInner>
                    <ChartFilterDropdownItem value="month" onClick={onChangeMonth} selected={showMonth}>
                      Meses
                    </ChartFilterDropdownItem>
                    <ChartFilterDropdownItem value="week" onClick={onChangeMonth} selected={!showMonth}>
                      Semanas
                    </ChartFilterDropdownItem>
                  </ChartFilterDropdownInner>
                </ChartFilterDropdown>
              }
            >
              {showMonth ? <>Meses</> : <>Semanas</>}
            </LkpDropdown>
          </>
        )}
        {onSelectSystems && <LkpFilterSystems onSelectSystems={onSelectSystems} />}
        {onSelectOccurrences && <LkpFilterOccurrences onSelectOccurrences={onSelectOccurrences} />}
        {onSelectStatus && <LkpFilterFixStatus onSelectStatus={onSelectStatus} />}
      </ChartFilterInner>
    </ChartFilterWrapper>
  );
};
