import { colors } from 'assets/styled/tokens';
import styled from 'styled-components';

export const StyledGeneralPlanLastUpdateCell = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 12px;
  line-height: 14px;
  color: ${colors.gray};
`;
export const StyledLastUpdateDate = styled.b`
  color: ${colors.carbon};
`;
