import { Button } from 'components';
import styled from 'styled-components';
import lupaIconSvg from 'assets/icons/magnifying.svg';
import updateIconSvg from 'assets/icons/update.svg';
import { colors, medias } from 'assets/styled/tokens';
import ReactPaginate from 'react-paginate';

export const Paginate = styled(ReactPaginate).attrs({
  className: 'paginationBtn',
  activeClassName: 'paginationActive',
  previousLinkClassName: 'previousBtn',
  nextLinkClassName: 'nextBtn',
  pageLinkClassName: 'pageBtn',
})`
  height: 40px;
  list-style: none;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  li {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    margin: 4px;
    border: 1px solid #e5e5e5;
    border-radius: 4px;
    color: #888888;
    cursor: pointer;

    &:hover {
      color: #1e1e1e;
      background-color: #f5f3f1;
    }
  }
  .paginationActive {
    color: #1e1e1e;
    background-color: #f5f3f1;
    font-weight: 700;
  }
  .previousBtn {
    display: inline-block;
    position: relative;
    z-index: 1;
    padding: 0.2em 0.8em 0.2em 0.8em;
    margin: -2em;
  }
  .nextBtn {
    display: inline-block;
    position: relative;
    z-index: 1;
    padding: 0.2em 0.8em 0.2em 0.8em;
    margin: -2em;
  }
  .pageBtn {
    display: inline-block;
    position: relative;
    z-index: 1;
    padding: 0.2em 0.8em 0.2em 0.8em;
    margin: -2em;
  }
`;

export const ScheduleMonitoringPage = styled.div`
  display: flex;
  flex-direction: row;
  min-height: 100vh;
  width: 100%;
  margin: 0;
  background: ${props => props.background || 'transparent'};
`;

export const TablecontainerWrapper = styled.div`
  border: 1px solid #e5e5e5;
  height: auto;
  min-width: 100%;
  box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  background: #ffffff;
`;
export const StyledContainerHeader = styled.div`
  background: #ffffff;
  margin: 24px 24px 24px 24px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  // padding: 0 0 0 0;
`;

export const StyledContainerSearchBar = styled.input`
  box-sizing: border-box;
  width: 320px;
  height: 32px;
  background: #ffffff;
  border: 1px solid #e5e5e5;
  border-radius: 4px;
  padding-left: 10px;
  ::placeholder {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.3px;
    color: #888888;
    padding: 6px 6px 6px 0;
    background: url(${lupaIconSvg}) no-repeat center/contain;
    background-position: right center;
    background-size: 12px;
    background-position-x: 95%;
  }
`;

export const UpdatePlansButton = styled(Button)`
  border: 1px solid #f38a00;
  border-radius: 4px;
  width: 167px;
  height: 32px;
  padding-left: 25px;
  background-color: #ffffff;
  background: url(${updateIconSvg}) no-repeat center/contain;
  background-size: 12px;
  background-position: left center;
  background-position-x: 5%;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  color: #f38a00;
`;

export const PageTitle = styled.h1`
  width: 334px;
  height: 36px;

  font-family: 'Blinker';
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 36px;

  letter-spacing: 0.5px;

  color: #1e1e1e;
  margin-bottom: 34px;
`;

export const StyledTableContainer = styled.div`
  display: block;
  background-color: ${colors.white};
  border-radius: 6px;
  box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.1);

  display: grid;
  grid-template-areas:
    'header header'
    'main main'
    'footer footer';
  grid-template-columns: 1fr;
`;
export const StyledTableHeader = styled.header`
  grid-area: header;
  width: 100%;
  border: 0 solid ${colors.whiteGray};
  border-bottom-width: 1px;

  @media screen and (min-width: ${medias.desktopMd}) {
    justify-self: center;
  }
`;
export const StyledTableContainerHeader = styled.div`
  display: flex;
  padding: 0 20px;

  div {
    button {
      &:first-child {
        transition: all 0.2s;
        &:hover {
          color: ${colors.white};
          background: ${colors.bittersweet};
          border-color: ${colors.bittersweet};
        }
        svg {
          margin-right: 8px;
        }
      }
    }
  }

  svg {
    overflow: visible;
    cursor: pointer;
  }
`;

export const StyledContentWrapper = styled.div`
  width: 100%;
  display: ${props => props.display || 'flex'};
  flex-direction: ${props => props.flexDirection || 'row'};
  justify-content: ${props => props.justifyContent};
  align-items: ${props => props.alignItems};
  grid-column-start: ${props => props.gridColumnStart};
  gap: ${props => props.gap};
`;

export const StyledContainerFooter = styled.div`
  width: 100%;
  padding: 0px 24px 24px 24px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const LeftContainerFooter = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  h3 {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.3px;
    color: #1e1e1e;
  }
  h4 {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.3px;
    color: #888888;
  }
`;

export const RightContainerFooter = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;
