import { AxiosMenuLas } from '../config';

const MenuService = {
  getUserMenu: ({ tipoUsuario, login, unidade, empresa }) => {
    return AxiosMenuLas({
      method: 'GET',
      url: '/v1/Usuario/UsuarioMenu',
      params: {
        login,
        tipoUsuario,
        unidade,
        empresa,
      },
      headers: {
        'content-type': 'application/json',
      },
    });
  },
};

export { MenuService };
