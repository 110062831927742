import styled from 'styled-components';
import { colors, medias } from 'assets/styled/tokens';

export const StyledPage = styled.div`
  //height: 100%;
  min-height: 100vh;
  width: 100%;
  //overflow-y: inherit;
  display: flex;
  flex-direction: column;
  background: ${colors.pampas};
  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
  & > div {
    padding: ${props => (props.noMargin ? 0 : '24px 24px 48px')};
  }
  @media screen and (max-width: ${medias.desktopXXl}) {
    padding-left: ${props => (props.isSubMenuOpen ? '200px' : '88px')};
  }
  @media screen and (max-width: ${medias.desktopXl}) {
    padding-left: ${props => (props.isSubMenuOpen ? '200px' : '88px')};
  }
  @media screen and (max-width: ${medias.desktopMd}) {
    padding-left: ${props => (props.isSubMenuOpen ? '200px' : '88px')};
    width: ${props => (props.monitoringPage ? 'calc(100% - 180px)' : '')};
  }
  @media screen and (max-width: ${medias.desktopSm}) {
    padding-left: 0;
  }
  @media screen and (max-width: ${medias.tablet}) {
    padding-left: 0;
  }
  @media screen and (max-width: ${medias.mobile}) {
    padding-left: 0;
  }
`;

export const StyledChildren = styled.div`
  //width: ${props => (props.monitoringPage ? 'calc(100% - 180px)' : '')};
`;
