import { unitGroups } from 'components/HeaderFilters/constants';

const unitSubgroupsDependencies = async ({ filterResponse }) => {
  const { unitGroup } = filterResponse;

  if (!unitGroup) {
    return [];
  }

  return [
    {
      filter: unitGroups,
      value: unitGroup,
    },
  ];
};

export { unitSubgroupsDependencies };
