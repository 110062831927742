const handleAuthError = error => {
  // Get data from current localstorage

  // return auth error
  if (error.config.url === '/v1/auth') {
    return new Promise((_, reject) => reject(error.response));
  }
  if (error.response.status !== 401) {
    return Promise.reject(error);
  } else {
    return Promise.reject(error);
  }
};

export { handleAuthError };
