import React from 'react';
import { StyledError } from '../FormGroup/styled';
import { StyledMaskedInput, StyledIcon } from './styled';

export const MaskedInput = React.forwardRef(
  ({ size = 'large', icon, error, errormessage, 'data-cy': dataCy, ...props }, ref) => {
    return (
      <>
        <StyledMaskedInput
          {...props}
          haserror={error ? 1 : 0}
          data-cy={dataCy}
          ref={ref}
          size={size}
          value={props.value || ''}
        />
        {icon && <StyledIcon src={icon} inputSize={size} />}
        <StyledError>
          <p>{error && errormessage}</p>
        </StyledError>
      </>
    );
  },
);
