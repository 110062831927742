import { ReactComponent as BatteryIconComponent } from 'assets/icons/battery.svg';
import { ReactComponent as WhatsappIconComponent } from 'assets/icons/whatsapp.svg';
import { colors, devices } from 'assets/styled/tokens';
import { Button } from 'components/Button';
import { primary } from 'components/Button/ButtonThemeFactory/themes/primary';
import { DateRangePicker } from 'components/Form/subcomponents/DateRangePicker';
import { FormGroup } from 'components/Form/subcomponents/FormGroup';
import { ModalGeneric } from 'components/ModalGeneric';
import styled, { css } from 'styled-components';
import { addTrailingDots, strongTextCss, strongTitleCss, textCss } from 'utils/helpers/css';

import { ReactComponent as OfflineIcon } from './assets/offline-circle.svg';
import { ReactComponent as OnlineIcon } from './assets/online-circle.svg';

export const PageContainer = styled.section`
  display: flex;
  margin: 0;
  width: 100%;
  position: relative;
  overflow: hidden;

  @media ${devices.mobile} {
    .mapboxgl-map,
    .mapboxgl-canvas,
    .mapboxgl-canvas-container {
      width: 100%;
      height: calc(100vh - 44px) !important;
    }
  }
  @media ${devices.screen} {
    .mapboxgl-map,
    .mapboxgl-canvas,
    .mapboxgl-canvas-container {
      width: 100%;
      height: 100vh !important;
    }
  }
  .mapboxgl-popup {
    font-family: Roboto, sans-serif;
  }
  .mapboxgl-popup-content {
    padding: 4px;
    width: 312px;
  }
  .unit-marker-popup > .mapboxgl-popup-content {
    background: ${colors.purpleBlue};
  }
  .unit-marker-popup.mapboxgl-popup-anchor-top > .mapboxgl-popup-tip {
    border-bottom-color: ${colors.purpleBlue};
  }
  .unit-marker-popup.mapboxgl-popup-anchor-bottom > .mapboxgl-popup-tip {
    border-top-color: ${colors.purpleBlue};
  }
  .unit-marker-popup.mapboxgl-popup-anchor-right > .mapboxgl-popup-tip {
    border-left-color: ${colors.purpleBlue};
  }
  .unit-marker-popup.mapboxgl-popup-anchor-left > .mapboxgl-popup-tip {
    border-right-color: ${colors.purpleBlue};
  }
  .technicians-marker-popup > .mapboxgl-popup-content {
    background: ${colors.primary};
  }

  .technicians-marker-popup.mapboxgl-popup-anchor-top > .mapboxgl-popup-tip {
    border-bottom-color: ${colors.primary};
  }
  .technicians-marker-popup.mapboxgl-popup-anchor-bottom > .mapboxgl-popup-tip {
    border-top-color: ${colors.primary};
  }
  .technicians-marker-popup.mapboxgl-popup-anchor-right > .mapboxgl-popup-tip {
    border-left-color: ${colors.primary};
  }
  .technicians-marker-popup.mapboxgl-popup-anchor-left > .mapboxgl-popup-tip {
    border-right-color: ${colors.primary};
  }
  .offline-technician-marker-popup > .mapboxgl-popup-content {
    background: ${colors.gray};
  }
  .offline-technician-marker-popup > .mapboxgl-popup-tip {
    border-top-color: ${colors.gray};
  }
`;

export const PageUserMenu = styled.div`
  position: absolute;
  padding: 8px;
  top: 0;
  right: 0;
  z-index: 5;
  background: ${colors.pampas};
  box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.2);
  border-radius: 0px 0px 0px 8px;
`;
const MapContainerFullScreenCss = css`
  width: 100%;
`;
const MapContainerSidebarOpenCss = css`
  width: 100%;
  position: absolute;
  right: 0;
`;
export const StyledMapContainer = styled.div`
  z-index: 0;
  height: 100%;
  ${({ isFullscreen }) => (isFullscreen ? MapContainerFullScreenCss : MapContainerSidebarOpenCss)};

  .map-container {
    height: 100%;
    width: 100%;
  }
`;
export const StyledHorizontalLine = styled.div`
  height: 1px;
  background: ${colors.whiteGray};
  width: calc(100% + 48px);
`;
export const StyledRoutesReportModal = styled(ModalGeneric)`
  overflow: visible;
  margin-top: -100px;
`;

export const StyledRouteReportFeedbackModal = styled(ModalGeneric)`
  overflow: visible;
`;

export const StyledRoutesReportModalTitleSuccess = styled('h3')`
  margin: 8px 0 16px;
  color: ${colors.successGreen};
  text-align: center;
`;

export const StyledRoutesReportModalTitleError = styled('h3')`
  margin: 8px 0 16px;
  color: ${colors.cinnabar};
  text-align: center;
`;

export const StyledRoutesReportModalFormGroup = styled(FormGroup)`
  margin-top: 24px;
`;
export const StyledUnitFiltersModal = styled(ModalGeneric)`
  max-width: 455px !important;
  max-height: 464px !important;
  justify-content: flex-start !important;
  overflow: visible !important;
  padding: 18px 24px 32px 24px !important;

  header {
    align-self: flex-start;
  }
`;

export const StyledUnitFiltersModalFormGroup = styled(FormGroup)`
  margin-top: 24px;

  > div {
    height: 32px;
  }
`;
export const StyledUnitFiltersButtonRow = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 32px;
  height: 32px;
`;
export const StyledClearUnitsFilterButton = styled(Button)`
  font-size: 14px;
  line-height: 16px;
  width: 110px;
`;
export const StyledFilterUnitsButton = styled(Button)`
  font-size: 14px;
  line-height: 16px;
  width: 124px;
`;

export const StyledStateCityRow = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  ${StyledUnitFiltersModalFormGroup} {
    width: 191px;
  }
`;

export const StyledDateRangePicker = styled(DateRangePicker)`
  width: auto;
`;
export const StyledDownloadRoutesReportExcelButton = styled(Button)`
  font-size: 14px;
  line-height: 15px;
  width: 220px;
  height: 32px;
  margin-right: auto;
  margin-top: 32px;
  svg {
    margin-right: 8px;
  }
`;
export const ErrorText = styled.div`
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.30000001192092896px;
  text-align: left;
  color: #c25446;
`;
export const ReportLimitationContainer = styled.div`
  text-align: left;
  width: 100%;
  margin: 20px 0 0 0;
  ul {
    display: inline-block;
    width: 100%;
    padding-left: 15px;
  }
`;
export const LimitationsHeader = styled.h1`
  font-family: Roboto;
  font-size: 14px;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 0.30000001192092896px;
  text-align: left;
  color: #286892;
`;
export const LimitationsList = styled.li`
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: left;
  color: #888888;
  margin-top: 10px;
  text-align: left;
  list-style: disc;
  :first-child {
    margin-top: 20px;
  }
`;
export const StyledOnlineOfflineEllipse = ({ status }) => {
  if (status === 'offline') {
    return <OfflineIcon />;
  } else {
    return <OnlineIcon />;
  }
};
export const StyledMapboxContainer = styled.div`
  height: 400px;
`;
export const StyledBatteryIconComponent = styled(BatteryIconComponent)``;
export const StyledAvatarUserInitials = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  border-radius: 100%;
  background: ${({ isOffline }) => (isOffline ? colors.mediumDarkGray : colors.darkPrimary)};
  border: ${({ isOffline }) => (isOffline ? ` 1px solid ${colors.mediumGray}` : ` 1px solid #eac18c`)};
  color: ${colors.white};
  font-size: 14px;
  line-height: 16px;
  font-weight: 700;
`;

export const StyledTechniciansCount = styled.span``;

export const StyledTechnicianName = styled.div`
  display: inline-block;
  ${strongTitleCss}
  ${addTrailingDots}
  width: 296px;
`;

export const StyledTechnicianStatus = styled.div`
  font-weight: 500;
  font-size: 14px;
  text-transform: capitalize;
  color: ${({ isOnline }) => (isOnline ? colors.primary : colors.gray)};
  margin-left: 5px;
`;
export const StyledSingleTechnicianStatusWrapper = styled.div`
  display: flex;
  align-items: center;
  ${StyledTechnicianStatus} {
    margin-right: 12px;
    margin-top: 4px;
  }
`;
export const StyledSeparatorEllipse = styled.svg`
  margin-left: 8px;
  margin-right: 8px;
`;

export const StyledTechnicianLocation = styled.div`
  display: inline-flex;
  ${strongTextCss}
  color: ${colors.gray};
  span {
    margin-left: 8px;
    color: ${colors.carbon};
  }
`;

export const StyledViewRouteButton = styled(Button)`
  ${primary}
  ${textCss}
  border: 1px solid ${colors.primary};
  text-align: center;
  box-sizing: border-box;
  width: 146px;
  height: 24px;
  border-radius: 4px;
`;

export const StyledWhatsappContactButton = styled('a')`
  display: flex;
  align-items: center;
  ${strongTextCss}
  background: transparent;
  color: ${colors.carbon};
  border: 2px solid ${colors.whatsapp};
  min-width: 136px;
  padding: 1px 5px;
  box-sizing: border-box;
  border-radius: 4px;
  margin-right: 12px;
`;

export const StyledWhatsappIconComponent = styled(WhatsappIconComponent)`
  margin-right: 4px;
`;
export const StyledExtraTechniciansCount = styled.div`
  display: inline-flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  color: white;
  font-weight: 700;
  font-size: 13px;
  margin-top: -8px;
  line-height: 15px;
  border-radius: 100%;
  z-index: 1;
  border: 1px solid ${colors.lightPurpleBlue};
  background: rgba(0, 0, 0, 0.3);
`;

export const StyledDailyPendingContainer = styled.div`
  ${strongTextCss}
`;
export const StyledDailyPendingActivitiesAndOccurrences = styled.div`
  display: flex;
  justify-content: left;
  ${strongTextCss}
  color: ${colors.darkPrimary};
  margin-top: ${props => (props.hasMarginTop ? '16px' : '4px')};
  margin-bottom: 12px;
`;
export const StyledGrayStrongText = styled.div`
  color: ${colors.gray};
  ${strongTextCss}
`;
export const StyledOccurrencesCountIcon = styled.svg`
  margin-right: 4px;
  margin-top: -1px;
`;
export const StyledOccurrencesCount = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: ${colors.gray};
  ${strongTextCss}
  b {
    color: ${colors.carbon};
    font-size: 21px;
    line-height: 24px;
  }
`;

export const StyledActivitiesCount = styled(StyledOccurrencesCount)`
  margin-top: 6px;
  b {
    margin-right: 6px;
  }
`;
export const StyledOccurrencesDetailsContainer = styled.div`
  display: flex;
  margin-top: 16px;
  margin-left: 10px;
`;
export const StyledOccurrencesDetails = styled.div`
  position: relative;

  margin-left: 23px;
  color: ${colors.gray};
  padding: 8px 12px 12px 12px;
`;

export const StyledViewOccurrencesButton = styled.a`
  ${primary}
  display: block;
  border-radius: 4px;
  margin-top: 4px;
  padding: 7px 14px 9px 14px;
`;
export const StyledOccurrencesDetailsBg = styled.svg`
  z-index: -1;
  position: absolute;
  top: 0px;
  left: -10px;
  display: block;
`;
