import { Select } from 'components/Form/subcomponents/Select';
import React, { useEffect, useState, useRef } from 'react';
import { useCallback } from 'react';
import { FiltersService } from 'services/reports';
import { GeneralPlansService } from 'services/reports/endpoints/GeneralPlansService';
import { createSuccessToast, createGenericErrorToast } from 'utils/createToast';
import { StyledApplicationTableExecutedByProviderCell } from './styled';
function ApplicationTableExecutedByProviderCell(props) {
  let disabled = props.disabled ? true : false;
  const [provider, setProvider] = useState(props.cell.value.id);
  const [providers, setProviders] = useState([]);
  const [isDirty, setIsDirty] = useState(false);
  const ref = useRef(null);

  useEffect(() => {
    let isMounted = true;
    const unit = props.cell.value.unitId;
    const getProviders = async () => {
      await FiltersService.getProviders({
        companyId: props.companyId,
        unitIds: unit,
      }).then(({ data }) => {
        let adaptedData = data.map(({ id, fantasyName }) => ({
          label: fantasyName,
          value: id,
        }));
        setProvider(props.cell.value.id);
        setProviders(adaptedData);
      });
    };
    if (isMounted && props.companyId && unit) {
      getProviders();
    }

    return () => {
      isMounted = false;
    };
  }, [props.companyId, props.isApplicationFormDirty, disabled, props.cell.value.unitId, props.cell.value.id]);
  const handleClickOutside = useCallback(
    e => {
      if (ref.current.contains(e.target)) {
        // inside click
      } else {
        // outside click
        setIsDirty(true);
      }
    },
    [ref],
  );
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [handleClickOutside]);
  return (
    <StyledApplicationTableExecutedByProviderCell ref={ref}>
      <Select
        {...props}
        size="small"
        placeholder="Seleção obrigatória"
        options={providers}
        isActive={props.isExecutedByProvider}
        disabled={disabled}
        isRequired
        value={provider}
        error={isDirty && !provider}
        onSelect={async ({ value }) => {
          try {
            await GeneralPlansService.updateSitesProvider({
              companyId: props.cell.value.companyId,
              applicationId: props.cell.value.unitId,
              planId: props.cell.value.planId,
              provider: value ? value : null,
            });
            setProvider(value);

            if (props.isEdit && !props.isDraft) {
              createSuccessToast(
                props.setToastList,
                'Fornecedor alterado. O fornecedor antigo não terá mais acesso a esse plano.',
              );
            }
          } catch (e) {
            createGenericErrorToast(props.setToastList);
          }
        }}
      />
    </StyledApplicationTableExecutedByProviderCell>
  );
}

export default ApplicationTableExecutedByProviderCell;
