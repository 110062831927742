import React, { useState, useEffect, useRef, useContext } from 'react';

import * as Sentry from '@sentry/react';

import { useHistory } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';

import { FiltersContext } from 'context/GlobalFiltersContext';
import { GlobalComponentsContext } from 'context/GlobalComponentsContext';
import { AuthContext } from 'context/AuthContext';
import { getLocalAuthStorage } from 'utils/localStorage';

import { HeaderFiltersPage } from 'containers/HeaderFiltersPage';
import { PageHeader } from 'components/PageHeader';
//import { Menu } from 'components/Menu';
import { MobileNavbar } from 'components/MobileNavbar';
import { PageWrapper } from 'components/PageWrapper';
import { Toast } from 'components/Toast';
import { Loading } from 'components/Loading';
import { FilterModal } from 'components/FilterModal';
import { FieldRow } from 'components/Form/subcomponents/FieldRow';
import { FormGroup } from 'components/Form/subcomponents/FormGroup';
import { DateRangePicker } from 'components/Form/subcomponents/DateRangePicker';
import { Select } from 'components/Form/subcomponents/Select';
import MultiSelect from 'components/Form/subcomponents/MultiSelect';
import { Radio } from 'components/Form/subcomponents/Radio';
import { createGenericErrorToast, createToast } from 'utils/createToast';
import { formatDateRangeValue } from 'utils/formatDateRangeValue';
import { InvisibleDownloadLink } from 'components/InvisibleDowloadLink';
import Accordion from 'components/Accordion';
import { GridColumn } from 'components/Grid/GridColumn';
import { ReportContainer } from 'components/ReportContainer';
import { ReportContainerFillFields } from 'components/ReportContainer/subcomponents/ReportContainerFillFields';
import { ReportContainerFields } from 'components/ReportContainer/subcomponents/ReportContainerFields';
import { ReportContainerColumn } from 'components/ReportContainer/subcomponents/ReportContainerColumn';
import { LineSeparator } from 'components/ReportContainer/subcomponents/LineSeparator';

import { AvailabilityService, SavedFiltersService, FiltersService } from 'services/reports';

import { colors } from 'assets/styled/tokens';
import alert from 'assets/icons/alert.svg';

import {
  StyledFieldsColumn,
  StyleDivRadio,
  StyledRadio,
  StyledBoxAlert,
  StyledAlert,
  Header,
  GoBackButton,
  ErrorText,
} from './styled';
import { NewMenu } from 'components/Menu/NewMenu';

const reportName = 'availability';

const Availability = ({ pathname }) => {
  const { authContext } = useContext(AuthContext);
  const { user } = authContext;

  const { globalComponentsContext, setGlobalComponentsContext } = useContext(GlobalComponentsContext);
  const { isLoading } = globalComponentsContext;

  const { headerFiltersContext } = useContext(FiltersContext);
  const { headerFilters } = headerFiltersContext;
  const authStorage = getLocalAuthStorage();

  const history = useHistory();

  const { handleSubmit, register, setValue, watch, getValues, control } = useForm({
    defaultValues: {
      reportEquipment: 'nofilter',
    },
  });
  const [equipmentInputError, setEquipmentInputError] = useState(false);
  const [isFirstMount, setIsFirstMount] = useState(true);
  const [toastList, setToastList] = useState([]);
  const [fields, setFields] = useState({
    unitGroups: [],
    unitSubgroups: [],
    units: [],
    systems: [],
    equipments: [],
    equipmentTypes: [],
    equipmentGroup: [],
  });

  const [currentSavedFilter, setCurrentSavedFilter] = useState('');

  const [modalOptions, setModalOptions] = useState({
    open: false,
    propertyKey: null,
  });

  const downloadLinkElement = useRef(null);
  const [downloadReport, setDownloadReport] = useState({
    name: '',
    href: '',
  });

  const goback = () => history.push('/relatorios');

  const formatPayload = values => {
    const realizedPeriodRange = formatDateRangeValue(values?.realizedPeriod);

    const unitName = fields.units[0].label;

    return {
      ...values,
      realizedPeriodStart: realizedPeriodRange?.start,
      realizedPeriodEnd: realizedPeriodRange?.end,
      company: headerFilters?.companies?.name,
      companyId: headerFilters?.companies?.id,
      unitName: unitName,
    };
  };

  const validateRequiredFields = () => {
    if (!getValues('realizedPeriod')) {
      setToastList(prevState => [
        ...prevState,

        createToast({
          type: 'error',

          message: 'Por favor preencha ao menos um periodo',
        }),
      ]);

      return true;
    }
    if (getValues('showCompare') && !getValues('scheduledPeriod')) {
      setToastList(prevState => [
        ...prevState,
        createToast({
          type: 'error',
          message: 'Por favor preencha as datas do período programado',
        }),
      ]);
      return true;
    }
    if (getValues('equipments').length > 1000) {
      setEquipmentInputError(true);
      return true;
    }

    return false;
  };

  const applySavedFilter = data => {
    const {
      unit: savedUnit,
      unitGroup: savedUnitGroup,
      unitSubgroup: savedUnitSubgroup,
      realizedPeriodStart: savedRealizedPeriodStart,
      realizedPeriodEnd: savedRealizedPeriodEnd,
      systems: savedSystems,
      equipments: savedEquipments,
      equipmentTypes: savedEquipmentTypes,
      equipmentGroup: savedEquipmentGroup,
    } = data;

    if (savedUnit) {
      setValue('unit', savedUnit);
    }

    if (savedUnitGroup) {
      setValue('unitGroup', savedUnitGroup);
    }

    if (savedUnitSubgroup) {
      setValue('unitSubgroup', savedUnitSubgroup);
    }

    if (savedRealizedPeriodStart && savedRealizedPeriodEnd) {
      setValue('realizedPeriod', savedRealizedPeriodStart + '/' + savedRealizedPeriodEnd);
    }

    if (savedSystems) {
      setValue('systems', savedSystems);
    }

    if (savedEquipments) {
      setValue('equipments', savedEquipments);
    }

    if (savedEquipmentTypes) {
      setValue('equipmentTypes', savedEquipmentTypes);
    }

    if (savedEquipmentGroup) {
      setValue('equipmentGroup', savedEquipmentGroup);
    }
  };

  const cleanFields = () => {
    setValue('realizedPeriod', '');

    setValue('systems', '');

    setValue('equipments', '');

    setValue('equipmentTypes', '');

    setValue('equipmentGroup', '');

    setValue('unit', '');

    setValue('unitGroup', '');

    setValue('unitSubgroup', '');

    setCurrentSavedFilter('');

    fetchData();
  };

  const saveFilters = async () => {
    try {
      const formValues = getValues();

      const payload = formatPayload(formValues);

      const {
        filterName,
        unit,
        unitGroup,
        unitSubgroup,
        realizedPeriodStart,
        realizedPeriodEnd,
        systems,
        equipmentTypes,
        equipments,
        equipmentGroup,
      } = payload;

      const companyId = headerFilters?.companies?.id;
      const userId = user?.id;

      await SavedFiltersService.createSavedFilter({
        companyId,
        userId,
        filterName,
        reportName,
        fields: {
          unit,
          unitGroup,
          unitSubgroup,
          realizedPeriodStart,
          realizedPeriodEnd,
          systems,
          equipmentTypes,
          equipmentGroup,
          equipments,
        },
      });

      setToastList(prevState => [
        ...prevState,
        createToast({
          type: 'success',
          message: 'Filtros salvos com sucesso',
        }),
      ]);
    } catch (err) {
      setToastList(prevState => [
        ...prevState,
        createToast({
          type: 'error',
          message: 'Ocorreu um erro ao salvar os filtros',
        }),
      ]);
    }
  };

  const generateExcel = async payload => {
    try {
      const {
        companyId,
        unit,
        unitGroup,
        unitSubgroup,
        realizedPeriodStart,
        realizedPeriodEnd,
        equipments,
        equipmentTypes,
        equipmentGroup,
        systems,
      } = payload;

      const response = await AvailabilityService.generateExcel({
        empresa: companyId,
        unidades: [unit],
        grupoUnidades: unitGroup ? [unitGroup] : [0],
        subGrupoUnidades: unitSubgroup ? [unitSubgroup] : [0],
        dataInicial: realizedPeriodStart,
        dataFinal: realizedPeriodEnd,
        equipamentos: equipments,
        tiposEquipamento: equipmentTypes,
        gruposEquipamento: equipmentGroup,
        sistemas: systems,
        token: authStorage.token,
      });

      const { data } = response;
      if (!data) {
        return setToastList(() => [
          createToast({
            type: 'error',
            message: 'Não há dados para os filtros selecionados ',
          }),
        ]);
      }

      const blob = new Blob([data], {
        type: 'application/json',
      });

      const href = window.URL.createObjectURL(blob);
      setDownloadReport({
        ...downloadReport,
        href,
        name: `Leankeep-relatorio-disponibilidade-${new Date().getTime()}.xlsx`,
      });

      downloadLinkElement.current.click();

      Sentry.captureMessage('Relatório Disponibilidade excel baixado');

      setToastList(prevState => [
        ...prevState,
        createToast({
          type: 'success',
          message: 'Seu relatório foi exportado!',
        }),
      ]);
    } catch (err) {
      setToastList(() => [
        createToast({
          type: 'error',
          message: 'Não há dados para os filtros selecionados ',
        }),
      ]);
    }
  };

  const onSubmit = values => {
    setEquipmentInputError(false);
    const hasError = validateRequiredFields();

    if (hasError) {
      return;
    }

    const payload = formatPayload(values);

    setToastList(prevState => [
      ...prevState,
      createToast({
        type: 'loading',
        message: 'Gerando arquivo...',
      }),
    ]);

    return generateExcel(payload);
  };

  const onSelectFilter = async selectedFilter => {
    const { id, name } = selectedFilter;

    try {
      const response = await SavedFiltersService.getSavedFilters({
        companyId: headerFilters?.companies?.id,
        filterSavedId: id,
      });

      applySavedFilter(response.data);

      setCurrentSavedFilter(() => name);
    } catch (err) {
      setToastList(prevState => [
        ...prevState,
        createToast({
          type: 'error',
          message: 'Ocorreu um erro ao buscar o filtro selecionado.',
        }),
      ]);
    } finally {
      setModalOptions(() => ({
        open: false,
      }));
    }
  };

  const populateFieldsStrategy = async () => {
    const companyId = headerFilters?.companies?.id;
    const unitGroupId = getValues('unitGroup');
    const unitSubgroupId = getValues('unitSubgroup');
    const unitId = getValues('unit');
    const equipmentTypeId = getValues('equipmentTypes');
    const equipamentGroupId = getValues('equipmentGroup');
    const companySystemsId = getValues('systems');

    if (!unitId) {
      return Promise.all([
        FiltersService.getUnitGroups({
          companyId,
          ids: unitGroupId && [unitGroupId],
        }),
        FiltersService.getUnitSubgroups({
          companyId,
          ids: unitSubgroupId && [unitSubgroupId],
          unitGroupIds: unitGroupId && [unitGroupId],
        }),
        FiltersService.getUnits({
          companyId,
          ids: unitId && [unitId],
          unitGroupIds: unitGroupId && [unitGroupId],
          unitSubgroupIds: unitSubgroupId && [unitSubgroupId],
        }),
      ]).then(values => ({
        unitGroups: values[0].data.map(({ nome, grupoUnidadeId }) => ({
          label: nome,
          value: grupoUnidadeId,
        })),
        unitSubgroups: values[1].data.map(({ nome, subGrupoUnidadeId }) => ({
          label: nome,
          value: subGrupoUnidadeId,
        })),
        units: values[2].data.map(({ nome, site }) => ({
          label: nome,
          value: site,
        })),
      }));
    }

    return Promise.all([
      FiltersService.getUnitGroups({
        companyId,
        ids: unitGroupId && [unitGroupId],
      }),
      FiltersService.getUnitSubgroups({
        companyId,
        ids: unitSubgroupId && [unitSubgroupId],
        unitGroupIds: unitGroupId && [unitGroupId],
      }),
      FiltersService.getUnits({
        companyId,
        ids: unitId && [unitId],
        unitGroupIds: unitGroupId && [unitGroupId],
        unitSubgroupIds: unitSubgroupId && [unitSubgroupId],
      }),
      FiltersService.getSystems({
        companyId,
        unitIds: unitId && [unitId],
        unitGroupIds: unitGroupId && [unitGroupId],
        unitSubgroupIds: unitSubgroupId && [unitSubgroupId],
      }),
      FiltersService.getEquipmentTypesAvailibility({
        EmpresaId: companyId,
        UnidadeId: unitId && [unitId],
      }),
      FiltersService.getEquipmentGroup({
        empresaId: companyId,
        unidadeId: unitId && [unitId],
      }),
      FiltersService.getEquipments({
        companyId,
        unitIds: unitId && [unitId],
        unitGroupIds: unitGroupId && [unitGroupId],
        unitSubgroupIds: unitSubgroupId && [unitSubgroupId],
        equipmentTypeIds: equipmentTypeId === undefined ? 0 : equipmentTypeId,
        equipamentGroupId: equipamentGroupId === undefined ? 0 : equipamentGroupId,
        companySystemsId: companySystemsId === undefined ? 0 : companySystemsId,
      }),
    ]).then(values => ({
      unitGroups: values[0].data.map(({ nome, grupoUnidadeId }) => ({
        label: nome,
        value: grupoUnidadeId,
      })),
      unitSubgroups: values[1].data.map(({ nome, subGrupoUnidadeId }) => ({
        label: nome,
        value: subGrupoUnidadeId,
      })),
      units: values[2].data.map(({ nome, site }) => ({
        label: nome,
        value: site,
      })),
      systems: values[3].data
        .filter(value => value.sistemaEmpresa != null)
        .map(({ nome, sistemaEmpresa }) => ({
          label: nome,
          value: sistemaEmpresa,
        })),
      equipmentTypes: values[4].data
        .filter(value => value.inativo === false && value.linhaProdutoEmpresa != null)
        .map(({ nome, linhaProdutoEmpresa }) => ({
          label: nome,
          value: linhaProdutoEmpresa,
        })),
      equipmentGroup: values[5].data.map(({ nome, grupoEquipamento }) => ({
        label: nome,
        value: grupoEquipamento,
      })),
      equipments: values[6].data.map(({ name, id }) => ({
        label: name,
        value: id,
      })),
    }));
  };

  const populateFields = async () => {
    try {
      const fieldsData = await populateFieldsStrategy();
      setFields(prevState => ({
        ...prevState,
        ...fieldsData,
      }));

      const { unitSubgroups, unitGroups } = fieldsData;

      if (unitGroups.length === 1) {
        setValue('unitGroup', unitGroups[0].value);
      }

      if (unitSubgroups.length === 1) {
        setValue('unitSubgroup', unitSubgroups[0].value);
      }
    } catch (err) {
      createGenericErrorToast(setToastList);
    }
  };

  const getModalOptionsData = async () => {
    const companyId = headerFilters?.companies?.id;
    const userId = user?.id;

    try {
      const response = await SavedFiltersService.findSavedFiltersByReportName({
        reportName,
        companyId,
        userId,
      });
      return response.data;
    } catch (err) {
      setToastList(prevState => [
        ...prevState,
        createToast({
          type: 'error',
          message: 'Ocorreu um erro ao buscar os filtros salvos. Tente novamente..',
        }),
      ]);
    }
  };

  const deleteSavedFilter = async selectedFilter => {
    const { id } = selectedFilter;

    const companyId = headerFilters?.companies?.id;

    try {
      await SavedFiltersService.removeSavedFilter({
        companyId,
        filterSavedId: id,
      });
      setToastList(prevState => [
        ...prevState,
        createToast({
          type: 'success',
          message: 'O filtro selecionado foi deletado com sucesso',
        }),
      ]);
    } catch (err) {
      setToastList(prevState => [
        ...prevState,
        createToast({
          type: 'error',
          message: 'Ocorreu um erro ao deletar o filtro selecionado. Tente novamente.',
        }),
      ]);
    } finally {
      setModalOptions({
        open: false,
      });
    }
  };

  const openModalSavedFilters = () =>
    setModalOptions(() => ({
      title: 'Filtros salvos',
      open: true,
    }));

  const fetchData = async () => {
    setGlobalComponentsContext(prevState => ({
      ...prevState,
      isLoading: true,
    }));
    // await cleanFields();
    await populateFields();
    setGlobalComponentsContext(prevState => ({
      ...prevState,
      isLoading: false,
    }));
  };

  useEffect(() => {
    fetchData();
    setIsFirstMount(false);

    if (headerFilters.units && headerFilters.units.id && !getValues('unit')) {
      setValue('unit', headerFilters.units.id);
    }

    // eslint-disable-next-line
  }, [
    // eslint-disable-next-line
    watch('systems'),
    // eslint-disable-next-line
    watch('equipmentTypes'),
    // eslint-disable-next-line
    watch('equipmentGroup'),
    // eslint-disable-next-line
    watch('equipments'),
  ]);
  const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);
  return (
    <>
      {isLoading && <Loading />}
      <PageWrapper background={colors.pampas}>
        <MobileNavbar />
        {/* <Menu /> */}
        <NewMenu setIsSubMenuOpen={setIsSubMenuOpen} />
        <HeaderFiltersPage location={pathname} isSubMenuOpen={isSubMenuOpen}>
          <Header>
            <GoBackButton onClick={() => goback()}>
              <svg width="12" height="10" viewBox="0 0 24 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M9.75258 0.341509C10.1163 0.757146 10.0741 1.38891 9.6585 1.75259L2.51859 8.00001L9.6585 14.2474C10.0741 14.6111 10.1163 15.2429 9.75258 15.6585C9.3889 16.0742 8.75713 16.1163 8.3415 15.7526L0.341495 8.75259C0.12448 8.5627 0 8.28838 0 8.00001C0 7.71165 0.12448 7.43733 0.341495 7.24744L8.3415 0.247437C8.75713 -0.116245 9.3889 -0.0741276 9.75258 0.341509Z"
                  fill="black"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M0 8C0 7.44772 0.447715 7 1 7H23C23.5523 7 24 7.44772 24 8C24 8.55228 23.5523 9 23 9H1C0.447715 9 0 8.55228 0 8Z"
                  fill="black"
                />
              </svg>
            </GoBackButton>
            <PageHeader title="Relatório MTBF, MTTR e Disponibilidade" />
          </Header>
          <ReportContainer
            exportTitle="Exportar Relatório (Excel)"
            onSubmit={handleSubmit(onSubmit)}
            currentSavedFilter={currentSavedFilter}
            useSavedFilters={openModalSavedFilters}
            cleanSavedFilter={cleanFields}
            //cleanFilterButton={false}
            backButtondisabled={true}
            saveFilter={saveFilters}
            cleanFields={cleanFields}
            //onBack={goback}
            formId={reportName}
            refInputFilterName={register({
              required: true,
            })}
            renderFooter={watch('unit')}
          >
            <ReportContainerColumn justifyContent="flex-end" gridColumnStart={2}>
              <ReportContainerFields>
                <FieldRow marginBottom="1rem">
                  <FormGroup label="Grupo de unidades" disabled={fields?.unitGroups?.length <= 0}>
                    <Controller
                      render={props => (
                        <Select
                          {...props}
                          size="small"
                          placeholder="Seleção opcional"
                          options={fields?.unitGroups}
                          defaultValue={isFirstMount ? props.defaultValue : ''}
                          onChange={value => {
                            props.onChange(value);
                            fetchData();
                          }}
                        />
                      )}
                      name="unitGroup"
                      control={control}
                      defaultValue={headerFilters?.unitGroups?.id || ''}
                    />
                  </FormGroup>
                </FieldRow>

                <FieldRow marginBottom="1rem">
                  <FormGroup label="Subgrupo de unidades" disabled={fields?.unitSubgroups?.length <= 0}>
                    <Controller
                      render={props => (
                        <Select
                          {...props}
                          size="small"
                          placeholder="Seleção opcional"
                          options={fields?.unitSubgroups}
                          defaultValue={isFirstMount ? props.defaultValue : ''}
                          onChange={value => {
                            props.onChange(value);
                            fetchData();
                          }}
                        />
                      )}
                      name="unitSubgroup"
                      control={control}
                      defaultValue={headerFilters?.unitSubgroups?.id || ''}
                    />
                  </FormGroup>
                </FieldRow>
                <FieldRow marginBottom="1rem">
                  <FormGroup label="Unidade de manutenção" disabled={fields?.units?.length <= 0} isRequired>
                    <Controller
                      render={props => (
                        <Select
                          {...props}
                          size="small"
                          placeholder="Seleção obrigatória"
                          options={fields?.units}
                          defaultValue={isFirstMount ? props.defaultValue : ''}
                          onChange={value => {
                            props.onChange(value);
                            fetchData();
                          }}
                        />
                      )}
                      name="unit"
                      control={control}
                      defaultValue={headerFilters?.units?.id || ''}
                      rules={{ required: true }}
                    />
                  </FormGroup>
                </FieldRow>
                <StyledBoxAlert>
                  <StyledAlert>
                    <img src={alert} alt="alert" /> Para visualizar os dados deste relatório mantenha ativa a
                    funcionalidade “MTBF, MTTR e Disponibilidade de equipamentos” localizada em Configurações &gt; Geral
                    &gt; Dados gerais.
                  </StyledAlert>
                </StyledBoxAlert>
              </ReportContainerFields>
            </ReportContainerColumn>
            <LineSeparator show={watch('unit')} />
            {watch('unit') ? (
              <ReportContainerColumn>
                <ReportContainerFields>
                  <Accordion titulo="Equipamentos" isRequired>
                    <GridColumn size={1} columnGap="1rem" rowGap="1rem">
                      <FormGroup label="Filtrar equipamentos por:">
                        <StyledRadio>
                          <StyleDivRadio>
                            <Radio
                              text="Sistemas"
                              id="report-open"
                              name="reportEquipment"
                              value="reportSystems"
                              ref={register()}
                            />
                            <div></div>
                          </StyleDivRadio>
                          <StyleDivRadio>
                            <Radio
                              text="Tipos de equipamentos"
                              id="report-start"
                              name="reportEquipment"
                              value="reportEquipmentTypes"
                              ref={register()}
                            />
                          </StyleDivRadio>
                          <StyleDivRadio>
                            <Radio
                              text="Grupos de equipamentos"
                              id="report-limit"
                              name="reportEquipment"
                              value="reportEquipmentGroup"
                              ref={register()}
                            />
                          </StyleDivRadio>
                          <StyleDivRadio>
                            <Radio text="Não filtrar" name="reportEquipment" value="nofilter" ref={register()} />
                          </StyleDivRadio>
                        </StyledRadio>
                      </FormGroup>
                      {watch('reportEquipment') === 'reportSystems' && (
                        <>
                          <FormGroup label="Sistemas" isRequired>
                            <Controller
                              render={props => (
                                <MultiSelect
                                  size="small"
                                  placeholder="Seleção obrigatória"
                                  options={fields?.systems}
                                  {...props}
                                />
                              )}
                              name="systems"
                              control={control}
                              defaultValue={[]}
                            />
                          </FormGroup>
                          <FormGroup label="Equipamentos">
                            <Controller
                              render={props => (
                                <MultiSelect
                                  size="small"
                                  placeholder="Seleção opcional"
                                  options={fields?.equipments}
                                  {...props}
                                  required={equipmentInputError}
                                />
                              )}
                              name="equipments"
                              control={control}
                              defaultValue={[]}
                            />
                            {equipmentInputError && (
                              <ErrorText>A seleção excedeu o limite de 1000 equipamentos.</ErrorText>
                            )}
                          </FormGroup>
                        </>
                      )}
                      {watch('reportEquipment') === 'reportEquipmentTypes' && (
                        <>
                          <FormGroup label="Tipos de equipamentos" isRequired>
                            <Controller
                              render={props => (
                                <MultiSelect
                                  size="small"
                                  placeholder="Seleção obrigatória"
                                  options={fields?.equipmentTypes}
                                  {...props}
                                />
                              )}
                              name="equipmentTypes"
                              control={control}
                              defaultValue={[]}
                            />
                          </FormGroup>
                          <FormGroup label="Equipamentos">
                            <Controller
                              render={props => (
                                <MultiSelect
                                  size="small"
                                  placeholder="Seleção opcional"
                                  options={fields?.equipments}
                                  required={equipmentInputError}
                                  {...props}
                                />
                              )}
                              name="equipments"
                              control={control}
                              defaultValue={[]}
                            />
                            {equipmentInputError && (
                              <ErrorText>A seleção excedeu o limite de 1000 equipamentos.</ErrorText>
                            )}
                          </FormGroup>
                        </>
                      )}
                      {watch('reportEquipment') === 'reportEquipmentGroup' && (
                        <>
                          <FormGroup label="Grupos de equipamentos" isRequired>
                            <Controller
                              render={props => (
                                <MultiSelect
                                  size="small"
                                  placeholder="Seleção obrigatória"
                                  options={fields?.equipmentGroup}
                                  {...props}
                                />
                              )}
                              name="equipmentGroup"
                              control={control}
                              defaultValue={[]}
                            />
                          </FormGroup>
                          <FormGroup label="Equipamentos">
                            <Controller
                              render={props => (
                                <MultiSelect
                                  size="small"
                                  placeholder="Seleção opcional"
                                  options={fields?.equipments}
                                  required={equipmentInputError}
                                  {...props}
                                />
                              )}
                              name="equipments"
                              control={control}
                              defaultValue={[]}
                            />
                            {equipmentInputError && (
                              <ErrorText>A seleção excedeu o limite de 1000 equipamentos.</ErrorText>
                            )}
                          </FormGroup>
                        </>
                      )}
                      {watch('reportEquipment') === 'nofilter' && (
                        <FormGroup label="Equipamentos">
                          <Controller
                            render={props => (
                              <MultiSelect
                                size="small"
                                placeholder="Seleção opcional"
                                options={fields?.equipments}
                                required={equipmentInputError}
                                {...props}
                              />
                            )}
                            name="equipments"
                            control={control}
                            defaultValue={[]}
                          />
                          {equipmentInputError && (
                            <ErrorText>A seleção excedeu o limite de 1000 equipamentos.</ErrorText>
                          )}
                        </FormGroup>
                      )}
                    </GridColumn>
                  </Accordion>
                  <Accordion titulo="Período" isRequired>
                    <GridColumn size={1} columnGap="1rem" rowGap="1rem">
                      <StyledFieldsColumn width="1">
                        <FieldRow marginBottom="1rem">
                          <FormGroup label="Período" isRequired>
                            <Controller
                              render={props => (
                                <DateRangePicker
                                  size="small"
                                  placeholder="Seleção obrigatória"
                                  defaultValue={props.defaultValue}
                                  {...props}
                                />
                              )}
                              name="realizedPeriod"
                              control={control}
                              defaultValue=""
                            />
                          </FormGroup>
                        </FieldRow>
                      </StyledFieldsColumn>
                    </GridColumn>
                  </Accordion>
                </ReportContainerFields>
              </ReportContainerColumn>
            ) : (
              <ReportContainerFillFields />
            )}
          </ReportContainer>
          <InvisibleDownloadLink ref={downloadLinkElement} href={downloadReport?.href} download={downloadReport?.name}>
            Exportar Relatório (Excel)
          </InvisibleDownloadLink>
        </HeaderFiltersPage>
      </PageWrapper>
      <Toast toastList={toastList} autoDelete dismissTime={5000} />
      <FilterModal
        options={modalOptions}
        closeModal={() =>
          setModalOptions(() => ({
            open: false,
          }))
        }
        onSelect={onSelectFilter}
        hasDeleteBtn
        onDelete={deleteSavedFilter}
        getData={getModalOptionsData}
      />
    </>
  );
};

export { Availability };
