import styled from 'styled-components';
import { colors } from 'assets/styled/tokens';
import { Button } from 'components/Button';
import { CheckboxDropdown } from 'components/Form/subcomponents/CheckboxDropdown';
import { FormGroup } from 'components/Form/subcomponents/FormGroup';
import { StyledSearchInput } from 'components/StyledUtils/inputs';

import { ViewContainer } from '../styled';
import { StyledGrayStrongText } from 'pages/Geolocalize/styled';
import { headerTitleCss } from 'utils/helpers/css';
export const OrderByUnitsSelect = styled.select`
  border: none;
  background: transparent;
`;
export const StyledUnitsViewContainer = styled(ViewContainer)`
  font-size: 14px;
  line-height: 16px;
`;
export const StyledSearchUnitsInput = styled(StyledSearchInput)`
  width: 100%;
  font-size: 14px;
  line-height: 16px;
`;
export const StyledPendingOcurrencesDropdownFormGroup = styled(FormGroup)`
  margin-top: 16px;
`;
export const StyledUnitsViewControls = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;
`;
export const StyledCheckboxDropdown = styled(CheckboxDropdown)`
  input {
    font-size: 14px;
    line-height: 16px;
    padding: 8px 12px;
    height: 32px;
  }
`;
export const StyledFilterUnitsButtonWrapper = styled.div`
  display: flex;
  height: 32px;
  border-color: ${colors.whiteGray} !important;
  margin-right: 16px;
  background: ${colors.white};
  border-radius: 4px;
`;

export const StyledFilterUnitsButton = styled(Button)`
  font-size: 14px;
  line-height: 16px;
  color: ${colors.carbon} !important;
  font-weight: 500;
  border: 0;
  padding: 0;
  span {
    margin-left: 8px;
    margin-right: 12px;
  }
  svg {
    margin-left: 9px;
  }
`;
export const StyledFilterCount = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${colors.primary};
  color: ${colors.white};
  border-radius: 100%;
  font-size: 13px;
  width: 20px;
  height: 20px;
`;
export const StyledClearFilterUnitsButton = styled.button`
  border-left: 1px solid ${colors.whiteGray};
  padding: ${({ isHidden }) => (isHidden ? `0` : `0 12px`)};
`;
export const StyledPendingOccurrencesFilter = styled.div`
  display: flex;
  align-items: center;
  font-weight: 500;
  color: ${colors.carbon};
  cursor: pointer;
  span {
    color: ${colors.darkPrimary};
  }
  svg {
    margin-left: 6px;
    margin-right: 4px;
  }
`;

export const StyledUnitsViewHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 40px;
  ${StyledGrayStrongText} {
    margin-right: 8px;
  }
`;
export const StyledUnitsCount = styled.h4`
  color: ${colors.purpleBlue};
  ${headerTitleCss}
`;

export const StyledUnitsCountContainer = styled.div`
  display: flex;
  span {
    margin-left: 4px;
  }
`;

// TODO: HOIST TO REUSABLE TRANSPARENT DROPDOWN SELECT

export const StyledOrderUnitsBySelect = styled.select``;

export const StyledUnitsViewList = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 16px;
`;

export const StyledExcelRoutesDownloadButton = styled(Button)``;
