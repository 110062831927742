import styled from 'styled-components';
import { Button } from 'components';
import lupaIconSvg from 'assets/icons/magnifying.svg';
import { medias } from 'assets/styled/tokens';

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  margin-bottom: 32px;
  margin-top: 9px;
`;

export const HeaderName = styled.div`
  font-size: 32px;
  font-family: Blinker, sans-serif;
  font-weight: 700;
`;
export const HeaderRigth = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-left: 24px;
`;

export const SearchBar = styled.input`
  background: #ffffff;
  border: 1px solid #e5e5e5;
  border-radius: 4px;
  width: 240px;
  height: 32px;
  padding-left: 5px;
  ::placeholder {
    padding: 0 5px 0 5px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.3px;
    color: #888888;
    background: url(${lupaIconSvg}) no-repeat center/contain;
    background-position: right center;
    background-size: 12px;
    background-position-x: 95%;
  }
`;

export const OldReportsButton = styled(Button)`
  background: #ffffff;
  border: 1px solid #f38a00;
  border-radius: 4px;
  width: 232px;
  height: 32px;
  color: #f38a00;
  font-size: 14px;
  letter-spacing: 0.5px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const ReportsPageContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, 234px);
  //justify-content: space-evenly;
  gap: 3em;
  min-height: 100vh;
  @media screen and (max-width: ${medias.desktopMd}) {
    grid-template-columns: repeat(auto-fit, 241.5px);
    margin-left: 2.5px;
  }
`;
