import React, { createContext, useEffect, useState, useContext } from 'react';

import { Button } from 'components/Button';
import { ModalGeneric } from 'components/ModalGeneric';

import popUpValidationError from 'assets/images/pop-up-blocked.gif';

import { StyledModalBody } from './styled';
// import { download } from 'utils/files';
import * as Sentry from '@sentry/react';
import { AuthContext } from 'context/AuthContext';
import { parseJwt } from 'utils/parse';
import { FiltersContext } from 'context/GlobalFiltersContext';

const SocketIoContext = createContext();
SocketIoContext.displayName = 'SocketIoContext';

const SocketIoProvider = ({ children }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { authContext } = useContext(AuthContext);
  const { headerFiltersContext } = useContext(FiltersContext);
  // const location = useLocation();
  const { companies, units } = headerFiltersContext?.headerFilters;
  // TODO: HOIST TO OWN PROVIDER OR EXPORT AND IMPORT IN APP
  useEffect(() => {
    Sentry.configureScope(scope => {
      if (companies) {
        const croppedCompanyName = companies?.name.split(' ').slice(0, 3).join(' ');
        scope.setTag('Empresa', companies?.id);
        scope.setTag('Empresa.nome', croppedCompanyName);
      }
      if (units) {
        scope.setTag('Unidade', units.id);
        scope.setTag('Unidade.nome', units.name);
      }
    });
  }, [companies, units]);

  useEffect(() => {
    if (authContext.isAuthenticated && authContext.token) {
      const { email, unique_name: username, nameid: userId } = parseJwt(authContext.token);

      Sentry.configureScope(scope => {
        // scope.setTag("User.permissao", permissionLevel);
        scope.setTag('User.Username', username);
        scope.setTag('User.Email', email);
        scope.setTag('User.Id', userId);
        scope.setUser({ email, username, id: userId });
      });
    } else {
      Sentry.configureScope(scope => {
        scope.clear();
      });
    }
  }, [authContext.isAuthenticated, authContext.token, authContext.user]);

  return (
    <SocketIoContext.Provider
      value={
        {
          // socketIoContext: socketState,
          // setSocketIoContext: setSocketState
        }
      }
    >
      {children}
      <ModalGeneric
        open={isModalOpen}
        hasHeader={true}
        closeModal={() => setIsModalOpen(false)}
        size={{ maxWidth: '521px' }}
      >
        <StyledModalBody>
          <h6>Habilite os pop-ups para gerar relatórios</h6>
          <span>Para habilitar siga os passos abaixo:</span>
          <img src={popUpValidationError} alt="Pop-up bloqueada" />
          <Button type="button" onClick={() => setIsModalOpen(false)}>
            Entendi
          </Button>
        </StyledModalBody>
      </ModalGeneric>
    </SocketIoContext.Provider>
  );
};

export { SocketIoContext, SocketIoProvider };
