import { localStorageKeys } from 'constants/localStorage';

const skeleton = {
  isFiltersOpen: false,
  isMobileMenuOpen: false,
  isLoading: false,
};

const stored = JSON.parse(localStorage.getItem(localStorageKeys.globalComponentsContext));

const initialState = stored || skeleton;

export { initialState, skeleton };
