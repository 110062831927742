import { fonts } from 'assets/styled/tokens';
import styled, { css } from 'styled-components';

const baseStatusPill = css`
  height: 24px;
  font-weight: 600;
  font-size: 12px;
  width: 100%;
  border-radius: 4px;
  text-align: center;
  font-family: ${fonts.family.text};
`;
export const StyledScheduledPlanStatusPill = styled.div`
  ${baseStatusPill}
  background: #b4ede3;
  color: #03725f;
`;

export const StyledInactivePlanStatusPill = styled.div`
  ${baseStatusPill}
  background: #ffdbd6;
  color: #a6483c;
`;

export const StyledDraftPlanStatusPill = styled.div`
  ${baseStatusPill}
  background: #fce3c2;
  color: #a84c05;
`;
