import { getNameInitials } from 'pages/Geolocalize/utils';
import { StyledAvatarUserInitials } from 'pages/Geolocalize/styled';
import React from 'react';
import { StyledTechnicianAvatar, StyledTechnicianAvatarImage, StyledAvatarImageContainer } from './styled';

const TechnicianAvatar = ({ technician, zIndex }) => {
  return (
    <StyledTechnicianAvatar zIndex={zIndex} key={technician.id}>
      {technician.image ? (
        <StyledAvatarImageContainer>
          <StyledTechnicianAvatarImage alt={`Avatar do ${technician.name}`} src={technician.image} />
        </StyledAvatarImageContainer>
      ) : (
        <StyledAvatarUserInitials isOffline={technician.status === 'offline'}>
          {getNameInitials(technician.name)}
        </StyledAvatarUserInitials>
      )}
    </StyledTechnicianAvatar>
  );
};
export default TechnicianAvatar;
