import { forwardRef } from 'react';
import { StyledTextarea, TextAreaInput } from './styled';

export const TextaAreaInput = forwardRef(
  ({ label, width, height, onChange, disabled, errorColor = false, maxLength, ...props }, ref) => {
    return (
      <StyledTextarea width={width} height={height}>
        {label}
        <TextAreaInput
          maxLength={maxLength}
          onChange={onChange}
          errorColor={errorColor}
          ref={ref}
          width={width}
          height={height}
          disabled={disabled}
          {...props}
        ></TextAreaInput>
      </StyledTextarea>
    );
  },
);
