import * as t from 'io-ts';
import { localStorageKeys } from 'constants/localStorage';
import { systemsCodec } from 'pages/Dashboard/types';

export const headerFiltersRulesCodec = t.type({
  userHasManyCompanies: t.null,
  userIsProvider: t.null,
});
export type HeaderFiltersRulesType = t.TypeOf<typeof headerFiltersRulesCodec>;

export const headerFiltersItemCodec = t.type({
  id: t.number,
  name: t.string,
});
export type HeaderFiltersItemType = t.TypeOf<typeof headerFiltersItemCodec>;

export const headerFiltersCodec = t.type({
  cities: t.union([headerFiltersItemCodec, t.null]),
  companies: t.union([headerFiltersItemCodec, t.null]),
  providers: t.union([headerFiltersItemCodec, t.null]),
  states: t.union([headerFiltersItemCodec, t.null]),
  systems: t.array(systemsCodec),
  teams: t.union([headerFiltersItemCodec, t.null]),
  unitGroups: t.union([headerFiltersItemCodec, t.null]),
  units: t.union([headerFiltersItemCodec, t.null]),
  unitSubgroups: t.union([headerFiltersItemCodec, t.null]),
});
export type HeaderFiltersType = t.TypeOf<typeof headerFiltersCodec>;

export type StorageSkeletonType = {
  headerFilters: HeaderFiltersType;
  headerFiltersRules: HeaderFiltersRulesType;
};

const skeleton: StorageSkeletonType = {
  headerFiltersRules: {
    userHasManyCompanies: null,
    userIsProvider: null,
  },
  headerFilters: {
    cities: null,
    companies: null,
    providers: null,
    states: null,
    systems: [],
    teams: null,
    unitGroups: null,
    units: null,
    unitSubgroups: null,
  },
};

const stored = JSON.parse(localStorage.getItem(localStorageKeys.headerFiltersContext) as string);

const initialState = stored || skeleton;

export { initialState, skeleton };
