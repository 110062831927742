import React from 'react';

import { StyledFilter, StyledFilterChip, StyledFilterLabel } from './styled';

const FilterBadge = ({ data, label, currentValue, disableOpen, disableDelete, isOpen, onClick, onClickX, id = '' }) => {
  return (
    <StyledFilter hasData={data} isOpen={isOpen}>
      <StyledFilterLabel>
        {label}
        {!data && (
          <button data-cy={`${id}-filter`} onClick={onClick}>
            Adicionar filtro
          </button>
        )}
      </StyledFilterLabel>

      <StyledFilterChip data-cy={`${id}-chip`} disableOpen={disableOpen}>
        {/* eslint-disable-next-line*/}
        <span onClick={disableOpen ? null : onClick}>{currentValue}</span>
        {!disableDelete && (
          <button type="button" onClick={onClickX}>
            Remover filtro
          </button>
        )}
      </StyledFilterChip>
    </StyledFilter>
  );
};

export { FilterBadge };
