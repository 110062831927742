/* istanbul ignore file */

import styled from 'styled-components';
import { ReactComponent as WarningIcon } from 'assets/icons/warning-icon-2.svg';

export const StyledWarningIcon = styled(WarningIcon)`
  path {
    fill: white !important;
  }
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  min-width: 100%;
  align-items: center;
  margin-bottom: 34px;
`;

export const StyledOnlyCurrentCorrectionStatus = styled.div`
  display: flex;
  align-items: center;

  label {
    width: max-content;

    p {
      margin: 0 0.25rem;
    }
  }
`;
export const StatusInfo = styled.div`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.3px;
  text-transform: uppercase;
  color: #1e1e1e;
  margin-left: 8px;
`;
export const StatusWarningInfo = styled.div`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.3px;
  text-transform: uppercase;
  color: #c25446;
  margin-left: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;
export const RedoReportButton = styled.button`
  border: 1px solid #e5e5e5 !important;
  border-radius: 4px !important;
  height: 32px !important;
  width: 32px !important;
  color: black !important;
  padding: 0;
  margin-left: 10px;
  svg {
    height: 32px !important;
    width: 32px !important;
    path {
      fill: #1e1e1e !important;
    }
  }
`;
