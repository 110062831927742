import { colors } from 'assets/styled/tokens';
import styled from 'styled-components';

export const StyledBatteryWrapper = styled.div`
  position: relative;
  width: 16px;
  height: 4px;
  margin-right: 5px;
`;
export const StyledBatteryOutlineSvg = styled.svg`
  position: absolute;
  top: 0;
  path {
    fill: ${({ percentage }) =>
      percentage <= 33 ? colors.darkRed : percentage <= 66 ? colors.darkGray : colors.darkGreen};
  }
`;
export const StyledDynamicBatteryAmount = styled.div`
  position: absolute;
  top: 2px;
  width: ${({ percentage }) => `${percentage - 11.76}%`};
  background: ${({ percentage }) =>
    percentage <= 33 ? colors.bittersweet : percentage <= 66 ? colors.gray : colors.successGreen};
  height: 100%;
  transform: scaleY(0.7);
  overflow: hidden;
  z-index: 5;
  left: 0;
`;
