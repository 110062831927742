import React, { useState } from 'react';
import {
  StyledRouteCoveredTimeline,
  StyledTimelineDateSelectContainer,
  StyledTimelineDateSelectWrapper,
  StyledTimelineDateSelectArrow,
  StyledTimelineHeader,
  StyledTimelineStops,
  StyledRouteCoveredHorizontalLine,
  StyledDateRangePicker,
} from './styled';
import { ReactComponent as ChevronRight } from 'assets/icons/chevron-right.svg';
import TimelineStop from './TimelineStop';
function RouteCoveredTimeline({ stops }) {
  // TODO add functionality
  const handleAddDays = days => {
    const date = new Date(activeDate);
    const updatedDate = date.setDate(date.getDate() + days);
    setActiveDate(updatedDate);
  };
  const [activeDate, setActiveDate] = useState(new Date());
  return (
    <StyledRouteCoveredTimeline>
      <StyledTimelineDateSelectContainer>
        <StyledTimelineDateSelectWrapper>
          <StyledTimelineDateSelectArrow onClick={() => handleAddDays(-1)}>
            <ChevronRight style={{ transform: 'rotate(180deg)' }} />
          </StyledTimelineDateSelectArrow>
          <StyledDateRangePicker value={activeDate} onChange={value => setActiveDate(value)} />
          {/* <StyledActiveDate>
            <CalendarIcon />

            {activeDate}
          </StyledActiveDate> */}
          <StyledTimelineDateSelectArrow onClick={() => handleAddDays(1)}>
            <ChevronRight />
          </StyledTimelineDateSelectArrow>
        </StyledTimelineDateSelectWrapper>
      </StyledTimelineDateSelectContainer>
      <StyledTimelineHeader>Linha do tempo:</StyledTimelineHeader>
      <StyledTimelineStops>
        <StyledRouteCoveredHorizontalLine />

        {stops.map(stop => (
          <TimelineStop stop={stop} />
        ))}
      </StyledTimelineStops>
    </StyledRouteCoveredTimeline>
  );
}

export default RouteCoveredTimeline;
