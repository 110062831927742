import * as React from 'react';

import { HeaderFiltersType } from 'context/GlobalFiltersContext/initialState';
import { LkpChart } from '../Chart';
import { useBoolean, useDebounce } from 'hooks';
import { fetcher } from 'services';
import { ChartOccurrencesByFixStatusType } from './types';
import { ChartSeriesType, ChartsParamsType } from '../types';
import { getErrorMessageByStatusCode, groupBy } from 'utils';
import { LkpChartTopFilter } from '../Filters';
import { getStackedChartOptions } from '../EnteredAndLeftOccurrences/options';
import { isNumber } from 'fp-ts/lib/number';
import { useParams } from 'react-router-dom';

export interface LkpChartOccurrencesByFixStatusProps {
  headerFilters: HeaderFiltersType;
}

const HTTP_PATH = '/quantidade-ocorrencias-por-status-correcao-semana';

export function LkpChartOccurrencesByFixStatus({
  headerFilters,
}: LkpChartOccurrencesByFixStatusProps): React.ReactElement {
  const { value: isLoading, setValue: setLoading } = useBoolean();
  const { value: isError, setValue: setIsError } = useBoolean();
  const [errorMessage, setErrorMessage] = React.useState<string>();
  const { dateFrom, dateTo } = useParams<{ dateFrom: string; dateTo: string }>();

  const [series, setSeries] = React.useState<Array<ChartSeriesType>>([]);
  const [period, setPeriods] = React.useState<Array<string>>([]);
  const [paramsSystems, setParamsSystems] = React.useState<string>('');
  const [params, setParams] = React.useState<ChartsParamsType>({
    Empresa: headerFilters?.companies?.id || 0,
    Sites: headerFilters?.units?.id,
    Inicio: dateFrom,
    Fim: dateTo,
  });

  const debouncedStartValue = useDebounce<string>(params?.Inicio || '', 500);
  const debouncedEnvValue = useDebounce<string>(params?.Fim || '', 500);
  const debouncedParamsSystemsValue = useDebounce<string>(paramsSystems || '', 500);

  React.useEffect(() => {
    (async () => {
      await fetchChartData();
    })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedStartValue, debouncedEnvValue, debouncedParamsSystemsValue]);

  const fetchChartData = async () => {
    setLoading(true);

    let _httpPath = HTTP_PATH;
    if (!!paramsSystems) _httpPath = `${_httpPath}?${paramsSystems}`;

    const result = await fetcher
      .get<Array<ChartOccurrencesByFixStatusType>>(_httpPath, 'dashboard', {
        params,
      })
      .catch(error => {
        const message = getErrorMessageByStatusCode(error, _httpPath);
        setErrorMessage(message);
        setIsError(true);
      });

    if (result) {
      const _groupStatus = groupBy<ChartOccurrencesByFixStatusType>(result, d => d.status);

      const _series = _groupStatus.map(({ name, data }) => {
        const _amount: number[] = [];
        data.forEach(({ quantidade }) => {
          const _num = isNumber(Number(quantidade)) ? Number(quantidade) : 0;
          _amount.push(_num);
        });
        return { name, data: _amount };
      });

      setSeries(_series);

      const _groupPeriods = groupBy<ChartOccurrencesByFixStatusType>(result, d => d.periodo);

      setPeriods(_groupPeriods.map(({ name }) => name));
    }

    setLoading(false);
  };

  const handleSelectFixStatus = (selectedFixStatus: string) => {
    setParamsSystems(selectedFixStatus);
  };

  const handleChangeStartDate = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value: Inicio } = event.target;
    setParams({ ...params, Inicio });
  };

  const handleChangeEndDate = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value: Fim } = event.target;
    setParams({ ...params, Fim });
  };

  return (
    <React.Fragment>
      <LkpChart
        title="Ocorrências por status de correção"
        companyId={headerFilters?.companies?.id || 0}
        series={series}
        error={isError}
        errorMessage={errorMessage}
        onChartTryAgain={fetchChartData}
        loading={isLoading}
        options={getStackedChartOptions(period, {
          chart: {
            type: 'bar',
            height: 430,
            toolbar: {
              show: false,
            },
          },
          legend: {
            position: 'right',
          },
          colors: [
            '#00C0C7',
            '#5144D3',
            '#E8871A',
            '#DA3490',
            '#9089FA',
            '#47E26F',
            '#2780EB',
            '#6F38B1',
            '#DFBF03',
            '#CB6F10',
            '#268D6C',
            '#9BEC54',
            '#3366CC',
            '#DC3912',
            '#FF9900',
            '#109618',
            '#990099',
            '#0099C6',
            '#DD4477',
            '#66AA00',
            '#316395',
            '#994499',
            '#22AA99',
            '#AAAA11',
            '#6633CC',
            '#E67300',
            '#8B0707',
            '#CCCCCC',
            '#651067',
            '#329262',
          ],
          dataLabels: {
            enabled: true,
            formatter: function () {
              return ``;
            },
          },
          tooltip: {
            y: {
              formatter: function (value) {
                return value;
              },
            },
          },
        })}
        filter={
          <LkpChartTopFilter
            onChangeStartDate={handleChangeStartDate}
            onChangeEndDate={handleChangeEndDate}
            onSelectStatus={handleSelectFixStatus}
          />
        }
      />
    </React.Fragment>
  );
}
