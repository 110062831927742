import { localStorageKeys } from 'constants/localStorage';

const skeleton = {
  expiresIn: null,
  isAuthenticated: false,
  isRefreshing: false,
  statusCode: false,
  refreshSubscribers: [],
  refreshToken: null,
  stayConnected: true,
  token: null,
  user: {
    id: null,
    name: null,
    permissionType: null,
    fullName: null,
    signUpUnixDate: null,
    type: null,
  },
};

const stored = JSON.parse(localStorage.getItem(localStorageKeys.authContext));

const initialState = stored || skeleton;

export { initialState, skeleton };
