import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { removeDuplicates } from 'utils/removeDuplicates';

import errorIcon from 'assets/icons/error-icon.svg';
import trashIcon from 'assets/icons/trash.svg';
import loadingIcon from 'assets/icons/spinner.svg';
import closeIcon from 'assets/icons/close.svg';
import checkCircleIcon from 'assets/icons/check-circle.svg';
import hourGlassIcon from 'assets/icons/whitehourglass-icon.svg';

import {
  StyledToast,
  StyledIconAction,
  StyledButton,
  StyledCloseBtn,
  StyledToastContainer,
  StyledToastSeparator,
  StyledToastColumn,
} from './styled';

const Toast = props => {
  const { toastList, autoDelete, dismissTime } = props;

  const [list, setList] = useState(toastList);

  useEffect(() => {
    const toastListFilter = removeDuplicates(toastList, 'message');
    setList([...toastListFilter]);

    // eslint-disable-next-line
  }, [toastList]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (autoDelete && toastList.length && toastList.length) {
        deleteToast(toastList[0].id);
      }
    }, dismissTime);

    return () => {
      clearInterval(interval);
    };

    // eslint-disable-next-line
  }, [list, autoDelete, dismissTime, list]);

  const deleteToast = id => {
    const listItemIndex = list.findIndex(e => e.id === id);
    const toastListItem = toastList.findIndex(e => e.id === id);
    list.splice(listItemIndex, 1);
    toastList.splice(toastListItem, 1);

    setList(() => [...list]);
  };

  const handleIconByType = type => {
    switch (type) {
      case 'info':
        return trashIcon;
      case 'loading':
        return loadingIcon;
      case 'success':
        return checkCircleIcon;
      case 'darkSuccess':
        return checkCircleIcon;
      case 'warning':
        return null;
      case 'error':
        return errorIcon;
      case 'trash':
        return trashIcon;
      case 'updating':
        return hourGlassIcon;
      default:
        return null;
    }
  };

  return (
    <StyledToastContainer>
      {list.map((toast, i) => (
        <StyledToast key={i} theme={toast.type}>
          <StyledToastColumn>
            {handleIconByType(toast.type) && <StyledIconAction src={handleIconByType(toast.type)} alt={toast.type} />}
            <p>{toast.message}</p>
          </StyledToastColumn>
          <StyledToastSeparator />
          <StyledToastColumn>
            {toast.btnTitle && <StyledButton onClick={toast.onClick}>{toast.btnTitle}</StyledButton>}
            <StyledCloseBtn src={closeIcon} alt={toast.type} onClick={() => deleteToast(toast.id)} />
          </StyledToastColumn>
        </StyledToast>
      ))}
    </StyledToastContainer>
  );
};

Toast.propTypes = {
  autoDelete: PropTypes.bool,
  dismissTime: PropTypes.number,
};

Toast.defaultProps = {
  toastList: [],
};

export { Toast };
