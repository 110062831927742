import { AxiosReportLas, AxiosGlobalFilters } from '../config';

const ScheduleMonitoringService = {
  getPlans: ({ empresa }) => {
    return AxiosReportLas({
      method: 'get',
      url: '/v1/Agendamento/ListaAgendamento',
      params: {
        empresa,
      },
      headers: {
        'content-type': 'application/json',
      },
    });
  },
  getPlansAdmin: ({ empresa, unidade, status, plano }) => {
    return AxiosReportLas({
      method: 'get',
      url: '/v1/Agendamento/ListaAgendamentoAdmin',
      params: {
        empresa,
        unidade,
        status,
        plano,
      },
      headers: {
        'content-type': 'application/json',
      },
    });
  },
  cancelPlans: ({ planoAtividade }) => {
    return AxiosReportLas({
      method: 'post',
      url: '/v1/Agendamento/CancelarAgendamento',
      params: {
        planoAtividade,
      },
      headers: {
        'content-type': 'application/json',
      },
    });
  },
  reSchedulePlans: ({ planoAtividade }) => {
    return AxiosReportLas({
      method: 'post',
      url: '/v1/PlanoAtividade',
      data: {
        planoAtividadeID: `${planoAtividade}`,
        excluirAgendamentos: '0',
        siteID: '0',
        superUsuario: false,
      },
      headers: {
        'content-type': 'application/json',
      },
    });
  },
  units: ({ empresaId }) => {
    return AxiosGlobalFilters({
      method: 'get',
      url: '/v1/unidades/ativas',
      headers: {
        EmpresaId: empresaId,
      },
    });
  },
};

export { ScheduleMonitoringService };
