import styled, { css, keyframes } from 'styled-components';

const spin = keyframes`
  0% {
    -webkit-transform: rotate(0deg);
  }
  20% {
    -webkit-transform: rotate(60deg);
  }
  40% {
    -webkit-transform: rotate(120deg);
  }
  60% {
    -webkit-transform: rotate(180deg);
  }
  80% {
    -webkit-transform: rotate(240deg);
  }
  90% {
    -webkit-transform: rotate(280deg);
  }
  95% {
    -webkit-transform: rotate(320deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }

`;
export const spinAnimationCss = css`
  animation: ${spin} 1s linear infinite;
`;

export const StyledFlexWrapper = styled.div`
  display: ${props => props.display || 'flex'};
  flex-direction: ${props => props.flexDirection || 'row'};
  justify-content: ${props => props.justifyContent};
  align-items: ${props => props.alignItems};
  grid-column-start: ${props => props.gridColumnStart};
  gap: ${props => props.gap};
`;
export const headerTitleCss = css`
  font-family: Blinker, sans-serif;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
`;
export const strongTitleCss = css`
  font-weight: 500;
  font-size: 16px;
  line-height: 18.75px;
`;
export const strongTextCss = css`
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
`;

export const textCss = css`
  font-weight: 400;
  font-size: 14px;
`;
export const addTrailingDots = css`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const textSizeFactory = size => {
  const small = css`
    &:first-child {
      font-size: 12px;
      line-height: 14px;
    }
  `;
  const medium = css`
    &:first-child {
      font-size: 14px;
      line-height: 16px;
    }
  `;
  const large = css`
    font-size: 16px;
    line-height: 19px;
  `;
  switch (size) {
    case 'small':
      return small;
    case 'medium':
      return medium;
    case 'large':
      return large;
    default:
      return medium;
  }
};
