import styled, { css } from 'styled-components';
import { colors } from 'assets/styled/tokens';

export const FiltersSidebarContainer = styled.div`
  height: ${({ isCollapsed }) => (isCollapsed ? `0` : `100vh`)};
  width: ${({ isCollapsed }) => (isCollapsed ? `0` : `440px`)};
  margin-right: auto;
  background: ${({ isCollapsed }) => (isCollapsed ? `transparent` : colors.pampas)};
  box-shadow: 0px 10px 36px rgba(72, 40, 0, 0.53);
  position: relative;
  z-index: 1;
`;
export const ViewContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 0 16px 16px;
  border-right: 1px solid ${colors.lightGray};
  margin: 40px 0 54px;
  height: 100%;
  overflow-y: scroll;
  > * {
    width: 385px;
  }
  &::-webkit-scrollbar {
    width: 24px;
  }
  &::-webkit-scrollbar-track {
    background: inherit;
  }

  &::-webkit-scrollbar-thumb {
    background: #d6dee1;
    border-radius: 24px;
  }
  &::-webkit-scrollbar-thumb {
    background: #d6dee1;
    border-radius: 24px;
    border: 8px solid ${colors.pampas};
    background-clip: content-box;
  }
  > :first-child {
    margin-top: 24px;
  }
`;
export const ViewCardBase = css`
  display: flex;
  flex-direction: column;
  background: white;
  border: 1px solid ${colors.whiteGray};
  padding: 9px 9px 16px 8px;
  margin: 12px 0;
  border-radius: 4px;
  margin-bottom: -0.7px;
`;

export const CollapseFiltersSidebarButton = styled.button`
  position: absolute;
  width: 42px;
  height: 40px;
  right: 0;
  top: 0;
  z-index: 4;
  svg {
    width: 10px;
    height: 10px;
  }
`;

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 0 80px;
`;
export const InfoDescription = styled.h1`
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
  color: #888888;
  b {
    color: #1e1e1e;
  }
`;
export const ContactButton = styled.button`
  font-family: Roboto;
  font-size: 14px;
  font-weight: 700;
  line-height: 16px;
  color: #ffffff;
  width: 131px;
  height: 32px;
  background-color: #f38a00;
  border-radius: 4px;
  margin-top: 20px;
`;
export const InfoImg = styled.img`
  height: 202px;
  width: 202px;
  margin-bottom: 20px;
`;
