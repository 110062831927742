import axios, { AxiosInstance, AxiosRequestHeaders } from 'axios';
import createAuthRefreshInterceptor from 'axios-auth-refresh';
import { getLocalAuthStorage } from 'utils/localStorage';
import { refreshAuthLogic } from './main';

export type BaseUrlsKeys = 'mock' | 'dashboard' | 'legado' | 'lighthouse' | 'reports' | 'ws';

export const API_BASE_URLS = {
  dashboard: {
    baseUrl: process.env?.REACT_APP_LAS_CHART_API || '',
    basePath: '/api/v1/DashBoard',
  },
  legado: {
    baseUrl: process.env?.REACT_APP_LEGADO || '',
    basePath: '',
  },
  lighthouse: {
    baseUrl: process.env?.REACT_APP_LIGHTHOUSE_FILTERS_API || '',
    basePath: '',
  },
  mock: {
    baseUrl: process.env?.REACT_APP_MOCK_API || '',
    basePath: '',
  },
  reports: {
    baseUrl: process.env?.REACT_APP_REPORTS_API || '',
    basePath: '',
  },
  ws: {
    baseUrl: process.env?.REACT_APP_REPORTS_WEB_SOCKET || '',
    basePath: '',
  },
};

export function api(baseUrlKey: BaseUrlsKeys, headers: AxiosRequestHeaders = {}): AxiosInstance {
  const storage = getLocalAuthStorage();
  const token = storage?.token || '';
  const baseURL = API_BASE_URLS[baseUrlKey].baseUrl;
  const basePath = API_BASE_URLS[baseUrlKey].basePath;

  const axiosInstance = axios.create({
    baseURL: `${baseURL}${basePath}`,
    headers: {
      Authorization: token ? `Bearer ${token}` : '',
      ...headers,
    },
  });

  axiosInstance.interceptors.request.use(request => {
    if (process.env.NODE_ENV === 'development') {
      // eslint-disable-next-line no-console
      console.log('__ REQUEST __', `${request?.url}`, { request });
    }

    return request;
  });

  axiosInstance.interceptors.response.use(
    response => {
      if (process.env.NODE_ENV === 'development') {
        // eslint-disable-next-line no-console
        console.log('__ RESPONSE __', `${response?.config?.url}`, { response });
      }

      return response;
    },
    error => {
      if (process.env.NODE_ENV === 'development') {
        // eslint-disable-next-line no-console
        console.log('__ ERROR __', { error });
      }

      if (error.config.url === '/v1/auth') {
        return new Promise((_, reject) => reject(error.response));
      }

      if (error.response.status !== 401) {
        return Promise.reject(error);
      }

      return Promise.reject(error);
    },
  );

  createAuthRefreshInterceptor(axiosInstance, refreshAuthLogic);

  return axiosInstance;
}
