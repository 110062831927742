import axios from 'axios';
import { addBearerTokenInReq } from './interceptors/addBearerTokenInReq';
import createAuthRefreshInterceptor from 'axios-auth-refresh';
import { AuthService } from '.';
import { getLocalAuthStorage } from 'utils/localStorage';
import { localStorageKeys } from 'constants/localStorage';
import { settIsRefreshing } from 'utils/refresh';
import { handleAuthError } from './interceptors/handleAuthError';
import { Mutex } from 'async-mutex';
import { URLS } from 'constants/urls';

const mutex = new Mutex();
const saveTokensToCookies = (token, refreshToken) => {
  document.cookie = `authToken=${token}; path=/; domain=${URLS.cookieDomain}`;
  document.cookie = `refreshToken=${refreshToken}; path=/; domain=${URLS.cookieDomain}`;
};
function getCookie(name) {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(';').shift();
}
export const refreshAuthLogic = async failedRequest => {
  return mutex.runExclusive(async () => {
    const storage = getLocalAuthStorage();
    const token = getCookie(`authToken`);
    const refreshToken = getCookie(`refreshToken`);

    if (failedRequest.config.url === '/v1/auth') {
      return Promise.reject(failedRequest.response);
    }

    if (failedRequest.response.status === 401) {
      if (!storage.isRefreshing) {
        storage.isRefreshing = true;
        settIsRefreshing(true);

        try {
          const { data } = await AuthService.refreshToken({ token, refreshToken });
          const newToken = data.authToken.token;
          storage.token = newToken;
          storage.refreshToken = data.authToken.refreshToken;
          storage.expiresIn = data.authToken.expiresIn;
          localStorage.setItem(localStorageKeys.authContext, JSON.stringify(storage));
          saveTokensToCookies(data.authToken.token, data.authToken.refreshToken);
          failedRequest.response.config.headers['Authorization'] = 'Bearer ' + newToken;
          return Promise.resolve();
        } catch (error) {
          storage.isRefreshing = false;
          localStorage.setItem(localStorageKeys.authContext, JSON.stringify(storage));
          window.location.replace(`${process.env.REACT_APP_LEGADO}/security/signout.aspx`);
          return Promise.reject(error);
        } finally {
          settIsRefreshing(false);
          storage.isRefreshing = false;
        }
      } else {
        await new Promise(resolve => setTimeout(resolve, 1000));
        return refreshAuthLogic(failedRequest);
      }
    }

    return Promise.reject(failedRequest);
  });
};

const AxiosMain = axios.create({
  baseURL: process.env.REACT_APP_AUTH_API,
});

const AxiosMock = axios.create({
  baseURL: process.env.REACT_APP_MOCK_API,
});

const AxiosCharts = axios.create({
  baseURL: process.env.REACT_APP_LAS_CHART_API,
});

const AxiosGlobalFilters = axios.create({
  baseURL: process.env.REACT_APP_LIGHTHOUSE_FILTERS_API,
});

const AxiosMenuLas = axios.create({
  baseURL: process.env.REACT_APP_LAS,
});
const AxiosMediaCenter = axios.create({
  baseURL: process.env.REACT_APP_MEDIA_CENTER,
});
AxiosMediaCenter.interceptors.request.use(addBearerTokenInReq);
AxiosGlobalFilters.interceptors.request.use(addBearerTokenInReq);
AxiosCharts.interceptors.request.use(addBearerTokenInReq);
AxiosMenuLas.interceptors.request.use(addBearerTokenInReq);
AxiosMain.interceptors.request.use(addBearerTokenInReq);
AxiosMain.interceptors.response.use(res => res, handleAuthError);

createAuthRefreshInterceptor(AxiosGlobalFilters, refreshAuthLogic);
createAuthRefreshInterceptor(AxiosMediaCenter, refreshAuthLogic);
createAuthRefreshInterceptor(AxiosMenuLas, refreshAuthLogic);
createAuthRefreshInterceptor(AxiosCharts, refreshAuthLogic);
export { AxiosMain, AxiosCharts, AxiosGlobalFilters, AxiosMock, AxiosMenuLas, AxiosMediaCenter };
