import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { devices } from 'assets/styled/tokens/devices';
import tooltipArrow from 'assets/icons/tooltip-arrow.svg';

const TooltipText = styled.span`
  opacity: 0;
  width: 88px;
  text-align: left;
  background-color: #1e1e1e;
  color: #fff !important;
  border-radius: 6px;
  padding: 8px;
  position: absolute;
  z-index: 1;
  bottom: ${props => (props.isChildren ? '0px' : '-30px')};
  left: 1px;
  margin-left: 50px;
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  margin-bottom: 0 !important;
  height: ${props => (props.isChildren ? '25px' : '55px')};
  &:after {
    content: url(${tooltipArrow});
    display: inline-flex;
    position: absolute;
    top: 100%;
    left: ${props => (props.isChildren ? '5px' : '-50px')};
    margin-left: ${props => (props.isChildren ? '50px' : '80px')};
    border-width: 3px;
    width: 1px;
    overflow: hidden;
    border-style: solid;
    border-color: #1e1e1e transparent transparent transparent;
  }
  @media ${devices.tabletLandscape} {
    margin-left: 0px;
    width: 88px;
  }
`;

const TooltipContainer = styled.div`
  position: relative;
  display: inline-flex;
  top: 15px;
  :hover {
    margin-left: ${props => (props.isChildren ? '0px' : '40px')};
    ${TooltipText} {
      opacity: 1;
      width: 200px;
      margin-left: -25px;
      height: 25px;
      bottom: ${props => (props.isChildren ? '20px' : '15px')};
    }
  }
`;

import { useState } from 'react';

const useTooltip = () => {
  const [showTooltip, setShowTooltip] = useState(false);

  const handleMouseEnter = label => {
    setShowTooltip(prevStates => ({
      ...prevStates,
      [label]: true,
    }));
  };

  const handleMouseLeave = label => {
    setShowTooltip(prevStates => ({
      ...prevStates,
      [label]: false,
    }));
  };

  return {
    showTooltip,
    handleMouseEnter,
    handleMouseLeave,
  };
};

export default useTooltip;

export const Tooltip = ({ text, isChildren }) => {
  return (
    <TooltipContainer isChildren={isChildren}>
      <TooltipText isChildren={isChildren}>{text}</TooltipText>
    </TooltipContainer>
  );
};

Tooltip.propTypes = {
  text: PropTypes.string.isRequired,
};
