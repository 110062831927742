import { ReactComponent as SeparatorEllipse } from 'assets/icons/ellipse.svg';
import { StyledExtraTechniciansCount, StyledTechniciansCount } from 'pages/Geolocalize/styled';
import TechnicianAvatar from 'pages/Geolocalize/subcomponents/ReactMapbox/TechnicianAvatar';
import { useGeolocalizeStore } from 'pages/Geolocalize/store';

import {
  StyledOnlineTeamTechniciansTab,
  StyledOfflineTechniciansTab,
  StyledTeamCard,
  StyledTeamInfoContainer,
  StyledTeamName,
  StyledTeamTechnicians,
  StyledViewTeamOnMapButton,
} from './styled';

import { SHOW_TEAM_DETAILS } from 'pages/Geolocalize/constants';

const TeamCard = ({ name, technicians }) => {
  const [, dispatch] = useGeolocalizeStore();
  const onlineTechnicians = technicians.filter(technician => technician.status === 'online');
  const offlineTechnicians = technicians.filter(technician => technician.status === 'offline');
  const isOnlineCountActive = onlineTechnicians.length > 0;
  const isOfflineCountActive = offlineTechnicians.length > 0;
  const maxTechniciansPerCard = 10;

  const dispatchTeamDetails = () => {
    const payload = {
      name,
      technicians,
    };

    dispatch({ type: SHOW_TEAM_DETAILS, payload });
  };
  return (
    <StyledTeamCard>
      <StyledTeamName>{name}</StyledTeamName>
      <StyledTeamInfoContainer>
        {isOnlineCountActive && (
          <StyledOnlineTeamTechniciansTab>
            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="6" cy="6" r="6" fill="#F38A00" />
            </svg>

            <StyledTechniciansCount>Online ({onlineTechnicians.length})</StyledTechniciansCount>
          </StyledOnlineTeamTechniciansTab>
        )}
        {isOnlineCountActive && isOfflineCountActive && <SeparatorEllipse />}
        {isOfflineCountActive && (
          <StyledOfflineTechniciansTab>
            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="6" cy="6" r="6" fill="#888888" />
              <rect x="3" y="5" width="6" height="2" fill="white" />
            </svg>
            <StyledTechniciansCount>Offline ({offlineTechnicians.length})</StyledTechniciansCount>
          </StyledOfflineTechniciansTab>
        )}
      </StyledTeamInfoContainer>
      <StyledTeamTechnicians>
        {technicians.map((technician, index) => {
          const zIndex = technicians.length - index;

          if (index < maxTechniciansPerCard) {
            return <TechnicianAvatar technician={technician} zIndex={zIndex} />;
          } else {
            return null;
          }
        })}
        {technicians.length > maxTechniciansPerCard && (
          <StyledExtraTechniciansCount>+{technicians.length - maxTechniciansPerCard}</StyledExtraTechniciansCount>
        )}
      </StyledTeamTechnicians>
      <StyledViewTeamOnMapButton onClick={() => dispatchTeamDetails()}>+ Detalhes</StyledViewTeamOnMapButton>
    </StyledTeamCard>
  );
};

export default TeamCard;
