import { useCallback, useRef } from 'react';

export const useDebounce = (func, delay) => {
  const timeoutRef = useRef(null);

  const debouncedFunction = useCallback(
    (...args) => {
      return new Promise(resolve => {
        if (timeoutRef.current) {
          clearTimeout(timeoutRef.current);
        }
        timeoutRef.current = setTimeout(() => {
          resolve(func(...args));
        }, delay);
      });
    },
    [func, delay],
  );

  return debouncedFunction;
};
