import styled from 'styled-components';

export const CircleBody = styled.div`
  width: 128px;
  height: 128px;
  border-radius: 50%;
  border: 1px solid ${props => props.color};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  > h1 {
    font-family: Blinker;
    font-size: 40px;
    font-weight: 700;
    line-height: 44px;
    letter-spacing: 0.3px;
    text-align: center;
    color: ${props => props.color};
  }
  > h2 {
    font-family: Blinker;
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0.5px;
    text-align: center;
    color: ${props => props.color} !important;
  }
  svg {
    position: absolute;
    right: 10px;
    top: 10px;
  }
`;
