import styled from 'styled-components';

import { DefaultTextarea } from 'assets/styled/sheets/components/Forms';

import { inputStyleFactory } from 'components/Form/shared/inputStyleFactory';
import { colors } from 'assets/styled/tokens';

export const StyledTextarea = styled.textarea`
  ${DefaultTextarea};
  ${props => inputStyleFactory(props.size)};
  padding: 6px ​12px;
  border-color: ${props => props.error && colors.bittersweet};
  cursor: ${props => props.disabled && 'not-allowed'};

  &:active {
    pointer-events: ${props => props.disabled && 'none'};
  }

  &::placeholder {
    color: ${props => props.disabled && colors.mediumGray};
  }

  &:focus {
    border-color: ${props => props.disabled && colors.whiteGray};
  }
`;

export const StyledError = styled.div`
  display: block;
  transform: translateY(2px);
  font-size: 12px;
  margin-left: 2px;
  transition: all 300ms ease;
  p {
    line-height: initial;
    color: ${colors.darkRed};
  }
`;

export const StyledIcon = styled.img`
  height: 10px;
  width: 10px;
  position: absolute;
  right: 8px;
  top: 50%;
  transform: translateY(-50%);
`;
