import styled from 'styled-components';

export const ChartContainer = styled.div`
  display: block;
  width: 100%;
  overflow-y: scroll;
  height: 300px;
  margin: 16px;
`;

export type ColorType = {
  background: string;
  progress: string;
};

export const ChartItem = styled.div`
  border-bottom: 1px solid #e5e5e5;
  padding: 12px 0;
  display: block;
  width: 100%;

  &:first-child {
    border-top: 1px solid #e5e5e5;
  }
`;

export const ChartName = styled.span`
  color: #888;
  display: inline-block;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  text-transform: uppercase;
  min-width: 200px;
`;

export const ChartPercentage = styled.div<{ progress?: string }>`
  color: ${({ progress }) => `${progress ? progress : '#00a889'}`};
  display: inline-block;
  font-size: 14px;
  font-weight: 700;
  line-height: 16px;
  min-width: 50px;
  text-align: right;
`;

export const ChartProgress = styled.span<{ percentage?: number; background?: string; progress?: string }>`
  background-color: ${({ background }) => `${background ? background : '#bfe9e1'}`};
  border-radius: 100px;
  display: inline-block;
  height: 8px;
  margin: 0 24px 0 16px;
  position: relative;
  width: 160px;

  &:before {
    background-color: ${({ progress }) => `${progress ? progress : '#00a889'}`};
    border-radius: 100px;
    bottom: 0;
    content: '';
    display: inline-block;
    height: 8px;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: ${({ percentage = 0 }) => `${percentage}%`};
  }
`;
