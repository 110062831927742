import { pipe } from 'fp-ts/function';
import { fromNullable, fold } from 'fp-ts/Option';
import { getValidNumberValue } from '../helpers';

export function getOnlyHours(value: number) {
  return pipe(
    getValidNumberValue(value),
    fromNullable,
    fold(
      () => '-',
      value => {
        const hours = Math.floor(value / 60);
        return `${hours.toLocaleString('pt-BR')}`;
      },
    ),
  );
}
export function getOnlyHoursYaxis(value: number) {
  return pipe(
    getValidNumberValue(value),
    fromNullable,
    fold(
      () => '0',
      value => {
        const hours = 1 + Math.floor(value / 60);
        return `${hours.toLocaleString('pt-BR')}`;
      },
    ),
  );
}

export function minutesToHours(value: number) {
  return pipe(
    getValidNumberValue(value),
    fromNullable,
    fold(
      () => '-',
      value => {
        const hours = getOnlyHours(value);
        const minutes = value % 60;
        const txtMinutes = `00${minutes}`.slice(-2);

        if (txtMinutes === '00') return `${hours}h`;
        if (hours && Number(hours) === 0) return `${txtMinutes}m`;

        return `${hours}h ${txtMinutes}m`;
      },
    ),
  );
}
