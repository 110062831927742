import { HeaderFiltersType } from 'context/GlobalFiltersContext/initialState';
import { useBoolean, useDebounce } from 'hooks';
import * as React from 'react';
import { fetcher } from 'services';
import { LkpChartTopFilter } from '../Filters';
import { ChartsParamsType } from '../types';
import { ChartAvailabilityOfSystemsType } from './types';
import { LkpChart } from '../Chart';
import { ChartContainer, ChartItem, ChartName, ChartPercentage, ChartProgress, ColorType } from './styled';
import { getErrorMessageByStatusCode } from 'utils';
import { useParams } from 'react-router-dom';

export interface LkpChartAvailabilityOfSystemsProps {
  headerFilters: HeaderFiltersType;
}

const COLORS_CHART: Array<ColorType> = [
  {
    background: '#BFE9E1',
    progress: '#00A889',
  },
  {
    background: '#FCE2BF',
    progress: '#F38A00',
  },
  {
    background: '#FAD9D5',
    progress: '#EC6655',
  },
];

export function LkpChartAvailabilityOfSystems({
  headerFilters,
}: LkpChartAvailabilityOfSystemsProps): React.ReactElement {
  const { value: isLoading, setValue: setLoading } = useBoolean();
  const { value: isError, setValue: setIsError } = useBoolean();
  const [errorMessage, setErrorMessage] = React.useState<string>();
  const { dateFrom, dateTo } = useParams<{ dateFrom: string; dateTo: string }>();

  const [data, setData] = React.useState<Array<ChartAvailabilityOfSystemsType>>([]);
  const [paramsSystems, setParamsSystems] = React.useState<string>('');
  const [params, setParams] = React.useState<ChartsParamsType>({
    Empresa: headerFilters?.companies?.id || 0,
    Sites: headerFilters?.units?.id,
    Inicio: dateFrom,
    Fim: dateTo,
  });
  const debouncedStartValue = useDebounce<string>(params?.Inicio || '', 500);
  const debouncedEnvValue = useDebounce<string>(params?.Fim || '', 500);
  const debouncedParamsSystemsValue = useDebounce<string>(paramsSystems || '', 500);

  React.useEffect(() => {
    (async () => {
      await fetchChartData();
    })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedStartValue, debouncedEnvValue, debouncedParamsSystemsValue]);

  const fetchChartData = async () => {
    setLoading(true);

    let _httpPath = '/disponibilidade-por-sistema-por-periodo';
    if (!!paramsSystems) _httpPath = `${_httpPath}?${paramsSystems}`;
    const result = await fetcher
      .get<Array<ChartAvailabilityOfSystemsType>>(_httpPath, 'dashboard', { params })
      .catch(error => {
        const message = getErrorMessageByStatusCode(error, _httpPath);
        setErrorMessage(message);
        setIsError(true);
      });

    if (result) {
      setData(result);
    }

    setLoading(false);
  };

  const handleChangeStartDate = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value: Inicio } = event.target;
    setParams({ ...params, Inicio });
  };

  const handleChangeEndDate = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value: Fim } = event.target;
    setParams({ ...params, Fim });
  };

  const handleSelectSystems = (selectedSystems: string) => {
    setParamsSystems(selectedSystems);
  };

  return (
    <LkpChart
      title="Disponibilidade de Sistemas"
      companyId={headerFilters?.companies?.id || 0}
      loading={isLoading}
      error={isError}
      errorMessage={errorMessage}
      //height={446}
      //width={830}
      filter={
        <LkpChartTopFilter
          onChangeStartDate={handleChangeStartDate}
          onChangeEndDate={handleChangeEndDate}
          onSelectSystems={handleSelectSystems}
        />
      }
    >
      <ChartContainer>
        {data &&
          data.length > 0 &&
          data.map(({ nome, sistema: id, disponibilidade }) => {
            if (!disponibilidade) disponibilidade = 0;

            // 85 ou mais
            let color: ColorType = COLORS_CHART[0];
            // 70 a 84 laranja
            if (disponibilidade >= 70 && disponibilidade < 85) color = COLORS_CHART[1];
            // abaixo de 70 vermelho
            if (disponibilidade < 70) color = COLORS_CHART[2];

            return (
              <ChartItem key={id} id={id}>
                <ChartPercentage progress={color?.progress}>{disponibilidade}%</ChartPercentage>
                <ChartProgress percentage={disponibilidade} progress={color?.progress} background={color?.background} />
                {nome && <ChartName>{nome}</ChartName>}
              </ChartItem>
            );
          })}
      </ChartContainer>
    </LkpChart>
  );
}
