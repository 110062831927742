import React, { useState } from 'react';

import { useForm } from 'react-hook-form';

import { PreAuthPage } from 'containers/PreAuthPage';
import { Button } from 'components/Button';
import { Input } from 'components/Form/subcomponents/Input';
import { Toast } from 'components/Toast';
import { Loading } from 'components/Loading';
import { ModalGeneric } from 'components/ModalGeneric';

import leankeepLogo from 'assets/icons/leankeep/leankeep-facility-logo.svg';
import emailErrorIcon from 'assets/icons/email-error.svg';
import emailSuccessIcon from 'assets/icons/email-success.svg';

import {
  StyledWelcome,
  StyledForm,
  StyledFormGroup,
  StyledFormLogin,
  StyledFormFooter,
  StyledError,
  StyledModalBody,
} from './styled';
import { AuthService } from 'services/main';

const ForgotPassword = () => {
  const { register, handleSubmit, errors, getValues } = useForm({
    mode: 'all',
  });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentModalInfo, setCurrentModalInfo] = useState('errorPermission');

  const [showToast] = useState(false);
  const [loading] = useState(false);

  const { email } = getValues();

  const modalInfos = {
    success: {
      icon: emailSuccessIcon,
      title: 'Tudo certo!',
      text: (
        <>
          <p>Um link para redefinição de senha foi enviado para o e-mail:</p>
          <p>{email}</p>
          <p>Não esqueça de verificar o spam.</p>
        </>
      ),
    },

    errorPermission: {
      icon: emailErrorIcon,
      title: 'Login e/ou e-mail não coincidem',
      text: (
        <p>
          Para receber o link de redefinição de senha, seu login e e-mail precisam coincidir com os dados cadastrados no
          Leankeep.
        </p>
      ),
    },
  };

  const onSubmit = async values => {
    const response = await AuthService.recoveryPassword({
      email: values.email,
      login: values.login,
    });

    if (response.status === 200) {
      setCurrentModalInfo('success');
      setIsModalOpen(true);
    } else {
      setCurrentModalInfo('errorPermission');
      setIsModalOpen(true);
    }
  };

  return (
    <PreAuthPage>
      <StyledForm onSubmit={handleSubmit(onSubmit)}>
        <img src={leankeepLogo} alt="Leankeep" />
        <>
          <StyledWelcome>
            <h3>Esqueceu sua senha?</h3>
            <span>Vamos te ajudar a redefinir, insira seu login e e-mail cadastrado no Leankeep:</span>
          </StyledWelcome>

          <StyledFormLogin>
            <StyledFormGroup label="Login">
              <Input
                type="text"
                name="login"
                size="medium"
                ref={register({
                  required: true,
                })}
              />
              {errors.login && errors.login.type === 'required' && (
                <StyledError>
                  <p>Este campo é obrigatório.</p>
                </StyledError>
              )}
            </StyledFormGroup>
            <StyledFormGroup label="E-mail">
              <Input
                type="text"
                name="email"
                id="email"
                size="medium"
                ref={register({
                  required: true,
                })}
              />
              {errors.email && errors.email.type === 'required' && (
                <StyledError>
                  <p>Este campo é obrigatório.</p>
                </StyledError>
              )}
            </StyledFormGroup>
            <StyledFormFooter>
              <Button type="submit">Enviar</Button>
            </StyledFormFooter>
            <p>
              Dúvidas? Entre em contato pelo{' '}
              <a href="mailto:suporte@leankeep.com.br?subject=Duvida">suporte@leankeep.com.br</a>
            </p>
          </StyledFormLogin>
        </>
      </StyledForm>
      <>
        <ModalGeneric open={isModalOpen} closeModal={() => setIsModalOpen(false)}>
          <StyledModalBody>
            <img src={modalInfos[currentModalInfo].icon} alt="usuario invalido" />
            <h6>{modalInfos[currentModalInfo].title}</h6>
            {modalInfos[currentModalInfo].text}
            {currentModalInfo === 'success' ? (
              <Button type="button" onClick={() => window.location.replace('/login')}>
                ENTENDI
              </Button>
            ) : (
              <Button type="button" onClick={() => setIsModalOpen(false)}>
                ENTENDI
              </Button>
            )}
          </StyledModalBody>
        </ModalGeneric>
        {showToast && <Toast message="Login e/ou senha do usuário inválido(s)" type="error" />}
        {loading && <Loading />}
      </>
    </PreAuthPage>
  );
};

export { ForgotPassword };
