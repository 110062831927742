import React, { forwardRef, useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

import { Button } from 'components/Button';
import Conditional from 'components/Conditional';

import CleanInputIcon from 'components/Form/shared/CleanInputIcon';

import Checkbox from './subcomponents/Checkbox';

import { Input } from '../Input';

import { pagination } from 'utils/pagination';

import MagnifyingIcon from './assets/magnifying.svg';
import { ReactComponent as NoResultsFoundIcon } from './assets/no-results-found.svg';
import { ReactComponent as NextIcon } from './assets/next.svg';
import { ReactComponent as NextAllIcon } from './assets/next-all.svg';
import { ReactComponent as BackIcon } from './assets/back.svg';
import { ReactComponent as BackAllIcon } from './assets/back-all.svg';

import {
  StyledSelectWrapper,
  StyledModal,
  StyledSelect,
  StyledSearchBar,
  StyledContent,
  StyledDropdownItem,
  StyledPagination,
  StyledNoResultsFound,
} from './styled';

const ModalMultiSelect = forwardRef((props, ref) => {
  const { title, subtitle, size, placeholder, disabled, onChange, options = [] } = props;

  const [isOpen, setIsOpen] = useState(false);
  const [selecteds, setSelecteds] = useState([]);
  const [filterWord, setFilterWord] = useState('');

  const [currentPage, setCurrentPage] = useState(0);
  // const [pages, setPages] = useState([]);
  // const [pageLimit, setPageLimit] = useState({
  //   start: 0,
  //   end: 0
  // });
  const [totalPages, setTotalPages] = useState(0);

  const [quantity] = useState(9);

  const optionsMemoized = useMemo(() => options, [options]);

  useEffect(() => {
    const options = pagination(optionsMemoized, quantity);

    setTotalPages(options.totalPages);
    // setPages(options.pages);
    // setPageLimit({
    //   start: 0,
    //   end: 5
    // });

    // eslint-disable-next-line
  }, [optionsMemoized]);

  useEffect(() => {
    if (props.value && props.value.length > 0) {
      setSelecteds(props.value.map(item => `${item}`));
    } else {
      setSelecteds([]);
    }
  }, [props.value]);

  useEffect(() => {}, [optionsMemoized]);

  const openDropDownContent = () => {
    if (optionsMemoized.length > 0) {
      setIsOpen(() => !isOpen);
    }
  };

  const filterOptionsByWord = option => {
    if (!filterWord) {
      return option;
    }

    const wordMatches = `${option.label}`.toLowerCase().includes(filterWord);

    if (wordMatches) {
      return option;
    }

    return null;
  };

  const handleAllCheck = e => {
    if (e.target.checked) {
      const newState = optionsMemoized.map(option => `${option.value}`);
      setSelecteds(newState);
      onChange && onChange(newState);
    } else {
      setSelecteds([]);
      onChange && onChange([]);
    }
  };

  const handleCheck = useCallback(
    e => {
      if (e.target.checked) {
        setSelecteds([...selecteds, `${e.target.value}`]);
        onChange && onChange([...selecteds, `${e.target.value}`]);
      } else {
        const newState = selecteds.filter(item => `${item}` !== `${e.target.value}`);
        setSelecteds(newState);
        onChange && onChange(newState);
      }
    },
    [onChange, selecteds],
  );

  const handleClearSelections = () => {
    setSelecteds([]);
    onChange && onChange([]);
  };

  const handlePageChange = pageIndex => {
    if (pageIndex < 0) {
      return;
    }

    if (pageIndex >= totalPages) {
      return;
    }

    setCurrentPage(pageIndex);
  };

  const handlePlaceholder = useCallback(() => {
    if ((disabled && selecteds.length <= 0) || options.length <= 0) {
      return 'Seleção desabilitada';
    }

    if (options.length > 0 && selecteds.length > 0) {
      if (selecteds.length === 1) {
        return `${optionsMemoized.find(option => `${option.value}` === `${selecteds[0]}`).label}`;
      }
      return `${selecteds.length} itens selecionados`;
    }

    return placeholder;
  }, [disabled, options.length, optionsMemoized, placeholder, selecteds]);

  return (
    <StyledSelectWrapper ref={ref}>
      <StyledSelect
        readOnly
        type="text"
        size={size}
        placeholder={handlePlaceholder()}
        disabled={disabled || optionsMemoized.length <= 0}
        onClick={openDropDownContent}
      />

      {!disabled && optionsMemoized?.length > 0 && selecteds?.length > 0 && (
        <CleanInputIcon
          size={size}
          onClick={() => {
            setSelecteds([]);
            onChange && onChange([]);
          }}
        />
      )}

      <StyledModal open={isOpen} hasHeader title={title} subtitle={subtitle} closeModal={() => setIsOpen(false)}>
        <StyledContent>
          <StyledSearchBar>
            <Input
              type="text"
              autoComplete="off"
              name="search"
              size="medium"
              placeholder="Pesquisar"
              icon={MagnifyingIcon}
              onChange={e => setFilterWord(e.target.value)}
            />
          </StyledSearchBar>
          <Conditional when={!filterWord}>
            <StyledDropdownItem>
              <Checkbox
                name="select-all"
                label="Selecionar todos"
                value="all"
                isCheckboxAll={true}
                isChecked={selecteds && selecteds.length > 0}
                isAllSelected={selecteds.length === optionsMemoized.length}
                onChange={handleAllCheck}
              />
            </StyledDropdownItem>
          </Conditional>
          {optionsMemoized
            .filter(option => filterOptionsByWord(option))
            .slice(quantity * currentPage, quantity * currentPage + quantity)
            .map(({ label, value }, index) => (
              <StyledDropdownItem key={`${index}-${value}`}>
                <Checkbox
                  label={label}
                  value={value}
                  isChecked={selecteds.includes(`${value}`)}
                  onChange={handleCheck}
                />
              </StyledDropdownItem>
            ))}

          <Conditional when={optionsMemoized?.filter(option => filterOptionsByWord(option))?.length <= 0}>
            <StyledNoResultsFound>
              <NoResultsFoundIcon width={100} height={80} />
              <h6>Nenhum item corresponde a esta pesquisa.</h6>
              <p>Certifique-se de que todas as palavras estejam escritas corretamente.</p>
            </StyledNoResultsFound>
          </Conditional>
        </StyledContent>

        <Conditional when={optionsMemoized?.length > 9}>
          <StyledPagination>
            <button type="button" onClick={() => handlePageChange(0)}>
              <BackAllIcon width={10} height={8} />
            </button>

            <button type="button" onClick={() => handlePageChange(currentPage - 1)}>
              <BackIcon width={10} height={8} />
            </button>

            <ul>
              {/* {pages
              .slice(pageLimit.start, pageLimit.end)
              .map((page) => (
                <li>
                  <button
                    type="button"
                    onClick={() => handlePageChange(page)}
                  >
                    {page + 1}
                  </button>
                </li>
              ))} */}
              <li>
                <button type="button">{`${currentPage + 1}/${totalPages}`}</button>
              </li>
            </ul>

            <button type="button" onClick={() => handlePageChange(currentPage + 1)}>
              <NextIcon width={10} height={8} />
            </button>

            <button type="button" onClick={() => handlePageChange(totalPages - 1)}>
              <NextAllIcon width={10} height={8} />
            </button>
          </StyledPagination>
        </Conditional>

        <footer>
          <p>
            <Conditional when={selecteds?.length > 0}>
              {handlePlaceholder()}
              <button type="button" onClick={handleClearSelections}>
                Limpar seleção
              </button>
            </Conditional>
          </p>

          <Button
            type="button"
            onClick={() => {
              setIsOpen(false);
              onChange && onChange(selecteds);
            }}
          >
            Salvar
          </Button>
        </footer>
      </StyledModal>
    </StyledSelectWrapper>
  );
});

ModalMultiSelect.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  size: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  optionsMemoized: PropTypes.array.isRequired,
};

ModalMultiSelect.defaultProps = {
  title: '',
  subtitle: '',
  size: 'medium',
  placeholder: '',
  disabled: false,
  optionsMemoized: [],
};

export default ModalMultiSelect;
