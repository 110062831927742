import React from 'react';

import { ReactComponent as PencilAndPaperIcon } from '../../assets/pencil-and-paper.svg';

import { StyledColumnFillFields } from './styled';

const ReportContainerFillFields = () => {
  return (
    <StyledColumnFillFields>
      <PencilAndPaperIcon width={204} height={136} />
      <span>Comece preenchendo os campos ao lado</span>
    </StyledColumnFillFields>
  );
};

export { ReportContainerFillFields };
