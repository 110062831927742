/* istanbul ignore file */

import styled, { css } from 'styled-components';

import { colors, medias } from 'assets/styled/tokens';

export const StyledFiltersContainer = styled.div`
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;
  justify-content: flex-start;
  padding-right: 1rem;
  font-weight: 500;
  font-size: 12px;
  color: ${colors.gray};

  button {
    text-transform: uppercase;
  }

  ${props =>
    !props.isOpen &&
    css`
      ${StyledFilterColumn} {
        display: flex;
        flex-flow: row wrap;
        align-items: flex-start;
        justify-content: flex-start;

        @media screen and (max-width: ${medias.tablet}) {
          display: contents;
        }
      }
    `}
`;

export const StyledButtonFilter = styled.div`
  @media (max-width: ${medias.tablet}) {
    button {
      display: none;
    }
  }
`;

export const StyledFilterColumn = styled.div`
  flex: 0 0 auto;
  padding: 0 1rem;
  display: block;

  div {
    label {
      margin: 0px 5px 20px 5px !important;
    }
  }

  @media (max-width: ${medias.tablet}) {
    flex: auto;
    width: 100%;
    padding: 0px;
  }
`;
