const pages = {
  first: [
    // {
    //   label: 'Dashboard NPS',
    //   link: '/não-existe ainda',
    // },
    {
      label: 'Agendamento de planos',
      link: '/monitoria-de-agendamento-admin',
    },
    // {
    //   label: 'Envio de push',
    //   link: '/não-existe ainda',
    // },
    // {
    //   label: `Cadastro de Clientes`,
    //   link: '/não-existe ainda',
    // },
  ],
};

export { pages };
