import React from 'react';
import { Logo, MenuContainer, LogoutButton, StyledNav, StyledMenuCategory } from './styled';
import { CategoryItem } from './subcomponents/CategoryItem';
import { pages } from 'constants/menuAdmin';

export const Menu = () => {
  const logOut = () => {
    window.location.href = `${process.env.REACT_APP_LEGADO}/security/signout.aspx`;
  };
  return (
    <MenuContainer>
      <Logo>
        <svg width="132" height="39" viewBox="0 0 132 39" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M130.111 3.84967L124.444 0.547876C123.87 0.210908 123.219 0.0335083 122.556 0.0335083C121.892 0.0335083 121.241 0.210908 120.667 0.547876L115 3.84967C114.422 4.18873 113.943 4.67732 113.611 5.26571C113.28 5.8541 113.107 6.52131 113.111 7.19945V13.803C113.107 14.4812 113.28 15.1484 113.611 15.7368C113.943 16.3252 114.422 16.8138 115 17.1528L120.667 20.4546C121.241 20.7916 121.892 20.969 122.556 20.969C123.219 20.969 123.87 20.7916 124.444 20.4546L130.111 17.1528C130.689 16.8138 131.168 16.3252 131.5 15.7368C131.831 15.1484 132.004 14.4812 132 13.803V7.19945C132.004 6.52131 131.831 5.8541 131.5 5.26571C131.168 4.67732 130.689 4.18873 130.111 3.84967ZM122.556 3.74409C122.84 3.73654 123.12 3.81493 123.36 3.9693C123.6 4.12367 123.79 4.34709 123.905 4.61122C124.021 4.87535 124.056 5.1683 124.007 5.45294C123.958 5.73757 123.828 6.00108 123.631 6.21004C123.435 6.419 123.182 6.56402 122.904 6.6267C122.627 6.68939 122.337 6.66692 122.072 6.56214C121.807 6.45736 121.579 6.27499 121.416 6.03816C121.253 5.80132 121.164 5.52068 121.158 5.23181C121.154 5.03862 121.188 4.84659 121.258 4.66688C121.328 4.48717 121.432 4.32335 121.565 4.18494C121.697 4.04653 121.856 3.93629 122.031 3.86061C122.206 3.78493 122.394 3.74532 122.584 3.74409H122.556ZM117.833 7.58338H127.344V9.50303H117.824L117.833 7.58338ZM126.116 15.9242L122.678 12.4689C122.655 12.4466 122.625 12.4341 122.593 12.4341C122.562 12.4341 122.531 12.4466 122.508 12.4689L119.09 15.9146L117.39 14.8876L121.167 11.0963C121.545 10.7188 122.054 10.5073 122.584 10.5073C123.114 10.5073 123.623 10.7188 124 11.0963L127.778 14.9356L126.116 15.9242Z"
            fill="#F38A00"
          />
          <path
            d="M3.57974 12.7081C3.74955 12.8677 3.94885 12.9915 4.16609 13.0722C4.38333 13.1529 4.61418 13.189 4.84525 13.1784H11.1539V16.2882H4.39194C3.79156 16.2949 3.19644 16.1739 2.64477 15.9331C2.09327 15.6567 1.59443 15.2829 1.17149 14.8293C0.831835 14.4426 0.557404 14.0015 0.3593 13.5239C0.113702 12.885 -0.0081549 12.2038 0.000423358 11.5179V0H3.19253V11.5179C3.16262 11.9509 3.30173 12.3785 3.57974 12.7081Z"
            fill="#F38A00"
          />
          <path
            d="M12.5049 7.41908C12.4948 6.76011 12.6202 6.10626 12.8732 5.49943C13.0998 4.9309 13.4191 4.40524 13.8176 3.94452C14.2208 3.50075 14.701 3.13633 15.2342 2.86952C15.7304 2.61934 16.2764 2.48801 16.8303 2.48558H19.8618C20.5183 2.46551 21.1688 2.61756 21.7507 2.9271C22.271 3.21284 22.7327 3.59731 23.1106 4.0597C23.4719 4.52633 23.7587 5.0478 23.9606 5.60501C24.1585 6.13562 24.2608 6.69807 24.2628 7.26551V10.7977H15.6214V11.5271C15.5947 11.7846 15.6297 12.0448 15.7234 12.2854C15.8172 12.5261 15.9669 12.7402 16.1597 12.9093C16.5727 13.1966 17.0671 13.3382 17.5669 13.3124H23.7717V16.3454H16.8775C16.2889 16.3469 15.7065 16.2226 15.1681 15.9807C14.6504 15.7466 14.1772 15.4217 13.7704 15.0209C13.3652 14.5906 13.0446 14.0857 12.826 13.5332C12.5912 12.9217 12.4757 12.2697 12.486 11.6135L12.5049 7.41908ZM15.6686 8.22533H21.1746V8.11015C21.2013 7.48335 21.0145 6.86636 20.6457 6.36327C20.4762 6.14276 20.2578 5.96608 20.0083 5.84765C19.7589 5.72922 19.4854 5.67239 19.2102 5.6818H17.5386C17.2675 5.67896 17.0005 5.74846 16.7642 5.88336C16.5349 6.01585 16.3354 6.19567 16.1786 6.41126C16.0105 6.64487 15.8799 6.90403 15.7914 7.17912C15.6932 7.48911 15.6453 7.81331 15.6498 8.13895L15.6686 8.22533Z"
            fill="#F38A00"
          />
          <path
            d="M26.4253 6.87135C26.4823 6.30925 26.6125 5.75732 26.8125 5.23006C27.0283 4.70983 27.3502 4.24202 27.7569 3.85751C28.1737 3.43739 28.6724 3.11046 29.2208 2.89768C29.8638 2.64044 30.551 2.51643 31.2418 2.53295H32.8379C33.5538 2.51635 34.265 2.65373 34.925 2.93608C35.487 3.1821 35.9921 3.54503 36.4078 4.00148C36.7946 4.44422 37.093 4.95932 37.2861 5.518C37.4755 6.07055 37.5744 6.65097 37.5788 7.23609V16.2968H30.0235C29.5076 16.3089 28.9955 16.2051 28.5237 15.9927C28.0519 15.7803 27.632 15.4647 27.2942 15.0682C26.9769 14.6834 26.7421 14.2356 26.6048 13.7533C26.4502 13.2271 26.3738 12.6803 26.3781 12.1312C26.3751 11.5995 26.4581 11.071 26.6236 10.5667C26.763 10.1026 26.998 9.67392 27.3131 9.3093C27.6261 8.94755 28.0128 8.65934 28.4464 8.46466C28.9082 8.24465 29.4098 8.12376 29.9196 8.10953H34.4717V7.84077C34.477 7.51867 34.4485 7.19691 34.3867 6.88095C34.3417 6.62519 34.2418 6.38267 34.0939 6.17068C33.9295 5.95154 33.7066 5.78497 33.4517 5.69077C33.0884 5.59438 32.7109 5.56512 32.3373 5.60439H31.6763C31.1514 5.56412 30.6256 5.67032 30.1557 5.91153C29.8419 6.14377 29.6233 6.48552 29.5419 6.87135H26.4253ZM29.1736 12.1216C29.1473 12.4916 29.2437 12.8599 29.4474 13.1678C29.5217 13.268 29.6171 13.3502 29.7265 13.4083C29.836 13.4664 29.9568 13.499 30.0802 13.5037H34.4717V10.7394H30.0802C29.9551 10.7457 29.833 10.7809 29.7233 10.8425C29.6137 10.904 29.5193 10.9903 29.4474 11.0946C29.2465 11.3965 29.1501 11.7578 29.1736 12.1216Z"
            fill="#F38A00"
          />
          <path
            d="M43.4808 5.66161V16.2869H40.3643V2.52298H46.8618C47.5744 2.50916 48.282 2.64645 48.9395 2.92611C49.5084 3.16954 50.0202 3.53264 50.4411 3.99151C50.8503 4.42952 51.1712 4.94472 51.3855 5.50804C51.5824 6.05891 51.6846 6.64006 51.6877 7.22612V16.2869H48.5712V7.8788C48.5795 7.55704 48.5542 7.2353 48.4956 6.91898C48.4517 6.66123 48.3481 6.41779 48.1934 6.20871C48.0232 5.99146 47.7979 5.82553 47.5418 5.72879C47.1832 5.62367 46.809 5.58466 46.4368 5.61361L43.4808 5.66161Z"
            fill="#F38A00"
          />
          <path
            d="M68.3755 7.41946C68.3638 6.75938 68.4926 6.10453 68.7533 5.49981C68.9799 4.93128 69.2992 4.40562 69.6977 3.94489C70.0975 3.50142 70.5746 3.13695 71.1049 2.86989C71.6018 2.62194 72.1473 2.49072 72.7009 2.48596H75.7797C76.4364 2.46369 77.0874 2.61587 77.6685 2.92748C78.1922 3.2082 78.6547 3.59342 79.0285 4.06007C79.3966 4.52204 79.6841 5.04471 79.8784 5.60539C80.0782 6.13579 80.1837 6.698 80.1901 7.26588V10.798H71.5204V11.5275C71.4925 11.7841 71.526 12.0438 71.6181 12.2845C71.7102 12.5251 71.8581 12.7396 72.0493 12.9096C72.4628 13.1957 72.9568 13.3372 73.4564 13.3128H79.6612V16.3458H72.767C72.1781 16.3496 71.5953 16.2252 71.0576 15.9811C70.5373 15.7518 70.0633 15.4264 69.6599 15.0213C69.2515 14.5936 68.9305 14.088 68.7155 13.5335C68.4729 12.9241 68.354 12.2712 68.3661 11.6139L68.3755 7.41946ZM71.5393 8.22571H77.0546V8.11053C77.0768 7.48433 76.8905 6.86884 76.5258 6.36365C76.3543 6.14296 76.1342 5.96631 75.8832 5.84793C75.6322 5.72956 75.3574 5.67278 75.0808 5.68217H73.4375C73.1663 5.67796 72.899 5.74755 72.6631 5.88374C72.4325 6.01428 72.2326 6.19445 72.0776 6.41164C71.906 6.64301 71.775 6.90279 71.6904 7.1795C71.5891 7.48898 71.5381 7.81317 71.5393 8.13932V8.22571Z"
            fill="#F38A00"
          />
          <path
            d="M82.3898 7.41908C82.3797 6.76011 82.5051 6.10626 82.7581 5.49943C82.9848 4.9309 83.304 4.40524 83.7025 3.94452C84.1039 3.49874 84.5846 3.13401 85.1191 2.86952C85.6153 2.61934 86.1613 2.48801 86.7152 2.48558H89.7468C90.4032 2.46551 91.0537 2.61756 91.6356 2.9271C92.1559 3.21284 92.6176 3.59731 92.9955 4.0597C93.3568 4.52633 93.6436 5.0478 93.8455 5.60501C94.0388 6.1369 94.1411 6.69853 94.1477 7.26551V10.7977H85.5252V11.5271C85.4997 11.7836 85.5343 12.0425 85.6262 12.2828C85.7182 12.523 85.8648 12.7377 86.0541 12.9093C86.4712 13.1954 86.9681 13.3368 87.4707 13.3124H93.6755V16.3454H86.7813C86.1927 16.3469 85.6103 16.2226 85.0719 15.9807C84.5542 15.7466 84.081 15.4217 83.6742 15.0209C83.269 14.5906 82.9485 14.0857 82.7298 13.5332C82.495 12.9217 82.3795 12.2697 82.3898 11.6135V7.41908ZM85.5536 8.22533H91.0595V8.11015C91.0862 7.48335 90.8995 6.86636 90.5306 6.36327C90.3611 6.14276 90.1427 5.96608 89.8932 5.84765C89.6438 5.72922 89.3703 5.67239 89.0951 5.6818H87.4424C87.1713 5.67896 86.9043 5.74846 86.668 5.88336C86.4387 6.01585 86.2392 6.19567 86.0824 6.41126C85.9144 6.64487 85.7837 6.90403 85.6952 7.17912C85.597 7.48911 85.5491 7.81331 85.5536 8.13895V8.22533Z"
            fill="#F38A00"
          />
          <path
            d="M102.495 2.52346C103.208 2.50712 103.916 2.63097 104.582 2.8882C105.171 3.11053 105.707 3.4536 106.16 3.89601C106.583 4.34458 106.906 4.8818 107.104 5.47012C107.348 6.13603 107.466 6.84255 107.453 7.55294V11.2579C107.463 11.9649 107.345 12.6677 107.104 13.3311C106.904 13.9242 106.582 14.4673 106.16 14.9244C105.71 15.3729 105.173 15.7197 104.582 15.9418C103.913 16.1831 103.206 16.3002 102.495 16.2873H101.645C100.92 16.2978 100.2 16.1708 99.5203 15.913V21.0001H96.4321V2.52346H102.495ZM99.5203 5.63329V11.1523C99.5091 11.4189 99.55 11.6852 99.6408 11.9357C99.7316 12.1861 99.8704 12.4157 100.049 12.6112C100.227 12.7988 100.442 12.9462 100.679 13.0438C100.917 13.1414 101.172 13.1869 101.428 13.1775H102.495C102.759 13.19 103.023 13.146 103.269 13.0484C103.516 12.9508 103.739 12.8018 103.925 12.6109C104.111 12.42 104.256 12.1915 104.349 11.9402C104.443 11.689 104.484 11.4205 104.469 11.1523V7.62972C104.48 7.36359 104.435 7.09815 104.339 6.85028C104.244 6.60242 104.098 6.37755 103.912 6.18999C103.725 6.00539 103.503 5.86098 103.259 5.76534C103.016 5.6697 102.756 5.62479 102.495 5.63329H99.5203Z"
            fill="#F38A00"
          />
          <path
            d="M57.839 10.7313V16.3847H54.543V2.41925H57.839V7.97663H59.0384L63.2977 2.49604H67.2831L61.8527 9.2148L67.5192 16.3943H63.5054L59.1328 10.7409L57.839 10.7313Z"
            fill="#F38A00"
          />
          <path
            d="M3.27421 38.7926H0L4.66022 25.7881H7.89425L12.4339 38.7926H9.17983L8.51695 36.6148H3.93708L3.27421 38.7926ZM6.20693 28.9822C6.0998 29.3694 5.97928 29.7842 5.84536 30.2267C5.72484 30.6553 5.51727 31.3605 5.22266 32.3422L4.70039 34.0637H7.73355L7.21129 32.3422C7.0372 31.8168 6.86311 31.2637 6.68902 30.683C6.51493 30.0884 6.35424 29.5215 6.20693 28.9822Z"
            fill="#888888"
          />
          <path
            d="M19.7149 25H22.728V38.7926H20.0564L19.8957 37.8385H19.8354C19.4739 38.2257 19.0453 38.516 18.5499 38.7096C18.0544 38.9032 17.4919 39 16.8625 39C15.5502 39 14.6128 38.5921 14.0503 37.7763C13.5013 36.9605 13.2268 35.5916 13.2268 33.6696C13.2268 31.803 13.5749 30.4479 14.2713 29.6044C14.981 28.761 16.126 28.3393 17.7062 28.3393C18.0945 28.3393 18.4561 28.3669 18.7909 28.4222C19.1391 28.4637 19.4471 28.5259 19.7149 28.6089V25ZM18.128 31.1393C17.3245 31.1393 16.809 31.2983 16.5813 31.6163C16.3537 31.9205 16.2398 32.6049 16.2398 33.6696C16.2398 34.679 16.3403 35.3427 16.5411 35.6607C16.742 35.9649 17.2107 36.117 17.9472 36.117C18.2954 36.117 18.6168 36.0894 18.9114 36.0341C19.2194 35.9788 19.4873 35.8958 19.7149 35.7852V31.4089C19.514 31.3121 19.2797 31.243 19.0119 31.2015C18.7574 31.16 18.4628 31.1393 18.128 31.1393Z"
            fill="#888888"
          />
          <path
            d="M27.971 38.7926H24.958V28.5467H27.7702L27.8706 29.3141H27.9309C28.4264 28.996 28.9017 28.7541 29.3571 28.5881C29.8124 28.4222 30.2476 28.3393 30.6627 28.3393C31.2118 28.3393 31.6805 28.4291 32.0688 28.6089C32.4572 28.7748 32.7652 29.0306 32.9928 29.3763H33.0732C33.5285 29.0583 34.0374 28.8094 34.5998 28.6296C35.1756 28.436 35.7247 28.3393 36.247 28.3393C37.2245 28.3393 37.9477 28.6158 38.4164 29.1689C38.8985 29.722 39.1395 30.5654 39.1395 31.6993V38.7926H36.1264V32.1141C36.1264 31.7684 36.0327 31.5195 35.8452 31.3674C35.6577 31.2153 35.3631 31.1393 34.9614 31.1393C34.7471 31.1393 34.5195 31.16 34.2784 31.2015C34.0374 31.243 33.7963 31.3052 33.5553 31.3881V38.7926H30.5422V32.1141C30.5422 31.7684 30.4485 31.5195 30.261 31.3674C30.0735 31.2153 29.7789 31.1393 29.3771 31.1393C29.1629 31.1393 28.9352 31.16 28.6942 31.2015C28.4531 31.243 28.2121 31.3052 27.971 31.3881V38.7926Z"
            fill="#888888"
          />
          <path
            d="M44.4232 27.4267H41.2494V25H44.4232V27.4267ZM44.3429 38.7926H41.3298V28.5467H44.3429V38.7926Z"
            fill="#888888"
          />
          <path
            d="M49.5922 38.7926H46.5791V28.5467H49.1905L49.311 29.6252H49.3712C49.7998 29.1689 50.2618 28.844 50.7573 28.6504C51.2661 28.443 51.8754 28.3393 52.5852 28.3393C53.7101 28.3393 54.5604 28.6227 55.1363 29.1896C55.7121 29.7565 56 30.5931 56 31.6993V38.7926H52.9869V32.1141C52.9869 31.796 52.8597 31.5541 52.6053 31.3881C52.3642 31.2222 52.0027 31.1393 51.5206 31.1393C51.159 31.1393 50.8108 31.1807 50.476 31.2637C50.1412 31.3467 49.8466 31.4642 49.5922 31.6163V38.7926Z"
            fill="#888888"
          />
        </svg>
      </Logo>
      <StyledNav>
        {Object.keys(pages).map((key, index) => {
          const categories = pages[key];

          return (
            <StyledMenuCategory key={index}>
              {categories.map(category => (
                <CategoryItem key={category.label} category={category}></CategoryItem>
              ))}
            </StyledMenuCategory>
          );
        })}
      </StyledNav>
      <LogoutButton onClick={logOut}>
        <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M3.25 2.66667L4.1665 3.60667L2.4895 5.33333H9.1V6.66667H2.4895L4.1665 8.38667L3.25 9.33333L0 6L3.25 2.66667ZM11.7 1.33333H6.5V0H11.7C12.415 0 13 0.6 13 1.33333V10.6667C13 11.4 12.415 12 11.7 12H6.5V10.6667H11.7V1.33333Z"
            fill="#888888"
          />
        </svg>
        Sair
      </LogoutButton>
    </MenuContainer>
  );
};
