import { ErrorPage } from 'pages/ErrorPage';
import PrivateRoute from '../../../routes/PrivateRoute';
import { GeneralPlansService } from 'services/reports/endpoints/GeneralPlansService';
export const convertMinsToHrsMins = minutes => {
  var h = Math.floor(minutes / 60);
  var m = minutes % 60;
  h = h < 10 ? '0' + h : h;
  m = m < 10 ? '0' + m : m;
  return h + ':' + m;
};
export const frequencyToPeriodicity = freq => {
  switch (freq) {
    case 'daily':
      return 7;
    case 'weekly':
      return 5;
    case 'monthly':
      return 3;
    case 'bimonthly':
      return 10;
    case 'biannual':
      return 12;
    case 'annual':
      return 8;
    case 'none':
      return 9;
    default:
      return 9;
  }
};
export const getFrequencyDescription = plan => {
  if (plan.frequenciaNome === 'Não se Repete') {
    return `Não se Repete`;
  } else {
    return `${plan.frequenciaDescricao} • A cada ${plan.multiplicador} ${plan.frequenciaNome.toLowerCase()}`;
  }
};
export const PrivateGeneralPlansRoute = ({ isDisabled, ...props }) => {
  return isDisabled === false ? <PrivateRoute {...props} /> : <ErrorPage errorCode="403" />;
};
export const PrivateScheduleAdminRoute = ({ isDisabled, ...props }) => {
  return isDisabled === false ? <PrivateRoute {...props} /> : <ErrorPage errorCode="403" />;
};

export const editActivity = async ({ activity, companyId }) => {
  const {
    planId,
    category,
    description,
    activateConformity,
    estimatedTime,
    frequency,
    isMeasurementRequired,
    medicoes,
    repeatEvery,
    priority,
    startTime,
    saturdayExcChecked,
    sundayExcChecked,
    specificDay,
    specificDays,
    holidaysExcChecked,
    startDate,
  } = activity;
  let startDateTime = startDate;
  const hours = startTime.split(':')[0];
  const minutes = startTime.split(':')[1];
  startDateTime.setHours(hours);
  startDateTime.setMinutes(minutes);
  startDateTime = startDateTime.toISOString();
  const estimatedTimeHours = estimatedTime.split(':')[0];
  const estimatedTimeMinutes = estimatedTime.split(':')[1];
  let _estimatedTime = estimatedTimeHours * 60 + parseInt(estimatedTimeMinutes);
  let _specificDays;
  if (specificDays) {
    _specificDays = specificDays.map(day => ({ [day]: true }));
  } else {
    if (frequency === 'daily') {
      let singleSpecificDays = ['segunda', 'terca', 'quarta', 'quinta', 'sexta', 'sabado', 'domingo'];
      if (saturdayExcChecked === true) {
        _specificDays = singleSpecificDays.map(day => {
          if (day !== 'sabado') {
            return { [day]: true };
          } else {
            return { [day]: false };
          }
        });
      } else if (sundayExcChecked === true) {
        _specificDays = singleSpecificDays.map(day => {
          if (day !== 'domingo') {
            return { [day]: true };
          } else {
            return { [day]: false };
          }
        });
      } else {
        _specificDays = singleSpecificDays.map(day => ({ [day]: true }));
      }
    } else {
      _specificDays = [specificDay].map(day => ({ [day]: true }));
    }
  }

  let formattedData = {
    companyId: companyId,
    planoGeral: planId,
    descricao: description,
    categoria: category,
    tempoPrevisto: parseInt(_estimatedTime),
    inicio: startDateTime,
    medicoes: medicoes,
    prioridade: priority,
    periodicidade: frequencyToPeriodicity(frequency),
    multiplicador: repeatEvery ? parseInt(repeatEvery) : 1,
    conformidadeObrigatoriaBaixa: activateConformity,
    medicaoObrigatoriaBaixa: isMeasurementRequired,
    // exc false when checkbox selected
    sabadoExc: saturdayExcChecked === true ? false : true,
    domingoExc: sundayExcChecked === true ? false : true,
    feriadosExc: holidaysExcChecked === true || holidaysExcChecked === undefined ? false : true,
    tipoValidacao: activateConformity ? 1 : null,
  };
  Object.assign(formattedData, ..._specificDays);

  await GeneralPlansService.addGeneralPlanActivity(formattedData)
    .then(() => {
      return true;
    })
    .catch(() => false);
};
