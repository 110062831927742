import * as React from 'react';
import { LkpDropdown } from 'components/Dropdown';
import { LkpDropdownMenuItemProps } from 'components/Dropdown/Menu';
import { toggleAllItems, toggleItem } from './helpers';
import { fetcher } from 'services';
import { useHeaderFilters } from 'context/GlobalFiltersContext';
import { HeaderFiltersType } from 'context/GlobalFiltersContext/initialState';

export interface LkpFilterFixStatusProps {
  onSelectStatus?: (statusParams: string) => void; // occurrencesParams = 'status=123&status=123&status=123'
}

export function LkpFilterFixStatus({ onSelectStatus }: LkpFilterFixStatusProps): React.ReactElement {
  const { headerFiltersContext } = useHeaderFilters();
  const [fixStatusList, setFixStatusList] = React.useState<Array<LkpDropdownMenuItemProps>>([]);
  const [selectedFixStatus, setSelectedFixStatus] = React.useState<Array<LkpDropdownMenuItemProps>>([]);
  const [filters] = React.useState<HeaderFiltersType>(headerFiltersContext?.headerFilters);

  const fetchStatusList = async () => {
    let statusList = [{ id: '__SelecionarTodas', label: 'Selecionar todas', selectAll: true, selected: false }];
    const status = await fetcher
      .get<any[]>(`/v1/correcoes/tipos`, 'lighthouse', {}, { empresaId: filters?.companies?.id || 0 })
      .catch(err => {
        return err;
      });

    if (status) {
      status.forEach(item => {
        statusList = [
          ...statusList,
          {
            id: item.tipoAcaoCorretivaEmpresa,
            label: item.nome,
            selected: false,
            selectAll: false,
          },
        ];
      });

      setFixStatusList(statusList);
    }
  };

  const handleToogleAllOccurrences = (selected = true) => {
    const _newItems = toggleAllItems({ items: fixStatusList, toggle: selected });

    setFixStatusList(_newItems);
    setSelectedFixStatus(_newItems.filter(item => item.selected));
  };

  const handleSelectOccurrence = (itemId: string) => {
    let _newItems: Array<LkpDropdownMenuItemProps> = [];
    let _selectedItems: Array<LkpDropdownMenuItemProps> = [];

    if (fixStatusList) {
      _newItems = toggleItem({ items: fixStatusList, toggleItem: itemId });
      _selectedItems = _newItems.filter(item => item.selected);
    }

    setFixStatusList(_newItems);
    setSelectedFixStatus(_selectedItems);
  };

  React.useEffect(() => {
    if (onSelectStatus && fixStatusList && fixStatusList.length > 0) {
      let _fixStatus = fixStatusList
        .filter(({ id }) => id !== '__SelecionarTodas')
        .filter(({ selected }) => !!selected)
        .map(({ id }) => [id])
        .join('&status=');

      if (_fixStatus) _fixStatus = `status=${_fixStatus}`;
      onSelectStatus(_fixStatus);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fixStatusList]);

  React.useEffect(() => {
    fetchStatusList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <React.Fragment>
      {onSelectStatus && (
        <LkpDropdown
          onChangeItem={handleSelectOccurrence}
          onToggleAll={handleToogleAllOccurrences}
          items={fixStatusList}
        >
          {selectedFixStatus.length <= 0 && `Todos os Status de correção`}
          {selectedFixStatus.length === 1 && `1 status de correção`}
          {selectedFixStatus.length > 1 &&
            `${
              selectedFixStatus.length === fixStatusList.length
                ? selectedFixStatus.length - 1
                : selectedFixStatus.length
            } status de correção`}
        </LkpDropdown>
      )}
    </React.Fragment>
  );
}
