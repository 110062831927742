/* istanbul ignore file */

import styled from 'styled-components';

import { colors, medias } from 'assets/styled/tokens';
import leankeepHatIcon from 'assets/icons/leankeep/leankeep-hat.svg';
import brazilFlagIcon from 'assets/icons/brazil-flag.svg';

export const StyledMenu = styled.div`
  width: 180px;
  min-height: 100vh;
  padding: 1.5rem 1rem;
  background-color: ${colors.white};
  box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.3);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  z-index: 99;
  position: fixed;

  ::-webkit-scrollbar {
    display: none;
  }

  @media screen and (max-height: ${medias.tablet}) {
    justify-content: flex-start;
  }

  @media screen and (max-width: ${medias.desktopSm}) {
    box-shadow: 2px 0px 7px 2px rgba(0, 0, 0, 0.3);
    display: ${props => !props.isMobileMenuOpen && 'none'};
    max-height: 100vh;
    max-width: 310px;
    overflow-y: auto;
    padding-bottom: 0px;
    position: fixed;
    z-index: 99;
  }
`;

export const StyledLogo = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  margin-bottom: 24px;

  img {
    display: inline-block;
    transition: opacity 1s ease-in-out;
    &::webkit-transition {
      opacity: 1s ease-in-out;
    }
  }
  max-width: 2.75rem;

  svg {
    cursor: pointer;
  }

  @media screen and (max-width: ${medias.mobile}) {
    img {
      max-width: 2.75rem;
    }
  }

  @media screen and (max-width: ${medias.tablet}) {
    img {
      max-width: 2.75rem;
    }
  }

  @media screen and (min-width: ${medias.desktopSm}) {
    margin: 24px auto 10px;
    z-index: 99;
  }

  @media screen and (min-width: ${medias.desktopMd}) {
    margin: 24px auto 10px;

    svg {
      display: none;
    }
  }

  @media screen and (min-width: ${medias.desktopXl}) {
    margin: 24px auto 10px;
  }
`;

export const StyledNav = styled.nav`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: stretch;

  flex: 1;
  margin: 0 -1rem;

  @media screen and (max-width: ${medias.tablet}) {
    margin: 4em -1rem 2em -1rem;
  }

  @media screen and (min-width: ${medias.desktopSm}) {
    justify-content: center;
    z-index: 99;
  }

  @media screen and (min-width: ${medias.desktopMd}) {
    justify-content: center;
  }

  @media screen and (min-width: ${medias.desktopXl}) {
    justify-content: center;
  }
`;

export const StyledMenuCategory = styled.ul`
  & + ul {
    margin-top: 1rem;
    padding-top: 1rem;
    border-top: 1px solid ${colors.whiteGray};
  }
`;

export const StyledShowUserMenu = styled.div`
  display: block;
  margin: 0 -1rem;
  background: ${colors.pampas};

  @media screen and (min-width: ${medias.desktopSm}) {
    display: none;
  }
`;

export const StyledMenuActions = styled.div`
  display: flex;
  flex-flow: row wrap;
  align-items: stretch;
  justify-content: flex-end;

  a,
  button {
    font-size: 0.75rem;
    color: ${colors.gray};
    width: 50%;
  }

  button {
    height: 100%;
  }

  & > a {
    position: relative;
    display: inline-block;
    padding: 12px 0.5rem 12px 0.5rem;
    padding-right: 2rem;
    border: 1px solid #ddd;
    background-color: transparent;
    text-align: center;
    height: 50px;

    &:after {
      position: absolute;
      display: block;
      width: 1rem;
      height: 1rem;
      top: 32%;
    }

    &:first-child {
      &:after {
        background: url(${brazilFlagIcon}) no-repeat center/contain;
      }
    }

    &:last-child {
      &:after {
        right: 1rem;
        background: url(${leankeepHatIcon}) no-repeat center/contain;
      }
    }
  }
`;

export const StyledFooterMenu = styled.div`
  margin-bottom: 24px;
  display: none;
  img {
    max-height: 30px;
    margin: auto;
  }
  @media screen and (min-width: ${medias.desktopSm}) {
    display: block;
  }
`;
