/* istanbul ignore file */

import styled from 'styled-components';
import { fonts, colors, devices, medias } from 'assets/styled/tokens';
import { Checkbox } from 'components/Form/subcomponents/Checkbox';
import { Tooltip } from 'components/Tooltip';
import { FormGroup } from 'components/Form/subcomponents/FormGroup';

export const StyledWelcome = styled.div`
  width: 100%;
  margin-bottom: 32px;

  h3 {
    font-weight: 700;
    font-size: 24px;
    color: ${colors.carbon};
    font-family: ${fonts.family.title};
    line-height: 20px;
    margin-bottom: 8px;
  }

  span {
    font-size: 14px;
    line-height: 20px;
  }
`;

export const StyledForm = styled.form`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  max-width: 410px;
  width: 86vw;
  margin: 120px auto;

  img {
    max-width: 201px;
    margin: 0 auto 54px 0;
  }

  button {
    width: 100%;
    font-size: 13px;
  }
  @media ${devices.tabletLandscape} {
    height: auto;
    width: 100%;

    margin: 57px auto auto 210px;
  }
  @media ${devices.screenWide} {
    margin: 137px auto auto 316px;
  }
`;
export const StyledFormGroup = styled(FormGroup)`
  margin-bottom: 16px;
`;
export const StyledFormLogin = styled.div`
  display: block;
  width: 100%;
  min-width: 295px;

  > div {
    margin-bottom: 16px;
  }

  input {
    margin-bottom: 3px;
  }
`;

export const StyledStayConnectedCheckbox = styled(Checkbox)`
  width: auto;
  cursor: pointer;
  margin-right: 5px;
`;
export const StyledTooltip = styled(Tooltip)`
  margin-right: auto;
`;
export const StyledFormFooter = styled.div`
  display: flex;
  margin-top: 32px;
  #loginButton {
    max-width: 127px;
    width: 20vw;
  }
  button {
    margin-left: auto;
    width: 127px;
    height: 40px;
  }
`;

export const StyledForgetPasswordLink = styled.div`
  display: flex;
  justify-content: flex-end;
  position: absolute;
  right: 0;
  top: -2px;

  a {
    font-weight: 500;
    font-size: 13px;
    line-height: 15px;
    text-decoration-line: underline;
    color: ${colors.gray};
  }
`;

export const StyledError = styled.div`
  display: block;
  margin-top: -10px;
  font-size: 12px;

  p {
    font-family: ${fonts.family.text};
    color: ${colors.cinnabar};
  }
`;
export const StyledModalBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  padding-bottom: 21px;

  img {
    max-width: 80px;
    margin-bottom: 32px;
  }

  h6 {
    font-family: ${fonts.family.title};
    color: ${colors.bittersweet};
    font-weight: 700;
    font-size: 21px;
    line-height: 25.2px;
    margin-bottom: 32px;
    text-align: center;
  }

  p,
  span {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 18.75px;
    margin-top: 40px;
    text-align: center;
    color: ${colors.gray};

    a {
      color: ${colors.primary};
      font-weight: bold;
    }
  }

  button:first-child {
    margin-right: 16px;
    background-color: ${colors.white};
    color: ${colors.carbon};
    border: 1px solid ${colors.whiteGray};
  }

  @media screen and (min-width: ${medias.tablet}) {
    padding-right: 35px;
    padding-left: 35px;
  }
`;
export const StyledModalButton = styled.div`
  display: flex;
`;

export const StyledP = styled.div`
  margin-bottom: 40px;
  margin-top: -40px;
`;
