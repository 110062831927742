import styled from 'styled-components';

import { inputStyleFactory } from 'components/Form/shared/inputStyleFactory';

import { DefaultSelect } from 'assets/styled/sheets/components/Forms';
import { colors } from 'assets/styled/tokens';

import plusIcon from './assets/plus.svg';
import { ModalGeneric } from 'components/ModalGeneric';

export const StyledSelectWrapper = styled.div`
  position: relative;
  & > svg {
    position: absolute;
    right: 25px;
    top: 50%;
    transform: translate(0, -50%);
    cursor: pointer;
  }
`;

export const StyledSelect = styled.input`
  ${DefaultSelect};

  ${props => inputStyleFactory(props.size)};

  background-image: url(${plusIcon});
  background-size: 8px;
  position: relative;
  display: inline-block;
  width: 100%;
  cursor: pointer;

  cursor: ${props => props.disabled && 'not-allowed'};

  &:active {
    pointer-events: ${props => props.disabled && 'none'};
  }

  &::placeholder {
    color: ${props => props.disabled && colors.mediumGray};
  }

  &:focus {
    border-color: ${props => props.disabled && colors.whiteGray};
  }
`;

export const StyledModal = styled(ModalGeneric)`
  footer {
    padding: 1rem;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.25);
    display: flex;
    justify-content: space-between;

    p {
      color: ${colors.tundora};
      font-weight: 500;
      font-size: 0.75rem;
      line-height: 1rem;

      button {
        color: ${colors.mangoTango};
      }
    }
  }
`;

export const StyledSearchBar = styled.div`
  position: relative;
  margin: 0.5rem 0;
  padding: 0 1rem;
`;

export const StyledContent = styled.ul`
  width: 100%;
  height: 100%;
  min-height: 342px;
  overflow: auto;
  z-index: 1;

  &::-webkit-scrollbar {
    width: 1.25rem;

    &:before {
      content: '';
      margin: 1.25rem;
    }
  }

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    width: 0.9375rem;
  }

  &::-webkit-scrollbar-track {
    margin: 0.1875rem;
  }

  &::-webkit-scrollbar-thumb {
    background: ${colors.silver3};
    border: 0.3125rem solid ${colors.alabaster};
    border-radius: 6.25rem;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: ${colors.silver3};
  }
`;

export const StyledDropdownItem = styled.li`
  padding: 0.0625rem 1rem;

  &:hover {
    background: ${colors.desertStorm};
  }

  label {
    cursor: pointer;
  }

  p {
    font-size: 0.75rem;
    line-height: 1rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 11.25rem;
  }

  &:nth-child(2) {
    p {
      font-weight: 500;
      color: ${colors.tundora};
    }
  }
`;

export const StyledNoResultsFound = styled.div`
  max-width: 13.5rem;
  margin: 0 auto;
  text-align: center;
  padding: 3.4375rem 0;

  svg {
    margin-bottom: 1.5rem;
  }

  h6 {
    font-weight: 500;
    font-size: 1rem;
    line-height: 1rem;
    color: ${colors.tundora};
    margin-bottom: 0.25rem;
  }

  p {
    font-size: 0.75rem;
    line-height: 1rem;
    color: ${colors.gray};
  }
`;

export const StyledPagination = styled.div`
  width: 100%;
  margin-bottom: 24px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: 6px;

  button {
    border: 1px solid ${colors.whiteGray};
    border-radius: 3px;
  }

  ul {
    display: inline-flex;

    button {
      border: ${props => (props.currenPage ? colors.whiteGray : 'transparent')};
      background: ${props => (props.currenPage ? colors.pampas : 'transparent')};
      color: ${props => (props.currenPage ? colors.gray : colors.mediumGray)};
    }
  }
`;
