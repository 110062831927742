import React, { useEffect, useState } from 'react';
import iconErrorAll from 'assets/icons/icon_erro.svg';
import iconError419 from 'assets/icons/icon_error.svg';
import { Button } from 'components/Button';
import { useParams, useLocation } from 'react-router-dom';

import {
  Container,
  StyledImg,
  StyledError,
  StyledErrorMessage,
  StyledErrorMessage419,
  StyledErrorMessageSub,
  StyledLink,
  StyledRefreshPageButton,
  StyledButtonRow,
  StyledLinkIn,
} from './styled';

const ErrorPage = ({ errorCode }) => {
  const { code } = useParams();
  const location = useLocation();

  const [pathlink, setPathlink] = useState('/');
  const [codeError, setCodeError] = useState({
    code: 404,
    message: 'Opa... não encontramos a página',
    messageSub: 'A página que você procura foi removida, mudou de endereço ou está temporariamente indisponível.',
  });

  const handleErrorCode = () => {
    let _code = errorCode || code;
    switch (_code) {
      case '400':
        return {
          code: 400,
          message: 'Opa! não foi possível exibir o que deveria estar aqui',
          messageSub: 'Verifique sua conexão com a internet e tente novamente.',
        };
      case '401':
        return {
          code: 401,
          message: 'Parece que você não tem permissão para acessar',
          messageSub: 'Verifique os seus dados e tente fazer o login novamente.',
        };
      case '403':
        return {
          code: 403,
          message: 'Opa, parece que você não tem autorização para estar aqui',
          messageSub: 'Volte para a página inicial e continue usando o Leankeep.',
        };
      case '408':
        return {
          code: 408,
          message: 'O servidor demorou demais para nos responder',
          messageSub: 'Recarregue a página e tente novamente.',
        };
      case '410':
        return {
          code: 410,
          message: 'Parece que o que você procura não está mais aqui.',
          messageSub: '',
        };
      case '419':
        setTimeout(() => {
          window.location.replace('/logout');
        }, 5000);
        return {
          code: 419,
          message: 'Sua sessão foi encerrada',
          messageSub: 'Outra pessoa encerrou sua sessão para acessar com este mesmo usuário.',
        };
      case '500':
        return {
          code: 500,
          message: 'As coisas estão um pouco instáveis aqui',
          messageSub:
            'Mas não se preocupe, nosso time foi automaticamente notificado e está trabalhando para corrigir esse erro. Tente mais tarde.',
        };
      default:
        return {
          code: 404,
          message: 'Opa... não encontramos a página',
          messageSub: 'A página que você procura foi removida, mudou de endereço ou está temporariamente indisponível.',
        };
    }
  };

  useEffect(() => {
    if (location.search) {
      const urlLink = location.search.split('?')[1];
      const regex = new RegExp('^(http|https)://');

      if (regex.test(urlLink)) {
        setPathlink(urlLink);
      }
    }
    setCodeError(handleErrorCode(errorCode));
    // eslint-disable-next-line
  }, [code, location.search]);

  return (
    <Container>
      {codeError.code === 419 ? (
        <>
          <StyledImg src={iconError419}></StyledImg>
          <StyledErrorMessage419>{codeError.message}</StyledErrorMessage419>
        </>
      ) : (
        <>
          <StyledImg src={iconErrorAll}></StyledImg>
          <StyledError>Erro {codeError.code}</StyledError>
          <StyledErrorMessage>{codeError.message}</StyledErrorMessage>
        </>
      )}
      <StyledErrorMessageSub>{codeError.messageSub}</StyledErrorMessageSub>
      <StyledButtonRow>
        {codeError.code === 400 && (
          <StyledRefreshPageButton onClick={() => window.location.reload()}>Recarregar página</StyledRefreshPageButton>
        )}
        {pathlink !== '/' ? (
          <Button>
            <StyledLink href={pathlink}>Voltar</StyledLink>
          </Button>
        ) : (
          <Button>
            {codeError.code === 419 ? (
              <StyledLinkIn to="/login">Entendi</StyledLinkIn>
            ) : (
              <StyledLinkIn to="/">Voltar à página inicial</StyledLinkIn>
            )}
          </Button>
        )}
      </StyledButtonRow>
    </Container>
  );
};

export { ErrorPage };
