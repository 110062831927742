import styled from 'styled-components';

import { colors } from 'assets/styled/tokens';

export const StyledChartContainer = styled.div`
  display: block;
  background-color: ${colors.pampas};
`;

export const StyledHeader = styled.header`
  grid-area: header;
  width: 100%;
  border: 0 solid ${colors.whiteGray};
  margin-bottom: 20px;

  border-bottom-width: 1px;

  @media screen and (min-width: 768px) {
    justify-content: center;
  }
`;

export const StyledContainerHeader = styled.div`
  display: flex;
  padding: 0 20px;

  div {
    button {
      &:first-child {
        transition: all 0.2s;
        &:hover {
          color: ${colors.white};
          background: ${colors.bittersweet};
          border-color: ${colors.bittersweet};
        }
        svg {
          margin-right: 8px;
        }
      }
    }
  }
  svg {
    overflow: visible;
    cursor: pointer;
  }
`;
export const StyledContentWrapper = styled.div`
  width: 100%;
  display: ${props => props.display || 'flex'};
  flex-direction: ${props => props.flexDirection || 'row'};
  justify-content: ${props => props.justifyContent};
  align-items: ${props => props.alignItems};
  grid-column-start: ${props => props.gridColumnStart};
  gap: ${props => props.gap};
`;

export const StyledTitle = styled.h3`
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  font-family: Blinker, sans-serif;
  font-size: 18px;
  color: ${colors.primary};

  margin: 18px 0 0 16px;
`;

export const TabItem = styled.div`
  font-size: 18px;
  font-family: Blinker, sans-serif;
  font-weight: 600;
  line-height: 21px;
  cursor: pointer;
  border: 0;
  outline: 0;
  color: ${colors.mediumGray};
  margin-right: 20px;
  display: inline-flex;
  flex-direction: column;
  align-items: space-between;

  ${({ active }) =>
    active &&
    `
    color: ${colors.carbon};
    `}

  :hover {
    color: #666666;
  }
`;

export const TabItemBorder = styled.div`
  margin-top: 18px;
  background: ${colors.pampas};
  border-radius: 4px 4px 0 0;
  position: relative;
  width: 100%;
  height: 2px;
  ${({ active }) =>
    active &&
    `
    background: ${colors.carbon};
    `}
  &:after {
    ${({ active }) =>
      active &&
      `
    content: '';
    display: block;
    position: absolute;
    border-left: 0.35rem solid transparent;
    border-right: 0.35rem solid transparent;
    border-top: 0.35rem solid ${colors.carbon};
    left: 50%;
    transform: translate(-50%);

    `}
  }
`;

export const StyledMain = styled.div``;

export const StyledContainerMain = styled.div``;
