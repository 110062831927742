import styled, { css } from 'styled-components';

import { colors, medias } from 'assets/styled/tokens';
import chevronRight from 'assets/icons/chevron-silver-right.svg';
import { StyledSubCategory } from '../Subcategory/styled';

export const StyledCategoryItem = styled.li`
  position: relative;
  cursor: ${props => (props.disabled ? 'none' : 'pointer')};
  pointer-events: ${props => props.disabled && 'none'};
  margin-top: 10px;
  @media screen and (max-width: ${medias.desktopSm}) {
    padding: 5px;
  }

  &:first-child {
    margin-top: 0;
  }

  &:hover {
    background-color: ${props => (props.disabled ? 'transparent' : colors.pampas)};
  }

  ${props =>
    props.isOpen
      ? css`
          ${StyledSubCategory} {
            opacity: 1;
            visibility: visible;
            display: block;
          }
        `
      : css`
          ${StyledSubCategory} {
            opacity: 0;
            visibility: hidden;
          }
        `};

  a,
  span {
    padding: 0 20px;
    display: block;
    font-size: 0.813rem;

    color: ${colors.tundora};

    color: ${props => props.disabled && colors.silver};
    cursor: ${props => props.disabled && 'disabled'} !important;

    &:hover {
      text-decoration: none;
    }

    @media screen and (min-width: ${medias.desktopSm}) {
      font-weight: 500;
    }
  }

  a {
    color: ${props => props.disabled && colors.silver};
    pointer-events: ${props => props.disabled && 'none'};

    &.active {
      color: ${colors.primary};
      display: block;

      &:after {
        position: absolute;
        top: -6px;
        left: -183px;
        width: 3px;
        height: 100%;
        border-radius: 0 3px 3px 0;
        background-color: ${colors.primary};
      }
    }
  }

  ${props =>
    props.hasChildren &&
    css`
      &:after {
        position: absolute;
        right: 1rem;
        top: 6px;
        display: block;
        width: 6px;
        height: 10px;
        background: url(${chevronRight}) no-repeat center/contain;
        z-index: 1;

        @media screen and (max-width: ${medias.tablet}) {
          transform: ${props => props.isOpen && 'rotate(90deg)'};
        }
      }
    `}
`;
