import { Button } from 'components';
import styled from 'styled-components';
export const Header = styled.div`
  display: flex;
  flex-direction: row;
  min-width: 100%;
  align-items: center;
  margin-bottom: 34px;
`;

export const HeaderName = styled.h1`
  font-size: 32px;
  font-family: Blinker, sans-serif;
  font-weight: 700;
`;

export const ConfigPageWrapper = styled.div`
  background-color: #fff;
  width: 100%;
  height: 710px;
  border-radius: 8px;
  margin-bottom: 25px;
  box-shadow: 0px 1px 8px 0px #0000001a;
`;
export const CategoryTitle = styled.h1`
  font-family: Roboto;
  font-size: 16px;
  font-weight: 700;
  line-height: 16px;
  text-align: left;
`;
export const CategoryInfo = styled.h2`
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
  color: #888888;
`;
export const CategoryHeader = styled.div`
  padding: 15px 15px 10px 15px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #cccccc;
`;
export const CategoryHeaderTitle = styled.h3`
  font-family: Blinker;
  font-size: 24px;
  font-weight: 700;
  line-height: 28px;
  text-align: left;
  color: #f38a00;
`;
export const SaveButton = styled(Button)`
  background: #f38a00;
  color: #ffffff;
  font-family: Roboto;
  font-size: 12px;
  font-weight: 500;
  line-height: 14px;
  text-align: center;
`;
export const CategorySubHeader = styled.div`
  padding: 15px 15px 10px 15px;
  width: 100%;
  height: 60px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
export const ConfigOptionsContainer = styled.div`
  width: 100%;
  height: 206px;
  border: 1px solid #e5e5e5;
  background-color: #fafafa;
`;

export const CategoryItemContainer = styled.div`
  padding: 15px 15px 0px 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
export const CategoryItemTitle = styled.h4`
  font-family: Roboto;
  font-size: 14px;
  font-weight: 700;
  line-height: 16px;
  text-align: left;
  color: #000000;
`;
