import { AxiosReportLas } from '..';
import { AxiosGlobalFilters } from '../config';

const GeneralPlansService = {
  createPlan: ({ name, companyId, system, department }) => {
    return AxiosGlobalFilters({
      method: 'post',
      url: `/v1/planosgerais`,
      data: {
        nome: name,
        empresaId: companyId,
        sistemaEmpresa: system,
        setor: department,
      },
      headers: {
        EmpresaId: companyId,
      },
    });
  },
  updatePlan: ({ name, planId, companyId, system, department }) => {
    return AxiosGlobalFilters({
      method: 'post',
      url: `/v1/planosgerais`,
      data: {
        nome: name,
        empresaId: companyId,
        planoGeral: planId,
        sistemaEmpresa: system,
        setor: department,
      },
      headers: {
        EmpresaId: companyId,
      },
    });
  },
  addGeneralPlanActivity: ({
    atividadeGeral,
    planoGeral,
    companyId,
    categoria,
    conformidadeObrigatoriaBaixa,
    descricao,
    domingo,
    domingoExc,
    feriadosExc,
    inicio,
    medicaoObrigatoriaBaixa,
    medicoes,
    multiplicador,
    periodicidade,
    prioridade,
    quarta,
    quinta,
    sabado,
    sabadoExc,
    segunda,
    sexta,
    tempoPrevisto,
    terca,
    tipoValidacao,
  }) => {
    return AxiosGlobalFilters({
      method: 'post',
      url: `/v1/planosgerais/${planoGeral}/AtividadesGerais`,
      data: {
        ...(atividadeGeral && { atividadeGeral: atividadeGeral }),
        descricao,
        categoria,
        inicio,
        periodicidade,
        tipoValidacao,
        multiplicador,
        tempoPrevisto,
        medicaoObrigatoriaBaixa,
        segunda,
        terca,
        quarta,
        quinta,
        sexta,
        prioridade,
        sabado,
        domingo,
        sabadoExc,
        domingoExc,
        feriadosExc,
        conformidadeObrigatoriaBaixa,
        medicoes,
      },
      headers: {
        'content-type': 'application/json',
        empresaId: companyId,
        // unidadeId: unitId
      },
    });
  },
  getPlan: ({ planId, companyId }) => {
    if (companyId) {
      return AxiosGlobalFilters({
        method: 'get',
        url: `/v1/planosgerais/${planId}`,
        headers: {
          EmpresaId: companyId,
        },
      });
    }
  },
  getPlanStatus: ({ planId, companyId }) => {
    return AxiosGlobalFilters({
      method: 'get',
      url: `/v1/planosgerais/${planId}`,
      headers: {
        EmpresaId: companyId,
      },
    })
      .then(result => {
        return result.data.statusPlanoGeral;
      })
      .catch(() => {
        return new Promise(resolve => {
          resolve(undefined);
        });
      });
  },

  updateSitesProvider: ({ planId, companyId, applicationId, provider }) => {
    return AxiosGlobalFilters({
      method: 'post',
      url: `/v1/planosgerais/${planId}/Sites`,
      data: [
        {
          site: applicationId,
          fornecedor: provider,
        },
      ],
      headers: {
        'content-type': 'application/json',
        EmpresaId: companyId,
      },
    });
  },
  updateSites: ({ planId, companyId, applications }) => {
    return AxiosGlobalFilters({
      method: 'post',
      url: `/v1/planosgerais/${planId}/Sites`,
      data: applications,
      headers: {
        'content-type': 'application/json',
        EmpresaId: companyId,
      },
    });
  },
  deleteSite: ({ planId, companyId, applicationId }) => {
    return AxiosGlobalFilters({
      method: 'delete',
      url: `/v1/planosgerais/${planId}/Sites/${applicationId}`,
      headers: {
        EmpresaId: companyId,
      },
    });
  },
  getSites: ({ planId, companyId, pageSize, pageIndex }) => {
    if (companyId) {
      return AxiosGlobalFilters({
        method: 'get',
        url: `/v1/planosgerais/${planId}/Sites`,
        params: {
          PageSize: pageSize,
          PageIndex: pageIndex,
        },
        headers: {
          EmpresaId: companyId,
        },
      });
    }
  },
  getSitesCount: ({ planId, companyId }) => {
    if (companyId) {
      return AxiosGlobalFilters({
        method: 'get',
        url: `/v1/planosgerais/${planId}/Sites/count`,
        headers: {
          EmpresaId: companyId,
        },
      });
    }
  },
  validateSites: ({ planId, companyId }) => {
    if (companyId) {
      return AxiosGlobalFilters({
        method: 'get',
        url: `/v1/planosgerais/${planId}/Sites/Validate`,
        headers: {
          EmpresaId: companyId,
        },
      });
    }
  },
  getApplications: ({ planId, companyId }) => {
    return AxiosGlobalFilters({
      method: 'get',
      url: `/v1/planosgerais/${planId}/Aplicacao`,
      headers: {
        EmpresaId: companyId,
      },
    });
  },
  getEquipmentTypes: ({ companyId }) => {
    if (companyId) {
      return AxiosGlobalFilters({
        method: 'get',
        url: `/v1/tiposequipamentos`,
        headers: {
          EmpresaId: companyId,
        },
      });
    }
  },
  getApplicationType: ({ companyId, planId }) => {
    if (companyId && planId) {
      return AxiosGlobalFilters({
        method: 'get',
        url: `/v1/planosgerais/${planId}/Aplicacao`,
        headers: {
          EmpresaId: companyId,
        },
      });
    }
  },
  updateApplicationType: ({ companyId, planId, areaType, equipmentType }) => {
    if (companyId) {
      return AxiosGlobalFilters({
        method: 'POST',
        url: `/v1/planosgerais/${planId}/Aplicacao`,
        data: {
          isSite: !areaType && !equipmentType ? true : false,
          linhaProdutoEmpresa: equipmentType ? equipmentType : null,
          tipoArea: areaType ? areaType : null,
        },
        headers: {
          EmpresaId: companyId,
        },
      });
    }
  },
  getPlanActivity: ({ planId, companyId, activityId }) => {
    if (companyId) {
      return AxiosGlobalFilters({
        method: 'get',
        url: `/v1/planosgerais/${planId}/AtividadesGerais/${activityId}`,

        headers: {
          EmpresaId: companyId,
        },
      });
    }
  },
  deletePlanActivity: ({ planId, companyId, activityId }) => {
    if (companyId) {
      return AxiosGlobalFilters({
        method: 'DELETE',
        url: `/v1/planosgerais/${planId}/AtividadesGerais/${activityId}`,

        headers: {
          EmpresaId: companyId,
        },
      });
    }
  },
  schedulePlan: ({ planId }) => {
    return AxiosReportLas({
      method: 'post',
      url: `/v1/PlanoGeral/Agendamento`,
      params: {
        planoGeralId: planId,
      },
    });
  },
  deactivatePlan: ({ planId, companyId }) => {
    return AxiosReportLas({
      method: 'post',
      url: `/v1/PlanoGeral/InativarPlano`,
      params: {
        planoGeralId: planId,
      },
      headers: {
        EmpresaId: companyId,
      },
    });
  },
  deletePlan: ({ planId, companyId }) => {
    return AxiosReportLas({
      method: 'post',
      url: `/v1/PlanoGeral/ExcluirPlano`,
      params: {
        planoGeralId: planId,
      },
      headers: {
        EmpresaId: companyId,
      },
    });
  },
};

export { GeneralPlansService };
