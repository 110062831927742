import styled from 'styled-components';

export const CarouselContainer = styled.div`
  width: 100%;
  height: 100%;
  //border: 1px solid #aaaaaa;
  overflow: hidden;
  //border-radius: 4px;
`;

export const SlideItem = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  :hover {
    cursor: pointer;
  }
`;

export const ClickContainer = styled.div`
  height: 100%;
`;
