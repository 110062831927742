import { AxiosReports } from '../config';

const AuditService = {
  generateExcel: ({
    companyId,
    unit,
    unitGroup,
    unitSubgroup,
    showPicture,
    showAuditOccurrencePicture,
    showSignature,
    showCompare,
    showAuditOccurrence,
    expectedDateStart,
    expectedDateEnd,
    realizedPeriodStart,
    realizedPeriodEnd,
    scheduledPeriodStart,
    scheduledPeriodEnd,
    areas = [],
    systems = [],
    responsibles = [],
    audits = [],
    status = [],
    company,
    unitName,
  }) => {
    return AxiosReports({
      method: 'post',
      responseType: 'blob',
      url: `audit/excel/${companyId}`,
      data: {
        companyId,
        unit,
        unitGroup,
        unitSubgroup,
        showPicture,
        showAuditOccurrencePicture,
        showSignature,
        showCompare,
        showAuditOccurrence,
        expectedDateStart,
        expectedDateEnd,
        realizedPeriodStart,
        realizedPeriodEnd,
        scheduledPeriodStart,
        scheduledPeriodEnd,
        areas,
        systems,
        responsibles,
        audits,
        status,
        company,
        unitName,
      },
    });
  },

  generatePDF: ({
    companyId,
    unit,
    unitGroup,
    unitSubgroup,
    showPicture,
    showAuditOccurrencePicture,
    showSignature,
    showCompare,
    showAuditOccurrence,
    expectedDateStart,
    expectedDateEnd,
    realizedPeriodStart,
    realizedPeriodEnd,
    scheduledPeriodStart,
    scheduledPeriodEnd,
    areas = [],
    systems = [],
    responsibles = [],
    audits = [],
    status = [],
    company,
  }) => {
    return AxiosReports({
      method: 'post',
      responseType: 'blob',
      url: `audit/PDF/${companyId}`,
      data: {
        companyId,
        unit,
        unitGroup,
        unitSubgroup,
        showPicture,
        showAuditOccurrencePicture,
        showSignature,
        showCompare,
        showAuditOccurrence,
        expectedDateStart,
        expectedDateEnd,
        realizedPeriodStart,
        realizedPeriodEnd,
        scheduledPeriodStart,
        scheduledPeriodEnd,
        areas,
        systems,
        responsibles,
        audits,
        status,
        company,
      },
    });
  },
};

export { AuditService };
