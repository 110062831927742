import React from 'react';

//import { Menu } from 'components/Menu';
import { MobileNavbar } from 'components/MobileNavbar';

import { StyledReportPage } from './styled';
import { NewMenu } from 'components/Menu/NewMenu';

function ReportPage(props) {
  return (
    <StyledReportPage>
      <MobileNavbar />
      {/* <Menu /> */}
      <NewMenu setIsSubMenuOpen={props.setIsSubMenuOpen} />
      {props.children}
    </StyledReportPage>
  );
}

export default ReportPage;
