/* istanbul ignore file */

import styled from 'styled-components';
import { medias } from 'assets/styled/tokens';

export const DashWrapper = styled.div<{ isLoading?: boolean }>`
  opacity: ${({ isLoading = false }) => (isLoading ? '0.5' : '1')};
  pointer-events: ${({ isLoading = false }) => (isLoading ? 'none' : 'all')};
  background: #f5f3f1;
`;

export const DashGrid = styled.div`
  display: grid;
  align-content: center;
  margin-right: -12px;
  margin-left: -12px;
`;

export const DashHalfGrid = styled(DashGrid)`
  grid-template-columns: 100%;

  @media screen and (min-width: ${medias.desktopSm}) {
    grid-template-columns: 50% 50%;
  }
`;

export const DashFullGrid = styled(DashGrid)`
  grid-template-columns: 100%;
`;

export const DashColumn = styled.div`
  padding: 12px;
`;

export const DashHeader = styled.header`
  display: flex;
  align-items: center;
  margin-bottom: 32px;
  min-height: 32px;

  h1 {
    margin-right: 32px;
  }
`;
