import React, { forwardRef, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import CleanInputIcon from 'components/Form/shared/CleanInputIcon';
import Conditional from 'components/Conditional';

import { Input } from '../Input';

import MagnifyingIcon from 'assets/icons/magnifying.svg';

import {
  StyledPaginatedSelectWrapper,
  StyledPaginatedSelect,
  StyledSearchBar,
  StyledDropdownContent,
  StyledDropdownContentWrapper,
  StyledDropdownItem,
  StyledError,
  StyledSpinner,
} from './styled';
import { AxiosGlobalFilters } from 'services/main';
import PaginationRange from 'components/Tables/PaginationRange';
import { debounce } from 'utils/helpers/debounce';
import { colors } from 'assets/styled/tokens';

const PaginatedSelect = forwardRef((props, ref) => {
  const {
    size,
    placeholder,
    onChange,
    isActive = true,
    onSelect = () => {},
    error,
    errormessage,
    value,
    headers = {},
    params = {},
    adapter = data => {
      return data;
    },
    url,
    isRequired = false,
    isSearchDisabled = false,
    className = '',
    disabled,
    defaultValue = '',
    defaultValueLabel = '',
    'data-cy': dataCy,
  } = props;

  const [isLoading, setIsLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [filterWord, setFilterWord] = useState('');
  const [options, setOptions] = useState([]);
  const [pageCount, setPageCount] = React.useState(0);
  const [activeSearchQuery, setActiveSearchQuery] = React.useState('');
  const pageSize = 6;
  const [selectedValue, setSelectedValue] = useState(defaultValue);
  const [selectedLabel, setSelectedLabel] = useState('');
  const selectRef = useRef(ref);
  const fetchIdRef = React.useRef(0);
  const [activePageIndex, setActivePageIndex] = React.useState(0);
  const handlePageChange = page => {
    setActivePageIndex(page);
  };
  const activeParams = useMemo(() => {
    return {
      ...params,
      ...(activeSearchQuery && { Nome: activeSearchQuery }),
      PageSize: pageSize,
      PageIndex: activePageIndex + 1,
    };
  }, [params, pageSize, activePageIndex, activeSearchQuery]);

  const handleValueChange = useCallback(
    valueToFind => {
      if (valueToFind) {
        const current = options.find(option => option.value === valueToFind);

        if (current) {
          props.onChange(current.value);
          setSelectedValue(current.value);
          setSelectedLabel(current.label);
        } else {
          props.onChange(valueToFind);
          setSelectedValue(valueToFind);
          setSelectedLabel(defaultValueLabel);
        }
      }
    },
    [defaultValueLabel, options, props],
  );

  const fetchData = React.useCallback(async () => {
    // Give this fetch an ID
    setIsLoading(true);
    const fetchId = ++fetchIdRef.current;

    // Only update the data if this is the latest fetch
    if (fetchId === fetchIdRef.current) {
      await Promise.all([
        AxiosGlobalFilters({
          method: 'get',
          url: url,
          headers: headers,
          params: activeParams,
        }),
        AxiosGlobalFilters({
          method: 'get',
          url: `${url}/count`,
          headers: headers,
          params: activeParams,
        }),
      ])
        .then(res => {
          const data = res[0].data;
          const adaptedData = adapter(data);
          setOptions(adaptedData.sort());
          setPageCount(Math.ceil(res[1].data / pageSize));
          setIsLoading(false);
        })
        .catch(error => {
          console.error({ error });
          // handleError()
        });
    }
  }, [adapter, pageSize, headers, activeParams, url]);

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      fetchData();
    }
    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line
  }, [activePageIndex, activeSearchQuery, pageSize]);

  useEffect(() => {
    handleValueChange(defaultValue);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    handleValueChange(value);
  }, [handleValueChange, value]);

  const updateSearch = debounce(value => {
    setActiveSearchQuery(value);
  }, 1000);

  const handleChange = option => {
    setSelectedValue(option.value);
    setSelectedLabel(option.label);
    onChange && onChange(option.value);
  };

  const openDropDownContent = () => {
    if (options.length === 1 && selectedLabel) {
      return;
    }

    setIsOpen(() => !isOpen);
  };

  const filterOptionsByWord = option => {
    if (!filterWord) {
      return option;
    }

    const wordMatches = `${option.label}`.toLowerCase().includes(filterWord.toLowerCase());

    if (wordMatches) {
      return option;
    }

    return null;
  };

  const handleClickOutside = useCallback(
    e => {
      if (selectRef.current.contains(e.target)) {
        // inside click
      } else {
        // outside click
        setIsOpen(false);
      }
    },
    [selectRef],
  );

  useEffect(() => {
    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    setFilterWord('');
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [handleClickOutside, isOpen]);

  return (
    <StyledPaginatedSelectWrapper
      className={className}
      isActive={isActive}
      onKeyPress={e => {
        if (e.key === 13 && isOpen) {
          e.preventDefault();
          e.stopPropagation();
        }
      }}
      ref={selectRef}
      inputSize={size}
    >
      <StyledPaginatedSelect
        readOnly
        type="text"
        placeholder={placeholder}
        size={size}
        error={error}
        value={selectedLabel || ''}
        onChange={value => props.onChange(value)}
        disabled={disabled}
        onClick={openDropDownContent}
        data-cy={`toggle-${dataCy}`}
      />
      <Conditional when={!isRequired && selectedValue !== '' && options?.length > 0}>
        <CleanInputIcon
          size={size}
          onClick={() => {
            handleChange({ value: '', label: '' });
          }}
        />
      </Conditional>
      <StyledDropdownContent isOpen={isOpen}>
        {isSearchDisabled !== true && (
          <StyledSearchBar>
            <Input
              type="text"
              autoComplete="off"
              name="search"
              size="extraSmall"
              placeholder="Pesquisar"
              icon={MagnifyingIcon}
              data-cy={`search-${dataCy}`}
              onChange={e => {
                e.persist();
                updateSearch(e.target.value);
              }}
            />
          </StyledSearchBar>
        )}
        <StyledDropdownContentWrapper>
          {options
            ?.filter(option => filterOptionsByWord(option))
            .map(option => (
              <StyledDropdownItem
                key={option.value}
                data-cy={`${dataCy}-option`}
                onClick={() => {
                  setIsOpen(false);
                  handleChange(option);
                  onSelect(option);
                }}
              >
                {option.label}
              </StyledDropdownItem>
            ))}
        </StyledDropdownContentWrapper>
        <PaginationRange
          onPageChange={handlePageChange}
          pageCount={pageCount}
          currentPage={activePageIndex + 1}
          pageSize={pageSize}
        />
        {isLoading && (
          <StyledSpinner width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              opacity="0.05"
              fillRule="evenodd"
              clipRule="evenodd"
              d="M5.70807 3.11566C6.91985 2.54703 8.29975 2.44917 9.57966 2.84108C10.8596 3.23298 11.9481 4.08668 12.6338 5.2363C13.3194 6.38592 13.5533 7.74938 13.2898 9.06176C13.0264 10.3742 12.2845 11.5418 11.2083 12.3377C10.1322 13.1337 8.7985 13.5012 7.4665 13.3689C6.13449 13.2365 4.89925 12.6137 4.00075 11.6215C3.10226 10.6293 2.60467 9.33855 2.60467 7.99998H3.05176e-05C3.05176e-05 9.98475 0.737828 11.8987 2.07008 13.3699C3.40233 14.8411 5.2339 15.7645 7.20894 15.9607C9.18399 16.157 11.1615 15.6121 12.7572 14.4318C14.3529 13.2516 15.4529 11.5203 15.8435 9.57435C16.2341 7.62839 15.8874 5.60672 14.8707 3.90211C13.8541 2.1975 12.24 0.931674 10.3422 0.35057C8.44445 -0.230535 6.3984 -0.0854254 4.60161 0.757706C2.80483 1.60084 1.38561 3.08178 0.619693 4.91282L3.02258 5.91794C3.53913 4.68306 4.49628 3.68428 5.70807 3.11566Z"
              fill={colors.mediumGray}
            />
            <path
              opacity="0.15"
              fillRule="evenodd"
              clipRule="evenodd"
              d="M5.70807 3.11566C6.91985 2.54703 8.29975 2.44917 9.57966 2.84108C10.8596 3.23298 11.9481 4.08668 12.6338 5.2363C13.3194 6.38592 13.5533 7.74938 13.2898 9.06176C13.0264 10.3742 12.2845 11.5418 11.2083 12.3377C10.1322 13.1337 8.7985 13.5012 7.4665 13.3689C6.13449 13.2365 4.89925 12.6137 4.00075 11.6215C3.10226 10.6293 2.60467 9.33855 2.60467 7.99998H3.05176e-05C3.05176e-05 9.98475 0.737828 11.8987 2.07008 13.3699C3.40233 14.8411 5.2339 15.7645 7.20894 15.9607C9.18399 16.157 11.1615 15.6121 12.7572 14.4318C14.3529 13.2516 15.4529 11.5203 15.8435 9.57435C16.2341 7.62839 15.8874 5.60672 14.8707 3.90211C13.8541 2.1975 12.24 0.931674 10.3422 0.35057C8.44445 -0.230535 6.3984 -0.0854254 4.60161 0.757706L5.70807 3.11566Z"
              fill={colors.mediumGray}
            />
            <path
              opacity="0.3"
              fillRule="evenodd"
              clipRule="evenodd"
              d="M9.57966 2.8406C10.8596 3.23251 11.9481 4.08621 12.6338 5.23583C13.3194 6.38545 13.5533 7.7489 13.2898 9.06129C13.0264 10.3737 12.2845 11.5413 11.2083 12.3373C10.1322 13.1332 8.7985 13.5008 7.4665 13.3684C6.13449 13.236 4.89925 12.6133 4.00075 11.6211C3.10226 10.6288 2.60467 9.33807 2.60467 7.99951H3.05176e-05C3.05176e-05 9.98428 0.737828 11.8982 2.07008 13.3694C3.40233 14.8406 5.2339 15.764 7.20894 15.9603C9.18399 16.1565 11.1615 15.6116 12.7572 14.4314C14.3529 13.2511 15.4529 11.5198 15.8435 9.57388C16.2341 7.62792 15.8874 5.60625 14.8707 3.90164C13.8541 2.19703 12.24 0.931202 10.3423 0.350098L9.57966 2.8406Z"
              fill={colors.mediumGray}
            />
            <path
              opacity="0.5"
              fillRule="evenodd"
              clipRule="evenodd"
              d="M12.6338 5.23653C13.3194 6.38616 13.5533 7.74961 13.2898 9.062C13.0264 10.3744 12.2845 11.542 11.2083 12.338C10.1322 13.134 8.7985 13.5015 7.4665 13.3691C6.13449 13.2367 4.89925 12.614 4.00075 11.6218C3.10226 10.6296 2.60467 9.33878 2.60467 8.00022H3.05176e-05C3.05176e-05 9.98499 0.737828 11.8989 2.07008 13.3701C3.40233 14.8413 5.2339 15.7647 7.20894 15.961C9.18399 16.1572 11.1615 15.6123 12.7572 14.4321C14.3529 13.2518 15.4529 11.5205 15.8435 9.57459C16.2341 7.62863 15.8874 5.60695 14.8707 3.90234L12.6338 5.23653Z"
              fill={colors.mediumGray}
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M4.00097 11.6215C3.10247 10.6293 2.60489 9.33857 2.60489 8H0.000245094C0.000245094 9.98477 0.73804 11.8987 2.07029 13.3699C3.40254 14.8411 5.23412 15.7645 7.20916 15.9608C9.1842 16.157 11.1617 15.6121 12.7574 14.4319C14.3531 13.2516 15.4532 11.5203 15.8438 9.57437L13.29 9.06178C13.0266 10.3742 12.2847 11.5418 11.2086 12.3378C10.1324 13.1337 8.79872 13.5013 7.46671 13.3689C6.13471 13.2365 4.89946 12.6137 4.00097 11.6215Z"
              fill={colors.mediumGray}
            />
          </StyledSpinner>
        )}
      </StyledDropdownContent>
      <StyledError>
        <p>{error && errormessage}</p>
      </StyledError>
    </StyledPaginatedSelectWrapper>
  );
});

PaginatedSelect.propTypes = {
  size: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.any,
  options: PropTypes.array,
  defaultValue: PropTypes.any,
};

PaginatedSelect.defaultProps = {
  size: 'medium',
  options: [],
  defaultValue: '',
};

export { PaginatedSelect };
