import React, { useEffect, forwardRef, useState } from 'react';
import PropTypes from 'prop-types';

import { format, parse } from 'date-fns';
import { pt } from 'date-fns/locale';

import { Button } from 'components/Button';

import { ReactComponent as CalendarIcon } from './assets/calendar.svg';

import { colors } from 'assets/styled/tokens';

import {
  StyledInputWrapper,
  StyledDateRangePicker,
  StyledDropdownContent,
  StyledInput,
  StyledDropdownFooter,
} from './styled';
import CleanInputIcon from 'components/Form/shared/CleanInputIcon';
import Conditional from 'components/Conditional';

import { createToast } from 'utils/createToast';
import { Toast } from 'components/Toast';

const initialState = {
  startDate: new Date(),
  endDate: new Date(),
  key: 'selection',
  formattedISO: '',
  formattedShort: '',
};

const DateRangePicker = forwardRef((props, ref) => {
  const {
    size,
    value,
    placeholder,
    onChange,
    disabled,
    className,
    'data-cy': dataCy,
    required,
    notClean = false,
  } = props;
  const [isOpen, setIsOpen] = useState(false);
  const [ranges, setRanges] = useState([initialState]);
  const [tempRange, setTempRange] = useState([initialState]);

  // Add date mask in inputs
  useEffect(() => {
    const handleNaNInInput = input => {
      if (isNaN(Number(input.value.charAt(input.value.length - 1)))) {
        input.value = input.value.substr(0, input.value.length - 1);
      }
    };

    const handleDeleteCharInInput = (e, input) => {
      const ignoreKeys = ['ArrowLeft', 'ArrowRight', 'ArrowDown', 'ArrowUp'];

      if (ignoreKeys.includes(e.key)) {
        e.preventDefault();
        return;
      }

      if (e.key === 'Backspace' || e.key === 'Delete') {
        e.preventDefault();

        input.value = input.value.substr(0, input.value.length - 1);
        return;
      }
      if (input.value.length === 2 || input.value.length === 5) {
        input.value = input.value + '/';
      }

      if (input.value.length >= 10) {
        input.value = input.value.substr(0, input.value.length - 1);
      }
    };
    const inputs = ref.current.querySelectorAll('.rdrDateInput');
    inputs[0].children[0].addEventListener('input', () => handleNaNInInput(inputs[0].children[0]), false);
    inputs[0].children[0].addEventListener('keydown', e => handleDeleteCharInInput(e, inputs[0].children[0]), false);

    inputs[1].children[0].addEventListener('input', () => handleNaNInInput(inputs[1].children[0]), false);
    inputs[1].children[0].addEventListener('keydown', e => handleDeleteCharInInput(e, inputs[1].children[0]), false);

    return () => {
      inputs[0].children[0].removeEventListener('input', () => {}, false);
      inputs[0].children[0].removeEventListener('keydown', () => {}, false);

      inputs[1].children[0].removeEventListener('input', () => {}, false);
      inputs[1].children[0].removeEventListener('keydown', () => {}, false);
    };
  }, [ref]);

  const [toastList, setToastList] = useState([]);

  useEffect(() => {
    const handleValueChange = () => {
      try {
        if (!value) {
          setRanges([initialState]);
          onChange && onChange('');
        }
        const result = value.split('/');
        const startDate = parse(result[0], 'yyyy-MM-dd', new Date());
        const endDate = parse(result[1], 'yyyy-MM-dd', new Date());

        const formattedShort = format(startDate, 'dd/MM/yyyy') + ' - ' + format(endDate, 'dd/MM/yyyy');

        const formattedISO = format(startDate, 'yyyy-MM-dd') + '/' + format(endDate, 'yyyy-MM-dd');

        if (result && result.length === 2) {
          setRanges([
            {
              startDate,
              endDate,
              key: 'selection',
              formattedShort,
              formattedISO,
            },
          ]);
          onChange && onChange(formattedISO);
        }
      } catch (e) {}
    };

    if (ranges[0].formattedISO !== value) {
      handleValueChange();
    }
    // eslint-disable-next-line
  }, [value]);

  const toggle = () => setIsOpen(!isOpen);

  const handleChange = ({ selection }) => {
    const { startDate, endDate } = selection;

    const diffTime = Math.abs(endDate - startDate);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

    if (diffDays > 365) {
      setToastList(prevState => [
        ...prevState,
        createToast({
          type: 'error',
          message: 'Selecione um período inferior a 365 dias',
        }),
      ]);
      return true;
    }

    const formattedShort = format(startDate, 'dd/MM/yyyy') + ' - ' + format(endDate, 'dd/MM/yyyy');

    const formattedISO = format(startDate, 'yyyy-MM-dd') + '/' + format(endDate, 'yyyy-MM-dd');
    setRanges([{ ...selection, formattedShort, formattedISO }]);
    setTempRange([{ ...selection, formattedShort, formattedISO }]);
  };

  const handleClearDateRange = () => {
    setRanges([initialState]);
    setTempRange([initialState]);
    onChange && onChange('');
  };
  const applyDateRange = () => {
    setRanges(tempRange);
    setIsOpen(false);
    onChange && onChange(tempRange[0].formattedISO);
  };
  useEffect(() => {
    const handleClickOutside = e => {
      if (ref.current && ref.current.contains(e.target)) {
        // inside click
        return;
      }

      // outside click
      setIsOpen(false);
    };
    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen, ref]);
  return (
    <StyledInputWrapper ref={ref}>
      <CalendarIcon width={9} height={10} />
      <StyledInput
        readOnly
        type="text"
        data-cy={dataCy}
        size={size}
        placeholder={placeholder}
        value={ranges[0].formattedShort}
        onClick={toggle}
        disabled={disabled}
        required={required}
      />
      <Toast toastList={toastList} autoDelete dismissTime={7000} />
      <Conditional when={ranges[0].formattedShort !== '' && !notClean}>
        <CleanInputIcon size={size} onClick={handleClearDateRange} />
      </Conditional>
      <StyledDropdownContent className={className} isOpen={isOpen}>
        <StyledDateRangePicker
          locale={pt}
          rangeColors={[colors.primary]}
          onChange={handleChange}
          showSelectionPreview={true}
          moveRangeOnFirstSelection={false}
          ranges={ranges}
          // editableDateInputs
          dateDisplayFormat="dd/MM/yyyy"
          staticRanges={[]}
          inputRanges={[]}
        />
        <StyledDropdownFooter>
          <Button type="button" onClick={applyDateRange}>
            Aplicar
          </Button>
        </StyledDropdownFooter>
      </StyledDropdownContent>
    </StyledInputWrapper>
  );
});

DateRangePicker.prototype = {
  size: PropTypes.string,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
};

export { DateRangePicker };
