import { Dispatch, SetStateAction, useState } from 'react';

interface ReturnType {
  value: boolean;
  setValue: Dispatch<SetStateAction<boolean>>;
  setTrue: () => void;
  setFalse: () => void;
  toggle: () => boolean;
}

export function useBoolean(defaultValue = false): ReturnType {
  const [value, setValue] = useState<boolean>(defaultValue);

  const setTrue = () => setValue(true);
  const setFalse = () => setValue(false);
  const toggle = () => {
    setValue(x => !x);
    return !value;
  };

  return { value, setValue, setTrue, setFalse, toggle };
}
