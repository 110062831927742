import styled from 'styled-components';

import { colors } from 'assets/styled/tokens';

export const StyledAccordion = styled.div`
  width: 100%;
  //pointer-events: none;
`;

export const StyledHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 3px;
  cursor: pointer;
  border-bottom: 1px solid ${colors.primary};
`;

export const StyledTitle = styled.h3`
  font-weight: 700;
  font-family: 'Blinker';
  font-size: 20px;
  line-height: 24px;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  color: ${colors.primary};
`;

export const StyledBody = styled.div`
  margin: 0.6rem 0 1.8rem;
  display: ${props => (props.isOpen ? 'block' : 'none')};
  :last-child {
    margin: 0.6rem 0 0;
  }
`;
