import React from 'react';
import { StyledBatteryChargePercentage } from './styled';
import Battery from '..';

const BatteryCharge = ({ battery }) => {
  return (
    <StyledBatteryChargePercentage percentage={battery}>
      <Battery percentage={battery} />
      <span>
        {battery}
        <span>%</span>
      </span>
    </StyledBatteryChargePercentage>
  );
};

export default BatteryCharge;
