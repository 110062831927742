import React, { useContext } from 'react';
import { useTable } from 'react-table';
import { AuthContext } from 'context/AuthContext';
import {
  ContainerTable,
  TableRow,
  TableCell,
  StyledStatus,
  CancelScheduleButton,
  StatusWrapper,
  ReScheduleButton,
} from './styles';
import { ScheduleMonitoringService } from 'services/schedules';
import { createGenericErrorToast, createSuccessToast } from 'utils/createToast';

function Table({ columns, data, setToastList, fetchData }) {
  const { authContext } = useContext(AuthContext);
  // Use the state and functions returned from useTable to build your UI
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
    columns,
    data,
  });
  const statusColors = [
    {
      text: 'Não agendado',
      textColor: '#888888',
      borderColor: '#E5E5E5',
      backgroundColor: '#ffffff',
      iconColor: '#888888',
      icon: (
        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="6" cy="6" r="6" fill="#888888" />
          <path
            d="M7.97365 8.9172C7.85493 9.03592 7.66836 9.01896 7.5666 8.9172L6.00624 7.35683L4.44588 8.9172C4.32715 9.03592 4.14059 9.01896 4.03883 8.9172L3.08904 7.96741C2.97032 7.84869 2.97032 7.67908 3.08904 7.56036L4.6494 6L3.08904 4.43964C2.97032 4.32092 2.97032 4.15131 3.08904 4.03259L4.03883 3.0828C4.14059 2.98104 4.32715 2.96408 4.44588 3.0828L6.00624 4.64316L7.5666 3.0828C7.66836 2.98104 7.85493 2.96408 7.97365 3.0828L8.92343 4.03259C9.0252 4.13435 9.0252 4.33788 8.92343 4.43964L7.36307 6L8.92343 7.56036C9.0252 7.66212 9.0252 7.86565 8.92343 7.96741L7.97365 8.9172Z"
            fill="white"
          />
        </svg>
      ),
    },
    {
      text: 'Agendamento sendo gerado',
      textColor: '#286892',
      borderColor: '#85BFE4',
      backgroundColor: '#CCE4FA',
      iconColor: '#307CAE',
      icon: (
        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="6" cy="6" r="6" fill="#307CAE" />
          <path
            d="M3.00012 8.36719C3.00012 8.72314 3.28137 9 3.64298 9H8.35726C8.70548 9 9.00012 8.72314 9.00012 8.36719V4.78125H3.00012V8.36719ZM7.28584 5.7832C7.28584 5.7041 7.3528 5.625 7.44655 5.625H7.98226C8.06262 5.625 8.14298 5.7041 8.14298 5.7832V6.31055C8.14298 6.40283 8.06262 6.46875 7.98226 6.46875H7.44655C7.3528 6.46875 7.28584 6.40283 7.28584 6.31055V5.7832ZM7.28584 7.4707C7.28584 7.3916 7.3528 7.3125 7.44655 7.3125H7.98226C8.06262 7.3125 8.14298 7.3916 8.14298 7.4707V7.99805C8.14298 8.09033 8.06262 8.15625 7.98226 8.15625H7.44655C7.3528 8.15625 7.28584 8.09033 7.28584 7.99805V7.4707ZM5.57155 5.7832C5.57155 5.7041 5.63851 5.625 5.73226 5.625H6.26798C6.34834 5.625 6.42869 5.7041 6.42869 5.7832V6.31055C6.42869 6.40283 6.34834 6.46875 6.26798 6.46875H5.73226C5.63851 6.46875 5.57155 6.40283 5.57155 6.31055V5.7832ZM5.57155 7.4707C5.57155 7.3916 5.63851 7.3125 5.73226 7.3125H6.26798C6.34834 7.3125 6.42869 7.3916 6.42869 7.4707V7.99805C6.42869 8.09033 6.34834 8.15625 6.26798 8.15625H5.73226C5.63851 8.15625 5.57155 8.09033 5.57155 7.99805V7.4707ZM3.85726 5.7832C3.85726 5.7041 3.92423 5.625 4.01798 5.625H4.55369C4.63405 5.625 4.71441 5.7041 4.71441 5.7832V6.31055C4.71441 6.40283 4.63405 6.46875 4.55369 6.46875H4.01798C3.92423 6.46875 3.85726 6.40283 3.85726 6.31055V5.7832ZM3.85726 7.4707C3.85726 7.3916 3.92423 7.3125 4.01798 7.3125H4.55369C4.63405 7.3125 4.71441 7.3916 4.71441 7.4707V7.99805C4.71441 8.09033 4.63405 8.15625 4.55369 8.15625H4.01798C3.92423 8.15625 3.85726 8.09033 3.85726 7.99805V7.4707ZM8.35726 3.09375H7.71441V2.46094C7.71441 2.35547 7.60726 2.25 7.50012 2.25H7.07155C6.95101 2.25 6.85726 2.35547 6.85726 2.46094V3.09375H5.14298V2.46094C5.14298 2.35547 5.03584 2.25 4.92869 2.25H4.50012C4.37959 2.25 4.28584 2.35547 4.28584 2.46094V3.09375H3.64298C3.28137 3.09375 3.00012 3.38379 3.00012 3.72656V4.35938H9.00012V3.72656C9.00012 3.38379 8.70548 3.09375 8.35726 3.09375Z"
            fill="white"
          />
        </svg>
      ),
    },
    {
      text: 'Ocorreu um erro',
      textColor: '#A3463B',
      borderColor: '#F4A399',
      backgroundColor: '#FFDBD6',
      icon: (
        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="6" cy="6" r="6" fill="#EC6655" />
          <path
            d="M4.66699 2.37221L4.90942 5.68595C4.90942 5.89564 5.03063 6.00049 5.27305 6.00049H6.7276C6.97002 6.00049 7.09123 5.89564 7.09123 5.68595L7.33366 2.37221C7.33366 2.25784 7.29326 2.17205 7.21245 2.11486C7.15184 2.03861 7.07103 2.00049 6.97002 2.00049H5.03063C4.92962 2.00049 4.83871 2.03861 4.7579 2.11486C4.6973 2.17205 4.66699 2.25784 4.66699 2.37221Z"
            fill="#ffffff"
          />
          <path
            d="M6.00033 7.33398C6.36748 7.33398 6.67665 7.46925 6.92786 7.73978C7.19839 7.99099 7.33366 8.30017 7.33366 8.66732C7.33366 9.03447 7.19839 9.35331 6.92786 9.62384C6.67665 9.87505 6.36748 10.0007 6.00033 10.0007C5.63318 10.0007 5.31434 9.87505 5.0438 9.62384C4.7926 9.35331 4.66699 9.03447 4.66699 8.66732C4.66699 8.30017 4.7926 7.99099 5.0438 7.73978C5.31434 7.46925 5.63318 7.33398 6.00033 7.33398Z"
            fill="black"
          />
        </svg>
      ),
    },
    {
      text: 'Agendado com sucesso',
      textColor: '#00715C',
      borderColor: '#63CAB7',
      backgroundColor: '#B4EDE3',
      iconColor: '#00866E',
      icon: (
        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="6" cy="6" r="6" fill="#00866E" />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M5.56548 8.81249C5.32693 9.06246 4.92888 9.06246 4.69033 8.81249L2.42834 6.4422C2.19055 6.19303 2.19055 5.77838 2.42834 5.5292L2.9189 5.01517C3.15729 4.76536 3.54138 4.7652 3.77996 5.0147L5.1339 6.4209L8.21958 3.18747C8.45813 2.93751 8.84255 2.93751 9.0811 3.18747L9.57165 3.70151C9.80944 3.95069 9.80944 4.36534 9.57165 4.61451L5.56548 8.81249Z"
            fill="white"
          />
        </svg>
      ),
    },
    {
      text: 'Aguardando agendamento',
      textColor: '#888888',
      borderColor: '#E5E5E5',
      backgroundColor: '#ffffff',
      iconColor: '#888888',
      icon: (
        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="6" cy="6" r="6" fill="#888888" />
          <path
            d="M8.1875 4.125C8.35677 4.125 8.5 4.02734 8.5 3.89062V3.73438C8.5 3.60742 8.35677 3.5 8.1875 3.5H3.8125C3.63021 3.5 3.5 3.60742 3.5 3.73438V3.89062C3.5 4.02734 3.63021 4.125 3.8125 4.125C3.8125 5.01367 4.47656 5.76562 5.375 6C4.47656 6.24414 3.8125 6.99609 3.8125 7.875C3.63021 7.875 3.5 7.98242 3.5 8.10938V8.26562C3.5 8.40234 3.63021 8.5 3.8125 8.5H8.1875C8.35677 8.5 8.5 8.40234 8.5 8.26562V8.10938C8.5 7.98242 8.35677 7.875 8.1875 7.875C8.1875 6.99609 7.51042 6.24414 6.61198 6C7.51042 5.76562 8.1875 5.01367 8.1875 4.125Z"
            fill="white"
          />
        </svg>
      ),
    },
    {
      text: 'Agendamento cancelado',
      textColor: '#A3463B',
      borderColor: '#F4A399',
      backgroundColor: '#FFDBD6',
      iconColor: '#EC6655',
      icon: (
        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="6" cy="6" r="6" fill="#EC6655" />
          <path
            d="M7.97365 8.9172C7.85493 9.03592 7.66836 9.01896 7.5666 8.9172L6.00624 7.35683L4.44588 8.9172C4.32715 9.03592 4.14059 9.01896 4.03883 8.9172L3.08904 7.96741C2.97032 7.84869 2.97032 7.67908 3.08904 7.56036L4.6494 6L3.08904 4.43964C2.97032 4.32092 2.97032 4.15131 3.08904 4.03259L4.03883 3.0828C4.14059 2.98104 4.32715 2.96408 4.44588 3.0828L6.00624 4.64316L7.5666 3.0828C7.66836 2.98104 7.85493 2.96408 7.97365 3.0828L8.92343 4.03259C9.0252 4.13435 9.0252 4.33788 8.92343 4.43964L7.36307 6L8.92343 7.56036C9.0252 7.66212 9.0252 7.86565 8.92343 7.96741L7.97365 8.9172Z"
            fill="white"
          />
        </svg>
      ),
    },
  ];
  const handleCancelSchedule = async planoAtividade => {
    const { token } = authContext;
    try {
      const res = await ScheduleMonitoringService.cancelPlans({ planoAtividade, token });
      createSuccessToast(setToastList, res.data.result);
    } catch (e) {
      createGenericErrorToast(setToastList);
    } finally {
      fetchData();
    }
  };
  const handleReSchedule = async planoAtividade => {
    const { token } = authContext;
    try {
      const res = await ScheduleMonitoringService.reSchedulePlans({ planoAtividade, token });
      createSuccessToast(setToastList, res.data);
    } catch (e) {
      createGenericErrorToast(setToastList);
    } finally {
      fetchData();
    }
  };
  // Render the UI for your table
  return (
    <table {...getTableProps()}>
      <thead>
        {headerGroups.map(headerGroup => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map(column => (
              <th {...column.getHeaderProps()}>{column.render('Header')}</th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row, index) => {
          prepareRow(row);

          return (
            <TableRow gray={index % 2 === 0} {...row.getRowProps()}>
              {row.cells.map(cell => {
                return (
                  <TableCell {...cell.getCellProps()}>
                    {cell.column.id === 'status' ? (
                      <StatusWrapper>
                        <StyledStatus
                          backgroundColor={statusColors[cell.value - 1].backgroundColor}
                          textColor={statusColors[cell.value - 1].textColor}
                          borderColor={statusColors[cell.value - 1].borderColor}
                        >
                          {statusColors[cell.value - 1].icon}
                          {statusColors[cell.value - 1].text}
                        </StyledStatus>
                        {cell.value === 5 && (
                          <CancelScheduleButton
                            onClick={() => handleCancelSchedule(data[cell.row.index].planoAtividade)}
                          >
                            <svg
                              width="12"
                              height="12"
                              viewBox="0 0 12 12"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M0.229221 0.229221C0.534848 -0.0764069 1.03037 -0.0764069 1.336 0.229221L11.7708 10.664C12.0764 10.9696 12.0764 11.4652 11.7708 11.7708C11.4652 12.0764 10.9696 12.0764 10.664 11.7708L0.229221 1.336C-0.0764069 1.03037 -0.0764069 0.534848 0.229221 0.229221Z"
                                fill="#1E1E1E"
                              />
                              <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M11.7708 0.229221C11.4652 -0.0764069 10.9696 -0.0764069 10.664 0.229221L0.229221 10.664C-0.0764065 10.9696 -0.0764065 11.4652 0.229221 11.7708C0.534849 12.0764 1.03037 12.0764 1.336 11.7708L11.7708 1.336C12.0764 1.03037 12.0764 0.534848 11.7708 0.229221Z"
                                fill="#1E1E1E"
                              />
                            </svg>
                          </CancelScheduleButton>
                        )}
                        {cell.value === 3 && (
                          <ReScheduleButton onClick={() => handleReSchedule(data[cell.row.index].planoAtividade)}>
                            <svg
                              width="32"
                              height="32"
                              viewBox="0 0 32 32"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <rect x="0.5" y="0.5" width="31" height="31" rx="3.5" fill="white" stroke="#E5E5E5" />
                              <path
                                d="M21.7144 10H20.5716C20.4287 10 20.2859 10.1429 20.2859 10.2857C20.2859 10.3095 20.2859 10.3095 20.2859 10.3095L20.3811 12.2857C19.3097 11 17.6906 10.1905 15.9049 10.1905C12.643 10.1905 9.97635 12.8571 10.0002 16.119C10.0002 19.381 12.643 22 15.9049 22C17.4287 22 18.8097 21.4286 19.8573 20.5C19.9049 20.4286 19.9525 20.3571 19.9525 20.2857C19.9525 20.1905 19.9049 20.119 19.8573 20.0714L19.0478 19.2619C19.0002 19.2143 18.9287 19.1905 18.8573 19.1905C18.7859 19.1905 18.7144 19.2143 18.6668 19.2619C17.9287 19.9048 16.9525 20.2857 15.9049 20.2857C13.5716 20.2857 11.7144 18.4286 11.7144 16.0952C11.7144 13.7857 13.5716 11.9048 15.9049 11.9048C17.3335 11.9048 18.6192 12.6429 19.3811 13.7619L16.9525 13.6429C16.9525 13.6429 16.9525 13.6429 16.9287 13.6429C16.7859 13.6429 16.643 13.7857 16.643 13.9286H16.6668V15.0714C16.6668 15.2143 16.7859 15.3571 16.9525 15.3571H21.7144C21.8573 15.3571 22.0002 15.2143 22.0002 15.0714V10.2857C22.0002 10.1429 21.8573 10 21.7144 10Z"
                                fill="#888888"
                              />
                            </svg>
                          </ReScheduleButton>
                        )}
                      </StatusWrapper>
                    ) : (
                      <>{cell.render('Cell')}</>
                    )}
                  </TableCell>
                );
              })}
            </TableRow>
          );
        })}
      </tbody>
    </table>
  );
}

export default function ScheduleTable({ columns, data, setToastList, fetchData }) {
  return (
    <ContainerTable>
      <Table columns={columns} data={data} setToastList={setToastList} fetchData={fetchData} />
    </ContainerTable>
  );
}
