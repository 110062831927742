/* istanbul ignore file */

import styled from 'styled-components';
import { colors, medias, fonts } from 'assets/styled/tokens';
import { FormGroup } from 'components/Form/subcomponents/FormGroup';

export const BodyContainer = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  background-color: ${colors.pampas};
`;

export const StyledWelcome = styled.div`
  width: 100%;
  margin-bottom: 32px;

  h3 {
    font-weight: 500;
    margin-bottom: 16px;
    font-size: 24px;
    font-family: 'Blinker', sans-serif;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
    color: ${colors.primary};
  }

  span {
    font-size: 14px;
    font-family: ${fonts.family.text};
    font-weight: 400;
    line-height: 20px;
  }
`;

export const StyledLeankeepLogo = styled.svg`
  margin-bottom: 48px;
`;
export const StyledPasswordChangedParagraph = styled.div`
  margin-bottom: 72px;
  font-size: 14px;
  color: ${colors.tundora};
`;
export const StyledSupportEmail = styled.a`
  text-decoration: underline;
`;
export const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  max-width: 410px;
  width: 100%;
  height: 100%;
  margin: 104px auto 0;

  span {
    font-family: ${fonts.family.text};
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    color: ${colors.gray};
  }

  span > a {
    color: ${colors.primary};
  }

  img {
    max-width: 15rem;
    margin: 104px auto 50px 0;
  }

  button {
    width: 100%;
    transition: all 0.4s ease-in-out;
  }

  p {
    font-weight: 400;
    font-size: 11px;
    margin-top: 4px;
    color: ${colors.bittersweet};

    a {
      color: ${colors.primary};
      text-decoration-line: underline;
    }
  }

  @media screen and (max-width: ${medias.desktopSm}) {
    padding: 0 25px;
    img {
      display: initial;
    }
  }
`;
export const StyledFormGroup = styled(FormGroup)`
  margin-bottom: 16px;
  input {
    font-size: 24px;
    height: 40px;
  }
  input::placeholder {
    font-size: 12px;
    letter-spacing: 1px;
    transform: translateY(-5px);
  }
`;
export const StyledFormPass = styled.div`
  display: block;
  width: 100%;
  min-width: 295px;

  div {
    margin-bottom: 16px;
  }

  :-ms-input-placeholder {
    color: ${colors.gray};
  }

  @media screen and (min-width: ${medias.mobile}) {
    input {
      width: 325px;
    }
  }

  @media screen and (min-width: ${medias.tablet}) {
    input {
      width: 360px;
    }
  }

  @media screen and (min-width: ${medias.desktopSm}) {
    input {
      width: 360px;
    }
  }

  @media screen and (min-width: ${medias.desktopMd}) {
    input {
      width: 412px;
    }
  }

  @media screen and (min-width: ${medias.desktopXXl}) {
    input {
      width: 408px;
    }
  }
`;

export const StyledFormFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 32px 0 32px !important;

  button {
    max-width: 30%;
  }
`;

export const StyledError = styled.div`
  display: block;
  margin-top: -10px;
  font-size: 12px;

  p {
    font-family: ${fonts.family.text};
    font-size: 11px;
    font-weight: 400;
    color: ${colors.bittersweet};
  }
`;
export const StyledErrorToast = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  padding: 17px 20px;
  opacity: ${({ isActive }) => (isActive ? 1 : 0)};
  margin-top: 117px;
  font-size: 12px;
  background: ${colors.bittersweet};
  border-radius: 3px;
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.25);
  svg:first-child {
    margin-right: 15px;
  }
  p {
    font-family: ${fonts.family.text};
    color: ${colors.white};
    font-size: 14px;
    line-height: 16px;
    margin-right: auto;
  }
  svg:last-child {
    cursor: pointer;
  }
  transition: all 300ms ease;
`;

export const StyledModalBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 0 8px;

  svg {
    margin-bottom: 8px;
  }

  h6 {
    font-family: ${fonts.family.title};
    font-size: 21px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px;
    text-align: center;
    margin-bottom: 16px;
    color: #ec6655;
  }

  span {
    font-family: ${fonts.family.text};
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    text-align: center;
    margin-bottom: 32px;
    color: ${colors.gray};
  }

  button {
    padding: 7px 32px;
    border-radius: 3px;
    font-family: ${fonts.family.text};
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 14px;
    text-align: center;
    color: ${colors.white};
  }
`;
