import React from 'react';
import { ReactComponent as HideTechniciansOnMapIcon } from 'assets/icons/eye-hide.svg';
import { ReactComponent as ShowTechniciansOnMapIcon } from 'assets/icons/eye-show.svg';
import InputTooltip from 'components/Form/subcomponents/InputTooltip';
import { ToggleButton, StyledToggleOnMapButtonWrapper, StyledInputTooltip } from './styled';

const ToggleOnMapButton = ({ isTooltipVisible = true, isHidden, handleToggle }) => {
  return (
    <StyledToggleOnMapButtonWrapper onClick={handleToggle}>
      <StyledInputTooltip isLarge={isHidden}>
        <InputTooltip
          isVisible={isTooltipVisible}
          message="Clique para ver no mapa quem estava online e ficou offline hoje."
        />
      </StyledInputTooltip>

      {!isHidden && (
        <ToggleButton>
          <HideTechniciansOnMapIcon /> Ocultar do mapa
        </ToggleButton>
      )}
      {isHidden && (
        <ToggleButton>
          <ShowTechniciansOnMapIcon /> Exibir no mapa
        </ToggleButton>
      )}
    </StyledToggleOnMapButtonWrapper>
  );
};
export default ToggleOnMapButton;
