/* istanbul ignore file */

import styled from 'styled-components';

export const InvisibleDownloadLink = styled.a`
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  visibility: hidden;
`;
