import React, { memo } from 'react';
import PropTypes from 'prop-types';

import { StyledCheckbox, StyledInput } from './styled';

const Checkbox = props => {
  const { isChecked, isCheckboxAll, isAllSelected, label, value, onChange, 'data-cy': dataCy } = props;

  return (
    <StyledCheckbox isCheckboxAll={isCheckboxAll} isAllSelected={isAllSelected} data-cy={dataCy}>
      <StyledInput type="checkbox" data-cy={`${dataCy}-input`} checked={isChecked} onChange={onChange} value={value} />
      <span></span>
      <p>{label}</p>
    </StyledCheckbox>
  );
};

Checkbox.propTypes = {
  isChecked: PropTypes.bool,
  isCheckboxAll: PropTypes.bool,
  value: PropTypes.any,
  label: PropTypes.any,
  'data-cy': PropTypes.string,
};

export default memo(Checkbox);
