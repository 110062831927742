import React, { forwardRef } from 'react';
import { StyledCheckbox, StyledInput, SubLabel } from './styled';

const Checkbox = forwardRef(({ onChange, checked, name, label }, ref) => {
  return (
    <>
      <StyledCheckbox>
        <StyledInput
          type="checkbox"
          onChange={e => onChange(e.target.checked)}
          checked={checked}
          name={name}
          ref={ref}
        />
        <span></span> {/* You can add an icon or visual mark here if needed */}
        <p>{label}</p>
      </StyledCheckbox>
      <SubLabel>Esta função permite o acesso a informações através da leitura de códigos QR dos ativos.</SubLabel>
    </>
  );
});

export default Checkbox;
