import { previewAuditReportExcelFactory } from './excel';
import { previewAuditReportPdfFactory } from './pdf';

const previewAuditReportFactory = ({ reportType, params }) => {
  switch (reportType) {
    case 'pdf':
      return previewAuditReportPdfFactory(params);
    case 'excel':
      return previewAuditReportExcelFactory(params);
    default:
      return null;
  }
};

export { previewAuditReportFactory };
