import React, { useCallback, useEffect, useState } from 'react';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { colors } from 'assets/styled/tokens';
import { FiltersService } from 'services/reports';

import {
  FrequencyInputsRow,
  FrequencyInterval,
  StyledAddActivityButton,
  StyledAddActivityFormModal,
  StyledAddActivityFormModalHeader,
  StyledCheckbox,
  StyledCloseAddActivityFormModal,
  StyledTimeInput,
  StyledEstimatedTimeInput,
  StyledFormGroup,
  StyledRadioGroup,
  StyledMeasurementsRadioGroup,
  StyledFormRow,
  StyledCategoryFormRow,
  StyledMeasurementsFormRow,
  StyledAddMeasurementUnitButton,
  StyledDatePicker,
  StyledStartDateFormGroup,
  StyledMeasurementsContainer,
  StyledMeasurementsFormGroup,
  StyledMeasurementUnitRow,
  StyledDeleteMeasurementUnit,
  StyledOtherDaysConsideredContainer,
  StyledTextarea,
  StyledCategoryFormGroup,
  StyledTimeFormGroup,
  StyledPriorityFormGroup,
  StyledConformityRadioGroup,
  StyledMeasurementsInput,
  StyledAddActivityForm,
} from './NewGeneralPlanPage/styled';
import { Radio } from 'components/Form/subcomponents/Radio';
import { Input } from 'components/Form/subcomponents/Input';
import { Select } from 'components/Form/subcomponents/Select';
import { PaginatedSelect } from 'components/Form/subcomponents/PaginatedSelect';
import MultiSelect from 'components/Form/subcomponents/MultiSelect';
import InformativeTooltip from 'components/InformativeTooltip';
import { createGenericErrorToast, createSuccessToast } from 'utils/createToast';

function AddActivityFormModal({
  activeDraftPlanId,
  addActivity,
  isAddActivityModalActive,
  setGlobalComponentsContext,
  setToastList,
  companyId,
  createToast,
  setIsAddActivityModalActive,
}) {
  const {
    handleSubmit,
    register,
    reset,
    control,
    getValues,
    formState: { errors },
  } = useForm({
    defaultValues: {
      priority: 2,
      repeatEvery: '1',
      startTime: '',
      specificDay: '',
      description: '',
      startDate: new Date(),
      estimatedTime: '',
    },
  });
  const [isMeasurementRequired, setIsMeasurementRequired] = useState(true);
  const [saturdayExcChecked, setSaturdayExcChecked] = useState(false);
  const [sundayExcChecked, setSundayExcChecked] = useState(false);
  const [holidaysExcChecked, setHolidaysExcChecked] = useState(false);
  const [isConformityActive, setIsConformityActive] = useState(false);
  const [isAddActivityFormDirty, setIsAddActivityFormDirty] = useState(false);

  const [activeCategory, setActiveCategory] = useState(undefined);
  const [measurements, setMeasurements] = useState([
    { valorMinimo: '', valorMaximo: '', valorPadrao: '', unidadeMedicao: '' },
  ]);
  const [isMeasurementActivity, setIsMeasurementActivity] = useState(false);
  const [onlyConsiderBusinessDays, setOnlyConsiderBusinessDays] = useState(true);
  const [activeFrequency, setActiveFrequency] = useState(null);
  // Trim trailing slashes
  const replaceBadInputs = val => {
    // Replace impossible inputs as they appear
    // val = val.replace(/[^\dh:]/, '');
    // val = val.replace(/^[^0-2]/, '');
    val = val.replace(/^([2-9])[4-9]/, '$1');
    // val = val.replace(/^\d[:h]/, '');
    val = val.replace(/^([01][0-9])[^:h]/, '$1');
    val = val.replace(/^(2[0-3])[^:h]/, '$1');
    val = val.replace(/^(\d{2}[:h])[^0-5]/, '$1');
    val = val.replace(/^(\d{2}h)./, '$1');
    val = val.replace(/^(\d{2}:[0-5])[^0-9]/, '$1');
    val = val.replace(/^(\d{2}:\d[0-9])./, '$1');
    return val;
  };
  function beforeMaskedStateChange({ nextState }) {
    let { value } = nextState;
    value = replaceBadInputs(value);
    return {
      ...nextState,
      value,
    };
  }

  const startTimeMask = [/^([0-2])/, /([0-9])/, ':', /[0-5]/, /[0-9]/];
  const timeMask = [/^([0-9])/, /([0-9])/, ':', /[0-5]/, /[0-9]/];

  const [fields, setFields] = useState({
    description: [],
    startDate: [],
    startTime: [],
    repeatEvery: [],
    frequencies: [
      { value: 'none', label: 'Não se repete' },
      { value: 'daily', label: 'Diária' },
      { value: 'weekly', label: 'Semanal' },
      { value: 'monthly', label: 'Mensal' },
      // TODO: Pending backend (verify?)
      // { value: "bimonthly", label: "Bimestral" },
      // { value: "biannual", label: "Semestral" },
      { value: 'annual', label: 'Anual' },
    ],
    specificDays: [
      { value: 'segunda', label: 'Segunda-feira' },
      { value: 'terca', label: 'Terça-feira' },
      { value: 'quarta', label: 'Quarta-feira' },
      { value: 'quinta', label: 'Quinta-feira' },
      { value: 'sexta', label: 'Sexta-feira' },
      { value: 'sabado', label: 'Sábado' },
      { value: 'domingo', label: 'Domingo' },
    ],
    // materials: [],
    categories: [],
    priorities: [
      { value: 1, label: 'A' },
      { value: 2, label: 'B' },
      { value: 3, label: 'C' },
    ],
  });
  const handleDeleteMeasurement = useCallback(
    index => {
      const newMeasurements = measurements.filter((measurement, i) => i !== index);
      setMeasurements(newMeasurements);
    },
    [measurements],
  );

  const handleAddMeasurementUnit = () => {
    const newMeasurements = measurements;
    newMeasurements.push({
      unidadeMedicao: '',
      valorMinimo: '',
      valorMaximo: '',
      valorPadrao: '',
    });

    setMeasurements([...newMeasurements]);
  };

  const validateActivityRequiredFields = useCallback(() => {
    const description = getValues('description');
    const startDate = getValues('startDate');
    const category = getValues('category');
    const startTime = getValues('startTime');
    const frequency = getValues('frequency');
    const estimatedTime = getValues('estimatedTime');

    const requiredFields = [description, category, startDate, startTime, estimatedTime, frequency];

    const isMeasurementsInvalid = measurements.some(measurement => {
      return (
        measurement.unidadeMedicao === '' ||
        measurement.valorMinimo === '' ||
        measurement.valorMaximo === '' ||
        measurement.valorPadrao === ''
      );
    });

    const invalidFields = requiredFields.filter(element => !element);

    let invalidFieldsCount = invalidFields.length;

    let isSpecficDaysInvalid =
      activeFrequency === 'weekly' && (!getValues('specificDays') || getValues('specificDays').length === 0);
    if (isSpecficDaysInvalid) {
      invalidFieldsCount++;
    }
    if (isMeasurementActivity && isMeasurementsInvalid) {
      invalidFieldsCount++;
    }
    if (invalidFieldsCount === 1) {
      setToastList(() => [
        createToast({
          type: 'error',

          message: 'Um campo obrigatório não foi preenchido.',
        }),
      ]);

      return true;
    } else if (invalidFieldsCount > 1) {
      setToastList(() => [
        createToast({
          type: 'error',

          message: 'Há campos obrigatórios não preenchidos.',
        }),
      ]);
      return true;
    }

    return false;
  }, [getValues, measurements, activeFrequency, isMeasurementActivity, setToastList, createToast]);

  const onAddActivitySubmit = values => {
    // e.preventDefault()
    setIsAddActivityFormDirty(true);
    const hasError = validateActivityRequiredFields();
    if (hasError) {
      return;
    }
    let _values = {
      ...values,
      sundayExcChecked: sundayExcChecked,
      saturdayExcChecked: saturdayExcChecked,
      isMeasurementRequired: isMeasurementRequired,
      holidaysExcChecked: holidaysExcChecked,
      planId: activeDraftPlanId,
      isConformityActive: isConformityActive,
      medicoes: measurements,
    };
    addActivity(_values)
      .then(() => {
        createSuccessToast(setToastList, 'Atividade adicionada.');
      })
      .catch(() => {
        setToastList(prevState => [
          ...prevState,
          createToast({
            type: 'error',
            message: 'Ocorreu um erro. Tente novamente.',
          }),
        ]);
      })
      .finally(() => {
        handleCloseModal();
      });

    // addActivity(formatMeasurements(_values));
  };
  const populateFieldsStrategy = async () => {
    if (companyId) {
      return await Promise.all([
        // FiltersService.getMaterials({
        //   companyId
        // }),
        FiltersService.getCategories({
          companyId,
        }),
        FiltersService.getMeasurementUnits({
          companyId,
        }),
      ]).then(values => {
        return {
          categories: values[0].data.map(({ nome, categoria }) => ({
            label: nome,
            value: categoria,
          })),
        };
      });
    }
  };

  const populateFields = async () => {
    try {
      const fieldsData = await populateFieldsStrategy();
      setFields(prevState => ({
        ...prevState,
        ...fieldsData,
      }));
    } catch (err) {
      console.error(err);
      createGenericErrorToast(setToastList);
    }
  };
  const fetchData = async () => {
    setGlobalComponentsContext(prevState => ({
      ...prevState,
      isLoading: true,
    }));

    // await cleanFields();
    await populateFields();
    setGlobalComponentsContext(prevState => ({
      ...prevState,
      isLoading: false,
    }));
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, [errors]);

  useEffect(() => {
    const MEASUREMENT_CATEGORY_KEY = 12;
    if (activeCategory === MEASUREMENT_CATEGORY_KEY) {
      setIsMeasurementActivity(true);
    } else {
      setMeasurements([
        {
          unidadeMedicao: '',
          valorMinimo: '',
          valorMaximo: '',
          valorPadrao: '',
        },
      ]);

      setIsMeasurementActivity(false);
    }
    // eslint-disable-next-line
  }, [activeCategory]);
  const watchSpecificDays = useWatch({
    name: ['specificDay', 'specificDays'],
    control,
  });
  useEffect(() => {
    const isSaturdaySelected =
      getValues('specificDays')?.some(day => day === 'sabado') || getValues('specificDay') === 'sabado';
    const isSundaySelected =
      getValues('specificDays')?.some(day => day === 'domingo') || getValues('specificDay') === 'domingo';

    if (isSaturdaySelected) {
      setOnlyConsiderBusinessDays(false);
      setSaturdayExcChecked(true);
    }
    if (isSundaySelected) {
      setOnlyConsiderBusinessDays(false);
      setSundayExcChecked(true);
    }

    // eslint-disable-next-line
  }, [watchSpecificDays, onlyConsiderBusinessDays]);

  const handleCloseModal = () => {
    reset();
    setIsAddActivityFormDirty(false);
    setIsAddActivityModalActive(false);
    setActiveCategory(undefined);
    setOnlyConsiderBusinessDays(true);
    setMeasurements([
      {
        unidadeMedicao: '',
        valorMinimo: '',
        valorMaximo: '',
        valorPadrao: '',
      },
    ]);
    setIsMeasurementActivity(false);
    setActiveFrequency(null);
    reset();
  };
  const isRepeatEveryActive = activeFrequency !== 'bimonthly' && activeFrequency !== 'biannual';
  const isSpecificDaysActive =
    activeFrequency !== 'daily' &&
    activeFrequency !== 'monthly' &&
    activeFrequency !== 'bimonthly' &&
    activeFrequency !== 'biannual' &&
    activeFrequency !== 'annual';
  const isSpecificDayActive = activeFrequency !== 'daily' && !isSpecificDaysActive;
  return (
    <StyledAddActivityFormModal closeModal={() => handleCloseModal()} open={isAddActivityModalActive}>
      <StyledAddActivityForm onSubmit={handleSubmit(onAddActivitySubmit)}>
        <StyledAddActivityFormModalHeader>
          <h3>Adicionar atividade</h3>
          <StyledCloseAddActivityFormModal onClick={() => handleCloseModal()}>
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M1 1L15 15" stroke="#BBBBBB" strokeWidth="2" strokeLinecap="round" />
              <path d="M15 1L1 15" stroke="#BBBBBB" strokeWidth="2" strokeLinecap="round" />
            </svg>
          </StyledCloseAddActivityFormModal>
        </StyledAddActivityFormModalHeader>
        <StyledFormGroup label="Descrição da atividade" isRequired>
          <Controller
            render={props => (
              <StyledTextarea
                type="text"
                {...props}
                isRequired
                autoComplete="off"
                name="description"
                maxLength="500"
                size="small"
                error={isAddActivityFormDirty && !props.value}
                errormessage="Campo obrigatório"
                placeholder="Escreva o que deve ser realizado na atividade."
              />
            )}
            name="description"
            control={control}
          />
        </StyledFormGroup>
        <StyledCategoryFormRow>
          <StyledCategoryFormGroup label="Categoria" isRequired>
            <Controller
              render={props => (
                <Select
                  {...props}
                  size="small"
                  placeholder="Seleção obrigatória"
                  options={fields?.categories}
                  error={isAddActivityFormDirty && !activeCategory}
                  onChange={e => {
                    setActiveCategory(e);
                    props.onChange(e);
                  }}
                  errormessage="Campo obrigatório"
                  isRequired
                />
              )}
              name="category"
              control={control}
            />
          </StyledCategoryFormGroup>

          <StyledStartDateFormGroup label="Data de início" isRequired>
            <Controller
              render={props => (
                <StyledDatePicker
                  {...props}
                  name="startDate"
                  size="small"
                  error={isAddActivityFormDirty && getValues('startDate')?.length === 0}
                  onChange={e => {
                    props.onChange(e);
                  }}
                  errormessage="Campo obrigatório"
                  placeholder="Seleção obrigatória"
                />
              )}
              name="startDate"
              control={control}
            />
          </StyledStartDateFormGroup>
          <StyledPriorityFormGroup isRequired label="Prioridade">
            <Controller
              render={props => (
                <Select
                  {...props}
                  size="small"
                  isSearchDisabled
                  isRequired
                  placeholder="Selecione"
                  name="priority"
                  options={fields?.priorities}
                />
              )}
              name="priority"
              control={control}
            />
          </StyledPriorityFormGroup>
          <Controller
            render={props => (
              <StyledTimeFormGroup error={isAddActivityFormDirty && !props.value} isRequired label="Hora de início">
                <StyledTimeInput
                  {...props}
                  type="text"
                  mask={startTimeMask}
                  beforeMaskedStateChange={beforeMaskedStateChange}
                  isRequired
                  autoComplete="off"
                  error={isAddActivityFormDirty && !props.value}
                  errormessage="Campo obrigatório"
                  name="startTime"
                  size="small"
                  placeholder="00:00"
                />
              </StyledTimeFormGroup>
            )}
            name="startTime"
            control={control}
          />

          <Controller
            render={props => (
              <StyledTimeFormGroup error={isAddActivityFormDirty && !props.value} isRequired label="Tempo previsto">
                <StyledEstimatedTimeInput
                  {...props}
                  type="text"
                  mask={timeMask}
                  error={isAddActivityFormDirty && !props.value}
                  errormessage="Campo obrigatório"
                  isRequired
                  autoComplete="off"
                  name="estimatedTime"
                  size="small"
                  placeholder="00:00"
                />
              </StyledTimeFormGroup>
            )}
            name="estimatedTime"
            control={control}
          />
        </StyledCategoryFormRow>

        <StyledMeasurementsFormRow isActive={isMeasurementActivity}>
          <StyledMeasurementsRadioGroup isActive={isMeasurementActivity} label="Medições">
            <span>Tornar medição obrigatória para a baixa da atividade?</span>

            <Radio
              text="Sim"
              name="isMeasurementRequired"
              value={true}
              onChange={() => {}}
              onClick={() => setIsMeasurementRequired(true)}
              checked={isMeasurementRequired}
            />

            <Radio
              text="Não"
              type="radio"
              name="isMeasurementRequired"
              value={false}
              onChange={() => {}}
              onClick={() => setIsMeasurementRequired(false)}
              checked={!isMeasurementRequired}
            />
          </StyledMeasurementsRadioGroup>
          <StyledMeasurementsContainer isActive={isMeasurementActivity}>
            {measurements.map((measurementUnit, i) => (
              <StyledMeasurementUnitRow isFirst={i === 0} isLast={i === measurements.length - 1} key={i}>
                <StyledMeasurementsFormGroup isRequired label="Unidade de medida">
                  <Controller
                    render={props => (
                      <PaginatedSelect
                        size="small"
                        isRequired
                        error={isAddActivityFormDirty && !props.value}
                        errormessage="Campo obrigatório"
                        placeholder="Seleção obrigatória"
                        headers={{ EmpresaId: companyId }}
                        name={`measurementUnit${i}`}
                        // params={{ planoGeral: 3 }}
                        value={isMeasurementActivity ? props.value : ''}
                        onChange={value => {
                          let newMeasurements = measurements;
                          let newValue = value ? parseInt(value) : '';
                          newMeasurements[i].unidadeMedicao = newValue;
                          props.onChange(newValue);
                          setMeasurements(newMeasurements);
                        }}
                        adapter={data => {
                          return data.map(units => {
                            return {
                              label: units.nome,
                              value: units.unidadeMedicao,
                            };
                          });
                        }}
                        url="/v1/UnidadesMedicoes"
                        {...register(`measurementUnit${i}`)}
                      />
                    )}
                    name={`measurementUnit${i}`}
                    control={control}
                  />
                </StyledMeasurementsFormGroup>
                <Controller
                  render={props => (
                    <StyledMeasurementsInput
                      {...props}
                      type="text"
                      error={isAddActivityFormDirty && measurements[i].valorMinimo === ''}
                      isRequired
                      label="Mínimo"
                      errormessage="Campo obrigatório"
                      value={isMeasurementActivity ? measurements[i].valorMinimo : ''}
                      autoComplete="off"
                      onChange={e => {
                        const newMeasurements = measurements;
                        const parsedValue = parseInt(e.target.value);
                        const isInteger = Number.isInteger(parsedValue);
                        const newValue = isInteger ? parsedValue : '';
                        newMeasurements[i].valorMinimo = newValue;
                        isInteger ? props.onChange(newValue) : props.onChange('');
                        setMeasurements(newMeasurements);
                      }}
                      name={`measurementMin${i}`}
                      size="small"
                      placeholder=""
                      {...register(`measurementMin${i}`)}
                    />
                  )}
                  name={`measurementMin${i}`}
                  control={control}
                />
                <Controller
                  render={props => (
                    <StyledMeasurementsInput
                      {...props}
                      type="text"
                      value={isMeasurementActivity ? measurements[i].valorMaximo : ''}
                      isRequired
                      label="Máximo"
                      error={isAddActivityFormDirty && measurements[i].valorMaximo === ''}
                      onChange={e => {
                        const newMeasurements = measurements;
                        const parsedValue = parseInt(e.target.value);
                        const isInteger = Number.isInteger(parsedValue);
                        const newValue = isInteger ? parsedValue : '';
                        newMeasurements[i].valorMaximo = newValue;
                        isInteger ? props.onChange(newValue) : props.onChange('');
                        setMeasurements(newMeasurements);
                      }}
                      errormessage="Campo obrigatório"
                      autoComplete="off"
                      name={`measurementMax${i}`}
                      size="small"
                      placeholder=""
                      {...register(`measurementMax${i}`)}
                    />
                  )}
                  name={'measurementMax' + i}
                  control={control}
                />
                <Controller
                  render={props => (
                    <StyledMeasurementsInput
                      {...props}
                      type="text"
                      isRequired
                      label="Padrão"
                      error={isAddActivityFormDirty && measurements[i].valorPadrao === ''}
                      value={isMeasurementActivity ? measurements[i].valorPadrao : ''}
                      onChange={e => {
                        const newMeasurements = measurements;
                        const parsedValue = parseInt(e.target.value);
                        const isInteger = Number.isInteger(parsedValue);
                        const newValue = isInteger ? parsedValue : '';
                        newMeasurements[i].valorPadrao = newValue;
                        isInteger ? props.onChange(newValue) : props.onChange('');
                        setMeasurements(newMeasurements);
                      }}
                      errormessage="Campo obrigatório"
                      autoComplete="off"
                      name={`measurementAverage${i}`}
                      size="small"
                      placeholder=""
                      {...register(`measurementAverage${i}`)}
                    />
                  )}
                  name={'measurementAverage' + i}
                  control={control}
                />
                {measurements.length > 1 && (
                  <StyledDeleteMeasurementUnit onClick={() => handleDeleteMeasurement(i)}>
                    X
                  </StyledDeleteMeasurementUnit>
                )}
              </StyledMeasurementUnitRow>
            ))}
          </StyledMeasurementsContainer>
          {isMeasurementActivity && (
            <StyledAddMeasurementUnitButton type="button" onClick={() => handleAddMeasurementUnit()}>
              <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1 6H11" stroke={colors.primary} strokeWidth="2" strokeLinecap="round" />
                <path d="M6 1V11" stroke={colors.primary} strokeWidth="2" strokeLinecap="round" />
              </svg>
              <span>Adicionar mais uma medição</span>
            </StyledAddMeasurementUnitButton>
          )}
        </StyledMeasurementsFormRow>
        <StyledFormRow>
          <FrequencyInputsRow>
            <StyledFormGroup label="Frequência" isRequired>
              <Controller
                render={props => (
                  <Select
                    {...props}
                    size="small"
                    name="frequency"
                    onChange={e => {
                      props.onChange(e);
                      console.error(e);
                      setActiveFrequency(e);
                    }}
                    isRequired
                    error={isAddActivityFormDirty && !activeFrequency}
                    errormessage="Campo obrigatório"
                    placeholder="Seleção obrigatória"
                    options={fields?.frequencies}
                  />
                )}
                name="frequency"
                control={control}
              />
            </StyledFormGroup>
            {activeFrequency && activeFrequency !== 'none' && (
              <>
                {isRepeatEveryActive && (
                  <StyledFormGroup label="Repete a cada">
                    <Controller
                      render={props => (
                        <Input
                          {...props}
                          name="repeatEvery"
                          {...register('repeatEvery')}
                          size="small"
                          placeholder="Seleção obrigatória"
                        />
                      )}
                      name="repeatEvery"
                      defaultValue={1}
                      control={control}
                    />
                  </StyledFormGroup>
                )}

                <FrequencyInterval isEmpty={activeFrequency === 'bimonthly' || activeFrequency === 'biannual'}>
                  {activeFrequency === 'daily'
                    ? 'Dia(s)'
                    : activeFrequency === 'weekly'
                    ? 'Semana(s)'
                    : activeFrequency === 'monthly'
                    ? 'Mês(es)'
                    : activeFrequency === 'annual'
                    ? 'Ano(s)'
                    : ''}
                </FrequencyInterval>
                <StyledFormGroup isActive={isSpecificDayActive} label="Dia específico">
                  <Controller
                    render={props => (
                      <Select {...props} size="small" placeholder="Seleção opcional" options={fields?.specificDays} />
                    )}
                    name="specificDay"
                    control={control}
                  />
                </StyledFormGroup>
                {activeFrequency === 'weekly' ? (
                  <StyledFormGroup isActive={isSpecificDaysActive} isRequired label="Dia(s) específico(s)">
                    <Controller
                      render={props => (
                        <MultiSelect
                          {...props}
                          size="small"
                          error={isAddActivityFormDirty && (!props.value || props.value.length === 0)}
                          errormessage="Campo obrigatório"
                          isRequired
                          placeholder="Seleção obrigatória"
                          name="specificDays"
                          options={fields?.specificDays}
                          {...register('specificDays')}
                        />
                      )}
                      name="specificDays"
                      control={control}
                    />
                  </StyledFormGroup>
                ) : (
                  <StyledFormGroup isActive={isSpecificDaysActive} label="Dia(s) específico(s)">
                    <Controller
                      render={props => (
                        <MultiSelect
                          {...props}
                          size="small"
                          placeholder="Seleção opcional"
                          name="specificDays"
                          options={fields?.specificDays}
                          {...register('specificDays')}
                        />
                      )}
                      name="specificDays"
                      control={control}
                    />
                  </StyledFormGroup>
                )}
              </>
            )}
          </FrequencyInputsRow>
        </StyledFormRow>
        <StyledFormRow>
          <StyledRadioGroup label="Considerar apenas dias úteis?">
            <Radio
              text="Sim"
              name="onlyConsiderBusinessDays"
              value={true}
              onChange={() => {
                setSaturdayExcChecked(false);
                setHolidaysExcChecked(false);
                setSundayExcChecked(false);
                setOnlyConsiderBusinessDays(true);
              }}
              checked={onlyConsiderBusinessDays === true}
            />
            <Radio
              text="Não"
              type="radio"
              name="onlyConsiderBusinessDays"
              value={false}
              checked={onlyConsiderBusinessDays === false}
              onChange={() => setOnlyConsiderBusinessDays(false)}
            />
            <StyledOtherDaysConsideredContainer isActive={onlyConsiderBusinessDays === false}>
              <svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1 1L6 6L1 11" stroke="#BBBBBB" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
              </svg>

              <StyledCheckbox
                name="saturdayExcChecked"
                text="Sábados"
                checked={saturdayExcChecked}
                onChange={() => {
                  setSaturdayExcChecked(!saturdayExcChecked);
                }}
              />

              <StyledCheckbox
                name="sundayExcChecked"
                text="Domingos"
                checked={sundayExcChecked}
                onChange={() => {
                  setSundayExcChecked(!sundayExcChecked);
                }}
              />

              <StyledCheckbox
                name="holidaysExcChecked"
                text="Feriados"
                checked={holidaysExcChecked}
                onChange={() => {
                  setHolidaysExcChecked(!holidaysExcChecked);
                }}
              />
            </StyledOtherDaysConsideredContainer>
          </StyledRadioGroup>
        </StyledFormRow>
        <StyledFormRow>
          <StyledConformityRadioGroup label="Habilitar conformidade na baixa da atividade?">
            <InformativeTooltip
              message={`Ao marcar "Sim" o campo "Conformidade" será exibido ao executor na baixa da atividade.`}
            />
            <Radio text="Sim" value={true} checked={isConformityActive} onChange={() => setIsConformityActive(true)} />
            <Radio
              text="Não"
              type="radio"
              value={false}
              onChange={() => setIsConformityActive(false)}
              checked={!isConformityActive}
            />
          </StyledConformityRadioGroup>
        </StyledFormRow>

        {/* <StyledFormGroup label="Material">
        <Controller
          render={(props) => (
            <Select
              {...props}
              {...register('material')}
              size="small"
              placeholder="Selecionar setor"
              options={fields?.materials}
            />
          )}
          name="material"
          control={control}
        />
      </StyledFormGroup> */}
        <StyledAddActivityButton type="submit">
          <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="6" cy="6" r="6" fill="white" />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M5.53651 8.81249C5.28206 9.06246 4.85747 9.06246 4.60302 8.81249L2.19023 6.4422C1.93659 6.19303 1.93659 5.77838 2.19023 5.5292L2.71349 5.01517C2.96778 4.76536 3.37748 4.7652 3.63196 5.0147L5.07616 6.4209L8.36756 3.18747C8.622 2.93751 9.03206 2.93751 9.2865 3.18747L9.80976 3.70151C10.0634 3.95069 10.0634 4.36534 9.80976 4.61451L5.53651 8.81249Z"
              fill={colors.primary}
            />
          </svg>
          Adicionar atividade
        </StyledAddActivityButton>
      </StyledAddActivityForm>
    </StyledAddActivityFormModal>
  );
}

AddActivityFormModal.propTypes = {};

export default AddActivityFormModal;
