import { useCallback, useEffect } from 'react';
import { HeaderFilterService } from 'services/main';
import { FiltersService } from 'services/reports';

export const useFilterFields = ({
  handleUpdateFields,
  companyId,
  unitId,
  unitGroupId,
  unitSubgroupId,
  stateId,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  cityId,
}) => {
  const populateFieldsStrategy = useCallback(async () => {
    if (companyId) {
      if (!unitId) {
        return await Promise.all([
          FiltersService.getUnitGroups({
            companyId,
            ids: unitGroupId && [unitGroupId],
          }),
          FiltersService.getUnitSubgroups({
            companyId,
            unitGroupIds: unitGroupId && [unitGroupId],
          }),
          FiltersService.getUnits({
            companyId,
            unitGroupIds: unitGroupId && [unitGroupId],
            unitSubgroupIds: unitSubgroupId && [unitSubgroupId],
            stateIds: stateId && [stateId],
          }),
          FiltersService.getStates({
            companyId,
            unitGroupIds: unitGroupId && [unitGroupId],
            unitSubgroupIds: unitSubgroupId && [unitSubgroupId],
          }),
          HeaderFilterService.cities({
            empresaId: companyId,
            estadoId: stateId && stateId,
          }),
          // TODO: REPLACE WHEN UNITS MOCK REMOVED
          FiltersService.getPriorities({ companyId, unitIds: 9676 }),
          FiltersService.getPriorities({ companyId, unitIds: 9676 }),
          // FiltersService.getStatuses({ companyId, ids: unitId && [unitId] })

          // FiltersService.getPriorities({ companyId, unitIds: unitId && [unitId] })
        ]).then(values => ({
          unitGroups: values[0].data.map(({ name, id }) => ({
            label: name,
            value: id,
          })),
          unitSubgroups: values[1].data.map(({ name, id }) => ({
            label: name,
            value: id,
          })),
          units: values[2].data.map(({ name, id }) => ({
            label: name,
            value: id,
          })),
          states: values[3].data.map(({ name, id }) => ({
            label: name,
            value: id,
          })),
          cities: values[4].data.map(({ name, id }) => ({
            label: name,
            value: id,
          })),
          priorities: values[5].data.map(({ description, id }) => ({
            label: description,
            value: id,
          })),
          statuses: values[6].data.map(({ description, id }) => ({
            label: description,
            value: id,
          })),
        }));
      }

      return await Promise.all([
        FiltersService.getUnitGroups({
          companyId,
          ids: unitGroupId && [unitGroupId],
        }),
        FiltersService.getUnitSubgroups({
          companyId,
          unitGroupIds: unitGroupId && [unitGroupId],
        }),
        FiltersService.getUnits({
          companyId,
          unitGroupIds: unitGroupId && [unitGroupId],
          unitSubgroupIds: unitSubgroupId && [unitSubgroupId],
          stateIds: stateId && [stateId],
        }),
        FiltersService.getStates({
          companyId,
          unitGroupIds: unitGroupId && [unitGroupId],
          unitSubgroupIds: unitSubgroupId && [unitSubgroupId],
        }),
        HeaderFilterService.cities({
          empresaId: companyId,
          estadoId: stateId && stateId,
        }),
        // TODO: REPLACE WHEN UNITS MOCK REMOVED
        FiltersService.getPriorities({ companyId, unitIds: 9676 }),
        FiltersService.getPriorities({ companyId, unitIds: 9676 }),

        // FiltersService.getStatuses({ companyId, ids: unitId && [unitId] })
        // FiltersService.getPriorities({ companyId, unitIds: unitId && [unitId] })
      ]).then(values => ({
        unitGroups: values[0].data.map(({ name, id }) => ({
          label: name,
          value: id,
        })),
        unitSubgroups: values[1].data.map(({ name, id }) => ({
          label: name,
          value: id,
        })),
        units: values[2].data.map(({ name, id }) => ({
          label: name,
          value: id,
        })),
        states: values[3].data.map(({ name, id }) => ({
          label: name,
          value: id,
        })),
        cities: values[4].data.map(({ name, id }) => ({
          label: name,
          value: id,
        })),
        priorities: values[5].data.map(({ description, id }) => ({
          label: description,
          value: id,
        })),
        statuses: values[6].data.map(({ description, id }) => ({
          label: description,
          value: id,
        })),
      }));
    }
  }, [companyId, unitGroupId, unitId, unitSubgroupId, stateId]);

  useEffect(() => {
    const populateFields = async () => {
      try {
        const fieldsData = await populateFieldsStrategy();
        handleUpdateFields(fieldsData);
      } catch (err) {
        console.error(err);
      }
    };

    const fetchData = async () => {
      // await cleanFields();
      await populateFields();
    };
    fetchData();
    // eslint-disable-next-line
  }, [populateFieldsStrategy, stateId, unitGroupId, unitId, unitSubgroupId]);
};
