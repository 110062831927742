import styled from 'styled-components';

import { colors, medias } from 'assets/styled/tokens';
import { StyledSubCategory } from '../Subcategory/styled';

export const StyledSubCategoryItem = styled.li`
  display: block;
  list-style: none;
  width: auto;
  white-space: nowrap;
  padding: 0.25rem 0;
  background-color: ${colors.pampas};
  transition: background-color ease-in-out 210ms;
  cursor: ${props => (props.disabled ? 'none' : 'pointer')};
  pointer-events: ${props => props.disabled && 'none'};
  a {
    color: ${props => props.disabled && colors.silver} !important;
  }
  @media screen and (min-width: ${medias.desktopSm}) {
    padding: 0.25rem 1rem;

    &:hover {
      background-color: rgba(0, 0, 0, 0.05);
      //background-color: ${props => (props.disabled ? 'transparent' : colors.pampas)};
    }

    &:hover ${StyledSubCategory} {
      display: block;
    }
  }
`;
