import { Loading } from 'components/Loading';
import { GlobalComponentsContext } from 'context/GlobalComponentsContext';
import React, { useContext, useMemo, useState, useEffect } from 'react';
import { AuthContext } from 'context/AuthContext';
import {
  PageTitle,
  ScheduleMonitoringPage,
  ScheduleMonitoringPageContainer,
  StyledContainerHeader,
  TablecontainerWrapper,
  UpdatePlansButton,
  StyledContainerFilters,
  StyledContainerFooter,
  LeftContainerFooter,
  RightContainerFooter,
  FilterButton,
  FilterRowContainer,
  StyledContainerSearchBar,
  CleanFiltersButton,
  Paginate,
} from './styled';
import { colors } from 'assets/styled/tokens';
import { Controller, useForm } from 'react-hook-form';
import { Menu } from 'components/MenuAdmin';
import ScheduleTable from 'components/Tables/ScheduleTable';
import { Select } from 'components/Tables/ScheduleTable/Form/subcomponents/Select';
import { FieldRow } from 'components/Tables/ScheduleTable/Form/subcomponents/FieldRow';
import { FormGroup } from 'components/Tables/ScheduleTable/Form/subcomponents/FormGroup';
import { Toast } from 'components';
import { createGenericErrorToast } from 'utils/createToast';
import { ScheduleMonitoringService } from 'services/schedules';
import { CompanyService } from 'services/main';

const AdminScheduleMonitoring = () => {
  const [isFirstMount, setIsFirstMount] = useState(true);
  const { globalComponentsContext, setGlobalComponentsContext } = useContext(GlobalComponentsContext);
  const [search, setSearch] = useState('');
  const [itensPerPage, setItensPerPage] = useState(0);
  const [pageNumber, setPageNumber] = useState(0);
  const [toastList, setToastList] = useState([]);
  const { isLoading } = globalComponentsContext;
  const { authContext } = useContext(AuthContext);
  const [filterOptions, setFilterOptions] = useState({
    open: false,
  });
  const {
    setValue,
    getValues,
    control,
    formState: { errors },
  } = useForm();

  const [fields, setFields] = useState({
    plans: [],
  });
  const [filters, setFilters] = useState({
    companies: [],
    units: [],
  });

  const getDate = data => {
    const dateString = new Date(data);
    const date = dateString.toLocaleDateString();
    const time = dateString.toLocaleTimeString();
    return `${date} • ${time} `;
  };
  const [companyId, setCompanyId] = useState('');
  const populateTableStrategy = async () => {
    const empresa = companyId;
    const status = getValues('status');
    const unidade = getValues('unity');
    const { token } = authContext;
    return await Promise.all([
      ScheduleMonitoringService.getPlansAdmin({
        empresa,
        token,
        unidade,
        status,
      }),
    ]).then(values => ({
      plans: values[0].data.map(({ nomePlano, nomeEmpresa, nomeUnidade, data, status, planoAtividade }) => ({
        plano: nomePlano,
        empresa: nomeEmpresa,
        unidade: nomeUnidade,
        data: getDate(data),
        status,
        planoAtividade,
      })),
    }));
  };
  const populateTable = async () => {
    try {
      const tableData = await populateTableStrategy();
      tableData?.plans?.reverse();
      setFields(prevState => ({
        ...prevState,
        ...tableData,
      }));
    } catch (err) {
      createGenericErrorToast(setToastList);
    }
  };
  const fetchData = async () => {
    setGlobalComponentsContext(prevState => ({
      ...prevState,
      isLoading: true,
    }));
    try {
      await populateTable();
    } catch (err) {
      createGenericErrorToast(setToastList);
    } finally {
      setGlobalComponentsContext(prevState => ({
        ...prevState,
        isLoading: false,
      }));
    }
  };
  const updatePlansList = () => {
    fetchData();
  };
  const populateFiltersStrategy = async () => {
    setCompanyId(getValues('company'));
    if (!companyId) {
      return await Promise.all([CompanyService.getAll()]).then(values => ({
        companies: values[0].data.map(({ id, name }) => ({
          value: id,
          label: name,
        })),
      }));
    } else {
      return await Promise.all([
        CompanyService.getAll(),
        ScheduleMonitoringService.units({ empresaId: companyId }),
      ]).then(values => ({
        companies: values[0].data.map(({ id, name }) => ({
          value: id,
          label: name,
        })),
        units: values[1].data.map(({ site, nome }) => ({
          value: site,
          label: nome,
        })),
      }));
    }
  };
  const populateFilters = async () => {
    try {
      const filtersData = await populateFiltersStrategy();
      setFilters(prevState => ({
        ...prevState,
        ...filtersData,
      }));
    } catch (err) {
      createGenericErrorToast(setToastList);
    }
  };
  const fetchFilters = async () => {
    setGlobalComponentsContext(prevState => ({
      ...prevState,
      isLoading: true,
    }));
    try {
      await populateFilters();
    } catch (err) {
      createGenericErrorToast(setToastList);
    } finally {
      setGlobalComponentsContext(prevState => ({
        ...prevState,
        isLoading: false,
      }));
    }
  };
  const statusOptions = [
    {
      value: 1,
      label: 'Não Agendado',
    },
    {
      value: 2,
      label: 'Agendamento sendo gerado',
    },
    {
      value: 3,
      label: 'Ocorreu um erro',
    },
    {
      value: 4,
      label: 'Agendado com sucesso',
    },
    {
      value: 5,
      label: 'Aguardando Agendamento',
    },
    {
      value: 6,
      label: 'Agendamento Cancelado',
    },
  ];
  useEffect(() => {
    fetchFilters();
    // eslint-disable-next-line
  }, [companyId]);
  useEffect(() => {
    fetchData();
    setIsFirstMount(false);
    getItensPerPage();
    // eslint-disable-next-line
  }, [errors, itensPerPage, companyId]);

  const columns = useMemo(
    () => [
      { Header: 'Plano', accessor: 'plano' },
      { Header: 'Empresa', accessor: 'empresa' },
      {
        Header: 'Unidade de manutenção',
        accessor: 'unidade',
      },
      { Header: 'Data/hora da solicitação', accessor: 'data' },
      { Header: 'Status de Agendamento', accessor: 'status' },
    ],
    [],
  );
  const pageOptions = [
    { label: '5', value: 5 },
    { label: '10', value: 10 },
    { label: '25', value: 25 },
    { label: '50', value: 50 },
    { label: '100', value: 100 },
  ];

  const openCloseFilters = () => {
    setFilterOptions({
      open: !filterOptions.open,
    });
  };
  const getItensPerPage = () => {
    setItensPerPage(getValues('pageItens'));
  };
  const pageVisited = pageNumber * itensPerPage;
  const displayData1 =
    search === ''
      ? fields.plans.slice(pageVisited, pageVisited + itensPerPage)
      : fields.plans.filter(plan => plan.plano.includes(search)).slice(pageVisited, pageVisited + itensPerPage);
  const pageCountSearch = fields.plans.filter(plan => plan.plano.includes(search));
  const pageCount1 =
    search === '' ? Math.ceil(fields.plans.length / itensPerPage) : Math.ceil(pageCountSearch.length / itensPerPage);
  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };
  const handleSearch = event => {
    setSearch(event.target.value);
  };
  const resetStatus = () => {
    setValue('status', '');
  };
  const cleanFilters = () => {
    setValue('company', '');
    setValue('unity', '');
    setValue('status', '');
    setCompanyId('');
    setSearch('');
    fetchFilters();
    setFields({ plans: [] });
  };
  return (
    <>
      {isLoading && <Loading />}

      <ScheduleMonitoringPage background={colors.pampas}>
        <Menu></Menu>
        <ScheduleMonitoringPageContainer>
          <PageTitle>Agendamento de planos</PageTitle>
          <TablecontainerWrapper>
            <StyledContainerHeader>
              <StyledContainerFilters>
                <FilterButton onClick={openCloseFilters}>
                  {filterOptions.open ? (
                    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M0.545776 0H1.20923L1.56335 0.354126L1.56417 0.353312L11.9831 10.7722L10.9931 11.7622L7.63668 8.40573V11.4545C7.63668 11.6761 7.50032 11.8722 7.3043 11.9574C7.23612 11.983 7.15941 12 7.09123 12C6.94634 12 6.80998 11.9489 6.70771 11.8381L4.52589 9.65625C4.42362 9.55398 4.36396 9.41761 4.36396 9.27273V5.133L0.574219 1.34326L0.575378 1.3421L0.162253 0.928977C0.000321042 0.775568 -0.0422926 0.536932 0.0429347 0.332386C0.128162 0.136363 0.324185 0 0.545776 0ZM11.8384 0.928977L7.97825 4.78912L3.18913 0H11.4549C11.6765 0 11.8725 0.136363 11.9577 0.332386C12.0429 0.536932 12.0003 0.775568 11.8384 0.928977Z"
                        fill="#BBBBBB"
                      />
                    </svg>
                  ) : (
                    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M11.9577 0.332386C11.8725 0.136363 11.6765 0 11.4549 0H0.545776C0.324185 0 0.128162 0.136363 0.0429347 0.332386C-0.0422926 0.536932 0.000321041 0.775568 0.162253 0.928977L4.36396 5.13068V9.27273C4.36396 9.41761 4.42362 9.55398 4.52589 9.65625L6.70771 11.8381C6.80998 11.9489 6.94634 12 7.09123 12C7.15941 12 7.23612 11.983 7.3043 11.9574C7.50032 11.8722 7.63668 11.6761 7.63668 11.4545V5.13068L11.8384 0.928977C12.0003 0.775568 12.0429 0.536932 11.9577 0.332386Z"
                        fill="#BBBBBB"
                      />
                    </svg>
                  )}
                  FILTROS
                </FilterButton>
                {filterOptions.open && (
                  <CleanFiltersButton onClick={cleanFilters}>
                    <svg width="10" height="10" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M0.229221 0.229221C0.534848 -0.0764069 1.03037 -0.0764069 1.336 0.229221L11.7708 10.664C12.0764 10.9696 12.0764 11.4652 11.7708 11.7708C11.4652 12.0764 10.9696 12.0764 10.664 11.7708L0.229221 1.336C-0.0764069 1.03037 -0.0764069 0.534848 0.229221 0.229221Z"
                        fill="#EC6655"
                        stroke="#EC6655"
                        strokeWidth="1.2px"
                      />
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M11.7708 0.229221C11.4652 -0.0764069 10.9696 -0.0764069 10.664 0.229221L0.229221 10.664C-0.0764065 10.9696 -0.0764065 11.4652 0.229221 11.7708C0.534849 12.0764 1.03037 12.0764 1.336 11.7708L11.7708 1.336C12.0764 1.03037 12.0764 0.534848 11.7708 0.229221Z"
                        fill="#EC6655"
                        stroke="#EC6655"
                        strokeWidth="1.2px"
                      />
                    </svg>
                    LIMPAR FILTROS
                  </CleanFiltersButton>
                )}
              </StyledContainerFilters>
              <UpdatePlansButton onClick={updatePlansList}>ATUALIZAR LISTA</UpdatePlansButton>
            </StyledContainerHeader>
            {filterOptions.open && (
              <FilterRowContainer>
                <FieldRow>
                  <FormGroup label="Empresa">
                    <Controller
                      render={props => (
                        <Select
                          {...props}
                          $filter={true}
                          size="small"
                          placeholder="Seleção opcional"
                          options={filters?.companies}
                          setValue={setValue}
                          defaultValue={isFirstMount ? props.defaultValue : ''}
                          onChange={value => {
                            props.onChange(value);
                            fetchFilters();
                            fetchData();
                            setValue('unity', '');
                          }}
                          ref={null}
                        />
                      )}
                      name={'company'}
                      control={control}
                      defaultValue={''}
                    />
                  </FormGroup>
                </FieldRow>
                <FieldRow>
                  <FormGroup label="Unidade">
                    <Controller
                      render={props => (
                        <Select
                          {...props}
                          $filter={true}
                          size="small"
                          options={filters?.units}
                          placeholder="Seleção opcional"
                          setValue={setValue}
                          defaultValue={isFirstMount ? props.defaultValue : ''}
                          onChange={value => {
                            props.onChange(value);
                            fetchData();
                          }}
                          ref={null}
                        />
                      )}
                      name={'unity'}
                      control={control}
                      defaultValue={''}
                    />
                  </FormGroup>
                </FieldRow>
                <FieldRow>
                  <FormGroup label="Plano">
                    <StyledContainerSearchBar
                      placeholder="Buscar Plano"
                      $filter={true}
                      onChange={handleSearch}
                      value={search}
                    />
                  </FormGroup>
                </FieldRow>
                <FieldRow>
                  <FormGroup label="Status de agendamento">
                    <Controller
                      render={props => (
                        <Select
                          {...props}
                          $filter={true}
                          size="small"
                          options={statusOptions}
                          placeholder="Seleção opcional"
                          setValue={setValue}
                          cleanFields={resetStatus}
                          defaultValue={isFirstMount ? props.defaultValue : ''}
                          onChange={value => {
                            props.onChange(value);
                            fetchData();
                          }}
                          ref={null}
                        />
                      )}
                      name={'status'}
                      control={control}
                      defaultValue={''}
                    />
                  </FormGroup>
                </FieldRow>
              </FilterRowContainer>
            )}
            <ScheduleTable columns={columns} data={displayData1} fetchData={fetchData} setToastList={setToastList} />
            <StyledContainerFooter>
              <LeftContainerFooter>
                <h3>Itens por página:</h3>
                <FieldRow marginSides="16px">
                  <Controller
                    render={props => (
                      <Select
                        {...props}
                        size="small"
                        options={pageOptions}
                        setValue={setValue}
                        defaultValue={isFirstMount ? props.defaultValue : 5}
                        onChange={value => {
                          props.onChange(value);
                          setPageNumber(0);
                          getItensPerPage();
                        }}
                        ref={null}
                      />
                    )}
                    name={'pageItens'}
                    control={control}
                    defaultValue={pageOptions[0].value}
                  />
                </FieldRow>
                <h4>Total de {search === '' ? fields.plans.length : pageCountSearch.length} itens</h4>
              </LeftContainerFooter>
              <RightContainerFooter>
                <Paginate
                  previousLabel={
                    <svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M5 1L1 5L5 9"
                        stroke="#AAAAAA"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  }
                  nextLabel={
                    <svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M1 1L5 5L1 9"
                        stroke="#AAAAAA"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  }
                  pageCount={pageCount1}
                  onPageChange={changePage}
                  breakLabel={'...'}
                  marginPagesDisplayed={1}
                  pageRangeDisplayed={4}
                />
              </RightContainerFooter>
            </StyledContainerFooter>
          </TablecontainerWrapper>
        </ScheduleMonitoringPageContainer>
      </ScheduleMonitoringPage>
      <Toast toastList={toastList} autoDelete dismissTime={7000} />
    </>
  );
};

export { AdminScheduleMonitoring };
