import React, { useEffect, useMemo } from 'react';
import { useTable, useSortBy, usePagination, useFilters, useGlobalFilter } from 'react-table';
import { colors } from 'assets/styled/tokens';

import { StyledActivitiesHeader } from './styled';
import {
  StyledTableContainer,
  StyledTableHead,
  StyledTableRow,
  StyledTableHeader,
  StyledTableCell,
  StyledAddActivityButton,
  StyledControlsContainer,
  StyledTableBody,
} from './styled';
import { ArrowToggle } from 'components/ArrowToggle';
import PaginationControls from '../PaginationControls';
// Define a default UI for filtering

export default function GeneralPlanActivities({
  columns,
  data,
  fetchData,
  setIsAddActivityModalActive,
  setActivitiesTablePageSize: _setActivitiesTablePageSize,
  pageIndex,
  setPageIndex,
  activitiesTablePageSize,
  planId,
  hasActivitiesUpdated,
  setHasActivitiesUpdated,
  totalCount,
}) {
  const pageCount = Math.ceil(totalCount / activitiesTablePageSize);
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    setPageSize,
    state: { pageSize, sortBy },
    prepareRow,
  } = useTable(
    {
      columns,
      data,
      manualPagination: true,
      manualSortBy: true,
      autoResetPage: false,
      autoResetSortBy: false,
      initialState: { pageSize: activitiesTablePageSize },
    },
    useFilters, // useFilters!
    useGlobalFilter,
    useSortBy,
    usePagination,
  );
  useEffect(() => {
    if (planId) {
      fetchData({ pageSize: activitiesTablePageSize, sortBy });
    }
    if (setHasActivitiesUpdated) {
      setHasActivitiesUpdated(false);
    }
    // setGlobalFilter(globalSearchFilter);
    // setFilter('application', applicationFilter);
  }, [
    planId,
    sortBy,
    fetchData,
    pageIndex,
    pageSize,
    hasActivitiesUpdated,
    setHasActivitiesUpdated,
    setPageSize,
    activitiesTablePageSize,
  ]);

  const isPaginationContainerActive = useMemo(() => {
    return totalCount > 5;
  }, [totalCount]);
  const setActivitiesTablePageSize = size => {
    setPageSize(size);
    _setActivitiesTablePageSize(size);
  };

  if (data.length > 0) {
    return (
      <>
        <StyledControlsContainer>
          <StyledActivitiesHeader>Atividades</StyledActivitiesHeader>{' '}
          <StyledAddActivityButton onClick={() => setIsAddActivityModalActive(true)} size="small" type="submit">
            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M1 6H11" stroke={colors.primary} strokeWidth="2" strokeLinecap="round" />
              <path d="M6 1V11" stroke={colors.primary} strokeWidth="2" strokeLinecap="round" />
            </svg>
            Adicionar atividade
          </StyledAddActivityButton>
          {/* <StyledToggleFiltersButton
          onClick={() => setIsFiltersActive(!isFiltersActive)}
        >
          Filtros
          <svg
            width="12"
            height="6"
            viewBox="0 0 12 6"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M6 6L0 0H12L6 6Z" fill="#F5F3F1" />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M0.130033 0.163667C0.315787 -0.0406617 0.632011 -0.05572 0.83634 0.130033L6 4.82427L11.1637 0.130033C11.368 -0.05572 11.6842 -0.0406617 11.87 0.163667C12.0557 0.367996 12.0407 0.68422 11.8363 0.869974L6.33634 5.86997C6.14563 6.04335 5.85438 6.04335 5.66367 5.86997L0.163667 0.869974C-0.0406617 0.68422 -0.05572 0.367996 0.130033 0.163667Z"
              fill="#D0D0D0"
            />
          </svg>
        </StyledToggleFiltersButton> */}
          <PaginationControls
            isActive={isPaginationContainerActive}
            pageIndex={pageIndex}
            totalCount={totalCount}
            pageCount={pageCount}
            setPageIndex={setPageIndex}
            setPageSize={setActivitiesTablePageSize}
            pageSize={activitiesTablePageSize}
            itemsPerPageRange={[5, 10, 20, 50, 100]}
          />
        </StyledControlsContainer>
        {/* <StyledFiltersContainer isActive={isFiltersActive}>
        <StyledFormGroup label="Sistema">
          <StyledFilterSelect
            onChange={(e) => {
              setSystemFilter(e.target.value);
            }}
            size="small"
            data-cy="audit-report-unit-groups-select"
            placeholder="Seleção opcional"
            options={fields?.system}
            name="system"
            control={control}
            defaultValue=""
          />
        </StyledFormGroup>
        <StyledFormGroup label="Status">
          <StyledFilterSelect
            onChange={(e) => {
              setStatusFilter(e.target.value);
            }}
            size="small"
            data-cy="audit-report-unit-groups-select"
            placeholder="Seleção opcional"
            options={fields?.status}
            name="status"
            control={control}
            defaultValue=""
          />
        </StyledFormGroup>
        <StyledFormGroup label="Setor">
          <StyledFilterSelect
            onChange={(e) => {
              setSectorFilter(e.target.value);
            }}
            size="small"
            data-cy="audit-report-unit-groups-select"
            placeholder="Seleção opcional"
            options={fields?.sector}
            name="sector"
            control={control}
            defaultValue=""
          />
        </StyledFormGroup>
        <StyledFormGroup label="Aplicação">
          <StyledFilterSelect
            onChange={(e) => {
              setApplicationFilter(e.target.value);
            }}
            size="small"
            data-cy="audit-report-unit-groups-select"
            placeholder="Seleção opcional"
            options={fields?.application}
            name="application"
            control={control}
            defaultValue=""
          />
        </StyledFormGroup>
        <StyledClearFiltersButton>
          <ClearGrayIcon width={16} height={12} />
        </StyledClearFiltersButton>
      </StyledFiltersContainer> */}
        <StyledTableContainer {...getTableProps()}>
          <StyledTableHead>
            {headerGroups.map(headerGroup => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <StyledTableHeader
                    {...column.getHeaderProps(
                      column.disableSortBy !== true
                        ? column.getSortByToggleProps({
                            title: 'Ordenar por',
                          })
                        : column.getSortByToggleProps(),
                    )}
                  >
                    {column.render('Header')}

                    {column.disableSortBy !== true && (
                      <ArrowToggle
                        isSorted={column.isSorted}
                        isSortedDesc={column.isSortedDesc}
                        width="8"
                        height="10"
                        viewBox="0 0 8 10"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      />
                    )}
                  </StyledTableHeader>
                ))}
              </tr>
            ))}
          </StyledTableHead>
          <StyledTableBody {...getTableBodyProps()}>
            {page.map(row => {
              prepareRow(row);
              return (
                <StyledTableRow {...row.getRowProps()}>
                  {row.cells.map(cell => {
                    return <StyledTableCell {...cell.getCellProps()}>{cell.render('Cell')}</StyledTableCell>;
                  })}
                </StyledTableRow>
              );
            })}
          </StyledTableBody>
        </StyledTableContainer>
      </>
    );
  } else return <></>;
}
