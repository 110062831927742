import React, { useEffect } from 'react';
import { Button } from 'components/Button/index.js';
import { useLocation } from 'react-router-dom';
import QRCode from 'react-qr-code';
import {
  StyledQRcodeList,
  StyledQRcodeListContainer,
  StyledQRcodeItem,
  StyledQRcodeMain,
  StyledQRcodeInfo,
  StyledQRcodeCompany,
  StyledQRcodeNumber,
  StyledQRcodeGenerated,
  StyledQRcodeSerie,
} from './styled';
//import { QRcodeService } from 'services/reports';
//import { getLocalAuthStorage } from 'utils/localStorage';
//import ImgLogotipo from 'assets/images/grm-logo.png';
//import { FiltersContext } from 'context/GlobalFiltersContext';

const QRcodesList = () => {
  //const [qrCode, setQrCode] = useState([]);
  //const authStorage = getLocalAuthStorage();
  //const { token } = authStorage;
  const location = useLocation();
  // const { headerFiltersContext } = useContext(FiltersContext);
  // const { headerFilters } = headerFiltersContext;
  // const companyLogoPath = headerFilters?.companies?.logoPath;
  const data = location.state && location.state.data;
  // useEffect(() => {
  //   QRcodeService.getQRcode({ companyId: 6013, siteId: 58309, token })
  //     .then(success => {
  //       const { data } = success;
  //       setQrCode(data);
  //     })
  //     .catch();
  // }, [setQrCode, token]);
  useEffect(() => {
    const styleTag = document.createElement('style');
    styleTag.innerHTML = `
      .md-chat-widget-wrapper{
        display: none !important;
      }
      .inapp-survey{
        display: none !important;
      }
    `;

    document.head.appendChild(styleTag);

    return () => {
      document.head.removeChild(styleTag);
    };
  }, []);

  const formatUnit = unit => {
    const str = unit.slice(-3);

    return str;
  };

  //const value = 'https://google.com';
  const value1 = 'https://qr.lkp.app.br/E_';

  return (
    <StyledQRcodeList>
      <StyledQRcodeListContainer>
        {data.map((item, index) => {
          return (
            <StyledQRcodeItem key={index}>
              <StyledQRcodeMain>
                <StyledQRcodeInfo>
                  <StyledQRcodeCompany>
                    <img src={item.logoEmpresa} title="Logotipo" alt="Logo" />
                  </StyledQRcodeCompany>
                  <StyledQRcodeNumber>{formatUnit(item.tag)}</StyledQRcodeNumber>
                </StyledQRcodeInfo>
                <StyledQRcodeGenerated>
                  <QRCode
                    size={256}
                    style={{ height: 'auto', maxWidth: '100%', width: '100%' }}
                    value={value1 + item.equipamento}
                    viewBox={`0 0 256 256`}
                  />
                </StyledQRcodeGenerated>
              </StyledQRcodeMain>
              <StyledQRcodeSerie>{item.tag}</StyledQRcodeSerie>
            </StyledQRcodeItem>
          );
        })}
      </StyledQRcodeListContainer>
      <Button onClick={window.print}>Imprimir</Button>
    </StyledQRcodeList>
  );
};

export { QRcodesList };
