import styled from 'styled-components';
import magnifying from 'assets/icons/magnifying.svg';
import filter from 'assets/icons/filter.svg';
import { Select } from 'components/Form/subcomponents/Select';
import { FormGroup } from 'components/Form/subcomponents/FormGroup';
import { Button } from 'components/Button';
import { colors } from 'assets/styled/tokens';

export const StyledTableContainer = styled.table`
  width: 100%;
  margin-top: 24px;
  border-spacing: 0;
  border-radius: 8px;
`;
export const StyledActivitiesHeader = styled.h2`
  font-weight: 600;
  font-family: 'Blinker';
  font-size: 18px;
`;
export const StyledAddActivityButton = styled(Button)`
  color: ${colors.primary};
  margin-left: 24px;
  background: white;
  font-size: 12px;
  svg {
    margin-right: 8px;
  }
`;
export const StyledEmptySearchResultsAlert = styled.div`
  display: flex;
  color: #a6483c;
  align-items: center;
  margin-left: 24px;
  svg {
    margin-right: 12px;
  }
`;

export const StyledTableHead = styled.thead``;
export const StyledTableHeader = styled.th`
  text-align: left;
  font-size: 12px;
  border: 1px solid ${colors.whiteGray};

  font-weight: 500;
  line-height: 14px;
  padding: 17px 12px 16px;
  word-wrap: break-word;
  &:nth-child(1) {
    width: 50%;
  }
  &:nth-child(2) {
    width: 50%;
  }

  &:not(:last-child) {
    border-right: 0;
  }
  &:first-child {
    border-top-left-radius: 8px;
  }
  &:last-child {
    width: 52px;
    border-top-right-radius: 8px;
  }
`;

export const StyledTableRow = styled.tr``;
export const StyledTableCell = styled.td`
  font-size: 12px;
  color: ${colors.gray};
  border: 1px solid ${colors.whiteGray};
  border-top: 0;
  &:not(:last-child) {
    border-right: 0;
  }
  &:last-child {
    padding: 0;
  }
  height: 48px;
  padding: 0 12px;
`;
export const StyledTableBody = styled.tbody``;
export const StyledControlsContainer = styled.div`
  display: flex;
  align-items: center;
  > div {
    margin-top: -25px;
  }
`;
export const StyledToggleFiltersButton = styled.button`
  background: url(${filter}) no-repeat 8px;
  padding: 5px 8px 5px 28px;
  margin-right: 16px;
  border-radius: 4px;
  position: relative;
  font-weight: 500;
  font-size: 12px;
  color: ${colors.carbon};
  border: 1px solid ${colors.whiteGray};
  svg {
    position: absolute;
    bottom: -7px;
    left: 0;
    width: 100%;
  }
`;
export const StyledFilterSelect = styled(Select)``;
export const StyledFiltersContainer = styled.div`
  display: flex;
  align-items: center;
  height: ${props => (props.isActive ? '45px' : 0)};
  opacity: ${props => (props.isActive ? 1 : 0)};
  overflow: hidden;
  margin-bottom: 32px;
  transition: all 400ms ease-in;
`;
export const StyledClearFiltersButton = styled.button`
  display: flex;
  border: 1px solid ${colors.whiteGray};
  width: 24px;
  height: 30px;
`;
export const StyledFormGroup = styled(FormGroup)`
  width: 17vw;
  margin-right: 16px;
`;

export const StyledSearchPlansInput = styled.input`
  background: url(${magnifying}) no-repeat 94%;
  background-size: 12px;
  border: 1px solid ${colors.whiteGray};
  font-size: 12px;
  padding: 5px 8px;
  width: 240px;
  border-radius: 4px;
  &::placeholder {
    color: ${colors.gray};
  }
`;
