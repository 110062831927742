import { Button } from 'components/Button';
import { Select } from 'components/Form/subcomponents/Select';
import styled from 'styled-components';
import selectIcon from 'assets/icons/dropdown-select-icon.svg';

import { medias } from 'assets/styled/tokens';
export const MenuContainer = styled.div`
  display: none;
  @media (min-width: ${medias.desktopSm}) {
    height: 30%;
    width: 83%;
    color: #ffffff;
    font-family: Roboto;
    font-size: 14px;
    font-weight: 700;
    line-height: 16px;
    letter-spacing: 0.5px;
    display: flex;
    justify-content: space-between;
  }
`;
export const CompanySelect = styled(Select)`
  border: 1px solid #ffffff;
  height: 32px;
  width: 27%;
  border-radius: 4px;
  input {
    background: url(${selectIcon}) right 10px center / 8px 4px no-repeat, #1e1e1e;
    color: #ffffff;
  }
  ul {
    margin-top: 32px;
    background-color: #1e1e1e;
    border: 1px solid #ffffff;
    border-radius: 0 0 4px 4px;
    section {
      li {
        &:hover {
          background-color: #1e1e1e;
        }
        label {
          color: #ffffff;
        }
      }
    }
  }
`;
export const MenuButton = styled(Button)`
  border: 1px solid #ffffff;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  background-color: #1e1e1e;
  width: ${props => props.Width};
  padding: 4px;
`;
