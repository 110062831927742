import { citiesDependencies } from './filters/citiesDependencies';
import { unitSubgroupsDependencies } from './filters/unitSubgroupsDependencies';
import { unitiesDependencies } from './filters/unitiesDependencies';

const getDependentFilters = async ({ filterType, filterResponse }) => {
  switch (filterType) {
    case 'units':
      return unitiesDependencies({ filterResponse });
    case 'cities':
      return citiesDependencies({ filterResponse });
    case 'unitSubgroups':
      return unitSubgroupsDependencies({ filterResponse });
    default:
      return [];
  }
};

export { getDependentFilters };
