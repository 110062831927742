import styled from 'styled-components';
import { colors } from 'assets/styled/tokens';

import checkIcon from '../../assets/check.svg';
import minusIcon from '../../assets/minus.svg';

const iconCheckboxAllFactory = isAllSelected => {
  return isAllSelected ? checkIcon : minusIcon;
};

export const StyledCheckbox = styled.label`
  width: 100%;
  display: flex;
  align-items: center;
  margin: 0.3125rem 0;

  p {
    margin-left: 0.6875rem;
    font-size: 0.875rem;
    line-height: 1.25rem;
    color: ${colors.gray};
  }

  span {
    min-width: 12px;
    min-height: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${colors.white};
    border: 0.1875rem solid ${colors.lightGray};
    border-radius: 0.1875rem;
  }

  input:checked + span {
    background: ${colors.primary};
    border-color: ${colors.primary};

    &::after {
      content: '';
      display: block;
      width: 0.625rem;
      height: 0.625rem;

      background: ${props =>
        props.isCheckboxAll
          ? `url(${iconCheckboxAllFactory(props?.isAllSelected)}) no-repeat center/contain`
          : `url(${checkIcon}) no-repeat center/contain`};
    }
  }
`;

export const StyledInput = styled.input`
  position: absolute;
  z-index: -1;
  display: none;
  margin: 0;
`;
