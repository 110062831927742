import * as React from 'react';

import { HeaderFiltersType } from 'context/GlobalFiltersContext/initialState';
import { LkpChart } from '../Chart';
import { useBoolean, useDebounce } from 'hooks';
import { ChartSeriesType, ChartsParamsType } from '../types';
import { fetcher } from 'services';
import { ChartNumberOfOccurrencesRegisteredPerWeekType } from './types';
import { getErrorMessageByStatusCode, groupBy } from 'utils';
import { getLineChartOptions } from './options';
import { LkpChartTopFilter } from '../Filters';
import { useParams } from 'react-router-dom';

export interface LkpChartNumberOfOccurrencesRegisteredPerWeekProps {
  headerFilters: HeaderFiltersType;
}

const HTTP_PATH = '/quantidade-ocorrencias-por-periodo';

export function LkpChartNumberOfOccurrencesRegisteredPerWeek({
  headerFilters,
}: LkpChartNumberOfOccurrencesRegisteredPerWeekProps): React.ReactElement {
  const { value: isLoading, setValue: setLoading } = useBoolean();
  const { value: isError, setValue: setIsError } = useBoolean();
  const [errorMessage, setErrorMessage] = React.useState<string>();
  const { dateFrom, dateTo } = useParams<{ dateFrom: string; dateTo: string }>();
  const [series, setSeries] = React.useState<Array<ChartSeriesType>>([]);
  const [periods, setPeriods] = React.useState<Array<string>>([]);
  const [params, setParams] = React.useState<ChartsParamsType>({
    Empresa: headerFilters?.companies?.id || 0,
    Sites: headerFilters?.units?.id,
    Inicio: dateFrom,
    Fim: dateTo,
  });

  const debouncedStartValue = useDebounce<string>(params?.Inicio || '', 500);
  const debouncedEnvValue = useDebounce<string>(params?.Fim || '', 500);

  React.useEffect(() => {
    (async () => {
      await fetchChartData();
    })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedStartValue, debouncedEnvValue]);

  const fetchChartData = async () => {
    setLoading(true);

    const result = await fetcher
      .get<Array<ChartNumberOfOccurrencesRegisteredPerWeekType>>(HTTP_PATH, 'dashboard', {
        params,
      })
      .catch(error => {
        const message = getErrorMessageByStatusCode(error, HTTP_PATH);
        setErrorMessage(message);
        setIsError(true);
      });

    if (result) {
      const _series = result.map(({ quantidade }) => quantidade || 0);
      setSeries([{ name: 'Quantidade', data: _series }]);

      const _groupPeriods = groupBy<ChartNumberOfOccurrencesRegisteredPerWeekType>(result, d => d.periodo);
      setPeriods(_groupPeriods.map(({ name }) => name));
    }

    setLoading(false);
  };

  const handleChangeStartDate = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value: Inicio } = event.target;
    setParams({ ...params, Inicio });
  };

  const handleChangeEndDate = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value: Fim } = event.target;
    setParams({ ...params, Fim });
  };

  return (
    <LkpChart
      title="Quantidade de ocorrências cadastradas por semana"
      companyId={headerFilters?.companies?.id || 0}
      loading={isLoading}
      error={isError}
      errorMessage={errorMessage}
      onChartTryAgain={fetchChartData}
      series={series}
      //width={1700}
      type="line"
      options={getLineChartOptions(periods, {
        chart: {
          height: 350,
          type: 'line',
          zoom: {
            enabled: false,
          },
          toolbar: {
            show: false,
          },
        },
      })}
      filter={<LkpChartTopFilter onChangeStartDate={handleChangeStartDate} onChangeEndDate={handleChangeEndDate} />}
    />
  );
}
