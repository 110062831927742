import styled from 'styled-components';
import React from 'react';

const StyledArrowToggleSvg = styled.svg`
  margin-left: 8px;
  margin-top: 3px;
  transform-origin: center;
  transform-box: fill-box;
  transform: ${props => (props.isSorted ? (props.isSortedDesc ? `rotate(180deg)` : `rotate(0)`) : `rotate(0)`)};
  transition: all 300ms ease;
  overflow: hidden;
`;

const ArrowToggle = props => {
  return (
    <StyledArrowToggleSvg
      width="8"
      height="4"
      viewBox="0 0 8 4"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M4.23726 0.0906852C4.10623 -0.0302284 3.89377 -0.0302284 3.76274 0.0906852L0.0989494 3.47145C-0.112428 3.6665 0.0372787 4 0.336212 4L7.66379 4C7.96272 4 8.11243 3.6665 7.90105 3.47145L4.23726 0.0906852Z"
        fill="#BBBBBB"
      />
    </StyledArrowToggleSvg>
  );
};

export { ArrowToggle };
