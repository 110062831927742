const adapt = httpResponse => {
  const { usuario, nome, empresa, site, fornecedor } = httpResponse.data;
  return {
    ...httpResponse,
    userSession: {
      usuario: {
        id: usuario,
        name: nome,
      },
      empresa: {
        id: empresa,
        name: nome,
      },
      unidade: {
        id: site,
        empresaId: empresa,
        name: nome,
      },
      providerId: fornecedor,
    },
  };
};

const refreshAdapter = httpResponsePromise =>
  httpResponsePromise.then(
    resolve => adapt(resolve),
    reject => reject,
  );

export { refreshAdapter };
