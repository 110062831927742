import styled from 'styled-components';
import { colors } from 'assets/styled/tokens';
import { strongTextCss } from 'utils/helpers/css';
import {
  StyledDailyPendingActivitiesAndOccurrences,
  StyledOccurrencesDetailsContainer,
  StyledOccurrencesDetails,
  StyledOccurrencesDetailsBg,
  StyledGrayStrongText,
} from 'pages/Geolocalize/styled';

export const StyledUnitCard = styled.div`
  display: flex;
  flex-direction: column;
  background: white;
  border: 1px solid ${colors.whiteGray};
  margin-bottom: 12px;
  border-radius: 4px;
  overflow: hidden;
  cursor: pointer;
  ${StyledOccurrencesDetailsContainer} {
    justify-content: space-between;
    margin: 20px 24px 24px 16px;
    padding-right: 24px;
    ${StyledGrayStrongText} {
      margin-bottom: 4px;
    }
  }
  ${StyledOccurrencesDetails} {
    display: flex;
    flex-direction: column;
    width: 158px;
    z-index: 0;
    padding: 0 0 0 12px;
    margin-left: 0;
  }
  ${StyledDailyPendingActivitiesAndOccurrences} {
    font-size: 16px;
    line-height: 19px;
    margin-top: 0;
    justify-content: flex-start;
    margin-bottom: 12px;
  }
  ${StyledOccurrencesDetailsBg} {
    top: -10px;
  }
`;
export const StyledUnitInfoRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 17px 12px 16px 16px;
  svg {
    margin-top: 6px;
    margin-right: 14px;
    width: 20px;
    height: 24px;
    path {
      fill: ${colors.purpleBlue};
    }
  }
`;
export const StyledUnitInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
export const StyledUnitGroupName = styled.div`
  color: ${colors.gray};
  font-weight: 500;
  font-size: 14px;
  margin-bottom: 3px;
  line-height: 16px;
`;
export const StyledUnitName = styled.div`
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
`;

export const StyledOccurrencesCount = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: ${colors.gray};
  ${strongTextCss}
  margin-right: 16px;
  b {
    color: ${colors.carbon};
    font-size: 21px;
    line-height: 24px;
  }
`;
export const StyledDailyPendingContainer = styled.div`
  ${strongTextCss}
  button {
    font-size: 14px;
    line-height: 16px;
    margin-top: 2px;
  }
`;
export const StyledActivitiesCount = styled(StyledOccurrencesCount)`
  b {
    margin-right: 6px;
  }
`;
export const StyledSinglePendingEventRow = styled.div`
  ${StyledOccurrencesCount}, ${StyledActivitiesCount} {
    padding: 13px 16px 16px 16px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    b {
      margin-right: 0;
    }
    ${StyledDailyPendingActivitiesAndOccurrences} {
      margin-bottom: 0;
      margin-right: 16px;
    }
  }
  ${StyledGrayStrongText} {
    line-height: 15px;
    margin-left: 5px;
  }
`;

export const StyledViewOnMapButton = styled.button`
  border: none;
  font-weight: 500;
  color: ${colors.primary};
  padding: 5px 23px;
  box-sizing: border-box;
  border-radius: 4px;
  margin-left: 16px;
`;
