import { Carousel } from './Components/Carousel';
import { AdsContainer, Header, SocialMediaIconsContainer } from './styled';
import { ReactComponent as IgIcon } from 'assets/icons/IG-icon.svg';
import { ReactComponent as FbIcon } from 'assets/icons/fb-icon.svg';
import { ReactComponent as LkdIcon } from 'assets/icons/lkd-icon.svg';
import { ReactComponent as YtIcon } from 'assets/icons/yt-icon.svg';

export const AdsContainerCarousel = () => {
  const handleIconClick = (e, url) => {
    e.preventDefault();
    window.open(url);
  };
  return (
    <AdsContainer>
      <Header>
        <h1>Novidades</h1>
        <SocialMediaIconsContainer>
          <h2>Siga o Leankeep!</h2>
          <div>
            <IgIcon onClick={e => handleIconClick(e, 'https://www.instagram.com/leankeep/')} />
            <LkdIcon onClick={e => handleIconClick(e, 'https://br.linkedin.com/company/leankeep')} />
            <YtIcon onClick={e => handleIconClick(e, 'https://www.youtube.com/@leankeepsoftware')} />
            <FbIcon onClick={e => handleIconClick(e, 'https://pt-br.facebook.com/Leankeep/')} />
          </div>
        </SocialMediaIconsContainer>
      </Header>
      <Carousel />
    </AdsContainer>
  );
};
