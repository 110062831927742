import { colors } from 'assets/styled/tokens';
import { defaultTransition } from 'assets/styled/tokens/transitions';
import styled from 'styled-components';

export const StyledChartTooltip = styled.div`
  position: relative;
  z-index: 1000;
  width: ${props => (props.chart ? '100px' : '')};
  right: ${props => (props.chart ? '-730px' : '')};
  top: ${props => (props.chart ? '4.0rem' : '')};
  height: ${props => (props.chart ? '15px' : '')};
  span {
    font-size: 12px;
    line-height: 14px;
    text-align: center;
    border: 1px solid ${colors.carbon};
    background: ${colors.carbon};
    border-radius: 0.1875rem;
    color: #fff;
    position: absolute;
    top: ${props => (props.chart ? '-4.8rem' : '-2.3rem')};
    //top: -2.3rem;
    margin: auto;
    left: ${props => (props.chart ? '-50px' : '0')};
    right: ${props => (props.chart ? '150px' : '0')};
    //right: 0;
    padding: 0.5rem;
    z-index: 1000;
    width: ${props => (props.chart ? '150px' : 'fit-content')};
    //width: fit-content;
    visibility: ${props => (props.isVisible() ? 'visible' : 'hidden')};
    opacity: ${props => (props.isVisible() ? 1 : 0)};
    ${defaultTransition};
    &:before {
      content: '';
      display: block;
      position: absolute;
      bottom: -0.1875rem;
      width: 0.625rem;
      word-wrap: break-word;
      height: 0.625rem;
      border-bottom: 0.1875rem solid transparent;
      border-right: 0.1875rem solid transparent;
      background: ${colors.carbon};
      left: 45%;
      transform: rotate(45deg);
    }
  }
`;
