import styled from 'styled-components';
import { colors, medias } from 'assets/styled/tokens';
import { Button } from 'components/Button';

export const MenuContainer = styled.div`
  position: fixed;
  width: 180px;
  height: 100%;
  background-color: ${colors.carbon};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media screen and (max-width: ${medias.desktopXl}) {
    max-height: 969px;
  }
  @media screen and (max-width: ${medias.desktopMd}) {
    max-height: 652px;
  }
`;

export const Logo = styled.div`
  display: flex;
  margin: 32px 24px 0 24px;
  justify-content: center;
  align-items: center;
`;

export const LogoutButton = styled(Button)`
  margin: 0 24px 24px;
  background-color: ${colors.carbon};
  color: ${colors.gray};
  border: 1px solid ${colors.gray};
  display: flex;
  flex-direction: row;
  justify-content: center;

  svg {
    margin-right: 8px;
  }
`;

export const StyledNav = styled.nav`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 180px;

  flex: 1;
  //margin: 0 -1rem;

  @media screen and (max-width: ${medias.tablet}) {
    margin: 4em -1rem 2em -1rem;
  }

  @media screen and (min-width: ${medias.desktopSm}) {
    justify-content: center;
    z-index: 99;
  }

  @media screen and (min-width: ${medias.desktopMd}) {
    justify-content: center;
  }

  @media screen and (min-width: ${medias.desktopXl}) {
    justify-content: center;
  }
`;
export const StyledMenuCategory = styled.ul`
  width: 180px;
  & + ul {
    margin-top: 1rem;
    padding-top: 1rem;
    border-top: 1px solid ${colors.whiteGray};
  }
`;
