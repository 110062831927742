import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { Button } from 'components/Button';

import { ReactComponent as BackButtonIcon } from 'assets/icons/back-btn.svg';

import {
  TabItem,
  TabItemBorder,
  StyledReportContainer,
  StyledHeader,
  StyledTitle,
  StyledContentWrapper,
  StyledActions,
  StyledFooter,
  StyledMain,
  StyledContainerHeader,
  StyledContainerFooter,
  StyledContainerMain,
  NewCleanFiltersButton,
  NewCleanFiltersButtonContainer,
} from './styled';

const ReportContainerTabs = props => {
  const {
    tabs,
    isPageDisabled = false,
    emailNotVerifiedElement,
    activeTab,
    exportTitle,
    onSubmit,
    cleanFields,
    onBack,
    backButtonDisabled = false,
    formId,
    renderFooter,
    activeAfterSendEmail = false,
    setActiveAfterSendEmail,
    setAutoReload,
    isNotPdfOcurrence = true,
  } = props;

  const [active, setActive] = useState(activeTab);
  const changeTab = id => {
    setActive(id);
    setActiveAfterSendEmail(false);
    setAutoReload(id);
  };
  useEffect(() => {
    if (activeAfterSendEmail) {
      setActive(1);
      setAutoReload(1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeAfterSendEmail]);
  return (
    <StyledReportContainer>
      <StyledHeader>
        <StyledContainerHeader>
          <StyledContentWrapper justifyContent="flex-start" alignItems="center" gridColumnStart={2}>
            {backButtonDisabled && <BackButtonIcon width={24} height={24} onClick={onBack} />}
            <StyledTitle>
              {tabs.map(item => (
                <TabItem key={item.id} active={active === item.id} onClick={() => changeTab(item.id)} id={item?.idName}>
                  {item.title}
                  <TabItemBorder active={active === item.id} />
                </TabItem>
              ))}
            </StyledTitle>
          </StyledContentWrapper>
        </StyledContainerHeader>
      </StyledHeader>
      <StyledMain propertyContent={tabs[active].propertyContent}>
        {isPageDisabled ? (
          emailNotVerifiedElement
        ) : (
          <>
            {!tabs[active].id && isNotPdfOcurrence && (
              <NewCleanFiltersButtonContainer>
                <NewCleanFiltersButton onClick={cleanFields}>
                  <svg width="10" height="10" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1 1L11 11" stroke="#EC6655" strokeWidth="2" strokeLinecap="round" />
                    <path d="M11 1L1 11" stroke="#EC6655" strokeWidth="2" strokeLinecap="round" />
                  </svg>
                  LIMPAR FILTROS
                </NewCleanFiltersButton>
              </NewCleanFiltersButtonContainer>
            )}
            <form onSubmit={e => onSubmit(e)} id={formId}>
              <StyledContainerMain propertyContent={tabs[active].propertyContent}>
                {tabs[active].content}
              </StyledContainerMain>
            </form>
          </>
        )}
      </StyledMain>
      {renderFooter && active === 0 && (
        <StyledFooter>
          <StyledContainerFooter>
            <StyledContentWrapper justifyContent="flex-end" alignItems="center" gridColumnStart={2}>
              <StyledActions>
                <Button data-cy="export-audit-report-button" type="submit" form={formId}>
                  {exportTitle}
                </Button>
              </StyledActions>
            </StyledContentWrapper>
          </StyledContainerFooter>
        </StyledFooter>
      )}
    </StyledReportContainer>
  );
};

ReportContainerTabs.propsTypes = {
  title: PropTypes.string.isRequired,
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      title: PropTypes.string.isRequired,
      content: PropTypes.node.isRequired,
      propertyContent: PropTypes.string,
    }).isRequired,
  ).isRequired,
  activeTab: PropTypes.number.isRequired,
  formId: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  currentSavedFilter: PropTypes.string,
  cleanSavedFilter: PropTypes.func,
  saveFilter: PropTypes.func,
  cleanFields: PropTypes.func.isRequired,
  onBack: PropTypes.func,
  refInputFilterName: PropTypes.any,
  renderFooter: PropTypes.bool,
  children: PropTypes.node,
};

export { ReportContainerTabs };
