import React from 'react';
import { StyledActivitiesTableDescriptionCell, StyledCellDescription } from './styled';
function ActivitiesTableDescriptionCell(props) {
  return (
    <StyledActivitiesTableDescriptionCell>
      <StyledCellDescription>
        {props.cell.value.length < 106 ? props.cell.value : props.cell.value.substr(0, 106) + `...`}
      </StyledCellDescription>
    </StyledActivitiesTableDescriptionCell>
  );
}

export default ActivitiesTableDescriptionCell;
