import { useParams } from 'react-router-dom';
import { Content, Header, StyledImg } from './styled';

import leankeepLogoFooter from 'assets/icons/leankeep/logo-horizontal.svg';
import { StyledButton } from 'components/Button/styled';
import Equipamento from './Modules/equipamentos';
import Ambiente from './Modules/ambiente';
import Unidades from './Modules/unidades';

const LandingQR = () => {
  const { code } = useParams();
  let data = require('./mock.json');

  if (code.startsWith('E')) {
    data = { ...data, type: 'equipment' };
  } else if (code.startsWith('A')) {
    data = { ...data, type: 'environment' };
  } else if (code.startsWith('S')) {
    data = { ...data, type: 'unit' };
  }
  return (
    <>
      <Header>
        <Content>
          <StyledImg src={leankeepLogoFooter} alt="QR Code" />
          <StyledButton theme="whiteOutline" size="small" onClick={() => window.open(`/login`)}>
            Login
          </StyledButton>
        </Content>
      </Header>
      {data.type === 'equipment' ? (
        <Equipamento data={data} code={code} />
      ) : data.type === 'environment' ? (
        <Ambiente data={data} code={code} />
      ) : data.type === 'unit' ? (
        <Unidades data={data} code={code} />
      ) : (
        <h1>Erro ao carregar dados</h1>
      )}
    </>
  );
};

export { LandingQR };
