import React from 'react';
import ReactDOM from 'react-dom';
//import { hotjar } from 'react-hotjar';
import * as Sentry from '@sentry/react';
import 'mapbox-gl/dist/mapbox-gl.css';
import { App } from './App';
import { BrowserTracing } from '@sentry/browser';
import { isDevEnv, isHomologEnv, isProductionEnv } from 'utils/env';

Sentry.init({
  environment: process.env.REACT_APP_SENTRY_ENVIRONMENT,
  dsn: process.env.REACT_APP_SENTRY_DSN,
  release: process.env.REACT_APP_VERSION,
  integrations: [
    new BrowserTracing({
      routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
    }),
    new Sentry.Replay({
      maskAllText: false,
    }),
  ],
  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: process.env.REACT_APP_SENTRY_ENVIRONMENT === 'Dev' ? 0.4 : 1.0,
  debug: process.env.REACT_APP_SENTRY_ENVIRONMENT === 'Dev',
  replaysOnErrorSampleRate: process.env.REACT_APP_SENTRY_ENVIRONMENT === 'Dev' ? 0.0 : 0.4,
  replaysSessionSampleRate: process.env.REACT_APP_SENTRY_ENVIRONMENT === 'Dev' ? 0.0 : 0.3,
});
if (isProductionEnv) {
  //hotjar.initialize(1782133, 6);
}

// Disable console log on production and homolog
//  pending see why homolog returning production
if (!isDevEnv && !isHomologEnv) {
  window.console = {
    ...window.console,
    info: () => {},
    log: () => {},
    warn: () => {},
  };
}

ReactDOM.render(<App />, document.getElementById('root'));
