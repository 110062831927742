import { AxiosGlobalFilters } from '../config';

import { getAllCompaniesAdapter } from 'adapters/companies';
import { getAllUnitGroupsAdapter } from 'adapters/unitGroups';
import { getAllUnitSubgroupsAdapter } from 'adapters/unitSubgroups';
import { getAllUnitiesAdapter } from 'adapters/units';
import { getAllStatesAdapter } from 'adapters/states';
import { getAllCitiesAdapter } from 'adapters/cities';
import { getAllProvidersAdapter } from 'adapters/providers';

const HeaderFilterService = {
  companies: async () => {
    return getAllCompaniesAdapter(
      AxiosGlobalFilters({
        method: 'get',
        url: `/v1/empresas/ativas`,
      }),
    );
  },
  company: async id => {
    return AxiosGlobalFilters({
      method: 'get',
      url: `/v1/empresas/${id}`,
    });
  },

  unitGroups: ({ empresaId, estadoId, cidadeId, fornecedorId, equipeId }) =>
    getAllUnitGroupsAdapter(
      AxiosGlobalFilters({
        method: 'get',
        url: '/v1/gruposUnidades/ativas',
        params: {
          estadoId,
          cidadeId,
          fornecedorId,
          equipeId,
        },
        headers: {
          empresaId: empresaId,
        },
      }),
    ),

  unitSubgroups: ({ empresaId, grupoUnidadeId, cidadeId, estadoId, fornecedorId, equipeId }) =>
    getAllUnitSubgroupsAdapter(
      AxiosGlobalFilters({
        method: 'get',
        url: '/v1/subGruposUnidades/ativas',
        params: {
          grupoUnidadeId,
          cidadeId,
          estadoId,
          fornecedorId,
          equipeId,
        },
        headers: {
          empresaId: empresaId,
        },
      }),
    ),

  states: ({ empresaId, paisId, nome, sigla, fornecedorId, equipeId }) =>
    getAllStatesAdapter(
      AxiosGlobalFilters({
        method: 'get',
        url: '/v1/estados/ativos',
        params: {
          paisId,
          nome,
          sigla,
          fornecedorId,
          equipeId,
        },
        headers: {
          empresaId: empresaId,
        },
      }),
    ),

  cities: ({ empresaId, estadoId, paisId, nome, fornecedorId, equipeId }) =>
    getAllCitiesAdapter(
      AxiosGlobalFilters({
        method: 'get',
        url: '/v1/cidades/ativas',
        params: {
          estadoId,
          paisId,
          nome,
          fornecedorId,
          equipeId,
        },
        headers: {
          empresaId: empresaId,
        },
      }),
    ),
  unit: (companyId, unitId) => {
    return AxiosGlobalFilters({
      method: 'get',
      url: `/v1/unidades/${unitId}`,
      headers: {
        EmpresaId: companyId,
      },
    });
  },
  units: ({ empresaId, cidadeId, estadoId, fornecedorId, equipeId, grupoUnidadeId, subGrupoUnidadeId }) =>
    getAllUnitiesAdapter(
      AxiosGlobalFilters({
        method: 'get',
        url: '/v1/unidades/ativas',
        params: {
          cidadeId,
          estadoId,
          fornecedorId,
          equipeId,
          grupoUnidadeId,
          subGrupoUnidadeId,
        },
        headers: {
          EmpresaId: empresaId,
        },
      }),
    ),

  // teams: ({
  //   empresaId,
  //   nome,
  //   subGrupoUnidadeId,
  //   grupoUnidadeId,
  //   estadoId,
  //   cidadeId,
  //   fornecedorId,
  //   unidadeId
  // }) =>
  //   getAllProvidersAdapter(
  //     AxiosGlobalFilters({
  //       method: 'get',
  //       url: '/v1/equipes/ativas',
  //       params: {
  //         nome,
  //         subGrupoUnidadeId,
  //         grupoUnidadeId,
  //         estadoId,
  //         cidadeId,
  //         fornecedorId,
  //         unidadeId
  //       },
  //       headers: {
  //         empresaId: empresaId
  //       }
  //     })
  //   ),

  providers: ({ empresaId, unidadeId, equipeId }) =>
    getAllProvidersAdapter(
      AxiosGlobalFilters({
        method: 'get',
        url: '/v1/empresas/fornecedor',
        params: {
          unidadeId,
          equipeId,
        },
        headers: {
          empresaId: empresaId,
        },
      }),
    ),
};

export { HeaderFilterService };
