let cookieDomain = 'leankeep.com';

if (process.env.NODE_ENV === 'development') {
  cookieDomain = 'localhost';
  // pending: see why process.env.NODE_ENV returns production in homolog
} else if (window.location.href.indexOf('lkp.dev.br') > -1) {
  cookieDomain = 'lkp.dev.br';
}

export const URLS = {
  movidesk: 'https://leankeep.movidesk.com/kb',
  tickets: 'https://leankeep.movidesk.com/',
  account: `${process.env.REACT_APP_LEGADO}/Cobrancas/ShowCobrancasUsuarioPage.aspx`,
  occurrencesPanel: `${process.env.REACT_APP_LEGADO}/Anomalias/ShowAnomaliasTablePage.aspx`,
  imageDevelopment: 'https://static.lkp.dev.br/logos/https://media.lkp.app.br/v1/static',
  imageProduction:
    'https://static.lkp.app.br/cdn-cgi/image/fit=pad,w=56,h=56,background=%23FFFFFF,quality=99/https:/media.lkp.app.br/v1/static',
  cookieDomain,
  oldReports: `${process.env.REACT_APP_LEGADO}/Relatorios/ShowRelatoriosMenuPage.aspx`,
  oldDashboard: `${process.env.REACT_APP_LEGADO}/Dashboards/ShowDashboardsPage.aspx`,
};
