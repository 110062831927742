const formatDateRangeValue = dateRange => {
  if (dateRange) {
    const range = dateRange.split('/');

    if (range.length === 2) {
      return {
        start: range[0],
        end: range[1],
      };
    }
  }

  return undefined;
};

export { formatDateRangeValue };
