import React from 'react';
import { StyledGeneralPlanLastUpdateCell, StyledLastUpdateDate } from './styled';
import { format } from 'date-fns';
function GeneralPlanLastUpdateCell(props) {
  const date = new Date(props.cell.value);
  const formattedDate = format(date, 'dd/MM/yyyy');
  const formattedHour = format(date, 'kk:mm');

  return (
    <StyledGeneralPlanLastUpdateCell>
      <StyledLastUpdateDate>{formattedDate}</StyledLastUpdateDate>
      às {formattedHour}
    </StyledGeneralPlanLastUpdateCell>
  );
}

export default GeneralPlanLastUpdateCell;
