/* istanbul ignore file */

import { colors, fonts } from 'assets/styled/tokens';
import styled from 'styled-components';
import { strongTextCss, strongTitleCss, textCss } from 'utils/helpers/css';
import MapModalMarker from '..';
import { StyledModalMarkerModal } from '../../styled';
import { ReactComponent as UnitSmallMarkerSvg } from '../../../../assets/unit-small-marker.svg';
import { ReactComponent as UnitLargeMarkerSvg } from '../../../../assets/unit-large-marker.svg';
import { StyledHorizontalStackedAvatars, StyledTechnicianPins } from '../styled';

export const StyledUnitModalMarker = styled(MapModalMarker)`
  cursor: pointer;
`;
export const StyledUnitMarkerModal = styled(StyledModalMarkerModal)`
  overflow-x: hidden;
  z-index: 10;
`;

export const StyledNotificationIcon = styled.svg`
  position: absolute;
  z-index: 3;
  top: -9px;
  right: -15px;
`;

export const StyledUnitSmallMarkerSvg = styled(UnitSmallMarkerSvg)`
  position: absolute;
  top: 0px;
`;
export const StyledUnitLargeMarkerSvg = styled(UnitLargeMarkerSvg)`
  position: absolute;
  top: -4px;
`;
export const StyledUnitWithTechnicians = styled.div`
  position: relative;
  img {
    height: ${({ isSingleTechnicianPin }) => (isSingleTechnicianPin ? '24px' : '24px')};
    width: ${({ isSingleTechnicianPin }) => (isSingleTechnicianPin ? '24px' : '24px')};
    margin-top: ${({ isSingleTechnicianPin }) => (isSingleTechnicianPin ? '8px' : '1px')};
  }
`;
export const StyledUnitTechnicianPins = styled(StyledTechnicianPins)`
  ${StyledHorizontalStackedAvatars} {
    border: 1px solid ${colors.lightPurpleBlue};
  }
`;
export const StyledUnitTechnicians = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${colors.primary};
  border: 1px solid #f6a740;
  color: white;
  padding: 8px 0;
  height: 40px;
  margin-bottom: 8px;
  font-weight: 500;
  border-radius: 4px;
  font-size: 14px;
  line-height: 16px;
`;

export const StyledViewTechniciansSubModalButton = styled.button`
  color: white;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  padding: 4px 8px 4px 8px;
  border: 1px solid white;
  border-radius: 4px;
`;

export const StyledPendingUnitEvents = styled.div``;

export const StyledUnitGroupName = styled.div`
  font-family: ${fonts.family.text};
  font-size: 14px;
  line-height: 16px;
  color: ${colors.gray};
  font-weight: 500;
`;
export const StyledUnitName = styled.div`
  ${strongTitleCss}
  display: flex;
  align-items: baseline;
  margin: 8px 0;
  svg {
    margin-right: 6px;
  }
  path {
    fill: ${colors.purpleBlue};
  }
`;
export const StyledSubModalContainer = styled.div`
  position: relative;
`;
export const StyledSubModalHeader = styled.div`
  display: flex;
  align-items: top;
  background: ${colors.purpleBlue};
  padding: 12px 12px 12px 9px;
  overflow: hidden;
  border-radius: 4px;
  * {
    color: white;
  }
`;
export const StyledReturnToMainModalArrow = styled.div`
  align-self: center;
  cursor: pointer;
`;
export const StyledSubModalHeaderDescription = styled.div`
  ${textCss}
`;
export const StyledSubModalHeaderVerticalLine = styled.div`
  height: 53px;
  transform: scaleY(3.3);
  width: 1px;
  margin-right: 16px;
  margin-left: 9px;
  background-color: #7d73de;
`;
export const StyledTechnicianList = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  margin-top: 8px;
  max-height: 198px;
  overflow-y: auto;
  overflow-x: hidden;
  padding-top: 8px;
  padding-right: 4px;

  img {
    width: 22px;
    height: 22px;
  }
`;
export const StyledTechnicianLastLocation = styled.div`
  position: relative;
  ${strongTextCss}
  color: ${colors.gray};
  b {
    color: ${colors.carbon};
  }
  margin-bottom: -6px;
`;
export const StyledHorizontalLine = styled.div`
  width: 140px;
  margin-top: 12px;
  margin-bottom: 12px;
  transform: scaleX(3.3);
  min-height: 1px;
  background-color: ${colors.whiteGray};
`;
export const VerticalLine = styled.div`
  height: 53px;
  transform: scaleY(3.3);
  width: 1px;
  margin-right: 16px;
  margin-left: 12px;
  background-color: ${colors.whiteGray};
`;
export const StyledTechnicianListVerticalLine = styled.div`
  height: 199px;
  width: 1px;
  position: absolute;
  right: 20px;
  bottom: -1px;
  background-color: ${colors.whiteGray};
`;
