import { AxiosGlobalFilters, AxiosMain } from '../config';
import { loginAdapter, refreshAdapter, forgotAdapter, checkAdapter } from 'adapters/auth';
import qs from 'qs';

// TODO FIX TOKEN INITIALIZATION LOCATION
const AuthService = {
  login: async ({ login, password, authtoken = true, expireCurrentSession = false, stayConnected = false }) =>
    loginAdapter(
      AxiosMain({
        method: 'post',
        url: '/v1/auth',
        data: qs.stringify({
          login,
          password,
          platform: 6,
          authtoken,
          expireCurrentSession,
          stayConnected,
        }),
        headers: {
          'content-type': 'application/x-www-form-urlencoded;charset=utf-8',
          'Access-Control-Allow-Origin': '*',
        },
      }),
    ),

  refreshToken: ({ token, refreshToken }) =>
    loginAdapter(
      AxiosMain({
        method: 'post',
        url: '/v1/auth/refresh',
        data: qs.stringify({
          authtoken: { token, refreshToken },
        }),
        skipAuthRefresh: true,
        headers: {
          'content-type': 'application/x-www-form-urlencoded;charset=utf-8',
        },
      }),
    ),

  refreshSession: ({ empresaId, unidadeId }) =>
    refreshAdapter(
      AxiosGlobalFilters({
        method: 'post',
        url: '/v1/usuarios/session/refresh',
        data: {
          empresaId,
          unidadeId,
        },
      }),
    ),

  logout: () =>
    loginAdapter(
      AxiosMain({
        method: 'delete',
        url: '/v1/auth',
      }),
    ),

  checkToken: () =>
    checkAdapter(
      AxiosMain({
        method: 'get',
        url: '/v1/auth/check',
      }),
    ),

  resetPassword: ({ password, token }) =>
    AxiosMain({
      method: 'put',
      url: '/v1/account/password',
      data: {
        password,
        token,
      },
    }),
  getUser: async ({ id }) => {
    const url = `/v1/usuarios/${id}`;
    return AxiosGlobalFilters({
      method: 'get',
      url,
    });
  },
  validateResetPasswordToken: ({ passwordResetToken }) =>
    AxiosMain({
      method: 'get',
      url: `/v1/account/password/token/${passwordResetToken}`,
    }),

  recoveryPassword: ({ email, login }) =>
    forgotAdapter(
      AxiosMain({
        method: 'post',
        url: '/v1/account/password/recovery',
        data: {
          email,
          login,
        },
      }),
    ),
};

export { AuthService };
