import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { ReactComponent as AccordionIconOpened } from './icons/AccordionIconOpened.svg';
import { ReactComponent as AccordionIconClosed } from './icons/AccordionIconClosed.svg';

import { StyledAccordion, StyledHeader, StyledTitle, StyledBody } from './styled';

function Accordion(props) {
  const { titulo, isRequired, children } = props;

  const [isOpen, setIsOpen] = useState(isRequired);

  const toggle = () => setIsOpen(prevState => !prevState);

  return (
    <StyledAccordion {...props}>
      <StyledHeader onClick={toggle}>
        <StyledTitle>
          {titulo} {isRequired && '*'}
        </StyledTitle>
        {isOpen ? <AccordionIconOpened width={10} height={10} /> : <AccordionIconClosed width={10} height={10} />}
      </StyledHeader>
      <StyledBody isOpen={isOpen}>{children}</StyledBody>
    </StyledAccordion>
  );
}

Accordion.propTypes = {
  title: PropTypes.string.isRequired,
  isRequired: PropTypes.bool,
  children: PropTypes.node,
};

Accordion.defaultProps = {
  title: '',
  isRequired: false,
};

export default Accordion;
