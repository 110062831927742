import { colors } from 'assets/styled/tokens';

const { css } = require('styled-components');

const primary = () => css`
  background: ${colors.primary};
  border-color: ${colors.primary};
  color: ${colors.white};
  &:disabled {
    background: ${colors.pampas};
    color: ${colors.silver};
  }
`;

export { primary };
