import styled from 'styled-components';

import { DefaultLabel } from 'assets/styled/sheets/components/Forms';

import { colors, fonts } from 'assets/styled/tokens';

export const StyledField = styled.div`
  position: relative;
  width: 100%;
  //font-size: 14px;
  //font-weight: 700;

  input {
    font-family: ${fonts.family.text};
    color: ${colors.gray};
  }
`;

export const StyledLabel = styled.label`
  ${DefaultLabel}

  color: ${props => props.disabled && colors.mediumGray};
  font-size: 14px;
  font-weight: 700;
  span {
    color: ${colors.primary};
  }
`;

export const StyledError = styled.div`
  display: block;
  transform: translateY(2px);
  font-size: 12px;
  margin-left: 2px;
  p {
    line-height: initial;
    color: ${colors.darkRed};
  }
  transition: all 300ms ease;
`;
