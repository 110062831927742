/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import * as React from 'react';
import ReactApexChart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';

import { ReactComponent as UpdateIcon1 } from '../../assets/icons/spinnig-arrows-icon.svg';
import { ReactComponent as UpdateErrorIcon } from '../../assets/icons/exclamation.svg';
import { ApexChartTypes, ChartsParamsType } from './types';
import {
  ChartCard,
  ChartCardBody,
  ChartCardHeader,
  ChartCardIcon,
  ChartCardSettingsIcon,
  ChartCardTitle,
  ChartHeaderButton,
  ChartTryAgain,
  ErrorUpdateMessage,
  LastUpdateData,
  UpdateChartButton,
} from './styled';
import { LkpAlert, LkpLoading } from 'components';
import { Button } from 'components/Button';
import ChartTooltip from 'components/Charts/ChartToolTip';
import { Tooltip } from './TooltTip';
import useTooltip from './../../hooks/useToolTip';

export interface LkpChartProps extends ApexChartTypes {
  companyId?: number;
  error?: boolean;
  errorMessage?: string;
  loading?: boolean;
  options?: ApexOptions;
  series?: Array<any>;
  title?: string;
  filter?: React.ReactNode;
  children?: React.ReactNode;
  chart?: boolean;
  onSettingsClick?: () => void;
  onChartTryAgain?: () => void;
  chartUpdate?: () => void;
  setParams?: (params: ChartsParamsType) => void;
  isTecHour?: boolean;
  lastUpdateMessage?: any;
  lastUpdate?: () => void;
}

export const LkpChart = ({
  companyId,
  error = false,
  errorMessage,
  height = 300,
  loading = false,
  filter,
  options,
  series,
  title,
  type = 'bar',
  width,
  onSettingsClick,
  onChartTryAgain,
  children,
  chart,
  isTecHour = false,
  chartUpdate,
  lastUpdate,
  lastUpdateMessage,
  //setParams,
  ...props
}: LkpChartProps): React.ReactElement => {
  const [full, setFull] = React.useState(false);
  const cardRef = React.useRef<HTMLDivElement>(null);
  const [size, setSize] = React.useState<any>(cardRef.current?.offsetWidth);
  const { showTooltip, handleMouseEnter, handleMouseLeave } = useTooltip();
  const chartBody = () => {
    if (error) {
      return (
        <ChartTryAgain>
          <LkpAlert>{errorMessage || `Oops, ocorreu um erro ao buscar esses dados`}</LkpAlert>
          <Button>
            <span onClick={() => (onChartTryAgain ? onChartTryAgain() : null)}>Tentar novamente</span>
          </Button>
        </ChartTryAgain>
      );
    }

    if (!companyId) {
      return <LkpAlert>Nenhuma empresa selecionada.</LkpAlert>;
    }

    if (!series || series.length <= 0)
      return (
        <ChartTryAgain>
          <LkpAlert>O gráfico está vazio!</LkpAlert>
          <Button>
            <span onClick={() => (onChartTryAgain ? onChartTryAgain() : null)}>Tentar novamente</span>
          </Button>
        </ChartTryAgain>
      );
    return (
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      <ReactApexChart
        options={options}
        series={series}
        type={type}
        height={height}
        width={width ? width : cardRef.current ? size - 30 : 500}
        {...props}
      />
    );
  };
  React.useEffect(() => {
    setSize(cardRef.current?.offsetWidth);
  }, [cardRef.current?.offsetWidth]);
  const othersCorrectivesTooltipMessage =
    'Ocorrências do tipo: Acompanhamento; Melhoria; Processo ou Situação de Risco';
  const getDate = (dateMessage: any) => {
    const dateString = new Date(dateMessage);
    const date = dateString.toLocaleDateString();
    const time = dateString.toLocaleTimeString();
    return ` ${time} • ${date} `;
  };
  const UpdateChart = (e: any) => {
    e.preventDefault();
    chartUpdate ? chartUpdate() : null;
    onChartTryAgain ? onChartTryAgain() : null;
    lastUpdate ? lastUpdate() : null;
  };

  return (
    <ChartCard full={full} ref={cardRef}>
      <ChartCardHeader>
        {title && <ChartCardTitle>{title}</ChartCardTitle>}
        <div>
          {isTecHour && (
            <>
              {lastUpdateMessage === null || lastUpdateMessage === undefined || lastUpdateMessage === '' ? (
                <ErrorUpdateMessage>
                  <UpdateErrorIcon />
                  <p> Os dados podem estar desatualizados</p>
                </ErrorUpdateMessage>
              ) : (
                <LastUpdateData>Última atualização: {getDate(lastUpdateMessage)}</LastUpdateData>
              )}
              <UpdateChartButton
                onClick={(e: any) => UpdateChart(e)}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
              >
                <UpdateIcon1 />
                {showTooltip && (
                  <Tooltip
                    text={'Após clicar, aguarde alguns minutos e recarregue a página para atualizar o gráfico.'}
                  />
                )}
              </UpdateChartButton>
            </>
          )}
          {onSettingsClick && (
            <ChartHeaderButton onClick={onSettingsClick}>
              <ChartCardSettingsIcon />
            </ChartHeaderButton>
          )}
          <ChartHeaderButton
            onClick={() => {
              setSize(cardRef.current?.offsetWidth);
              setFull(!full);
            }}
          >
            <ChartCardIcon full={full} />
          </ChartHeaderButton>
        </div>
      </ChartCardHeader>
      {filter}
      <ChartTooltip isVisible={chart} message={othersCorrectivesTooltipMessage} chart={chart} />
      <ChartCardBody height={height} width={width}>
        {loading ? <LkpLoading /> : children ? <>{children}</> : chartBody()}
      </ChartCardBody>
    </ChartCard>
  );
};
