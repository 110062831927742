import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const TooltipText = styled.span`
  width: 160px;
  text-align: left;
  color: #fff;
  border-radius: 6px;
  position: relative;
  z-index: 1;
  left: 7%;
  margin-left: -75px;
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
`;

const TooltipContainer = styled.div`
  position: absolute;
  background-color: #333;
  color: #fff;
  border-radius: 5px;
  padding: 1px;
  top: -30px;
  left: -650%;
  transform: translateX(-50%);
  opacity: 0.8;
  display: block;
  z-index: 1;
  clip-path: polygon(0 0, 100% 0, 100% 0, 100% 100%, 0 100%);
  width: 480px;
  box-sizing: border-box;
  height: 20px;
`;
const ToolTipTip = styled.div`
  position: absolute;
  background-color: #333;
  box-sizing: border-box;
  color: #fff;
  padding: 10px;
  top: -14px;
  left: 50%;
  transform: translateX(-50%) rotate(360deg) scale(0.6);
  opacity: 0.8;
  display: block;
  z-index: 1;
  clip-path: polygon(0 0, 50% 50%, 100% 0);
`;

function Tooltip({ text }) {
  return (
    <>
      <TooltipContainer>
        <TooltipText>{text}</TooltipText>
      </TooltipContainer>
      <ToolTipTip />
    </>
  );
}

Tooltip.propTypes = {
  text: PropTypes.string.isRequired,
  className: PropTypes.string,
};

export { Tooltip };
