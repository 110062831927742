import React, { useEffect, useMemo, useState } from 'react';
import { StyledEditGeneralPlanButtonContainer, StyledOption, StyledOptionsContainer } from './styled';
import { useHistory } from 'react-router-dom';

import { ReactComponent as TripleCircle } from 'assets/icons/triple-circle.svg';
import { GeneralPlansService } from 'services/reports/endpoints/GeneralPlansService';
import { createGenericErrorToast } from 'utils/createToast';

function EditGeneralPlanButton({ planId, handleDeleteDraft, handleDeactivatePlan, companyId, setToastList }) {
  const history = useHistory();
  const [activeStatus, setActiveStatus] = useState(undefined);
  const [activeValue, setActiveValue] = useState('none');
  const handleAction = action => {
    if (action === 'edit') {
      history.push(`/atividades/planos-gerais/${planId}`);
    } else if (action === 'deleteDraft') {
      handleDeleteDraft(planId);
    } else if (action === 'deactivate') {
      handleDeactivatePlan(planId);
    }
    setActiveValue('none');
  };
  const activeOptions = useMemo(() => {
    switch (activeStatus) {
      case 'Agendado':
        return [
          { value: 'edit', label: 'Editar' },
          { value: 'deactivate', label: 'Inativar' },
        ];
      case 'Inativo':
        return [];
      case 'Rascunho':
        return [
          { value: 'edit', label: 'Editar' },
          { value: 'deleteDraft', label: 'Descartar' },
        ];

      default:
        return [];
    }
  }, [activeStatus]);
  useEffect(() => {
    if (companyId && planId) {
      GeneralPlansService.getPlanStatus({
        planId: planId,
        companyId: companyId,
      })
        .then(result => {
          setActiveStatus(result);
        })
        .catch(() => {
          createGenericErrorToast(setToastList);
        });
    }

    // eslint-disable-next-line
  }, [planId, companyId]);
  const isEditButtonVisible = useMemo(() => {
    return activeOptions.length !== 0;
  }, [activeOptions]);
  return (
    <StyledEditGeneralPlanButtonContainer isActive={isEditButtonVisible}>
      <TripleCircle />
      <StyledOptionsContainer
        value={activeValue}
        onChange={e => {
          e.persist();
          setActiveValue(e.target.value);
          handleAction(e.target.value);
        }}
      >
        {activeOptions?.map((option, i) => {
          return (
            <StyledOption key={i} value={option.value}>
              {option.label}
            </StyledOption>
          );
        })}
        <StyledOption style={{ display: 'none' }} value="none"></StyledOption>
      </StyledOptionsContainer>
    </StyledEditGeneralPlanButtonContainer>
  );
}

export default EditGeneralPlanButton;
