import { colors } from 'assets/styled/tokens';
import { StyledBatteryChargePercentage } from 'components/Battery/BatteryCharge/styled';
import styled from 'styled-components';

export const StyledTimelineStop = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 32px;
`;
export const StyledTimelineStopTitle = styled.div`
  display: flex;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  margin-bottom: 8px;
  align-items: flex-start;

  > svg {
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 8px;
    margin-right: 8px;
  }
  color: ${({ isDarkRed }) => (isDarkRed ? colors.darkRed : colors.carbon)};
`;
export const StyledTimelineStopIcon = styled.div`
  height: 20px;
  z-index: 1;
  width: 20px;
  margin-right: 2px;
  margin-top: -3px;
`;
export const StyledBatteryTimeContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
`;
export const StyledTimelineStopContent = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 23px;
  font-weight: 400;
  * {
    color: ${colors.gray};
    font-size: 14px;
    line-height: 16px;
  }
  ${StyledBatteryChargePercentage} {
    flex-direction: row;
    align-items: center;
    margin-top: -4px;
    margin-left: 8px;

    > span {
      margin-top: 6px;
      margin-left: 4px;
    }
  }
`;
export const StyledTimelineStopTime = styled.div`
  font-size: 14px;
  color: ${colors.gray};
  line-height: 16px;
  margin-right: 8px;
`;
export const StyledTimelineStopAddress = styled.div`
  display: flex;
  align-items: center;
  svg {
    margin-right: 8px;
  }
`;
export const StyledTimelineStopUnit = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  svg {
    margin-right: 8px;
  }
`;
export const StyledTimelineStopApplicationsList = styled.ul``;
export const StyledTimelineStopApplicationsListItem = styled.li`
  display: flex;
  align-items: center;
  margin-bottom: 4px;
  margin-left: 1;
  svg {
    height: 6px;
    width: 6px;
    margin-right: 5px;
  }
`;
export const StyledTimelineStopApplicationsListTitle = styled.div`
  margin-bottom: 8px;
`;
