import React from 'react';
import { useTable } from 'react-table';
import { ReactComponent as UpdateIcon } from 'assets/icons/update-icon.svg';

import { ContainerTable, TitleReport, UpdateGeneratedReportsButton, SubHeader } from './styles';
import { GenericTooltip } from 'components/Tooltip/GenericTooltip';

function Table({ columns, data }) {
  // Use the state and functions returned from useTable to build your UI
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
    columns,
    data,
  });
  // Render the UI for your table
  return (
    <table {...getTableProps()}>
      <thead>
        {headerGroups.map(headerGroup => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map(column => (
              <th {...column.getHeaderProps()}>
                {column.render('Header')}{' '}
                {column.Header === 'Arquivo' && (
                  <GenericTooltip
                    afterTop={'86%'}
                    afterLeft={'32%'}
                    bottom={'140%'}
                    left={'25px'}
                    text="Fotos contabilizadas: A quantidade de fotos exibida no arquivo pode ser menor do que a contabilizada, caso a opção 'Agrupar fotos' esteja ativada nos filtros."
                  />
                )}{' '}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map(row => {
          prepareRow(row);
          return (
            <tr {...row.getRowProps()}>
              {row.cells.map(cell => {
                return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>;
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}

export default function ReportTable({ title, columns, data, updateGeneratedReports, id }) {
  return (
    <ContainerTable>
      <SubHeader>
        <TitleReport id={id}>{title}</TitleReport>
        <UpdateGeneratedReportsButton onClick={e => updateGeneratedReports(e)}>
          <UpdateIcon />
          Atualizar Lista
        </UpdateGeneratedReportsButton>
      </SubHeader>
      <Table columns={columns} data={data} />
    </ContainerTable>
  );
}
