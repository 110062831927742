import { ApexOptions } from 'apexcharts';
import { CHART_DEFAULT_OPTIONS } from '../options';

export function getChartOptions(categories: Array<number | string> = [], options = {}): ApexOptions {
  const { yaxis, dataLabels, ...defaultOptions } = CHART_DEFAULT_OPTIONS;

  return {
    ...defaultOptions,
    xaxis: { categories },
    yaxis: {
      ...yaxis,
      labels: {
        formatter: function (value) {
          return `${value}`;
        },
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '55%',
      },
    },

    colors: [
      '#00C0C7',
      '#5144D3',
      '#E8871A',
      '#DA3490',
      '#9089FA',
      '#47E26F',
      '#2780EB',
      '#6F38B1',
      '#DFBF03',
      '#CB6F10',
      '#268D6C',
      '#9BEC54',
      '#3366CC',
      '#DC3912',
      '#FF9900',
      '#109618',
      '#990099',
      '#0099C6',
      '#DD4477',
      '#66AA00',
      '#316395',
      '#994499',
      '#22AA99',
      '#AAAA11',
      '#6633CC',
      '#E67300',
      '#8B0707',
      '#CCCCCC',
      '#651067',
      '#329262',
    ],
    dataLabels: {
      ...dataLabels,
      formatter: function (value) {
        return `${value}`;
      },
    },
    tooltip: {
      y: {
        formatter: function (value) {
          return `${value}`;
        },
      },
    },
    stroke: {
      show: false,
    },
    ...options,
  };
}
