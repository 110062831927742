import React from 'react';
import { TransparentBgPhotos, ModalPhotos, ModalHeader, CloseModalButton } from './styled';
import { Carousel } from '../Carousel';
export const PhotosModal = ({ photos, setIsPhotosOpen }) => {
  const handleOverlayClick = (e, setState) => {
    if (e.target === e.currentTarget) {
      setState(false);
    }
  };
  return (
    <TransparentBgPhotos onClick={e => handleOverlayClick(e, setIsPhotosOpen)}>
      <ModalPhotos>
        <ModalHeader>
          Fotos
          <CloseModalButton onClick={() => setIsPhotosOpen(false)}>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
              <path d="M1 1L15 15" stroke="#BBBBBB" strokeWidth="2" stroke-linecap="round" />
              <path d="M15 1L1 15" stroke="#BBBBBB" strokeWidth="2" stroke-linecap="round" />
            </svg>
          </CloseModalButton>
        </ModalHeader>
        <Carousel photos={photos} />
      </ModalPhotos>
    </TransparentBgPhotos>
  );
};
