import React, { useRef } from 'react';
import MapGL, { Marker } from '@urbica/react-map-gl';
import Cluster from '@urbica/react-map-gl-cluster';
import ClusterMarker from './ClusterMarker';
import mapboxgl from 'mapbox-gl';
import { useGeolocalizeStore } from 'pages/Geolocalize/store';
import { UPDATE_VIEWPORT } from 'pages/Geolocalize/constants';

mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;

// import { ReactComponent as UnitMarkerIcon } from "../../assets/unit-marker-icon.svg";

const style = {
  width: '20px',
  height: '20px',
  color: '#fff',
  background: '#1978c8',
  borderRadius: '20px',
  textAlign: 'center',
};

const ReactMapbox = ({ unitMarkersData, children, isUnitClustersActive }) => {
  const clusterRef = useRef();
  const [{ viewport }, dispatch] = useGeolocalizeStore();
  const setViewport = nextViewport => {
    dispatch({ type: UPDATE_VIEWPORT, payload: nextViewport });
  };
  const onMapClick = () => {
    setViewport(prevViewport => ({
      ...prevViewport,
    }));
  };

  // const onMarkerClick = event => {
  //   alert("You clicked on marker");
  //   event.stopPropagation();
  // };

  const onClusterClick = cluster => {
    const { clusterId, longitude, latitude } = cluster;

    const supercluster = clusterRef.current.getCluster();
    const zoom = supercluster.getClusterExpansionZoom(clusterId);

    setViewport({ ...viewport, latitude, longitude, zoom });
  };

  return (
    <MapGL
      mapStyle="mapbox://styles/leankeep/ckya4uoy21vkm14t9o2m70708"
      accessToken={process.env.REACT_APP_MAPBOX_TOKEN}
      antialias={true}
      onViewportChange={nextViewport => setViewport(nextViewport)}
      onClick={onMapClick}
      {...viewport}
    >
      {isUnitClustersActive && (
        <Cluster
          ref={clusterRef}
          radius={40}
          extent={512}
          nodeSize={64}
          component={cluster => <ClusterMarker onClick={() => onClusterClick(cluster)} {...cluster} />}
        >
          {unitMarkersData.map(point => (
            <Marker key={point.id} longitude={point.longitude} latitude={point.latitude}>
              <div style={style} />
            </Marker>
          ))}
        </Cluster>
      )}
      {children}
    </MapGL>
  );
};

export default ReactMapbox;
