import styled from 'styled-components';

import { colors, medias } from 'assets/styled/tokens';

export const StyledUserMenu = styled.div`
  position: relative;

  button {
    position: relative;
    font-size: 0.8125rem;
    line-height: 1rem;
    padding: 0.125rem 0 0.125rem 0.625rem;

    span {
      font-weight: 700;
      color: ${colors.primary};
      margin-right: 5px;
    }
  }

  ul {
    position: absolute;
    top: 2.25rem;
    left: 0;
    width: 100%;
    border-radius: 6px;
    background-color: white;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.3);
    z-index: 1;

    &:before {
      position: absolute;
      top: -0.375rem;
      left: 50%;
      display: block;
      border-right: 7px solid transparent;
      border-bottom: 7px solid white;
      border-left: 7px solid transparent;
      z-index: 3;
      transform: translateX(-50%);
    }

    li {
      background-color: white;
      font-size: 0.75rem;
      color: ${colors.gray};
      transition: background-color ease-in-out 210ms;

      a,
      span {
        display: block;
        padding: 0.25rem 1rem;
        cursor: pointer;
        text-decoration: none;
        color: ${colors.gray};
      }

      &:first-child {
        border-radius: 6px 6px 0 0;
      }

      &:last-child {
        border-radius: 0 0 6px 6px;
      }

      &:hover {
        background-color: ${colors.pampas};
      }
    }
  }

  @media screen and (max-width: ${medias.tablet}) {
    width: 100%;
    padding: 1rem;
    border-top: 1px solid ${colors.whiteGray};

    button {
      padding: 0.125rem 2rem 0.125rem 0;
    }

    ul {
      position: relative;
      box-shadow: none;
      top: 0.5rem;

      &::before {
        display: none;
      }

      li {
        background: ${colors.pampas};
        border-bottom: none;
        display: flex;
        padding: 1px 0;

        &::before {
          display: inline-block;
          margin-right: 8px;
          color: ${colors.gray};
          content: '•';
        }

        &:last-child {
          border-bottom: 0px;
        }

        a,
        span {
          padding: 0;
        }
      }
    }
  }
`;
