import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { devices } from 'assets/styled/tokens/devices';
import tooltipArrow from 'assets/icons/blackTooltipArrow.svg';
import { ReactComponent as BlueWarningIcon } from 'assets/icons/blue-warning.svg';
const TooltipText = styled.span`
  visibility: hidden;
  width: 360px !important;
  text-align: left;
  background-color: #1e1e1e !important;
  color: #fff;
  border-radius: 6px !important;
  padding: 8px;
  position: absolute;
  z-index: 1;
  bottom: ${props => props.bottom};
  left: ${props => props.left};
  margin-left: -75px;
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  border: none !important;

  &:after {
    content: url(${tooltipArrow});
    display: inline-flex;
    position: absolute;
    top: ${props => props.afterTop};
    left: ${props => props.afterLeft};
    margin-left: -3px;
    border-width: 3px;
    width: 10px;
    overflow: hidden;
    //border-style: solid;
    //border-color: #666666 transparent transparent transparent;
  }
  @media ${devices.tabletLandscape} {
    margin-left: -137px;
    width: 291px;
  }
`;

const TooltipContainer = styled.div`
  position: relative;
  display: inline-flex;
  svg {
    margin-top: auto;
    margin-bottom: auto;
    path {
      fill: white !important;
    }
  }
  :hover span {
    visibility: visible;
  }
`;

function GenericTooltip({ text, className = '', bottom, left, afterTop, afterLeft }) {
  return (
    <TooltipContainer className={className}>
      <BlueWarningIcon />
      <TooltipText bottom={bottom} left={left} afterLeft={afterLeft} afterTop={afterTop}>
        {text}
      </TooltipText>
    </TooltipContainer>
  );
}

GenericTooltip.propTypes = {
  text: PropTypes.string.isRequired,
  className: PropTypes.string,
};

export { GenericTooltip };
