import styled from 'styled-components';
import { colors } from 'assets/styled/tokens';

export const StyledRadio = styled.label`
  display: block;
  position: relative;
  //cursor: not-allowed;
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  caret-color: ${props => (props.disabled ? 'red' : 'auto')};
  padding-left: 16px;
  color: ${props => (props.disabled ? colors.gray : colors.black)};
  font-size: 14px;

  &:hover input ~ span {
    background: ${colors.pampas} !important;
  }

  input:checked ~ span {
    border-color: ${props => (props.disabled ? colors.gray : colors.primary)} !important;
  }

  input:checked ~ span:after {
    display: block !important;
    background: ${props => (props.disabled ? colors.gray : colors.primary)} !important;
  }

  span:after {
    top: 2px !important;
    left: 2px !important;
    width: 6px !important;
    height: 6px !important;
    border-radius: 50% !important;
    background: white !important;
    content: '';
    position: absolute;
    display: none;
  }

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }

  span {
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    left: 0;
    width: 12px;
    height: 12px;
    background: ${colors.white} !important;
    border: 1px solid ${colors.alto2} !important;
    border-radius: 50% !important;
  }
`;
