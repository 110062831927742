const pagination = (itens = [], quantityPerPage) => {
  const totalPages = Math.ceil(itens.length / quantityPerPage);

  const pages = [...Array(totalPages).keys()];
  const startIndex = 0;
  const endIndex = pages.length - 1;

  return {
    totalPages,
    quantityPerPage,
    pages,
    startIndex,
    endIndex,
  };
};

export { pagination };
