import * as React from 'react';
import {
  ChartFilterDateGroupTop,
  ChartFilterDateInput,
  ChartFilterDateLabel,
  ChartFilterDateTitle,
  ChartFilterDateTop,
  StyledVerticalLine,
} from './styled';

export interface LkpFilterDateProps {
  onChangeStartDate?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onChangeEndDate?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  defaultStartDate?: string;
  defaultEndDate?: string;
}

export function LkpFilterDate({
  onChangeStartDate,
  onChangeEndDate,
  defaultStartDate,
  defaultEndDate,
}: LkpFilterDateProps): React.ReactElement {
  return (
    <React.Fragment>
      {(onChangeStartDate || onChangeEndDate) && (
        <ChartFilterDateTop>
          <ChartFilterDateGroupTop>
            <svg width="10" height="12" viewBox="0 0 10 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M0 10.875C0 11.5078 0.46875 12 1.07143 12H8.92857C9.50893 12 10 11.5078 10 10.875V4.5H0V10.875ZM7.14286 6.28125C7.14286 6.14063 7.25446 6 7.41071 6H8.30357C8.4375 6 8.57143 6.14063 8.57143 6.28125V7.21875C8.57143 7.38281 8.4375 7.5 8.30357 7.5H7.41071C7.25446 7.5 7.14286 7.38281 7.14286 7.21875V6.28125ZM7.14286 9.28125C7.14286 9.14062 7.25446 9 7.41071 9H8.30357C8.4375 9 8.57143 9.14062 8.57143 9.28125V10.2188C8.57143 10.3828 8.4375 10.5 8.30357 10.5H7.41071C7.25446 10.5 7.14286 10.3828 7.14286 10.2188V9.28125ZM4.28571 6.28125C4.28571 6.14063 4.39732 6 4.55357 6H5.44643C5.58036 6 5.71429 6.14063 5.71429 6.28125V7.21875C5.71429 7.38281 5.58036 7.5 5.44643 7.5H4.55357C4.39732 7.5 4.28571 7.38281 4.28571 7.21875V6.28125ZM4.28571 9.28125C4.28571 9.14062 4.39732 9 4.55357 9H5.44643C5.58036 9 5.71429 9.14062 5.71429 9.28125V10.2188C5.71429 10.3828 5.58036 10.5 5.44643 10.5H4.55357C4.39732 10.5 4.28571 10.3828 4.28571 10.2188V9.28125ZM1.42857 6.28125C1.42857 6.14063 1.54018 6 1.69643 6H2.58929C2.72321 6 2.85714 6.14063 2.85714 6.28125V7.21875C2.85714 7.38281 2.72321 7.5 2.58929 7.5H1.69643C1.54018 7.5 1.42857 7.38281 1.42857 7.21875V6.28125ZM1.42857 9.28125C1.42857 9.14062 1.54018 9 1.69643 9H2.58929C2.72321 9 2.85714 9.14062 2.85714 9.28125V10.2188C2.85714 10.3828 2.72321 10.5 2.58929 10.5H1.69643C1.54018 10.5 1.42857 10.3828 1.42857 10.2188V9.28125ZM8.92857 1.5H7.85714V0.375C7.85714 0.1875 7.67857 0 7.5 0H6.78571C6.58482 0 6.42857 0.1875 6.42857 0.375V1.5H3.57143V0.375C3.57143 0.1875 3.39286 0 3.21429 0H2.5C2.29911 0 2.14286 0.1875 2.14286 0.375V1.5H1.07143C0.46875 1.5 0 2.01562 0 2.625V3.75H10V2.625C10 2.01562 9.50893 1.5 8.92857 1.5Z"
                fill="#F38A00"
              />
            </svg>

            <ChartFilterDateTitle>Período: </ChartFilterDateTitle>
          </ChartFilterDateGroupTop>
          <StyledVerticalLine />
          {onChangeStartDate && (
            <ChartFilterDateGroupTop>
              <ChartFilterDateLabel>De</ChartFilterDateLabel>
              <ChartFilterDateInput type="date" defaultValue={defaultStartDate} onChange={onChangeStartDate} />
            </ChartFilterDateGroupTop>
          )}
          <StyledVerticalLine />
          {onChangeEndDate && (
            <ChartFilterDateGroupTop>
              <ChartFilterDateLabel>Até</ChartFilterDateLabel>
              <ChartFilterDateInput type="date" defaultValue={defaultEndDate} onChange={onChangeEndDate} />
            </ChartFilterDateGroupTop>
          )}
        </ChartFilterDateTop>
      )}
    </React.Fragment>
  );
}
