import React from 'react';
import PropTypes from 'prop-types';

import { StyledInformativeTooltip } from './styled';

import { ReactComponent as QuestionMarkIcon } from './assets/question-mark.svg';

function InformativeTooltip(props) {
  return (
    <StyledInformativeTooltip>
      <QuestionMarkIcon> </QuestionMarkIcon>
      <span className={props.className}>{props.message}</span>
    </StyledInformativeTooltip>
  );
}

InformativeTooltip.propTypes = {
  className: PropTypes.string,
  message: PropTypes.string,
};

InformativeTooltip.defaultProps = {
  className: '',
  message: '',
};

export default InformativeTooltip;
