/* istanbul ignore file */

import styled from 'styled-components';

export const StyledFlexRow = styled.div`
  display: flex;
  flex-direction: row;
`;
export const StyledFlexColumn = styled.div`
  display: flex;
  flex-direction: column;
`;
