const adapt = httpResponse => ({
  ...httpResponse,
  data: httpResponse.data.map(unidade => ({
    id: unidade.site,
    name: unidade.nome,
    logoPath: unidade.logomarcaPath,
    city: unidade.cidade && {
      id: unidade.cidade.cidadeId,
      name: unidade.cidade.nome,
      state: unidade.cidade.estado && {
        id: unidade.cidade.estado.estadoId,
        name: unidade.cidade.estado.nome,
        initials: unidade.cidade.estado.sigla,
        countryId: unidade.cidade.estado.paisId,
      },
    },
    unitSubgroup: unidade.subGrupoUnidade && {
      id: unidade.subGrupoUnidade.subGrupoUnidadeId,
      name: unidade.subGrupoUnidade.nome,
      unitGroup: unidade.subGrupoUnidade.grupoUnidade && {
        id: unidade.subGrupoUnidade.grupoUnidade.grupoUnidadeId,
        name: unidade.subGrupoUnidade.grupoUnidade.nome,
      },
    },
  })),
});

const getAllUnitiesAdapter = httpResponsePromise =>
  httpResponsePromise.then(
    resolve => adapt(resolve),
    reject => reject,
  );

export { getAllUnitiesAdapter };
