import styled from 'styled-components';
import { colors } from 'assets/styled/tokens';
import checkIcon from 'assets/icons/check.svg';

export const StyledCheckbox = styled.label`
  width: 100%;
  display: flex;
  align-items: center;

  p {
    margin-left: 4px;
    font-size: 14px;
    line-height: 20px;
    color: ${colors.gray};
  }

  span {
    width: 12px;
    height: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${colors.white};
    border: 2px solid ${colors.silver};
    border-radius: 3px;
  }

  input:checked + span {
    background: ${colors.primary};
    border-color: ${colors.primary};

    &::after {
      content: '';
      display: block;
      width: 0.625rem;
      height: 0.625rem;
      background: url(${checkIcon}) no-repeat center/contain;
    }
  }
`;

export const StyledInput = styled.input`
  display: none;
`;
