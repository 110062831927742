import styled from 'styled-components';

import { colors, devices, medias } from 'assets/styled/tokens';
import { ReactComponent as CloseGrayIcon } from 'assets/icons/close-gray.svg';
import { ReactComponent as SuccessCheckmark } from 'assets/icons/success-checkmark.svg';

import MultiSelect from 'components/Form/subcomponents/MultiSelect';
import { FormGroup } from 'components/Form/subcomponents/FormGroup';
import { Button } from 'components/Button';
import { Input } from 'components/Form/subcomponents/Input';
import { Checkbox } from 'components/Form/subcomponents/Checkbox';
import { MaskedInput } from 'components/Form/subcomponents/MaskedInput';
import { DatePicker } from 'components/Form/subcomponents/DatePicker';
import { TimeInput } from 'components/Form/subcomponents/TimeInput';
import { FormSubHeader, StyledAddUnitsModalHeaderUnderline } from '../NewGeneralPlanPage/styled';
import { ModalGeneric } from 'components/ModalGeneric';
import { StyledInputTooltip } from 'components/Form/subcomponents/InputTooltip/styled';

export const StyledFormSectionNavbar = styled.nav`
  display: flex;
  justify-content: flex-start;
  margin-bottom: -5px;
`;
export const ApplicationFormUnitSubHeader = styled(FormSubHeader)`
  margin-bottom: 0;
`;
export const StyledApplicationForm = styled.div`
  display: ${props => (props.isActive ? 'flex' : 'none')};
  flex-direction: column;
  align-self: center;
  padding: 26px 0 0 0;
  width: 100%;
  transition: all 300ms ease;
`;
export const StyledNavItemNumber = styled.div`
  color: ${colors.primary};
`;
export const StyledNavItemCheckmark = styled.svg`
  display: ${props => (props.isActive ? 'inline-flex' : 'none')};
  color: #03725f;
  margin-right: 10px;
`;
export const StyledFormsContainerWrapper = styled.section`
  background: white;
  border-radius: 8px;
  box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.1);
`;
export const StyledFormsContainer = styled.main`
  display: flex;
  padding: 24px;
  flex-direction: column;
`;
export const StyledFormHeaderContainer = styled.div`
  display: flex;
  height: 56px;
  justify-content: flex-start;
  border-bottom: 1px solid ${colors.whiteGray};
  padding: 16px 24px;
`;

export const StyledFormGroup = styled(FormGroup)`
  flex-direction: column;
  display: flex;
`;
export const StyledRadioGroup = styled(StyledFormGroup)`
  flex-direction: row;
  font-size: 12px;
  color: ${colors.gray};
  font-weight: 400;
  align-items: center;
  ${StyledInputTooltip} {
    span {
      top: -2.7rem;
      font-family: Roboto;
      font-weight: 500;
    }
  }
  label:nth-child(1) {
    margin-right: 24px;
  }

  label {
    margin-right: 20px;
    font-family: Roboto, sans-serif;
    margin-bottom: 0;
  }
`;

export const ApplyToAreaEquipTypeHeader = styled(FormSubHeader)`
  display: flex;
  align-items: center;
  margin-top: 48px;
  margin-bottom: 1px;
  white-space: nowrap;
  border-top: 1px solid ${colors.whiteGray};
  padding-top: 21px;
  ${StyledRadioGroup} {
    margin-left: 24px;
  }
`;

export const StyledDeactivatePlanButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  font-weight: 500;
  color: ${colors.carbon};
  border: 1px solid ${colors.whiteGray};
  padding: 5px 8px;
  margin-left: 24px;
  border-radius: 4px;
  background: ${colors.white};

  transition: all ease-in 300ms;
`;
export const StyledSchedulePlanWarningPill = styled.div`
  display: flex;
  align-items: center;
  border-radius: 4px;
  justify-content: center;
  overflow: hidden;
  width: ${props => (props.isActive ? `323px` : 0)};
  height: 24px;
  margin: ${props => (props.isActive ? `0 16px` : 0)};
  padding: ${props => (props.isActive ? `0 8px` : 0)};
  opacity: ${props => (props.isActive ? `1` : 0)};
  white-space: nowrap;

  font-size: 12px;
  font-weight: 500;
  color: ${colors.richGold};
  background: ${colors.dairyCream};
  svg:nth-child(1) {
    margin-right: 8px;
    circle {
      fill: ${colors.mangoTango};
    }
    path {
      fill: white;
    }
  }
  svg:nth-child(2) {
    margin-left: 8px;
  }
  transition: all 300ms ease;
`;
export const StyledSchedulePlanButton = styled(Button)`
  display: ${props => (props.isActive ? `flex` : `none`)};
  font-size: 12px;
  font-weight: 500;
  color: ${colors.white};
  width: ${props => (props.isActive ? `113px` : 0)};
  height: 24px;
  opacity: ${props => (props.isActive ? `1` : 0)};
  white-space: nowrap;
  padding: 0;
  border-radius: 4px;
  background: ${colors.primary};
  path,
  rect {
    fill: ${colors.white};
  }
  svg {
    margin-right: 8px;
  }

  transition: all 900ms ease;
`;
export const StyledSchedulePlanSuccessContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 48px;
`;
export const StyledSchedulePlanSuccessIcon = styled(SuccessCheckmark)`
  margin-top: 32px;
  margin-bottom: 16px;
`;
export const StyledSchedulePlanSuccessHeader = styled.h2`
  color: ${colors.successGreen};
  font-weight: 600;
  margin-bottom: 24px;
  font-size: 18px;
`;
export const StyledSchedulePlanSuccessButton = styled(Button)``;

export const StyledModalParagraph = styled.p`
  margin-top: 0;
  margin-bottom: 24px;
  line-height: 19px;
  color: ${colors.gray};
`;
export const StyledModalButtons = styled.div`
  display: flex;
  margin-bottom: 8px;
`;
export const StyledModalCancelButton = styled(Button)`
  background: white;
  border: 1px solid ${colors.whiteGray};
  color: ${colors.carbon};
  margin-right: 16px;
`;
export const StyledModalSchedulePlanButton = styled(Button)`
  color: ${colors.white};
  background: ${colors.primary};
  svg {
    margin-right: 8px;
  }
`;
export const StyledModalDeactivatePlanButton = styled(Button)`
  color: ${colors.white};
  background: ${colors.primary};
`;

export const StyledEditPlanOptionsContainer = styled.div`
  display: flex;
  margin-left: auto;
  font-size: 12px;

  color: ${colors.gray};
  font-weight: 400;
`;
export const StyledFormHeader = styled.div`
  display: inline-flex;
  flex-direction: column;
  font-family: Blinker, sans-serif;
  align-items: space-between;
  padding-left: 18px;
  font-weight: 600;
  font-size: 1.125rem;
  line-height: 21px;
  color: ${colors.primary};
`;
export const NextSectionArrow = styled.svg`
  margin-right: 18px;
`;
export const StyledSectionNavItemUnderline = styled.div``;
export const StyledFormSectionNavItem = styled.button`
  font-size: 12px;
  text-transform: uppercase;
  margin-right: 24px;
  padding-left: 0;
  padding-right: 0;
  ${StyledSectionNavItemUnderline} {
    margin-top: 8px;
    border-top: ${props => (props.isActive ? `2px solid ${colors.carbon}` : 'none')};
  }
  color: ${props => (props.isActive ? colors.carbon : colors.gray)};
  font-weight: ${props => (props.isActive ? '700' : '500')};
`;
export const StyledFormSection = styled.div`
  display: ${props => (props.isActive ? 'flex' : 'none')};
  flex-direction: column;
  margin-top: 24px;
`;
export const StyledPlanInfoSection = styled(StyledFormSection)`
  display: ${props => (props.isActive ? 'flex' : 'none')};
  flex-direction: column;
  align-items: center;
`;

export const ActivitiesTableContainer = styled.table`
  width: 100%;
  border-spacing: 0;
  border-radius: 8px;
  margin-bottom: 32px;
  th {
    padding: 17px 12px;
    border: 1px solid ${colors.whiteGray};
    text-align: left;
    font-size: 12px;
  }
  th:nth-child(1) {
    width: 340px;
  }
  th:nth-child(2) {
    width: 84px;
  }
  th:nth-child(3) {
    width: 121px;
  }
  th:nth-child(4) {
    width: 144px;
  }
  th:nth-child(5) {
    width: 334px;
  }
  th:nth-child(6) {
    width: 52px;
  }
  td {
    border: 1px solid ${colors.whiteGray};
    font-size: 12px;
    font-weight: 400;
    color: ${colors.gray};
  }
  border: 1px solid ${colors.whiteGray};
`;

export const StyledPlanInfoHeader = styled.h2`
  display: flex;
  font-size: 18px;
  line-height: 21px;
  color: ${colors.carbon};
  font-family: Blinker;
  font-weight: 600;
  margin-bottom: 24px;
  div {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: auto;
    width: auto;
    font-size: 12px;
    padding: 5px 8px;
    margin-left: 16px;
  }
  @media ${devices.screenWide} {
    font-weight: 700;
  }
`;
export const StyledCloseDeleteActivityModal = styled(Button)`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  font-weight: 500;
  color: ${colors.carbon};
  border: 1px solid ${colors.whiteGray};
  padding: 9px 12px;
  margin-right: 16px;
  border-radius: 4px;
  background: ${colors.white};

  transition: all ease-in 300ms;
`;
export const StyledDeleteActivityModalHeader = styled.h2`
  margin-bottom: 16px;
  font-size: 21px;
  line-height: 25px;
  margin-top: 8px;
  color: #ec6655;
  font-family: Blinker;
`;
export const StyledDeleteActivityModalParagraph = styled.p`
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  color: ${colors.gray};
`;
export const StyledDeleteActivityModalButtons = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 32px;
`;
export const StyledDeleteActivityModal = styled(ModalGeneric)`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 483px;
  max-width: 483px;
  padding: 24px 24px 32px 24px;
  div {
    width: 100%;
    align-items: center;
  }
  margin-bottom: 45px;
`;
export const StyledDeleteActivityButton = styled(Button)`
  background: #ec6655;
  color: white;
  display: flex;
  align-items: center;
  padding: 9px 12px;
  svg {
    margin-right: 6px;
  }
  border-radius: 6px;
  color: white;
`;
export const StyledExitModal = styled(ModalGeneric)`
  h6 {
  }
  p {
  }
`;
export const StyledAddUnitsModal = styled(ModalGeneric)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 728px;
  max-width: 728px;
  ${StyledAddUnitsModalHeaderUnderline} {
    width: 107.2%;
    margin-left: -3.6%;
  }
  overflow: visible;
  > div {
    width: 100%;
    align-items: flex-start;
  }
`;
export const StyledAddUnitButton = styled.button`
  width: 188px;
  border: 1px solid ${colors.primary};
  font-size: 12px;
  border-radius: 3px;
  font-weight: 500;
  color: ${colors.primary};
  padding: 6px;
  margin-bottom: 48px;
`;

export const StyledEmptyActivitiesContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
export const StyledEmptyActivitiesButtonsRow = styled.div`
  display: flex;
  justify-content: space-around;
  button {
    padding: 9px 12px;
  }
`;
export const StyledEmptyActivitiesIcon = styled.svg`
  margin-bottom: 16px;
`;
export const StyledEmptyActivitiesHeader = styled.h2`
  font-size: 18px;
  font-weight: 600;
  color: #aaaaaa;
  margin-bottom: 32px;
`;
export const StyledAddActivitiesButton = styled(Button)`
  font-weight: 500;
  color: white;
  background: ${colors.primary};
  border-radius: 4px;
  svg {
    margin-right: 8px;
  }
`;
export const StyledAddActivityButton = styled.button`
  background: ${colors.primary};
  height: 24px;
  padding: 9px 12px;
  svg {
    margin-right: 8px;
  }
  border-radius: 6px;
  color: white;
`;
export const StyledExitPageModalButton = styled(Button)`
  &:first-child {
    color: ${colors.carbon};
    margin-right: 16px;
  }
`;
export const StyledRealizedActivitiesModal = styled(ModalGeneric)`
  width: 390px;
  h6 {
    color: #ec6655;
  }
  div {
    text-align: center;
  }
  div > button {
    width: 89px;
    margin-bottom: 0;
  }
`;

export const StyledAddActivityFormModal = styled.form`
  position: absolute;
  background: white;
  border-radius: 8px;
  display: flex;
  flex-wrap: wrap;
  height: ${props => (props.isActive ? '660px' : 0)};
  max-height: 100vh;
  align-content: flex-start;
  width: ${props => (props.isActive ? '746px' : 0)};
  z-index: ${props => (props.isActive ? '10' : '-4')};
  opacity: ${props => (props.isActive ? `1` : 0)};
  overflow: hidden auto;
  padding: 19px 24px 24px;
  top: 7vh;
  bottom: 7vh;
  margin: auto;
  transition: all 600ms ease;
  overflow: scroll;
`;
export const StyledAddActivityFormModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 20px;
  border-bottom: 1px solid ${colors.whiteGray};
  margin-bottom: 24px;
  width: 100%;
  h3 {
    color: ${colors.primary};
    font-size: 21px;
    font-family: 'Blinker';

    font-weight: 700;
  }
`;
export const StyledCloseAddActivityFormModal = styled.div`
  position: absolute;
  top: 16px;
  right: 16px;
  cursor: pointer;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  &:hover {
    background: #eae8e5;
    path {
      stroke: #000;
    }
  }
  transition: all 300ms ease;
`;
export const StyledAdvanceFirstFormButtonRow = styled.div`
  display: flex;
  margin-top: 4px;
`;
export const StyledFormButtonsRow = styled.div`
  display: flex;
  margin-top: 24px;
  width: 552px;
`;
export const StyledReturnFormStepButton = styled(Button)`
  align-self: flex-start;
  background: white;
  color: ${colors.carbon};
  border: 1px solid ${colors.whiteGray};
  margin-right: 16px;
  svg {
    margin-right: 6px;
  }
`;
export const SubmitPlanInfoFormButton = styled(Button)`
  align-self: flex-start;
  svg {
    margin-left: 6px;
  }
`;
export const StyledAdvancePlanInfoFormButton = styled(Button)`
  align-self: flex-start;
  svg {
    margin-left: 6px;
  }
`;
export const StyledEditPlanForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;
export const StyledCheckbox = styled(Checkbox)`
  width: auto;
`;
export const StyledOtherDaysConsideredContainer = styled.div`
  display: flex;
  align-items: center;
  svg {
    margin-right: 24px;
  }
  p {
    font-size: 12px;
  }
`;
export const FrequencyInputsRow = styled.div`
  display: inline-flex;
`;
export const FrequencyInterval = styled.div`
  display: flex;
  margin-top: auto;
  margin-right: 30px;
  font-size: 12px;
  height: 19px;
  margin-left: 9px;
  color: ${colors.gray};
`;
export const StyledFormRow = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${colors.whiteGray};
  width: 100%;
  justify-content: space-between;
  padding-top: 24px;
  padding-bottom: 24px;
`;
export const StyledMeasurementsFormRow = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
  border-bottom: ${props => (props.isActive ? `1px solid ${colors.whiteGray}` : 'none')};
  margin-bottom: ${props => (props.isActive ? `21px` : '0')};
  div {
    margin-right: 0;
  }
  transition: all ease-in 300ms;
`;

export const StyledPlanInfoFormSection = styled.div`
  width: 552px;
  margin-top: 34px;

  > div {
    margin-bottom: 24px;
  }
`;

export const StyledPlanNameInput = styled(Input)``;

export const StyledInlineFormGroup = styled(StyledFormGroup)`
  display: inline-flex;

  width: 272px;
`;
export const StyledCategoryFormGroup = styled(StyledFormGroup)`
  width: 210px;
`;
export const StyledStartDateFormGroup = styled(StyledFormGroup)`
  width: 200px;
`;
export const StyledPriorityFormGroup = styled(StyledFormGroup)`
  width: 72px;
`;

export const StyledTimeFormGroup = styled(StyledFormGroup)`
  width: 60px;
`;
export const StyledAddMeasurementUnitButton = styled(Button)`
  border: none;
  background: #fff;
  margin-left: -9px;
  margin-top: 16px;
  margin-bottom: 24px;
  color: ${colors.primary};
  svg {
    margin-right: 8px;
  }
`;
export const StyledDateInput = styled(Input)`
  height: 47px;
  width: 180px;
  display: inline-flex;
  margin-right: 30px;
  flex-direction: column;
  input {
    flex-direction: row;
  }
`;
export const StyledDatePicker = styled(DatePicker)`
  height: 27px;
`;
export const StyledTimeInput = styled(TimeInput)`
  margin-right: 24px;
  display: inline-flex;
  flex-direction: column;

  input {
    flex-direction: row;
    margin-top: 4px;
  }
`;
export const StyledEstimatedTimeInput = styled(MaskedInput)`
  margin-right: 24px;
  display: inline-flex;
  flex-direction: column;
  input {
    margin-top: 4px;
    flex-direction: row;
  }
`;
export const StyledMeasurementUnitRow = styled.div`
  display: flex;
  position: relative;
  justify-content: space-between;
  width: 100%;
  border: 1px solid ${colors.whiteGray};
  border-top-width: ${props => (props.isFirst ? `1px` : 0)};
  border-radius: ${props => (props.isFirst ? `4px 4px 0 0` : props.isLast ? `0 0 4px 4px` : `0`)};
  padding: 16px;
`;
export const StyledMeasurementsFormGroup = styled(StyledFormGroup)`
  &:nth-child(1) {
    width: 200px;
  }
  width: 136px;
`;

export const StyledMeasurementsRadioGroup = styled(StyledRadioGroup)`
  height: ${props => (props.isActive ? `27px` : 0)};
  margin-bottom: ${props => (props.isActive ? `16px` : 0)};
  overflow: hidden;
  span {
    margin-right: 16px;
  }
`;
export const StyledMeasurementsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: ${props => (props.isActive ? props.height : '0')};
  opacity: ${props => (props.isActive ? 1 : 0)};
  transition: all 300ms ease-in;
`;

export const StyledDeleteMeasurementUnit = styled(CloseGrayIcon)`
  display: flex;
  position: absolute;
  top: 9px;
  right: 9px;
  cursor: pointer;
`;

export const PseudoMultiSelect = styled(MultiSelect)`
  cursor: pointer;
  input {
    pointer-events: none;
  }
`;
export const StyledColumnContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 40px 0;
`;

export const PageBackdrop = styled.div`
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100vw;
  background: rgba(0, 0, 0, 1);
  z-index: ${props => (props.isActive ? '9' : '-4')};

  opacity: ${props => (props.isActive ? '0.3' : 0)};
  transition: opacity 600ms ease-in;
`;
export const StyledFormItem = styled.div`
  display: block;
`;

export const StyledFieldsColumn = styled.div`
  display: inline-grid;
  align-items: flex-end;
  grid-template-columns: repeat(${props => (props.width ? props.width : '1')}, 1fr);
  column-gap: 15px;
  width: 100%;
  margin-top: ${props => (props.marginTop ? props.marginTop : '0')};

  @media screen and (max-width: ${medias.mobile}) {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
  }
`;

export const StyledSwitchesRow = styled.div`
  display: flex;
  column-gap: 15px;
  margin-top: 30px;
`;

export const StyledSwitchAppend = styled.div`
  max-width: 456px;
  width: 100%;
  background-color: ${colors.pampas};
  padding: 0.625rem;
  border: 1px solid ${colors.whiteGray};
  border-radius: 0px 3px 3px 3px;
`;

export const StyledResponsibleFormContainer = styled.div`
  background: ${colors.pampas};
  padding: 16px 8px;
  margin-bottom: 8px;
  border-radius: 0 3px 3px 3px;
  border: 1px solid ${colors.whiteGray};
  position: relative;
`;
