import React from 'react';
import PropTypes from 'prop-types';

import { StyledCleanInputPosition } from './styled';

import { ReactComponent as CloseGrayIcon } from 'assets/icons/close-gray.svg';

function CleanInputIcon(props) {
  const { size, onClick } = props;

  return (
    <StyledCleanInputPosition size={size} onClick={onClick}>
      <CloseGrayIcon width={8} height={8} />
    </StyledCleanInputPosition>
  );
}

CleanInputIcon.propTypes = {
  size: PropTypes.string,
  onClick: PropTypes.func.isRequired,
};

export default CleanInputIcon;
