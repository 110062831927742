import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { MultiCheckboxContainer, CheckboxRow } from './styled';
import Checkbox from './subcomponents/Checkbox';
const MultiCheckbox = React.forwardRef(props => {
  const [selecteds, setSelecteds] = useState([]);
  const [groupPhotos, setGroupPhotos] = useState(false);
  const { options = [], 'data-cy': dataCy, onChange, register } = props;
  const handleCheck = useCallback(
    e => {
      e.persist();
      if (e.target.checked) {
        if (e.target.name === 'Fotos') {
          setGroupPhotos(true);
        }
        setSelecteds([...selecteds, { label: e.target.name, value: `${e.target.checked}` }]);
        onChange && onChange([...selecteds, { label: e.target.name, value: `${e.target.checked}` }]);
      } else {
        if (e.target.name === 'Fotos') {
          setGroupPhotos(false);
        }
        const newState = selecteds.filter(item => `${item.label}` !== `${e.target.name}`);
        setSelecteds(newState);
        onChange && onChange(newState);
      }
    },
    [onChange, selecteds],
  );
  return (
    <MultiCheckboxContainer>
      {options.map((option, index) => (
        <CheckboxRow key={index}>
          <Checkbox
            key={index}
            label={option.label}
            data-cy={`${dataCy}-option`}
            onChange={handleCheck}
            groupPhotos={groupPhotos}
            name={option.label}
            register={register}
          />
        </CheckboxRow>
      ))}
    </MultiCheckboxContainer>
  );
});

MultiCheckbox.propTypes = {
  text: PropTypes.string,
};

export { MultiCheckbox };
