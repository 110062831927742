import { CircleInfo } from './Components/CircleInfo';
import { CirclesContainer, DashBoardContainer, DashButton, DashboardInnerContainer, TitleContaniner } from './styled';
import { useHistory } from 'react-router-dom';
export const HomeDashboard = ({ charts }) => {
  const history = useHistory();
  const handleDashboardButtonClick = e => {
    e.preventDefault();
    history.push('/dashboard-geral');
  };
  return (
    <DashBoardContainer>
      <DashboardInnerContainer>
        <TitleContaniner>
          <div>
            <h1>Preventivas</h1>
            <h2>Dados do mês atual</h2>
          </div>
        </TitleContaniner>
        <CirclesContainer>
          <CircleInfo
            ocorrencias={false}
            chartData={charts.Atividades.qtdAtividadesPorStatus?.pendente || 0}
            text={'pendentes'}
          />
          <CircleInfo
            ocorrencias={false}
            chartData={charts.Atividades.qtdAtividadesPorStatus?.realizada || 0}
            text={'realizadas'}
          />
        </CirclesContainer>
      </DashboardInnerContainer>
      <hr />
      <DashboardInnerContainer>
        <TitleContaniner>
          <div>
            <h1>Corretivas</h1>
            <h2>Dados do mês atual</h2>
          </div>
        </TitleContaniner>
        <CirclesContainer>
          <CircleInfo
            ocorrencias={true}
            chartData={charts.Ocorrencias?.analisada + charts.Ocorrencias?.naoAnalisada || 0}
            text={'abertas'}
          />
          <CircleInfo ocorrencias={true} chartData={charts.Ocorrencias?.solucionada || 0} text={'fechadas'} />
        </CirclesContainer>
      </DashboardInnerContainer>
      <DashButton onClick={e => handleDashboardButtonClick(e)}>ACESSAR DASHBOARD</DashButton>
    </DashBoardContainer>
  );
};
