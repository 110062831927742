import { ApexOptions } from 'apexcharts';

export const CHART_DEFAULT_OPTIONS: ApexOptions = {
  chart: {
    type: 'bar',
    height: 350,
    toolbar: {
      show: false,
    },
  },
  dataLabels: {
    enabled: true,
    style: {
      fontSize: '12px',
      fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
      fontWeight: '700',
    },
  },
  stroke: {
    show: false,
  },
  yaxis: {
    labels: {
      style: {
        colors: ['#888888'],
        fontSize: '12px',
        fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
        fontWeight: '400',
      },
    },
  },
  fill: {
    opacity: 1,
  },
};
