const TECHNICIANS_TAB = 'TECHNICIANS_TAB';
const TEAMS_TAB = 'TEAMS_TAB';
const UNIT_MARKERS_TAB = 'UNIT_MARKERS_TAB';
const ORDER_UNIT_MARKERS_BY_ACTIVITY_COUNT = 'orderUnitsByActivityCount';
const ORDER_UNIT_MARKERS_BY_OCCURRENCE_COUNT = 'orderUnitsByOccurencesCount';
const ORDER_UNIT_MARKERS_BY_ALPHABET = 'orderUnitsByAlphabet';
//  reducer actions
const TOGGLE_ROUTES_REPORT_MODAL = 'TOGGLE_ROUTES_REPORT_MODAL';
const UPDATE_SIDEBAR_ROUTE_SUBVIEW_TECHNICIAN = 'UPDATE_SIDEBAR_ROUTE_SUBVIEW_TECHNICIAN';
const TOGGLE_UNIT_MARKERS_FILTERS_MODAL = 'TOGGLE_UNIT_MARKERS_FILTERS_MODAL';
const UPDATE_VIEWPORT = 'UPDATE_VIEWPORT';
const UPDATE_FILTERED_UNIT_MARKERS = 'UPDATE_FILTERED_UNIT_MARKERS';
const UPDATE_UNIT_MARKERS_FILTER = 'UPDATE_UNIT_MARKERS_FILTER';
const CLEAR_UNIT_MARKERS_FILTERS = 'CLEAR_UNIT_MARKERS_FILTERS';
const UPDATE_UNIT_MARKERS_FILTER_FIELDS = 'UPDATE_UNIT_MARKERS_FILTER_FIELDS';
const TOGGLE_HIDE_UNITS_WITHOUT_OCCURRENCES = 'TOGGLE_HIDE_UNITS_WITHOUT_OCCURRENCES';
const UNITS_WITH_OCCURRENCES_SUBFILTER = 'UNITS_WITH_OCCURRENCES_SUBFILTER';
const UPDATE_MARKERS = 'UPDATE_MARKERS';
const TOGGLE_IS_OFFLINE_TECHNICIANS_HIDDEN = 'TOGGLE_IS_OFFLINE_TECHNICIANS_HIDDEN';
const TOGGLE_IS_ONLINE_TECHNICIANS_HIDDEN = 'TOGGLE_IS_ONLINE_TECHNICIANS_HIDDEN';
const UPDATE_TECHNICIAN_MARKERS_SEARCH_TERM = 'UPDATE_TECHNICIAN_MARKERS_SEARCH_TERM';
const SHOW_TEAM_DETAILS = 'SHOW_TEAM_DETAILS';
const HIDE_TEAM_DETAILS = 'HIDE_TEAM_DETAILS';
const UPDATE_TEAM_MARKERS_SEARCH_TERM = 'UPDATE_TEAM_MARKERS_SEARCH_TERM';

export {
  ORDER_UNIT_MARKERS_BY_ACTIVITY_COUNT,
  ORDER_UNIT_MARKERS_BY_OCCURRENCE_COUNT,
  ORDER_UNIT_MARKERS_BY_ALPHABET,
  TOGGLE_UNIT_MARKERS_FILTERS_MODAL,
  UPDATE_VIEWPORT,
  UPDATE_FILTERED_UNIT_MARKERS,
  UPDATE_UNIT_MARKERS_FILTER,
  CLEAR_UNIT_MARKERS_FILTERS,
  UPDATE_UNIT_MARKERS_FILTER_FIELDS,
  TOGGLE_HIDE_UNITS_WITHOUT_OCCURRENCES,
  UNITS_WITH_OCCURRENCES_SUBFILTER,
  TOGGLE_ROUTES_REPORT_MODAL,
  UPDATE_SIDEBAR_ROUTE_SUBVIEW_TECHNICIAN,
  TECHNICIANS_TAB,
  TEAMS_TAB,
  UNIT_MARKERS_TAB,
  UPDATE_MARKERS,
  TOGGLE_IS_OFFLINE_TECHNICIANS_HIDDEN,
  TOGGLE_IS_ONLINE_TECHNICIANS_HIDDEN,
  UPDATE_TECHNICIAN_MARKERS_SEARCH_TERM,
  SHOW_TEAM_DETAILS,
  HIDE_TEAM_DETAILS,
  UPDATE_TEAM_MARKERS_SEARCH_TERM,
};
