import { MobileNavbar } from 'components/MobileNavbar';
//import { Menu } from 'components/Menu';
import { Loading } from 'components/Loading';
import { GlobalComponentsContext } from 'context/GlobalComponentsContext';
import React, { useContext, useMemo, useState } from 'react';
import { AuthContext } from 'context/AuthContext';
import {
  LeftContainerFooter,
  PageTitle,
  RightContainerFooter,
  ScheduleMonitoringPage,
  StyledContainerFooter,
  StyledContainerHeader,
  StyledContainerSearchBar,
  TablecontainerWrapper,
  UpdatePlansButton,
  Paginate,
} from './styled';
import { HeaderFiltersPage } from 'containers/HeaderFiltersPage';
import { colors } from 'assets/styled/tokens';
import { FiltersContext } from 'context';
import ScheduleTable from 'components/Tables/ScheduleTable';
import { FieldRow } from 'components/Tables/ScheduleTable/Form/subcomponents/FieldRow';
import { Select } from 'components/Tables/ScheduleTable/Form/subcomponents/Select';
import { Controller, useForm } from 'react-hook-form';
import { useEffect } from 'react';
import { createErrorToast, createGenericErrorToast } from 'utils/createToast';
import { ScheduleMonitoringService } from 'services/schedules';
import { Toast } from 'components';
import { NewMenu } from 'components/Menu/NewMenu';

export const ClientScheduleMonitoring = ({ pathname }) => {
  const [isFirstMount, setIsFirstMount] = useState(true);
  const [pageNumber, setPageNumber] = useState(0);
  const [search, setSearch] = useState('');
  const [itensPerPage, setItensPerPage] = useState(0);
  const [toastList, setToastList] = useState([]);
  const { globalComponentsContext, setGlobalComponentsContext } = useContext(GlobalComponentsContext);
  const { isLoading } = globalComponentsContext;
  const { authContext } = useContext(AuthContext);
  const { headerFiltersContext } = useContext(FiltersContext);
  const { headerFilters } = headerFiltersContext;
  const {
    setValue,
    getValues,
    control,
    formState: { errors },
  } = useForm();

  const [fields, setFields] = useState({
    plans: [],
  });
  const getDate = data => {
    const dateString = new Date(data);
    const date = dateString.toLocaleDateString();
    const time = dateString.toLocaleTimeString();
    return `${date} • ${time} `;
  };

  const populateTableStrategy = async () => {
    const empresa = headerFilters?.companies?.id;
    const { token } = authContext;
    return await Promise.all([
      ScheduleMonitoringService.getPlans({
        empresa,
        token,
      }),
    ]).then(values => ({
      plans: values[0].data.map(({ nomePlano, nomeEmpresa, nomeUnidade, data, status, planoAtividade }) => ({
        plano: nomePlano,
        empresa: nomeEmpresa,
        unidade: nomeUnidade,
        data: getDate(data),
        status,
        planoAtividade,
      })),
    }));
  };
  const populateTable = async () => {
    try {
      const tableData = await populateTableStrategy();
      tableData?.plans?.reverse();
      setFields(prevState => ({
        ...prevState,
        ...tableData,
      }));
    } catch (err) {
      createGenericErrorToast(setToastList);
    }
  };
  const fetchData = async () => {
    setGlobalComponentsContext(prevState => ({
      ...prevState,
      isLoading: true,
    }));
    try {
      await populateTable();
    } catch (err) {
      createGenericErrorToast(setToastList);
    } finally {
      setGlobalComponentsContext(prevState => ({
        ...prevState,
        isLoading: false,
      }));
    }
    if (!headerFilters?.companies?.id && headerFilters) {
      createErrorToast(setToastList, 'Selecione uma empresa antes de continuar');
      return;
    }
  };
  const updatePlansList = () => {
    fetchData();
  };
  useEffect(() => {
    fetchData();
    setIsFirstMount(false);
    getItensPerPage();
    // eslint-disable-next-line
  }, [errors, itensPerPage, headerFilters]);

  const columns = useMemo(
    () => [
      { Header: 'Plano', accessor: 'plano' },
      { Header: 'Empresa', accessor: 'empresa' },
      {
        Header: 'Unidade de manutenção',
        accessor: 'unidade',
      },
      { Header: 'Data/hora da solicitação', accessor: 'data' },
      { Header: 'Status de Agendamento', accessor: 'status' },
    ],
    [],
  );
  const pageOptions = [
    { label: '5', value: 5 },
    { label: '10', value: 10 },
    { label: '25', value: 25 },
    { label: '50', value: 50 },
    { label: '100', value: 100 },
  ];
  const getItensPerPage = () => {
    setItensPerPage(getValues('pageItens'));
  };
  const pageVisited = pageNumber * itensPerPage;
  const displayData1 =
    search === ''
      ? fields.plans.slice(pageVisited, pageVisited + itensPerPage)
      : fields.plans.filter(plan => plan.plano.includes(search)).slice(pageVisited, pageVisited + itensPerPage);
  const pageCountSearch = fields.plans.filter(plan => plan.plano.includes(search));
  const pageCount1 =
    search === '' ? Math.ceil(fields.plans.length / itensPerPage) : Math.ceil(pageCountSearch.length / itensPerPage);
  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };
  const handleSearch = event => {
    setPageNumber(0);
    setSearch(event.target.value);
  };
  const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);
  return (
    <>
      {isLoading && <Loading />}
      <ScheduleMonitoringPage background={colors.pampas}>
        <MobileNavbar />
        {/* <Menu /> */}
        <NewMenu setIsSubMenuOpen={setIsSubMenuOpen} />
        <HeaderFiltersPage location={pathname} monitoringPage={true} isSubMenuOpen={isSubMenuOpen}>
          <PageTitle>Agendamento de planos</PageTitle>
          <TablecontainerWrapper>
            <StyledContainerHeader>
              <StyledContainerSearchBar placeholder={'Buscar plano'} onChange={handleSearch} />
              <UpdatePlansButton onClick={updatePlansList}>ATUALIZAR LISTA</UpdatePlansButton>
            </StyledContainerHeader>
            <ScheduleTable columns={columns} data={displayData1} setToastList={setToastList} fetchData={fetchData} />
            <StyledContainerFooter>
              <LeftContainerFooter>
                <h3>Itens por página:</h3>
                <FieldRow marginSides="16px">
                  <Controller
                    render={props => (
                      <Select
                        {...props}
                        size="small"
                        options={pageOptions}
                        setValue={setValue}
                        defaultValue={isFirstMount ? props.defaultValue : 5}
                        onChange={value => {
                          props.onChange(value);
                          setPageNumber(0);
                          getItensPerPage();
                        }}
                        ref={null}
                      />
                    )}
                    name={'pageItens'}
                    control={control}
                    defaultValue={pageOptions[0].value}
                  />
                </FieldRow>
                <h4>Total de {search === '' ? fields.plans.length : pageCountSearch.length} itens</h4>
              </LeftContainerFooter>
              <RightContainerFooter>
                <Paginate
                  previousLabel={
                    <svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M5 1L1 5L5 9"
                        stroke="#AAAAAA"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  }
                  nextLabel={
                    <svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M1 1L5 5L1 9"
                        stroke="#AAAAAA"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  }
                  pageCount={pageCount1}
                  onPageChange={changePage}
                  breakLabel={'...'}
                  marginPagesDisplayed={1}
                  pageRangeDisplayed={4}
                />
              </RightContainerFooter>
            </StyledContainerFooter>
          </TablecontainerWrapper>
        </HeaderFiltersPage>
      </ScheduleMonitoringPage>
      <Toast toastList={toastList} autoDelete dismissTime={7000} />
    </>
  );
};
