import styled from 'styled-components';
import { colors } from 'assets/styled/tokens';

import checkIcon from '../../assets/check.svg';
import minusIcon from '../../assets/minus.svg';

const iconCheckboxAllFactory = isAllSelected => {
  return isAllSelected ? checkIcon : minusIcon;
};

export const StyledCheckbox = styled.label`
  width: 100%;
  display: flex;
  align-items: center;
  //margin: 0.3125rem 0;
  padding-left: 12px;
  cursor: pointer;
  p {
    margin-left: 8px;
    font-size: 0.875rem;
    line-height: 1.25rem;
    color: ${colors.gray};
  }

  span {
    min-width: 12px;
    min-height: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${colors.white};
    border: 0.1875rem solid #aaaaaa;
    border-radius: 0.1875rem;
  }

  input:checked + span {
    background: ${colors.primary};
    border-color: ${colors.primary};

    &::after {
      content: '';
      display: block;
      //width: 0.625rem;
      //height: 0.625rem;
      width: 7px;
      height: 7px;
      background: ${props =>
        props.isCheckboxAll
          ? `url(${iconCheckboxAllFactory(props?.isAllSelected)}) no-repeat center/contain`
          : `url(${checkIcon}) no-repeat center/contain`};
      background-size: auto;
    }
  }
`;

export const StyledInput = styled.input`
  position: absolute;
  z-index: -1;
  display: none;
  margin: 0;
`;

export const PhotoRadioContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  height: auto;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.3px;
  div {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.3px;
    color: #888888;
    margin-right: 10px;
  }
  div:not(:first-child) {
    display: flex;
    align-items: center;
  }
  label {
    color: #888888 !important;
    margin-right: 5px;
  }
  width: 80%;
`;
