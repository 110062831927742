import { AuthContext } from 'context/AuthContext';
import { FiltersContext } from 'context/GlobalFiltersContext';
import React, { useContext, useEffect, useState } from 'react';
import { AuthService, HeaderFilterService } from 'services/main';

const Amplifique = () => {
  const { authContext } = useContext(AuthContext);
  const { headerFiltersContext } = useContext(FiltersContext);
  const { user, token } = authContext;
  const { companies } = headerFiltersContext?.headerFilters;
  const email = `${user.id}@userleankeep.com`;
  const [companyType, setCompanyType] = useState();
  const [userType, setUserType] = useState();

  useEffect(() => {
    const styleTag = document.createElement('style');
    styleTag.innerHTML = `
      .amp-icon-cancel:before {
        display: block !important;
      }
    `;
    document.head.appendChild(styleTag);

    const loadScript = () => {
      return new Promise((resolve, reject) => {
        if (document.querySelector('script[src="https://cdn.amplifique.me/amplifiqueme-inapp-survey.js"]')) {
          resolve();
          return;
        }

        const script = document.createElement('script');
        script.src = 'https://cdn.amplifique.me/amplifiqueme-inapp-survey.js';
        script.defer = true;
        script.onload = resolve;
        script.onerror = reject;
        document.body.appendChild(script);
      });
    };

    const initSurvey = async () => {
      try {
        const companyResponse = await HeaderFilterService.company(companies?.id, token);
        setCompanyType(companyResponse.data.tipoNome);

        const userResponse = await AuthService.getUser({ id: user?.id, token });
        setUserType(userResponse.data.tipoNome);

        await loadScript();

        window.Amplifiqueme = window.Amplifiqueme || {};
        window.Amplifiqueme.onLoad = () => {
          window.Amplifiqueme.identify(
            {
              email: email,
              created_at: user?.signUpUnixDate,
              name: user?.fullName,
              company: companies?.name,
              custom_fields: {
                nome_empresa: companies?.name,
                nome_usuario: user?.fullName,
                tipo_usuario: userType,
                segmento: companyType,
                idUsuario: user?.id,
                ehAdmin: user?.type === 'Operacional' ? 'True' : 'False',
                idEmpresa: String(companies?.id),
                plataforma: 'Web-Novo',
                nomeEmpresa: companies?.name,
                os: 'WEB',
              },
            },
            true,
          );
        };

        const existingSurveyElement = document.querySelector('ampl-survey');
        if (!existingSurveyElement) {
          const amplSurveyElement = document.createElement('ampl-survey');
          amplSurveyElement.setAttribute('identifier', '64286cc685cacdbcf60441ac');
          //amplSurveyElement.setAttribute('force', 'true');
          //amplSurveyElement.setAttribute('debug', 'true');
          document.body.appendChild(amplSurveyElement);
        }
      } catch (error) {
        console.error('Error initializing Amplifique survey:', error);
      }
    };

    if (companies && user) {
      initSurvey();
    }

    return () => {
      const existingSurveyElement = document.querySelector('ampl-survey');
      if (existingSurveyElement && existingSurveyElement.parentNode) {
        existingSurveyElement.parentNode.removeChild(existingSurveyElement);
      }

      if (styleTag && styleTag.parentNode) {
        styleTag.parentNode.removeChild(styleTag);
      }
    };
  }, [companies, user, token, email, userType, companyType]);

  return <div></div>;
};

export default Amplifique;
