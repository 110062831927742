import { colors } from 'assets/styled/tokens';
import styled from 'styled-components';
import { strongTextCss } from 'utils/helpers/css';

export const StyledBatteryChargePercentage = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  > div {
    margin-left: 2px;
  }
  > span {
    margin-left: 1px;
  }
  span {
    ${strongTextCss}
    margin-top: 7px;
    color: ${({ percentage }) =>
      percentage <= 33 ? colors.bittersweet : percentage <= 66 ? colors.gray : colors.darkGreen};
  }
`;
