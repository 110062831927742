import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';

import { format } from 'date-fns';
import { pt } from 'date-fns/locale';

import { Button } from 'components/Button';

import { ReactComponent as CalendarIcon } from './assets/calendar.svg';

import {
  StyledInputWrapper,
  StyledDatePicker,
  StyledDropdownContent,
  StyledInput,
  StyledDropdownFooter,
} from './styled';
import CleanInputIcon from 'components/Form/shared/CleanInputIcon';
import Conditional from 'components/Conditional';
import { StyledError } from '../FormGroup/styled';

const initialState = {
  date: new Date(),
  formattedISO: '',
};

const DatePicker = props => {
  const {
    size,
    value,
    placeholder,
    className = '',
    onChange,
    error,
    errormessage,
    disabled,
    'data-cy': dataCy,
  } = props;
  const ref = React.createRef();
  const [formattedValue, setFormattedValue] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [date, setDate] = useState(initialState.date);

  // const [toastList, setToastList] = useState([]);

  useEffect(() => {
    const handleValueChange = () => {
      try {
        if (!value) {
          setDate('');
          setFormattedValue('');
          onChange && onChange('');
        }

        if (value) {
          setDate(value);
          handleChange(value);
          onChange && onChange(value);
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error({ error });
      }
    };

    if (date?.formattedISO !== value) {
      handleValueChange();
    }
    // eslint-disable-next-line
  }, [value]);

  const toggle = () => setIsOpen(!isOpen);

  const handleChange = date => {
    const formattedValue = format(date, 'dd/MM/yyyy');

    setFormattedValue(formattedValue);
    setDate(date);
    onChange && onChange(date);
  };

  const handleClearDate = () => {
    setDate(initialState.date);
    setFormattedValue('');

    onChange && onChange('');
  };
  const handleClickOutside = useCallback(
    e => {
      if (ref?.current && ref.current.contains(e.target)) {
        // inside click
        return;
      }

      // outside click
      // onChange(e.target.value)
      setIsOpen(false);
    },
    [ref],
  );

  useEffect(() => {
    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen, handleClickOutside, ref]);

  return (
    <StyledInputWrapper className={className} ref={ref}>
      <CalendarIcon width={9} height={10} />
      <StyledInput
        readOnly
        error={error}
        type="text"
        data-cy={dataCy}
        size={size}
        placeholder={placeholder}
        value={formattedValue || ''}
        onClick={toggle}
        disabled={disabled}
      />
      {/* <Toast toastList={toastList} autoDelete dismissTime={7000} /> */}
      <Conditional when={formattedValue !== ''}>
        <CleanInputIcon size={size} onClick={handleClearDate} />
      </Conditional>
      <StyledDropdownContent isOpen={isOpen}>
        <StyledDatePicker
          locale={pt}
          onChange={handleChange}
          date={date}
          // editableDateInputs
          dateDisplayFormat="dd/MM/yyyy"
        />
        <StyledDropdownFooter>
          <Button type="button" onClick={() => setIsOpen(false)}>
            Aplicar
          </Button>
        </StyledDropdownFooter>
      </StyledDropdownContent>
      <StyledError>
        <p>{error && errormessage}</p>
      </StyledError>
    </StyledInputWrapper>
  );
};

DatePicker.prototype = {
  size: PropTypes.string,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
};

export { DatePicker };
