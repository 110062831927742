/* istanbul ignore file */
import { colors, fonts, devices } from 'assets/styled/tokens';
import styled from 'styled-components';

export const Header = styled.div`
  background-color: ${colors.carbon};
  padding: 20px 0;
  width: 100%;
`;

export const Content = styled.div`
  display: flex;
  margin: 24px auto auto auto;
  width: 1184px;
  justify-content: space-between;
  @media (max-width: 1184px) {
    display: block;
    width: 90%;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 50px;
  @media ${devices.tabletLandscape} {
    display: block;
  }
`;

export const StyledImg = styled.img`
  display: flex;
  width: 154px;
  height: auto;
  filter: brightness(0) invert(1);
`;

export const StyledBoxBorder = styled.div`
  border: 1px solid ${colors.silver3};
  position: relative;
  width: 570px;
  border-radius: 5px;
  @media screen and (max-width: 1184px) {
    margin: auto;
    width: 100%;
  }
`;

export const StyledLocationLogoContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StyledLocationLogo = styled.img`
  width: auto;
  height: 110px;
  object-fit: contain;
  object-position: center;
  @media screen and (max-width: 1184px) {
    width: auto;
    height: 110px;
  }
`;

export const StyledEquipmentPhoto = styled.img`
  width: 100px;
  height: 100px;
  object-fit: contain;
  object-position: center;
  @media screen {
    width: 100%;
  }
`;

export const StyledClientInfo = styled.div`
  background-color: ${colors.pampas};
  width: 100%;
  padding: 10px 15px 24px 15px;
  h2 {
    font-family: 'Blinker', sans-serif;
    font-weight: 700;
    font-size: 24px;
  }
  h3 {
    font-family: ${fonts.fontFamily};
    font-size: 14px;
    font-weight: 700;
    color: #f38a00;
    cursor: pointer;
  }
  h4 {
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    font-weight: 700;
    padding: 21px 0 4px 0;
  }
  p {
    color: #888888;
    font-family: ${fonts.fontFamily};
    font-size: 14px;
  }
  div {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  a {
    font-family: Roboto;
    font-size: 14px;
    font-weight: 700;
    line-height: 16px;
    letter-spacing: 0.5px;
    text-align: left;
    color: #f38a00;
    svg {
      path {
        stroke: #f38a00;
      }
    }
  }
`;
export const StyledBoxContent = styled.div`
  padding: 16px;
  border: solid 1px ${colors.silver3};
  border-radius: 5px;

  @media screen and (max-width: 1184px) {
    width: 100%;
    margin-top: 24px;
  }

  h2 {
    font-family: 'Blinker', sans-serif;
    font-weight: 700;
    font-size: 24px;
    color: #f38a00;
    padding-bottom: 24px;
  }
  h3 {
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    font-weight: bold;
  }
  div {
    display: flex;
    justify-content: space-between;
    padding-bottom: 16px;
    gap: 3%;
    @media screen {
      display: block;
    }
  }
  span {
    width: 100%;
    display: grid;
    grid-template-columns: 48% 48%;
    gap: 0px 4%;
    margin-bottom: 6px;
    @media screen and (max-width: 1184px) {
      width: 100%;
      display: grid;
      grid-template-columns: 48% 48%;
    }
  }
  span span {
    display: block;
    @media screen and (max-width: 1184px) {
      width: 100%;
      display: block;
    }
  }
  p {
    color: #888888;
    font-family: ${fonts.fontFamily};
    font-size: 14px;
    padding: 4px 0 16px 0;
  }
`;
export const StyleEquipamentInfo = styled.div`
  padding: 16px;
  border-bottom: 1px solid ${colors.silver3};

  @media screen and (max-width: 1184px) {
    margin-top: 24px;
  }
  h2 {
    font-family: 'Blinker', sans-serif;
    font-weight: 700;
    font-size: 24px;
    color: #f38a00;
    padding-bottom: 12px;
  }
  div {
    display: flex !important;
    justify-content: space-between;
    padding-bottom: 24px;
    gap: 3%;
    @media screen and (max-width: 1184px) {
      display: block !important;
    }
  }
  img {
    width: 48%;
    height: auto;
    @media screen and (max-width: 1184px) {
      width: 100%;
      height: auto;
      margin-bottom: 10px;
    }
  }
  img {
    width: 48%;
    @media screen and (max-width: 1184px) {
      width: 100%;
    }
  }
  span {
    width: 48%;
    @media screen and (max-width: 1148px) {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
    }
  }
  span span {
    @media screen and (max-width: 1184px) {
      width: 48%;
      display: block;
    }
  }
  h3 {
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    padding-bottom: 4px;
  }
  p {
    color: #888888;
    font-family: ${fonts.fontFamily};
    font-size: 14px;
    padding-bottom: 16px;
  }
`;
export const StyleMaintenanceInfo = styled.div`
  padding: 16px;
  h2 {
    font-family: 'Blinker', sans-serif;
    font-weight: 700;
    font-size: 24px;
    color: #f38a00;
    padding-bottom: 12px;
  }
  h3 {
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    padding-bottom: 4px;
  }
  p {
    font-size: 24px;
    color: #888888;
    font-family: ${fonts.fontFamily};
    font-weight: 700;
    padding-bottom: 16px;
  }
  button {
    background-color: #f38a00;
    padding: 12px 40px;
    border-radius: 5px;
    width: fit-content;
    font-size: 16px;
    color: white;
    font-weight: 600;
    font-family: ${fonts.fontFamily};
  }
  span {
    font-size: 12px;
    font-weight: 400;
  }
`;
export const StyledBoxLeft = styled.div`
  display: block;
  width: 48%;
  @media screen and (max-width: 1184px) {
    width: 100%;
  }
`;
export const StyledLocationBox = styled.div`
  padding: 16px;
  border: solid 1px ${colors.silver3};
  border-radius: 5px;
  margin-top: 21px;
  h2 {
    font-family: 'Blinker', sans-serif;
    font-weight: 700;
    font-size: 24px;
    color: #f38a00;
    padding-bottom: 12px;
  }
  h3 {
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    padding-bottom: 4px;
  }
  p {
    color: #888888;
    font-family: ${fonts.fontFamily};
    font-size: 14px;
    padding-bottom: 16px;
  }
`;
export const StyledPreventiveMaintenance = styled.div`
  background-color: #f38a00;
  padding: 27px 40px 28px 32px;
  margin-top: 24px;
  border-radius: 5px;
  div {
    display: flex;
    justify-content: space-between;
    @media screen {
      display: block;
    }
  }
  span {
    width: 56%;
  }
  h2 {
    color: white;
    font-family: 'Blinker', sans-serif;
    font-weight: 700;
    font-size: 32px;
    padding-bottom: 9px;
  }
  .firstSpan {
    width: 56%;
  }
  .secondSpan {
    width: 42%;
  }
  p {
    font-size: 14px;
    font-weight: 400;
    color: white;
    font-family: ${fonts.fontFamily};
    padding-bottom: 22px;
  }
  button {
    background-color: white;
    color: #f38a00;
    padding: 6px 23px;
    border-radius: 5px;
    font-size: 16px;
    font-weight: 600;
    width: fit-content;
  }
`;

export const StyledSocialLinks = styled.div`
  display: flex;
  justify-content: flex-start !important;
  gap: 16px;
  margin-top: 12px;
  a {
    img {
      width: 24px;
    }
  }
  .site {
    font-family: 'Blinker', sans-serif;
    font-weight: 700;
    font-size: 16px;
    color: #f38a00;
    text-decoration: underline;
  }
`;
