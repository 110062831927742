import React from 'react';
import styled from 'styled-components';

import { ReactComponent as Spinner } from 'assets/icons/spinner-1.svg';
import { spinAnimationCss } from 'utils/helpers/css';

const StyledViewLoadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  height: 100%;
  svg {
    ${spinAnimationCss};
  }
`;
const StyledViewLoadingText = styled.h1`
  font-family: Blinker;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  text-align: center;
  margin-top: 16px;
  color: #aaaaaa;
`;
const ViewLoadingContainer = () => {
  return (
    <StyledViewLoadingContainer>
      <Spinner />
      <StyledViewLoadingText>Carregando</StyledViewLoadingText>
    </StyledViewLoadingContainer>
  );
};
export default ViewLoadingContainer;
