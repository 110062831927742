import styled from 'styled-components';

import { Calendar } from 'react-date-range';
import { DefaultSelect } from 'assets/styled/sheets/components/Forms';
import { inputStyleFactory } from 'components/Form/shared/inputStyleFactory';
import { colors } from 'assets/styled/tokens';

import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file

export const StyledInputWrapper = styled.div`
  width: 100%;
  position: relative;
  background: ${colors.white};
  display: flex;
  align-items: center;
  & > svg {
    position: absolute;
    z-index: 1;
    left: 9px;
  }
`;

export const StyledInput = styled.input`
  ${DefaultSelect};
  ${props => inputStyleFactory(props.size)};
  position: relative;
  display: inline-block;
  cursor: pointer;
  padding-left: 24px;
  width: 100%;
  color: ${colors.gray};
  border-color: ${props => props.error && colors.bittersweet};

  cursor: ${props => props.disabled && 'not-allowed'};

  &:active {
    pointer-events: ${props => props.disabled && 'none'};
  }
`;

export const StyledDropdownContent = styled.div`
  width: 100%;
  z-index: 2;
  top: 24px;
  position: absolute;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
  border-radius: 0px 0px 3px 3px;
  display: ${props => (props.isOpen ? 'block' : 'none')};
`;

export const StyledDropdownFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 10px 15px;
  background: ${colors.white};

  button {
    font-size: 12px;
    line-height: 14px;
  }
`;

export const StyledDatePicker = styled(Calendar)`
  width: 100% !important;
  position: relative;
  z-index: 2;

  .rdrNextPrevButton {
    width: auto;
    margin: 0 7px;
  }
  .rdrNextButton i {
    margin: 0;
  }
  .rdrMonthAndYearPickers select {
    padding: 10px 20px 10px 0px;
  }
  .rdrDateDisplayWrapper {
    background-color: ${colors.pampas};
  }
  .rdrMonth {
    width: auto;
    padding: 0 0.833em 0 0.833em;
  }
  .rdrMonthAndYearWrapper {
    height: auto;
    padding-top: 0;
  }
  .rdrMonthAndYearWrapper {
    button {
      background: ${colors.pampas};
    }
  }

  .rdrStaticRanges {
    button.rdrStaticRange {
      border-color: ${colors.whiteGray};

      .rdrStaticRangeLabel {
        padding: 10px 15px;
        :hover {
          background: ${colors.cararra};
        }
      }
    }
  }

  .rdrDefinedRangesWrapper {
    border-color: ${colors.whiteGray};
  }

  .rdrDay:hover {
    span {
      border-radius: 0 !important;
    }
  }

  .rdrDayStartPreview,
  .rdrDayEndPreview {
    border-radius: 0 !important;
  }

  .rdrDayStartOfMonth {
    .rdrDayInPreview {
      border-radius: 0 !important;
    }
  }

  .rdrDayStartOfWeek {
    .rdrInRange {
      border-top-left-radius: 0.4em !important;
      border-bottom-left-radius: 0.4em !important;
    }
    .rdrDayInPreview {
      border-radius: 0 !important;
    }
  }

  .rdrDayEndOfWeek {
    .rdrInRange {
      border-top-right-radius: 0.4em !important;
      border-bottom-right-radius: 0.4em !important;
    }

    .rdrDayInPreview {
      border-radius: 0 !important;
    }
  }

  .rdrDayEndOfWeek {
    border-top-right-radius: 0.4em;
    border-bottom-right-radius: 0.4em;
  }

  .rdrStartEdge {
    border-top-left-radius: 0.4em;
    border-bottom-left-radius: 0.4em;
  }
  .rdrEndEdge {
    border-top-right-radius: 0.4em;
    border-bottom-right-radius: 0.4em;
  }
`;
