import React, { useEffect } from 'react';

import { Container } from './styled';
import { Loading } from 'components/Loading';
import { useHistory, useLocation } from 'react-router-dom';
import { refresh } from 'utils/auth';
import { getLocalAuthStorage } from 'utils/localStorage';
import { useCookies } from 'react-cookie';
import { URLS } from 'constants/urls';

const RefreshSession = () => {
  const { push } = useHistory();
  const storage = getLocalAuthStorage();
  const queryString = useLocation().search;
  const mode = new URLSearchParams(queryString).get('mode');
  const setCookie = useCookies(['authToken'])[1];

  useEffect(() => {
    refresh({ token: storage.token, refreshToken: storage.refreshToken, mode: mode })
      .then(isSessionStillActive => {
        //console.log(16, isSessionStillActive);
        if (isSessionStillActive) {
          const newAuthToken = isSessionStillActive;
          setCookie('authToken', newAuthToken, { path: '/', domain: URLS.cookieDomain });
          push({
            pathname: '/',
            search: `?token=${isSessionStillActive}`,
          });
        } else {
          push('/logout');
        }
      })
      .catch(error => {
        console.error({ error });
        window.localStorage.clear();
        push('/logout');
      });
    // eslint-disable-next-line
  }, []);

  return (
    <Container>
      <Loading />
    </Container>
  );
};

export { RefreshSession };
