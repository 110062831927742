import { unitSubgroups, cities, unitGroups } from 'components/HeaderFilters/constants';

const unitiesDependencies = async ({ filterResponse }) => {
  const { city, unitSubgroup } = filterResponse;

  const dependencies = [];

  if (city) {
    dependencies.push({
      filter: cities,
      value: city,
    });

    const { state } = city;

    if (state) {
      dependencies.push({
        filter: 'states',
        value: state,
      });
    }
  }

  if (unitSubgroup) {
    dependencies.push({
      filter: unitSubgroups,
      value: unitSubgroup,
    });

    const { unitGroup } = unitSubgroup;

    if (unitGroup) {
      dependencies.push({
        filter: unitGroups,
        value: unitGroup,
      });
    }
  }

  return dependencies;
};

export { unitiesDependencies };
