import { colors } from 'assets/styled/tokens';

const { css } = require('styled-components');

const white = () => css`
  background: ${colors.white};
  border-color: ${colors.lightGray};
  color: ${colors.tundora};

  &:hover {
    background: ${colors.pampas};
    transition: all 0.5s;
  }

  &:active {
    background: ${colors.pampas2};
  }

  &:disabled {
    background: ${colors.pampas};
    color: ${colors.silver};
  }
`;

export { white };
