import React, { useRef, useState } from 'react';
import { StyledCategoryButton, StyledCategoryItem, StyledNavLink, SubMenuContainer } from './styled';

import { ReactComponent as HomeIcon } from 'assets/icons/Home-icon-orange.svg';
import { ReactComponent as DashIcon } from 'assets/icons/dash-menu-icon.svg';
import { ReactComponent as GeoIcon } from 'assets/icons/geo-menu-icon.svg';
import { ReactComponent as OccurIcon } from 'assets/icons/occur-menu-icon.svg';
import { ReactComponent as ActvIcon } from 'assets/icons/actv-menu-icon.svg';
import { ReactComponent as ReportsIcon } from 'assets/icons/report-menu-icon.svg';
import { ReactComponent as MaterialsIcon } from 'assets/icons/materials-menu-icon.svg';
import { ReactComponent as DocIcon } from 'assets/icons/doc-menu-icon.svg';
import { ReactComponent as RegIcon } from 'assets/icons/registry-menu-icon.svg';
import { ReactComponent as ConfigIcon } from 'assets/icons/config-menu-icon.svg';
import { Subcategory } from '../Subcategory';
import { SubCategoryItem } from '../SubcategoryItem';
import useTooltip from '../Tooltip';
import { Tooltip } from '../Tooltip';
import { useLocation } from 'react-router-dom';
// ... (other imports)

export const CategoryItem = ({
  category: { link, label, isExternal, disabled, children, ...props },
  setIsSubMenuOpen,
  subMenusAreOpen,
  setSubMenusAreOpen,
}) => {
  const itemRef = useRef(null);
  const location = useLocation();
  const { showTooltip, handleMouseEnter, handleMouseLeave } = useTooltip();
  const [mainLabel] = useState(label);

  const iconObj = Object.fromEntries([
    ['Home', <HomeIcon />],
    ['Dashboard', <DashIcon />],
    ['Ocorrências', <OccurIcon />],
    ['Atividades', <ActvIcon />],
    ['Relatórios', <ReportsIcon />],
    ['Materiais', <MaterialsIcon />],
    ['Cadastros', <RegIcon />],
    ['Documentos', <DocIcon />],
    ['Configurações', <ConfigIcon />],
    ['Geolocalização', <GeoIcon />],
  ]);

  const renderLink = ({ label, link, isExternal = false, isChildren = false, disabled }) => {
    const linkProps = {
      activeClassName: !isChildren ? 'active' : undefined,
      to: link,
    };
    const isActive = link.toLowerCase().includes(location.pathname.toLowerCase());
    const commonButtonProps = {
      key: label,
      isChildren,
      disabled: label === 'Geolocalização' ? false : disabled,
      isActive,
      onMouseEnter: () => handleMouseEnter(label),
      onMouseLeave: () => handleMouseLeave(label),
    };

    if (isExternal || link === '') {
      return (
        <StyledCategoryButton {...commonButtonProps}>
          {showTooltip[label] && disabled && (
            <Tooltip isChildren={isChildren} text={'Você não tem acesso a esse item.'} />
          )}
          {isExternal ? (
            <a href={link} rel="noopener noreferrer">
              {!isChildren && <div>{iconObj[mainLabel]}</div>}
              {label}
            </a>
          ) : (
            <span>
              {!isChildren && <div>{iconObj[mainLabel]}</div>}
              {label}
            </span>
          )}
        </StyledCategoryButton>
      );
    }

    return (
      <StyledCategoryButton {...commonButtonProps}>
        {showTooltip[label] && disabled && <Tooltip text={'Você não tem acesso a esse item.'} />}
        <StyledNavLink {...linkProps}>
          {!isChildren && <div>{iconObj[mainLabel]}</div>}
          {label}
        </StyledNavLink>
      </StyledCategoryButton>
    );
  };

  const handleOpenSubmenu = (mainLabel, children) => {
    setIsSubMenuOpen(!subMenusAreOpen[mainLabel] && children);
    setSubMenusAreOpen(prevSubMenus => ({
      ...Object.fromEntries(Object.keys(prevSubMenus).map(key => [key, false])),
      [mainLabel]: !subMenusAreOpen[mainLabel],
    }));
  };

  const getBasePath = pathname => {
    const segments = pathname.split('/');
    return `/${segments[1]}`;
  };

  const checkIsActive = children => {
    const basePath = getBasePath(location.pathname.toLowerCase());
    return children.some(child => {
      const childLink = child.link ? child.link.toLowerCase() : null;
      return childLink && childLink.includes(basePath.toLowerCase());
    });
  };

  return (
    <StyledCategoryItem
      key={label}
      hasChildren={children}
      disabled={disabled}
      isOpen={subMenusAreOpen[mainLabel]}
      ref={itemRef}
      onClick={() => handleOpenSubmenu(mainLabel, children)}
      {...props}
    >
      {link && renderLink({ label, link, isExternal, isChildren: false, disabled })}
      {children && (
        <SubMenuContainer>
          <StyledCategoryButton key={label} disabled={disabled} isActive={checkIsActive(children)}>
            <span>
              <div>{iconObj[mainLabel]}</div>
              {label}
            </span>
          </StyledCategoryButton>
          <Subcategory
            mainLabel={mainLabel}
            setIsSubMenuOpen={setIsSubMenuOpen}
            setSubMenusAreOpen={setSubMenusAreOpen}
            onClick={e => e.stopPropagation()}
          >
            {children.map(({ label, link, isExternal, disabled }) => (
              <SubCategoryItem key={label} disabled={disabled}>
                {renderLink({ label, link, isExternal, isChildren: true, disabled })}
              </SubCategoryItem>
            ))}
          </Subcategory>
        </SubMenuContainer>
      )}
    </StyledCategoryItem>
  );
};
