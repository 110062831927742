import { AxiosMock, AxiosCharts, AxiosGlobalFilters } from 'services/main';

const GeolocalizeService = {
  getMarkers: params => {
    const queryString = new URLSearchParams(params).toString();
    return AxiosCharts({
      method: 'get',
      url: `/api/v1/Geolocalizacao/obterPorFiltro?${queryString}`,
    });
  },
  getTechnician: ({ companyId }) => {
    return AxiosMock({
      method: 'get',
      url: `/technicians/:id`,
      headers: {
        EmpresaId: companyId,
      },
    });
  },
  getRoute: ({ routeId, companyId }) => {
    return AxiosMock({
      method: 'get',
      url: `/routes/${routeId}`,
      headers: {
        EmpresaId: companyId,
      },
    });
  },
  getRouteReport: ({ tecnicos, empresa, dataInicio, dataFim }) => {
    let params = new URLSearchParams();
    tecnicos.forEach(element => {
      params.append('tecnicos', element);
    });
    params.append('empresa', empresa);
    params.append('dataInicio', dataInicio);
    params.append('dataFim', dataFim);
    return AxiosCharts({
      method: 'get',
      responseType: 'blob',
      url: `/api/v1/Geolocalizacao/ObterRelatorioRotas`,
      params,
    });
  },
  getTeams: ({ companyId }) => {
    return AxiosGlobalFilters({
      method: 'get',
      url: `/v1/equipes`,
      headers: {
        EmpresaId: companyId,
        Inativo: false,
      },
    });
  },
  getUnits: ({ companyId }) => {
    return AxiosMock({
      method: 'get',
      url: `/units`,
      headers: {
        EmpresaId: companyId,
      },
    });
  },
  getOcurrencesPriorities: ({ unitId }) => {
    return AxiosGlobalFilters({
      method: 'get',
      url: `/v1/prioridadesocorrencias?unidadeId=${unitId}`,
    });
  },
};

export { GeolocalizeService };
