import { ApexOptions } from 'apexcharts';
import { CHART_DEFAULT_OPTIONS } from '../options';

export function getStackedChartOptions(categories: Array<number | string> = [], options = {}): ApexOptions {
  const { chart, yaxis, dataLabels, ...defaultOptions } = CHART_DEFAULT_OPTIONS;

  return {
    ...defaultOptions,
    xaxis: { categories },
    chart: {
      ...chart,
      stacked: true,
      // stackType: isChartPercent ? '100%' : undefined,
      toolbar: {
        show: false,
      },
    },
    yaxis: {
      ...yaxis,
      labels: {
        formatter: function (value) {
          return `${value}`;
        },
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '55%',
      },
    },
    colors: ['#00C0C7', '#E8871A', '#00C0C7', '#E8871A', '#00C0C7', '#E8871A', '#00C0C7', '#E8871A'],
    dataLabels: {
      ...dataLabels,
      formatter: function (value) {
        return `${value}`;
      },
    },
    tooltip: {
      y: {
        formatter: function (value) {
          return `${value}`;
        },
      },
    },
    stroke: {
      show: false,
    },
    ...options,
  };
}
