import styled from 'styled-components';

import arrowFullSvg from 'assets/icons/arrow-full.svg';
import settingsSvg from 'assets/icons/settings-icon.svg';
import radioIconSvg from 'assets/icons/radio-icon.svg';
import radioOffIconSvg from 'assets/icons/radio-off-icon.svg';
import timesIconSvg from 'assets/icons/times-icon.svg';
import { ModalGeneric } from 'components/ModalGeneric';

export const ChartCard = styled.section<{ full?: boolean }>`
  background-color: #fff;
  border-radius: 8px;
  bottom: ${({ full = false }) => (full ? '15px' : '0')};
  box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.1);
  height: ${({ full = false }) => (full ? 'calc(100vh-30px)' : '440px')};
  left: ${({ full = false }) => (full ? '15px' : '0')};
  position: ${({ full = false }) => (full ? 'fixed' : 'relative')};
  right: ${({ full = false }) => (full ? '15px' : '0')};
  top: ${({ full = false }) => (full ? '15px' : '0')};
  width: ${({ full = false }) => (full ? 'calc(100vw-30px)' : 'auto')};
  /* transition: all 0.12s ease-in-out; */
  z-index: ${({ full = false }) => (full ? '1000' : '1')};
`;

export const ChartCardHeader = styled.header`
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 16px;
  border-bottom: 1px solid #e5e5e5;
  > div {
    display: flex;
    flex-direction: row;
  }
`;

export const ChartCardTitle = styled.h1`
  font-size: 18px;
  font-weight: 600;
  line-height: 22px;
`;

export const ChartCardBody = styled.div<{ height?: number | string; width?: number | string }>`
  align-items: center;
  display: flex;
  height: ${({ height }) => (typeof height === 'number' ? `${height}px` : height ? height : '100%')};
  justify-content: center;
  width: ${({ width }) => (typeof width === 'number' ? `${width}px` : width ? width : '100%')};
  margin: 0 auto;
`;

export const ChartHeaderButton = styled.button`
  background: none #fff;
  border-radius: 4px;
  height: 32px;
  margin-right: 8px;
  padding: 8px;
  width: 32px;
  border: 1px solid #e5e5e5;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ChartCardIcon = styled.span<{ full?: boolean }>`
  background: ${({ full = false }) => (full ? `url(${timesIconSvg})` : `url(${arrowFullSvg})`)} no-repeat center/contain;
  display: inline-block;
  height: 16px;
  width: 16px;
`;

export const ChartCardSettingsIcon = styled.span`
  background: url(${settingsSvg}) no-repeat center/contain;
  display: inline-block;
  height: 16px;
  width: 16px;
`;

export const DashModal = styled(ModalGeneric)`
  padding: 0;

  > div {
    align-items: start;
    justify-content: start;
    padding: 0;
  }
`;

export const DashModalInner = styled.div`
  width: 100%;
`;

export const DashModalHeader = styled.header`
  padding: 20px;
  border-bottom: 1px solid #e5e5e5;
  display: flex;
  width: 100%;

  h3 {
    margin: 0;
    font-weight: 700;
    font-size: 21px;
    line-height: 25px;
    letter-spacing: 0.02em;
    color: #f38a00;
  }
`;

export const DashModalContent = styled.div`
  padding: 20px;

  > span {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #1e1e1e;
  }
`;

export const DashModalItems = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 28px;

  > span {
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #888;
    cursor: pointer;
    display: flex;
    align-items: center;
    margin-right: 16px;
  }
`;

export const DashRadio = styled.span<{ checked?: boolean }>`
  width: 12px;
  height: 12px;
  display: inline-block;
  margin-right: 4px;
  background: ${({ checked = false }) => (checked ? `url(${radioIconSvg})` : `url(${radioOffIconSvg})`)} no-repeat
    center/contain;
`;

export const ChartTryAgain = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  > div {
    margin-bottom: 0.5rem;
  }
`;
export const UpdateChartButton = styled.button`
  position: relative;
  width: 32px;
  height: 32px;
  padding: 8px, 12px, 8px, 12px;
  border-radius: 4px;
  border: 1px;
  border: 1px solid #e5e5e5;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 8px;
  :hover {
    svg {
      path {
        fill: #1e1e1e;
      }
    }
  }
`;
export const LastUpdateData = styled.div`
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: right;
  color: #888888;
  display: flex;
  align-items: center;
  margin-right: 10px;
`;

export const ErrorUpdateMessage = styled.div`
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: right;
  color: #888888;
  display: flex;
  align-items: center;
  margin-right: 20px;
  p {
    margin-left: 10px;
  }
`;
