import * as React from 'react';
import { DropdownChildren, DropdownContent, DropdownContentInner, DropdownWrapper } from './styled';
import { useBoolean } from 'hooks';
import { LkpDropdownMenu, LkpDropdownMenuItemProps } from './Menu';
import { useOnClickOutside } from 'hooks/useOnClickOutside';

export interface LkpDropdownProps {
  children: React.ReactNode;
  content?: React.ReactNode;
  items?: Array<LkpDropdownMenuItemProps>;
  onToggle?: (isOpened: boolean) => void;
  onChangeItem?: (itemId: string, isSelected?: boolean) => void;
  onToggleAll?: (isAllSelected: boolean) => void;
}

export function LkpDropdown({
  children,
  content,
  items,
  onToggle,
  onChangeItem,
  onToggleAll,
}: LkpDropdownProps): React.ReactElement {
  const dropdownRef = React.useRef(null);

  const { value: isOpenedDropdown, toggle: onToggleDropdown, setFalse: handleCloseDropdown } = useBoolean();

  const handleDropdownnClick = () => {
    const toggled = onToggleDropdown();
    if (onToggle) onToggle(toggled);
  };

  useOnClickOutside(dropdownRef, handleCloseDropdown);

  return (
    <DropdownWrapper ref={dropdownRef}>
      <DropdownChildren onClick={handleDropdownnClick} isOpened={isOpenedDropdown}>
        {children}
      </DropdownChildren>
      <DropdownContent isOpened={isOpenedDropdown}>
        {items && items.length > 0 && (
          <LkpDropdownMenu items={items} onChangeItem={onChangeItem} onToggleAll={onToggleAll} />
        )}
        {content && <DropdownContentInner>{content}</DropdownContentInner>}
      </DropdownContent>
    </DropdownWrapper>
  );
}
