import { colors } from 'assets/styled/tokens';

const { css } = require('styled-components');

const lightOutline = () => css`
  background: white;
  color: ${colors.carbon};
  border: 1px solid ${colors.whiteGray};
`;

export { lightOutline };
