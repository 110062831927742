import { previewOccurrenceReportExcelFactory } from './excel';
import { previewOccurrenceReportPdfFactory } from './pdf';

const previewOccurrenceReportFactory = ({ reportType, params }) => {
  switch (reportType) {
    case 'pdf':
      return previewOccurrenceReportPdfFactory(params);
    case 'excel':
      return previewOccurrenceReportExcelFactory(params);
    default:
      return [];
  }
};

export { previewOccurrenceReportFactory };
