import { colors } from 'assets/styled/tokens';
import styled from 'styled-components';

export const StyledTabHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid ${colors.whiteGray};
`;
export const StyledTabsContainer = styled.div`
  display: flex;
  align-items: flex-end;
`;
export const StyledTabItem = styled.div`
  display: inline-flex;
  flex-direction: column;
  font-family: Blinker, sans-serif;
  align-items: space-between;
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;
  color: ${colors.primary};
`;
export const RoundedBottomBorder = styled.div`
  margin-top: 18px;
  background: ${colors.primary};
  border-radius: 4px 4px 0 0;
  width: 100%;
  height: 2px;
`;
