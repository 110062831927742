import { extraSmall } from './sizes/extraSmall';
import { small } from './sizes/small';
import { large } from './sizes/large';
import { medium } from './sizes/medium';

const buttonSizeFactory = size => {
  switch (size) {
    case 'extraSmall':
      return extraSmall();
    case 'small':
      return small();
    case 'medium':
      return medium();
    case 'large':
      return large();
    default:
      return small();
  }
};

export { buttonSizeFactory };
