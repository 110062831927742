import styled from 'styled-components';

import { colors, medias } from 'assets/styled/tokens';

import arrowLeftIcon from 'assets/icons/arrow-left.svg';

export const StyledColumnFillFields = styled.div`
  width: 100%;
  height: 100%;
  display: none;

  svg {
    margin-left: -60px;
  }

  span {
    position: relative;
    width: 100%;
    max-width: 360px;
    font-weight: 100;
    font-size: 36px;
    line-height: 36px;
    color: ${colors.silver3};

    &:before {
      position: absolute;
      top: 8px;
      left: -50px;
      display: block;
      width: 24px;
      height: 16px;
      background: url(${arrowLeftIcon}) no-repeat center/contain;
      z-index: 1;
    }
  }

  @media screen and (min-width: ${medias.desktopSm}) {
    height: 80%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2rem;
  }
`;
