import { createGlobalStyle } from 'styled-components';

import { colors, devices, fonts } from 'assets/styled/tokens';
import { StyledPage } from 'containers/HeaderFiltersPage/styled';

export const CSSGlobal = createGlobalStyle`
/* Global */
  * {
    outline: none;
  }
  body > iframe {
  display: none;
}
  body {

    font-family: ${fonts.family.text};

  }
  .md-chat-widgetwrapper {
    @media print {
      display: none;
    }
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  li,
  body {
    line-height: calc(100% + 8px);
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: Blinker, sans-serif;
    letter-spacing: 0;
  }
  body,
  .root {
    min-height: 100vh;

  }
  &::-webkit-scrollbar {
    width: 10px;
    height: 80%;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    width: 15px;
    height: 15px;
    border: 1px solid black;
  }

  &::-webkit-scrollbar-thumb {
    background: ${colors.silver3};
    border-radius: 100px;
    height: 160px;
  }

  /* Handle on hover */
  &:not(${StyledPage})::-webkit-scrollbar-thumb:hover {
    background: ${colors.silver2};
  }
  button {
    font-family: ${fonts.family.text};
    transition: all .2s ease-in-out;

    &:hover {
      cursor: pointer;
    }

    &:focus {
      outline:0;
    }
  }
  /* Responsive */
  @media ${devices.screen} {
  html {
    font-size: 16px;
  }
  }

  @media ${devices.screenWide} {

    html {
      font-size: 18px;
    }

  }
`;
