import styled from 'styled-components';
import { colors } from 'assets/styled/tokens';

export const TitleReport = styled.h3`
  font-size: 18px;
  font-family: Blinker, sans-serif;
  margin-bottom: 18px;
  font-weight: 700;
`;

export const ContainerTable = styled.div`
  table {
    border-spacing: 0;
    border: 1px solid ${colors.whiteGray};
    width: 100%;
    border-radius: 8px 8px 0px 0px;

    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    td,
    th {
      margin: 0;
      border-bottom: 1px solid ${colors.whiteGray};
      border-right: 1px solid ${colors.whiteGray};
      text-align: left;
      font-size: 12px;

      line-height: 14px;
      padding: 17px 12px;
      word-wrap: break-word;

      :nth-child(1),
      :nth-child(2),
      :nth-child(4) {
        width: 310px;
      }

      :nth-child(3) {
        width: 690px;
      }

      :last-child {
        border-right: 0;
      }
    }

    th {
      font-weight: 500;
    }

    td {
      border-top: 0;
      color: ${colors.gray};

      .download-report {
        display: flex;
        align-content: center;
        align-items: center;
      }

      button {
        color: ${colors.primary};
        text-transform: uppercase;
        text-decoration: underline;
        margin-left: 10px;
        padding: 0;
        text-align: start;
      }

      svg {
        width: 15px;
        height: 15px;

        > path {
          fill: ${colors.primary};
        }
      }
    }
  }
`;

export const DownloadButton = styled.button`
  color: ${colors.primary};
  text-transform: uppercase;
  text-decoration: underline;
  margin-left: 10px;
  padding: 0;
  text-align: start;
`;

export const UpdateGeneratedReportsButton = styled.button`
  color: ${colors.primary};
  border: 1px solid #f38a00;
  border-radius: 4px;
  width: 167px;
  height: 32px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  margin-left: 10px;
  padding: 0;
  text-align: start;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  box-sizing: border-box;
  margin-bottom: 24px;
`;
export const SubHeader = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;
