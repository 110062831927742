import Conditional from 'components/Conditional';
import React from 'react';
import PaginationRange from '../PaginationRange';
import {
  StyledItemsPerPageSelect,
  StyledITotalItemsContainer,
  StyledItemsPerPageContainer,
  StyledPaginationButton,
  StyledPaginationControlsContainer,
} from './styled';

function PaginationControls({
  isActive,
  pageCount,
  pageIndex,
  pageSize,
  setPageIndex,
  setPageSize,
  totalCount,
  itemsPerPageRange = [5, 10, 20, 50, 100],
}) {
  return (
    <StyledPaginationControlsContainer>
      {isActive && (
        <>
          <Conditional when={pageCount > 1}>
            <StyledPaginationButton onClick={() => setPageIndex(0)} disabled={pageIndex === 0}>
              <svg width="10" height="8" viewBox="0 0 10 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M4.98745 3.56596C4.76161 3.81577 4.76161 4.19048 4.98745 4.4153L8.40025 7.81265C8.65119 8.06245 9.0276 8.06245 9.25345 7.81265L9.83061 7.26308C10.0565 7.01327 10.0565 6.63856 9.83061 6.41374L7.39649 3.99063L9.83061 1.59251C10.0565 1.36768 10.0565 0.967994 9.83061 0.743169L9.25345 0.168618C9.0276 -0.0562061 8.65119 -0.0562061 8.40025 0.168618L4.98745 3.56596ZM0.169385 4.4153L3.58218 7.81265C3.83312 8.06245 4.20954 8.06245 4.43538 7.81265L5.01255 7.26308C5.23839 7.01327 5.23839 6.63856 5.01255 6.41374L2.57842 3.99063L5.01255 1.59251C5.23839 1.36768 5.23839 0.967994 5.01255 0.743169L4.43538 0.168618C4.20954 -0.0562061 3.83312 -0.0562061 3.58218 0.168618L0.169385 3.56596C-0.0564617 3.81577 -0.0564617 4.19048 0.169385 4.4153Z"
                  fill="#BBBBBB"
                />
              </svg>
            </StyledPaginationButton>
            <StyledPaginationButton onClick={() => setPageIndex(pageIndex - 1)} disabled={pageIndex === 0}>
              <svg width="5" height="8" viewBox="0 0 5 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M0.163438 3.56596C-0.0544794 3.81577 -0.0544794 4.19048 0.163438 4.4153L3.45642 7.81265C3.69855 8.06245 4.06174 8.06245 4.27966 7.81265L4.83656 7.26308C5.05448 7.01327 5.05448 6.63856 4.83656 6.41374L2.48789 3.99063L4.83656 1.59251C5.05448 1.36768 5.05448 0.967994 4.83656 0.743169L4.27966 0.168618C4.06174 -0.0562061 3.69855 -0.0562061 3.45642 0.168618L0.163438 3.56596Z"
                  fill="#BBBBBB"
                />
              </svg>
            </StyledPaginationButton>
          </Conditional>

          <PaginationRange
            onPageChange={page => setPageIndex(page)}
            pageCount={pageCount}
            currentPage={pageIndex + 1}
            pageSize={pageSize}
          />
          <Conditional when={pageCount > 1}>
            <StyledPaginationButton onClick={() => setPageIndex(pageIndex + 1)} disabled={pageIndex === pageCount - 1}>
              <svg width="5" height="8" viewBox="0 0 5 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M4.83656 3.56596C5.05448 3.81577 5.05448 4.19048 4.83656 4.4153L1.54358 7.81265C1.30145 8.06245 0.938257 8.06245 0.720339 7.81265L0.163438 7.26308C-0.0544791 7.01327 -0.0544791 6.63856 0.163438 6.41374L2.51211 3.99063L0.163438 1.59251C-0.0544791 1.36768 -0.0544791 0.967994 0.163438 0.743169L0.720339 0.168618C0.938257 -0.0562061 1.30145 -0.0562061 1.54358 0.168618L4.83656 3.56596Z"
                  fill="#BBBBBB"
                />
              </svg>
            </StyledPaginationButton>
            <StyledPaginationButton onClick={() => setPageIndex(pageCount - 1)} disabled={pageIndex === pageCount - 1}>
              <svg width="10" height="8" viewBox="0 0 10 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M5.01255 3.56596C5.23839 3.81577 5.23839 4.19048 5.01255 4.4153L1.59975 7.81265C1.34881 8.06245 0.972397 8.06245 0.74655 7.81265L0.169386 7.26308C-0.0564613 7.01327 -0.0564613 6.63856 0.169386 6.41374L2.60351 3.99063L0.169386 1.59251C-0.0564613 1.36768 -0.0564613 0.967994 0.169386 0.743169L0.74655 0.168618C0.972397 -0.0562061 1.34881 -0.0562061 1.59975 0.168618L5.01255 3.56596ZM9.83061 4.4153L6.41782 7.81265C6.16688 8.06245 5.79046 8.06245 5.56462 7.81265L4.98745 7.26308C4.76161 7.01327 4.76161 6.63856 4.98745 6.41374L7.42158 3.99063L4.98745 1.59251C4.76161 1.36768 4.76161 0.967994 4.98745 0.743169L5.56462 0.168618C5.79046 -0.0562061 6.16688 -0.0562061 6.41782 0.168618L9.83061 3.56596C10.0565 3.81577 10.0565 4.19048 9.83061 4.4153Z"
                  fill="#BBBBBB"
                />
              </svg>
            </StyledPaginationButton>
          </Conditional>
        </>
      )}
      {totalCount > 0 && (
        <StyledItemsPerPageContainer>
          Itens por página:
          <StyledItemsPerPageSelect
            value={pageSize}
            onChange={e => {
              setPageSize(Number(e.target.value));
            }}
          >
            {itemsPerPageRange.map(pageSize => (
              <option key={pageSize} value={pageSize}>
                {pageSize}
              </option>
            ))}
          </StyledItemsPerPageSelect>
          <StyledITotalItemsContainer>Total de {totalCount} itens</StyledITotalItemsContainer>
        </StyledItemsPerPageContainer>
      )}
    </StyledPaginationControlsContainer>
  );
}

export default PaginationControls;
