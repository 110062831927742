import { ReactComponent as CloseIcon } from 'assets/icons/close-gray.svg';
import { FiltersContext } from 'context/GlobalFiltersContext';
import { useGeolocalizeStore } from 'pages/Geolocalize/store';
import React, { useContext } from 'react';

import { TEAMS_TAB, TECHNICIANS_TAB, UNIT_MARKERS_TAB } from '../../constants';
import {
  CollapseFiltersSidebarButton,
  ContactButton,
  FiltersSidebarContainer,
  InfoContainer,
  InfoDescription,
  InfoImg,
} from './styled';
import TeamsView from './TeamsView';
import TechniciansView from './TechniciansView';
import TechnicianRouteSubView from './TechniciansView/TechnicianRouteSubView';
import UnitsView from './UnitsView';
import workerImgSrc from 'assets/images/workers-img.png';
import { usePermissions } from 'hooks/usePermission';
const FiltersSidebar = ({ activeTab, handleTabChange, setToastList, toggleExcelRoutesDownloadModal }) => {
  const { headerFiltersContext } = useContext(FiltersContext);
  const { headerFilters } = headerFiltersContext;
  const { permissionContext } = usePermissions();
  const companyId = headerFilters?.companies?.id ?? 4157;
  const [state] = useGeolocalizeStore();
  //const variavelDecisaoRenderizarComponentes = true;
  const goToContactPage = () => {
    window.open('https://leankeep.movidesk.com/kb/form/2343/fale-conosco');
  };
  return (
    <FiltersSidebarContainer isCollapsed={!activeTab}>
      {activeTab && (
        <CollapseFiltersSidebarButton onClick={e => handleTabChange(e, undefined)} aria-label="Fechar filtros">
          <CloseIcon />
        </CollapseFiltersSidebarButton>
      )}

      {state.sidebarRouteSubviewTechnician && (
        <TechnicianRouteSubView companyId={companyId} technician={state.sidebarRouteSubviewTechnician} />
      )}
      {/* {activeTab === TECHNICIANS_TAB && (

          <TechniciansView
            {...{
              toggleExcelRoutesDownloadModal,
              setToastList,
            }}
          />

      )}
      {activeTab === TEAMS_TAB && state.teamMarkers.teamDetails.showTeamDetails && (
        <TechniciansView
          {...{
            toggleExcelRoutesDownloadModal,
            setToastList,
            showTeamDetails: true,
          }}
        />
      )}
      {activeTab === TEAMS_TAB && (
        <TeamsView
          {...{
            activeTab,
            setToastList,
            companyId,
          }}
        />
      )}
      {activeTab === UNIT_MARKERS_TAB && (
        <UnitsView activeTab={activeTab} setToastList={setToastList} companyId={companyId} />
      )} */}
      {permissionContext.geolocalizacao ? (
        <>
          {activeTab === TECHNICIANS_TAB && (
            <TechniciansView
              {...{
                toggleExcelRoutesDownloadModal,
                setToastList,
              }}
            />
          )}

          {activeTab === TEAMS_TAB && state.teamMarkers.teamDetails.showTeamDetails && (
            <TechniciansView
              {...{
                toggleExcelRoutesDownloadModal,
                setToastList,
                showTeamDetails: true,
              }}
            />
          )}

          {activeTab === TEAMS_TAB && (
            <TeamsView
              {...{
                activeTab,
                setToastList,
                companyId,
              }}
            />
          )}
        </>
      ) : (
        <>
          {(activeTab === TEAMS_TAB || activeTab === TECHNICIANS_TAB) && (
            <InfoContainer>
              <InfoImg src={workerImgSrc} />
              <InfoDescription>
                Contrate o <b>módulo de Geolocalização</b> e tenha o monitoramento da localização dos técnicos no mapa.
              </InfoDescription>
              <ContactButton onClick={goToContactPage}>Fale conosco</ContactButton>
            </InfoContainer>
          )}
        </>
      )}
      {activeTab === UNIT_MARKERS_TAB && (
        <UnitsView activeTab={activeTab} setToastList={setToastList} companyId={companyId} />
      )}
    </FiltersSidebarContainer>
  );
};

export default FiltersSidebar;
