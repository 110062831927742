/* istanbul ignore file */

import styled from 'styled-components';

import calendarIconSvg from 'assets/icons/calendar-icon.svg';
import OpenCalendarIconSvg from 'assets/icons/seta-dropdown-select.svg';
import checkIconSvg from 'assets/icons/check-icon.svg';
import { colors } from 'assets/styled/tokens';

export const ChartFilterWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  margin-bottom: 15px;
`;

export const ChartFilterInner = styled.div`
  align-items: center;
  border-radius: 0px 0px 4px 4px;
  border: 1px solid #e5e5e5;
  border-top: 0px;
  box-sizing: border-box;
  color: #888;
  display: flex;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;

  > div:not(:last-child) {
    border-right: 1px solid #e5e5e5;
  }
`;

export const ChartFilterDateTop = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  background: #fff;
  border: 1px solid #e5e5e5;
  border-radius: 4px;
  height: 32px;

  > span {
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #888;
    display: flex;
    align-items: center;

    &:first-child::before {
      content: '';
      display: inline-block;
      width: 14px;
      height: 16px;
      background: url(${calendarIconSvg}) no-repeat center/contain;
      margin-right: 8px;
    }

    &:last-child::before {
      content: ' – ';
      display: inline-block;
      margin: 0 5px;
    }
  }
`;
export const ChartFilterDate = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  background: #fff;

  > span {
    height: 40px;
    width: 100px;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #888;
    display: flex;
    align-items: center;
    padding-left: 2px;

    &:first-child::before {
      content: '';
      display: inline-block;
      width: 14px;
      height: 16px;
      background: url(${calendarIconSvg}) no-repeat center/contain;
      margin-right: 8px;
    }

    &:last-child::before {
      content: ' – ';
      display: inline-block;
      margin: 0 5px;
    }
  }
`;

export const StyledVerticalLine = styled.div`
  content: '';
  border-right: 1px solid #e5e5e5;
  height: 100%;
  width: 1px;
  background: #e5e5e5;
`;
export const ChartFilterDateGroupTop = styled.div`
  display: flex;
  position: relative;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  &:first-child {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    padding-left: 10px;
  }
  &:last-child input {
    border-radius: 0 4px 4px 0;
  }
`;

export const ChartFilterDateGroup = styled.div`
  display: inline-block;
  position: relative;
`;

export const ChartFilterDateTitle = styled.h3`
  background: #fff;
  border: none !important;
  color: ${colors.black};
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: 500;
  padding: 0 10px 0 10px;
`;
export const ChartFilterDateLabel = styled.h3`
  font-family: Roboto, sans-serif;
  font-weight: 400;
  color: #888;
  font-size: 14px;
  padding-left: 10px;
`;
export const ChartFilterDateToggle = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 12px;
  height: 12px;
  background: url(${calendarIconSvg}) no-repeat center/contain;
  z-index: 1;
`;

export const ChartFilterDateInput = styled.input`
  background: #fff;
  border: none;
  color: #888;
  height: 29px;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  padding: 8px;

  &::-webkit-calendar-picker-indicator {
    cursor: pointer;
    position: absolute;
    display: flex;
    justify-content: flex-end;
    left: 0;
    height: 29px;
    width: 180%;
    padding: 2px;
    background: url(${OpenCalendarIconSvg}) no-repeat center/contain;
    background-size: 8px 4px;
  }
`;

export const ChartFilterDropdown = styled.div`
  width: 100px;
  position: absolute;
  top: 100%;
  left: 0;
`;

export const ChartFilterDropdownInner = styled.div`
  background: #fff;
  box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #888;
`;

export const ChartFilterDropdownItem = styled.button<{ selected?: boolean }>`
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #888;
  padding: 4px 12px;
  width: 100%;
  text-align: left;
  position: relative;

  &:not(:last-child) {
    border-bottom: 1px solid #e5e5e5;
  }

  &::after {
    background: url(${checkIconSvg}) no-repeat center/contain;
    content: '';
    display: ${({ selected = false }) => (selected ? 'inline-block' : 'none')};
    height: 8px;
    margin-left: 6px;
    width: 8px;
    position: absolute;
    right: 12px;
    top: 50%;
    transform: translateY(-50%);
  }
`;

export const ChartSystemFilterWrapper = styled.div``;
