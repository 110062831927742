import styled, { css } from 'styled-components';
import { inputStyleFactory } from 'components/Form/shared/inputStyleFactory';
import { DefaultSelect } from 'assets/styled/sheets/components/Forms';
import { colors } from 'assets/styled/tokens';

export const StyledSelectWrapper = styled.div`
  position: relative;
  display: ${({ isActive }) => (isActive ? 'flex' : 'none')};
  flex-direction: column;
`;

export const StyledSelect = styled.input`
  ${DefaultSelect};
  ${props => inputStyleFactory(props.size)};
  height: 32px;
  display: flex;
  flex-direction: column;
  position: relative;
  width: ${props => (props.width ? props.width : '100%')};
  cursor: pointer;
  color: ${colors.gray};
  cursor: ${props => props.disabled && 'not-allowed'};
  border-color: ${props => props.error && colors.bittersweet};

  ${({ inputDifferent }) =>
    inputDifferent &&
    css`
      input {
        /* Adicione os estilos diferentes aqui */
        width: 234px;
        height: 20px;
        padding: 1px 6px;
      }
    }`}

  &:active {
    pointer-events: ${props => props.disabled && 'none'};
  }

  &::placeholder {
    color: ${props => props.disabled && colors.mediumGray};
    font-size: 14px;
  }

  &:focus {
    border-color: ${props => props.disabled && colors.whiteGray};
  }
`;

export const StyledError = styled.div`
  display: block;
  transform: translateY(2px);
  font-size: 12px;
  margin-left: 2px;
  transition: all 300ms ease;
  p {
    line-height: initial;
    color: ${colors.darkRed};
  }
`;

export const StyledDropdownContentWrapper = styled.section`
  overflow: auto;
  display: flex;
  position: relative;
  flex-direction: column;
  max-height: 172px;
  &::-webkit-scrollbar {
    width: 20px;
    &:before {
      content: '';
      margin: 20px;
    }
  }

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    width: 15px;
  }

  &::-webkit-scrollbar-track {
    margin: 3px;
  }

  &::-webkit-scrollbar-thumb {
    height: 20px;

    background: ${colors.silver3};
    border: 5px solid ${colors.alabaster};
    border-radius: 100px;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: ${colors.silver3};
  }
`;

export const StyledDropdownContent = styled.ul`
  position: absolute;
  background-color: ${colors.alabaster};
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 2;
  display: ${props => (props.isOpen ? 'block' : 'none')};
  width: 100%;
  max-height: 214px;
`;

export const DropdownOption = styled.input.attrs({
  type: 'button',
})`
  width: 0;
  height: 0;
  font-size: 12px;
  line-height: 16px;
  text-align: start;
  border: none;
  background: transparent;
  cursor: pointer;
`;

export const StyledDropdownItem = styled.li`
  padding: 1px 10px 1px 15px;

  &:hover {
    background: ${colors.desertStorm};
  }

  ${({ inputDifferent }) =>
    inputDifferent &&
    css`
      input {
        /* Adicione os estilos diferentes aqui */
        width: 0 !important;
        height: 0 !important;
        color: rgb(136, 136, 136);
        border: none;
      }
    `}

  label {
    cursor: pointer;
    font-size: 12px;
    line-height: 16px;
    white-space: nowrap;
    overflow: hidden;
    left: 0;
    display: inline-flex;
    height: 24px;
    align-items: center;
    padding-left: 1rem;
    position: absolute;
    text-overflow: ellipsis;
    width: 100%;
  }
`;

export const StyledSearchBar = styled.div`
  position: relative;
  margin: 8px 0;
  padding: ${props => (props.inputDifferent ? '0 !important' : '1px 6px')};
  img {
  }
`;
