import { colors } from 'assets/styled/tokens';
import styled from 'styled-components';
import { StyledTechniciansViewContainer } from '../styled';
import { Button } from 'components/Button';
import { StyledBatteryChargePercentage } from 'components/Battery/BatteryCharge/styled';
import { StyledAvatarColumn } from '../TechnicianCard/styled';
export const StyledTechniciansRouteSubViewContainer = styled(StyledTechniciansViewContainer)`
  padding: 21.6px 16px 16px 16px;
  ${StyledBatteryChargePercentage} {
    display: flex;
    flex-direction: row;
    align-items: center;
    > span {
      margin-left: 2px;
      margin-top: 5px;
    }
  }
`;
export const StyledArrowLeft = styled.span`
  border: 1px solid ${colors.whiteGray};
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  margin-top: 6px;
  cursor: pointer;
  svg {
  }
  path {
    stroke: ${colors.carbon};
  }
`;
export const StyledSubViewHeaderContainer = styled.div`
  display: flex;
  /* override technician card styles */
  > div {
    background: transparent;
    justify-content: flex-start;
    border: none;
    margin: 0 0 32px 16px;
    height: auto;
    ${StyledAvatarColumn} {
      width: 45px;
    }
    button:last-child {
      display: none;
    }
  }
`;
export const StyledRouteCoveredContainer = styled.div`
  display: flex;
  flex-direction: column;
  background: ${colors.white};
  border: 1px solid ${colors.whiteGray};
  border-radius: 8px;
  padding: 19px 16px 32px;
`;
export const StyledRouteCoveredHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;
export const StyledRouteCovered = styled.h3`
  font-weight: 600;
  font-size: 21px;
  line-height: 25px;
`;
export const StyledRouteReportButton = styled(Button)`
  padding: 4px 15px;
  svg {
    margin-right: 8px;
  }
`;
