import styled from 'styled-components';
import { ViewContainer } from '../styled';
import { StyledSearchInput } from 'components/StyledUtils/inputs';
import { headerTitleCss } from 'utils/helpers/css';

export const StyledTeamsViewContainer = styled(ViewContainer)`
  font-size: 12px;
  line-height: 14px;
`;
export const StyledSearchTeamsInput = styled(StyledSearchInput)`
  width: 100%;
`;

export const StyledTeamsViewControls = styled.div`
  display: flex;
  justify-content: space-between;
`;
export const StyledTeamsViewHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 32px;
`;
export const StyledTeamsCount = styled.div`
  ${headerTitleCss}
`;

export const StyledTeamsViewList = styled.div`
  display: flex;
  flex-direction: column;

  margin-top: 16px;
  margin-bottom: 58px;
`;
