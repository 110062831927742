import React, { useEffect, useMemo } from 'react';
import { useTable, useSortBy, usePagination, useFilters, useGlobalFilter } from 'react-table';
import { debounce } from 'utils/helpers/debounce';
import PaginationControls from '../PaginationControls';
import {
  StyledTableContainer,
  StyledEmptySearchResultsAlert,
  StyledTableHead,
  StyledTableRow,
  StyledTableHeader,
  StyledTableCell,
  StyledSearchPlansInput,
  StyledControlsContainer,
  StyledTableBody,
} from './styled';
import { ArrowToggle } from 'components/ArrowToggle';
// Define a default UI for filtering

export default function GeneralPlansTable({
  columns,
  data,
  fetchData,
  isTablePendingReload,
  setIsTablePendingReload,
  pageIndex,
  setPageIndex,
  activeSearchQuery,
  setActiveSearchQuery,
  isSearchResultsEmpty,
  totalCount,
  pageCount: controlledPageCount,
}) {
  // fix page size
  // const [isFiltersActive, setIsFiltersActive] = useState(false);
  // const [globalSearchFilter, setGlobalSearchFilter] = useState('');
  // const [applicationFilter, setApplicationFilter] = useState('');
  // const [systemFilter, setSystemFilter] = useState('');
  // const [statusFilter, setStatusFilter] = useState('');
  // const [sectorFilter, setSectorFilter] = useState('');

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    // pageOptions,
    setPageSize,
    state: { pageSize, sortBy },
    prepareRow,
    // setFilter,
    // setGlobalFilter
  } = useTable(
    {
      columns,
      data,
      manualPagination: true,
      manualSortBy: true,
      initialState: {
        pageSize: 5,
        sortBy: [
          {
            id: 'ultimaModificacao',
            desc: true,
          },
        ],
      },
      autoResetPage: false,
      autoResetSortBy: false,
      pageCount: controlledPageCount,
    },
    useFilters, // useFilters!
    useGlobalFilter,
    useSortBy,
    usePagination,
  );

  const isPaginationContainerActive = useMemo(() => {
    return controlledPageCount > 1 && data.length > 0 && !activeSearchQuery;
  }, [activeSearchQuery, controlledPageCount, data.length]);

  const updateSearch = debounce(value => {
    setActiveSearchQuery(value);
  }, 1000);

  useEffect(() => {
    fetchData({ pageSize, sortBy });
    if (isTablePendingReload) {
      setIsTablePendingReload(false);
    }
    // setGlobalFilter(globalSearchFilter);
    // setFilter('application', applicationFilter);
  }, [activeSearchQuery, sortBy, fetchData, pageIndex, pageSize, isTablePendingReload, setIsTablePendingReload]);

  return (
    <>
      <StyledControlsContainer>
        {/* <StyledToggleFiltersButton
          onClick={() => setIsFiltersActive(!isFiltersActive)}
        >
          Filtros
          <svg
            width="12"
            height="6"
            viewBox="0 0 12 6"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M6 6L0 0H12L6 6Z" fill="#F5F3F1" />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M0.130033 0.163667C0.315787 -0.0406617 0.632011 -0.05572 0.83634 0.130033L6 4.82427L11.1637 0.130033C11.368 -0.05572 11.6842 -0.0406617 11.87 0.163667C12.0557 0.367996 12.0407 0.68422 11.8363 0.869974L6.33634 5.86997C6.14563 6.04335 5.85438 6.04335 5.66367 5.86997L0.163667 0.869974C-0.0406617 0.68422 -0.05572 0.367996 0.130033 0.163667Z"
              fill="#D0D0D0"
            />
          </svg>
        </StyledToggleFiltersButton> */}
        <StyledSearchPlansInput
          isActive={data.length > 0 || isSearchResultsEmpty}
          onChange={e => {
            updateSearch(e.target.value || undefined); // Set undefined to remove the filter entirely
          }}
          placeholder={`Buscar plano`}
        />
        {isSearchResultsEmpty && (
          <StyledEmptySearchResultsAlert>
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="8" cy="8" r="8" fill="#EC6655" />
              <path
                d="M6.22266 3.16262L6.54589 7.58095C6.54589 7.86053 6.7075 8.00033 7.03074 8.00033H8.97013C9.29336 8.00033 9.45498 7.86053 9.45498 7.58095L9.77821 3.16262C9.77821 3.01012 9.72434 2.89575 9.6166 2.81949C9.53579 2.71783 9.42804 2.66699 9.29336 2.66699H6.70751C6.57282 2.66699 6.45161 2.71783 6.34387 2.81949C6.26306 2.89575 6.22266 3.01012 6.22266 3.16262Z"
                fill="white"
              />
              <path
                d="M8.00043 9.77832C8.48997 9.77832 8.9022 9.95867 9.23715 10.3194C9.59786 10.6543 9.77821 11.0666 9.77821 11.5561C9.77821 12.0456 9.59786 12.4708 9.23715 12.8315C8.9022 13.1664 8.48997 13.3339 8.00043 13.3339C7.5109 13.3339 7.08578 13.1664 6.72507 12.8315C6.39013 12.4708 6.22266 12.0456 6.22266 11.5561C6.22266 11.0666 6.39013 10.6543 6.72507 10.3194C7.08578 9.95867 7.5109 9.77832 8.00043 9.77832Z"
                fill="white"
              />
            </svg>
            Nenhum item corresponde a esta pesquisa. Quer fazer outra busca?
          </StyledEmptySearchResultsAlert>
        )}
        <PaginationControls
          isActive={isPaginationContainerActive}
          pageIndex={pageIndex}
          totalCount={totalCount}
          pageCount={controlledPageCount}
          setPageIndex={setPageIndex}
          setPageSize={setPageSize}
          pageSize={pageSize}
          itemsPerPageRange={[5, 10, 20, 50, 100]}
        />
      </StyledControlsContainer>
      {/* <StyledFiltersContainer isActive={isFiltersActive}>
        <StyledFormGroup label="Sistema">
          <StyledFilterSelect
            onChange={(e) => {
              setSystemFilter(e.target.value);
            }}
            size="small"
            data-cy="audit-report-unit-groups-select"
            placeholder="Seleção opcional"
            options={fields?.system}
            name="system"
            control={control}
            defaultValue=""
          />
        </StyledFormGroup>
        <StyledFormGroup label="Status">
          <StyledFilterSelect
            onChange={(e) => {
              setStatusFilter(e.target.value);
            }}
            size="small"
            data-cy="audit-report-unit-groups-select"
            placeholder="Seleção opcional"
            options={fields?.status}
            name="status"
            control={control}
            defaultValue=""
          />
        </StyledFormGroup>
        <StyledFormGroup label="Setor">
          <StyledFilterSelect
            onChange={(e) => {
              setSectorFilter(e.target.value);
            }}
            size="small"
            data-cy="audit-report-unit-groups-select"
            placeholder="Seleção opcional"
            options={fields?.sector}
            name="sector"
            control={control}
            defaultValue=""
          />
        </StyledFormGroup>
        <StyledFormGroup label="Aplicação">
          <StyledFilterSelect
            onChange={(e) => {
              setApplicationFilter(e.target.value);
            }}
            size="small"
            data-cy="audit-report-unit-groups-select"
            placeholder="Seleção opcional"
            options={fields?.application}
            name="application"
            control={control}
            defaultValue=""
          />
        </StyledFormGroup>
        <StyledClearFiltersButton>
          <ClearGrayIcon width={16} height={12} />
        </StyledClearFiltersButton>
      </StyledFiltersContainer> */}
      {!isSearchResultsEmpty && data.length > 0 && (
        <StyledTableContainer {...getTableProps()}>
          <StyledTableHead>
            {headerGroups.map(headerGroup => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <StyledTableHeader
                    {...column.getHeaderProps(
                      column.disableSortBy !== true
                        ? column.getSortByToggleProps({
                            title: 'Ordenar por',
                          })
                        : column.getSortByToggleProps(),
                    )}
                  >
                    {column.render('Header')}

                    {column.disableSortBy !== true && (
                      <ArrowToggle
                        isSorted={column.isSorted}
                        isSortedDesc={column.isSortedDesc}
                        width="8"
                        height="10"
                        viewBox="0 0 8 10"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      />
                    )}
                  </StyledTableHeader>
                ))}
              </tr>
            ))}
          </StyledTableHead>
          <StyledTableBody {...getTableBodyProps()}>
            {page.map(row => {
              prepareRow(row);
              return (
                <StyledTableRow {...row.getRowProps()}>
                  {row.cells.map(cell => {
                    return <StyledTableCell {...cell.getCellProps()}>{cell.render('Cell')}</StyledTableCell>;
                  })}
                </StyledTableRow>
              );
            })}
          </StyledTableBody>
        </StyledTableContainer>
      )}
    </>
  );
}
