import { AxiosReportLas } from '../config';

const AvailabilityService = {
  generateExcel: ({
    empresa,
    dataInicial,
    dataFinal,
    unidades = [],
    grupoUnidades = [],
    subGrupoUnidades = [],
    gruposEquipamento = [],
    tiposEquipamento = [],
    equipamentos = [],
    sistemas = [],
  }) => {
    return AxiosReportLas({
      method: 'post',
      responseType: 'blob',
      headers: {
        'content-type': 'application/json',
      },
      url: `/v1/Relatorios/Disponibilidade`,
      data: {
        empresa,
        dataInicial,
        dataFinal,
        unidades,
        grupoUnidades,
        subGrupoUnidades,
        gruposEquipamento,
        tiposEquipamento,
        equipamentos,
        sistemas,
      },
    });
  },
};

export { AvailabilityService };
