import { colors } from 'assets/styled/tokens';

const { css } = require('styled-components');

const whiteOutline = () => css`
  background: ${colors.transparent};
  border-color: ${colors.white};
  color: ${colors.white};

  &:hover {
    background: ${colors.white};
    transition: all 0.5s;
    color: ${colors.carbon};
  }

  &:active {
    background: ${colors.pampas2};
  }

  &:disabled {
    background: ${colors.pampas};
    color: ${colors.silver};
  }
`;

export { whiteOutline };
