/* istanbul ignore file */

import { css } from 'styled-components';

export const growOnHover = css`
  /* Grow */
  display: inline-flex;
  vertical-align: middle;
  transform: translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  backface-visibility: hidden;
  -moz-osx-font-smoothing: grayscale;
  transition-duration: 0.3s;
  transition-property: transform;
  &:hover,
  &:focus,
  &:active {
    transform: scale(1.04);
  }
`;
export const defaultTransition = css`
  transition: all 300ms ease;
`;
