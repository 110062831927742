import * as React from 'react';
import Routes from 'routes';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { GlobalComponentsContextProvider } from 'context/GlobalComponentsContext';
import { GeolocalizeStoreProvider } from 'pages/Geolocalize/store';
import { HeaderFiltersProvider } from 'context/GlobalFiltersContext';
import { AuthContextProvider } from 'context/AuthContext';
import { SocketIoProvider } from 'context/SocketIoContext';
import { QueryClient, QueryClientProvider } from 'react-query';
import { CSSReset, CSSGlobal } from 'assets/styled/sheets/generic';
import Amplifique from 'components/Amplifique';
const history = createBrowserHistory();

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
});
export const App = () => (
  <React.StrictMode>
    <Router history={history}>
      <QueryClientProvider client={queryClient}>
        <CSSReset />
        <CSSGlobal />
        <AuthContextProvider>
          <GlobalComponentsContextProvider>
            <HeaderFiltersProvider>
              <GeolocalizeStoreProvider>
                <SocketIoProvider>
                  <Routes />
                </SocketIoProvider>
              </GeolocalizeStoreProvider>
              {process.env?.NODE_ENV !== 'development' && <Amplifique />}
            </HeaderFiltersProvider>
          </GlobalComponentsContextProvider>
        </AuthContextProvider>
      </QueryClientProvider>
    </Router>
  </React.StrictMode>
);
