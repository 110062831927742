import { states } from 'components/HeaderFilters/constants';

const citiesDependencies = async ({ filterResponse }) => {
  const { state } = filterResponse;

  if (!state) {
    return [];
  }

  return [
    {
      filter: states,
      value: state,
    },
  ];
};

export { citiesDependencies };
