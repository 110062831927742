import styled from 'styled-components';
import { devices } from 'assets/styled/tokens';

export const FirstLineHomeWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  @media ${devices.screenWide} {
    justify-content: space-between;
  }
`;
export const Bg1080pContainer = styled.div`
  @media ${devices.tabletPortrait} {
    display: none;
  }
  @media ${devices.screenWide} {
    position: fixed;
    right: 0;
    bottom: 0;
    display: flex;
  }
`;
