import * as React from 'react';

import { useBoolean, useDebounce } from 'hooks';
import { fetcher } from 'services';
import { LkpChart } from 'components/Charts/Chart';
import {
  ChartsParamsType,
  ChartActivitiesOccurrencesPeriodType,
  ChartUpdate,
  UpdateParamsType,
} from 'components/Charts/types';
import { COMPARATIVE_WEEKS_OPTIONS } from './options';
import { LkpChartTopFilter } from '../Filters';
import { HeaderFiltersType } from 'context/GlobalFiltersContext/initialState';
import { getErrorMessageByStatusCode, minutesToHours } from 'utils';
import { isNumber } from 'fp-ts/lib/number';
import { useParams } from 'react-router-dom';
import { createToast } from '../createToast';

export interface LkpChartActivitiesOccurrencesPeriodProps {
  headerFilters: HeaderFiltersType;
  setToastList: any;
}

const HTTP_PATH = '/horas-preventivas-corretivas-total';

export function LkpChartActivitiesOccurrencesPeriod({
  headerFilters,
  setToastList,
}: LkpChartActivitiesOccurrencesPeriodProps): React.ReactElement {
  const { dateFrom, dateTo } = useParams<{ dateFrom: string; dateTo: string }>();
  const { value: isLoading, setValue: setLoading } = useBoolean();
  const { value: isError, setValue: setIsError } = useBoolean();
  const [errorMessage, setErrorMessage] = React.useState<string>();

  const [series, setSeries] = React.useState<Array<number>>([]);
  const [labels, setLabels] = React.useState<Array<string>>([]);
  const [paramsSystems, setParamsSystems] = React.useState<string>('');
  const [updateParamns, setUpdateParamns] = React.useState<UpdateParamsType>({
    Empresa: headerFilters?.companies?.id || 0,
    Inicio: dateFrom,
    Fim: dateTo,
  });
  const [params, setParams] = React.useState<ChartsParamsType>({
    Empresa: headerFilters?.companies?.id || 0,
    Sites: headerFilters?.units?.id,
    Inicio: dateFrom,
    Fim: dateTo,
  });
  const [lastUpdateMessage, setLastUpdateMessage] = React.useState();
  const debouncedStartValue = useDebounce<string>(params?.Inicio || '', 500);
  const debouncedEnvValue = useDebounce<string>(params?.Fim || '', 500);
  const debouncedParamsSystemsValue = useDebounce<string>(paramsSystems || '', 500);

  React.useEffect(() => {
    (async () => {
      await fetchChartData();
      await LastUpdate();
    })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedStartValue, debouncedEnvValue, debouncedParamsSystemsValue]);
  const LastUpdate = async () => {
    const _httpPath2 = `/ultima-atualizacao-dm-horas-tecnicas-por-sistema?Empresa=${updateParamns.Empresa}&Inicio=${updateParamns.Inicio}&Fim=${updateParamns.Fim}`;
    const result = await fetcher.get(_httpPath2, 'dashboard').catch(err => {
      const message = getErrorMessageByStatusCode(err, _httpPath2);
      setErrorMessage(message);
      setIsError(true);
    });
    setLastUpdateMessage(result);
    const newToast = createToast({
      type: 'updating',
      message: 'Atualizando dados. Aguarde alguns minutos e recarregue a página para atualizar o gráfico.',
    });
    setToastList(prevState => [...prevState, newToast]);
  };
  const UpdateChart = async () => {
    const _httpPath1 = `/atualizar-dm-horas-tecnicas-por-sistema?Empresa=${updateParamns.Empresa}&Inicio=${updateParamns.Inicio}&Fim=${updateParamns.Fim}`;
    await fetcher.get<ChartUpdate>(_httpPath1, 'dashboard').catch(err => {
      const message = getErrorMessageByStatusCode(err, _httpPath1);
      setErrorMessage(message);
      setIsError(true);
    });
    await LastUpdate();
  };
  const fetchChartData = async () => {
    setLoading(true);

    let _httpPath = HTTP_PATH;
    if (!!paramsSystems) _httpPath = `${_httpPath}?${paramsSystems}`;

    const result = await fetcher
      .get<Array<ChartActivitiesOccurrencesPeriodType>>(_httpPath, 'dashboard', { params })
      .catch(error => {
        const message = getErrorMessageByStatusCode(error, _httpPath);
        setErrorMessage(message);
        setIsError(true);
      });

    if (result) {
      mountLabels(result);
      mountSeries(result);
    }

    setLoading(false);
  };

  const handleChangeStartDate = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value: Inicio } = event.target;
    setParams({ ...params, Inicio });
    setUpdateParamns({ ...params, Inicio });
  };

  const handleChangeEndDate = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value: Fim } = event.target;
    setParams({ ...params, Fim });
    setUpdateParamns({ ...params, Fim });
  };

  const mountSeries = (data: Array<ChartActivitiesOccurrencesPeriodType>) => {
    setSeries(data.map(({ tempo }) => (isNumber(Number(tempo)) ? Number(tempo) : 0)));
  };

  const mountLabels = (data: Array<ChartActivitiesOccurrencesPeriodType>) => {
    setLabels(data.map(({ tipo }) => tipo));
  };

  const handleSelectSystems = (selectedSystems: string) => {
    setParamsSystems(selectedSystems);
  };
  const chartSchemeColor = () => {
    if (labels[0] === 'Preventiva') {
      return ['#00C0C7'];
    }
    if (labels.length === 2 && !labels.includes('Preventiva')) {
      return ['#E8871A', '#804A0E'];
    }
    if (labels.length === 2 && labels.includes('Preventiva')) {
      return ['#E8871A', '#00C0C7'];
    }
    return ['#E8871A', '#804A0E', '#00C0C7'];
  };
  return (
    <LkpChart
      chart={labels.includes('Outras corretivas') ? true : false}
      isTecHour={true}
      type="donut"
      title="Horas Técnicas Investidas - Atividades x Ocorrências por período"
      companyId={headerFilters?.companies?.id || 0}
      series={series}
      //height={446}
      //width={500}
      options={{
        ...COMPARATIVE_WEEKS_OPTIONS,
        chart: {
          type: 'donut',
          toolbar: {
            show: false,
          },
        },
        labels,
        colors: chartSchemeColor(),
        tooltip: {
          y: {
            formatter: function (value) {
              return minutesToHours(Number(value));
            },
          },
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
                toolbar: {
                  show: false,
                },
              },
              legend: {
                position: 'bottom',
              },
            },
          },
        ],
      }}
      error={isError}
      errorMessage={errorMessage}
      loading={isLoading}
      onChartTryAgain={fetchChartData}
      chartUpdate={UpdateChart}
      lastUpdateMessage={lastUpdateMessage}
      lastUpdate={LastUpdate}
      filter={
        <LkpChartTopFilter
          onChangeStartDate={handleChangeStartDate}
          onChangeEndDate={handleChangeEndDate}
          onSelectSystems={handleSelectSystems}
        />
      }
    />
  );
}
