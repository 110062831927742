import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import { StyledField, StyledLabel } from './styled';
import InputTooltip from '../InputTooltip';

const FormGroup = props => {
  const { label, size, children, isRequired, disabled, isActive = true, showTooltip, tooltipMessage } = props;

  const node = useRef();
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);
  const [isMounted, setIsMounted] = useState(false);

  const handleClickOutside = e => {
    if (node.current?.contains(e.target)) {
      // inside click
      return;
    }
    // outside click
    setIsTooltipVisible(false);
  };

  useEffect(() => {
    setIsMounted(true);
    if (isTooltipVisible && isMounted) {
      document.addEventListener('mousedown', handleClickOutside);

      setTimeout(() => setIsTooltipVisible(false), 3000);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      setIsMounted(false);
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [disabled, isTooltipVisible, isMounted]);

  return isActive ? (
    <StyledField {...props} ref={node} onClick={() => setIsTooltipVisible(prevState => !prevState)}>
      {label && (
        <StyledLabel size={size} disabled={disabled}>
          {label} <span>{isRequired && '*'}</span>
        </StyledLabel>
      )}
      {isMounted && showTooltip && <InputTooltip isVisible={disabled && isTooltipVisible} message={tooltipMessage} />}
      {children}
    </StyledField>
  ) : (
    <></>
  );
};

FormGroup.propTypes = {
  disabled: PropTypes.bool,
  isRequired: PropTypes.bool,
  label: PropTypes.string,
  children: PropTypes.node,
  error: PropTypes.bool,
  errormessage: PropTypes.string,
  showTooltip: PropTypes.bool,
  tooltipMessage: PropTypes.string,
};

FormGroup.defaultProps = {
  disabled: false,
  isRequired: false,
  label: '',
  error: false,
  errormessage: '',
  showTooltip: false,
  tooltipMessage: '',
};

export { FormGroup };
