import styled from 'styled-components';
import { devices } from 'assets/styled/tokens';

export const AdsContainer = styled.div`
  box-shadow: 0px 1px 8px 0px #0000001a;
  background: #ffffff;
  //width: 515px;
  height: 532px;
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  padding: 12px 24px 24px 24px;
  margin-left: 24px;
  @media ${devices.screen} {
    width: 515px;
  }
  @media (min-width: 1360px) {
    width: 555px;
  }
  @media (min-width: 1440px) {
    width: 585px;
  }
  @media (min-width: 1500px) {
    width: 605px;
  }
  @media (min-width: 1600px) {
    width: 670px;
  }
  @media (min-width: 1700px) {
    width: 755px;
  }
  @media ${devices.screenWide} {
    width: 49%;
  }
`;

export const Header = styled.div`
  width: 100%;
  display: flex;
  height: auto;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  h1 {
    font-family: Blinker;
    font-size: 32px;
    font-weight: 700;
    line-height: 36px;
    letter-spacing: 0.5px;
    text-align: left;
  }
`;

export const SocialMediaIconsContainer = styled.div`
  display: flex;
  flex-direction: row;
  h2 {
    font-family: Roboto;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.3px;
    text-align: left;
    color: #888888;
    margin-right: 16px;
  }
  div svg:hover {
    cursor: pointer;
  }
  div :not(:last-child) {
    margin-right: 8px;
  }
`;
