import styled, { css } from 'styled-components';

export const BaseMapToastCss = css`
  background: #ffffff;
  border-radius: 4px;
  overflow: hidden;
  padding: 12px;
`;

export const StyledModalMarkerModal = styled.div`
  ${BaseMapToastCss};
`;
