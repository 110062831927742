import { AxiosReportLas } from '../config';

const ReportService = {
  getGeneratedReports: ({ usuarioId, tipos }) => {
    let params = new URLSearchParams();
    tipos.forEach(element => {
      params.append('tipo', element);
    });
    params.append('usuarioId', usuarioId);
    return AxiosReportLas({
      method: 'get',
      url: '/v1/Relatorios/ObterRelatoriosGerados',
      params,
      headers: {
        'content-type': 'application/json',
      },
    });
  },
  redoReport: ({ id }) => {
    return AxiosReportLas({
      method: 'post',
      url: '/v1/Relatorios/ReprocessarRelatorio',
      params: {
        id,
      },
      headers: {
        'content-type': 'application/json',
      },
    });
  },
  getUrlToDownload: ({ nomeArquivo, tipo }) => {
    return AxiosReportLas({
      method: 'post',
      url: '/v1/Relatorios/ObterUrlRelatorio',
      params: {
        nomeArquivo,
        tipo,
      },
      headers: {
        'content-type': 'application/json',
      },
    });
  },
};

export { ReportService };
