import { pipe } from 'fp-ts/function';
import { LkpDropdownMenuItemProps } from 'components/Dropdown/Menu';

export function getFirstId(items): string {
  return pipe(items, items => (items && items.length > 0 ? items[0].id : null)) || '';
}

export function toggleAllItems({
  items,
  toggle: selected,
}: {
  items: Array<LkpDropdownMenuItemProps>;
  toggle: boolean;
}): Array<LkpDropdownMenuItemProps> {
  const _firstId = getFirstId(items);

  return items.map(item => {
    if (item && item?.id === _firstId) return { ...item, selected, partialSelected: false };
    return { ...item, selected };
  });
}

export function toggleItem({
  items,
  toggleItem,
}: {
  items: Array<LkpDropdownMenuItemProps>;
  toggleItem: string;
}): Array<LkpDropdownMenuItemProps> {
  const _firstId = getFirstId(items);

  const _newItems = items.map(item => {
    if (item && item?.id === toggleItem) return { ...item, selected: !item?.selected };
    return { ...item };
  });

  const _countSelectedItems = _newItems.filter(item => item.selected).length;

  return _newItems.map(item => {
    if (item && item?.id === _firstId)
      return {
        ...item,
        partialSelected: _countSelectedItems > 0,
        selected: items.length === _countSelectedItems,
      };
    return { ...item };
  });
}
