import React from 'react';
import { colors } from 'assets/styled/tokens';

import {
  StyledLogin,
  StyledLoginWrapper,
  StyledLoginContainer,
  StyledLoginIntro,
  StyledLoginMain,
  CarouselSlide1TopImage,
  CarouselSlide1BottomImage,
  StyledHeader,
  StyledFooter,
} from './styled';

const PreAuthPage = ({ children }) => {
  return (
    <StyledLogin>
      <StyledLoginIntro>
        <StyledLoginWrapper>
          <CarouselSlide1TopImage
            width="528"
            height="277"
            viewBox="0 0 528 277"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M336.046 122.946L336.046 -18.547C336.067 -33.135 332.243 -47.471 324.958 -60.11C317.674 -72.7489 307.186 -83.2443 294.552 -90.5383L171.939 -161.285C159.323 -168.568 145.013 -172.403 130.445 -172.403C115.878 -172.403 101.567 -168.568 88.9519 -161.285L-33.6615 -90.5383C-46.2951 -83.2443 -56.7827 -72.7489 -64.0674 -60.1099C-71.3522 -47.4709 -75.1765 -33.135 -75.155 -18.547L-75.155 122.946C-75.14 137.499 -71.2984 151.792 -64.0155 164.391C-56.7327 176.991 -46.2646 187.453 -33.6614 194.73L88.9519 265.476C101.568 272.76 115.878 276.594 130.445 276.594C145.013 276.594 159.323 272.76 171.939 265.476L294.552 193.692C307.005 186.505 317.376 176.205 324.65 163.803C331.924 151.401 335.851 137.322 336.046 122.946Z"
              fill="white"
              fillOpacity="0.08"
            />
            <path
              d="M423.1 71.3033L423.099 -70.1896C423.121 -84.7776 419.297 -99.1136 412.012 -111.753C404.727 -124.392 394.24 -134.887 381.606 -142.181L258.993 -212.927C246.377 -220.211 232.066 -224.045 217.499 -224.045C202.932 -224.045 188.621 -220.211 176.006 -212.927L53.3922 -142.181C40.7586 -134.887 30.271 -124.391 22.9863 -111.752C15.7015 -99.1135 11.8772 -84.7776 11.8987 -70.1896L11.8988 71.3033C11.9137 85.8562 15.7553 100.149 23.0382 112.749C30.321 125.348 40.7891 135.811 53.3923 143.087L176.006 213.834C188.621 221.117 202.932 224.952 217.499 224.952C232.066 224.952 246.377 221.117 258.993 213.834L381.606 142.05C394.058 134.862 404.429 124.563 411.703 112.161C418.977 99.7588 422.904 85.6797 423.1 71.3033Z"
              fill="white"
              fillOpacity="0.06"
            />
            <path
              d="M527.859 12.2857L527.859 -129.207C527.881 -143.795 524.056 -158.131 516.772 -170.77C509.487 -183.409 498.999 -193.904 486.366 -201.198L363.752 -271.945C351.137 -279.229 336.826 -283.063 322.259 -283.063C307.692 -283.063 293.381 -279.229 280.765 -271.945L158.152 -201.198C145.518 -193.904 135.031 -183.409 127.746 -170.77C120.461 -158.131 116.637 -143.795 116.659 -129.207L116.659 12.2857C116.673 26.8386 120.515 41.1316 127.798 53.731C135.081 66.3304 145.549 76.7931 158.152 84.0695L280.765 154.816C293.381 162.1 307.692 165.934 322.259 165.934C336.826 165.934 351.137 162.1 363.752 154.816L486.366 83.0321C498.818 75.8446 509.189 65.5452 516.463 53.1432C523.737 40.7412 527.664 26.6621 527.859 12.2857Z"
              fill="white"
              fillOpacity="0.04"
            />
          </CarouselSlide1TopImage>

          <StyledLoginMain>
            <svg viewBox="0 0 202 55" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M199.764 4.33071L193.423 0.7004C192.78 0.327343 192.05 0.130859 191.307 0.130859C190.564 0.130859 189.833 0.327343 189.191 0.7004L182.849 4.33071C182.204 4.69966 181.667 5.23271 181.294 5.87582C180.921 6.51893 180.725 7.24924 180.725 7.99273V15.2375C180.725 15.983 180.922 16.7154 181.295 17.3609C181.668 18.0065 182.204 18.5426 182.849 18.9154L189.191 22.5298C189.833 22.9029 190.564 23.0994 191.307 23.0994C192.05 23.0994 192.78 22.9029 193.423 22.5298L199.764 18.9154C200.41 18.5426 200.946 18.0065 201.319 17.3609C201.692 16.7154 201.888 15.983 201.889 15.2375V7.99273C201.889 7.24924 201.693 6.51893 201.319 5.87582C200.946 5.23271 200.41 4.69966 199.764 4.33071ZM191.362 4.21974C191.676 4.21974 191.982 4.31272 192.243 4.48691C192.504 4.6611 192.707 4.90869 192.827 5.19837C192.947 5.48804 192.978 5.80679 192.917 6.1143C192.856 6.42182 192.705 6.70429 192.483 6.926C192.262 7.14771 191.979 7.29869 191.672 7.35986C191.364 7.42103 191.045 7.38963 190.756 7.26965C190.466 7.14966 190.218 6.94647 190.044 6.68577C189.87 6.42507 189.777 6.11857 189.777 5.80503C189.777 5.38459 189.944 4.98136 190.241 4.68406C190.539 4.38676 190.942 4.21974 191.362 4.21974ZM186.068 8.45246H196.673V10.5767H186.068V8.45246ZM195.294 17.5996L191.473 13.8107C191.463 13.7943 191.45 13.7807 191.433 13.7713C191.416 13.7619 191.397 13.7569 191.378 13.7569C191.359 13.7569 191.34 13.7619 191.324 13.7713C191.307 13.7807 191.293 13.7943 191.283 13.8107L187.463 17.5837L185.56 16.474L189.777 12.3047C190.201 11.8903 190.77 11.6582 191.362 11.6582C191.955 11.6582 192.524 11.8903 192.948 12.3047L197.149 16.474L195.294 17.5996Z"
                fill={colors.primary}
              />
              <path
                d="M6.40755 40.4118C6.69259 40.6755 7.02714 40.8799 7.3918 41.0132C7.75646 41.1466 8.14396 41.2062 8.53184 41.1886H19.1216V46.3408H7.7709C6.76127 46.3541 5.76074 46.1486 4.83812 45.7384C3.9009 45.2595 3.062 44.6088 2.36507 43.8202C1.79862 43.1729 1.33845 42.4398 1.00172 41.6483C0.587909 40.5934 0.383304 39.4681 0.399309 38.3351V19.4067H5.75758V38.3351C5.71561 38.7074 5.75173 39.0844 5.86365 39.442C5.97556 39.7995 6.1608 40.1298 6.40755 40.4118Z"
                fill={colors.primary}
              />
              <path
                d="M21.3863 31.5977C21.3725 30.5096 21.583 29.4303 22.0045 28.4271C22.385 27.4881 22.9209 26.6199 23.5898 25.8589C24.263 25.126 25.0701 24.5287 25.9678 24.0992C26.7997 23.6816 27.716 23.4593 28.6469 23.4493H33.7357C34.8389 23.4217 35.9307 23.6783 36.9063 24.1944C37.779 24.688 38.5443 25.3509 39.1574 26.1443C39.767 26.9129 40.2487 27.7747 40.5841 28.6966C40.9163 29.573 41.0881 30.5019 41.0914 31.4391V37.2413H26.6177V38.462C26.5741 38.8847 26.6335 39.3117 26.7909 39.7065C26.9482 40.1013 27.1989 40.4521 27.5213 40.7289C28.2126 41.2091 29.0426 41.4486 29.8834 41.4106H40.2988V46.4042H28.7262C27.7373 46.4145 26.7581 46.2089 25.8568 45.8018C24.9815 45.4265 24.1854 44.8886 23.5106 44.2166C22.8304 43.506 22.2924 42.6719 21.9253 41.7593C21.5312 40.7495 21.3373 39.6726 21.3546 38.5888L21.3863 31.5977ZM26.697 32.9452H35.9392V32.7232C35.9868 31.6876 35.673 30.6677 35.0515 29.838C34.7703 29.4689 34.4048 29.1727 33.9855 28.9741C33.5663 28.7755 33.1055 28.6803 32.6418 28.6966H29.8359C29.3809 28.6919 28.9326 28.8067 28.5359 29.0295C28.1553 29.2543 27.8216 29.5503 27.5531 29.9014C27.2739 30.2891 27.0548 30.7166 26.9031 31.1696C26.74 31.6821 26.6598 32.2172 26.6653 32.7549L26.697 32.9452Z"
                fill={colors.primary}
              />
              <path
                d="M44.7542 30.7102C44.8466 29.7761 45.0652 28.8588 45.4041 27.9835C45.7622 27.1174 46.303 26.3387 46.9894 25.7007C47.6933 25.0121 48.5291 24.4729 49.4466 24.1154C50.5281 23.6981 51.6801 23.4935 52.8391 23.513H55.5183C56.72 23.4855 57.9139 23.7124 59.0218 24.1788C59.9628 24.5965 60.8094 25.2005 61.5107 25.9543C62.16 26.6797 62.6609 27.5252 62.985 28.4432C63.3016 29.3562 63.4676 30.3146 63.4764 31.2809V46.2619H50.7941C49.8846 46.2777 48.9838 46.0823 48.1625 45.6912C47.4132 45.3472 46.7478 44.8441 46.2126 44.2168C45.6384 43.5772 45.211 42.8197 44.9603 41.9974C44.702 41.128 44.5738 40.2252 44.5798 39.3183C44.5748 38.435 44.714 37.5568 44.992 36.7184C45.2294 35.9533 45.6235 35.2461 46.1492 34.6417C46.6713 34.0445 47.3216 33.573 48.0516 33.2625C48.8585 32.9007 49.7356 32.7221 50.6197 32.7393H58.2608V32.2955C58.2643 31.7952 58.2165 31.2958 58.1182 30.8053C58.0373 30.3795 57.8701 29.9749 57.6267 29.6163C57.3497 29.2589 56.9754 28.9892 56.5487 28.8395C55.9464 28.6403 55.3111 28.5595 54.6781 28.6017H53.5684C52.6855 28.5351 51.8016 28.7163 51.0161 29.1249C50.4858 29.5053 50.118 30.0711 49.9856 30.7102H44.7542ZM49.3674 39.3817C49.3232 39.9879 49.4852 40.5913 49.8271 41.0938C49.9493 41.262 50.1088 41.3996 50.2931 41.4958C50.4774 41.5921 50.6814 41.6444 50.8892 41.6487H58.2608V37.0989H50.8892C50.6803 37.1062 50.4758 37.1615 50.2917 37.2604C50.1075 37.3594 49.9485 37.4994 49.8271 37.6696C49.4918 38.1751 49.3304 38.7762 49.3674 39.3817Z"
                fill={colors.primary}
              />
              <path
                d="M73.3848 28.6962V46.2612H68.1533V23.5123H79.0601C80.2563 23.4895 81.4441 23.7162 82.5477 24.1781C83.4769 24.5754 84.3149 25.1587 85.0102 25.8921C85.7054 26.6255 86.2431 27.4935 86.5902 28.4426C86.9194 29.3527 87.091 30.3124 87.0975 31.2802V46.2612H81.8661V32.3582C81.8745 31.8585 81.832 31.3592 81.7392 30.8681C81.6622 30.4385 81.4888 30.0319 81.2319 29.6791C80.9453 29.3249 80.5669 29.0562 80.1381 28.9023C79.5389 28.7146 78.9091 28.6446 78.2833 28.6962H73.3848Z"
                fill={colors.primary}
              />
              <path
                d="M115.173 31.5968C115.155 30.5068 115.371 29.4256 115.807 28.4263C116.187 27.4872 116.723 26.619 117.392 25.8581C118.06 25.1257 118.861 24.5283 119.754 24.0984C120.587 23.6839 121.503 23.4618 122.433 23.4485H127.601C128.705 23.4165 129.798 23.6734 130.772 24.1935C131.634 24.6841 132.384 25.348 132.976 26.1434C133.597 26.9043 134.08 27.7681 134.402 28.6958C134.738 29.5718 134.915 30.5004 134.926 31.4383V37.2405H120.452V38.4611C120.406 38.8826 120.463 39.3088 120.618 39.7035C120.772 40.0982 121.02 40.4497 121.34 40.7281C122.032 41.2062 122.861 41.4455 123.702 41.4098H134.117V46.4034H122.544C121.56 46.4155 120.586 46.2097 119.691 45.801C118.811 45.4336 118.014 44.8947 117.345 44.2157C116.659 43.5094 116.12 42.6743 115.759 41.7585C115.352 40.752 115.153 39.6736 115.173 38.588V31.5968ZM120.484 32.9443H129.742V32.7224C129.781 31.6878 129.468 30.6704 128.854 29.8372C128.569 29.4678 128.201 29.1716 127.779 28.9731C127.357 28.7746 126.894 28.6795 126.428 28.6958H123.67C123.215 28.6888 122.766 28.8037 122.37 29.0287C121.985 29.2475 121.65 29.5445 121.387 29.9006C121.102 30.2847 120.883 30.7132 120.737 31.1688C120.569 31.6804 120.483 32.2155 120.484 32.7541V32.9443Z"
                fill={colors.primary}
              />
              <path
                d="M138.701 31.5977C138.688 30.5096 138.898 29.4303 139.32 28.4271C139.7 27.4881 140.236 26.6199 140.905 25.8589C141.575 25.1224 142.383 24.5246 143.283 24.0992C144.115 23.6816 145.031 23.4593 145.962 23.4493H151.051C152.154 23.4217 153.246 23.6783 154.221 24.1944C155.1 24.6863 155.871 25.3493 156.488 26.1443C157.098 26.9129 157.58 27.7747 157.915 28.6966C158.24 29.5751 158.411 30.5027 158.422 31.4391V37.2413H143.949V38.462C143.907 38.8831 143.966 39.3081 144.12 39.7021C144.275 40.0961 144.52 40.448 144.837 40.7289C145.535 41.2071 146.369 41.4463 147.214 41.4106H157.63V46.4042H146.057C145.068 46.4145 144.089 46.2089 143.188 45.8018C142.313 45.4265 141.516 44.8886 140.842 44.2166C140.161 43.506 139.623 42.6719 139.256 41.7593C138.862 40.7495 138.668 39.6726 138.686 38.5888L138.701 31.5977ZM144.012 32.9452H153.254V32.7232C153.302 31.6876 152.988 30.6677 152.367 29.838C152.086 29.4689 151.72 29.1727 151.301 28.9741C150.881 28.7755 150.421 28.6803 149.957 28.6966H147.183C146.728 28.6919 146.28 28.8067 145.883 29.0295C145.502 29.2543 145.169 29.5503 144.9 29.9014C144.621 30.2891 144.402 30.7166 144.25 31.1696C144.087 31.6821 144.007 32.2172 144.012 32.7549V32.9452Z"
                fill={colors.primary}
              />
              <path
                d="M172.449 23.5119C173.644 23.493 174.832 23.6973 175.953 24.1144C176.941 24.4875 177.842 25.0594 178.6 25.7948C179.311 26.5298 179.852 27.4119 180.185 28.3788C180.594 29.4786 180.793 30.6456 180.772 31.8189V37.9381C180.788 39.1058 180.589 40.2666 180.185 41.3623C179.853 42.3436 179.312 43.2413 178.6 43.9939C177.85 44.7398 176.947 45.3132 175.953 45.6743C174.829 46.0782 173.643 46.2769 172.449 46.2608H171.022C169.804 46.2734 168.594 46.0584 167.455 45.6267V54.0288H162.271V23.5119H172.449ZM167.455 28.6483V37.7637C167.435 38.2043 167.503 38.6446 167.655 39.0585C167.808 39.4724 168.042 39.8515 168.343 40.1733C168.639 40.4865 168.999 40.7321 169.398 40.8935C169.798 41.0549 170.227 41.1283 170.658 41.1086H172.449C172.889 41.1262 173.327 41.0522 173.736 40.8912C174.146 40.7301 174.517 40.4857 174.827 40.1733C175.138 39.8559 175.381 39.4787 175.542 39.0645C175.703 38.6503 175.778 38.2077 175.762 37.7637V31.9615C175.782 31.5193 175.709 31.0779 175.548 30.6656C175.387 30.2533 175.141 29.8794 174.827 29.5677C174.513 29.2629 174.14 29.0243 173.731 28.8664C173.323 28.7084 172.887 28.6342 172.449 28.6483H167.455Z"
                fill={colors.primary}
              />
              <path d="M97.4819 37.0675V46.4207H91.9492V23.3389H97.4819V32.486H99.4952L106.645 23.4181H113.335L104.219 34.5152L113.731 46.3731H106.994L99.6537 37.0199L97.4819 37.0675Z" />
            </svg>
            <h2>Software para operação e manutenção de edificações.</h2>
          </StyledLoginMain>
          <CarouselSlide1BottomImage
            width="604"
            height="304"
            viewBox="0 0 604 304"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M412.046 406.946L412.046 265.453C412.067 250.865 408.243 236.529 400.958 223.89C393.674 211.251 383.186 200.756 370.552 193.462L247.939 122.715C235.323 115.432 221.013 111.597 206.445 111.597C191.878 111.597 177.567 115.432 164.952 122.716L42.3385 193.462C29.7049 200.756 19.2173 211.251 11.9326 223.89C4.64783 236.529 0.82352 250.865 0.845036 265.453L0.845049 406.946C0.859973 421.499 4.70156 435.792 11.9845 448.391C19.2673 460.991 29.7354 471.454 42.3386 478.73L164.952 549.476C177.568 556.76 191.878 560.595 206.445 560.595C221.013 560.594 235.323 556.76 247.939 549.476L370.552 477.693C383.005 470.505 393.376 460.206 400.65 447.804C407.924 435.402 411.851 421.323 412.046 406.946Z"
              fill="white"
              fillOpacity="0.08"
            />
            <path
              d="M499.1 355.304L499.099 213.811C499.121 199.223 495.297 184.887 488.012 172.248C480.727 159.609 470.24 149.113 457.606 141.819L334.993 71.0729C322.377 63.7893 308.066 59.9548 293.499 59.9548C278.932 59.9548 264.621 63.7893 252.006 71.0729L129.392 141.819C116.759 149.113 106.271 159.609 98.9863 172.248C91.7015 184.887 87.8772 199.223 87.8987 213.811L87.8988 355.304C87.9137 369.856 91.7553 384.149 99.0382 396.749C106.321 409.348 116.789 419.811 129.392 427.087L252.006 497.834C264.621 505.117 278.932 508.952 293.499 508.952C308.066 508.952 322.377 505.117 334.993 497.834L457.606 426.05C470.058 418.862 480.429 408.563 487.703 396.161C494.977 383.759 498.904 369.68 499.1 355.304Z"
              fill="white"
              fillOpacity="0.06"
            />
            <path
              d="M603.859 296.286L603.859 154.793C603.881 140.205 600.056 125.869 592.772 113.23C585.487 100.591 574.999 90.096 562.366 82.802L439.752 12.0556C427.137 4.77196 412.826 0.937456 398.259 0.937457C383.692 0.937458 369.381 4.77196 356.765 12.0556L234.152 82.8021C221.518 90.096 211.031 100.591 203.746 113.23C196.461 125.869 192.637 140.205 192.659 154.793L192.659 296.286C192.673 310.839 196.515 325.132 203.798 337.732C211.081 350.331 221.549 360.794 234.152 368.07L356.765 438.816C369.381 446.1 383.692 449.935 398.259 449.935C412.826 449.935 427.137 446.1 439.752 438.816L562.366 367.033C574.818 359.845 585.189 349.546 592.463 337.144C599.737 324.742 603.664 310.663 603.859 296.286Z"
              fill="white"
              fillOpacity="0.04"
            />
          </CarouselSlide1BottomImage>
        </StyledLoginWrapper>
      </StyledLoginIntro>
      <StyledLoginContainer>
        <StyledHeader>
          <span>
            Não é cliente?{' '}
            <a href="https://leankeep.com/#contato" target="_blank" rel="noopener noreferrer">
              Solicite uma proposta
            </a>
          </span>
        </StyledHeader>

        {children}

        <StyledFooter>
          <h6>Copyright 2024 Leankeep Software.</h6>
          <span>
            Conheça o{' '}
            <a href="https://leankeep.com/" target="_blank" rel="noopener noreferrer">
              leankeep.com
            </a>
          </span>
        </StyledFooter>
      </StyledLoginContainer>
    </StyledLogin>
  );
};

export { PreAuthPage };
