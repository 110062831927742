import { useTechniciansRoute } from 'pages/Geolocalize/queries';
import React from 'react';
import { ReactComponent as ArrowLeft } from 'assets/icons/arrow-small.svg';
import { ReactComponent as DownloadIcon } from 'assets/icons/download.svg';
import TechnicianCard from '../TechnicianCard';
import {
  StyledTechniciansRouteSubViewContainer,
  StyledSubViewHeaderContainer,
  StyledRouteCoveredContainer,
  StyledRouteCoveredHeaderContainer,
  StyledRouteReportButton,
  StyledRouteCovered,
  StyledArrowLeft,
} from './styled';
import { useGeolocalizeStore } from 'pages/Geolocalize/store';
import { TOGGLE_ROUTES_REPORT_MODAL, UPDATE_SIDEBAR_ROUTE_SUBVIEW_TECHNICIAN } from 'pages/Geolocalize/constants';
import { Loading } from 'components/Loading';
import RouteCoveredTimeline from './RouteCoveredTimeline';

const TechnicianRouteSubView = ({ technician, companyId }) => {
  // TODO add is loading indicator and error handle
  //eslint-disable-next-line
  const { status, data, error } = useTechniciansRoute(companyId, technician.id);
  const [, dispatch] = useGeolocalizeStore();
  if (data) {
    return (
      <StyledTechniciansRouteSubViewContainer>
        <StyledSubViewHeaderContainer>
          <StyledArrowLeft
            onClick={() =>
              dispatch({
                type: UPDATE_SIDEBAR_ROUTE_SUBVIEW_TECHNICIAN,
                payload: undefined,
              })
            }
          >
            <ArrowLeft />
          </StyledArrowLeft>
          <TechnicianCard technician={technician} />
        </StyledSubViewHeaderContainer>
        <StyledRouteCoveredContainer>
          <StyledRouteCoveredHeaderContainer>
            <StyledRouteCovered>Rota percorrida</StyledRouteCovered>
            <StyledRouteReportButton
              theme="primary"
              onClick={() => dispatch({ type: TOGGLE_ROUTES_REPORT_MODAL, payload: technician.id })}
            >
              <DownloadIcon />
              Relatório da rota
            </StyledRouteReportButton>
          </StyledRouteCoveredHeaderContainer>

          <RouteCoveredTimeline stops={data.stops} />
        </StyledRouteCoveredContainer>
      </StyledTechniciansRouteSubViewContainer>
    );
  } else {
    return <Loading />;
  }
};

export default TechnicianRouteSubView;
