import styled from 'styled-components';

import { inputStyleFactory } from 'components/Form/shared/inputStyleFactory';

import { DefaultSelect } from 'assets/styled/sheets/components/Forms';
import { colors } from 'assets/styled/tokens';

export const StyledSelectWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
`;

export const StyledSelect = styled.input`
  ${DefaultSelect};

  ${props => inputStyleFactory(props.size)};

  background-size: 8px;
  position: relative;
  display: inline-flex;
  width: 100%;
  cursor: pointer;
  height: 32px;
  color: ${colors.gray};
  border-color: ${props => props.error && colors.bittersweet};

  cursor: ${props => props.disabled && 'not-allowed'};

  &:active {
    pointer-events: ${props => props.disabled && 'none'};
  }

  &::placeholder {
    color: ${props => props.disabled && colors.mediumGray};
    font-size: 14px;
  }

  &:focus {
    border-color: ${props => props.disabled && colors.whiteGray};
  }
  &:required {
    border-color: ${props => props.required && colors.bittersweet};
  }
`;

export const StyledDropdownContent = styled.ul`
  position: absolute;
  background-color: ${colors.alabaster};
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 2;
  display: ${props => (props.isOpen ? 'block' : 'none')};
  width: 100%;
`;
export const StyledDropdownItems = styled.div`
  overflow: auto;
  max-height: 262px;
  &::-webkit-scrollbar {
    width: 20px;

    &:before {
      content: '';
      margin: 20px;
    }
  }

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    width: 15px;
  }

  &::-webkit-scrollbar-track {
    margin: 3px;
  }

  &::-webkit-scrollbar-thumb {
    background: ${colors.silver3};
    border: 5px solid ${colors.alabaster};
    border-radius: 100px;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: ${colors.silver3};
  }
`;

export const StyledDropdownItem = styled.li`
  padding: 1px 6px;

  &:hover {
    background: ${colors.desertStorm};
  }

  label {
    cursor: pointer;
  }

  p {
    font-size: 12px;
    line-height: 16px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 180px;
  }

  &:nth-child(1) {
    p {
      font-weight: 500;
      color: ${colors.tundora};
    }
  }
`;

export const StyledSearchBar = styled.div`
  position: relative;
  margin: 8px 0;
`;
