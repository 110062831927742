import React from 'react';
import PropTypes from 'prop-types';

import { StyledButton } from './styled';

const Button = props => {
  const { children, theme, size, type, ...rest } = props;

  return (
    <StyledButton type={type} theme={theme} size={size} {...rest}>
      {children}
    </StyledButton>
  );
};

Button.propTypes = {
  children: PropTypes.node.isRequired,
  theme: PropTypes.string,
  type: PropTypes.string,
  size: PropTypes.string,
};

Button.defaultProps = {
  theme: 'primary',
  type: 'button',
  size: 'medium',
};

export { Button };
