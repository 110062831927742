import styled, { keyframes } from 'styled-components';

import { ReactComponent as LoadingIcon } from 'assets/icons/loading-icon.svg';
export const TableContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
`;

export const ChamadoBox = styled.div`
  width: 100%;
  height: auto;
  padding: 24px 15px;
  border: 1px solid #e5e5e5;
  h1 {
    font-family: Roboto;
    font-size: 14px;
    font-weight: 700;
    line-height: 16px;
    text-align: left;
    color: #1e1e1e;
    margin: 5px 0;
  }
  div {
    font-family: Roboto;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    color: #888888;
  }

  background-color: ${props => (props.even ? '#FaFaFa' : '#FfFfFf')};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
`;

export const Status = styled.div`
  color: ${props => props.textColor};
  background-color: ${props => props.backgroundColor};
  border: 1px solid ${props => props.borderColor};
  border-radius: 4px;
  padding: 2px 6px;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 700 !important;
  line-height: 16px;
  text-align: left;

  svg {
    margin-right: 5px;
    > path {
      fill: #fff;
    }
  }
`;
export const Loading = styled.div`
  height: 310px;
  width: 100%px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  text-align: center;
  color: #888888;
`;
const rotate = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

// Criando o componente SVG estilizado
export const RotatingCircle = styled(LoadingIcon)`
  animation: ${rotate} 2s linear infinite;
  width: 56px !important;
  height: 56px !important;
`;
