import { DatePicker } from 'components/Form/subcomponents/DatePicker';
import { colors } from 'assets/styled/tokens';
import styled from 'styled-components';
import { StyledCleanInputPosition } from 'components/Form/shared/CleanInputIcon/styled';

export const StyledRouteCoveredTimeline = styled.div`
  display: flex;
  flex-direction: column;
`;
export const StyledRouteCoveredHorizontalLine = styled.div`
  position: absolute;
  z-index: 0;
  left: 9px;
  top: 0;
  width: 2px;
  height: 100%;

  background: ${colors.whiteGray};
`;
export const StyledTimelineDateSelectContainer = styled.div`
  padding-bottom: 24px;
  background: ${colors.white};
  z-index: 1;
`;
export const StyledTimelineDateSelectWrapper = styled.div`
  display: flex;
  border: 1px solid ${colors.whiteGray};
  border-radius: 4px;
`;

export const StyledDateRangePicker = styled(DatePicker)`
  input {
    background: ${props => (props.disabled ? colors.pampas : 'white')};
  }
  ${StyledCleanInputPosition} {
    display: none;
  }
`;
export const StyledTimelineDateSelectArrow = styled.div`
  cursor: pointer;
  svg {
    width: 5px;
    height: 10px;
  }
  padding: 11px 41px;
  &:first-child {
    border-right: 1px solid ${colors.whiteGray};
  }
  &:last-child {
    border-left: 1px solid ${colors.whiteGray};
  }
`;
export const StyledTimelineHeader = styled.div`
  font-size: 16px;
  line-height: 19px;
  font-weight: 400;
  padding-bottom: 24px;
  background: ${colors.white};
  z-index: 1;

  color: ${colors.gray};
`;
export const StyledTimelineStops = styled.div`
  position: relative;
`;
