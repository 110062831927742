import styled from 'styled-components';
import { colors } from 'assets/styled/tokens';

export const StyledPaginationListContainer = styled.ul`
  display: inline-flex;
  align-items: center;
`;
export const StyledPaginationNumberContainer = styled.li`
  display: flex;
  cursor: pointer;
  justify-content: center;
  width: 24px;
  border-radius: 4px;
  height: 24px;
  font-size: 13px;
  margin: 0 3px;
  color: ${props => (props.isCurrentPage ? 'black' : colors.gray)};
  background: ${props => (props.isCurrentPage ? '#f5f3f1' : 'transparent')};
  border: ${props => (props.isCurrentPage ? '1px solid #E5E5E5' : 'none')};
  &:hover {
    background: #eae8e5;
  }
`;
