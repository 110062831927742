export const formatDateTime = DateTime => {
  let date = new Date(DateTime);
  let day = date.getDate();
  let _day = day < 10 ? '0' + day : day;
  let month = date.getMonth() + 1;
  let _month = month < 10 ? '0' + month : month;
  let _year = date.getFullYear();
  let _date = _day + '/' + _month + '/' + _year;
  let hour = date.getHours();
  let _hours = hour < 10 ? '0' + hour : hour;
  let minutes = date.getMinutes();
  let _minutes = minutes < 10 ? '0' + minutes : minutes;
  let formattedDateTime = `${_hours}:${_minutes} de ${_date}`;
  return formattedDateTime;
};
