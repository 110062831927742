import React, { useState, useEffect, createContext } from 'react';

import { localStorageKeys } from 'constants/localStorage';

import { initialState } from './initialState';

const GlobalComponentsContext = createContext(initialState);
GlobalComponentsContext.displayName = 'GlobalComponentsContext';

const GlobalComponentsContextProvider = ({ children }) => {
  const [state, setState] = useState(initialState);

  // TODO should this be in localStorage?
  useEffect(() => {
    localStorage.setItem(localStorageKeys.globalComponentsContext, JSON.stringify(state));
  }, [state]);

  return (
    <GlobalComponentsContext.Provider
      value={{
        globalComponentsContext: state,
        setGlobalComponentsContext: setState,
      }}
    >
      {children}
    </GlobalComponentsContext.Provider>
  );
};

export { GlobalComponentsContext, GlobalComponentsContextProvider };
