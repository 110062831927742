import React, { useCallback, useEffect, useState } from 'react';
import { Controller, useForm, useWatch } from 'react-hook-form';

import {
  FrequencyInputsRow,
  FrequencyInterval,
  StyledEditActivityButton,
  StyledEditActivityFormModal,
  StyledEditActivityFormModalHeader,
  StyledCheckbox,
  StyledCloseEditActivityFormModal,
  StyledTimeInput,
  StyledEstimatedTimeInput,
  StyledFormGroup,
  StyledRadioGroup,
  StyledMeasurementsRadioGroup,
  StyledFormRow,
  StyledCategoryFormRow,
  StyledMeasurementsFormRow,
  StyledDatePicker,
  StyledStartDateFormGroup,
  StyledMeasurementsFormGroup,
  StyledMeasurementUnitRow,
  StyledOtherDaysConsideredContainer,
  StyledTextarea,
  StyledCategoryFormGroup,
  StyledTimeFormGroup,
  StyledPriorityFormGroup,
  StyledConformityRadioGroup,
  StyledDeleteMeasurementUnit,
  StyledMeasurementsContainer,
  StyledAddMeasurementUnitButton,
  StyledEditActivityForm,
} from '../NewGeneralPlanPage/styled';
import { Radio } from 'components/Form/subcomponents/Radio';
import { Input } from 'components/Form/subcomponents/Input';
import { Select } from 'components/Form/subcomponents/Select';
import MultiSelect from 'components/Form/subcomponents/MultiSelect';
import { GeneralPlansService } from 'services/reports/endpoints/GeneralPlansService';
import { FiltersService } from 'services/reports';
import { format } from 'date-fns';
import InformativeTooltip from 'components/InformativeTooltip';
import { convertMinsToHrsMins } from '../utils';
import { PaginatedSelect } from 'components/Form/subcomponents/PaginatedSelect';
import { colors } from 'assets/styled/tokens';
import { createGenericErrorToast } from 'utils/createToast';

function EditActivityFormModal({
  planId,
  editActivity,
  isEditActivityModalActive,
  setToastList,
  companyId,
  createToast,
  editActivityId,
  setEditActivityId,
}) {
  const {
    handleSubmit,
    register,
    control,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    defaultValues: {
      startTime: '',
      specificDay: '',
      description: '',
      startDate: '',
      estimatedTime: '',
    },
  });
  const [isEditActivityFormDirty, setIsEditActivityFormDirty] = useState(false);
  const [isMeasurementRequired, setIsMeasurementRequired] = useState(true);
  const [isConformityActive, setIsConformityActive] = useState(false);
  const [activeCategory, setActiveCategory] = useState(undefined);
  const [saturdayExcChecked, setSaturdayExcChecked] = useState(false);
  const [sundayExcChecked, setSundayExcChecked] = useState(false);
  const [holidaysExcChecked, setHolidaysExcChecked] = useState(false);
  const [measurements, setMeasurements] = useState([
    { valorMinimo: '', valorMaximo: '', valorPadrao: '', unidadeMedicao: '' },
  ]);
  const [isMeasurementActivity, setIsMeasurementActivity] = useState(false);
  const [onlyConsiderBusinessDays, setOnlyConsiderBusinessDays] = useState(true);
  const [activeFrequency, setActiveFrequency] = useState(null);
  // Trim trailing slashes
  const replaceBadInputs = val => {
    // Replace impossible inputs as they appear
    // val = val.replace(/[^\dh:]/, '');
    // val = val.replace(/^[^0-2]/, '');
    val = val.replace(/^([2-9])[4-9]/, '$1');
    // val = val.replace(/^\d[:h]/, '');
    val = val.replace(/^([01][0-9])[^:h]/, '$1');
    val = val.replace(/^(2[0-3])[^:h]/, '$1');
    val = val.replace(/^(\d{2}[:h])[^0-5]/, '$1');
    val = val.replace(/^(\d{2}h)./, '$1');
    val = val.replace(/^(\d{2}:[0-5])[^0-9]/, '$1');
    val = val.replace(/^(\d{2}:\d[0-9])./, '$1');
    return val;
  };
  function beforeMaskedStateChange({ nextState }) {
    let { value } = nextState;
    value = replaceBadInputs(value);
    return {
      ...nextState,
      value,
    };
  }

  const startTimeMask = [/^([0-2])/, /([0-9])/, ':', /[0-5]/, /[0-9]/];
  const timeMask = [/^([0-9])/, /([0-9])/, ':', /[0-5]/, /[0-9]/];

  const [fields, setFields] = useState({
    description: [],
    startDate: [],
    startTime: [],
    repeatEvery: [],
    frequencies: [
      { value: 'none', label: 'Não se repete' },
      { value: 'daily', label: 'Diária' },
      { value: 'weekly', label: 'Semanal' },
      { value: 'monthly', label: 'Mensal' },
      // TODO: Pending backend (verify?)
      // { value: "bimonthly", label: "Bimestral" },
      // { value: "biannual", label: "Semestral" },
      { value: 'annual', label: 'Anual' },
    ],
    specificDays: [
      { value: 'segunda', label: 'Segunda-feira' },
      { value: 'terca', label: 'Terça-feira' },
      { value: 'quarta', label: 'Quarta-feira' },
      { value: 'quinta', label: 'Quinta-feira' },
      { value: 'sexta', label: 'Sexta-feira' },
      { value: 'sabado', label: 'Sábado' },
      { value: 'domingo', label: 'Domingo' },
    ],
    // materials: [],
    categories: [],
    priorities: [
      { value: 1, label: 'A' },
      { value: 2, label: 'B' },
      { value: 3, label: 'C' },
    ],
  });

  const validateActivityRequiredFields = useCallback(() => {
    const description = getValues('description');
    const startDate = getValues('startDate');
    const category = getValues('category');
    const startTime = getValues('startTime');
    const frequency = getValues('frequency');
    const estimatedTime = getValues('estimatedTime');

    const requiredFields = [description, category, startDate, startTime, estimatedTime, frequency];

    const isMeasurementsInvalid = measurements.some(measurement => {
      return (
        measurement.unidadeMedicao === '' ||
        measurement.valorMinimo === '' ||
        measurement.valorMaximo === '' ||
        measurement.valorPadrao === ''
      );
    });

    const invalidFields = requiredFields.filter(element => !element);

    let invalidFieldsCount = invalidFields.length;

    let isSpecficDaysInvalid =
      activeFrequency === 'weekly' && (!getValues('specificDays') || getValues('specificDays').length === 0);
    if (isSpecficDaysInvalid) {
      invalidFieldsCount++;
    }
    if (isMeasurementActivity && isMeasurementsInvalid) {
      invalidFieldsCount++;
    }
    if (invalidFieldsCount === 1) {
      setToastList(() => [
        createToast({
          type: 'error',

          message: 'Um campo obrigatório não foi preenchido.',
        }),
      ]);

      return true;
    } else if (invalidFieldsCount > 1) {
      setToastList(() => [
        createToast({
          type: 'error',

          message: 'Há campos obrigatórios não preenchidos.',
        }),
      ]);
      return true;
    }
    return false;
  }, [getValues, measurements, activeFrequency, isMeasurementActivity, setToastList, createToast]);

  const periodicityToFrequency = period => {
    switch (period) {
      case 7:
        return 'daily';
      case 5:
        return 'weekly';
      case 3:
        return 'monthly';
      case 10:
        return 'bimonthly';
      case 12:
        return 'biannual';
      case 8:
        return 'annual';
      case 9:
        return 'none';
      default:
        return 'none';
    }
  };
  const onEditActivitySubmit = async (values, e) => {
    e.preventDefault();
    setIsEditActivityFormDirty(true);
    const hasError = validateActivityRequiredFields();
    if (hasError) {
      return;
    }
    let _values = {
      ...values,
      planId: planId,
      atividadeGeral: editActivityId,
      isMeasurementRequired: isMeasurementRequired,
      isConformityActive: isConformityActive,
      sundayExcChecked: sundayExcChecked,
      saturdayExcChecked: saturdayExcChecked,
      holidaysExcChecked: holidaysExcChecked,
      medicoes: measurements,
    };
    await editActivity(_values).finally(() => {
      handleCloseModal();
    });

    // editActivity(formatMeasurements(_values));
  };
  const handleDeleteMeasurement = useCallback(
    index => {
      const newMeasurements = measurements.filter((measurement, i) => i !== index);
      setMeasurements(newMeasurements);
    },
    [measurements],
  );
  const handleAddMeasurementUnit = () => {
    const newMeasurements = measurements;
    newMeasurements.push({
      unidadeMedicao: '',
      valorMinimo: '',
      valorMaximo: '',
      valorPadrao: '',
    });

    setMeasurements([...newMeasurements]);
  };

  const populateFieldsStrategy = async () => {
    if (companyId) {
      return await Promise.all([
        GeneralPlansService.getPlanActivity({
          companyId,
          activityId: editActivityId,
          planId: planId,
        }),
        FiltersService.getCategories({
          companyId,
        }),
      ])
        .then(values => {
          let activity = values[0].data;
          let categories = values[1].data.map(({ nome, categoria }) => ({
            label: nome,
            value: categoria,
          }));
          setActiveCategory(activity.categoria);

          let promises = activity.medicoes.map(unit => {
            return getMeasurementName(unit.unidadeMedicao).then(name => {
              return { ...unit, name };
            });
          });
          Promise.all(promises).then(res => {
            setMeasurements(
              res.map((measurement, i) => {
                return {
                  unidadeMedicao: activity.medicoes[i].unidadeMedicao,
                  valorMaximo: activity.medicoes[i].valorMaximo,
                  valorMinimo: activity.medicoes[i].valorMinimo,
                  valorPadrao: activity.medicoes[i].valorPadrao,
                  name: measurement.name,
                };
              }),
            );
          });
          const startDateTime = new Date(activity.inicio);

          let startHours = startDateTime.getHours();
          startHours = ('0' + startHours).slice(-2);
          let startMinutes = startDateTime.getMinutes();
          startMinutes = ('0' + startMinutes).slice(-2);
          let estimatedTimeMinutes = activity.tempoPrevisto;
          let startTime = `${startHours}:${startMinutes}`;
          let estimatedTime = convertMinsToHrsMins(estimatedTimeMinutes);
          setActiveFrequency(periodicityToFrequency(activity.periodicidade));
          setHolidaysExcChecked(!activity.feriadosExc);
          setSaturdayExcChecked(!activity.sabadoExc);
          setIsConformityActive(activity.conformidadeObrigatoriaBaixa);
          setIsMeasurementRequired(activity.medicaoObrigatoriaBaixa);
          setSundayExcChecked(!activity.domingoExc);
          setValue('description', activity.descricao);
          setValue('startDate', startDateTime);
          setValue('startTime', startTime);
          // setValue("specificDay", startTime);
          setValue('estimatedTime', estimatedTime);
          setValue('frequency', periodicityToFrequency(activity.periodicidade));
          setValue('repeatEvery', activity.multiplicador);
          setValue('category', activity.categoria);
          setValue('priority', activity.prioridade);

          let singleSpecificDays = ['segunda', 'terca', 'quarta', 'quinta', 'sexta', 'sabado', 'domingo'];
          let _specificDays = singleSpecificDays.filter(day => activity[day]);
          if (_specificDays.length > 1) {
            setValue('specificDays', _specificDays);
          } else if (_specificDays.length === 1) {
            setValue('specificDay', _specificDays[0]);
          }
          if (activity.feriadosExc && activity.sabadoExc && activity.domingoExc) {
            setOnlyConsiderBusinessDays(true);
          } else {
            setOnlyConsiderBusinessDays(false);
          }

          return {
            categories: categories,
            activity: {
              categoryId: activity.categoria,
              categoryName: activity.categoriaNome,
              monday: activity.segunda,
              tuesday: activity.terca,
              wednesday: activity.quarta,
              thursday: activity.quinta,
              friday: activity.sexta,
              saturday: activity.sabado,
              sunday: activity.domingo,
              sundayExcChecked: activity.domingoExc,
              weekendsExc: activity.feriadosExc,
              frequencyDescription: activity.frequenciaDescricao,
              frequencyName: activity.frequenciaNome,
              startDateTime: format(startDateTime, 'dd/MM/yyyy'),
              startTime: startTime,
              repeatEvery: activity.multiplicador,
              estimatedTime: estimatedTime,
              frequency: periodicityToFrequency(activity.periodicidade),
            },
          };
        })
        .catch(err => {
          console.error(err);
        });
    }
  };

  const populateFields = async () => {
    try {
      const fieldsData = await populateFieldsStrategy();
      setFields(prevState => ({
        ...prevState,
        ...fieldsData,
      }));
    } catch (err) {
      createGenericErrorToast(setToastList);
    }
  };
  const fetchData = async () => {
    // await cleanFields();
    await populateFields();
  };
  const getMeasurementName = async id => {
    return await FiltersService.getMeasurementUnit({ companyId, id }).then(res => {
      return res.data[0].nome;
    });
  };
  const handleCloseModal = () => {
    setEditActivityId(undefined);
  };

  useEffect(() => {
    if (editActivityId) {
      fetchData();
    }
    // setValue('description', )
    // eslint-disable-next-line
  }, [errors, editActivityId]);

  useEffect(() => {
    const MEASUREMENT_CATEGORY_KEY = 12;
    setMeasurements([
      {
        unidadeMedicao: '',
        valorMinimo: '',
        valorMaximo: '',
        valorPadrao: '',
      },
    ]);
    if (activeCategory === MEASUREMENT_CATEGORY_KEY) {
      setIsMeasurementActivity(true);
    } else {
      setIsMeasurementActivity(false);
    }

    // eslint-disable-next-line
  }, [activeCategory]);
  const watchSpecificDays = useWatch({
    name: ['specificDay', 'specificDays'],
    control,
  });
  useEffect(() => {
    const isSaturdaySelected =
      getValues('specificDays')?.some(day => day === 'sabado') || getValues('specificDay') === 'sabado';
    const isSundaySelected =
      getValues('specificDays')?.some(day => day === 'domingo') || getValues('specificDay') === 'domingo';

    if (isSaturdaySelected) {
      setOnlyConsiderBusinessDays(false);
      setSaturdayExcChecked(true);
    }
    if (isSundaySelected) {
      setOnlyConsiderBusinessDays(false);
      setSundayExcChecked(true);
    }

    // eslint-disable-next-line
  }, [watchSpecificDays, onlyConsiderBusinessDays]);

  const isRepeatEveryActive = activeFrequency !== 'bimonthly' && activeFrequency !== 'biannual';

  const isSpecificDaysActive =
    activeFrequency !== 'daily' &&
    activeFrequency !== 'monthly' &&
    activeFrequency !== 'bimonthly' &&
    activeFrequency !== 'biannual' &&
    activeFrequency !== 'annual';
  const isSpecificDayActive = activeFrequency !== 'daily' && !isSpecificDaysActive;
  return (
    <StyledEditActivityFormModal
      open={isEditActivityModalActive ? true : false}
      isActive={isEditActivityModalActive}
      closeModal={() => setEditActivityId(undefined)}
    >
      <StyledEditActivityForm onSubmit={handleSubmit(onEditActivitySubmit)}>
        <StyledEditActivityFormModalHeader>
          <h3>Editar atividade</h3>
          <StyledCloseEditActivityFormModal onClick={() => setEditActivityId(undefined)}>
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M1 1L15 15" stroke="#BBBBBB" strokeWidth="2" strokeLinecap="round" />
              <path d="M15 1L1 15" stroke="#BBBBBB" strokeWidth="2" strokeLinecap="round" />
            </svg>
          </StyledCloseEditActivityFormModal>
        </StyledEditActivityFormModalHeader>
        <StyledFormGroup label="Descrição da atividade" isRequired>
          <Controller
            render={props => (
              <StyledTextarea
                type="text"
                {...props}
                isRequired
                autoComplete="off"
                name="description"
                maxLength="500"
                defaultValue={getValues('description')}
                size="small"
              />
            )}
            name="description"
            control={control}
          />
        </StyledFormGroup>
        <StyledCategoryFormRow>
          <StyledCategoryFormGroup label="Categoria" isRequired>
            <Controller
              render={props => (
                <Select
                  {...props}
                  size="small"
                  placeholder="Seleção obrigatória"
                  options={fields?.categories}
                  error={isEditActivityFormDirty && !getValues('category')}
                  onChange={e => {
                    props.onChange(e);
                    setActiveCategory(e);
                  }}
                  defaultValue={fields?.activity?.categoriaNome}
                  value={getValues('category')}
                  errormessage="Campo obrigatório"
                  name="category"
                  isRequired
                />
              )}
              defaultValue={fields?.activity?.categoriaNome}
              name="category"
              control={control}
            />
          </StyledCategoryFormGroup>

          <StyledStartDateFormGroup label="Data de início" isRequired>
            <Controller
              render={props => (
                <StyledDatePicker
                  {...props}
                  name="startDate"
                  size="small"
                  error={isEditActivityFormDirty && getValues('startDate').length === 0}
                  value={getValues('startDate')}
                  errormessage="Campo obrigatório"
                  {...register('startDate')}
                />
              )}
              name="startDate"
              control={control}
            />
          </StyledStartDateFormGroup>
          <StyledPriorityFormGroup isRequired label="Prioridade">
            <Controller
              render={props => (
                <Select
                  {...props}
                  size="small"
                  isSearchDisabled
                  isRequired
                  placeholder="Selecione"
                  value={getValues('priority')}
                  options={fields?.priorities}
                />
              )}
              name="priority"
              control={control}
            />
          </StyledPriorityFormGroup>

          <Controller
            render={props => (
              <StyledTimeFormGroup
                error={isEditActivityFormDirty && getValues('startTime') === ''}
                isRequired
                label="Hora de início"
              >
                <StyledTimeInput
                  {...props}
                  type="text"
                  mask={startTimeMask}
                  beforeMaskedStateChange={beforeMaskedStateChange}
                  isRequired
                  autoComplete="off"
                  error={isEditActivityFormDirty && getValues('startTime') === ''}
                  errormessage="Campo obrigatório"
                  name="startTime"
                  size="small"
                  value={getValues('startTime')}
                  {...register('startTime')}
                />
              </StyledTimeFormGroup>
            )}
            name="startTime"
            control={control}
          />

          <Controller
            render={props => (
              <StyledTimeFormGroup
                error={isEditActivityFormDirty && getValues('estimatedTime') === ''}
                isRequired
                label="Tempo previsto"
              >
                <StyledEstimatedTimeInput
                  {...props}
                  type="text"
                  mask={timeMask}
                  error={isEditActivityFormDirty && getValues('estimatedTime') === ''}
                  errormessage="Campo obrigatório"
                  isRequired
                  autoComplete="off"
                  name="estimatedTime"
                  size="small"
                  value={getValues('estimatedTime')}
                  defaultValue={fields?.activity?.estimatedTime}
                  {...register('estimatedTime')}
                />
              </StyledTimeFormGroup>
            )}
            name="estimatedTime"
            control={control}
          />
        </StyledCategoryFormRow>

        <StyledMeasurementsFormRow isActive={isMeasurementActivity}>
          <StyledMeasurementsRadioGroup isActive={isMeasurementActivity} label="Medições">
            <span>Tornar medição obrigatória para a baixa da atividade?</span>

            <Radio
              text="Sim"
              name="isMeasurementRequired"
              value={true}
              onChange={() => {}}
              onClick={() => setIsMeasurementRequired(true)}
              checked={isMeasurementRequired}
            />

            <Radio
              text="Não"
              type="radio"
              name="isMeasurementRequired"
              value={false}
              onChange={() => {}}
              onClick={() => setIsMeasurementRequired(false)}
              checked={!isMeasurementRequired}
            />
          </StyledMeasurementsRadioGroup>

          <StyledMeasurementsContainer isActive={isMeasurementActivity}>
            {measurements.map((measurementUnit, i) => (
              <StyledMeasurementUnitRow isFirst={i === 0} isLast={i === measurements.length - 1} key={i}>
                <StyledMeasurementsFormGroup isRequired label="Unidade de medida">
                  <Controller
                    render={props => (
                      <PaginatedSelect
                        size="small"
                        isRequired
                        error={isEditActivityFormDirty && !props.value}
                        errormessage="Campo obrigatório"
                        placeholder="Seleção obrigatória"
                        headers={{ EmpresaId: companyId }}
                        name={`measurementUnit${i}`}
                        value={measurements[i].unidadeMedicao}
                        defaultValueLabel={measurements[i].name}
                        // params={{ planoGeral: 3 }}
                        onChange={value => {
                          let newMeasurements = measurements;
                          let newValue = value ? parseInt(value) : '';
                          newMeasurements[i].unidadeMedicao = newValue;
                          props.onChange(newValue);
                          setMeasurements(newMeasurements);
                        }}
                        adapter={data => {
                          return data.map(units => {
                            return {
                              label: units.nome,
                              value: units.unidadeMedicao,
                            };
                          });
                        }}
                        url="/v1/UnidadesMedicoes"
                        {...register(`measurementUnit${i}`)}
                      />
                    )}
                    name={`measurementUnit${i}`}
                    control={control}
                  />
                </StyledMeasurementsFormGroup>
                <StyledMeasurementsFormGroup isRequired label="Mínimo">
                  <Controller
                    render={props => (
                      <Input
                        {...props}
                        type="text"
                        error={isEditActivityFormDirty && measurements[i].valorMinimo === ''}
                        errormessage="Campo obrigatório"
                        value={isMeasurementActivity ? measurements[i].valorMinimo : ''}
                        autoComplete="off"
                        name={`measurementMin${i}`}
                        size="small"
                        placeholder=""
                        onChange={e => {
                          const newMeasurements = measurements;
                          const parsedValue = parseInt(e.target.value);
                          const isInteger = Number.isInteger(parsedValue);
                          const newValue = isInteger ? parsedValue : '';
                          newMeasurements[i].valorMinimo = newValue;
                          isInteger ? props.onChange(newValue) : props.onChange('');
                          setMeasurements(newMeasurements);
                        }}
                        {...register(`measurementMin${i}`)}
                      />
                    )}
                    name={`measurementMin${i}`}
                    control={control}
                  />
                </StyledMeasurementsFormGroup>
                <StyledMeasurementsFormGroup isRequired label="Máximo">
                  <Controller
                    render={props => (
                      <Input
                        {...props}
                        type="text"
                        value={isMeasurementActivity ? measurements[i].valorMaximo : ''}
                        error={isEditActivityFormDirty && measurements[i].valorMaximo === ''}
                        onChange={e => {
                          const newMeasurements = measurements;
                          const parsedValue = parseInt(e.target.value);
                          const isInteger = Number.isInteger(parsedValue);
                          const newValue = isInteger ? parsedValue : '';
                          newMeasurements[i].valorMaximo = newValue;
                          isInteger ? props.onChange(newValue) : props.onChange('');
                          setMeasurements(newMeasurements);
                        }}
                        errormessage="Campo obrigatório"
                        autoComplete="off"
                        name={`measurementMax${i}`}
                        size="small"
                        placeholder=""
                        {...register(`measurementMax${i}`)}
                      />
                    )}
                    name={'measurementMax' + i}
                    control={control}
                  />
                </StyledMeasurementsFormGroup>
                <StyledMeasurementsFormGroup isRequired label="Padrão">
                  <Controller
                    render={props => (
                      <Input
                        {...props}
                        type="text"
                        error={isEditActivityFormDirty && measurements[i].valorPadrao === ''}
                        value={isMeasurementActivity ? measurements[i].valorPadrao : ''}
                        onChange={e => {
                          const newMeasurements = measurements;
                          const parsedValue = parseInt(e.target.value);
                          const isInteger = Number.isInteger(parsedValue);
                          const newValue = isInteger ? parsedValue : '';
                          newMeasurements[i].valorPadrao = newValue;
                          isInteger ? props.onChange(newValue) : props.onChange('');
                          setMeasurements(newMeasurements);
                        }}
                        errormessage="Campo obrigatório"
                        autoComplete="off"
                        name={`measurementAverage${i}`}
                        size="small"
                        placeholder=""
                        {...register(`measurementAverage${i}`)}
                      />
                    )}
                    name={'measurementAverage' + i}
                    control={control}
                  />
                </StyledMeasurementsFormGroup>
                {measurements.length > 1 && (
                  <StyledDeleteMeasurementUnit onClick={() => handleDeleteMeasurement(i)}>
                    X
                  </StyledDeleteMeasurementUnit>
                )}
              </StyledMeasurementUnitRow>
            ))}
          </StyledMeasurementsContainer>
          {isMeasurementActivity && (
            <StyledAddMeasurementUnitButton type="button" onClick={() => handleAddMeasurementUnit()}>
              <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1 6H11" stroke={colors.primary} strokeWidth="2" strokeLinecap="round" />
                <path d="M6 1V11" stroke={colors.primary} strokeWidth="2" strokeLinecap="round" />
              </svg>
              <span>Adicionar mais uma medição</span>
            </StyledAddMeasurementUnitButton>
          )}
        </StyledMeasurementsFormRow>
        <StyledFormRow>
          <FrequencyInputsRow>
            <StyledFormGroup label="Frequência" isRequired>
              <Controller
                render={props => (
                  <Select
                    {...props}
                    size="small"
                    name="frequency"
                    onChange={e => {
                      props.onChange(e);
                      setActiveFrequency(e);
                    }}
                    defaultValue={fields?.activity?.frequency}
                    value={getValues('frequency')}
                    isRequired
                    error={isEditActivityFormDirty && !getValues('frequency')}
                    errormessage="Campo obrigatório"
                    placeholder="Seleção obrigatória"
                    options={fields?.frequencies}
                  />
                )}
                name="frequency"
                defaultValue={fields?.activity?.frequency}
                control={control}
              />
            </StyledFormGroup>
            {activeFrequency && activeFrequency !== 'none' && (
              <>
                {isRepeatEveryActive && (
                  <StyledFormGroup label="Repete a cada">
                    <Controller
                      render={props => (
                        <Input
                          {...props}
                          name="repeatEvery"
                          value={getValues('repeatEvery')}
                          size="small"
                          placeholder="Seleção obrigatória"
                          onChange={e => {
                            props.onChange(e);
                          }}
                          ref={register('repeatEvery')}
                        />
                      )}
                      name="repeatEvery"
                      control={control}
                    />
                  </StyledFormGroup>
                )}

                <FrequencyInterval isEmpty={activeFrequency === 'bimonthly' || activeFrequency === 'biannual'}>
                  {activeFrequency === 'daily'
                    ? 'Dia(s)'
                    : activeFrequency === 'weekly'
                    ? 'Semana(s)'
                    : activeFrequency === 'monthly'
                    ? 'Mês(es)'
                    : activeFrequency === 'annual'
                    ? 'Ano(s)'
                    : ''}
                </FrequencyInterval>
                <StyledFormGroup isActive={isSpecificDayActive} label="Dia específico">
                  <Controller
                    render={props => (
                      <Select {...props} size="small" placeholder="Seleção opcional" options={fields?.specificDays} />
                    )}
                    name="specificDay"
                    control={control}
                  />
                </StyledFormGroup>

                <StyledFormGroup isActive={isSpecificDaysActive} isRequired label="Dia(s) específico(s)">
                  <Controller
                    render={props => (
                      <MultiSelect
                        {...props}
                        size="small"
                        error={isEditActivityFormDirty && !getValues('specificDays')}
                        errormessage="Campo obrigatório"
                        isRequired
                        placeholder="Seleção obrigatória"
                        name="specificDays"
                        value={getValues('specificDays')}
                        options={fields?.specificDays}
                        {...register('specificDays')}
                      />
                    )}
                    name="specificDays"
                    control={control}
                  />
                </StyledFormGroup>
              </>
            )}
          </FrequencyInputsRow>
        </StyledFormRow>
        <StyledFormRow>
          <StyledRadioGroup label="Considerar apenas dias úteis?">
            <Radio
              text="Sim"
              name="onlyConsiderBusinessDays"
              value={true}
              onChange={() => {
                setSaturdayExcChecked(false);
                setHolidaysExcChecked(false);
                setSundayExcChecked(false);
                setOnlyConsiderBusinessDays(true);
              }}
              checked={onlyConsiderBusinessDays === true}
            />
            <Radio
              text="Não"
              type="radio"
              name="onlyConsiderBusinessDays"
              value={false}
              checked={onlyConsiderBusinessDays === false}
              onChange={() => setOnlyConsiderBusinessDays(false)}
            />
            <StyledOtherDaysConsideredContainer isActive={onlyConsiderBusinessDays === false}>
              <svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1 1L6 6L1 11" stroke="#BBBBBB" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
              </svg>

              <StyledCheckbox
                name="saturdayExcChecked"
                checked={saturdayExcChecked}
                onChange={() => {
                  setSaturdayExcChecked(!saturdayExcChecked);
                }}
                text="Sábados"
              />

              <StyledCheckbox
                text="Domingos"
                name="sundayExcChecked"
                checked={sundayExcChecked}
                onChange={() => {
                  setSundayExcChecked(!sundayExcChecked);
                }}
              />

              <StyledCheckbox
                text="Feriados"
                name="holidaysExcChecked"
                checked={holidaysExcChecked}
                onChange={() => {
                  setHolidaysExcChecked(!holidaysExcChecked);
                }}
              />
            </StyledOtherDaysConsideredContainer>
          </StyledRadioGroup>
        </StyledFormRow>
        <StyledFormRow>
          <StyledConformityRadioGroup label="Habilitar conformidade na baixa da atividade?">
            <InformativeTooltip
              message={`Ao marcar "Sim" o campo "Conformidade" será exibido ao executor na baixa da atividade.`}
            />
            <Radio text="Sim" value={true} checked={isConformityActive} onChange={() => setIsConformityActive(true)} />
            <Radio
              text="Não"
              type="radio"
              value={false}
              onChange={() => setIsConformityActive(false)}
              checked={!isConformityActive}
            />
          </StyledConformityRadioGroup>
        </StyledFormRow>

        {/* <StyledFormGroup label="Material">
        <Controller
          render={(props) => (
            <Select
              {...props}
              {...register('material')}
              size="small"
              placeholder="Selecionar setor"
              options={fields?.materials}
            />
          )}
          name="material"
          control={control}
        />
      </StyledFormGroup> */}
        <StyledEditActivityButton type="submit">Salvar alterações</StyledEditActivityButton>
      </StyledEditActivityForm>
    </StyledEditActivityFormModal>
  );
}

EditActivityFormModal.propTypes = {};

export default EditActivityFormModal;
