import * as React from 'react';
import { Loading, LoadingInner } from './styled';

export const LkpLoading = (): React.ReactElement => (
  <Loading>
    <LoadingInner />
    <LoadingInner />
    <LoadingInner />
    <LoadingInner />
  </Loading>
);
