import { colors } from 'assets/styled/tokens';
import { primary } from 'components/Button/ButtonThemeFactory/themes/outline';
import styled, { css } from 'styled-components';
import { textCss } from 'utils/helpers/css';
import { StyledHorizontalLine } from 'pages/Geolocalize/styled';
import { ReactComponent as CloseIcon } from '../../../assets/close-popup-icon.svg';

export const BaseRoundMarker = css``;

export const StyledMarkerButton = styled.button`
  cursor: pointer;
  padding: 0;
  position: relative;
`;

export const StyledMapModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  z-index: 10;
  cursor: initial;
`;

export const StyledTechnicianHorizontalLine = styled(StyledHorizontalLine)`
  width: 100vw;
  margin-top: 10px;
  margin-bottom: 12px;
  margin-left: -100%;
`;

export const StyledHorizontalStackedAvatars = styled.img`
  border-radius: 100%;
  overflow: hidden;
  position: relative;
  border: ${({ isOffline }) => (isOffline ? ` 1px solid ${colors.mediumGray}` : ` 1px solid #F3D5B5`)};

  &:nth-child(1) {
    z-index: 3;
  }
  &:nth-child(2) {
    z-index: 2;
  }
  &:nth-child(3) {
    z-index: 1;
  }
`;

export const StyledHorizontalStackedInitials = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 23px;
  height: 23px;
  border-radius: 100%;
  background: ${({ isOffline }) => (isOffline ? colors.mediumDarkGray : colors.darkPrimary)};
  border: ${({ isOffline }) => (isOffline ? ` 1px solid ${colors.mediumGray}` : ` 1px solid #eac18c`)};
  color: ${colors.white};
  font-size: 12px;
  font-weight: 700;

  &:nth-child(1) {
    z-index: 3;
  }
  &:nth-child(2) {
    z-index: 2;
  }
  &:nth-child(3) {
    z-index: 1;
  }
`;

export const StyledCloseIcon = styled(CloseIcon)`
  cursor: pointer;
`;
export const StyledMapModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 4px;
  padding: 0 4px;
  * {
    color: white;
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
  }
`;

export const StyledTechnicianPins = styled.div`
  display: inline-flex;
  flex-wrap: wrap;
  max-width: 48px;
  height: ${({ isSingleTechnicianPin }) => (isSingleTechnicianPin ? '32px' : '43px')};
  margin-top: ${({ isSingleTechnicianPin }) => (isSingleTechnicianPin ? '0' : '-1px')};
  align-items: center;
  justify-content: center;

  img {
    width: ${({ isSingleTechnicianPin }) => (isSingleTechnicianPin ? '32px' : '24px')};
    height: ${({ isSingleTechnicianPin }) => (isSingleTechnicianPin ? '32px' : '24px')};

    /* margin-right: ${({ isSingleTechnicianPin }) => (isSingleTechnicianPin ? `0` : `-5px`)}; */
  }
  img:first-child {
    /* margin-left: ${({ isSingleTechnicianPin }) => (isSingleTechnicianPin ? `0` : `-6px`)}; */
  }
`;
export const StyledSubModalTechnicianPinImages = styled(StyledTechnicianPins)`
  max-width: 100%;
  margin: 0 12px 0 8px;
  div {
    border: none;
    margin-top: 0;
  }
  img {
    border: 1px solid ${colors.cream};
  }
  img:first-child {
    margin-left: 0;
  }
`;
export const StyledViewRouteModalButton = styled.button`
  ${primary}
  ${textCss}
  border: 1px solid ${colors.primary};
  text-align: center;
  box-sizing: border-box;
  width: 73px;
  height: 24px;
  border-radius: 4px;
`;
