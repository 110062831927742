import { Popup as UrbicaPopup } from '@urbica/react-map-gl';
import React, { useCallback, useRef, useState } from 'react';
import { useOutsideClick } from 'utils/hooks/useOutsideClick';

import MapMarker from '../MapMarker';
import { StyledCloseIcon, StyledMapModalHeader, StyledMapModalWrapper, StyledMarkerButton } from './styled';

export default function MapModalMarker({
  onToggle = () => {},
  className = '',
  pinComponent,
  modalTitle,
  type,
  marker,
  modalComponent,
}) {
  const ref = useRef();
  const [isModalActive, setIsModalActive] = useState(false);
  const handleToggleModal = () => {
    onToggle();
    setIsModalActive(prevState => !prevState);
  };
  const Popup = useCallback(
    props => {
      return <UrbicaPopup {...props} type={type} />;
    },
    [type],
  );
  useOutsideClick(ref, () => handleToggleModal());
  return (
    <>
      {!isModalActive && (
        <MapMarker latitude={marker.latitude} longitude={marker.longitude} offsetTop={-5} className={className}>
          <StyledMarkerButton onClick={handleToggleModal}>{pinComponent}</StyledMarkerButton>
        </MapMarker>
      )}
      {isModalActive && type && (
        <Popup
          latitude={marker.latitude}
          longitude={marker.longitude}
          maxWidth="322px"
          closeOnClick={false}
          className={type + `-marker-popup`}
          offset={[0, 30, 0, 0]}
          closeButton={false}
        >
          <StyledMapModalWrapper ref={ref}>
            <StyledMapModalHeader>
              <div>{modalTitle}</div>
              <StyledCloseIcon onClick={handleToggleModal} />
            </StyledMapModalHeader>
            {modalComponent}
          </StyledMapModalWrapper>
        </Popup>
      )}
    </>
  );
}
