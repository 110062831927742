const createToast = ({
  type,
  message,
  btnTitle,
  onClick,
}: {
  type: string;
  message: string;
  btnTitle?: string;
  onClick?: () => void;
}) => {
  return {
    id: Math.floor(Math.random() * 1000),
    type,
    message,
    btnTitle,
    onClick,
  };
};

export { createToast };
