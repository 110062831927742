import React, { memo } from 'react';
import PropTypes from 'prop-types';

import { StyledCheckbox, StyledInput } from './styled';

const Checkbox = props => {
  const { isChecked, isCheckboxAll, isAllSelected, label, value, onChange } = props;

  return (
    <StyledCheckbox isCheckboxAll={isCheckboxAll} isAllSelected={isAllSelected}>
      <StyledInput type="checkbox" checked={isChecked} onChange={onChange} value={value} />
      <span></span>
      <p>{label}</p>
    </StyledCheckbox>
  );
};

Checkbox.propTypes = {
  isChecked: PropTypes.bool,
  isCheckboxAll: PropTypes.bool,
  value: PropTypes.any,
  label: PropTypes.any,
};

export default memo(Checkbox);
