export function groupBy<T>(arr: any[], keyGetter): Array<{ name: string; data: Array<T> }> {
  const map = new Map();

  arr.forEach(item => {
    const key = keyGetter(item);
    const collection = map.get(key);

    if (!collection) {
      map.set(key, [item]);
    } else {
      collection.push(item);
    }
  });

  return Array.from(map, ([name, data]) => ({ name, data }));
}
