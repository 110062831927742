import React from 'react';
import { SpinnerContainer } from './styled';
import { ReactComponent as SpinnerIcon } from 'assets/icons/icon-spinner.svg';

export const Spinner = () => {
  return (
    <SpinnerContainer>
      <SpinnerIcon />
    </SpinnerContainer>
  );
};
