import { Container, StyledErrorMessage, StyledErrorMessageSub, StyledLinkIn, StyledImg, ButtonHome } from './styled';
import conectionErrorIcon from 'assets/icons/conection-error-icon.svg';

export const PermissionError = () => {
  return (
    <Container>
      <StyledImg src={conectionErrorIcon} />
      <StyledErrorMessage>Desculpe, parece que você não tem permissão para acessar essa tela.</StyledErrorMessage>
      <StyledErrorMessageSub>
        Se precisar de ajuda ou acredita que isso seja um erro, fique à vontade para{' '}
        <a href="https://leankeep.movidesk.com/kb/form/2343/fale-conosco" target="_blank">
          entrar em contato conosco
        </a>
      </StyledErrorMessageSub>
      <ButtonHome>
        <StyledLinkIn to="/home">voltar à homepage</StyledLinkIn>
      </ButtonHome>
    </Container>
  );
};
