import { CLEAR_UNIT_MARKERS_FILTERS } from './constants';

const getIsEveryTechnicianOffline = marker => marker.technicians.every(technician => technician.status === 'offline');
const getStopsCoordinates = () => {};
const getNameInitials = name => {
  const fullName = name.replace('.', ' ').split(' ');
  let initials = '';

  if (fullName.length > 1) {
    initials = fullName.shift().charAt(0) + fullName.pop().charAt(0);
  } else {
    initials = fullName.shift().charAt(0);
  }

  return initials.toUpperCase();
};

const getFilteredTechnicians = ({ technicians, searchTerm }) => {
  if (searchTerm === '') {
    return technicians;
  } else {
    const searchQuery = searchTerm.toLowerCase();
    return technicians.filter(technician => {
      const name = technician?.name?.toLowerCase();
      return name.indexOf(searchQuery) !== -1;
    });
  }
};

const getFilteredUnits = ({ units, searchTerm }) => {
  if (searchTerm === '') {
    return units;
  } else {
    const searchQuery = searchTerm.toLowerCase();
    return units.filter(item => {
      const name = item?.unit?.name?.toLowerCase();
      return name.indexOf(searchQuery) !== -1;
    });
  }
};

const getFilteredTeams = ({ teams, searchTerm }) => {
  if (searchTerm === '') {
    return teams;
  } else {
    const searchQuery = searchTerm.toLowerCase();
    return teams.filter(team => {
      const name = team?.name?.normalize('NFD').toLowerCase();
      return name.indexOf(searchQuery) !== -1;
    });
  }
};

const getFilteredUnitMarkers = ({ unitMarkers, unitMarkersFilters }) => {
  let filteredMarkers = [];
  const { isUnitsWithoutOccurrencesHidden, unitsWithOccurrencesSubfilter, unitSearchTerm } = unitMarkersFilters;
  const showOnlyUnitsWithOccurrences = isUnitsWithoutOccurrencesHidden && unitsWithOccurrencesSubfilter === '';
  const hasOccurrencesSubfilterSelected = unitsWithOccurrencesSubfilter && unitsWithOccurrencesSubfilter !== '';
  const showUnitsWithAnalysedOccurrences = hasOccurrencesSubfilterSelected && unitsWithOccurrencesSubfilter === 1;
  const showUnitsWithNotAnalysedOccurrences = hasOccurrencesSubfilterSelected && unitsWithOccurrencesSubfilter === 2;

  if (showOnlyUnitsWithOccurrences) {
    filteredMarkers = unitMarkers.data.filter(
      unitMarker =>
        unitMarker?.unit?.occurrences?.length > 0 &&
        (unitMarker?.unit?.occurrences[0].analysed > 0 || unitMarker?.unit?.occurrences[0].notAnalysed > 0),
    );
  } else if (showUnitsWithAnalysedOccurrences) {
    filteredMarkers = unitMarkers.data.filter(unitMarker => {
      return (
        unitMarker?.unit?.occurrences &&
        unitMarker?.unit?.occurrences?.length > 0 &&
        unitMarker?.unit?.occurrences[0]?.analysed &&
        unitMarker?.unit?.occurrences[0]?.analysed > 0
      );
    });
  } else if (showUnitsWithNotAnalysedOccurrences) {
    filteredMarkers = unitMarkers.data.filter(unitMarker => {
      return (
        unitMarker?.unit?.occurrences &&
        unitMarker?.unit?.occurrences?.length > 0 &&
        unitMarker?.unit?.occurrences[0]?.notAnalysed &&
        unitMarker?.unit?.occurrences[0]?.notAnalysed > 0
      );
    });
  } else {
    filteredMarkers = unitMarkers.data;
  }

  if (unitSearchTerm) {
    const searchQuery = unitMarkersFilters.unitSearchTerm.toLowerCase();
    filteredMarkers = filteredMarkers.filter(unitMarker => {
      const unitName = unitMarker?.unit?.name?.toLowerCase();
      const unitGroupName = unitMarker?.unit?.unitGroupName?.toLowerCase();
      const name = `${unitName} ${unitGroupName}`;
      return name.indexOf(searchQuery) !== -1;
    });
  }

  return filteredMarkers;
};

const handleUnitsFiltersClear = (e, dispatch) => {
  // TODO: Add functionality to automatically  e.preventDefault() to inputs (?)
  e.preventDefault();
  dispatch({ type: CLEAR_UNIT_MARKERS_FILTERS });
};
export {
  getIsEveryTechnicianOffline,
  getStopsCoordinates,
  getNameInitials,
  getFilteredTechnicians,
  getFilteredUnits,
  getFilteredUnitMarkers,
  getFilteredTeams,
  handleUnitsFiltersClear,
};
