import styled from 'styled-components';
import { medias, colors, fonts } from 'assets/styled/tokens';
import { FormGroup } from 'components/Form/subcomponents/FormGroup';
import { Checkbox } from 'components/Form/subcomponents/Checkbox';
import { Tooltip } from 'components/Tooltip';
export const LoginContainer = styled.div`
  position: absolute;
  left: calc(33% - 109px);
  bottom: calc(66% - 422px);
  width: 327px;
  height: 422px;
  box-shadow: 0px 4px 24px 0px #00000026;
  background-color: #ffffff;
  border-radius: 12px;
  padding: 20px 8px;
  @media (min-width: ${medias.desktopSm}) {
    right: calc(40% - 472px);
    width: 472px;
    left: auto;
    bottom: auto;
    padding: 20px;
  }
`;
export const TitleContainer = styled.div`
  margin: 10px 0;
`;
export const Title = styled.h1`
  font-family: Blinker;
  font-size: 32px;
  font-weight: 700;
  line-height: 38px;
  letter-spacing: 1px;
  text-align: left;
  color: #f38a00;
  margin-bottom: 5px;
`;

export const StyledForm = styled.form`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 20px;
`;
export const StyledFormGroup = styled(FormGroup)`
  margin-bottom: 16px;
`;
export const StyledFormLogin = styled.div``;
export const StyledForgetPasswordLink = styled.div`
  display: flex;
  justify-content: flex-end;
  position: absolute;
  right: 0;
  top: -2px;

  a {
    font-weight: 500;
    font-size: 13px;
    line-height: 15px;
    text-decoration-line: underline;
    color: ${colors.primary};
  }
`;
export const StyledFormFooter = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 5px;
  width: 100%;
  align-items: flex-start;
  #loginButton {
    max-width: 127px;
    width: 20vw;
  }
  button {
    //margin-left: auto;
    width: 127px;
    height: 40px;
    margin-top: 25px;
  }
  > div {
    display: flex;
  }
`;
export const StyledStayConnectedCheckbox = styled(Checkbox)`
  width: auto;
  cursor: pointer;
  margin-right: 5px;
`;
export const StyledTooltip = styled(Tooltip)`
  margin-right: auto;
`;
export const StyledModalBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  padding-bottom: 21px;

  img {
    max-width: 80px;
    margin-bottom: 32px;
  }

  h6 {
    font-family: ${fonts.family.title};
    color: ${colors.bittersweet};
    font-weight: 700;
    font-size: 21px;
    line-height: 25.2px;
    margin-bottom: 32px;
    text-align: center;
  }

  p,
  span {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 18.75px;
    margin-top: 40px;
    text-align: center;
    color: ${colors.gray};

    a {
      color: ${colors.primary};
      font-weight: bold;
    }
  }

  button:first-child {
    margin-right: 16px;
    background-color: ${colors.white};
    color: ${colors.carbon};
    border: 1px solid ${colors.whiteGray};
  }

  @media screen and (min-width: ${medias.tablet}) {
    padding-right: 35px;
    padding-left: 35px;
  }
`;
export const StyledModalButton = styled.div`
  display: flex;
`;
export const StyledP = styled.div`
  margin-bottom: 40px;
  margin-top: -40px;
`;
