import styled from 'styled-components';
import { Button } from 'components/Button';
import { GridColumn } from 'components';
import { FormGroup } from 'components/Form/subcomponents/FormGroup';
export const Header = styled.div`
  display: flex;
  flex-direction: row;
  min-width: 100%;
  align-items: center;
  margin-bottom: 34px;
`;

export const HeaderName = styled.h1`
  font-size: 32px;
  font-family: Blinker, sans-serif;
  font-weight: 700;
`;
export const PageContainer = styled.div`
  background-color: #fff;
  min-height: 200vh;
  width: 100%;
  box-shadow: 0px 1px 8px 0px #0000001a;
  border-radius: 8px;
`;

export const CategoryHeader = styled.div`
  padding: 15px 15px 15px 15px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #e5e5e5;
`;
export const CategoryHeaderTitle = styled.h3`
  font-family: Blinker;
  font-size: 24px;
  font-weight: 700;
  line-height: 28px;
  text-align: left;
  color: #f38a00;
`;
export const SaveButton = styled(Button)`
  background: #f38a00;
  color: #ffffff;
  font-family: Roboto;
  font-size: 12px;
  font-weight: 500;
  line-height: 14px;
  text-align: center;
  width: 86px;
  height: 48px;
`;
export const RegisterContainerFields = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  padding: 50px;
`;
export const FormWrapper = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const StyledGridColumn = styled(GridColumn)`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
export const StyledCompanyPresentatiosTitle = styled.div`
  width: 600px;
  margin-bottom: 25px;
`;
export const StyledInnerGridColumn = styled(GridColumn)`
  display: flex;
  margin-bottom: 0 !important;
`;

export const StyledFormGroup = styled(FormGroup)`
  width: auto;
  margin-bottom: 25px;
  div {
    label {
      margin-bottom: 0;
    }
  }
  input {
    border: 1px solid #e5e5e5;
    border-radius: 4px;
    width: ${props => props.width || '234px'};
    height: 32px;
    font-size: 16px;
  }
`;
export const ImageFormGroup = styled(FormGroup)`
  display: flex;
  justify-content: center;
`;

export const InnerForm = styled.div`
  font-family: 'Roboto', sans-serif;
  width: 580px;
  h3 {
    font-size: 32px;
    font-family: 'Blinker', sans-serif;
    margin-bottom: 25px;
  }
`;
export const ErrorMessage = styled.div`
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
  color: #c25446;
`;
export const InnerFormInfo = styled.h4`
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  color: #888888;
  margin-bottom: 15px;
`;
