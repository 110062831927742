import React from 'react';

import { StyledPaginationListContainer, StyledPaginationNumberContainer } from './styled';
import { usePaginationRange, DOTS } from './usePaginationRange';
const PaginationRange = props => {
  const { onPageChange, pageCount, className = '', siblingCount = 1, currentPage, pageSize } = props;
  const paginationRange = usePaginationRange({
    currentPage,
    pageCount,
    siblingCount,
    pageSize,
  });

  if (paginationRange && paginationRange.length < 2) {
    return null;
  }

  return (
    <StyledPaginationListContainer className={className}>
      {paginationRange ? (
        paginationRange.map((pageNumber, i) => {
          if (pageNumber === DOTS) {
            return <StyledPaginationNumberContainer key={i}>&#8230;</StyledPaginationNumberContainer>;
          }

          return (
            <StyledPaginationNumberContainer
              key={i}
              isCurrentPage={pageNumber === currentPage}
              onClick={() => onPageChange(pageNumber - 1)}
            >
              {pageNumber}
            </StyledPaginationNumberContainer>
          );
        })
      ) : (
        <></>
      )}
    </StyledPaginationListContainer>
  );
};

export default PaginationRange;
