import React, { memo } from 'react';
import PropTypes from 'prop-types';

import { PhotoRadioContainer, StyledCheckbox, StyledInput } from './styled';
import { Radio } from 'components/Form/subcomponents/Radio';

import { Tooltip } from '../Tooltip/index';
const Checkbox = props => {
  const {
    isChecked,
    isCheckboxAll,
    isAllSelected,
    label,
    value,
    onChange,
    'data-cy': dataCy,
    name,
    groupPhotos,
    register,
  } = props;

  return (
    <StyledCheckbox isCheckboxAll={isCheckboxAll} isAllSelected={isAllSelected} data-cy={dataCy}>
      <StyledInput
        type="checkbox"
        data-cy={`${dataCy}-input`}
        checked={isChecked}
        onChange={onChange}
        value={value}
        name={name}
      />
      <span></span>
      <p>{label}</p>
      {name === 'Fotos' && groupPhotos && (
        <PhotoRadioContainer>
          <div>:</div>
          <div>
            <Radio text="Agrupar fotos" id="yesGroup" name="groupPhotos" value="yes" ref={register()} />
            <Tooltip text="As fotos são agrupadas por área/equipamento/unidade (indicado para atividades baixadas em lote)." />
          </div>
          <div>
            <Radio text="Não agrupar fotos" id="noGroup" name="groupPhotos" value="no" ref={register()} />
            <Tooltip text="As fotos são exibidas abaixo de cada atividade (indicado para atividades baixadas individualmente)." />
          </div>
        </PhotoRadioContainer>
      )}
    </StyledCheckbox>
  );
};

Checkbox.propTypes = {
  isChecked: PropTypes.bool,
  isCheckboxAll: PropTypes.bool,
  value: PropTypes.any,
  label: PropTypes.any,
  'data-cy': PropTypes.string,
};

export default memo(Checkbox);
