import { AxiosReportLas } from '../config';

const QRcodeService = {
  getQRcode: objs => {
    const { empresa, tipoEquipamento, unidades, grupoArea } = objs;
    return AxiosReportLas({
      method: 'post',
      url: '/v1/Listagem/ListagemEquipamentos',
      data: {
        empresa,
        tipoEquipamento,
        unidades,
        grupoArea,
      },
      headers: {
        'content-type': 'application/json',
      },
    });
  },
};

export { QRcodeService };
