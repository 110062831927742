import styled from 'styled-components';
import { Button } from 'components/Button';

export const GoBackButton = styled(Button)`
  left: 0;
  margin-right: 24px;
  background: #ffffff;
  border: 1px solid #e5e5e5;
  border-radius: 100px;
  height: 32px;
  width: 32px;
  box-sizing: border-box;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  min-width: 100%;
  align-items: center;
  margin-bottom: 34px;
`;

export const StyledQRcodeList = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  @page {
    size: 6.1cm 4.5cm;
    margin: 0;
  }

  button {
    margin: 32px;
    @media print {
      display: none;

      @page {
        margin: 0cm;
      }
    }
  }
`;

export const StyledQRcodeListContainer = styled.div`
  display: block;
`;

export const StyledQRcodeItem = styled.div`
  page-break-after: always;
  display: block;
  width: 230.5511811px;
  height: 151.18110236px;
  margin: 0 0 10px 0;
  padding: 20px 12px;
  border: 1px solid #333;
  border-radius: 8px;
  overflow: hidden;
`;

export const StyledQRcodeMain = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const StyledQRcodeInfo = styled.div`
  display: block;
  width: 45%;
  text-align: center;
`;

export const StyledQRcodeCompany = styled.div`
  display: flex;
  width: 100%;
  text-align: center;
  justify-content: center;
  img {
    display: block;
    height: 40px;
    width: fit-content;
  }
`;

export const StyledQRcodeNumber = styled.span`
  display: block;
  font-family: 'Roboto', sans-serif;
  font-size: 40px;
  font-weight: 700;
  margin: 0;
  line-height: 1.4;
`;

export const StyledQRcodeGenerated = styled.div`
  display: block;
  width: 45%;
  text-align: center;
`;

export const StyledQRcodeSerie = styled.span`
  display: block;
  font-family: 'Roboto', sans-serif;
  font-size: 13px;
  font-weight: 700;
  text-align: center;
  text-transform: uppercase;
`;
