/* istanbul ignore file */

import styled from 'styled-components';

export const ReportContainerFields = styled.div`
  width: 100%;
  display: ${props => props.flex};
  align-items: ${props => props.flexStart};
  flex-direction: ${props => props.direction};
`;
