const adapt = httpResponse => ({
  ...httpResponse,
  data: httpResponse.data.map(grupo => ({
    id: grupo.grupoUnidadeId,
    name: grupo.nome,
  })),
});

const getAllUnitGroupsAdapter = httpResponsePromise =>
  httpResponsePromise.then(
    resolve => adapt(resolve),
    reject => reject,
  );

export { getAllUnitGroupsAdapter };
