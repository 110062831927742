import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'components/Button';
// import { InputSave } from "components/Form/subcomponents/InputSave";

//import { ReactComponent as BackButtonIcon } from 'assets/icons/back-btn.svg';
// import { ReactComponent as CloseGrayIcon } from 'assets/icons/close-gray.svg';
import { ReactComponent as ClearGrayIcon } from './assets/clear-gray.svg';
import { ReactComponent as ClearWhiteIcon } from './assets/clear-white.svg';

import {
  StyledReportContainer,
  StyledHeader,
  StyledTitle,
  StyledContentWrapper,
  StyledActions,
  // StyledCurrentFilterActive,
  StyledFooter,
  StyledMain,
  StyledContainerHeader,
  StyledContainerFooter,
  StyledContainerMain,
  CleanFiltersButton,
} from './styled';

const ReportContainer = props => {
  const {
    title,
    formId,
    onSubmit,
    isClearFiltersBtnActive = true,
    // currentSavedFilter,
    // cleanSavedFilter,
    // useSavedFilters,
    // saveFilter,
    cleanFields,
    // refInputFilterName,
    renderFooter,
    children,
    exportTitle,
  } = props;

  // const [isInputSaveOpen, setIsInputSaveOpen] = useState(false);

  const [isClearBtnHover, setIsClearBtnHover] = useState(false);

  return (
    <StyledReportContainer>
      <StyledHeader>
        <StyledContainerHeader>
          <StyledContentWrapper justifyContent="flex-start" alignItems="center" gridColumnStart={2}>
            <StyledTitle>{title}</StyledTitle>
          </StyledContentWrapper>
          {isClearFiltersBtnActive && (
            <StyledContentWrapper justifyContent="flex-end" alignItems="center" gridColumnStart={4} gap="5px">
              <CleanFiltersButton
                theme="white"
                size="small"
                type="button"
                data-cy="audit-report-clear-fields-button"
                onClick={() => cleanFields()}
                onMouseEnter={() => setIsClearBtnHover(true)}
                onMouseLeave={() => setIsClearBtnHover(false)}
              >
                {isClearBtnHover ? <ClearWhiteIcon width={16} height={12} /> : <ClearGrayIcon width={16} height={12} />}
                Limpar filtros
              </CleanFiltersButton>
              {/* {currentSavedFilter ? (
              <StyledCurrentFilterActive>
                Filtro salvo aplicado:
                <span>
                  {currentSavedFilter}
                  <CloseGrayIcon onClick={cleanSavedFilter} />
                </span>
              </StyledCurrentFilterActive>
            ) : (
              <Button
                theme="white"
                size="small"
                type="button"
                onClick={useSavedFilters}
              >
                Utilizar filtros salvos
              </Button>
            )} */}
            </StyledContentWrapper>
          )}
        </StyledContainerHeader>
      </StyledHeader>
      <StyledMain>
        <form onSubmit={onSubmit} id={formId}>
          <StyledContainerMain>{children}</StyledContainerMain>
        </form>
      </StyledMain>
      {renderFooter && (
        <StyledFooter>
          <StyledContainerFooter>
            <StyledContentWrapper justifyContent="flex-end" alignItems="center" gridColumnStart={2}>
              <StyledActions>
                {/* <InputSave
                  name="filterName"
                  ref={refInputFilterName}
                  isOpen={isInputSaveOpen}
                  nameBtnSubmitSave="saveFilters"
                  onClickOpen={() => setIsInputSaveOpen(() => true)}
                  onClickSubmitSave={() => {
                    setIsInputSaveOpen(() => false);
                    saveFilter();
                  }}
                /> */}
                <Button data-cy="export-audit-report-button" type="submit" form={formId}>
                  {exportTitle}
                </Button>
              </StyledActions>
            </StyledContentWrapper>
          </StyledContainerFooter>
        </StyledFooter>
      )}
    </StyledReportContainer>
  );
};

ReportContainer.propsTypes = {
  title: PropTypes.string.isRequired,
  formId: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  currentSavedFilter: PropTypes.string,
  cleanSavedFilter: PropTypes.func,
  saveFilter: PropTypes.func,
  cleanFields: PropTypes.func.isRequired,
  onBack: PropTypes.func,
  refInputFilterName: PropTypes.any,
  renderFooter: PropTypes.bool,
  children: PropTypes.node,
};

export { ReportContainer };
