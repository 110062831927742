import { StyledTechnicianAvatarImage } from './../../../ReactMapbox/TechnicianAvatar/styled';
import styled from 'styled-components';
import { colors } from 'assets/styled/tokens';
import { StyledBatteryChargePercentage } from 'components/Battery/BatteryCharge/styled';

export const StyledTechnicianCard = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: white;
  cursor: pointer;
  border: 1px solid ${colors.whiteGray};
  width: 384px;
  margin: 12px 0;
  border-radius: 8px;
  height: 111px;
  > ${StyledBatteryChargePercentage} {
    width: 30px;
    margin-left: 10px;
  }
  ${StyledTechnicianAvatarImage} {
    border: 1px solid rgb(0, 0, 0, 0.2);
  }
  &:first-child {
    margin-top: 0;
  }
  &:hover {
    border-color: #cccccc;
  }
`;

export const StyledAvatarColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 16px;
  width: 40px;
  ${StyledBatteryChargePercentage} {
    margin-left: 9px;
  }
`;
export const StyledTechnicianInfoContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 4px;
  margin-bottom: 14px;
`;
