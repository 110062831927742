import { parseJwt } from 'utils/parse';

const adapt = httpResponse => {
  if (httpResponse.data.authToken) {
    const { authToken } = httpResponse.data;
    const payload = parseJwt(authToken.token);
    const comps = JSON.parse(payload.comps);
    return {
      ...httpResponse,
      data: {
        user: {
          id: payload.nameid,
          name: payload.unique_name,
        },
        company: {
          id: comps[0]?.Id,
        },
        unit: {
          id: comps[0]?.Units[0],
          companyId: comps[0]?.Id,
        },
        //providerId: fornecedor,
        authToken: {
          token: authToken.token,
          refreshToken: authToken.refreshToken,
          expiresIn: authToken.expiresIn,
        },
      },
    };
  } else {
    return httpResponse;
  }
};

const loginAdapter = httpResponsePromise => {
  return httpResponsePromise.then(
    resolve => adapt(resolve),
    reject => reject,
  );
};

export { loginAdapter };
