import React, { useContext, useEffect, useState, useRef } from 'react';
import { useForm, Controller, FormProvider } from 'react-hook-form';
import InputMask from 'react-input-mask';
import {
  CategoryHeader,
  CategoryHeaderTitle,
  FormWrapper,
  Header,
  HeaderName,
  PageContainer,
  RegisterContainerFields,
  SaveButton,
  StyledGridColumn,
  StyledInnerGridColumn,
  StyledFormGroup,
  InnerForm,
  ImageFormGroup,
  InnerFormInfo,
  ErrorMessage,
  StyledCompanyPresentatiosTitle,
} from './styled';
import { FiltersContext } from 'context/GlobalFiltersContext';
import { GlobalComponentsContext } from 'context/GlobalComponentsContext';
import { AuthContext } from 'context/AuthContext';
//import { getLocalAuthStorage } from 'utils/localStorage';
import { HeaderFiltersPage } from 'containers/HeaderFiltersPage';
import { PageWrapper } from 'components/PageWrapper';
import { MobileNavbar } from 'components/MobileNavbar';
import { colors } from 'assets/styled/tokens';
import { NewMenu } from 'components/Menu/NewMenu';
import { Loading, Toast } from 'components';
import ImageUpload from './Components/ImageUpload';
import { FieldRow } from 'components/Form/subcomponents/FieldRow';
import Checkbox from './Components/Checkbox';
import { RegistrationService } from 'services/main/endpoints/RegistrationService';
import { Select } from 'components/Form/subcomponents/DebouncedSelect';
import { createErrorToast, createGenericErrorToast, createSuccessToast } from 'utils/createToast';
import { useDebounce } from 'utils/hooks/useDebounce';
import * as Sentry from '@sentry/react';
import { TextaAreaInput } from './Components/TextAreaInput';

export const CompaniesRegistrationPage = ({ pathname }) => {
  const { authContext } = useContext(AuthContext);
  const { token } = authContext;
  const { globalComponentsContext } = useContext(GlobalComponentsContext);
  const { isLoading } = globalComponentsContext;
  const { headerFiltersContext } = useContext(FiltersContext);
  const { headerFilters } = headerFiltersContext;
  const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);
  const [fields, setFields] = useState({});
  const [toastList, setToastList] = useState([]);
  const [cities, setCities] = useState([]);
  const methods = useForm({
    defaultValues: {
      cep: '',
      endereco: '',
      numero: '',
      complemento: '',
      bairro: '',
      cidade: '',
      telefone: '',
      site: '',
      instagram: '',
      linkedin: '',
      facebook: '',
      dadosPublicos: false,
      imageCompany: null,
      imageBuilding: null,
      pageUrl: '',
      companyDiff: '',
      companyHistory: '',
      companyService: '',
      companyTarget: '',
      email: '',
    },
  });
  const { handleSubmit, control, reset } = methods;
  const getNewPath = async (empresaId, mediaType, fileExtension) => {
    try {
      const response = await RegistrationService.getNewMediaPath({ mediaType, fileExtension, empresaId, token });
      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {
      console.error('Erro ao obter o novo caminho de mídia:', error);
      Sentry.captureException(error);
    }
  };
  const uploadImage = async (mediaPath, file) => {
    try {
      const response = await RegistrationService.uploadFileToApi({ filePath: mediaPath, file, token });
      return response.status === 200;
    } catch (error) {
      console.error('Erro ao fazer o upload:', error);
      Sentry.captureException(error);
      return false;
    }
  };
  const handleImageUpload = async (imageData, mediaType, empresaId) => {
    if (imageData && typeof imageData !== 'string') {
      const file = imageData;
      const fileExtension = file.name.split('.').pop();

      // Optional: Add file extension validation
      const allowedExtensions = ['jpg', 'jpeg', 'png'];
      if (!allowedExtensions.includes(fileExtension.toLowerCase())) {
        createErrorToast(setToastList, `Formato de arquivo não suportado.`);
        return;
      }
      try {
        const mediaPath = await getNewPath(empresaId, mediaType, fileExtension);
        const uploadSuccess = await uploadImage(mediaPath, file);

        if (uploadSuccess) {
          return mediaPath.replace('/uploads/', '');
        } else {
          throw new Error(`Erro ao fazer upload da imagem.`);
        }
      } catch (error) {
        console.error(error.message);
        Sentry.captureMessage(error.message);
        return;
      }
    }
    return imageData;
  };
  function isValidPageUrl(url) {
    // Verifica se o URL contém apenas caracteres válidos
    const regex = /^[a-zA-Z0-9-]+$/;
    return regex.test(url);
  }
  const onSubmit = async data => {
    setIsUsedUrl(false);
    setContainInvalidChar(false);
    const isUrlValid = isValidPageUrl(data.pageUrl);
    if (!isUrlValid) {
      setContainInvalidChar(true);
      return;
    }
    if (
      !data.whatsapp ||
      !data.telefone ||
      !data.endereco ||
      !data.bairro ||
      !data.numero ||
      !data.cep ||
      !data.pageUrl
    ) {
      createErrorToast(setToastList, 'Um ou mais campos obrigatórios não foram preenchidos!');
      return;
    }
    if (!data.cidade) {
      createErrorToast(setToastList, 'Selecione uma cidade para continuar');
      return;
    }
    if (!headerFilters?.companies?.id) {
      createErrorToast(setToastList, 'Selecione uma empresa para continuar');
      return;
    }
    const empresaId = headerFilters?.companies?.id;
    const imageUrlCompany = await handleImageUpload(data.imageCompany, 'Empresas', empresaId);
    if (!imageUrlCompany) {
      createErrorToast(setToastList, 'Não foi possível fazer o upload da imagem da logo da empresa. Tente novamente!');
      return;
    }
    const imageUrlBuilding = await handleImageUpload(data.imageBuilding, 'Empresas', empresaId);
    if (!imageUrlBuilding) {
      createErrorToast(setToastList, 'Não foi possível fazer o upload da imagem do edifício. Tente novamente!');
      return;
    }
    const payloadData = {
      empresa: headerFilters?.companies?.id,
      cep: String(data.cep),
      endereco: data.endereco,
      numero: String(data.numero)?.replace(/\D/g, ''),
      complemento: data.complemento,
      bairro: data.bairro,
      cidade: data.cidade.value || data.cidade,
      telefone: String(data.telefone)?.replace(/_$/, ''),
      url: data.site,
      instagran: data.instagram,
      linkedin: data.linkedin,
      facebook: data.facebook,
      logoMarcaPath: imageUrlCompany,
      fotoEdificio: imageUrlBuilding,
      subdominio: data.pageUrl,
      historia: data.companyHistory,
      diferencial: data.companyDiff,
      servicos: data.companyService,
      publicoAlvo: data.companyTarget,
      email: data.email,
      whatsapp: data.whatsapp?.replace(/_$/, ''),
      habilitarQrCodePublico: data.dadosPublicos ? data.dadosPublicos : false,
    };

    await sendRegistrationData(payloadData);
  };

  const sendRegistrationData = async payloadData => {
    try {
      const response = await RegistrationService.sendToHomepageEmpresa({ data: payloadData, token });
      if (response.status === 200) {
        createSuccessToast(setToastList, 'Cadastro realizado com sucesso');
      } else {
        createErrorToast(setToastList, 'Ocorreu um erro e não foi possível salvar. Tente novamente.');
        Sentry.captureMessage('Erro ao enviar os dados.');
      }
    } catch (error) {
      if ((error.message = 'Outra empresa já esta usando esse subdominio')) {
        setIsUsedUrl(true);
        Sentry.captureMessage('Outra empresa já esta usando esse subdominio. Escolha um diferente.');
      } else {
        console.error('Erro ao enviar os dados para /v1/homepageempresa:', error);
        Sentry.captureException(error);
        return false;
      }
    }
  };
  const handleFocus = event => {
    if (event.target.value === '') {
      event.target.setSelectionRange(0, 0);
    }
  };
  const fetchCities = async Nome => {
    try {
      const response = await RegistrationService.getCity({ Nome, token });
      const cities = response.data.map(city => ({
        label: city.nome,
        value: city.cidadeId,
      }));
      setCities(cities);
    } catch (error) {
      createGenericErrorToast(setToastList);
      console.error(error);
    }
  };

  const debouncedFetchCities = useDebounce(Nome => {
    fetchCities(Nome);
  }, 300);

  const handleChange = event => {
    if (event === '') return;
    debouncedFetchCities(event);
  };
  const populateFieldsStrategy = async () => {
    const companyId = headerFilters?.companies?.id;
    return await RegistrationService.getCompanieInfo({
      EmpresaId: companyId,
      token,
    }).then(response => {
      const data = response.data;
      if (data?.subdominio) {
        setHasDomain(true);
      }
      return {
        cep: data.cep || '',
        endereco: data.endereco || '',
        numero: data.numero || '',
        complemento: data.complemento || '',
        bairro: data.bairro || '',
        cidade: data.cidadeObj ? { value: data.cidadeObj.cidadeId, label: data.cidadeObj.nome } : '',
        telefone: data.telefone || '',
        site: data.url || '',
        instagram: data.instagran || '',
        linkedin: data.linkedin || '',
        facebook: data.facebook || '',
        pageUrl: data.subdominio || '',
        imageBuilding: data.fotoEdificio || null,
        imageCompany: data.empresaObj ? data.empresaObj.logomarcaPath || null : null,
        dadosPublicos: data.empresaObj ? data.empresaObj.habilitarQrCodePublico || '' : '',
        companyDiff: data.diferencial || '',
        companyHistory: data.historia || '',
        companyService: data.servicos || '',
        companyTarget: data.publicoAlvo || '',
        email: data.email || '',
        whatsapp: data.whatsapp || '',
      };
    });
  };
  const populateFields = async () => {
    try {
      const fieldsData = await populateFieldsStrategy();
      setFields(prevState => ({
        ...prevState,
        ...fieldsData,
      }));
      reset(fieldsData);
    } catch (err) {
      createGenericErrorToast(setToastList);
    }
  };
  const fetchData = async () => {
    try {
      await populateFields();
    } catch (err) {
      if (err.message.includes('Network Error')) {
        history.push('/conection-error');
      }
      createGenericErrorToast(setToastList);
    }
  };
  useEffect(() => {
    if (headerFilters?.companies?.id) {
      fetchData();
    } else {
      createErrorToast(setToastList, 'Selecione uma empresa para continuar');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [headerFilters?.companies?.id]);
  const [isUsedUrl, setIsUsedUrl] = useState(false);
  const [containInvalidChar, setContainInvalidChar] = useState(false);
  const [hasDomain, setHasDomain] = useState(false);
  const textAreaRef = useRef(null);
  useEffect(() => {
    if ((isUsedUrl || containInvalidChar) && textAreaRef.current) {
      textAreaRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }, [isUsedUrl, containInvalidChar]);
  return (
    <>
      {isLoading && <Loading />}
      <PageWrapper background={colors.pampas}>
        <MobileNavbar />
        <NewMenu setIsSubMenuOpen={setIsSubMenuOpen} />
        <HeaderFiltersPage location={pathname} isSubMenuOpen={isSubMenuOpen}>
          <Header>
            <HeaderName>Cadastrar Página da Empresa</HeaderName>
          </Header>
          <PageContainer>
            <CategoryHeader>
              <CategoryHeaderTitle>Cadastro</CategoryHeaderTitle>
            </CategoryHeader>
            <RegisterContainerFields>
              <FormProvider {...methods}>
                <FormWrapper onSubmit={handleSubmit(onSubmit)}>
                  <StyledGridColumn size={1} columnGap="1rem" rowGap="1rem">
                    <StyledCompanyPresentatiosTitle>
                      <h1>Apresentação da empresa</h1>
                    </StyledCompanyPresentatiosTitle>
                    <ImageFormGroup label="">
                      <ImageUpload inputName={'imageCompany'} inputLabel={'Logo da Empresa'} />
                      <ImageUpload inputName={'imageBuilding'} inputLabel={'Foto do edifício, fachada ou escritório'} />
                    </ImageFormGroup>
                    <FieldRow marginBottom="2rem" />
                    <InnerForm>
                      <StyledFormGroup label="" width="600px" isRequired>
                        <Controller
                          name="pageUrl"
                          control={control}
                          defaultValue=""
                          render={props => (
                            <TextaAreaInput
                              maxLength={255}
                              disabled={hasDomain}
                              label={
                                <p>
                                  <b>Final do URL da página. Exemplo:</b> software.leankeep.com/clientes/
                                  <b>nomedaempresa</b> <div>*</div>
                                </p>
                              }
                              errorColor={isUsedUrl || containInvalidChar}
                              width="600px"
                              height="32px"
                              {...props}
                              ref={textAreaRef}
                            />
                          )}
                        />
                        {isUsedUrl && <ErrorMessage>Essa URL já está em uso. Por favor, escolha outra.</ErrorMessage>}
                        {containInvalidChar && (
                          <ErrorMessage>
                            Caracteres especiais não são permitidos: espaços, acentuação, cedilha, vírgulas, barras (/),
                            underline ou subtraço (_), símbolos, figuras, sinais, etc.
                          </ErrorMessage>
                        )}
                      </StyledFormGroup>
                      <StyledFormGroup label="" width="600px">
                        <Controller
                          name="companyHistory"
                          control={control}
                          defaultValue=""
                          render={props => (
                            <TextaAreaInput
                              label={
                                <p>
                                  <b>Qual é a história da empresa?</b>
                                </p>
                              }
                              errorColor={false}
                              width="600px"
                              height="104px"
                              {...props}
                              ref={null}
                            />
                          )}
                        />
                      </StyledFormGroup>
                      <StyledFormGroup label="" width="600px">
                        <Controller
                          name="companyDiff"
                          control={control}
                          defaultValue=""
                          render={props => (
                            <TextaAreaInput
                              label={
                                <p>
                                  <b>Qual é o diferencial da empresa?</b>
                                </p>
                              }
                              errorColor={false}
                              width="600px"
                              height="96px"
                              {...props}
                              ref={null}
                            />
                          )}
                        />
                      </StyledFormGroup>
                      <StyledFormGroup label="" width="600px">
                        <Controller
                          name="companyService"
                          control={control}
                          defaultValue=""
                          render={props => (
                            <TextaAreaInput
                              label={
                                <p>
                                  <b>Quais serviços oferecem?</b>
                                </p>
                              }
                              errorColor={false}
                              width="600px"
                              height="96px"
                              {...props}
                              ref={null}
                            />
                          )}
                        />
                      </StyledFormGroup>
                      <StyledFormGroup label="" width="600px">
                        <Controller
                          name="companyTarget"
                          control={control}
                          defaultValue=""
                          render={props => (
                            <TextaAreaInput
                              label={
                                <p>
                                  <b>Qual o público alvo da sua empresa?</b>
                                </p>
                              }
                              errorColor={false}
                              width="600px"
                              height="96px"
                              {...props}
                              ref={null}
                            />
                          )}
                        />
                      </StyledFormGroup>
                      <h3>Endereço</h3>
                      <StyledInnerGridColumn size={2} columnGap="1rem">
                        <StyledFormGroup label="CEP" width="178px" isRequired>
                          <Controller
                            name="cep"
                            control={control}
                            defaultValue=""
                            render={props => (
                              <InputMask
                                value={props.value || ''}
                                onChange={props.onChange}
                                mask="99999-999"
                                maskchar=" "
                                onFocus={handleFocus}
                                ref={null}
                                {...props}
                              ></InputMask>
                            )}
                          />
                        </StyledFormGroup>
                        <StyledFormGroup label="Endereço" width="398px" isRequired>
                          <Controller
                            name="endereco"
                            control={control}
                            defaultValue=""
                            render={props => <input maxLength={200} ref={null} onChange={props.onChange} {...props} />}
                          />
                        </StyledFormGroup>
                      </StyledInnerGridColumn>
                      <StyledInnerGridColumn size={2} columnGap="1rem">
                        <StyledFormGroup label="Número" width="178px" isRequired>
                          <Controller
                            name="numero"
                            control={control}
                            defaultValue=""
                            render={props => (
                              <InputMask
                                maskchar="**********"
                                mask="999999"
                                ref={null}
                                onChange={props.onChange}
                                {...props}
                              />
                            )}
                          />
                        </StyledFormGroup>
                        <StyledFormGroup label="Complemento" width="398px">
                          <Controller
                            name="complemento"
                            control={control}
                            defaultValue=""
                            render={props => <input maxLength={100} ref={null} onChange={props.onChange} {...props} />}
                          />
                        </StyledFormGroup>
                      </StyledInnerGridColumn>
                      <StyledInnerGridColumn size={2} columnGap="1rem">
                        <StyledFormGroup label="Bairro" width="288px" isRequired>
                          <Controller
                            name="bairro"
                            control={control}
                            defaultValue={fields?.companie?.bairro || ''}
                            render={props => (
                              <input
                                maxLength={100}
                                ref={null}
                                value={fields?.companie?.bairro}
                                onChange={props.onChange}
                                {...props}
                              />
                            )}
                          />
                        </StyledFormGroup>
                        <StyledFormGroup label="Cidade" isRequired width="288px">
                          <Controller
                            name="cidade"
                            control={control}
                            //defaultValue={fields?.companie?.cidade}
                            render={props => (
                              <Select
                                {...props}
                                size="small"
                                inputDifferent={true}
                                placeholder="Seleção obrigatória"
                                options={cities}
                                onInputChange={handleChange}
                                onChange={value => {
                                  //handleChange(value);
                                  props.onChange(value);
                                  //fetchData();
                                }}
                                ref={null}
                              />
                            )}
                          />
                        </StyledFormGroup>
                      </StyledInnerGridColumn>
                    </InnerForm>
                  </StyledGridColumn>
                  <StyledGridColumn size={1} columnGap="1rem" rowGap="1rem">
                    <InnerForm>
                      <h3>Contato</h3>
                      <InnerFormInfo>
                        Lembre-se de inserir links completos. Exemplo: https://www.google.com/
                      </InnerFormInfo>
                      <StyledInnerGridColumn size={2} columnGap="1rem">
                        <StyledFormGroup label="Telefone" width="288px" isRequired>
                          <Controller
                            name="telefone"
                            control={control}
                            defaultValue=""
                            render={props => (
                              <InputMask
                                mask="(99) 99999-9999"
                                placeholder="( ) "
                                maskchar=""
                                onFocus={handleFocus}
                                onChange={props.onChange}
                                ref={null}
                                {...props}
                              ></InputMask>
                            )}
                          />
                        </StyledFormGroup>
                        <StyledFormGroup label="Whatsapp" width="288px" isRequired>
                          <Controller
                            name="whatsapp"
                            control={control}
                            defaultValue=""
                            render={props => (
                              <InputMask
                                mask="(99) 99999-9999"
                                placeholder="( ) "
                                maskchar=""
                                onFocus={handleFocus}
                                onChange={props.onChange}
                                ref={null}
                                {...props}
                              ></InputMask>
                            )}
                          />
                        </StyledFormGroup>
                      </StyledInnerGridColumn>
                      <StyledInnerGridColumn size={2} columnGap="1rem">
                        <StyledFormGroup label="Email" width="288px">
                          <Controller
                            name="email"
                            control={control}
                            defaultValue=""
                            render={props => <input maxLength={100} ref={null} onChange={props.onChange} {...props} />}
                          />
                        </StyledFormGroup>
                        <StyledFormGroup label="Site" width="288px">
                          <Controller
                            name="site"
                            control={control}
                            defaultValue=""
                            render={props => <input maxLength={200} ref={null} onChange={props.onChange} {...props} />}
                          />
                        </StyledFormGroup>
                      </StyledInnerGridColumn>
                      <StyledInnerGridColumn size={2} columnGap="1rem">
                        <StyledFormGroup label="Instagram" width="288px">
                          <Controller
                            name="instagram"
                            control={control}
                            defaultValue=""
                            render={props => <input maxLength={200} ref={null} onChange={props.onChange} {...props} />}
                          />
                        </StyledFormGroup>
                        <StyledFormGroup label="Linkedin" width="288px">
                          <Controller
                            name="linkedin"
                            control={control}
                            defaultValue=""
                            render={props => <input maxLength={200} ref={null} onChange={props.onChange} {...props} />}
                          />
                        </StyledFormGroup>
                      </StyledInnerGridColumn>
                      <StyledInnerGridColumn size={2} columnGap="1rem">
                        <StyledFormGroup label="Facebook" width="288px">
                          <Controller
                            name="facebook"
                            control={control}
                            defaultValue=""
                            render={props => <input maxLength={200} ref={null} onChange={props.onChange} {...props} />}
                          />
                        </StyledFormGroup>
                      </StyledInnerGridColumn>
                      <StyledFormGroup>
                        <Controller
                          name="dadosPublicos"
                          control={control}
                          defaultValue={false}
                          render={props => (
                            <Checkbox
                              onChange={props.onChange}
                              checked={props.value}
                              name="dadosPublicos"
                              label="Tornar dados públicos"
                              ref={null}
                            />
                          )}
                        />
                      </StyledFormGroup>
                    </InnerForm>
                  </StyledGridColumn>
                  <StyledGridColumn size={1} columnGap="1rem" rowGap="1rem">
                    <InnerForm>
                      <StyledInnerGridColumn size={2} columnGap="1rem">
                        <SaveButton onClick={handleSubmit(onSubmit)}>Salvar</SaveButton>
                      </StyledInnerGridColumn>
                    </InnerForm>
                  </StyledGridColumn>
                </FormWrapper>
              </FormProvider>
            </RegisterContainerFields>
          </PageContainer>
        </HeaderFiltersPage>
      </PageWrapper>
      <Toast toastList={toastList} autoDelete dismissTime={5000} />
    </>
  );
};
