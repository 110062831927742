import styled, { css } from 'styled-components';

import { colors, medias } from 'assets/styled/tokens';
import chevronRight from 'assets/icons/chevron-right.svg';
import { StyledSubCategory } from '../Subcategory/styled';

export const StyledCategoryItem = styled.li`
  position: relative;
  width: 180px;
  margin-bottom: 16px;
  margin-right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: ${props => (props.disabled ? 'none' : 'pointer')};
  pointer-events: ${props => props.disabled && 'none'};

  @media screen and (max-width: ${medias.desktopSm}) {
    padding: 5px;
  }

  &:first-child {
    margin-top: 0;
  }

  }

  ${props =>
    props.isOpen
      ? css`
          ${StyledSubCategory} {
            opacity: 1;
            visibility: visible;
            display: block;
          }
        `
      : css`
          ${StyledSubCategory} {
            opacity: 0;
            visibility: hidden;
          }
        `};

  /* &:nth-child(4) {
      padding: 0 10px 0 10px;

    } */
  a,
  span {
    padding: 0 50px 0 24px;
    display: block;
    font-size: 14px;
    width: 180px;
    text-align: left;
    line-height: initial;
    color: ${colors.gray};

    color: ${props => props.disabled && colors.silver};
    cursor: ${props => props.disabled && 'disabled'} !important;

    &:hover {
      text-decoration: none;
    }

    @media screen and (min-width: ${medias.desktopSm}) {
      font-weight: 500;
    }
  }

  a {
    color: ${props => props.disabled && colors.silver};
    pointer-events: ${props => props.disabled && 'none'};
    &:hover {
      color: ${colors.primary};
    }
    &.active {
      color: ${colors.primary};
      border-left: 4px solid ${colors.primary};
      display: block;

      &:after {
        position: absolute;
        top: -6px;
        left: -183px;
        width: 3px;
        height: 100%;
        border-radius: 0 3px 3px 0;
        background-color: ${colors.primary};
      }
    }
  }

  ${props =>
    props.hasChildren &&
    css`
      &:after {
        position: absolute;
        right: 1rem;
        top: 6px;
        display: block;
        width: 6px;
        height: 10px;
        background: url(${chevronRight}) no-repeat center/contain;
        z-index: 1;

        @media screen and (max-width: ${medias.tablet}) {
          transform: ${props => props.isOpen && 'rotate(90deg)'};
        }
      }
    `}
`;
