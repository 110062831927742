/* istanbul ignore file */

import styled from 'styled-components';

import { fonts, colors, medias } from 'assets/styled/tokens';
import { ReactComponent as CloseGrayIcon } from 'assets/icons/close-gray.svg';
import MultiSelect from 'components/Form/subcomponents/MultiSelect';
import { ModalGeneric } from 'components/ModalGeneric';
import { StyledModalGenericBody, StyledModalGenericClose } from 'components/ModalGeneric/styled';
import { Button } from 'components/Button';
import { ReactComponent as WarningIcon } from 'assets/icons/warning-icon-2.svg';

export const StyledWarningIcon = styled(WarningIcon)`
  path {
    fill: white !important;
  }
`;

export const GoBackButton = styled(Button)`
  left: 0;
  margin-right: 24px;
  background: #ffffff;
  border: 1px solid #e5e5e5;
  border-radius: 100px;
  height: 32px;
  width: 32px;
  box-sizing: border-box;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const Header = styled.div`
  display: flex;
  flex-direction: row;
  min-width: 100%;
  align-items: center;
  margin-bottom: 34px;
`;
export const SendPmocEmailSuccessModal = styled(ModalGeneric)`
  h6 {
    color: ${colors.successGreen};
    margin-bottom: 16px;
  }
  p {
    width: 328px;
    color: ${colors.gray};
    text-align: center;
    margin-bottom: 24px;
    font-size: 16px;
    line-height: 19px;
  }

  ${StyledModalGenericClose} {
    margin-right: -8px;
  }
  ${StyledModalGenericBody} button {
    font-size: 14px;
    line-height: 16px;
    width: 96px;
  }
`;
export const AtentionModal = styled(ModalGeneric)`
  h6 {
    color: ${colors.primary};
    margin-bottom: 16px;
  }
  p {
    color: ${colors.gray};
    text-align: center;
    margin-bottom: 24px;
    font-size: 16px;
    line-height: 19px;
  }

  ${StyledModalGenericClose} {
    margin-right: -8px;
  }
  ${StyledModalGenericBody} button {
    font-size: 14px;
    line-height: 16px;
    width: 96px;
  }
`;
export const RemoveSecondResponsibleButton = styled(CloseGrayIcon)`
  display: flex;
  position: absolute;
  top: 9px;
  right: 9px;
  cursor: pointer;
`;

export const PseudoMultiSelect = styled(MultiSelect)`
  cursor: pointer;
  input {
    pointer-events: none;
  }
`;
export const StyledColumnContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 40px 0;
`;

export const StyledFormRadio = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  font-family: ${fonts.family.text};
  font-size: 12px;

  label {
    margin-left: 15px;

    &:first-child {
      margin-left: 0;
    }

    input {
      margin-right: 5px;
      cursor: pointer;
    }
  }
`;

export const StyledFormItem = styled.div`
  display: block;
`;

export const StyledFieldsColumn = styled.div`
  display: inline-grid;
  align-items: flex-end;
  grid-template-columns: repeat(${props => (props.width ? props.width : '1')}, 1fr);
  column-gap: 15px;
  width: 100%;
  margin-top: ${props => (props.marginTop ? props.marginTop : '0')};

  @media screen and (max-width: ${medias.mobile}) {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
  }
`;

export const StyledSwitchesRow = styled.div`
  display: flex;
  column-gap: 15px;
  margin-top: 30px;
`;

export const StyledSwitchAppend = styled.div`
  max-width: 456px;
  width: 100%;
  background-color: ${colors.pampas};
  padding: 0.625rem;
  border: 1px solid ${colors.whiteGray};
  border-radius: 0px 3px 3px 3px;
`;

export const StyledCertificateTypeRadioContainer = styled.div`
  display: inline-flex;
  background: white;
  border-radius: 3px;
  border: 1px solid ${colors.whiteGray};
  width: 243px;
  align-items: center;
  justify-content: space-around;
  label {
    color: ${colors.gray};
    font-size: 12px;
  }
  b {
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
  }
`;

export const StyledResponsibleFormContainer = styled.div`
  background: ${colors.pampas};
  padding: 16px 8px;
  margin-bottom: 8px;
  border-radius: 0 3px 3px 3px;
  border: 1px solid ${colors.whiteGray};
  position: relative;
`;
export const StyledAddResponsibleButton = styled.button`
  display: flex;
  margin-left: auto;
  color: ${colors.gray};
  font-weight: 500;
`;
export const EmailNotVerifiedWarningContainer = styled.section`
  display: flex;
  flex-direction: column;
  width: 100%;
  grid-column: 1 / -1;
  align-items: center;
  div {
    font-family: Blinker;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    margin-top: 16px;
    margin-bottom: 16px;
    text-align: center;

    color: #ec6655;
  }
  span {
    font-size: 16px;
    line-height: 19px;
    margin-bottom: 24px;
    text-align: center;

    color: #888888;
  }
  button > svg {
    margin-right: 8px;
  }
`;
export const StatusInfo = styled.div`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.3px;
  text-transform: uppercase;
  color: #1e1e1e;
  margin-left: 8px;
`;
export const KnowMoreButton = styled(Button)`
  box-sizing: border-box;
  position: relative;
  right: 0;
  margin-left: auto;
  width: 127px;
  height: 32px;
  background-color: #fff;
  color: ${colors.black};
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  border: 1px solid #e5e5e5;
  border-radius: 4px;
  padding: 9px 13px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
export const StatusWarningInfo = styled.div`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.3px;
  text-transform: uppercase;
  color: #c25446;
  margin-left: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;
export const RedoReportButton = styled.button`
  border: 1px solid #e5e5e5 !important;
  border-radius: 4px !important;
  height: 32px !important;
  width: 32px !important;
  color: black !important;
  padding: 0;
  margin-left: 10px;
  svg {
    height: 32px !important;
    width: 32px !important;
    path {
      fill: #1e1e1e !important;
    }
  }
`;
