import React from 'react';
import { CloseArrowContainer, StyledSubCategory, StyledSubCategoryTitle } from './styled';
import { ReactComponent as ChevronLeftButton } from 'assets/icons/chevron-left.svg';
export const Subcategory = ({ children, mainLabel, setSubMenusAreOpen, setIsSubMenuOpen, ...props }) => {
  const closeSubMenu = e => {
    e.preventDefault();
    e.stopPropagation();
    setIsSubMenuOpen(false);
    setSubMenusAreOpen(prevSubMenus => ({
      ...prevSubMenus,
      [mainLabel]: false,
    }));
  };
  return (
    <StyledSubCategory {...props}>
      <CloseArrowContainer onClick={e => closeSubMenu(e)}>
        <div>
          <ChevronLeftButton />
        </div>
      </CloseArrowContainer>
      <StyledSubCategoryTitle>{mainLabel}</StyledSubCategoryTitle>
      {children}
    </StyledSubCategory>
  );
};
