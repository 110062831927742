import React, { useCallback, useEffect, useState } from 'react';
import { GeneralPlansService } from 'services/reports/endpoints/GeneralPlansService';
import { StyledGeneralPlanApplicationCell, StyledCellDescription, StyledApplicationType } from './styled';
function GeneralPlanApplicationCell(props) {
  const [unitsCount, setUnitsCount] = useState(undefined);
  const [isLoading, setIsLoading] = useState(true);
  const [isApplicationsEmpty, setIsApplicationsEmpty] = useState(!props.cell.value);
  const getUnits = useCallback(async () => {
    if (props.cell.value.planId && props.cell.value.companyId) {
      GeneralPlansService.getSitesCount({
        companyId: props.cell.value.companyId,
        planId: props.cell.value.planId,
      })
        .then(res => {
          if (res.data === 0) {
            setIsApplicationsEmpty(true);
          } else {
            setIsApplicationsEmpty(false);

            setUnitsCount(res.data);
          }
        })
        .catch(e => {
          console.error(e);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [props.cell.value.companyId, props.cell.value.planId]);

  useEffect(() => {
    if (!props.cell.value.subGroup) {
      getUnits();
    } else {
      setIsLoading(false);
    }
  }, [getUnits, props.cell.value.subGroup, props.cell.value.planId]);

  return (
    <StyledGeneralPlanApplicationCell>
      {!isLoading && (
        <>
          <StyledApplicationType isEmpty={isApplicationsEmpty}>
            {!isApplicationsEmpty ? `${props.cell.value.type}:` : 'Nenhuma aplicação foi selecionada'}
          </StyledApplicationType>
          {!isApplicationsEmpty && (
            <StyledCellDescription>
              {props.cell.value.subGroup ? props.cell.value.subGroup : `${unitsCount} Unidade(s) de manutenção`}
            </StyledCellDescription>
          )}
        </>
      )}
    </StyledGeneralPlanApplicationCell>
  );
}

export default GeneralPlanApplicationCell;
