import { CarouselContainer, SlideItem, ClickContainer } from './styled';
import { register } from 'swiper/element/bundle';
import { useEffect, useRef } from 'react';

register();

export const Carousel = ({ photos }) => {
  const swiperRef = useRef();
  useEffect(() => {
    const swiperContainer = swiperRef.current;
    const hasMultipleItems = photos.length > 1;
    const params = {
      navigation: hasMultipleItems,
      pagination: { clickable: true },
      autoplay: {
        delay: 15000,
      },
      loop: true,
      injectStyles: [
        `
         .swiper {
              overflow: visible !important;
            }
        .swiper-button-next{
          margin-right: 10px;
        }
        .swiper-button-prev{
          margin-left: 10px;
        }
          .swiper-button-next,
          .swiper-button-prev {
            background-color: #fff;
            border-radius: 100%;
            /*border: 1px solid black;*/
            color: #1e1e1e;
            width: 32px;
            height: 32px;
            display: flex;
            justify-content: center;
            align-items: center;
          }
          .swiper-button-next svg,
          .swiper-button-prev svg{
            width:7px;
            height:14px;
          }
            .swiper-button-next svg path,
          .swiper-button-prev svg path{
            fill: #aaaaaa;
          }
          .swiper-pagination-bullet{
            width: 12px;
            height: 12px;
            background-color: #fff;
            border: 1px solid #AAAAAA;
            box-sizing: border-box;
            opacity: 1;
          }
          .swiper-pagination-bullet-active {
            opacity: var(--swiper-pagination-bullet-opacity, 1);
            background-color: #F38A00 !important;
            border-color: #F38A00;
        }

          .swiper-button-next.swiper-button-disabled, .swiper-button-prev.swiper-button-disabled {
            opacity: 1;
          }
          .swiper-horizontal > .swiper-pagination-bullets, .swiper-pagination-bullets.swiper-pagination-horizontal, .swiper-pagination-custom, .swiper-pagination-fraction {
            bottom: -32px;
            top: auto;
            left: 50%;
            background-color: #fff;
            transform: translateX(-50%);
            border: none;
            border-bottom: none;
            width: fit-content;
            height: 24px;
            border-top-left-radius: 10px;
            border-top-right-radius: 10px;
            z-index: 10;
            :host{
              height:100%;
            }
           
      `,
      ],
    };
    Object.assign(swiperContainer, params);
    swiperContainer.initialize();
  }, [photos?.length]);
  return (
    <CarouselContainer>
      <swiper-container
        ref={swiperRef}
        init="false"
        style={{
          height: '100%',
          padding: '0 0 110px 0',
        }}
      >
        {photos.map((url, index) => (
          <swiper-slide key={index}>
            <ClickContainer>
              <SlideItem src={process.env.REACT_APP_URL_IMAGES + url} alt="teste" />
            </ClickContainer>
          </swiper-slide>
        ))}
      </swiper-container>
    </CarouselContainer>
  );
};
