import { AxiosError } from 'axios';
import { pipe } from 'fp-ts/function';
import { fromNullable, fold } from 'fp-ts/Option';

export const errors: {
  [key: number]: string;
} = {
  400: 'Bad Request',
  401: 'Unauthorized',
  404: 'Not Found',
};

export function getErrorMessage(error: Error) {
  const message = error.toString();

  const errorMessage = message.replace(/error: /i, '');

  return errorMessage;
}

export function getErrorMessageByStatusCode(error: AxiosError, name?: string) {
  const statusCode = error.response?.status || 500;

  return pipe(
    errors[statusCode],
    fromNullable,
    fold(
      () => 'Unfortunately an error occurred...',
      value => `Error ${statusCode}: ${value}${name ? ` on ${name}` : ''}`,
    ),
  );
}
