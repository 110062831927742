import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Controller } from 'react-hook-form';
import { ReactComponent as ToastWarningIcon } from 'assets/icons/toast-warning-icon.svg';
import { colors } from 'assets/styled/tokens';
import { FiltersService } from 'services/reports';
import { Radio } from 'components/Form/subcomponents/Radio';
import GeneralPlanApplicationsTable from 'components/Tables/GeneralPlanApplication';
import ApplicationTableUnitCell from 'components/Tables/GeneralPlanApplication/ApplicationTableUnitCell';
import ApplicationTableActionCell from 'components/Tables/GeneralPlanApplication/ApplicationTableActionCell';
import ApplicationTableExecutedByProviderCell from 'components/Tables/GeneralPlanApplication/ApplicationTableExecutedByProviderCell';
import ApplicationTableExecutedByProviderHeader from 'components/Tables/GeneralPlanApplication/ApplicationTableExecutedByProviderHeader';
import {
  StyledAddUnitsMultiSelect,
  StyledAddUnitsModalHeader,
  StyledAddUnitsModalHeaderUnderline,
  StyledAddUnitsButton,
  StyledDeleteUnitModalHeader,
  StyledDeleteUnitModalParagraph,
  StyledDeleteUnitButton,
  StyledDeleteUnitModal,
  StyledDeleteUnitModalButtons,
  StyledAreaTypesSelect,
  StyledAddApplicationButton,
  StyledEquipmentTypeSelect,
  StyledCloseDeleteUnitModal,
  StyledRightArrow,
  StyledClearProvidersModalParagraph,
  StyledClearProvidersModalButtons,
  StyledModalCancelButton,
  StyledClearProvidersModalButton,
} from '../NewGeneralPlanPage/styled';
import { GeneralPlansService } from 'services/reports/endpoints/GeneralPlansService';
import {
  StyledFormGroup,
  StyledApplicationForm,
  StyledRadioGroup,
  ApplyToAreaEquipTypeHeader,
  ApplicationFormUnitSubHeader,
  StyledAddUnitsModal,
} from './styled';
import { createSuccessToast, createGenericErrorToast, createTrashToast } from 'utils/createToast';
import Conditional from 'components/Conditional';
import { ModalGeneric } from 'components/ModalGeneric';
import { DRAFT_PLAN_STATUS } from '../constants';

function FormApplicationStep({
  companyId,
  setToastList,
  planId,
  planStatus,
  setApplicationsPaginatedTableData,
  applicationsPaginatedTableData,
  setIsExecutedByProvider: _setIsExecutedByProvider,
  getValues,
  setValue,
  register,
  control,
  isActive,
  errors,
  setGlobalComponentsContext,
}) {
  const [isExecutedByProvider, setIsExecutedByProvider] = useState(false);
  const [isClearProvidersModalActive, setIsClearProvidersModalActive] = useState(false);
  const [applicationType, setApplicationType] = useState(false);
  const [applicationsTablePageIndex, setApplicationsTablePageIndex] = useState(0);
  // eslint-disable-next-line
  const [isApplicationFormDirty, setIsApplicationFormDirty] = useState(false);
  const [applicationsTableTotalCount, setApplicationsTableTotalCount] = useState(0);
  const [isAddApplicationModalActive, setIsAddApplicationModalActive] = useState(false);
  const [isDeleteUnitModalActive, setIsDeleteUnitModalActive] = useState(false);
  const [deleteUnitId, setDeleteUnitId] = useState(undefined);
  const [applicationsTablePageSize, setApplicationsTablePageSize] = useState(5);
  const [applicationsTablePageCount, setApplicationsTablePageCount] = useState(0);
  const [isAddUnitsModalFormDirty, setIsAddUnitsModalFormDirty] = useState(false);
  // eslint-disable-next-line
  const [sortBy, setSortBy] = useState(false);

  const [fields, setFields] = useState({
    units: [],
    providers: [],
  });

  const fetchData = async () => {
    setGlobalComponentsContext(prevState => ({
      ...prevState,
      isLoading: true,
    }));

    // await cleanFields();
    await populateFields();
    setGlobalComponentsContext(prevState => ({
      ...prevState,
      isLoading: false,
    }));
  };

  const getApplications = useCallback(async () => {
    try {
      if (companyId && planId) {
        const pageSize = 100;
        let isPaginationResultsFull = false,
          activePage = 1;
        let applications = [];
        while (isPaginationResultsFull === false) {
          let { data } = await GeneralPlansService.getSites({
            companyId,
            planId,
            pageIndex: activePage,
            pageSize: pageSize,
          });
          applications = applications.concat(data);
          if (data.length < pageSize) {
            isPaginationResultsFull = true;
          } else {
            activePage += 1;
          }
        }
        return applications;
      }
    } catch (e) {
      console.error(e);
      createGenericErrorToast(setToastList);
    }
  }, [companyId, planId, setToastList]);

  const handleClearProviders = async () => {
    try {
      const applications = await getApplications();
      applications.forEach(async application => {
        let applicationId = application.site;
        await GeneralPlansService.updateSitesProvider({
          companyId: companyId,
          planId: planId,
          applicationId: applicationId,
          provider: null,
        });
      });
      setIsExecutedByProvider(false);
    } catch (e) {
      createGenericErrorToast(setToastList);
    } finally {
      setIsClearProvidersModalActive(false);
    }
  };

  const handleUpdateTableData = useCallback(async () => {
    return await Promise.all([
      GeneralPlansService.getSites({
        companyId,
        planId,
        pageIndex: applicationsTablePageIndex + 1,
        pageSize: applicationsTablePageSize,
      }),
      GeneralPlansService.getSitesCount({
        companyId,
        planId,
      }),
      GeneralPlansService.getApplications({ planId, companyId }),
    ]).then(values => {
      let formattedApplicationsData = values[0].data.map(application => {
        return {
          unit: { id: application.site, label: application.siteNome },
          executedByProvider: {
            unitId: application.site,
            id: application.fornecedor,
            label: application.fornecedorNome,
            planId: planId,
            companyId: companyId,
          },
          edit: { id: application.site },
        };
      });

      setApplicationsTableTotalCount(values[1].data);

      setApplicationsPaginatedTableData(formattedApplicationsData);
      return values[0].data.map(application => application.site);
    });
  }, [applicationsTablePageIndex, applicationsTablePageSize, companyId, planId, setApplicationsPaginatedTableData]);

  const handleRemoveApplication = useCallback(
    id => {
      GeneralPlansService.deleteSite({
        planId,
        companyId,
        applicationId: id,
      })
        .then(() => {
          handleUpdateTableData();
          createTrashToast(setToastList, 'Unidade de manutenção excluída.');
        })
        .catch(() => {
          createGenericErrorToast(setToastList);
        })
        .finally(() => {
          handleCloseDeleteUnitModal();
        });
    },
    [companyId, planId, setToastList, handleUpdateTableData],
  );
  const areFieldsDisabled = useMemo(() => {
    return planStatus === DRAFT_PLAN_STATUS ? false : true;
  }, [planStatus]);
  const columns = useMemo(
    () => [
      {
        Header: 'Unidade de manutenção',
        accessor: 'unit',
        sortType: 'alphanumeric',
        disableSortBy: true,
        Cell: props => <ApplicationTableUnitCell {...props} />,
      },
      {
        Header: props => (
          <ApplicationTableExecutedByProviderHeader
            disabled={areFieldsDisabled}
            setIsExecutedByProvider={isExecuted => {
              setIsExecutedByProvider(isExecuted);
              _setIsExecutedByProvider(isExecuted);
            }}
            setIsClearProvidersModalActive={setIsClearProvidersModalActive}
            isExecutedByProvider={isExecutedByProvider}
            {...props}
          />
        ),
        accessor: 'executedByProvider',
        disableSortBy: true,

        Cell: props => (
          <ApplicationTableExecutedByProviderCell
            isExecutedByProvider={isExecutedByProvider}
            setIsExecutedByProvider={isExecuted => {
              setIsExecutedByProvider(isExecuted);
              _setIsExecutedByProvider(isExecuted);
            }}
            setToastList={setToastList}
            companyId={companyId}
            units={getValues('units')}
            isDraft={planStatus === DRAFT_PLAN_STATUS}
            isEdit
            isApplicationFormDirty={isApplicationFormDirty}
            {...props}
          />
        ),
      },

      {
        Header: 'Ação',
        accessor: 'edit',
        disableSortBy: true,
        Cell: props => (
          <ApplicationTableActionCell deleteUnitId={deleteUnitId} setDeleteUnitId={setDeleteUnitId} {...props} />
        ),
      },
    ],
    [
      areFieldsDisabled,
      isExecutedByProvider,
      _setIsExecutedByProvider,
      setToastList,
      companyId,
      getValues,
      planStatus,
      isApplicationFormDirty,
      deleteUnitId,
    ],
  );

  const populateFieldsStrategy = async () => {
    if (companyId) {
      return await Promise.all([
        FiltersService.getUnits({
          companyId,
        }),
        FiltersService.getEquipmentTypes({
          companyId,
        }),
        GeneralPlansService.getApplicationType({ companyId, planId }),
      ]).then(values => ({
        units: values[0].data.map(({ nome, site }) => ({
          label: nome,
          value: site,
        })),
        equipmentTypes: values[1].data.map(({ name, id }) => ({
          label: name,
          value: id,
        })),
        applicationType: values[2].data,
      }));
    }
  };

  const populateFields = async () => {
    try {
      const fieldsData = await populateFieldsStrategy();
      if (fieldsData.applicationType.isSite) {
        setApplicationType(false);
      } else if (fieldsData.applicationType.tipoArea) {
        setApplicationType('area');
        setValue('areaType', fieldsData.applicationType.tipoArea);
      } else if (fieldsData.applicationType.linhaProdutoEmpresa) {
        setApplicationType('equipment');
        setValue('equipmentType', fieldsData.applicationType.linhaProdutoEmpresa);
      }
      setFields(prevState => ({
        ...prevState,
        ...fieldsData,
      }));
    } catch (err) {
      console.error(err);
      createGenericErrorToast(setToastList);
    }
  };

  const handleAddApplications = () => {
    setIsAddUnitsModalFormDirty(true);
    let isFormValid = false;
    if (getValues('units')?.length > 0) {
      isFormValid = true;
    }
    if (isFormValid)
      GeneralPlansService.updateSites({
        companyId: companyId,
        applications: getValues('units').map(id => {
          return { site: parseInt(id) };
        }),
        planId: planId,
      })
        .then(() => {
          handleUpdateTableData();
          createSuccessToast(setToastList, 'Unidade(s) adicionada(s).');

          setIsAddApplicationModalActive(false);
        })
        .catch(() => {
          // add error function
          createGenericErrorToast(setToastList);
        })
        .finally(() => {
          setIsAddUnitsModalFormDirty(false);
        });
  };
  const handleCloseDeleteUnitModal = () => {
    setDeleteUnitId(undefined);
    setIsDeleteUnitModalActive(false);
  };
  const handleUpdateApplicationType = useCallback(
    (type, value) => {
      GeneralPlansService.updateApplicationType({
        companyId,
        planId,
        areaType: type === 'areaType' ? value : null,
        equipmentType: type === 'equipmentType' ? value : null,
      });
    },
    [companyId, planId],
  );
  useEffect(() => {
    if (isActive) {
      fetchData();
    }
    // eslint-disable-next-line
  }, [errors, isActive]);
  useEffect(() => {
    if (deleteUnitId) {
      setIsDeleteUnitModalActive(true);
    }
  }, [deleteUnitId]);
  useEffect(() => {
    if (isActive) {
      handleUpdateTableData();
    }
  }, [
    applicationsPaginatedTableData.length,
    applicationsTablePageIndex,
    applicationsTablePageSize,
    setGlobalComponentsContext,
    companyId,
    planId,
    isActive,
    setValue,
    handleUpdateTableData,
  ]);

  useEffect(() => {
    getApplications().then(applications => {
      if (applications?.some(application => application.fornecedor !== null)) {
        setIsExecutedByProvider(true);
      } else {
        setIsExecutedByProvider(false);
      }
    });
  }, [getApplications, setToastList]);
  return (
    <StyledApplicationForm isActive={isActive}>
      <ApplicationFormUnitSubHeader>
        Unidades de manutenção
        <StyledAddApplicationButton onClick={() => setIsAddApplicationModalActive(true)} size="small" type="submit">
          <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1 6H11" stroke={colors.primary} strokeWidth="2" strokeLinecap="round" />
            <path d="M6 1V11" stroke={colors.primary} strokeWidth="2" strokeLinecap="round" />
          </svg>
          Adicionar unidades
        </StyledAddApplicationButton>
      </ApplicationFormUnitSubHeader>

      <>
        {applicationsPaginatedTableData?.length > 0 && (
          <GeneralPlanApplicationsTable
            columns={columns}
            totalCount={applicationsTableTotalCount}
            pageSize={applicationsTablePageSize}
            setSortBy={setSortBy}
            pageIndex={applicationsTablePageIndex}
            pageCount={applicationsTablePageCount}
            setPageCount={setApplicationsTablePageCount}
            setPageSize={setApplicationsTablePageSize}
            setPageIndex={setApplicationsTablePageIndex}
            data={applicationsPaginatedTableData}
            setToastList={setToastList}
          />
        )}
        <ApplyToAreaEquipTypeHeader>
          Aplicar também a um tipo de Área ou Equipamento?
          <StyledRadioGroup
            disabled={areFieldsDisabled}
            showTooltip={areFieldsDisabled}
            tooltipMessage="Não editável após o agendamento"
          >
            <Radio
              text="Não"
              name="applicationType"
              type="radio"
              value="none"
              ref={register()}
              disabled={areFieldsDisabled}
              onChange={() => {}}
              onClick={() => {
                handleUpdateApplicationType();
                setValue('areaType', null);
                setValue('equipmentType', null);
                setApplicationType(false);
              }}
              checked={applicationType === false}
            />
            <Radio
              text="Tipo de Área"
              name="applicationType"
              type="radio"
              disabled={areFieldsDisabled}
              value="areaType"
              onChange={() => setApplicationType('area')}
              checked={applicationType === 'area'}
              ref={register()}
            />
            <Radio
              text="Tipo de Equipamento"
              type="radio"
              name="applicationType"
              disabled={areFieldsDisabled}
              value="equipmentTypes"
              onChange={() => setApplicationType('equipment')}
              checked={applicationType === 'equipment'}
              ref={register()}
            />
            <Conditional when={applicationType === 'area'}>
              <StyledRightArrow width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1 1L6 6L1 11" stroke="#BBBBBB" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
              </StyledRightArrow>
            </Conditional>

            <Controller
              render={props => (
                <StyledAreaTypesSelect
                  size="small"
                  {...props}
                  isRequired
                  isActive={applicationType === 'area'}
                  disabled={areFieldsDisabled}
                  placeholder="Selecione um tipo de área"
                  headers={{ EmpresaId: companyId }}
                  name="areaType"
                  // params={{ planoGeral: 3 }}
                  value={getValues('areaType') || fields?.applicationType?.tipoArea}
                  onSelect={({ value }) => {
                    handleUpdateApplicationType('areaType', value);
                  }}
                  adapter={data => {
                    return data.map(areaType => {
                      return {
                        label: areaType.nome,
                        value: areaType.tipoArea,
                      };
                    });
                  }}
                  url="/v1/tiposareas"
                />
              )}
              name="areaType"
              control={control}
            />
            <Conditional when={applicationType === 'equipment'}>
              <StyledRightArrow width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1 1L6 6L1 11" stroke="#BBBBBB" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
              </StyledRightArrow>
            </Conditional>

            <Controller
              render={props => (
                <StyledEquipmentTypeSelect
                  {...props}
                  isActive={applicationType === 'equipment'}
                  name="equipmentType"
                  size="small"
                  disabled={areFieldsDisabled}
                  placeholder="Selecione um tipo de equipamento"
                  error={isApplicationFormDirty && !props.value}
                  errormessage="Campo obrigatório"
                  options={fields?.equipmentTypes}
                  value={getValues('equipmentType') || fields?.applicationType?.linhaProdutoEmpresa}
                  onSelect={({ value }) => {
                    handleUpdateApplicationType('equipmentType', value);
                  }}
                />
              )}
              name="equipmentType"
              control={control}
            />
          </StyledRadioGroup>
        </ApplyToAreaEquipTypeHeader>
      </>
      <StyledAddUnitsModal
        open={isAddApplicationModalActive}
        closeModal={() => setIsAddApplicationModalActive(false)}
        size={{ maxWidth: '521px' }}
      >
        <StyledAddUnitsModalHeader>Adicionar Unidades de Manutenção</StyledAddUnitsModalHeader>
        <StyledAddUnitsModalHeaderUnderline />
        <StyledFormGroup label="Unidades de Manutenção " isRequired>
          <Controller
            render={props => (
              <StyledAddUnitsMultiSelect
                {...props}
                error={isAddUnitsModalFormDirty && !getValues('units')?.length > 0}
                errormessage="Campo obrigatório"
                isRequired
                size="small"
                placeholder="Seleção obrigatória"
                options={fields?.units}
              />
            )}
            name={`units`}
            control={control}
            rules={{ required: true }}
          />
        </StyledFormGroup>
        <StyledAddUnitsButton onClick={() => handleAddApplications()}>
          <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="6" cy="6" r="6" fill="white" />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M5.53651 8.81249C5.28206 9.06246 4.85747 9.06246 4.60302 8.81249L2.19023 6.4422C1.93659 6.19303 1.93659 5.77838 2.19023 5.5292L2.71349 5.01517C2.96778 4.76536 3.37748 4.7652 3.63196 5.0147L5.07616 6.4209L8.36756 3.18747C8.622 2.93751 9.03206 2.93751 9.2865 3.18747L9.80976 3.70151C10.0634 3.95069 10.0634 4.36534 9.80976 4.61451L5.53651 8.81249Z"
              fill={colors.primary}
            />
          </svg>
          Adicionar unidades
        </StyledAddUnitsButton>
      </StyledAddUnitsModal>
      <StyledDeleteUnitModal open={isDeleteUnitModalActive} closeModal={() => handleCloseDeleteUnitModal()}>
        <svg width="80" height="88" viewBox="0 0 80 88" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M1.75436e-05 30.1139V57.8454C-0.00416577 60.7046 0.739864 63.5143 2.15712 65.9914C3.57437 68.4686 5.61477 70.5256 8.07267 71.9552L31.9273 85.8209C34.3817 87.2485 37.1659 88 40 88C42.8341 88 45.6183 87.2485 48.0727 85.8209L71.9273 71.9552C74.3852 70.5256 76.4256 68.4686 77.8429 65.9914C79.2601 63.5143 80.0042 60.7046 80 57.8454V30.1139C79.9971 27.2617 79.2497 24.4603 77.8328 21.9909C76.4159 19.5216 74.3793 17.471 71.9273 16.0448L48.0727 2.17907C45.6183 0.751537 42.8341 0 40 0C37.1659 0 34.3817 0.751537 31.9273 2.17907L8.07267 16.2481C5.65006 17.6568 3.63231 19.6755 2.21715 22.1062C0.802002 24.5368 0.0379999 27.2962 1.75436e-05 30.1139Z"
            fill="#FFDBD6"
          />
          <path
            d="M54.8571 26.5H46.2857L45.5714 25.0938C45.2857 24.4688 44.6429 24 44.0714 24H35.8571C35.2857 24 34.5714 24.4688 34.3571 25.0938L33.7143 26.5H25.1429C24.5 26.5 24 27.125 24 27.75V30.25C24 30.9531 24.5 31.5 25.1429 31.5H54.8571C55.4286 31.5 56 30.9531 56 30.25V27.75C56 27.125 55.4286 26.5 54.8571 26.5ZM27.7857 60.4844C27.8571 62.4375 29.4286 64 31.2143 64H48.7143C50.5 64 52.0714 62.4375 52.1429 60.4844L53.7143 34H26.2857L27.7857 60.4844Z"
            fill="#EC6655"
          />
          <rect x="34" y="42" width="2" height="14" fill="#FFDBD6" />
          <rect x="39" y="42" width="2" height="14" fill="#FFDBD6" />
          <rect x="44" y="42" width="2" height="14" fill="#FFDBD6" />
        </svg>

        <StyledDeleteUnitModalHeader>Excluir unidade de manutenção?</StyledDeleteUnitModalHeader>
        <StyledDeleteUnitModalParagraph>O plano geral será inativado nessa unidade.</StyledDeleteUnitModalParagraph>
        <StyledDeleteUnitModalButtons>
          <StyledCloseDeleteUnitModal onClick={() => handleCloseDeleteUnitModal()}>Cancelar</StyledCloseDeleteUnitModal>
          <StyledDeleteUnitButton onClick={() => handleRemoveApplication(deleteUnitId)}>
            Excluir unidade
          </StyledDeleteUnitButton>
        </StyledDeleteUnitModalButtons>
      </StyledDeleteUnitModal>
      <ModalGeneric
        size={{ width: '469px' }}
        open={isClearProvidersModalActive}
        hasHeader
        icon={<ToastWarningIcon />}
        title="Inativar fornecedores?"
        closeModal={() => setIsClearProvidersModalActive(false)}
      >
        <StyledClearProvidersModalParagraph>
          Os campos de fornecedores preenchidos serão apagados.
        </StyledClearProvidersModalParagraph>
        <StyledClearProvidersModalButtons>
          <StyledModalCancelButton type="button" onClick={() => setIsClearProvidersModalActive(false)}>
            Cancelar
          </StyledModalCancelButton>
          <StyledClearProvidersModalButton type="button" onClick={() => handleClearProviders()}>
            Inativar mesmo assim
          </StyledClearProvidersModalButton>
        </StyledClearProvidersModalButtons>
      </ModalGeneric>
    </StyledApplicationForm>
  );
}

export default FormApplicationStep;
