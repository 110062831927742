const adapt = httpResponse => ({
  ...httpResponse,
  data: httpResponse.data.map(fornecedor => ({
    id: fornecedor.id,
    name: fornecedor.nome,
  })),
});

const getAllProvidersAdapter = httpResponsePromise =>
  httpResponsePromise.then(
    resolve => adapt(resolve),
    reject => reject,
  );

export { getAllProvidersAdapter };
