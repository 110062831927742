import { AxiosReports } from '../config';

const SavedFiltersService = {
  createSavedFilter: ({ companyId, userId, reportName, filterName, fields }) =>
    AxiosReports({
      method: 'post',
      url: `/savedFilters/${companyId}/users/${userId}`,
      data: {
        reportName,
        filterName,
        fields,
      },
    }),

  findSavedFiltersByReportName: ({ companyId, userId, reportName }) =>
    AxiosReports({
      method: 'get',
      url: `/savedFilters/${companyId}/users/${userId}/filters/${reportName}`,
    }),

  getSavedFilters: ({ companyId, filterSavedId }) =>
    AxiosReports({
      method: 'get',
      url: `/savedFilters/${companyId}/filters/${filterSavedId}`,
    }),

  removeSavedFilter: ({ companyId, filterSavedId }) =>
    AxiosReports({
      method: 'delete',
      url: `/savedFilters/${companyId}/filters/${filterSavedId}`,
    }),
};

export { SavedFiltersService };
