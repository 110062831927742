import * as React from 'react';
import { LkpDropdown } from 'components/Dropdown';
import { LkpDropdownMenuItemProps } from 'components/Dropdown/Menu';
import { toggleAllItems, toggleItem } from './helpers';

export interface LkpFilterOccurrencesProps {
  onSelectOccurrences?: (occurrencesParams: string) => void; // occurrencesParams = 'status=123&status=123&status=123'
}

const DEFAULT_ITEMS: Array<LkpDropdownMenuItemProps> = [
  { id: '__SelecionarTodas', label: 'Selecionar todas', selectAll: true, selected: false },
  { id: '1', label: 'Analisadas', selected: false },
  { id: '2', label: 'Solucionadas', selected: false },
  { id: '3', label: 'Não analisadas', selected: false },
  { id: '4', label: 'Inativas', selected: false },
  { id: '5', label: 'Expiradas', selected: false },
];

export function LkpFilterOccurrences({ onSelectOccurrences }: LkpFilterOccurrencesProps): React.ReactElement {
  const [localOccurrences, setOccurrences] = React.useState<Array<LkpDropdownMenuItemProps>>(DEFAULT_ITEMS);
  const [selectedOccurrences, setSelectedOccurrences] = React.useState<Array<LkpDropdownMenuItemProps>>([]);

  React.useEffect(() => {
    if (onSelectOccurrences && localOccurrences && localOccurrences.length > 0) {
      let _occurrences = localOccurrences
        .filter(({ id }) => id !== '__SelecionarTodas')
        .filter(({ selected }) => !!selected)
        .map(({ id }) => [id])
        .join('&status=');

      if (_occurrences) _occurrences = `status=${_occurrences}`;
      onSelectOccurrences(_occurrences);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localOccurrences]);

  const handleToogleAllOccurrences = (selected = true) => {
    const _newItems = toggleAllItems({ items: localOccurrences, toggle: selected });

    setOccurrences(_newItems);
    setSelectedOccurrences(_newItems.filter(item => item.selected));
  };

  const handleSelectOccurrence = (itemId: string) => {
    let _newItems: Array<LkpDropdownMenuItemProps> = [];
    let _selectedItems: Array<LkpDropdownMenuItemProps> = [];

    if (localOccurrences) {
      _newItems = toggleItem({ items: localOccurrences, toggleItem: itemId });
      _selectedItems = _newItems.filter(item => item.selected);
    }

    setOccurrences(_newItems);
    setSelectedOccurrences(_selectedItems);
  };

  return (
    <React.Fragment>
      {onSelectOccurrences && (
        <LkpDropdown
          onChangeItem={handleSelectOccurrence}
          onToggleAll={handleToogleAllOccurrences}
          items={localOccurrences}
        >
          {selectedOccurrences.length <= 0 && `Todas as ocorrências`}
          {selectedOccurrences.length === 1 && `1 ocorrência`}
          {selectedOccurrences.length > 1 &&
            `${
              selectedOccurrences.length === localOccurrences.length
                ? selectedOccurrences.length - 1
                : selectedOccurrences.length
            } ocorrências`}
        </LkpDropdown>
      )}
    </React.Fragment>
  );
}
