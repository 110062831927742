import styled from 'styled-components';

import { colors, fonts, medias } from 'assets/styled/tokens';
import { ReactComponent as CloseGrayIcon } from 'assets/icons/close-gray.svg';
import { ReactComponent as SuccessCheckmark } from 'assets/icons/success-checkmark.svg';

import MultiSelect from 'components/Form/subcomponents/MultiSelect';
import { Select } from 'components/Form/subcomponents/Select';
import { FormGroup } from 'components/Form/subcomponents/FormGroup';
import { Button } from 'components/Button';
import { Input } from 'components/Form/subcomponents/Input';
import { Textarea } from 'components/Form/subcomponents/Textarea';
import { Checkbox } from 'components/Form/subcomponents/Checkbox';
import { MaskedInput } from 'components/Form/subcomponents/MaskedInput';
import { DatePicker } from 'components/Form/subcomponents/DatePicker';
import { TimeInput } from 'components/Form/subcomponents/TimeInput';
import { Radio } from 'components/Form/subcomponents/Radio';
import { DefaultSelect } from 'assets/styled/sheets/components/Forms';
import { inputStyleFactory } from 'components/Form/shared/inputStyleFactory';
import { ModalGeneric } from 'components/ModalGeneric';
import { PaginatedSelect } from 'components/Form/subcomponents/PaginatedSelect';

export const StyledFormSectionNavbar = styled.nav`
  display: flex;
  justify-content: center;
  border-bottom: 1px solid ${colors.whiteGray};
`;
export const StyledNavItemNumber = styled.div`
  color: ${colors.primary};
`;
export const StyledNavItemCheckmark = styled.svg`
  display: ${props => (props.isActive ? 'inline-flex' : 'none')};
  color: #03725f;
  margin-right: 10px;
`;
export const StyledFormsContainerWrapper = styled.section`
  background: white;
  border-radius: 8px;
  box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.1);
`;
export const StyledFormsContainer = styled.main`
  display: flex;
  flex-direction: column;
`;
export const StyledTabHeaderContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  border-bottom: 1px solid ${colors.whiteGray};
  margin-bottom: 13px;
  padding: 16px 24px;
  a:first-child {
    height: 24px;
  }
`;
export const StyledSchedulePlanSuccessContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 48px;
`;
export const StyledSchedulePlanSuccessIcon = styled(SuccessCheckmark)`
  margin-top: 40px;
  margin-bottom: 16px;
`;
export const StyledSchedulePlanSuccessHeader = styled.h2`
  color: ${colors.successGreen};
  font-weight: 600;
  margin-bottom: 16px;
  font-size: 18px;
`;
export const StyledSchedulePlanSuccessSubHeader = styled.p`
  color: ${colors.gray};
  margin-bottom: 24px;
  font-weight: 400;
  font-size: 16px;
`;
export const StyledSchedulePlanSuccessButton = styled(Button)``;
export const StyledSchedulePlanLoadingStickyHeader = styled.div`
  display: flex;
  justify-content: center;
  background: ${colors.primary};
  position: fixed;
  top: 0;
  left: 0;
  padding: 15px 0 17px;
  z-index: 5;
  width: 100%;
`;

export const StyledDiscardDraftButton = styled.button`
  display: flex;
  align-items: center;
  font-weight: 500;
  color: ${colors.carbon};
  border: 1px solid ${colors.whiteGray};
  padding: 5px 8px;
  margin-left: 24px;
  border-radius: 4px;
  background: #fff;
  svg {
    margin-right: 8px;
  }
  &:hover {
    color: #fff;
    background: #ec6655;
    border: 1px solid #ec6655;
    path,
    rect {
      fill: #fff;
    }
  }
  transition: all ease-in 300ms;
`;
export const StyledModalBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  img {
    max-width: 80px;
    margin-bottom: 32px;
  }

  h6 {
    font-family: Blinker;
    color: ${colors.bittersweet};
    font-weight: 700;
    font-size: 21px;
    line-height: 25.2px;
    margin-bottom: 16px;
    text-align: center;
  }
`;
export const StyledDeleteDraftModalParagraph = styled.p`
  margin-top: 0;
  margin-bottom: 24px;
  color: ${colors.gray};
`;
export const StyledModalButtons = styled.div``;
export const StyledModalCancelButton = styled(Button)`
  background: white;
  border: 1px solid ${colors.whiteGray};
  color: ${colors.carbon};
  margin-right: 16px;
`;
export const StyledModalDeleteDraftButton = styled(Button)`
  color: ${colors.white};
  background: #ec6655;
`;

export const StyledDraftOptionsContainer = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
  font-size: 12px;
  color: ${colors.gray};
  font-weight: 400;
  svg {
    margin-right: 8px;
  }
`;
export const StyledTabHeader = styled.div`
  display: inline-flex;
  flex-direction: column;
  font-family: Blinker, sans-serif;
  align-items: space-between;
  padding-left: 16px;
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;
  color: ${colors.primary};
`;
export const NextSectionArrow = styled.svg`
  margin-right: 18px;
`;
export const StyledFormSectionNavItem = styled.button`
  font-size: 12px;
  text-transform: uppercase;
  display: flex;
  margin-right: 24px;
  padding: 0 0 11px 0;
  border-bottom: ${props =>
    props.isFinished && props.isActive ? '2px solid #03725f' : props.isActive ? `2px solid ${colors.primary}` : 'none'};
  color: ${props => (props.isFinished ? '#03725f' : props.isActive ? colors.primary : colors.gray)};
  font-weight: ${props => (props.isActive || props.isFinished ? '700' : '500')};
`;
export const StyledFormSection = styled.div`
  display: ${props => (props.isActive ? 'flex' : 'none')};
  flex-direction: column;
  padding: 24px;
`;
export const StyledPlanInfoSection = styled(StyledFormSection)`
  display: ${props => (props.isActive ? 'flex' : 'none')};
  flex-direction: column;
  align-items: center;
`;
export const StyledApplicationForm = styled.div`
  display: ${props => (props.isActive ? 'flex' : 'none')};
  flex-direction: column;
  align-self: center;
  padding: 40px 24px;
  width: ${props => (props.isStep1 ? `588px` : `100%`)};
  transition: all 300ms ease;
`;
export const StyledClearProvidersModalParagraph = styled.p`
  margin-top: 0;
  margin-bottom: 24px;
  line-height: 19px;
  color: ${colors.gray};
`;
export const StyledClearProvidersModalButtons = styled.div`
  display: flex;
  margin-bottom: 8px;
`;
export const StyledClearProvidersModalButton = styled(Button)`
  color: ${colors.white};
  background: ${colors.primary};
`;
export const ActivitiesTableContainer = styled.table`
  width: 100%;
  border-spacing: 0;
  border-radius: 8px;
  margin-bottom: 32px;
  th {
    padding: 17px 12px;
    border: 1px solid ${colors.whiteGray};
    text-align: left;
    font-size: 12px;
  }
  th:nth-child(1) {
    width: 340px;
  }
  th:nth-child(2) {
    width: 84px;
  }
  th:nth-child(3) {
    width: 121px;
  }
  th:nth-child(4) {
    width: 144px;
  }
  th:nth-child(5) {
    width: 334px;
  }
  th:nth-child(6) {
    width: 52px;
  }
  td {
    border: 1px solid ${colors.whiteGray};
    font-size: 12px;
    font-weight: 400;
    color: ${colors.gray};
  }
  border: 1px solid ${colors.whiteGray};
`;

export const StyledPlanInfoHeader = styled.h2`
  font-size: 18px;
  line-height: 21px;
  color: ${colors.carbon};
  font-family: Blinker;
  font-weight: 600;
  margin-bottom: 24px;
  margin-top: 16px;
`;

export const StyledAddUnitButton = styled.button`
  width: 188px;
  border: 1px solid ${colors.primary};
  font-size: 12px;
  border-radius: 3px;
  font-weight: 500;
  color: ${colors.primary};
  padding: 6px;
  margin-bottom: 48px;
`;

export const FormSubHeader = styled.h3`
  font-size: 18px;
  color: ${colors.carbon};
  width: 100%;
  font-family: Blinker;
  font-weight: 600;
  margin-bottom: 32px;
  line-height: 21px;
  > span {
    font-weight: 500;
    font-size: 18px;
    margin-left: 8px;
    line-height: 22px;
    color: ${colors.gray};
  }
`;
export const ApplicationFormUnitSubHeader = styled(FormSubHeader)`
  margin-bottom: 24px;
`;
export const StyledAddApplicationButton = styled(Button)`
  display: inline-flex;
  align-items: center;
  margin-left: 24px;
  svg {
    margin-right: 6px;
    path {
      stroke: white;
    }
  }
`;

export const StyledAreaTypesSelect = styled(PaginatedSelect)`
  margin-left: 24px;
  width: 100%;
  max-width: 359px;
  section {
    min-height: 100px;
    max-height: 12vh;
  }
`;
export const StyledEquipmentTypeSelect = styled(Select)`
  margin-left: 24px;
  width: 100%;
  max-width: 359px;
  section {
    min-height: 100px;
    max-height: 12vh;
  }
`;
export const StyledMultiSelect = styled(MultiSelect)`
  display: flex;

  width: ${props => (!props.isStep1 ? `320px` : `100%`)};
`;
export const StyledAddUnitsButton = styled.button`
  background: ${colors.primary};
  display: flex;
  align-items: center;
  padding: 9px 12px;
  margin-top: 32px;
  svg {
    margin-right: 8px;
  }
  border-radius: 6px;
  color: white;
`;
export const StyledUnitsMultiSelect = styled(MultiSelect)`
  display: flex;
  button {
    display: none;
  }
  width: 100%;
`;
export const StyledAddUnitsMultiSelect = styled(MultiSelect)`
  display: flex;

  width: 100%;
`;
export const StyledAddUnitsModalHeader = styled.h2`
  margin-bottom: 20px;
  font-size: 21px;
  line-height: 25px;
  color: ${colors.primary};
`;
export const StyledAddUnitsModalHeaderUnderline = styled.span`
  height: 1px;
  width: calc(100% + 48px);
  margin-left: -24px;
  background: ${colors.whiteGray};
  margin-bottom: 24px;
`;
export const StyledAddUnitsModal = styled(ModalGeneric)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 552px;
  max-width: 552px;
  > div {
    width: 100%;
    align-items: flex-start;
  }
`;
export const StyledDeleteUnitModalHeader = styled.h2`
  margin-bottom: 16px;
  font-size: 21px;
  line-height: 25px;
  margin-top: 8px;
  color: #ec6655;
  font-family: Blinker;
`;
export const StyledDeleteUnitModalParagraph = styled.p`
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  color: ${colors.gray};
`;
export const StyledDeleteUnitModalButtons = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 32px;
`;
export const StyledDeleteUnitModal = styled(ModalGeneric)`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 357px;
  max-width: 357px;
  padding: 24px 24px 32px 24px;
  div {
    width: 100%;
    align-items: center;
  }
  margin-bottom: 45px;
`;
export const StyledDeleteUnitButton = styled(Button)`
  background: #ec6655;
  color: white;
  display: flex;
  align-items: center;
  padding: 9px 12px;
  svg {
    margin-right: 6px;
  }
  border-radius: 6px;
  color: white;
`;
export const StyledCloseDeleteUnitModal = styled(Button)`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  font-weight: 500;
  color: ${colors.carbon};
  border: 1px solid ${colors.whiteGray};
  padding: 9px 12px;
  margin-right: 16px;
  border-radius: 4px;
  background: ${colors.white};

  transition: all ease-in 300ms;
`;
export const StyledRightArrow = styled.svg`
  min-width: 7px;
`;
export const StyledExecutedByProviderHeader = styled.div`
  display: flex;
  margin-bottom: 32px;
  margin-top: 32px;
  h4 {
    font-size: 18px;
    color: ${colors.carbon};
    margin-right: 24px;
    font-family: Blinker;
    line-height: 21px;
    font-weight: 600;
    white-space: nowrap;
  }
  span {
    display: flex;
    font-weight: 400;
    font-size: 12px;
  }
`;
export const StyledRadio = styled(Radio)`
  display: flex;
  align-items: center;
  margin-right: 16px;
  color: ${colors.gray};
`;
export const StyledRadioGroup = styled(FormGroup)`
  display: flex;
  flex-direction: row;
  font-size: 12px;
  color: ${colors.gray};
  font-weight: 400;
  align-items: center;
  margin-left: 15px;
  div:nth-child(1) {
    font-weight: 500;
  }
  label {
    margin-bottom: 0;

    line-height: 14px;
    font-family: Roboto, sans-serif;
    margin-right: 24px;
  }
`;
export const ApplyToAreaEquipTypeHeader = styled(FormSubHeader)`
  display: flex;
  align-items: center;
  margin-top: 48px;
  margin-bottom: 40px;
  white-space: nowrap;
  border-top: 1px solid ${colors.whiteGray};
  padding-top: 21px;
  padding-bottom: 21px;
  border-bottom: 1px solid ${colors.whiteGray};
  ${StyledRadioGroup} {
    margin-left: 24px;
  }
`;
export const StyledEmptyActivitiesContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
export const StyledEmptyActivitiesButtonsRow = styled.div`
  display: flex;
  justify-content: space-around;
  margin-bottom: 24px;
  button {
    padding: 9px 12px;
  }
`;
export const StyledEmptyActivitiesIcon = styled.svg`
  margin-bottom: 16px;
  margin-top: 16px;
`;
export const StyledEmptyActivitiesHeader = styled.h2`
  font-size: 18px;
  font-weight: 600;
  color: #aaaaaa;
  margin-bottom: 24px;
`;
export const StyledAddActivitiesButton = styled(Button)`
  font-weight: 500;
  svg {
    margin-right: 8px;
  }
`;
export const StyledAddActivityButton = styled.button`
  background: ${colors.primary};
  display: flex;
  align-items: center;
  padding: 9px 12px;
  margin-top: 24px;
  svg {
    margin-right: 8px;
  }
  border-radius: 6px;
  color: white;
`;

export const StyledEditActivityButton = StyledAddActivityButton;

export const StyledAddActivityForm = styled.form`
  width: 100%;
`;

export const StyledAddActivityFormModal = styled(ModalGeneric)`
  background: white;
  border-radius: 8px;
  display: flex;
  flex-wrap: wrap;
  height: auto;
  max-width: none;
  max-height: 94vh;
  align-content: flex-start;
  max-width: 746px;
  position: absolute;
  padding: 19px 24px 24px;
  top: 50%;
  transform: translate(0, -50%);
  margin: 0 auto;
`;
export const StyledEditActivityFormModal = StyledAddActivityFormModal;
export const StyledEditActivityForm = StyledAddActivityForm;
export const StyledAddActivityFormModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 20px;
  border-bottom: 1px solid ${colors.whiteGray};
  width: 100%;
  margin-bottom: 24px;
  h3 {
    color: ${colors.primary};
    font-size: 21px;
    font-family: 'Blinker';
    line-height: 25px;
    font-weight: 700;
  }
`;
export const StyledEditActivityFormModalHeader = StyledAddActivityFormModalHeader;
export const StyledCloseAddActivityFormModal = styled.div`
  position: absolute;
  top: 16px;
  right: 16px;
  cursor: pointer;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  &:hover {
    background: #eae8e5;
    path {
      stroke: #000;
    }
  }
  transition: all 300ms ease;
`;
export const StyledCloseEditActivityFormModal = StyledCloseAddActivityFormModal;
export const StyledAdvanceFirstFormButtonRow = styled.div`
  display: flex;
  margin-top: 32px;
  margin-bottom: 24px;
`;
export const StyledFormButtonsRow = styled.div`
  display: flex;
  margin-top: 24px;
  width: 550px;
`;
export const StyledReturnFormStepButton = styled(Button)`
  align-self: flex-start;
  background: white;
  color: ${colors.carbon};
  border: 1px solid ${colors.whiteGray};
  margin-right: 16px;
  svg {
    margin-right: 6px;
  }
`;
export const SubmitPlanInfoFormButton = styled(Button)`
  align-self: flex-start;
  svg {
    margin-left: 6px;
  }
`;
export const SchedulePlanButton = styled(Button)`
  align-self: flex-start;
  svg {
    margin-right: 6px;
  }
`;
export const FormApplicationStep1ButtonRow = styled.div``;
export const AdvanceApplicationStep1Button = styled(Button)`
  svg {
    margin-left: 6px;
  }
`;
export const StyledAdvancePlanInfoFormButton = styled(Button)`
  align-self: flex-start;
  svg {
    margin-left: 6px;
  }
`;
export const StyledNewPlanForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;
export const StyledCheckbox = styled(Checkbox)`
  span {
    width: 12px !important;
    height: 12px !important;
  }
`;
export const StyledOtherDaysConsideredContainer = styled.div`
  display: ${props => (props.isActive ? `flex` : `none`)};
  align-items: center;
  svg {
    width: 23px;
    height: 10px;
    margin-right: 24px;
  }
  p {
    font-size: 12px;
    line-height: 17px;
  }
`;
export const FrequencyInputsRow = styled.div`
  display: inline-flex;
  & > div:first-child {
    margin-right: 24px;
  }
`;
export const FrequencyInterval = styled.div`
  display: flex;
  margin-top: 17px;
  margin-right: ${props => (props.isEmpty ? '0' : '30px')};
  margin-left: ${props => (props.isEmpty ? '0' : '9px')};
  font-size: 12px;
  height: 21px;
  color: ${colors.gray};
  transition: all 300ms ease;
`;
export const StyledFormRow = styled.div`
  display: flex;
  align-items: flex-start;
  border-bottom: 1px solid ${colors.whiteGray};
  width: 100%;
  justify-content: space-between;
  padding-bottom: 24px;
  padding-top: 24px;
  label {
    font-size: 12px;
    line-height: 14px;
  }
`;
export const StyledCategoryFormRow = styled(StyledFormRow)`
  padding-bottom: 24px;
  padding-top: 24px;
`;
export const StyledMeasurementsFormRow = styled.div`
  display: ${({ isActive }) => (isActive ? 'flex' : 'none')};
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
  border-bottom: ${props => (props.isActive ? `1px solid ${colors.whiteGray}` : 'none')};
  margin-bottom: 0;
  div {
    margin-right: 0;
  }
  transition: all ease-in 300ms;
`;

export const StyledFormGroup = styled(FormGroup)`
  display: flex;
  flex-direction: column;
`;
export const StyledPlanInfoFormSection = styled.div`
  width: 550px;

  > div:not(:last-child) {
    margin-bottom: 24px;
  }
`;

export const StyledTextarea = styled(Textarea)`
  min-height: 80px;
  margin-top: 4px;
`;

export const StyledPlanNameInput = styled(Input)``;

export const StyledInlineFormGroup = styled(StyledFormGroup)`
  display: inline-flex;

  width: 272px;
`;
export const StyledCategoryFormGroup = styled(StyledFormGroup)`
  width: 210px;
`;
export const StyledStartDateFormGroup = styled(StyledFormGroup)`
  width: 200px;
`;
export const StyledPriorityFormGroup = styled(StyledFormGroup)`
  width: 72px;
`;

export const StyledTimeFormGroup = styled(StyledFormGroup)`
  width: 60px;
  margin-top: -14px;
`;
export const StyledAddMeasurementUnitButton = styled.div`
  border: none;
  display: flex;
  align-items: center;
  background: #fff;
  margin-top: 16px;
  font-size: 12px;
  margin-bottom: 24px;
  color: ${colors.primary};
  font-weight: 500;
  cursor: pointer;
  line-height: 14px;
  svg {
    margin-right: 8px;
  }
`;
export const StyledDateInput = styled(Input)`
  height: 47px;
  width: 180px;
  display: inline-flex;
  margin-right: 30px;
  flex-direction: column;
  input {
    flex-direction: row;
  }
`;
export const StyledDatePicker = styled(DatePicker)`
  height: 27px;
`;
export const StyledTimeInput = styled(TimeInput)`
  margin-right: 24px;
  display: inline-flex;
  flex-direction: column;

  input {
    flex-direction: row;
    margin-top: 4px;
  }
`;
export const StyledEstimatedTimeInput = styled(MaskedInput)`
  margin-right: 24px;
  display: inline-flex;
  flex-direction: column;
  input {
    margin-top: 4px;
    flex-direction: row;
  }
`;

export const StyledMeasurementUnitRow = styled.div`
  display: flex;
  position: relative;
  justify-content: space-between;
  width: 100%;
  border: 1px solid ${colors.whiteGray};
  border-top-width: ${props => (props.isFirst ? `1px` : 0)};
  border-radius: ${props => (props.isFirst ? `4px 4px 0 0` : props.isLast ? `0 0 4px 4px` : `0`)};
  padding: 16px;
`;
export const StyledDisabledSelect = styled.select`
  ${DefaultSelect}
  ${props => inputStyleFactory(props.size)};
`;

export const StyledMeasurementsFormGroup = styled(StyledFormGroup)`
  &:nth-child(1) {
    width: 200px;
  }
  width: 136px;
`;
export const StyledMeasurementsInput = styled(Input)`
  width: 136px;
`;

export const StyledConformityRadioGroup = styled(FormGroup)`
  display: flex;
  flex-direction: row;
  font-size: 12px;
  color: ${colors.gray};
  font-weight: 400;
  align-items: center;

  label {
    margin-bottom: 0;

    line-height: 14px;
    font-family: ${fonts.family.text};
    margin-right: 16px;
  }
  label:nth-child(1) {
    margin-right: 8px;
  }
  label:nth-child(2) {
    margin-left: 18px;
  }
`;
export const StyledMeasurementsRadioGroup = styled(StyledRadioGroup)`
  height: ${props => (props.isActive ? `27px` : 0)};
  margin-bottom: ${props => (props.isActive ? `16px` : 0)};
  margin-top: ${props => (props.isActive ? `24px` : 0)};
  overflow: hidden auto;
  span {
    margin-right: 16px;
  }
  transition: all 300ms ease;
`;
export const StyledMeasurementsContainer = styled.div`
  display: ${({ isActive }) => (isActive ? `flex` : `none`)};
  flex-direction: column;
  width: 100%;
  opacity: ${props => (props.isActive ? 1 : 0)};
  transition: all 300ms ease-in;
`;

export const StyledDeleteMeasurementUnit = styled(CloseGrayIcon)`
  display: flex;
  position: absolute;
  top: 9px;
  right: 9px;
  cursor: pointer;
`;

export const PseudoMultiSelect = styled(MultiSelect)`
  cursor: pointer;
  input {
    pointer-events: none;
  }
`;
export const StyledColumnContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 40px 0;
`;

export const PageBackdrop = styled.div`
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100vw;
  background: rgba(0, 0, 0, 1);
  z-index: ${props => (props.isActive ? '9' : '-4')};

  opacity: ${props => (props.isActive ? '0.3' : 0)};
  transition: opacity 600ms ease-in;
`;
export const StyledFormItem = styled.div`
  display: block;
`;

export const StyledFieldsColumn = styled.div`
  display: inline-grid;
  align-items: flex-end;
  grid-template-columns: repeat(${props => (props.width ? props.width : '1')}, 1fr);
  column-gap: 15px;
  width: 100%;
  margin-top: ${props => (props.marginTop ? props.marginTop : '0')};

  @media screen and (max-width: ${medias.mobile}) {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
  }
`;

export const StyledSwitchesRow = styled.div`
  display: flex;
  column-gap: 15px;
  margin-top: 30px;
`;

export const StyledSwitchAppend = styled.div`
  max-width: 456px;
  width: 100%;
  background-color: ${colors.pampas};
  padding: 0.625rem;
  border: 1px solid ${colors.whiteGray};
  border-radius: 0px 3px 3px 3px;
`;

export const StyledResponsibleFormContainer = styled.div`
  background: ${colors.pampas};
  padding: 16px 8px;
  margin-bottom: 8px;
  border-radius: 0 3px 3px 3px;
  border: 1px solid ${colors.whiteGray};
  position: relative;
`;
