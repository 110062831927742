import styled from 'styled-components';
import reportPreviewBgSvg from 'assets/icons/reports-preview-bg.svg';

export const BoxContainer = styled.div`
  background: #ffffff;
  border: 1px solid #e5e5e5;
  border-radius: 8px;
  height: 372px;
  width: 264px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  :hover {
    cursor: pointer;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.24);
  }
`;

export const KnowMoreLinkContainer = styled.div`
  width: 100%;
  button {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0;
    width: 45%;
    text-decoration: none;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    color: #888888;
    :hover {
      color: #f38a00;
      svg {
        path {
          fill: #f38a00;
        }
      }
    }
  }
`;

export const NewReport = styled.div`
  display: ${props => (props.new ? 'block' : 'none')};
  position: absolute;
  top: 0;
  left: 0;
`;
export const BoxContainerHeader = styled.div`
  background: url(${reportPreviewBgSvg}) no-repeat center/contain;
  width: 100%;
  height: 176px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;
export const PreviewImage = styled.img`
  width: 184px;
  height: 128px;
  border-radius: 2px;
`;
export const BoxContainerFooter = styled.div`
  width: 100%;
  padding: 0 16px 24px 16px;
  //height: 30px;
`;
export const BoxContainerMiddle = styled.div`
  padding: 0px 16px 0px 16px;
  height: 136px;
`;
export const TitleReport = styled.div`
  font-family: 'Blinker';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.5px;
  color: #1e1e1e;
  margin-bottom: 8px;
`;
export const DescriptionReport = styled.div`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.3px;
  color: #888888;
`;
