import React from 'react';
import { StyledApplicationTableUnitCell, StyledCellDescription } from './styled';
function ApplicationTableUnitCell(props) {
  return (
    <StyledApplicationTableUnitCell>
      <StyledCellDescription>
        {props.cell.value ? props.cell.value.label : 'Nenhum material selecionado'}
      </StyledCellDescription>
    </StyledApplicationTableUnitCell>
  );
}

export default ApplicationTableUnitCell;
