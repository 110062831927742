import { localStorageKeys } from 'constants/localStorage';
import { AuthService } from 'services/main';
import { getLocalAuthStorage } from './localStorage';
import jwt from 'jwt-decode';

export const refresh = async ({ token, refreshToken, mode }) => {
  const storage = getLocalAuthStorage();
  if (refreshToken === null) {
    // eslint-disable-next-line
    throw 'Expired';
  }
  if (mode === 'full' || Date.now() > jwt(token).exp * 1000) {
    return await AuthService.refreshToken({
      token: token,
      refreshToken: refreshToken,
    })
      .then(async res => {
        if (res.status && res.status === 200) {
          //console.log(19, res.data.authToken.token);
          storage.token = res.data.authToken.token;
          storage.refreshToken = res.data.authToken.refreshToken;
          storage.expiresIn = res.data.authToken.expiresIn;
          s; //torage.teste = 'teste';
          localStorage.setItem(localStorageKeys.authContext, JSON.stringify(storage));
          return res.data.authToken.token;
        } else {
          // eslint-disable-next-line
        return false;
        }
        // else {
        //   window.location.replace('/logout');
        //   return false;
        // }
      })
      .catch(() => false);
  } else {
    return token;
  }
};
