import React, { useMemo, useEffect } from 'react';
import { useTable, useSortBy, usePagination, useFilters, useGlobalFilter } from 'react-table';

import {
  StyledTableContainer,
  StyledTableHead,
  StyledTableRow,
  StyledTableHeader,
  StyledTableCell,
  StyledControlsContainer,
  StyledTableBody,
} from './styled';
import PaginationControls from '../PaginationControls';
import { ArrowToggle } from 'components/ArrowToggle';
// Define a default UI for filtering

export default function GeneralPlanApplication({
  columns,
  data,
  totalCount,
  setSortBy,
  setPageSize,
  setPageIndex,
  pageSize,
  pageIndex: _pageIndex,
}) {
  const pageCount = Math.ceil(totalCount / pageSize);
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    state: { sortBy },
    prepareRow,
  } = useTable(
    {
      columns,
      data,
      manualPagination: true,
      initialState: {
        pageSize: 5,
      },
      autoResetSortBy: false,
      autoResetPage: false,

      pageCount: pageCount,
    },
    useFilters, // useFilters!
    useGlobalFilter,
    useSortBy,
    usePagination,
  );
  useEffect(() => {
    setSortBy(sortBy);
  }, [sortBy, setSortBy]);
  const isPaginationContainerActive = useMemo(() => {
    if (totalCount > 5) {
      return true;
    }
  }, [totalCount]);

  if (data.length > 0) {
    return (
      <>
        <StyledControlsContainer>
          <PaginationControls
            isActive={isPaginationContainerActive}
            pageIndex={_pageIndex}
            totalCount={totalCount}
            pageCount={pageCount}
            setPageIndex={setPageIndex}
            setPageSize={setPageSize}
            pageSize={pageSize}
            itemsPerPageRange={[5, 10, 20, 50, 100]}
          />
        </StyledControlsContainer>
        {/* <StyledFiltersContainer isActive={isFiltersActive}>
        <StyledFormGroup label="Sistema">
          <StyledFilterSelect
            onChange={(e) => {
              setSystemFilter(e.target.value);
            }}
            size="small"
            data-cy="audit-report-unit-groups-select"
            placeholder="Seleção opcional"
            options={fields?.system}
            name="system"
            control={control}
            defaultValue=""
          />
        </StyledFormGroup>
        <StyledFormGroup label="Status">
          <StyledFilterSelect
            onChange={(e) => {
              setStatusFilter(e.target.value);
            }}
            size="small"
            data-cy="audit-report-unit-groups-select"
            placeholder="Seleção opcional"
            options={fields?.status}
            name="status"
            control={control}
            defaultValue=""
          />
        </StyledFormGroup>
        <StyledFormGroup label="Setor">
          <StyledFilterSelect
            onChange={(e) => {
              setSectorFilter(e.target.value);
            }}
            size="small"
            data-cy="audit-report-unit-groups-select"
            placeholder="Seleção opcional"
            options={fields?.sector}
            name="sector"
            control={control}
            defaultValue=""
          />
        </StyledFormGroup>
        <StyledFormGroup label="Aplicação">
          <StyledFilterSelect
            onChange={(e) => {
              setApplicationFilter(e.target.value);
            }}
            size="small"
            data-cy="audit-report-unit-groups-select"
            placeholder="Seleção opcional"
            options={fields?.application}
            name="application"
            control={control}
            defaultValue=""
          />
        </StyledFormGroup>
        <StyledClearFiltersButton>
          <ClearGrayIcon width={16} height={12} />
        </StyledClearFiltersButton>
      </StyledFiltersContainer> */}
        <StyledTableContainer {...getTableProps()}>
          <StyledTableHead>
            {headerGroups.map(headerGroup => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <StyledTableHeader
                    {...column.getHeaderProps(
                      column.disableSortBy !== true
                        ? column.getSortByToggleProps({
                            title: 'Ordenar por',
                          })
                        : column.getSortByToggleProps(),
                    )}
                  >
                    {column.render('Header')}

                    {column.disableSortBy !== true && (
                      <ArrowToggle
                        isSorted={column.isSorted}
                        isSortedDesc={column.isSortedDesc}
                        width="8"
                        height="10"
                        viewBox="0 0 8 10"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      />
                    )}
                  </StyledTableHeader>
                ))}
              </tr>
            ))}
          </StyledTableHead>
          <StyledTableBody {...getTableBodyProps()}>
            {page.map(row => {
              prepareRow(row);
              return (
                <StyledTableRow {...row.getRowProps()}>
                  {row.cells.map(cell => {
                    return <StyledTableCell {...cell.getCellProps()}>{cell.render('Cell')}</StyledTableCell>;
                  })}
                </StyledTableRow>
              );
            })}
          </StyledTableBody>
        </StyledTableContainer>
      </>
    );
  } else return <></>;
}
