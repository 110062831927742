/* istanbul ignore file */

import styled from 'styled-components';
import { colors } from 'assets/styled/tokens';
import { ReactComponent as TechnicianMarkerSvg } from '../../../../assets/technician-marker.svg';
import { ReactComponent as TechniciansMarkerSvg } from '../../../../assets/technicians-marker.svg';
import { StyledModalMarkerModal } from '../../styled';
import MapModalMarker from '../../MapModalMarker';
export const StyledTechnicianModalContainer = styled.div``;
export const StyledTechnicianMarker = styled(MapModalMarker)`
  cursor: pointer;
  z-index: 2;
`;

export const StyledTechnicianMarkerModal = styled(StyledModalMarkerModal)`
  max-height: 198px;
  overflow: ${({ isSingleTechnicianPin }) => (isSingleTechnicianPin ? 'hidden' : 'scroll')};
`;
export const StyledOfflineTechnicianMarkerIcon = styled.div`
  position: absolute;
  left: 0;
  display: flex;
  justify-content: center;
  width: 100%;
  svg {
    border: 1px solid #c0c0c0;
    border-radius: 100%;
    transform: scale(1.1666);
  }
  bottom: ${({ isSingleTechnicianPin }) => (isSingleTechnicianPin ? '-5px' : '-6px')};

  z-index: 4;
`;
export const StyledTechnicianMarkerSvg = styled(TechnicianMarkerSvg)`
  position: absolute;
  top: -4px;
  left: -4px;

  z-index: -2;
  display: ${({ shouldRender }) => (shouldRender ? 'block' : 'none')};
  path {
    fill: ${({ isOffline }) => (isOffline ? colors.gray : colors.primary)};
    stroke: rgba(0, 0, 0, 0.4);
    stroke-width: 1px;
  }
`;

export const StyledTechniciansMarkerSvg = styled(TechniciansMarkerSvg)`
  position: absolute;
  top: -9px;
  left: -3px;
  z-index: -2;
  path {
    fill: ${({ isOffline }) => (isOffline ? colors.gray : colors.primary)};
    stroke: rgba(0, 0, 0, 0.4);
    stroke-width: 1px;
  }
`;
export const StyledModalAvatarImage = styled.img`
  width: 30px;
  height: 30px;
  border-radius: 100%;
`;
