import React, { useState } from 'react';

import { FormGroup } from '../FormGroup';
import { StyledError } from '../FormGroup/styled';

import { StyledInput, StyledIcon, StyledPasswordEye } from './styled';

const Input = React.forwardRef(
  (
    { size = 'large', icon, type, error, onChange = () => {}, errormessage, isPassword, 'data-cy': dataCy, ...props },
    ref,
  ) => {
    const [isEyeOpen, setIsEyeOpen] = useState(false);
    const handleType = type => {
      if (isPassword && isEyeOpen) {
        return 'text';
      }
      return type;
    };
    return (
      <FormGroup {...props}>
        {isPassword && <StyledPasswordEye isEyeOpen={isEyeOpen} onClick={() => setIsEyeOpen(!isEyeOpen)} />}
        <StyledInput
          data-cy={dataCy}
          error={error}
          type={handleType(type)}
          ref={ref}
          size={size}
          onChange={onChange}
          spellcheck="false"
          {...props}
          maxLength={props.maxLength}
        />
        {icon && <StyledIcon src={icon} inputSize={size} />}
        <StyledError>
          <p>{error && errormessage}</p>
        </StyledError>
      </FormGroup>
    );
  },
);

export { Input };
