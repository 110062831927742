/* istanbul ignore file */

export const colors = {
  // lkp
  primary: '#f38a00',
  darkPrimary: '#db7c00',
  extraDarkPrimary: '#925300',

  // black
  black: '#000000',
  offBlack: '#010101',
  carbon: '#1e1e1e',

  // White
  white: '#ffffff',
  pampas: '#f5f3f1',
  gallery: '#ededed',
  alabaster: '#f9f9f9',

  // gray
  whiteGray: '#e5e5e5',
  lightGray: '#dddddd',
  mediumGray: '#aaaaaa',
  gray: '#888',
  mediumDarkGray: '#777',
  darkGray: '#666666',

  // light gray
  desertStorm: '#eeedeb',
  cararra: '#eae8e5',
  westar: '#e3ded9',
  pampas2: '#e9e4df',
  alto2: '#d0d0d0',
  silver: '#bbbbbb',
  silver2: '#c2c2c2',
  silver3: '#cccccc',

  // dark gray
  mineShaft: '#333333',
  tundora: '#444444',

  // orange
  linen: '#fcf4ea',
  serenade: '#fff3e7',
  caramel: '#ffd9b2',
  dairyCream: '#fce3c2',
  cream: '#fbdcbb',
  mangoTango: '#db7c00',
  cherokee: '#fdd59f',
  beige: '#eac18c',
  yellowOrange: '#ffaa38',
  lightbrown: '#fef3e5',
  richGold: '#a84c05',
  //red
  bittersweet: '#fe6e5b',
  cinnabar: '#e74c3c',
  darkRed: '#a6483c',
  // blue
  purpleBlue: '#5144d3',
  lightPurpleBlue: '#a8a1e9',
  darkPurpleBlue: '#31297f',
  // green
  caribbeanGreen: '#06c2a1',
  successGreen: '#00a889',
  darkGreen: '#00745f',

  //thirdparty
  whatsapp: `#10ae10`,

  // transparent
  transparent: 'transparent',
};

/* TODO: Set the colors of alerts in const ALERT_TYPE_COLORS */
export const ALERT_TYPE_COLORS = {
  default: {
    backgroundColor: colors.gallery,
    borderColor: colors.gallery,
    color: colors.gray,
  },
  success: {
    backgroundColor: colors.successGreen,
    borderColor: colors.successGreen,
    color: '#888',
  },
  error: {
    backgroundColor: '#fff',
    borderColor: '#888',
    color: '#888',
  },
  warn: {
    backgroundColor: '#fff',
    borderColor: '#888',
    color: '#888',
  },
  info: {
    backgroundColor: '#cfe2ff',
    borderColor: '#b6d4fe',
    color: '#084298',
  },
};
