import React from 'react';
import { StyledActivitiesTableFrequencyCell, StyledCellDescription } from './styled';
function ActivitiesTableFrequencyCell(props) {
  return (
    <StyledActivitiesTableFrequencyCell>
      <StyledCellDescription>{props.cell.value}</StyledCellDescription>
    </StyledActivitiesTableFrequencyCell>
  );
}

export default ActivitiesTableFrequencyCell;
