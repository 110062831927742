import { ApexOptions } from 'apexcharts';
import { CHART_DEFAULT_OPTIONS } from '../options';

export function getChartOptions(options = {}): ApexOptions {
  const { chart, yaxis, dataLabels, ...defaultOptions } = CHART_DEFAULT_OPTIONS;

  return {
    ...defaultOptions,
    chart: {
      ...chart,
      type: 'donut',
      toolbar: {
        show: false,
      },
    },
    yaxis: {
      ...yaxis,
      labels: {
        formatter: function (value) {
          return `${value}`;
        },
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '55%',
      },
    },
    dataLabels: {
      ...dataLabels,
      formatter: function (_, opt) {
        const value = opt.w.config.series[opt.seriesIndex];
        return `${value}`;
      },
    },
    tooltip: {
      y: {
        formatter: function (value) {
          return `${value}`;
        },
      },
    },
    ...options,
  };
}

export function getLineChartOptions(categories: Array<number | string> = [], options = {}): ApexOptions {
  const { yaxis, dataLabels, ...defaultOptions } = CHART_DEFAULT_OPTIONS;

  return {
    ...defaultOptions,
    xaxis: { categories },
    stroke: {
      curve: 'straight',
    },
    grid: {
      row: {
        colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
        opacity: 0.5,
      },
    },
    yaxis: {
      ...yaxis,
      // labels: {
      //   formatter: function (value) {
      //     return `${value}`;
      //   },
      // },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '55%',
      },
    },
    colors: ['#00C0C7'],
    dataLabels: {
      ...dataLabels,
      // formatter: function (_, opt) {
      //   const value = opt.w.config.series[opt.seriesIndex];
      //   return `${value}`;
      // },
    },
    tooltip: {
      y: {
        formatter: function (value) {
          return `${value}`;
        },
      },
    },
    ...options,
  };
}
