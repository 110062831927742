import { Select } from 'components/Form/subcomponents/Select';
import styled from 'styled-components';
import { medias } from 'assets/styled/tokens';
import selectIcon from 'assets/icons/dropdown-select-icon.svg';
export const MenuContainer = styled.div`
  position: relative;
  width: 100%;
  @media (min-width: ${medias.desktopSm}) {
    display: none;
    width: 100vw;
  }
`;
export const CompanySelect = styled(Select)`
  border: 1px solid #ffffff;
  height: 32px;
  width: 200px;
  border-radius: 4px;
  input {
    background: url(${selectIcon}) right 10px center / 8px 4px no-repeat, #1e1e1e;
    color: #ffffff;
  }
  ul {
    margin-top: 32px;
    background-color: #1e1e1e;
    border: 1px solid #ffffff;
    border-radius: 0 0 4px 4px;
    section {
      li {
        &:hover {
          background-color: #1e1e1e;
        }
        label {
          color: #ffffff;
        }
      }
    }
  }
`;
export const DropdownButton = styled.button`
  border: 1px solid #ffffff;
  background-color: #1e1e1e;
  height: 32px;
  width: 32px;
  position: absolute;
  color: #ffffff;
  font-size: 20px;
  letter-spacing: 2px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  line-height: 7px;
  align-items: center;
  border-radius: 4px;
  right: 0px;
  top: calc(-530% - 32px);
  padding: 4px;
`;

export const DropDownMenu = styled.div`
  width: 179px;
  height: 141px;
  background: #f5f3f1;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 0.5px;
  text-align: left;
  color: #000000;
  position: absolute;
  border-radius: 5px;
  padding: 18px;
  right: 0;
  top: calc(-89% - 129px);
  display: ${props => (props.isOpen ? 'flex' : 'none')};
  flex-direction: column;
  justify-content: space-between;
  svg {
  }
`;
export const MenuItem = styled.button`
  text-transform: uppercase;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 0.5px;
  text-align: left;
  color: #000000;
  border: none;
  padding: 0;
  height: 33%;
`;
