import React from 'react';

import { Header } from 'components/Header';
import { HeaderFilters } from 'components/HeaderFilters';

import { StyledPage, StyledChildren } from './styled';

const HeaderFiltersPage = ({ children, monitoringPage, noMargin = false, isSubMenuOpen = false }) => {
  const headerFiltersRef = React.createRef(null);
  return (
    <StyledPage noMargin={noMargin} monitoringPage={monitoringPage} isSubMenuOpen={isSubMenuOpen}>
      <Header>
        <HeaderFilters ref={headerFiltersRef} />
      </Header>
      <StyledChildren monitoringPage={monitoringPage}>{children}</StyledChildren>
    </StyledPage>
  );
};

export { HeaderFiltersPage };
