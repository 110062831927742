import React from 'react';
import {
  StyledTechniciansViewContainer,
  StyledTechniciansViewControls,
  StyledSearchTechniciansInput,
  //StyledExcelRoutesDownloadButton,
  StyledTechniciansViewList,
  StyledOnlineTechnicians,
  StyledOfflineTechnicians,
  StyledArrowLeft,
  StyledBackButtonContainer,
  StyledTeamName,
} from './styled';
import MagnifyingIcon from 'assets/icons/magnifying.svg';
//import { ReactComponent as DownloadIcon } from 'assets/icons/download.svg';
import { ReactComponent as ArrowLeft } from 'assets/icons/arrow-small.svg';
import ViewLoadingContainer from '../ViewLoadingContainer';
import { useGeolocalizeStore } from 'pages/Geolocalize/store';

import TechnicianCard from './TechnicianCard';

import ToggleOnMapButton from '../ToggleOnMapButton';
import {
  //TOGGLE_ROUTES_REPORT_MODAL,
  // TOGGLE_IS_ONLINE_TECHNICIANS_HIDDEN,
  TOGGLE_IS_OFFLINE_TECHNICIANS_HIDDEN,
  UPDATE_TECHNICIAN_MARKERS_SEARCH_TERM,
  HIDE_TEAM_DETAILS,
} from 'pages/Geolocalize/constants';
import { getFilteredTechnicians } from 'pages/Geolocalize/utils';
import { StyledTechniciansCount } from 'pages/Geolocalize/styled';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const TechniciansView = ({ showTeamDetails }) => {
  const [state, dispatch] = useGeolocalizeStore();
  const technicianMarkersState = state.technicianMarkers;
  const techniciansMarkers = technicianMarkersState.data;

  // Teams
  const teamDetailsMarkersState = state.teamMarkers.teamDetails.teamData;
  const teamDetails = [{ ...teamDetailsMarkersState }];

  const currentTechniciansToShow = showTeamDetails ? teamDetails : techniciansMarkers;

  if (technicianMarkersState.isFetching) {
    return <ViewLoadingContainer />;
  }

  const technicians = [];

  currentTechniciansToShow.forEach(marker => {
    let techniciansArr = [];

    marker.technicians.forEach(technician => {
      if (marker.latitude && marker.longitude) {
        techniciansArr = [
          ...techniciansArr,
          {
            ...technician,
            latitude: marker.latitude,
            longitude: marker.longitude,
          },
        ];
      } else {
        techniciansArr = [...techniciansArr, { ...technician }];
      }
    });

    technicians.push(...techniciansArr);
  });

  const filteredTechnicians = getFilteredTechnicians({ technicians, searchTerm: technicianMarkersState.searchTerm });

  const onlineTechnicians = filteredTechnicians.filter(technician => technician.status === 'online');
  const offlineTechnicians = filteredTechnicians.filter(technician => technician.status === 'offline');
  return (
    <StyledTechniciansViewContainer>
      {showTeamDetails && (
        <StyledBackButtonContainer>
          <StyledArrowLeft onClick={() => dispatch({ type: HIDE_TEAM_DETAILS })}>
            <ArrowLeft />
          </StyledArrowLeft>
          <StyledTeamName>{teamDetailsMarkersState.name}</StyledTeamName>
        </StyledBackButtonContainer>
      )}
      <StyledTechniciansViewControls>
        {/* <StyledExcelRoutesDownloadButton onClick={() => dispatch({ type: TOGGLE_ROUTES_REPORT_MODAL })}>
          <DownloadIcon />
          <span>Relatório de rotas</span>
        </StyledExcelRoutesDownloadButton> */}
        <StyledSearchTechniciansInput
          type="text"
          autoComplete="off"
          name="search"
          size="small"
          placeholder="Buscar técnico"
          icon={MagnifyingIcon}
          dataCy={`search-input}`}
          onChange={e => {
            e.persist();
            // filter active technicians
            const searchTerm = e.target.value;
            dispatch({ type: UPDATE_TECHNICIAN_MARKERS_SEARCH_TERM, payload: searchTerm });

            // getFilteredTechnicians(e.target.value);
          }}
        />
      </StyledTechniciansViewControls>

      <StyledTechniciansViewList>
        <StyledOnlineTechnicians>
          <h4>
            <span>
              <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="6" cy="6" r="6" fill="#F38A00" />
              </svg>
              <StyledTechniciansCount>Online ({onlineTechnicians.length})</StyledTechniciansCount>{' '}
            </span>
            {/* {!showTeamDetails && onlineTechnicians.length > 0 && (
              <ToggleOnMapButton
                isTooltipVisible={false}
                handleToggle={() => dispatch({ type: TOGGLE_IS_ONLINE_TECHNICIANS_HIDDEN })}
                isHidden={technicianMarkersState.isOnlineHidden}
              />
            )} */}
          </h4>
          {onlineTechnicians.map(technician => (
            <TechnicianCard technician={technician} />
          ))}
        </StyledOnlineTechnicians>
        <StyledOfflineTechnicians>
          <h4>
            <span>
              <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="6" cy="6" r="6" fill="#888888" />
                <rect x="3" y="5" width="6" height="2" fill="white" />
              </svg>
              <StyledTechniciansCount>Offline ({offlineTechnicians.length})</StyledTechniciansCount>
            </span>
            {!showTeamDetails && offlineTechnicians.length > 0 && (
              <ToggleOnMapButton
                handleToggle={() => dispatch({ type: TOGGLE_IS_OFFLINE_TECHNICIANS_HIDDEN })}
                isHidden={technicianMarkersState.isOfflineHidden}
              />
            )}
          </h4>
          {offlineTechnicians.map(technician => (
            <TechnicianCard technician={technician} />
          ))}
        </StyledOfflineTechnicians>
      </StyledTechniciansViewList>
    </StyledTechniciansViewContainer>
  );
};

export default TechniciansView;
