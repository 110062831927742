import { css } from 'styled-components';

import { fonts, colors } from 'assets/styled/tokens';

import selectDropdown from 'assets/icons/select-dropdown.svg';
import { textSizeFactory } from 'utils/helpers/css';

const field = css`
  display: block;
  width: 100%;
  padding: 0.35rem 0.85rem;
  font-size: 12px;
  line-height: 16px;
  font-family: ${fonts.family.text};
  border: 1px solid ${colors.lightGray};
  border-radius: 3px;
  background-color: white;
  color: ${props => props.disabled && colors.mediumGray};

  margin-top: 0;
`;

export const DefaultForm = css`
  * {
    font-family: ${fonts.family.text};
  }
`;

export const DefaultRow = css`
  position: relative;
`;

export const DefaultLabel = css`
  display: block;
  font-size: 12px;
  line-height: 16px;
  font-weight: 500;
  color: ${colors.carbon};
  margin-bottom: 4px;
`;

export const DefaultInput = css`
  ${field};
  background: ${props => props.disabled && colors.pampas};
  ${props => textSizeFactory(props.size)};

  &:active,
  &:focus {
    border-color: ${colors.gray} !important;
  }
`;

export const DefaultTextarea = css`
  ${field};
  background: ${props => props.disabled && colors.pampas};
  resize: ${props => (props.disabled ? `none` : `vertical`)};

  overflow: hidden;
`;

export const DefaultSelect = css`
  ${field};
  padding-right: 26px;
  appearance: none;
  background: ${props => `url(${selectDropdown}), ${props.disabled ? colors.pampas : 'white'}`};
  background-size: ${({ dropdownDisabled }) => (dropdownDisabled ? '0' : '8px 4px')};
  background-position: right 10px center;
  background-repeat: no-repeat;

  &:active,
  &:focus {
    border-color: ${colors.gray};
  }
`;
