import styled from 'styled-components';

export const StyledCleanInputPosition = styled.div`
  display: flex;
  position: absolute;
  right: 17px;
  top: 50%;
  transform: translate(0, -50%);
  padding: 0px;
  cursor: pointer;
  height: 12px;
  width: 12px;
  svg {
    margin-bottom: auto;
    margin-top: auto;
    right: 3px;
    position: relative;
  }
`;
