import { AxiosGlobalFilters } from '../config';
const NotificationConfig = {
  getConfig: ({ EmpresaId, UnidadeId }) => {
    return AxiosGlobalFilters({
      method: 'get',
      url: 'v1/configuracoes/push',
      headers: {
        'content-type': 'application/json',

        EmpresaId,
        UnidadeId,
      },
    });
  },
  pushconfig: ({ EmpresaId, configCorrecao, configOcorrencia, UnidadeId }) => {
    return AxiosGlobalFilters({
      method: 'put',
      url: 'v1/configuracoes/push',
      data: {
        empresaId: EmpresaId,
        notificacoesAdminOcorrencia: false,
        notificacaoAdminCorrececao: false,
        notificacaoOperacionalOcorrencia: configOcorrencia,
        notificacaoOperacionalCorrecao: configCorrecao,
      },
      headers: {
        'content-type': 'application/json',

        EmpresaId,
        UnidadeId,
      },
    });
  },
};

export { NotificationConfig };
