import styled from 'styled-components';

import { Link } from 'react-router-dom';
import { colors, devices, fonts } from 'assets/styled/tokens';
import { Button } from 'components';
export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #e5e5e5;
  /* @media ${devices.tabletLandscape} {
    transform: translateY(-60px);
  } */
`;
export const ButtonHome = styled(Button)`
  background-color: #fff;
  border: 1px solid #e5e5e5;
`;
export const StyledImg = styled.img`
  display: flex;
  width: 105px;
  height: 98px;
  //margin-top: auto;
  margin-bottom: 5px;
  align-content: center;
`;

export const StyledError = styled.div`
  font-family: ${fonts.family.title};
  font-weight: 800;
  font-size: 21px;
  font-style: normal;
  line-height: 24px;
  color: ${colors.primary};
`;

export const StyledErrorMessage = styled.div`
  margin-top: 25px;
  font-family: ${fonts.family.title};
  font-weight: 700;
  width: 450px;
  font-style: normal;
  font-size: 24px;
  line-height: 29.26px;
  text-align: center;
  color: ${colors.tundora};
`;
export const StyledLinkIn = styled(Link)`
  text-decoration: none;
  color: ${colors.black};
  font-family: Roboto;
  font-size: 14px;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 0.5px;
  text-align: left;
`;
export const StyledErrorMessageSub = styled.div`
  width: 325px;
  margin-top: 7px;
  margin-bottom: 46px;
  font-family: ${fonts.family.text};
  font-weight: 400;
  font-style: normal;
  font-size: 18px;
  line-height: 21.09px;
  text-align: center;
  color: ${colors.gray};
  a {
    color: ${colors.primary};
    text-decoration: underline;
  }

  @media screen and (min-width: 768px) {
    width: 850px;
  }
`;
