import styled from 'styled-components';
import { colors } from 'assets/styled/tokens';
import { strongTextCss } from 'utils/helpers/css';

export const StyledInputTooltip = styled.div`
  transform: ${({ isLarge }) => (isLarge ? 'translate(2px, -26px)' : 'translate(-4px, -26px)')};
`;
export const StyledToggleOnMapButtonWrapper = styled.div`
  position: relative;
  ${StyledInputTooltip} {
    display: none;
  }
  &:hover {
    ${StyledInputTooltip} {
      position: absolute;
      display: block;
      left: -80px;
      font-weight: 500;
      span {
        width: 142px;
        height: auto !important;
      }
    }
  }
`;
export const ToggleButton = styled.button`
  ${strongTextCss}
  color: ${colors.gray};
  svg {
    margin-right: 8px;
  }
`;
