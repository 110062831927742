import React from 'react';
import { StyledInfoLink } from './styled';
import { PropTypes } from 'prop-types';

const InfoLink = ({ link, text }) => {
  return (
    <StyledInfoLink target="_blank" href={link}>
      <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M6 0C2.68548 0 0 2.70968 0 6C0 9.31452 2.68548 12 6 12C9.29032 12 12 9.31452 12 6C12 2.70968 9.29032 0 6 0ZM6 2.66129C6.55645 2.66129 7.01613 3.12097 7.01613 3.67742C7.01613 4.25806 6.55645 4.69355 6 4.69355C5.41935 4.69355 4.98387 4.25806 4.98387 3.67742C4.98387 3.12097 5.41935 2.66129 6 2.66129ZM7.35484 8.80645C7.35484 8.97581 7.20968 9.09677 7.06452 9.09677H4.93548C4.76613 9.09677 4.64516 8.97581 4.64516 8.80645V8.22581C4.64516 8.08064 4.76613 7.93548 4.93548 7.93548H5.22581V6.3871H4.93548C4.76613 6.3871 4.64516 6.26613 4.64516 6.09677V5.51613C4.64516 5.37097 4.76613 5.22581 4.93548 5.22581H6.48387C6.62903 5.22581 6.77419 5.37097 6.77419 5.51613V7.93548H7.06452C7.20968 7.93548 7.35484 8.08064 7.35484 8.22581V8.80645Z"
          fill="#3A97D4"
        />
      </svg>
      <span>{text}</span>

      <svg width="11" height="10" viewBox="0 0 11 10" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M11 0.46875C11 0.214844 10.7899 0 10.5417 0H8.09722C7.67708 0 7.46701 0.507812 7.77257 0.800781L8.44097 1.50391L3.80035 6.26953C3.60938 6.44531 3.60938 6.73828 3.80035 6.93359L4.22049 7.36328C4.41146 7.55859 4.69792 7.55859 4.86979 7.36328L9.52951 2.61719L10.217 3.30078C10.5035 3.59375 11 3.39844 11 2.96875V0.46875ZM7.77257 5.29297L7.46701 5.60547C7.39062 5.68359 7.33333 5.83984 7.33333 5.9375V8.75H1.22222V2.5H6.26389C6.35938 2.5 6.51215 2.44141 6.56944 2.36328L6.875 2.05078C7.18056 1.75781 6.97049 1.25 6.56944 1.25H0.916667C0.401042 1.25 0 1.67969 0 2.1875V9.0625C0 9.58984 0.401042 10 0.916667 10H7.63889C8.13542 10 8.55556 9.58984 8.55556 9.0625V5.625C8.55556 5.21484 8.05903 5 7.77257 5.29297Z"
          fill="#3A97D4"
        />
      </svg>
    </StyledInfoLink>
  );
};
InfoLink.propTypes = {
  link: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};
export { InfoLink };
