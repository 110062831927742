import { AxiosRequestConfig, AxiosRequestHeaders } from 'axios';
import { BaseUrlsKeys, api } from './http';

export async function get<T>(
  path: string,
  baseUrlKey: BaseUrlsKeys = 'mock',
  config: AxiosRequestConfig = {},
  headers: AxiosRequestHeaders = {},
) {
  const http = api(baseUrlKey, headers);
  const { data } = await http.get<T>(path, { ...config }).catch(error => {
    return error;
  });
  return data;
}
