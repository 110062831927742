import { AxiosGlobalFilters } from '../config';
const HomeService = {
  getHomeDashAtividades: ({ unidade, empresa, Month, Year, SubGrupoUnidadeId, GrupoUnidadeId }) => {
    return AxiosGlobalFilters({
      method: 'GET',
      url: '/v1/reports/summary/activities',
      params: {
        Month,
        Year,
        SubGrupoUnidadeId,
        GrupoUnidadeId,
      },
      headers: {
        'content-type': 'application/json',

        EmpresaId: empresa,
        UnidadeId: unidade !== undefined ? unidade : '',
      },
    });
  },
  getHomeDashOcorrencias: ({ unidade, empresa, Month, Year, SubGrupoUnidadeId, GrupoUnidadeId }) => {
    return AxiosGlobalFilters({
      method: 'GET',
      url: '/v1/reports/summary/occurrences',
      params: {
        Month,
        Year,
        SubGrupoUnidadeId,
        GrupoUnidadeId,
      },
      headers: {
        'content-type': 'application/json',

        EmpresaId: empresa,
        UnidadeId: unidade !== undefined ? unidade : '',
      },
    });
  },
};

export { HomeService };
