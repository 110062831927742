import { StyledAvatarUserInitials, StyledExtraTechniciansCount } from 'pages/Geolocalize/styled';
import React from 'react';
import { formatDateTime } from 'utils/format';

import { StyledHorizontalStackedAvatars, StyledHorizontalStackedInitials, StyledTechnicianPins } from '../styled';
import {
  StyledTechnicianMarker,
  StyledTechnicianMarkerSvg,
  StyledTechniciansMarkerSvg,
  StyledOfflineTechnicianMarkerIcon,
} from './styled';
import { getIsEveryTechnicianOffline, getNameInitials } from 'pages/Geolocalize/utils';
import TechnicianModalContent from './TechnicianModalContent';
import { ReactComponent as OfflineIcon } from '../../../../assets/offline-circle.svg';

export const getTechniciansLatestDateTime = technicians => {
  // const currentCenturyPrepend = '20';
  const dates = technicians.map(technician => {
    const day = technician.date.split('/')[0];
    const month = technician.date.split('/')[1];
    const year = technician.date.split('/')[2];
    const date = new Date(`${year}-${month}-${day}T${technician.time}:00Z`);
    const userTimezoneOffset = date.getTimezoneOffset() * 60000;
    return new Date(date.getTime() + userTimezoneOffset);
  });

  const maximumDate = new Date(Math.max.apply(null, dates));
  const formattedMaxDateTime = formatDateTime(maximumDate);
  const maxDateTimeSplitted = formatDateTime(maximumDate).split(' ');
  const fomattedMaxDate = maxDateTimeSplitted[2];
  const fomattedMaxTime = maxDateTimeSplitted[0];

  return { formatted: formattedMaxDateTime, date: fomattedMaxDate, time: fomattedMaxTime };
};

export default function TechnicianModalMarker({ marker }) {
  const isSingleTechnicianPin = marker.technicians.length === 1;
  const singleTechnician = marker.technicians[0];
  const latestLocalizationDateTime = getTechniciansLatestDateTime(marker.technicians);
  const isEveryTechnicianOffline = getIsEveryTechnicianOffline(marker);
  const latestLocalization = isEveryTechnicianOffline
    ? latestLocalizationDateTime.formatted
    : latestLocalizationDateTime.time;
  const isDoubleTechnicianPin = marker.technicians?.length === 2;
  const isMultipleTechniciansActive = marker.technicians?.length > 2;
  const horizontalStackedAvatars = marker.technicians.map((technician, i) => {
    if (technician.insideUnit) return null;
    if (isSingleTechnicianPin && !singleTechnician.image) {
      return (
        <StyledAvatarUserInitials key={i} isOffline={isEveryTechnicianOffline}>
          {getNameInitials(technician.name)}
        </StyledAvatarUserInitials>
      );
    } else if (i < 2) {
      return technician.image ? (
        <StyledHorizontalStackedAvatars key={i} isOffline={technician.status === 'offline'} src={technician.image} />
      ) : (
        <StyledHorizontalStackedInitials isOffline={technician.status === 'offline'}>
          {getNameInitials(technician.name)}
        </StyledHorizontalStackedInitials>
      );
    } else {
      return null;
    }
  });
  return (
    <StyledTechnicianMarker
      marker={marker}
      key={marker.id}
      modalTitle={'TÉCNICO'}
      type={isEveryTechnicianOffline ? 'offline-technician' : 'technicians'}
      pinComponent={
        <>
          <StyledTechnicianPins
            isSingleTechnicianPin={isSingleTechnicianPin}
            isDoubleTechnicianPin={isDoubleTechnicianPin}
          >
            {horizontalStackedAvatars.filter((pinImage, i) => {
              return i < 3 && pinImage;
            })}
            {isMultipleTechniciansActive && (
              <StyledExtraTechniciansCount>+{horizontalStackedAvatars.length - 2}</StyledExtraTechniciansCount>
            )}
          </StyledTechnicianPins>
          {isSingleTechnicianPin ? (
            <StyledTechnicianMarkerSvg
              shouldRender={!singleTechnician.insideUnit}
              isOffline={isEveryTechnicianOffline}
            />
          ) : (
            <StyledTechniciansMarkerSvg isOffline={isEveryTechnicianOffline} />
          )}
          {isEveryTechnicianOffline && !isMultipleTechniciansActive && (
            <StyledOfflineTechnicianMarkerIcon isSingleTechnicianPin={isSingleTechnicianPin}>
              <OfflineIcon />
            </StyledOfflineTechnicianMarkerIcon>
          )}
          {/* {technicianPinImages.length === 1 && (
            <StyledTechnicianOfflineMarkerSvg
            isOffline={isEveryTechnicianOffline}
          />
          )} */}
        </>
      }
      modalComponent={
        <TechnicianModalContent
          marker={marker}
          isSingleTechnicianPin={isSingleTechnicianPin}
          singleTechnician={singleTechnician}
          isEveryTechnicianOffline={isEveryTechnicianOffline}
          latestLocalization={latestLocalization}
        />
      }
    />
  );
}
