import React, { useState, useEffect, useRef, useContext } from 'react';

import * as Sentry from '@sentry/react';

import { useHistory } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';

import { FiltersContext } from 'context/GlobalFiltersContext';
import { GlobalComponentsContext } from 'context/GlobalComponentsContext';
import { AuthContext } from 'context/AuthContext';

import { HeaderFiltersPage } from 'containers/HeaderFiltersPage';

//import { Menu } from 'components/Menu';
import { MobileNavbar } from 'components/MobileNavbar';
import { PageWrapper } from 'components/PageWrapper';

import { Toast } from 'components/Toast';
import { Preview } from 'components/Preview';
import { Loading } from 'components/Loading';
import { FilterModal } from 'components/FilterModal';

import { FieldRow } from 'components/Form/subcomponents/FieldRow';
import { FormGroup } from 'components/Form/subcomponents/FormGroup';
import { DateRangePicker } from 'components/Form/subcomponents/DateRangePicker';
import { Select } from 'components/Form/subcomponents/Select';
import { Switch } from 'components/Form/subcomponents/Switch';
import MultiSelect from 'components/Form/subcomponents/MultiSelect';
import { Radio } from 'components/Form/subcomponents/Radio';

import { ReportContainer } from 'components/ReportContainer';
import { ReportContainerFillFields } from 'components/ReportContainer/subcomponents/ReportContainerFillFields';
import { ReportContainerFields } from 'components/ReportContainer/subcomponents/ReportContainerFields';
import { ReportContainerColumn } from 'components/ReportContainer/subcomponents/ReportContainerColumn';
import { LineSeparator } from 'components/ReportContainer/subcomponents/LineSeparator';

import { AuditService, SavedFiltersService, FiltersService } from 'services/reports';

import { createGenericErrorToast, createToast } from 'utils/createToast';
import { formatDateRangeValue } from 'utils/formatDateRangeValue';

import { colors } from 'assets/styled/tokens';

import { StyledFieldsColumn, StyledSwitchesRow, StyledFormGroup, StyledFormRadio } from './styled';
import { InvisibleDownloadLink } from 'components/InvisibleDowloadLink';
import { NewMenu } from 'components/Menu/NewMenu';

const reportName = 'audit';

const Audit = ({ pathname }) => {
  const { authContext } = useContext(AuthContext);
  const { user } = authContext;
  const { globalComponentsContext, setGlobalComponentsContext } = useContext(GlobalComponentsContext);
  const { isLoading } = globalComponentsContext;

  const { headerFiltersContext } = useContext(FiltersContext);
  const { headerFilters } = headerFiltersContext;

  const history = useHistory();

  const { handleSubmit, register, setValue, watch, getValues, control } = useForm();

  const [isFirstMount, setIsFirstMount] = useState(true);
  const [toastList, setToastList] = useState([]);
  const [fields, setFields] = useState({
    unitGroups: [],
    unitSubgroups: [],
    units: [],
    areas: [],
    responsibles: [],
    systems: [],
    audits: [],
    status: [],
  });

  const [currentSavedFilter, setCurrentSavedFilter] = useState('');
  const [showOccurrencesData, setShowOccurrencesData] = useState(false);

  const [modalOptions, setModalOptions] = useState({
    open: false,
    propertyKey: null,
  });

  const downloadLinkElement = useRef(null);
  const [downloadReport, setDownloadReport] = useState({
    name: '',
    href: '',
  });

  const goback = () => history.goBack();

  const formatPayload = values => {
    const realizedPeriodRange = formatDateRangeValue(values?.realizedPeriod);

    const scheduledPeriodRange = formatDateRangeValue(values?.scheduledPeriod);
    const unitName = fields.units[0].label;

    const { areas, audits } = values;

    return {
      ...values,
      realizedPeriodStart: realizedPeriodRange?.start,
      realizedPeriodEnd: realizedPeriodRange?.end,
      scheduledPeriodStart: scheduledPeriodRange?.start,
      scheduledPeriodEnd: scheduledPeriodRange?.end,
      areas: areas && areas.map(area => Number(area)),
      audits: audits && audits.map(audit => Number(audit)),
      company: headerFilters?.companies?.name,
      companyId: headerFilters?.companies?.id,
      unitName: unitName,
    };
  };

  const validateRequiredFields = () => {
    if (!getValues('realizedPeriod')) {
      setToastList(prevState => [
        ...prevState,

        createToast({
          type: 'error',

          message: 'Por favor preencha ao menos um periodo',
        }),
      ]);

      return true;
    }
    if (getValues('showCompare') && !getValues('scheduledPeriod')) {
      setToastList(prevState => [
        ...prevState,
        createToast({
          type: 'error',
          message: 'Por favor preencha as datas do período programado',
        }),
      ]);
      return true;
    }

    return false;
  };

  const onSubmit = values => {
    const hasError = validateRequiredFields();

    if (hasError) {
      return;
    }

    const payload = formatPayload(values);

    setToastList(prevState => [
      ...prevState,
      createToast({
        type: 'loading',
        message: 'Gerando arquivo...',
      }),
    ]);

    if (watch('reportType') === 'pdf') {
      return generatePDF(payload);
    }

    return generateExcel(payload);
  };

  const applySavedFilter = data => {
    const {
      unit: savedUnit,
      unitGroup: savedUnitGroup,
      unitSubgroup: savedUnitSubgroup,
      reportType: savedReportType,
      showPicture: savedShowPicture,
      showAuditOccurrencePicture: savedShowAuditOccurrencePicture,
      showSignature: savedShowSignature,
      showCompare: savedShowCompare,
      showAuditOccurrence: savedShowOccurrenceAudit,
      realizedPeriodStart: savedRealizedPeriodStart,
      realizedPeriodEnd: savedRealizedPeriodEnd,
      scheduledPeriodStart: savedScheduledPeriodStart,
      scheduledPeriodEnd: savedScheduledPeriodEnd,
      audits: savedAudits,
      status: savedStatus,
      areas: savedAreas,
      systems: savedSystems,
      responsibles: savedResponsibles,
    } = data;

    if (savedUnit) {
      setValue('unit', savedUnit);
    }

    if (savedUnitGroup) {
      setValue('unitGroup', savedUnitGroup);
    }

    if (savedUnitSubgroup) {
      setValue('unitSubgroup', savedUnitSubgroup);
    }

    if (savedStatus) {
      setValue('status', true);
    }

    if (savedReportType) {
      setValue('reportType', savedReportType);
    }

    if (savedShowPicture) {
      setValue('showPicture', true);
    }

    if (savedShowAuditOccurrencePicture) {
      setValue('showAuditOccurrencePicture', true);
    }

    if (savedShowSignature) {
      setValue('showSignature', true);
    }

    if (savedShowCompare) {
      setValue('showCompare', true);
    }

    if (savedShowOccurrenceAudit) {
      setValue('showAuditOccurrence', true);
      setShowOccurrencesData(!showOccurrencesData);
    }

    if (savedRealizedPeriodStart && savedRealizedPeriodEnd) {
      setValue('realizedPeriod', savedRealizedPeriodStart + '/' + savedRealizedPeriodEnd);
    }

    if (savedScheduledPeriodStart && savedScheduledPeriodEnd) {
      setValue('scheduledPeriod', savedScheduledPeriodStart + '/' + savedScheduledPeriodEnd);
    }

    if (savedAreas) {
      setValue('areas', savedAreas);
    }

    if (savedSystems) {
      setValue('areas', savedSystems);
    }

    if (savedResponsibles) {
      setValue('savedResponsibles', savedResponsibles);
    }

    if (savedAudits) {
      setValue('audits', savedAudits);
    }
  };

  const cleanFields = () => {
    setValue('realizedPeriod', '');

    setValue('scheduledPeriod', '');

    setValue('status', false);

    setValue('reportValue', 'pdf');

    setValue('showPicture', false);

    setValue('showAuditOccurrencePicture', false);

    setValue('showSignature', false);

    setValue('showCompare', false);

    setValue('showAuditOccurrence', false);

    setShowOccurrencesData(false);

    setValue('areas', '');

    setValue('systems', '');

    setValue('responsibles', '');

    setValue('audits', '');

    setValue('unit', '');

    setValue('unitGroup', '');

    setValue('unitSubgroup', '');

    setCurrentSavedFilter('');

    fetchData();
  };
  const updateUnitGroup = () => {
    setValue('areas', '');

    setValue('systems', '');

    setValue('responsibles', '');
    setValue('unit', '');
    setValue('audits', '');
    setValue('unitSubgroup', '');
  };
  const saveFilters = async () => {
    try {
      const formValues = getValues();

      const payload = formatPayload(formValues);

      const {
        filterName,
        unit,
        unitGroup,
        unitSubgroup,
        showPicture,
        showAuditOccurrencePicture,
        showSignature,
        showCompare,
        showAuditOccurrence,
        realizedPeriodStart,
        realizedPeriodEnd,
        scheduledPeriodStart,
        scheduledPeriodEnd,
        areas,
        systems,
        responsibles,
        audits,
        status,
        reportType,
      } = payload;

      const companyId = headerFilters?.companies?.id;
      const userId = user?.id;
      await SavedFiltersService.createSavedFilter({
        companyId,
        userId,
        filterName,
        reportName,
        fields: {
          unit,
          unitGroup,
          unitSubgroup,
          showPicture,
          showAuditOccurrencePicture,
          showSignature,
          showCompare,
          showAuditOccurrence,
          realizedPeriodStart,
          realizedPeriodEnd,
          scheduledPeriodStart,
          scheduledPeriodEnd,
          areas,
          systems,
          responsibles,
          audits,
          status,
          reportType,
        },
      });

      setToastList(prevState => [
        ...prevState,
        createToast({
          type: 'success',
          message: 'Filtros salvos com sucesso',
        }),
      ]);
    } catch (err) {
      setToastList(prevState => [
        ...prevState,
        createToast({
          type: 'error',
          message: 'Ocorreu um erro ao salvar os filtros',
        }),
      ]);
    }
  };

  const generateExcel = async payload => {
    try {
      const {
        companyId,
        unit,
        unitGroup,
        unitSubgroup,
        showPicture,
        showAuditOccurrencePicture,
        showSignature,
        showCompare,
        showAuditOccurrence,
        realizedPeriodStart,
        realizedPeriodEnd,
        scheduledPeriodStart,
        scheduledPeriodEnd,
        areas,
        systems,
        responsibles,
        audits,
        status,
        company,
        unitName,
      } = payload;

      const response = await AuditService.generateExcel({
        companyId,
        unit,
        unitGroup,
        unitSubgroup,
        showPicture,
        showAuditOccurrencePicture,
        showSignature,
        showCompare,
        showAuditOccurrence,
        realizedPeriodStart,
        realizedPeriodEnd,
        scheduledPeriodStart,
        scheduledPeriodEnd,
        areas,
        systems,
        responsibles,
        audits,
        status,
        company,
        unitName,
      });

      const { data } = response;

      if (!data) {
        return setToastList(() => [
          createToast({
            type: 'error',
            message: 'Não há dados para os filtros selecionados',
          }),
        ]);
      }

      Sentry.captureMessage('Relatório Auditoria excel baixado');

      const blob = new Blob([data], {
        type: 'application/vnd.ms-excel',
      });

      const href = window.URL.createObjectURL(blob);

      setDownloadReport({
        ...downloadReport,
        href,
        name: `Leankeep-relatorio-auditoria-${new Date().getTime()}.xlsx`,
      });

      downloadLinkElement.current.click();

      setToastList(prevState => [
        ...prevState,
        createToast({
          type: 'success',
          message: 'Seu relatório foi exportado!',
        }),
      ]);
    } catch (err) {
      setToastList(() => [
        createToast({
          type: 'error',
          message: 'Não há dados para os filtros selecionados',
        }),
      ]);
    }
  };

  const generatePDF = async payload => {
    try {
      const {
        companyId,
        unit,
        unitGroup,
        unitSubgroup,
        showPicture,
        showAuditOccurrencePicture,
        showSignature,
        showCompare,
        showAuditOccurrence,
        realizedPeriodStart,
        realizedPeriodEnd,
        scheduledPeriodStart,
        scheduledPeriodEnd,
        areas,
        systems,
        responsibles,
        audits,
        status,
        company,
      } = payload;

      const response = await AuditService.generatePDF({
        companyId,
        unit,
        unitGroup,
        unitSubgroup,
        showPicture,
        showAuditOccurrencePicture,
        showSignature,
        showCompare,
        showAuditOccurrence,
        realizedPeriodStart,
        realizedPeriodEnd,
        scheduledPeriodStart,
        scheduledPeriodEnd,
        areas,
        systems,
        responsibles,
        audits,
        status,
        company,
      });

      const { data } = response;

      if (!data) {
        setToastList(prevState => [
          ...prevState,
          createToast({
            type: 'error',
            message: 'Não há dados para os filtros selecionados.',
          }),
        ]);
        return;
      }

      Sentry.captureMessage('Relatório Auditoria PDF baixado');

      const blob = new Blob([data], { type: 'application/pdf' });
      const href = window.URL.createObjectURL(blob);

      setDownloadReport({
        ...downloadReport,
        href,
        name: `Leankeep-relatorio-auditoria-${new Date().getTime()}.pdf`,
      });
      downloadLinkElement.current.click();

      setToastList(prevState => [
        ...prevState,
        createToast({
          type: 'success',
          message: 'Seu relatório foi exportado!',
        }),
      ]);
    } catch (err) {
      setToastList(prevState => [
        ...prevState,
        createToast({
          type: 'error',
          message: 'Não há dados para os filtros selecionados.',
        }),
      ]);
    }
  };

  const onSelectFilter = async selectedFilter => {
    const { id, name } = selectedFilter;
    if (id !== undefined) {
      try {
        const response = await SavedFiltersService.getSavedFilters({
          companyId: headerFilters?.companies?.id,
          filterSavedId: id,
        });

        applySavedFilter(response.data);

        setCurrentSavedFilter(() => name);
      } catch (err) {
        setToastList(prevState => [
          ...prevState,
          createToast({
            type: 'error',
            message: 'Ocorreu um erro ao buscar o filtro selecionado.',
          }),
        ]);
      } finally {
        setModalOptions(() => ({
          open: false,
        }));
      }
    }
  };

  const populateFieldsStrategy = async () => {
    const companyId = headerFilters?.companies?.id;
    const unitId = getValues('unit') || headerFilters.units.id;
    const unitGroupId = getValues('unitGroup');
    const unitSubgroupId = getValues('unitSubgroup');

    if (companyId) {
      if (!unitId) {
        return await Promise.all([
          FiltersService.getUnitGroups({
            companyId,
            ids: unitGroupId && [unitGroupId],
          }),
          FiltersService.getUnitSubgroups({
            companyId,
            ids: unitSubgroupId && [unitSubgroupId],
            unitGroupIds: unitGroupId && [unitGroupId],
          }),
          FiltersService.getUnits({
            companyId,
            ids: unitId && [unitId],
            unitGroupIds: unitGroupId && [unitGroupId],
            unitSubgroupIds: unitSubgroupId && [unitSubgroupId],
          }),
        ]).then(values => ({
          unitGroups: values[0].data.map(({ nome, grupoUnidadeId }) => ({
            label: nome,
            value: grupoUnidadeId,
          })),
          unitSubgroups: values[1].data.map(({ nome, subGrupoUnidadeId }) => ({
            label: nome,
            value: subGrupoUnidadeId,
          })),
          units: values[2].data.map(({ nome, site }) => ({
            label: nome,
            value: site,
          })),
        }));
      }

      return await Promise.all([
        FiltersService.getUnitGroups({
          companyId,
          ids: unitGroupId && [unitGroupId],
        }),
        FiltersService.getUnitSubgroups({
          companyId,
          ids: unitSubgroupId && [unitSubgroupId],
          unitGroupIds: unitGroupId && [unitGroupId],
        }),
        FiltersService.getUnits({
          companyId,
          ids: unitId && [unitId],
          unitGroupIds: unitGroupId && [unitGroupId],
          unitSubgroupIds: unitSubgroupId && [unitSubgroupId],
        }),
        FiltersService.getAreas({
          companyId,
          unitIds: unitId && [unitId],
          unitGroupIds: unitGroupId && [unitGroupId],
          unitSubgroupIds: unitSubgroupId && [unitSubgroupId],
        }),
        FiltersService.getActivityResponsiblesV2({
          companyId,
          unitIds: unitId && [unitId],
          unitGroupIds: unitGroupId && [unitGroupId],
          unitSubgroupIds: unitSubgroupId && [unitSubgroupId],
        }),
        FiltersService.getSystems({
          companyId,
          unitIds: unitId && [unitId],
          unitGroupIds: unitGroupId && [unitGroupId],
          unitSubgroupIds: unitSubgroupId && [unitSubgroupId],
        }),
        FiltersService.getAuditActivities({
          companyId,
          unitIds: unitId && [unitId],
          unitGroupIds: unitGroupId && [unitGroupId],
          unitSubgroupIds: unitSubgroupId && [unitSubgroupId],
        }),
        FiltersService.getOcurrenceStatus({ companyId }),
      ]).then(values => ({
        unitGroups: values[0].data.map(({ nome, grupoUnidadeId }) => ({
          label: nome,
          value: grupoUnidadeId,
        })),
        unitSubgroups: values[1].data.map(({ nome, subGrupoUnidadeId }) => ({
          label: nome,
          value: subGrupoUnidadeId,
        })),
        units: values[2].data.map(({ nome, site }) => ({
          label: nome,
          value: site,
        })),
        areas: values[3].data.map(({ name, id }) => ({
          label: name,
          value: id,
        })),
        // TODO verify if this is a bug
        responsibles: values[4].data.map(({ name }) => ({
          label: name,
          value: name,
        })),
        systems: values[5].data.map(({ name, id }) => ({
          label: name,
          value: id,
        })),
        audits: values[6].data.map(({ name, id }) => ({
          label: name,
          value: id,
        })),
        status: values[7].data.map(({ name }) => ({
          label: name,
          value: name,
        })),
      }));
    }
  };

  const populateFields = async () => {
    try {
      const fieldsData = await populateFieldsStrategy();
      if (fieldsData) {
        setFields(prevState => ({
          ...prevState,
          ...fieldsData,
        }));

        const { unitSubgroups, unitGroups } = fieldsData;

        if (unitGroups.length === 1) {
          setValue('unitGroup', unitGroups[0].value);
        }

        if (unitSubgroups.length === 1) {
          setValue('unitSubgroup', unitSubgroups[0].value);
        }
      }
    } catch (err) {
      console.error(err);
      createGenericErrorToast(setToastList);
    }
  };

  const getModalOptionsData = async () => {
    const companyId = headerFilters?.companies?.id;
    const userId = user?.id;

    try {
      const response = await SavedFiltersService.findSavedFiltersByReportName({
        reportName,
        companyId,
        userId,
      });
      return response.data;
    } catch (err) {
      setToastList(prevState => [
        ...prevState,
        createToast({
          type: 'error',
          message: 'Ocorreu um erro ao buscar os filtros salvos. Tente novamente..',
        }),
      ]);
    }
  };

  const deleteSavedFilter = async selectedFilter => {
    const { id } = selectedFilter;

    const companyId = headerFilters?.companies?.id;

    try {
      await SavedFiltersService.removeSavedFilter({
        companyId,
        filterSavedId: id,
      });
      setToastList(prevState => [
        ...prevState,
        createToast({
          type: 'success',
          message: 'O filtro selecionado foi deletado com sucesso',
        }),
      ]);
    } catch (err) {
      setToastList(prevState => [
        ...prevState,
        createToast({
          type: 'error',
          message: 'Ocorreu um erro ao deletar o filtro selecionado. Tente novamente.',
        }),
      ]);
    } finally {
      setModalOptions({
        open: false,
      });
    }
  };

  const openModalSavedFilters = () =>
    setModalOptions(() => ({
      title: 'Filtros salvos',
      open: true,
    }));

  const fetchData = async () => {
    setGlobalComponentsContext(prevState => ({
      ...prevState,
      isLoading: true,
    }));
    // await cleanFields();
    await populateFields();
    setGlobalComponentsContext(prevState => ({
      ...prevState,
      isLoading: false,
    }));
  };
  useEffect(() => {
    const unitGroupId = getValues('unitGroup');
    const unitSubgroupId = getValues('unitSubgroup');
    // if company has been cleared but forms are active clear fields
    if (!headerFilters?.companies?.id && (unitGroupId || unitSubgroupId)) {
      cleanFields();

      setFields({
        unitGroups: [],
        unitSubgroups: [],
        units: [],
        areas: [],
        responsibles: [],
        systems: [],
        audits: [],
        status: [],
      });
    }

    if (headerFilters.units && headerFilters.units.id && !getValues('unit')) {
      setValue('unit', headerFilters.units.id);
    }

    fetchData();
    setIsFirstMount(false);

    // eslint-disable-next-line
  }, [headerFilters.units, headerFilters.companies]);
  const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);
  return (
    <>
      {isLoading && <Loading />}
      <PageWrapper background={colors.pampas}>
        <MobileNavbar />
        {/* <Menu /> */}
        <NewMenu setIsSubMenuOpen={setIsSubMenuOpen} />
        <HeaderFiltersPage location={pathname} isSubMenuOpen={isSubMenuOpen}>
          <ReportContainer
            title="Relatório de Auditoria"
            exportTitle="Exportar Relatório"
            onSubmit={handleSubmit(onSubmit)}
            currentSavedFilter={currentSavedFilter}
            useSavedFilters={openModalSavedFilters}
            cleanSavedFilter={cleanFields}
            saveFilter={saveFilters}
            cleanFields={cleanFields}
            onBack={goback}
            formId={reportName}
            refInputFilterName={register({
              required: true,
            })}
            renderFooter={watch('unit')}
          >
            <ReportContainerColumn justifyContent="flex-end" gridColumnStart={2}>
              <ReportContainerFields>
                <FieldRow marginBottom="1rem">
                  <FormGroup label="Grupo de unidades" disabled={fields?.unitGroups?.length <= 0}>
                    <Controller
                      render={props => (
                        <Select
                          {...props}
                          size="small"
                          data-cy="audit-report-unit-groups-select"
                          placeholder="Seleção opcional"
                          options={fields?.unitGroups}
                          defaultValue={isFirstMount ? props.defaultValue : ''}
                          onChange={value => {
                            props.onChange(value);
                            updateUnitGroup();
                            fetchData();
                          }}
                        />
                      )}
                      name="unitGroup"
                      control={control}
                      defaultValue={headerFilters?.unitGroups?.id || ''}
                    />
                  </FormGroup>
                </FieldRow>

                <FieldRow marginBottom="1rem">
                  <FormGroup label="Subgrupo de unidades" disabled={fields?.unitSubgroups?.length <= 0}>
                    <Controller
                      render={props => (
                        <Select
                          {...props}
                          size="small"
                          data-cy="audit-report-unit-subgroups-select"
                          placeholder="Seleção opcional"
                          options={fields?.unitSubgroups}
                          defaultValue={isFirstMount ? props.defaultValue : ''}
                          onChange={value => {
                            props.onChange(value);
                            fetchData();
                          }}
                        />
                      )}
                      name="unitSubgroup"
                      control={control}
                      defaultValue={headerFilters?.unitSubgroups?.id || ''}
                    />
                  </FormGroup>
                </FieldRow>
                <FieldRow marginBottom="1rem">
                  <FormGroup label="Unidade de manutenção" disabled={fields?.units?.length <= 0} isRequired>
                    <Controller
                      render={props => (
                        <Select
                          {...props}
                          size="small"
                          data-cy="audit-report-unit-select"
                          placeholder="Seleção obrigatória"
                          options={fields?.units}
                          defaultValue={isFirstMount ? props.defaultValue : ''}
                          onChange={value => {
                            props.onChange(value);
                            fetchData();
                          }}
                        />
                      )}
                      name="unit"
                      control={control}
                      defaultValue={headerFilters?.units?.id || ''}
                      rules={{ required: true }}
                    />
                  </FormGroup>
                </FieldRow>

                <StyledFormGroup>
                  <FormGroup label="Formato do relatório" isRequired>
                    <StyledFormRadio>
                      <Radio
                        text="PDF (texto e fotos)"
                        id="pdf-report-radio-option"
                        name="reportType"
                        value="pdf"
                        ref={register()}
                        defaultChecked
                      />
                      <Radio
                        text="Excel (apenas texto)"
                        type="radio"
                        id="excel-report-radio-option"
                        name="reportType"
                        value="excel"
                        ref={register()}
                      />
                    </StyledFormRadio>
                  </FormGroup>
                </StyledFormGroup>

                {watch('unit') && (
                  <Preview
                    reportName={reportName}
                    reportType={watch('reportType')}
                    params={{
                      showPicture: watch('showPicture'),
                      showSignature: watch('showSignature'),
                      showAuditOccurrence: watch('showAuditOccurrence'),
                      showCompare: watch('showCompare'),
                      showAuditOccurrencePicture: watch('showAuditOccurrencePicture'),
                    }}
                  />
                )}
              </ReportContainerFields>
            </ReportContainerColumn>
            <LineSeparator show={watch('unit')} />
            {watch('unit') ? (
              <ReportContainerColumn>
                <ReportContainerFields>
                  <FieldRow marginBottom="1rem">
                    <FormGroup label="Auditorias" disabled={fields?.audits?.length <= 0}>
                      <Controller
                        render={props => (
                          <MultiSelect
                            size="small"
                            placeholder="Seleção opcional"
                            data-cy="audit-report-audits-multi-select"
                            options={fields?.audits}
                            {...props}
                          />
                        )}
                        name="audits"
                        control={control}
                        defaultValue={[]}
                      />
                    </FormGroup>
                  </FieldRow>

                  <StyledFieldsColumn width="1">
                    <FieldRow marginBottom="1rem">
                      <FormGroup label="Período Realizado" isRequired>
                        <Controller
                          render={props => (
                            <DateRangePicker
                              size="small"
                              placeholder="Selecione um período"
                              data-cy="audit-realized-period-date-range-picker"
                              defaultValue={props.defaultValue}
                              {...props}
                            />
                          )}
                          name="realizedPeriod"
                          control={control}
                          defaultValue=""
                        />
                      </FormGroup>
                    </FieldRow>
                  </StyledFieldsColumn>

                  <FieldRow marginBottom="1rem">
                    <FormGroup label="Áreas" disabled={fields?.areas?.length <= 0}>
                      <Controller
                        render={props => (
                          <MultiSelect
                            size="small"
                            data-cy="audit-report-areas-multi-select"
                            placeholder="Seleção opcional"
                            options={fields?.areas}
                            {...props}
                          />
                        )}
                        name="areas"
                        control={control}
                        defaultValue={[]}
                      />
                    </FormGroup>
                  </FieldRow>
                  <FieldRow marginBottom="1rem">
                    <FormGroup label="Sistemas" disabled={fields?.systems?.length <= 0}>
                      <Controller
                        render={props => (
                          <MultiSelect
                            size="small"
                            data-cy="audit-report-systems-multi-select"
                            placeholder="Seleção opcional"
                            options={fields?.systems}
                            {...props}
                          />
                        )}
                        name="systems"
                        control={control}
                        defaultValue={[]}
                      />
                    </FormGroup>
                  </FieldRow>

                  <FieldRow marginBottom="1rem">
                    <FormGroup label="Responsáveis" disabled={fields?.responsibles?.length <= 0}>
                      <Controller
                        render={props => (
                          <MultiSelect
                            size="small"
                            data-cy="audit-report-responsibles-multi-select"
                            placeholder="Seleção opcional"
                            options={fields?.responsibles}
                            {...props}
                          />
                        )}
                        name="responsibles"
                        control={control}
                        defaultValue={[]}
                      />
                    </FormGroup>
                  </FieldRow>

                  <StyledSwitchesRow>
                    {watch('reportType') === 'pdf' && (
                      <FieldRow marginBottom="1rem">
                        <Switch
                          label="Exibir foto"
                          data-cy="audit-report-show-picture-switch"
                          $hasMaxContent
                          name="showPicture"
                          ref={register()}
                        />
                      </FieldRow>
                    )}

                    {watch('reportType') === 'pdf' && (
                      <FieldRow marginBottom="1rem">
                        <Switch
                          label="Exibir assinatura"
                          data-cy="audit-report-show-signature-switch"
                          $hasMaxContent
                          name="showSignature"
                          ref={register()}
                        />
                      </FieldRow>
                    )}
                  </StyledSwitchesRow>
                  {/*
                  <StyledFieldsColumn>
                    <FieldRow>
                      <Switch
                        label="Apresentar dados de ocorrências desta auditoria"
                        $hasMaxContent
                        name="showAuditOccurrence"
                        ref={register()}
                        onChange={() => {
                          setShowOccurrencesData(
                            !showOccurrencesData
                          );
                        }}
                      />
                    </FieldRow>

                    {watch('showAuditOccurrence') && (
                      <StyledSwitchAppend>
                        <StyledFieldsColumn width="2">
                          <FieldRow>
                            <FormGroup
                              label="Status"
                              disabled={fields?.status?.length <= 0}
                            >
                              <Controller
                                render={(props) => (
                                  <MultiSelect
                                    size="small"
                                    placeholder="Seleção opcional"
                                    options={fields?.status}
                                    {...props}
                                  />
                                )}
                                name="status"
                                control={control}
                                defaultValue={[]}
                              />
                            </FormGroup>
                          </FieldRow>

                          {watch('reportType') === 'pdf' && (
                            <FieldRow>
                              <Switch
                                label="Exibir foto das ocorrências"
                                $hasMaxContent
                                name="showAuditOccurrencePicture"
                                ref={register()}
                              />
                            </FieldRow>
                          )}
                        </StyledFieldsColumn>
                      </StyledSwitchAppend>
                    )}
                  </StyledFieldsColumn> */}
                  {/*
                  <StyledFieldsColumn>
                    <FieldRow marginTop="1rem">
                      <Switch
                        label="Exibir comparativo de atividades"
                        $hasMaxContent
                        name="showCompare"
                        ref={register()}
                      />
                    </FieldRow>
                    {watch('showCompare') && (
                      <StyledSwitchAppend>
                        <FieldRow>
                          <FormGroup
                            label="Período Programado"
                            isRequired
                          >
                            <Controller
                              as={
                                <DateRangePicker
                                  size="small"
                                  placeholder="Seleção obrigatória"
                                />
                              }
                              name="scheduledPeriod"
                              control={control}
                              defaultValue=""
                            />
                          </FormGroup>
                        </FieldRow>
                      </StyledSwitchAppend>
                    )}
                  </StyledFieldsColumn> */}
                </ReportContainerFields>
              </ReportContainerColumn>
            ) : (
              <ReportContainerFillFields />
            )}
          </ReportContainer>
          <InvisibleDownloadLink ref={downloadLinkElement} href={downloadReport?.href} download={downloadReport?.name}>
            Baixar arquivo
          </InvisibleDownloadLink>
        </HeaderFiltersPage>
      </PageWrapper>
      <Toast toastList={toastList} autoDelete dismissTime={7000} />
      {modalOptions.open && (
        <FilterModal
          options={modalOptions}
          id="audit-filter-modal"
          closeModal={() =>
            setModalOptions(() => ({
              open: false,
            }))
          }
          onSelect={onSelectFilter}
          hasDeleteBtn
          onDelete={deleteSavedFilter}
          getData={getModalOptionsData}
        />
      )}
    </>
  );
};

export { Audit };
