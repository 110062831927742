const adapt = httpResponse => ({
  ...httpResponse,
  data: httpResponse.data.map(estado => ({
    id: estado.estadoId,
    name: estado.nome,
    initials: estado.sigla,
    countryId: estado.paisId,
  })),
});

const getAllStatesAdapter = httpResponsePromise =>
  httpResponsePromise.then(
    resolve => adapt(resolve),
    reject => reject,
  );

export { getAllStatesAdapter };
