/* istanbul ignore file */

const medias = {
  mobile: '375px',
  tablet: '768px',
  desktopSm: '1081px',
  desktopMd: '1360px',
  desktopXl: '1920px',
  desktopXXl: '2560px',
};

export { medias };
