import instagramSvg from 'assets/images/bt_instagram.svg';
import facebookSvg from 'assets/images/bt_facebook.svg';
import linkedinSvg from 'assets/images/bt_linkedin.svg';
import { StyledSocialLinks } from '../styled';

const SocialLinks = ({ links }) => {
  const { instagram, facebook, linkedin, site } = links;
  const formatSiteName = url => {
    if (!url) return '';
    try {
      const hostname = new URL(url).hostname;
      return hostname;
    } catch (error) {
      console.error('Invalid URL:', url);
      return '';
    }
  };

  return (
    <StyledSocialLinks>
      {instagram && (
        <a href={instagram} target="_blank" rel="noopener noreferrer">
          <img src={instagramSvg} alt="instagram" />
        </a>
      )}
      {facebook && (
        <a href={facebook} target="_blank" rel="noopener noreferrer">
          <img src={facebookSvg} alt="facebook" />
        </a>
      )}
      {linkedin && (
        <a href={linkedin} target="_blank" rel="noopener noreferrer">
          <img src={linkedinSvg} alt="linkedin" />
        </a>
      )}
      {site && (
        <a className="site" href={site} target="_blank" rel="noopener noreferrer">
          {formatSiteName(site)}
        </a>
      )}
    </StyledSocialLinks>
  );
};

export default SocialLinks;
