import styled from 'styled-components';
import { colors } from 'assets/styled/tokens';
export const StyledEditGeneralPlanButtonContainer = styled.div`
  position: relative;
  cursor: pointer;
  height: 100%;
  width: 100%;
  display: ${({ isActive }) => (isActive ? `flex` : `none`)};
  align-items: center;
  justify-content: center;
  &:hover {
    background: ${colors.pampas};
    path {
      fill: ${colors.carbon};
    }
  }
  transition: all 600ms ease;
`;

export const StyledOptionsContainer = styled.select`
  opacity: ${props => (props.isActive ? `1` : 0)};
  width: 100%;
  height: 100%;
  overflow: hidden;
  cursor: pointer;
  display: flex;
  position: absolute;
  z-index: 2;
  background: white;
  flex-direction: column;

  transition: all 900ms ease;
`;
export const StyledOption = styled.option`
  cursor: pointer;
  &:hover {
    color: white;
    background: blue;
  }
  transition: all 600ms ease;
`;
