import React, { useState, useEffect, createContext } from 'react';

import { localStorageKeys } from 'constants/localStorage';

import { initialState } from './initialState';

const AuthContext = createContext(initialState);
AuthContext.displayName = 'AuthContext';

const AuthContextProvider = ({ children }) => {
  const [state, setState] = useState(initialState);

  useEffect(() => {
    localStorage.setItem(localStorageKeys.authContext, JSON.stringify(state));
  }, [state]);

  return (
    <AuthContext.Provider
      value={{
        authContext: state,
        setAuthContext: setState,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export { AuthContext, AuthContextProvider };
