import React, { memo, useContext, useEffect, useState } from 'react';

import { FiltersContext } from 'context/GlobalFiltersContext';
import { URLS } from 'constants/urls';

import { MenuService } from 'services/main/endpoints/MenuService';
import { getLocalAuthStorage } from 'utils/localStorage';
import leankeepLogoCarbon from 'assets/icons/leankeep/leankeep-carbon.svg';
import { StyledLogo, StyledMenu, StyledMenuCategory, StyledNav } from './styled';
import { CategoryItem } from './subcomponents/CategoryItem';
import jwt from 'jwt-decode';
//import { menuSuperUser } from 'utils/helpers/menuSuperUser';
import { menuAdmin } from 'utils/helpers/menuAdmin';
import { createGenericErrorToast } from 'utils/createToast';
import { usePermissions } from 'hooks/usePermission';
import { Toast } from 'components/Toast';
import { GlobalComponentsContext } from 'context';
export const NewMenu = memo(({ setIsSubMenuOpen }) => {
  const { globalComponentsContext } = useContext(GlobalComponentsContext);
  const { setPermissionContext } = usePermissions();
  const [toastList, setToastList] = useState([]);
  const { headerFiltersContext } = useContext(FiltersContext);
  const { headerFilters } = headerFiltersContext;
  const companyLogoPath = headerFilters?.companies?.logoPath;
  const unitLogoPath = headerFilters?.units?.logoPath;
  const logoPath = unitLogoPath ? unitLogoPath : companyLogoPath;
  const [pages, setPages] = useState({});
  const [adminMenu, setAdminMenu] = useState();
  const { user, token } = getLocalAuthStorage();
  const getUserType = () => {
    const { utyp } = jwt(token);
    const tipoUsuario = JSON.parse(utyp);
    return tipoUsuario;
  };
  const type = getUserType();
  useEffect(() => {
    MenuService.getUserMenu({
      token,
      login: user?.name,
      tipoUsuario: getUserType(),
      unidade: type === 1 ? undefined : headerFilters?.units?.id,
      empresa: type === 1 ? undefined : headerFilters?.companies?.id,
    })
      .then(res => {
        switch (type) {
          case 1:
            setAdminMenu(res.data);
            break;
          case 2:
            setAdminMenu(res.data);
            break;
          case 3:
            setAdminMenu(res.data);
            break;
          default:
            setAdminMenu(res.data);
            break;
        }
      })
      .catch(() => {
        createGenericErrorToast(setToastList);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, user?.name, user?.permissionType, headerFilters?.companies?.id, headerFilters?.units?.id]);
  useEffect(() => {
    const isNotSuperUser = type === 2 || type === 3 || type === 4;
    if (isNotSuperUser && adminMenu) {
      setPages(menuAdmin(adminMenu, type));
      const permissions = handlePermissions(adminMenu, headerFilters?.units?.id);
      if (permissions !== undefined) {
        setPermissionContext(permissions);
      }
    } else if (type === 1 && adminMenu) {
      const unit = type === 1 ? null : headerFilters?.units?.id;
      setPages(menuAdmin(adminMenu, unit, type));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [headerFilters?.units?.id, headerFilters?.companies?.id, adminMenu]);

  const handlePermissions = (menu, unit) => {
    const arrayMenu = {
      home: 'Home',
      dashboard: 'Dashboard',
      planosGerais: 'Planos Gerais',
      relatorios: 'Relatórios',
      geolocalizacao: 'Geolocalização',
    };
    const filteredJson = menu.find(obj => obj.unidade === (unit !== undefined && unit !== null ? unit : 0));
    const extractedValues = {};
    if (filteredJson === undefined) {
      return;
    }
    const processSubMenu = subMenu => {
      subMenu?.forEach(subItem => {
        const foundKey = Object.keys(arrayMenu).find(key => arrayMenu[key] === subItem.nome);
        if (foundKey) {
          extractedValues[foundKey] = subItem.acesso;
        }
      });
    };
    filteredJson?.menu?.forEach(menuItem => {
      if (menuItem.subMenu && menuItem.subMenu.length > 0) {
        processSubMenu(menuItem.subMenu);
      }

      const foundKey = Object.keys(arrayMenu).find(key => arrayMenu[key] === menuItem.nome);
      if (foundKey) {
        extractedValues[foundKey] = menuItem.acesso;
      }
    });

    return extractedValues;
  };
  const [subMenusAreOpen, setSubMenusAreOpen] = useState({});
  useEffect(() => {
    Object.keys(pages)?.map(key => {
      const categories = pages[key];

      categories?.forEach(category => {
        if (category.children != null || category.children != undefined) {
          setSubMenusAreOpen(prevSubMenus => ({
            ...prevSubMenus,
            [category.label]: false,
          }));
        }
      });
    });
  }, [pages]);
  return (
    <>
      <StyledMenu display={globalComponentsContext?.isMobileMenuOpen ? 'flex' : 'none'}>
        <StyledLogo>
          {logoPath && (
            <img
              src={
                process.env.NODE_ENV === 'development'
                  ? `${URLS.imageDevelopment}/${logoPath}`
                  : `${URLS.imageProduction}/${logoPath}`
              }
              alt="Logo"
            />
          )}

          {!logoPath && <img src={leankeepLogoCarbon} alt="Logo" />}
        </StyledLogo>
        <StyledNav>
          {pages &&
            Object.keys(pages)?.map((key, index) => {
              const categories = pages[key];
              return (
                <StyledMenuCategory key={index}>
                  {categories?.map(category => (
                    <CategoryItem
                      subMenusAreOpen={subMenusAreOpen}
                      setSubMenusAreOpen={setSubMenusAreOpen}
                      setIsSubMenuOpen={setIsSubMenuOpen}
                      key={category.label}
                      category={category}
                    />
                  ))}
                </StyledMenuCategory>
              );
            })}
        </StyledNav>
      </StyledMenu>
      <Toast toastList={toastList} autoDelete dismissTime={5000} />
    </>
  );
});
