/* istanbul ignore file */

import styled from 'styled-components';

export const ReportContainerColumn = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: ${props => props.justifyContent};
  align-items: ${props => props.alignItems};
  grid-column-start: ${props => props.gridColumnStart};
`;
