import React, { useEffect, useState, useContext } from 'react';
import { useQuery, useQueryClient } from 'react-query';

import PropTypes from 'prop-types';

import { FiltersContext } from 'context/GlobalFiltersContext';

import { Input } from 'components/Form/subcomponents/Input';

import MagnifyingIcon from 'assets/icons/magnifying.svg';

import { FilterOptions } from './FilterOptions';

import {
  StyledBlackLayer,
  StyledModalInfo,
  StyledModalHeader,
  StyledModalTitle,
  StyledModalClose,
  EmptyList,
  StyledSearchBar,
} from './styled';

const FilterModal = props => {
  const { options, getData, hasDeleteBtn, closeModal, onSelect, onDelete, id = '' } = props;
  const { open, title, propertyKey } = options;
  const { headerFiltersContext } = useContext(FiltersContext);
  const [responseItems, setResponseItems] = useState([]);
  const [filterWord, setFilterWord] = useState('');
  const [showModal, setShowModal] = useState(false);

  const { headerFilters } = headerFiltersContext;
  const { error, data } = useQuery('filterModalData', getData, { enabled: open });
  const queryClient = useQueryClient();

  useEffect(() => {
    queryClient.invalidateQueries('filterModalData');

    if (open) {
      setShowModal(true);
    }
    if (data) {
      if (data.length >= 1) {
        setResponseItems(data);
      } else {
        setResponseItems([]);
      }
    }

    if (error) {
      setResponseItems([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, error, onSelect, headerFilters, open, queryClient]);
  function filterOptionsByWord(responseItems) {
    if (!responseItems) {
      return [];
    }

    if (!filterWord) {
      return responseItems;
    }

    return responseItems.filter(item => {
      const wordMatches = propertyKey && `${item[propertyKey]}`.toLowerCase().includes(filterWord);

      if (wordMatches) {
        return item;
      }

      return null;
    });
  }

  return (
    <>
      {showModal && (
        <StyledBlackLayer>
          <StyledModalInfo>
            <StyledModalHeader>
              <StyledModalTitle>{title}</StyledModalTitle>

              <StyledModalClose onClick={() => closeModal()} type="button">
                Fechar modal
              </StyledModalClose>
            </StyledModalHeader>

            {propertyKey && responseItems && responseItems.length > 5 && (
              <StyledSearchBar>
                <Input
                  data-cy={`${id}-search-input`}
                  type="text"
                  autoComplete="off"
                  name="search"
                  size="small"
                  placeholder="Pesquisar"
                  icon={MagnifyingIcon}
                  onChange={e => setFilterWord(e.target.value.toLowerCase())}
                />
              </StyledSearchBar>
            )}
            {responseItems && responseItems.length <= 0 ? (
              <EmptyList>
                <h5>Nenhum item cadastrado</h5>
              </EmptyList>
            ) : (
              <FilterOptions
                hasDeleteBtn={hasDeleteBtn}
                options={filterOptionsByWord(responseItems)}
                onSelect={({ option }) => onSelect(option)}
                onDelete={({ option }) => onDelete(option)}
              />
            )}
          </StyledModalInfo>
        </StyledBlackLayer>
      )}
    </>
  );
};

FilterModal.proptype = {
  options: PropTypes.array,
  getData: PropTypes.func,
  hasDeleteBtn: PropTypes.bool,
  closeModal: PropTypes.func,
  onSelect: PropTypes.func,
  onDelete: PropTypes.func,
};

FilterModal.defaultProps = {
  hasDeleteBtn: false,
};

export { FilterModal };
