import { ReactComponent as UnitIcon } from 'assets/icons/building.svg';
import { ReactComponent as EllipseIcon } from 'assets/icons/ellipse.svg';
import BatteryCharge from 'components/Battery/BatteryCharge';
import React from 'react';

import {
  StyledBatteryTimeContainer,
  StyledTimelineStop,
  StyledTimelineStopAddress,
  StyledTimelineStopApplicationsList,
  StyledTimelineStopApplicationsListItem,
  StyledTimelineStopApplicationsListTitle,
  StyledTimelineStopContent,
  StyledTimelineStopIcon,
  StyledTimelineStopTime,
  StyledTimelineStopTitle,
  StyledTimelineStopUnit,
} from './styled';

function TimelineStop({ stop: { eventType, title, address, time, battery, applications, unitName } }) {
  switch (eventType) {
    case 'generic': {
      return (
        <StyledTimelineStop>
          <StyledTimelineStopTitle>
            <StyledTimelineStopIcon>
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="10" cy="10" r="8" fill="#1E1E1E" stroke="white" strokeWidth="4" />
                <circle cx="10" cy="10" r="2" fill="white" />
              </svg>
            </StyledTimelineStopIcon>

            {title}
          </StyledTimelineStopTitle>
          <StyledTimelineStopContent>
            <StyledBatteryTimeContainer>
              <StyledTimelineStopTime>{time}</StyledTimelineStopTime>
              <EllipseIcon />
              <BatteryCharge battery={battery} />
            </StyledBatteryTimeContainer>
            <StyledTimelineStopAddress>
              <svg width="9" height="12" viewBox="0 0 9 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M4.03125 11.7541C4.24219 12.082 4.73438 12.082 4.94531 11.7541C8.36719 6.83707 9 6.32195 9 4.49561C9 2.01366 6.98438 0 4.5 0C1.99219 0 0 2.01366 0 4.49561C0 6.32195 0.609375 6.83707 4.03125 11.7541ZM4.5 6.36878C3.44531 6.36878 2.625 5.54927 2.625 4.49561C2.625 3.46537 3.44531 2.62244 4.5 2.62244C5.53125 2.62244 6.375 3.46537 6.375 4.49561C6.375 5.54927 5.53125 6.36878 4.5 6.36878Z"
                  fill="#BBBBBB"
                />
              </svg>
              {address}
            </StyledTimelineStopAddress>
          </StyledTimelineStopContent>
        </StyledTimelineStop>
      );
    }
    case 'activity': {
      return (
        <StyledTimelineStop>
          <StyledTimelineStopTitle>
            <StyledTimelineStopIcon>
              {' '}
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="10" cy="10" r="8" fill="#1E1E1E" stroke="white" strokeWidth="4" />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M9.56548 12.8125C9.32693 13.0625 8.92888 13.0625 8.69033 12.8125L6.42834 10.4422C6.19055 10.193 6.19055 9.77838 6.42834 9.5292L6.9189 9.01517C7.15729 8.76536 7.54138 8.7652 7.77996 9.0147L9.1339 10.4209L12.2196 7.18747C12.4581 6.93751 12.8426 6.93751 13.0811 7.18747L13.5717 7.70151C13.8094 7.95069 13.8094 8.36534 13.5717 8.61451L9.56548 12.8125Z"
                  fill="white"
                />
              </svg>
            </StyledTimelineStopIcon>

            {title}
          </StyledTimelineStopTitle>
          <StyledTimelineStopContent>
            <StyledBatteryTimeContainer>
              <StyledTimelineStopTime>{time}</StyledTimelineStopTime>
              <EllipseIcon />
              <BatteryCharge battery={battery} />
            </StyledBatteryTimeContainer>
            <StyledTimelineStopUnit>
              <UnitIcon />
              {unitName}
            </StyledTimelineStopUnit>
            <StyledTimelineStopApplicationsList>
              {applications && (
                <StyledTimelineStopApplicationsListTitle>Aplicações:</StyledTimelineStopApplicationsListTitle>
              )}
              {applications &&
                applications.map(application => (
                  <StyledTimelineStopApplicationsListItem>
                    <EllipseIcon /> {application}
                  </StyledTimelineStopApplicationsListItem>
                ))}
            </StyledTimelineStopApplicationsList>
          </StyledTimelineStopContent>
        </StyledTimelineStop>
      );
    }
    case 'lostConnection': {
      return (
        <StyledTimelineStop>
          <StyledTimelineStopTitle isDarkRed>
            <StyledTimelineStopIcon>
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="10" cy="10" r="8" fill="#EC6655" stroke="white" strokeWidth="4" />
                <rect x="7" y="9" width="6" height="2" fill="white" />
              </svg>
            </StyledTimelineStopIcon>
            {title}
            <EllipseIcon /> <StyledTimelineStopTime>{time}</StyledTimelineStopTime>
          </StyledTimelineStopTitle>
        </StyledTimelineStop>
      );
    }
    default: {
      return <></>;
    }
  }
}

export default TimelineStop;
