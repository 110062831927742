/* istanbul ignore file */

import styled from 'styled-components';

import { fonts, colors, medias } from 'assets/styled/tokens';
import { Button } from 'components/Button';
export const ErrorText = styled.div`
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.30000001192092896px;
  text-align: left;
  color: #c25446;
`;
export const GoBackButton = styled(Button)`
  left: 0;
  margin-right: 24px;
  background: #ffffff;
  border: 1px solid #e5e5e5;
  border-radius: 100px;
  height: 32px;
  width: 32px;
  box-sizing: border-box;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const Header = styled.div`
  display: flex;
  flex-direction: row;
  min-width: 100%;
  align-items: center;
  margin-bottom: 34px;
`;
export const StyledBoxAlert = styled.div`
  padding: 12px 8px 16px;
  margin-top: 32px;
  border-radius: 3px;
  background-color: ${colors.lightbrown};

  img {
    width: 14px;
    height: 12px;
    margin-right: 6px;
  }
`;

export const StyledAlert = styled.span`
  display: flex;
  align-content: center;
  font-family: ${fonts.family.text};
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  color: ${colors.gray};
`;

export const StyledRadio = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-bottom: 8px;
  font-family: ${fonts.family.text};
  font-size: 0.75rem;
`;

export const StyleDivRadio = styled.span`
  display: flex;
  color: ${colors.gray};
`;

export const StyledFormGroup = styled.div`
  display: block;
`;

export const StyledColumnContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 40px 0;
`;

export const StyledFormRadio = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  font-family: ${fonts.family.text};
  font-size: 12px;

  label {
    margin-left: 15px;

    &:first-child {
      margin-left: 0;
    }

    input {
      margin-right: 5px;
      cursor: pointer;
    }
  }
`;

export const StyledFormItem = styled.div`
  display: block;
`;

export const StyledFieldsColumn = styled.div`
  display: inline-grid;
  align-items: flex-end;
  grid-template-columns: repeat(${props => (props.width ? props.width : '1')}, 1fr);
  column-gap: 15px;
  width: 100%;
  margin-top: ${props => (props.marginTop ? props.marginTop : '0')};

  @media screen and (max-width: ${medias.mobile}) {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
  }
`;
