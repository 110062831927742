const { css } = require('styled-components');

const large = () => css`
  height: 40px;
  padding: 0.75rem 0.688rem;
  font-size: 0.875rem;
  line-height: 16px;
  border: 1px solid invisible;
  border-radius: 4px;
`;

export { large };
