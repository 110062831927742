import React from 'react';

import Conditional from 'components/Conditional';

import {
  StyledModalGeneric,
  StyledModalGenericWrapper,
  StyledModalGenericIcon,
  StyledModalGenericHeader,
  StyledModalGenericHeaderContainer,
  StyledModalSubtitle,
  StyledModalGenericClose,
  StyledModalGenericBody,
  StyledModalGenericLoadingContainer,
  StyledModalGenericLoadingSpinner,
  StyledModalGenericLoadingHeader,
} from './styled';

const ModalGeneric = props => {
  const {
    open = false,
    hasHeader = false,
    title,
    icon,
    size,
    closeModal = () => {},
    isLoading,
    loadingMessage = '',
    children,
    subtitle = '',
    position = 'center',
  } = props;

  return (
    <StyledModalGeneric position={position} open={open}>
      {isLoading ? (
        <StyledModalGenericLoadingContainer>
          <StyledModalGenericLoadingSpinner />
          <StyledModalGenericLoadingHeader>{loadingMessage}</StyledModalGenericLoadingHeader>
        </StyledModalGenericLoadingContainer>
      ) : (
        <StyledModalGenericWrapper size={size} {...props}>
          <StyledModalGenericClose onClick={() => closeModal && closeModal()} type="button" />
          <Conditional when={!!icon}>
            <StyledModalGenericIcon>{icon}</StyledModalGenericIcon>
          </Conditional>

          <Conditional when={hasHeader}>
            <StyledModalGenericHeader>
              <StyledModalGenericHeaderContainer>
                {title}
                {subtitle && <StyledModalSubtitle>{subtitle}</StyledModalSubtitle>}
              </StyledModalGenericHeaderContainer>
            </StyledModalGenericHeader>
          </Conditional>
          <StyledModalGenericBody>{children}</StyledModalGenericBody>
        </StyledModalGenericWrapper>
      )}
    </StyledModalGeneric>
  );
};

export { ModalGeneric };
