/* istanbul ignore file */

import { ALERT_TYPE_COLORS } from 'assets/styled/tokens';
import styled from 'styled-components';

export type VariantsType = 'default' | 'success' | 'danger' | 'warn' | 'info';

export const AlertWrapper = styled.div<{ type?: VariantsType }>`
  background: ${({ type = 'default' }) => ALERT_TYPE_COLORS[type].backgroundColor};
  border: 1px solid ${({ type = 'default' }) => ALERT_TYPE_COLORS[type].borderColor};
  border-radius: 4px;
  display: block;
`;

export const AlertInner = styled.div`
  padding: 12px 16px;
  display: flex;
  justify-content: space-betweenl;
`;

export const AlertIcon = styled.span``;

export const AlertMessage = styled.span<{ type?: VariantsType }>`
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  color: ${({ type = 'default' }) => ALERT_TYPE_COLORS[type].color};
`;
