/* istanbul ignore file */

import styled from 'styled-components';

import { fonts, colors, medias } from 'assets/styled/tokens';

export const StyledFormGroup = styled.div`
  display: block;
`;

export const StyledColumnContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 40px 0;
`;

export const StyledFormRadio = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  font-family: ${fonts.family.text};
  font-size: 12px;

  label {
    margin-left: 15px;

    &:first-child {
      margin-left: 0;
    }

    input {
      margin-right: 5px;
      cursor: pointer;
    }
  }
`;

export const StyledFormItem = styled.div`
  display: block;
`;

export const StyledFieldsColumn = styled.div`
  display: inline-grid;
  align-items: flex-end;
  grid-template-columns: repeat(${props => (props.width ? props.width : '1')}, 1fr);
  column-gap: 15px;
  width: 100%;
  margin-top: ${props => (props.marginTop ? props.marginTop : '0')};

  @media screen and (max-width: ${medias.mobile}) {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
  }
`;

export const StyledSwitchesRow = styled.div`
  display: flex;
  column-gap: 15px;
  margin-top: 30px;
`;

export const StyledSwitchAppend = styled.div`
  max-width: 456px;
  width: 100%;
  background-color: ${colors.pampas};
  padding: 0.625rem;
  border: 1px solid ${colors.whiteGray};
  border-radius: 0px 3px 3px 3px;
`;
