import styled from 'styled-components';

import { colors, medias } from 'assets/styled/tokens';

export const StyledSubCategory = styled.ul`
  background-color: ${colors.pampas};
  position: absolute;
  top: -0.25rem;
  z-index: 1;
  cursor: pointer;

  display: ${props => (props.isOpen ? 'block' : 'none')};

  @media screen and (min-width: ${medias.desktopSm}) {
    box-shadow: 0 0 16px rgba(0, 0, 0, 0.3);
    padding: 0.75rem 0;

    left: 100%;
    width: auto;
    border-radius: 6px;
    transition: opacity ease-in-out 210ms;
  }

  a,
  span {
    position: relative;
    display: inline-block;
    padding: 0;

    &:before {
      display: inline-block;
      margin-right: 8px;
      color: ${colors.gray};
      content: '•';
    }

    &:hover {
      &:before {
        color: ${colors.black};
      }
    }
  }

  @media screen and (max-width: ${medias.desktopSm}) {
    position: relative;

    li {
      border-bottom: 1px solid ${colors.whiteGray};

      &:last-child {
        border-bottom: 0px;
      }
    }
  }
`;
