import { AxiosReports, AxiosReportLas } from '../config';

const OccurrenceService = {
  validateLogin: login => {
    return AxiosReportLas({
      method: 'post',
      url: `/v1/Relatorios/ValidarEmailUsuario?login=${login}`,
      headers: {
        'content-type': 'application/json',
      },
    });
  },
  sendExcelByEmail: ({
    empresa,
    login,
    dataInicial,
    dataFinal,
    unidades,
    ocorrenciasEmAberto,
    ocorrenciasAuditoria,
    ocorrenciasAtrasadas,
    sistemas,
    statusOcorrencias,
    materiais,
  }) => {
    return AxiosReportLas({
      method: 'post',
      headers: {
        'Content-Type': 'application/json',

        EmpresaId: empresa,
      },
      url: '/v1/Relatorios/Ocorrencias',
      data: {
        login,
        empresa,
        dataInicial,
        dataFinal,
        listUnidadesId: unidades,
        ocorrenciasEmAberto,
        ocorrenciasAuditoria,
        ocorrenciasAtrasadas,
        sistemas,
        statusOcorrencias,
        materiais,
      },
    });
  },
  generatePDF: ({
    companyId,
    unitGroups,
    unitSubgroups,
    states,
    systems,
    units,
    showOnlyOpenOccurrences,
    showAuditOccurrence,
    showOnlyDelayedOccurrences,
    period,
    status,
    people,
    local,
    prioritization,
    occurrences,
    corrections,
    metrics,
    userId,
    token,
  }) => {
    return AxiosReports({
      method: 'post',
      responseType: 'blob',
      url: `occurrences/pdf/${companyId}`,
      data: {
        unitGroups,
        unitSubgroups,
        states,
        systems,
        units,
        showOnlyOpenOccurrences,
        showAuditOccurrence,
        showOnlyDelayedOccurrences,
        period,
        status,
        people,
        local,
        prioritization,
        occurrences,
        corrections,
        metrics,
        userId,
        token,
      },
    });
  },
};

export { OccurrenceService };
