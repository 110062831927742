/* istanbul ignore file */

import styled from 'styled-components';

import { DefaultForm } from 'assets/styled/sheets/components/Forms';
import { fonts, colors } from 'assets/styled/tokens';

export const StyledTitle = styled.div`
  padding: 1.125rem;
  border-bottom: 1px solid ${colors.whiteGray};
  color: ${colors.primary};
  background-color: ${colors.gallery};

  h1 {
    padding-left: 45px;
    font-family: ${fonts.family.title};
    font-size: 1rem;
    text-transform: uppercase;
  }
`;

export const StyledForm = styled.form`
  ${DefaultForm}
  border: 1px solid ${colors.whiteGray};
  border-radius: 6px;
  background-color: white;

  fieldset {
    padding: 1rem;
  }
`;

export const StyledFields = styled.fieldset`
  padding-bottom: 0;
`;

export const StyledActions = styled.fieldset`
  border-top: 1px solid ${colors.whiteGray};
  text-align: right;
`;
