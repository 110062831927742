import { ReactComponent as UnitIcon } from 'assets/icons/building.svg';
import { StyledFlexColumn } from 'components/StyledUtils/flex';
import {
  StyledGrayStrongText,
  StyledDailyPendingActivitiesAndOccurrences,
  StyledOccurrencesDetails,
  StyledOccurrencesCountIcon,
  StyledOccurrencesDetailsBg,
  StyledOccurrencesDetailsContainer,
  StyledViewOccurrencesButton,
  StyledHorizontalLine,
} from 'pages/Geolocalize/styled';
import React from 'react';
import { StyledFlexWrapper } from 'utils/helpers/css';

import {
  StyledDailyPendingContainer,
  StyledSinglePendingEventRow,
  StyledOccurrencesCount,
  StyledActivitiesCount,
  StyledUnitCard,
  StyledUnitGroupName,
  StyledUnitInfoContainer,
  StyledUnitInfoRow,
  StyledUnitName,
} from './styled';

const UnitCard = ({
  id = null,
  name = '',
  unitGroupName = null,
  activitiesCount = null,
  occurrences = null,
  onClick,
}) => {
  const hasOccurrencesObject = occurrences && occurrences.length > 0;
  let isOccurrencesActive = false;

  if (hasOccurrencesObject) {
    isOccurrencesActive = occurrences[0].analysed > 0 || occurrences[0].notAnalysed > 0;
  }

  const occurrencesCount = isOccurrencesActive && occurrences[0].analysed + occurrences[0].notAnalysed;
  const isActivitiesActive = activitiesCount > 0;
  return (
    <StyledUnitCard key={id} onClick={onClick}>
      <StyledUnitInfoRow>
        <UnitIcon />
        <StyledUnitInfoContainer>
          {unitGroupName && <StyledUnitGroupName>{unitGroupName}</StyledUnitGroupName>}
          {name && <StyledUnitName>{name}</StyledUnitName>}
        </StyledUnitInfoContainer>
      </StyledUnitInfoRow>
      {isOccurrencesActive ? (
        <StyledDailyPendingContainer>
          <StyledHorizontalLine />
          <StyledOccurrencesDetailsContainer>
            <StyledFlexColumn>
              <StyledDailyPendingActivitiesAndOccurrences>Pendente hoje:</StyledDailyPendingActivitiesAndOccurrences>
              <StyledFlexWrapper alignItems="center">
                <StyledActivitiesCount>
                  <b>{activitiesCount}</b>
                  <StyledGrayStrongText>Atividades</StyledGrayStrongText>
                </StyledActivitiesCount>
                <StyledOccurrencesCount>
                  <StyledFlexWrapper alignItems="center">
                    <StyledOccurrencesCountIcon
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle cx="8" cy="8" r="8" fill="#F38A00" />
                      <path
                        d="M6.22266 3.16262L6.54589 7.58095C6.54589 7.86053 6.7075 8.00033 7.03074 8.00033H8.97013C9.29336 8.00033 9.45498 7.86053 9.45498 7.58095L9.77821 3.16262C9.77821 3.01012 9.72434 2.89575 9.6166 2.81949C9.53579 2.71783 9.42804 2.66699 9.29336 2.66699H6.70751C6.57282 2.66699 6.45161 2.71783 6.34387 2.81949C6.26306 2.89575 6.22266 3.01012 6.22266 3.16262Z"
                        fill="white"
                      />
                      <path
                        d="M8.00043 9.77832C8.48997 9.77832 8.9022 9.95867 9.23715 10.3194C9.59786 10.6543 9.77821 11.0666 9.77821 11.5561C9.77821 12.0456 9.59786 12.4708 9.23715 12.8315C8.9022 13.1664 8.48997 13.3339 8.00043 13.3339C7.5109 13.3339 7.08578 13.1664 6.72507 12.8315C6.39013 12.4708 6.22266 12.0456 6.22266 11.5561C6.22266 11.0666 6.39013 10.6543 6.72507 10.3194C7.08578 9.95867 7.5109 9.77832 8.00043 9.77832Z"
                        fill="white"
                      />
                    </StyledOccurrencesCountIcon>
                    <b>{occurrencesCount}</b>
                  </StyledFlexWrapper>
                  <StyledGrayStrongText>Ocorrências</StyledGrayStrongText>
                </StyledOccurrencesCount>
              </StyledFlexWrapper>
            </StyledFlexColumn>
            <StyledOccurrencesDetails>
              <StyledFlexColumn>
                <StyledGrayStrongText>{occurrences[0].analysed} analisada(s)</StyledGrayStrongText>
                <StyledGrayStrongText>{occurrences[0].notAnalysed} não analisada(s)</StyledGrayStrongText>
              </StyledFlexColumn>
              <StyledViewOccurrencesButton
                href="https://app.leankeep.com/Anomalias/ShowAnomaliasTablePage.aspx"
                target="_blank"
                rel="noopener noreferrer"
              >
                Ver ocorrências
              </StyledViewOccurrencesButton>

              <StyledOccurrencesDetailsBg
                width="191"
                height="96"
                viewBox="0 0 191 96"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect x="9.5" y="0.5" width="181" height="95" rx="3.5" stroke="#F38A00" />
                <path d="M2 56L10 64V48L2 56Z" fill="white" />
                <path d="M9.5 64L1.5 56L9.5 48" stroke="#F38A00" />
              </StyledOccurrencesDetailsBg>
            </StyledOccurrencesDetails>
          </StyledOccurrencesDetailsContainer>
        </StyledDailyPendingContainer>
      ) : isActivitiesActive ? (
        <>
          <StyledSinglePendingEventRow>
            <StyledHorizontalLine />
            <StyledActivitiesCount>
              <StyledDailyPendingActivitiesAndOccurrences>Pendente hoje:</StyledDailyPendingActivitiesAndOccurrences>
              <b>{activitiesCount}</b>
              <StyledGrayStrongText>Atividades</StyledGrayStrongText>
            </StyledActivitiesCount>
          </StyledSinglePendingEventRow>
        </>
      ) : (
        <></>
      )}
    </StyledUnitCard>
  );
};

export default UnitCard;
