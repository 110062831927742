import React, { useState, useEffect, useContext, useRef, useCallback, useMemo } from 'react';
import { Prompt } from 'react-router';
import { Controller, useForm } from 'react-hook-form';
import { Link, useParams, useHistory } from 'react-router-dom';

import { colors } from 'assets/styled/tokens';
import { ReactComponent as CalendarIcon } from 'assets/icons/calendar.svg';
import { ReactComponent as ToastWarningIcon } from 'assets/icons/toast-warning-icon.svg';
import { ReactComponent as ModalErrorIcon } from 'assets/icons/icon_error.svg';
import { ReactComponent as WarningIcon } from 'assets/icons/warning-icon.svg';
import { ReactComponent as RightArrow } from 'assets/icons/right-arrow.svg';

import { FiltersService } from 'services/reports';
import { AxiosGlobalFilters } from 'services/main';
import { GeneralPlansService } from 'services/reports/endpoints/GeneralPlansService';

import {
  createDarkSuccessToast,
  createErrorToast,
  createGenericErrorToast,
  createSuccessToast,
  createToast,
  createTrashToast,
} from 'utils/createToast';

import { FiltersContext } from 'context/GlobalFiltersContext';
import { GlobalComponentsContext } from 'context/GlobalComponentsContext';

import { HeaderFiltersPage } from 'containers/HeaderFiltersPage';
//import { Menu } from 'components/Menu';
import { Toast } from 'components/Toast';
import { Loading } from 'components/Loading';
import { PageWrapper } from 'components/PageWrapper';
import { ModalGeneric } from 'components/ModalGeneric';
import { MobileNavbar } from 'components/MobileNavbar';
import { Select } from 'components/Form/subcomponents/Select';

import AddActivityFormModal from '../AddActivityFormModal';
import { PageHeader } from 'components/PageHeader';
import GeneralPlanActivities from 'components/Tables/GeneralPlanActivities';
import ActivitiesTableFrequencyCell from 'components/Tables/GeneralPlanActivities/ActivitiesTableFrequencyCell';
import ActivitiesTableStartDateCell from 'components/Tables/GeneralPlanActivities/ActivitiesTableStartDateCell';
import { GeneralPlanStatusBadge } from 'components/Tables/GeneralPlans/GeneralPlanStatusCell';

import {
  StyledFormGroup,
  StyledPlanInfoHeader,
  StyledSectionNavItemUnderline,
  StyledFormSectionNavbar,
  StyledFormSectionNavItem,
  StyledSchedulePlanWarningPill,
  StyledSchedulePlanButton,
  StyledDeactivatePlanButton,
  StyledEditPlanOptionsContainer,
  StyledModalParagraph,
  StyledExitPageModalButton,
  StyledModalButtons,
  StyledModalCancelButton,
  StyledModalDeactivatePlanButton,
  StyledModalSchedulePlanButton,
  StyledFormsContainer,
  StyledFormSection,
  StyledPlanInfoSection,
  StyledPlanInfoFormSection,
  StyledAddActivitiesButton,
  StyledFormsContainerWrapper,
  StyledFormHeaderContainer,
  StyledFormHeader,
  StyledPlanNameInput,
  StyledEmptyActivitiesHeader,
  StyledEmptyActivitiesContainer,
  StyledEmptyActivitiesButtonsRow,
  StyledEmptyActivitiesIcon,
  StyledReturnFormStepButton,
  StyledSchedulePlanSuccessContainer,
  StyledSchedulePlanSuccessIcon,
  StyledDeleteActivityModalHeader,
  StyledDeleteActivityModalParagraph,
  StyledDeleteActivityButton,
  StyledDeleteActivityModal,
  StyledExitModal,
  StyledDeleteActivityModalButtons,
  StyledCloseDeleteActivityModal,
  StyledSchedulePlanSuccessButton,
  StyledRealizedActivitiesModal,
} from './styled';
import EditGeneralPlanActivityButton from 'components/Tables/GeneralPlans/EditGeneralPlanActivityButton';
import EditActivityFormModal from './EditActivityFormModal';
import { frequencyToPeriodicity, getFrequencyDescription } from '../utils';
import FormApplicationStep from './FormApplicationStep';
import ActivitiesTableDescriptionCell from 'components/Tables/GeneralPlanActivities/ActivitiesTableDescriptionCell';
import {
  StyledSchedulePlanSuccessSubHeader,
  StyledSchedulePlanSuccessHeader,
  StyledDeleteDraftModalParagraph,
  StyledModalDeleteDraftButton,
  StyledModalBody,
} from '../NewGeneralPlanPage/styled';
import { debounce } from 'utils/helpers/debounce';
import { Button } from 'components/Button';
import { DRAFT_PLAN_STATUS, INACTIVE_PLAN_STATUS, SCHEDULED_PLAN_STATUS } from '../constants';
import { NewMenu } from 'components/Menu/NewMenu';

const EditGeneralPlanPage = ({ pathname }) => {
  const fetchIdRef = useRef(0);
  const { headerFiltersContext } = useContext(FiltersContext);
  const { headerFilters } = headerFiltersContext;
  const { globalComponentsContext, setGlobalComponentsContext } = useContext(GlobalComponentsContext);
  const { push } = useHistory();

  const { isLoading } = globalComponentsContext;
  const [applicationsPaginatedTableData, setApplicationsPaginatedTableData] = useState([]);
  const [isPageBlocked, setIsPageBlocked] = useState(true);
  const [isPageBlockedModalActive, setIsPageBlockedModalActive] = useState(false);
  const [blockedNextLocation, setBlockedNextLocation] = useState(false);
  const [activeDeleteActivityId, setActiveDeleteActivityId] = useState(undefined);
  const [isSchedulePlanModalOpen, setIsSchedulePlanModalOpen] = useState(false);
  const [isSchedulePlanSuccess, setIsSchedulePlanSuccess] = useState(false);
  const [isSchedulePlanLoading, setIsSchedulePlanLoading] = useState(false);
  const [isDeactivatePlanLoading, setIsDeactivatePlanLoading] = useState(false);

  const [isDeactivatePlanModalOpen, setIsDeactivatePlanModalOpen] = useState(false);
  const [isDeleteDraftModalOpen, setIsDeleteDraftModalOpen] = useState(false);
  // eslint-disable-next-line

  const [editActivityId, setEditActivityId] = useState(undefined);
  const [hasActivitiesUpdated, setHasActivitiesUpdated] = useState(undefined);

  const [isPlanInfoFormDirty, setIsPlanInfoFormDirty] = useState(false);
  const [isAddActivityModalActive, setIsAddActivityModalActive] = useState(false);
  const [isRealizedActivitiesErrorModalActive, setIsRealizedActivitiesErrorModalActive] = useState(false);
  const [isExecutedByProvider, setIsExecutedByProvider] = useState(false);

  const [pageCount, setPageCount] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [activeFormStep, setActiveFormStep] = useState(1);
  const [activitiesTablePageSize, setActivitiesTablePageSize] = useState(5);
  const [activitiesTablePageIndex, setActivitiesTablePageIndex] = useState(0);
  const [isDeleteActivityModalActive, setIsDeleteActivityModalActive] = useState(false);

  const [errors, setErrors] = useState({});
  const [toastList, setToastList] = useState([]);
  const [finishedFormSteps, setFinishedFormSteps] = useState([]);
  const [activitiesTableData, setActivitiesTableData] = useState([]);
  const [fields, setFields] = useState({
    description: [],
    system: [],
    department: [],
    activities: [],
    plan: undefined,
  });
  let { id: planId } = useParams();
  const columns = [
    {
      Header: 'Descrição',
      disableSortBy: true,
      accessor: 'descricao', // accessor is the "key" in the data
      Cell: props => <ActivitiesTableDescriptionCell {...props} />,
    },
    {
      Header: 'Frequência',
      accessor: 'frequency',
      disableSortBy: true,
      Cell: props => <ActivitiesTableFrequencyCell {...props} />,
    },
    {
      Header: 'Data / Hora início',
      accessor: 'startDateTime',
      disableSortBy: true,
      Cell: props => <ActivitiesTableStartDateCell {...props} />,
    },
    // {
    //   Header: 'Material',
    //   accessor: 'material',
    //   Cell: (props) => <ActivitiesTableMaterialCell {...props} />
    // },
    {
      Header: 'Categoria',
      disableSortBy: true,
      accessor: 'category',
    },

    {
      Header: 'Ação',
      accessor: 'atividadeGeral',
      disableSortBy: true,
      Cell: props => (
        <EditGeneralPlanActivityButton
          setEditActivityId={setEditActivityId}
          handleRemoveActivity={handleRemoveActivity}
          {...props}
        />
      ),
    },
  ];
  const handleCloseDeleteDraftModal = () => {
    setIsDeleteDraftModalOpen(false);
  };

  const handleDeleteDraft = () => {
    setIsDeleteDraftModalOpen(true);
  };

  const {
    // handleSubmit,
    register,
    setValue,
    control,
    getValues,
  } = useForm({
    defaultValues: {
      extraApplications: 'onlyMaintenanceUnits',
      planName: '',
      system: '',
      department: '',
    },
  });

  const blockedNavigation = nLocation => {
    //nLocation gives the next location object
    // block redirect if plan hasnt been scheduled succesfully
    if (isPageBlocked) {
      setIsPageBlockedModalActive(true);
      setBlockedNextLocation(nLocation.pathname);
      //required to block navigation
      return false;
    }
  };
  const handleRemoveActivity = useCallback(
    id => {
      if (id) {
        setActiveDeleteActivityId(id);
        setIsDeleteActivityModalActive(true);
      } else {
        GeneralPlansService.deletePlanActivity({
          planId: planId,
          companyId: headerFilters?.companies?.id,
          activityId: activeDeleteActivityId,
        })
          .then(() => {
            setHasActivitiesUpdated(true);
            createTrashToast(setToastList, 'Atividade excluída.');
          })
          .catch(() => {
            createGenericErrorToast(setToastList);
          })
          .finally(() => {
            setActiveDeleteActivityId(undefined);
            setIsDeleteActivityModalActive(false);
          });
      }
    },
    [activeDeleteActivityId, headerFilters?.companies?.id, planId],
  );
  const addNewActivity = async activity => {
    const {
      planId,
      category,
      description,
      isConformityActive,
      estimatedTime,
      frequency,
      isMeasurementRequired,
      medicoes,
      repeatEvery,
      priority,
      startTime,
      saturdayExcChecked,
      sundayExcChecked,
      specificDay,
      specificDays,
      holidaysExcChecked,
      startDate,
    } = activity;
    let startDateTime = startDate;
    const hours = startTime.split(':')[0];
    const minutes = startTime.split(':')[1];
    startDateTime.setUTCHours(hours);
    startDateTime.setMinutes(minutes);
    startDateTime = startDateTime.toISOString();

    const estimatedTimeHours = estimatedTime.split(':')[0];
    const estimatedTimeMinutes = estimatedTime.split(':')[1];
    let _estimatedTime = estimatedTimeHours * 60 + parseInt(estimatedTimeMinutes);
    let _specificDays;
    if (specificDays) {
      _specificDays = specificDays.map(day => ({ [day]: true }));
    } else {
      if (frequency === 'daily') {
        let singleSpecificDays = ['segunda', 'terca', 'quarta', 'quinta', 'sexta', 'sabado', 'domingo'];
        if (saturdayExcChecked === true) {
          _specificDays = singleSpecificDays.map(day => {
            if (day !== 'sabado') {
              return { [day]: true };
            } else {
              return { [day]: false };
            }
          });
        } else if (sundayExcChecked === true) {
          _specificDays = singleSpecificDays.map(day => {
            if (day !== 'domingo') {
              return { [day]: true };
            } else {
              return { [day]: false };
            }
          });
        } else {
          _specificDays = singleSpecificDays.map(day => ({ [day]: true }));
        }
      } else {
        _specificDays = [specificDay].map(day => ({ [day]: true }));
      }
    }

    let formattedData = {
      companyId: headerFilters?.companies?.id,
      planoGeral: planId,
      prioridade: priority,
      descricao: description,
      categoria: category,
      ...(editActivityId && { atividadeGeral: editActivityId }),

      tempoPrevisto: parseInt(_estimatedTime),
      inicio: startDateTime,
      periodicidade: frequencyToPeriodicity(frequency),
      multiplicador: repeatEvery ? parseInt(repeatEvery) : 1,
      conformidadeObrigatoriaBaixa: isConformityActive,
      medicaoObrigatoriaBaixa: isMeasurementRequired,
      // exc false when checkbox selected
      sabadoExc: saturdayExcChecked === true ? false : true,
      domingoExc: sundayExcChecked === true ? false : true,
      feriadosExc: holidaysExcChecked === true ? false : true,
      tipoValidacao: isConformityActive ? 1 : null,
    };
    if (medicoes[0]?.unidadeMedicao) {
      formattedData.medicoes = medicoes;
    }
    Object.assign(formattedData, ..._specificDays);

    return await GeneralPlansService.addGeneralPlanActivity(formattedData)
      .then(() => {
        setHasActivitiesUpdated(true);
      })
      .catch(e => console.error(e));

    // const activeCount = newActivities.length;
    // setTotalCount(activeCount);
    // setPageCount(Math.ceil(activeCount / activitiesTablePageSize));
  };

  const handleForceRedirect = location => {
    window.onbeforeunload = null;
    window.location.replace(location);
  };

  const validateRequiredFields = () => {
    const planName = getValues('planName');

    if (planName.length === 0) {
      setErrors({ ...errors, planName: { error: 'required' } });
    }

    return false;
  };

  // const onSubmit = (values, e) => {
  //   const hasError = validateRequiredFields();
  //   if (hasError) {
  //     return;
  //   }

  //   const payload = formatPayload(values);
  // };
  const handleEditGeneralPlanInfo = async () => {
    setIsPlanInfoFormDirty(true);
    const hasError = validatePlanInfoForm();
    if (hasError) {
      return;
    }
    advanceFormStep();
  };
  const validatePlanInfoForm = () => {
    const planName = getValues('planName');
    const system = getValues('system');
    const department = getValues('department');
    const requiredFields = [planName, system, department];
    const invalidFieldsCount = requiredFields.filter(element => element?.length === 0).length;

    if (!headerFilters?.companies?.id) {
      setToastList(prevState => [
        ...prevState,

        createToast({
          type: 'error',

          message: 'Uma empresa não foi selecionada nos filtros',
        }),
      ]);

      return true;
    } else if (invalidFieldsCount === 1) {
      setToastList(prevState => [
        ...prevState,

        createToast({
          type: 'error',

          message: 'Um campo obrigatório não foi preenchido.',
        }),
      ]);

      return true;
    } else if (invalidFieldsCount > 1) {
      setToastList(prevState => [
        ...prevState,

        createToast({
          type: 'error',

          message: 'Há campos obrigatórios não preenchidos.',
        }),
      ]);
      return true;
    }

    return false;
  };

  const populateFieldsStrategy = async () => {
    // TODO hoist companyID up to remove this implicit repopulation on companyId change
    const companyId = headerFilters?.companies?.id;

    if (companyId) {
      return await Promise.all([
        FiltersService.getUnits({
          companyId,
        }),
        FiltersService.getSystems({ companyId }),
        FiltersService.getCompanyDepartments({ companyId }),
        GeneralPlansService.getPlan({
          planId,
          companyId,
        }),
      ]).then(values => ({
        units: values[0].data.map(({ nome, site }) => ({
          label: nome,
          value: site,
        })),
        system: values[1].data.map(({ nome, sistemaEmpresa }) => ({
          label: nome,
          value: sistemaEmpresa,
        })),
        departments: values[2].data.map(({ nome, setor }) => ({
          label: nome,
          value: setor,
        })),
        plan: values[3].data,
      }));
    }
  };

  const populateFields = async () => {
    try {
      const fieldsData = await populateFieldsStrategy();
      // TODO refactor to not use only use fields values instead of setValue
      setFields(prevState => ({
        ...prevState,
        ...fieldsData,
      }));
      if (fieldsData?.plan.nome) {
        setValue('planName', fieldsData.plan.nome);
      }
      if (fieldsData?.plan.sistemaEmpresa) {
        setValue('system', fieldsData.plan.sistemaEmpresa);
      }
      if (fieldsData?.plan.setor) {
        setValue('department', fieldsData.plan.setor);
      }
    } catch (err) {
      console.error(err);
      createGenericErrorToast(setToastList);
    }
  };

  const fetchData = async () => {
    setGlobalComponentsContext(prevState => ({
      ...prevState,
      isLoading: true,
    }));
    // await cleanFields();
    await populateFields();
    setGlobalComponentsContext(prevState => ({
      ...prevState,
      isLoading: false,
    }));
  };

  const isStepFinished = stepNumber => {
    if (finishedFormSteps.includes(stepNumber)) {
      return true;
    } else {
      return false;
    }
  };
  const returnFormStep = () => {
    setActiveFormStep(activeFormStep - 1);
  };
  const advanceFormStep = () => {
    if (activeFormStep === 0) {
    }
    if (activeFormStep === 3) {
      // const editPlanData = {
      //   planName: getValues('planName'),
      //   system: getValues('system'),
      //   department: getValues('department'),
      //   extraApplications: getValues('extraApplications'),
      //   savedPlanActivities: savedPlanActivities
      // };
    }

    if (activeFormStep <= 2) {
      let newFinishedFormSteps = finishedFormSteps;
      finishedFormSteps.push(activeFormStep);

      setFinishedFormSteps(newFinishedFormSteps);
      setActiveFormStep(activeFormStep + 1);
    }
  };
  const formatDate = datetime => {
    let date = new Date(datetime);
    let day = date.getDate();
    let _day = day < 10 ? '0' + day : day;
    let month = date.getMonth() + 1; //+1 pois no getMonth Janeiro começa com zero.
    let _month = month < 10 ? '0' + month : month;
    let _year = date.getFullYear();
    let _date = _day + '/' + _month + '/' + _year;
    let hour = date.getHours();
    let _hours = hour < 10 ? '0' + hour : hour;
    let minutes = date.getMinutes();
    let _minutes = minutes < 10 ? '0' + minutes : minutes;
    let formattedDatetime = `${_date} • ${_hours}:${_minutes}`;
    return formattedDatetime;
  };
  const fetchGeneralPlanActivities = useCallback(
    async ({ pageSize, sortBy }) => {
      // Give this fetch an ID
      const fetchId = ++fetchIdRef.current;

      // Set the loading state

      let params = {
        PageIndex: activitiesTablePageIndex + 1,
        PageSize: pageSize,
      };
      let headers;
      if (headerFilters?.companies?.id) {
        headers = {
          EmpresaId: headerFilters?.companies?.id,
        };
      } else {
        headers = undefined;
      }

      if (sortBy[0]?.id) {
        params = {
          ...params,
          'OrderBy.Column': sortBy[0].id,
          'OrderBy.Desc': sortBy[0].desc,
        };
      }

      // Only update the data if this is the latest fetch
      if (fetchId === fetchIdRef.current) {
        await Promise.all([
          AxiosGlobalFilters({
            method: 'get',
            url: `/v1/planosgerais/${planId}/AtividadesGerais`,
            headers: headers,
            params: params,
          }),
          AxiosGlobalFilters({
            method: 'get',
            url: `/v1/planosgerais/${planId}/AtividadesGerais/count`,
            headers: headers,
          }),
        ])
          .then(res => {
            const data = res[0].data;

            const adaptedData = data.map(plan => {
              return {
                ...plan,
                frequency: getFrequencyDescription(plan),
                startDateTime: formatDate(plan.inicio),
                category: plan.categoriaNome,
              };
            });

            setActivitiesTableData(adaptedData.sort());
            setPageCount(Math.ceil(res[1].data / pageSize));

            setTotalCount(res[1].data);
          })
          .catch(err => {
            console.error(err);
            setToastList(prevState => [
              ...prevState,
              createToast({
                type: 'error',
                message: 'Ocorreu um erro ao carregar as atividades. Tente novamente.',
              }),
            ]);
          });
      }
    },
    [headerFilters?.companies?.id, activitiesTablePageIndex, planId],
  );

  const handleEditActivity = async activity => {
    return await addNewActivity(activity)
      .then(() => {
        setHasActivitiesUpdated(true);
        createSuccessToast(setToastList, 'Edição concluída.');
      })
      .catch(e => {
        console.error(e);
        createGenericErrorToast(setToastList);
      });
  };
  const handleCloseDeactivatePlanModal = () => {
    setIsDeactivatePlanModalOpen(false);
  };

  const deactivatePlan = async () => {
    setIsDeactivatePlanLoading(true);

    try {
      const { data } = await GeneralPlansService.deactivatePlan({
        planId: planId,
        companyId: headerFilters?.companies?.id,
      });
      if (data === 'Plano atualizado com sucesso.') {
        handleCloseDeactivatePlanModal();
        createDarkSuccessToast(setToastList, 'O plano foi inativado.');

        setFields(prevState => ({
          ...prevState,
          plan: { ...prevState.plan, statusPlanoGeral: INACTIVE_PLAN_STATUS },
        }));
      } else {
        throw new Error('Unexpected backend status');
      }
    } catch (e) {
      console.error(e);
      createGenericErrorToast(setToastList);
    } finally {
      setIsDeactivatePlanLoading(false);
      handleCloseDeactivatePlanModal();
    }
  };

  const deleteDraft = async () => {
    try {
      const { data } = await GeneralPlansService.deletePlan({
        planId: planId,
        companyId: headerFilters?.companies?.id,
      });

      if (data === 'Plano excluído com sucesso.') {
        createTrashToast(setToastList, 'Rascunho descartado.');
      } else {
        throw new Error('Unexpected backend status');
      }
    } catch (e) {
      console.error(e);
      createGenericErrorToast(setToastList);
    } finally {
      handleCloseDeleteDraftModal();
      setIsPageBlocked(false);
      setTimeout(() => push('/atividades/planos-gerais/novo'), 3000);
    }
  };

  const toggleDeactivatePlanModal = async () => {
    setIsDeactivatePlanModalOpen(!isDeactivatePlanModalOpen);
  };
  const handlePlanInfoInputChange = debounce(async () => {
    await GeneralPlansService.updatePlan({
      name: getValues('planName'),
      system: getValues('system'),
      department: getValues('department'),
      planId,
      companyId: headerFilters?.companies?.id,
    });
  }, 1000);

  const getInvalidSites = async () => {
    try {
      const res = await GeneralPlansService.validateSites({
        planId,
        companyId: headerFilters?.companies?.id,
      });
      return res.data.data;
    } catch (e) {
      createGenericErrorToast(setToastList);
      return e;
    }
  };
  const handleSchedulePlan = async () => {
    let invalidFieldsCount = 0;
    if (isExecutedByProvider) {
      let invalidSites = await getInvalidSites();

      invalidFieldsCount += invalidSites ? invalidSites.length : 0;
    }
    if (invalidFieldsCount === 0) {
      setIsSchedulePlanLoading(true);
      try {
        const { data } = await GeneralPlansService.schedulePlan({
          planId,
          companyId: headerFilters?.companies?.id,
        });
        if (
          data === 'Plano foi atualizado e enviado para fila de agendamento.' ||
          data === 'Plano gerado e enviado para fila de agendamento.'
        ) {
          setIsSchedulePlanSuccess(true);
          setIsPageBlocked(false);
        } else if (
          data ===
          'Este plano possui atividades baixadas e por isso não pode ser agendado. Estamos trabalhando em melhorias para tornar esse agendamento possível, em breve.'
        ) {
          setIsRealizedActivitiesErrorModalActive(true);
        } else {
          throw new Error(data);
        }
      } catch (e) {
        createGenericErrorToast(setToastList);
      } finally {
        setIsSchedulePlanLoading(false);

        setIsSchedulePlanModalOpen(false);
      }
    } else {
      setIsSchedulePlanModalOpen(false);
      createErrorToast(
        setToastList,
        invalidFieldsCount === 1
          ? 'Um campo obrigatório não foi preenchido.'
          : 'Há campos obrigatórios não preenchidos.',
      );
    }
  };

  const isSchedulePlanReady = useMemo(() => {
    return activeFormStep === 3 && applicationsPaginatedTableData.length > 0;
  }, [activeFormStep, applicationsPaginatedTableData]);

  useEffect(() => {
    fetchData();

    if (!headerFilters?.companies?.id) {
      handleForceRedirect('/atividades/planos-gerais');
    }

    // TODO remove this implicit rerender
    // eslint-disable-next-line
  }, [activitiesTablePageIndex, headerFilters?.companies?.id]);
  window.onbeforeunload = () => {
    if (isPageBlocked && !isSchedulePlanSuccess) {
      return '';
    }
  };

  const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);
  return (
    <>
      {isLoading && <Loading />}
      <PageWrapper background={colors.pampas}>
        <MobileNavbar />
        {/* <Menu /> */}
        <NewMenu setIsSubMenuOpen={setIsSubMenuOpen} />
        <HeaderFiltersPage location={pathname} isSubMenuOpen={isSubMenuOpen}>
          <Prompt when={!isSchedulePlanSuccess} message={blockedNavigation} />
          <PageHeader title="Planos Gerais" />

          <StyledFormsContainerWrapper>
            <StyledFormHeaderContainer>
              <Link to="/atividades/planos-gerais">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="12" cy="12" r="11.5" stroke="#E5E5E5" />
                  <path d="M17 12H7" stroke="#BBBBBB" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                  <path
                    d="M11 8L7 12L11 16"
                    stroke="#BBBBBB"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </Link>
              <StyledFormHeader>Editar Plano Geral</StyledFormHeader>
              <StyledEditPlanOptionsContainer>
                {!isSchedulePlanSuccess && fields.plan?.statusPlanoGeral !== INACTIVE_PLAN_STATUS && (
                  <StyledDeactivatePlanButton
                    onClick={() =>
                      fields.plan?.statusPlanoGeral === DRAFT_PLAN_STATUS
                        ? handleDeleteDraft()
                        : toggleDeactivatePlanModal()
                    }
                  >
                    {fields.plan?.statusPlanoGeral === DRAFT_PLAN_STATUS
                      ? `Descartar rascunho`
                      : fields.plan?.statusPlanoGeral === SCHEDULED_PLAN_STATUS
                      ? `Inativar plano`
                      : ''}
                  </StyledDeactivatePlanButton>
                )}

                {/* extract this logic */}
                <StyledSchedulePlanWarningPill isActive={isSchedulePlanReady && !isSchedulePlanSuccess}>
                  <WarningIcon />
                  As alterações serão aplicadas ao agendar o plano
                  <RightArrow />
                </StyledSchedulePlanWarningPill>
                <StyledSchedulePlanButton
                  isActive={isSchedulePlanReady && !isSchedulePlanSuccess}
                  onClick={() => setIsSchedulePlanModalOpen(true)}
                >
                  <CalendarIcon />
                  Agendar plano
                </StyledSchedulePlanButton>
              </StyledEditPlanOptionsContainer>
            </StyledFormHeaderContainer>
            {isSchedulePlanSuccess ? (
              <StyledSchedulePlanSuccessContainer>
                <StyledSchedulePlanSuccessIcon></StyledSchedulePlanSuccessIcon>
                <StyledSchedulePlanSuccessHeader>
                  Estamos agendando seu plano de atividades atualizado
                </StyledSchedulePlanSuccessHeader>
                <StyledSchedulePlanSuccessSubHeader>
                  Isso pode demorar um pouco. Continue suas tarefas enquanto isso.
                </StyledSchedulePlanSuccessSubHeader>
                <StyledSchedulePlanSuccessButton onClick={() => push('/atividades/planos-gerais')} theme="white">
                  Voltar à tela de planos
                </StyledSchedulePlanSuccessButton>
              </StyledSchedulePlanSuccessContainer>
            ) : (
              <StyledFormsContainer>
                <StyledFormSectionNavbar>
                  <StyledFormSectionNavItem
                    isActive={activeFormStep === 1}
                    isFinished={isStepFinished(1)}
                    onClick={() => setActiveFormStep(1)}
                  >
                    Dados do plano
                    <StyledSectionNavItemUnderline />
                  </StyledFormSectionNavItem>

                  <StyledFormSectionNavItem
                    isFinished={isStepFinished(2)}
                    isActive={activeFormStep === 2}
                    onClick={() =>
                      activeFormStep === 1
                        ? handleEditGeneralPlanInfo()
                        : activeFormStep === 3
                        ? returnFormStep()
                        : () => {}
                    }
                  >
                    Atividades
                    <StyledSectionNavItemUnderline />
                  </StyledFormSectionNavItem>

                  <StyledFormSectionNavItem
                    isFinished={isStepFinished(3)}
                    isActive={activeFormStep === 3}
                    onClick={() => setActiveFormStep(3)}
                  >
                    Aplicação
                    <StyledSectionNavItemUnderline />
                  </StyledFormSectionNavItem>
                </StyledFormSectionNavbar>

                <StyledPlanInfoSection isActive={activeFormStep === 1}>
                  <StyledPlanInfoFormSection>
                    <StyledPlanInfoHeader>
                      Dados do plano <GeneralPlanStatusBadge type={fields.plan?.statusPlanoGeral} />
                    </StyledPlanInfoHeader>
                    <Controller
                      render={props => (
                        <StyledPlanNameInput
                          {...props}
                          label="Nome do plano"
                          isRequired
                          onChange={e => {
                            props.onChange(e);
                            handlePlanInfoInputChange(e);
                          }}
                          type="text"
                          onBlur={() => validateRequiredFields()}
                          autoComplete="off"
                          name="planName"
                          maxLength="50"
                          error={errors?.planName}
                          errormessage="Campo obrigatório"
                          size="small"
                          placeholder="Preenchimento obrigatório"
                        />
                      )}
                      name="planName"
                      control={control}
                    />
                    <StyledFormGroup
                      label="Sistema"
                      isRequired
                      disabled={fields?.plan?.statusPlanoGeral === 'Agendado' ? true : false}
                      showTooltip={fields?.plan?.statusPlanoGeral === 'Agendado' ? true : false}
                      tooltipMessage="Não editável após o agendamento"
                    >
                      <Controller
                        render={props => (
                          <Select
                            size="small"
                            {...props}
                            placeholder="Seleção obrigatória"
                            name="system"
                            disabled={fields?.plan?.statusPlanoGeral === 'Agendado' ? true : false}
                            options={fields?.system}
                            error={isPlanInfoFormDirty && props.value.length === 0}
                            errormessage="Campo obrigatório"
                          />
                        )}
                        name="system"
                        control={control}
                      />
                    </StyledFormGroup>
                    <StyledFormGroup
                      label="Setor"
                      isRequired
                      disabled={fields?.plan?.statusPlanoGeral === 'Agendado' ? true : false}
                      showTooltip={fields?.plan?.statusPlanoGeral === 'Agendado' ? true : false}
                      tooltipMessage="Não editável após o agendamento"
                    >
                      <Controller
                        render={props => (
                          <Select
                            size="small"
                            disabled={fields?.plan?.statusPlanoGeral === 'Agendado' ? true : false}
                            {...props}
                            error={isPlanInfoFormDirty && props.value.length === 0}
                            errormessage="Campo obrigatório"
                            placeholder="Seleção obrigatória"
                            name="department"
                            options={fields?.departments}
                          />
                        )}
                        name="department"
                        control={control}
                      />
                    </StyledFormGroup>
                  </StyledPlanInfoFormSection>
                </StyledPlanInfoSection>
                <StyledFormSection isActive={activeFormStep === 2}>
                  <GeneralPlanActivities
                    setIsAddActivityModalActive={setIsAddActivityModalActive}
                    activitiesTablePageSize={activitiesTablePageSize}
                    setActivitiesTablePageSize={setActivitiesTablePageSize}
                    columns={columns}
                    data={activitiesTableData}
                    hasActivitiesUpdated={hasActivitiesUpdated}
                    setHasActivitiesUpdated={setHasActivitiesUpdated}
                    setEditActivityId={setEditActivityId}
                    planId={planId}
                    fetchData={fetchGeneralPlanActivities}
                    pageIndex={activitiesTablePageIndex}
                    setPageIndex={setActivitiesTablePageIndex}
                    loading={isLoading}
                    totalCount={totalCount}
                    pageCount={pageCount}
                  />
                  {activitiesTableData.length === 0 && (
                    <StyledEmptyActivitiesContainer>
                      <StyledEmptyActivitiesIcon
                        width="80"
                        height="88"
                        viewBox="0 0 80 88"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M1.75436e-05 30.1139V57.8454C-0.00416577 60.7046 0.739864 63.5143 2.15712 65.9914C3.57437 68.4686 5.61477 70.5256 8.07267 71.9552L31.9273 85.8209C34.3817 87.2485 37.1659 88 40 88C42.8341 88 45.6183 87.2485 48.0727 85.8209L71.9273 71.9552C74.3852 70.5256 76.4256 68.4686 77.8429 65.9914C79.2601 63.5143 80.0042 60.7046 80 57.8454V30.1139C79.9971 27.2617 79.2497 24.4603 77.8328 21.9909C76.4159 19.5216 74.3793 17.471 71.9273 16.0448L48.0727 2.17907C45.6183 0.751537 42.8341 0 40 0C37.1659 0 34.3817 0.751537 31.9273 2.17907L8.07267 16.2481C5.65006 17.6568 3.63231 19.6755 2.21715 22.1062C0.802002 24.5368 0.0379999 27.2962 1.75436e-05 30.1139Z"
                          fill="#EAE8E5"
                        />
                        <path
                          d="M61.7241 50.0833C62.4885 48.75 61.5712 47 60.1188 47H29.619C27.7844 47 26.1792 48.0833 25.3383 49.6667L19.8346 60C19.0702 61.3333 19.9875 63 21.4398 63H51.9397C53.7743 63 55.3795 62 56.2204 60.4167L61.7241 50.0833ZM29.619 44.3333H54.6915V40.3333C54.6915 38.1667 53.0098 36.3333 51.0224 36.3333H38.7919L33.8997 31H21.6692C19.6053 31 18 32.8333 18 35V58.25L23.2744 48.3333C24.5739 45.9167 26.9436 44.3333 29.619 44.3333Z"
                          fill="#AAAAAA"
                        />
                        <rect x="49" y="18" width="3" height="8" rx="1.5" fill="#AAAAAA" />
                        <rect x="66" y="32" width="3" height="8" rx="1.5" transform="rotate(90 66 32)" fill="#AAAAAA" />
                        <rect
                          x="60.6567"
                          y="21"
                          width="3"
                          height="8"
                          rx="1.5"
                          transform="rotate(45 60.6567 21)"
                          fill="#AAAAAA"
                        />
                      </StyledEmptyActivitiesIcon>

                      <StyledEmptyActivitiesHeader>
                        Adicione as atividades que devem ser executadas
                      </StyledEmptyActivitiesHeader>
                      <StyledEmptyActivitiesButtonsRow>
                        <StyledReturnFormStepButton onClick={() => returnFormStep()} form="editPlan" type="submit">
                          <svg
                            width="12"
                            height="10"
                            viewBox="0 0 12 10"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M11 5H1"
                              stroke={colors.carbon}
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M5 1L1 5L5 9"
                              stroke={colors.carbon}
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                          Voltar
                        </StyledReturnFormStepButton>

                        <StyledAddActivitiesButton onClick={() => setIsAddActivityModalActive(true)}>
                          <svg
                            width="12"
                            height="12"
                            viewBox="0 0 12 12"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M1 6H11" stroke="white" strokeWidth="2" strokeLinecap="round" />
                            <path d="M6 1V11" stroke="white" strokeWidth="2" strokeLinecap="round" />
                          </svg>
                          Adicionar Atividade
                        </StyledAddActivitiesButton>
                      </StyledEmptyActivitiesButtonsRow>
                    </StyledEmptyActivitiesContainer>
                  )}
                </StyledFormSection>
                <FormApplicationStep
                  planStatus={fields.plan?.statusPlanoGeral}
                  setIsExecutedByProvider={setIsExecutedByProvider}
                  applicationsPaginatedTableData={applicationsPaginatedTableData}
                  setApplicationsPaginatedTableData={setApplicationsPaginatedTableData}
                  isActive={activeFormStep === 3}
                  planId={planId}
                  companyId={headerFilters?.companies?.id}
                  getValues={getValues}
                  setValue={setValue}
                  register={register}
                  control={control}
                  returnFormStep={returnFormStep}
                  errors={errors}
                  setToastList={setToastList}
                  createToast={createToast}
                  advanceFormStep={advanceFormStep}
                  setGlobalComponentsContext={setGlobalComponentsContext}
                />
              </StyledFormsContainer>
            )}
          </StyledFormsContainerWrapper>
        </HeaderFiltersPage>
      </PageWrapper>
      <StyledRealizedActivitiesModal
        hasHeader
        title="Este plano possui atividades baixadas e por isso não pode ser agendado"
        icon={<ModalErrorIcon />}
        size={{ width: '587px' }}
        open={isRealizedActivitiesErrorModalActive}
        closeModal={() => setIsRealizedActivitiesErrorModalActive(false)}
      >
        <StyledModalParagraph>
          Estamos trabalhando em melhorias e em breve o agendamento será possível.
        </StyledModalParagraph>
        <StyledModalButtons>
          <Button type="button" onClick={() => setIsRealizedActivitiesErrorModalActive(false)}>
            Entendi
          </Button>
        </StyledModalButtons>
      </StyledRealizedActivitiesModal>

      <StyledExitModal
        hasHeader
        title="Sair sem aplicar as alterações?"
        icon={<ToastWarningIcon />}
        size={{ width: '587px' }}
        open={isPageBlockedModalActive}
        closeModal={() => setIsPageBlockedModalActive(false)}
      >
        <StyledModalParagraph>Alterações só são aplicadas ao concluir o agendamento do plano.</StyledModalParagraph>
        <StyledModalButtons>
          <StyledExitPageModalButton type="button" theme="white" onClick={() => setIsPageBlockedModalActive(false)}>
            Voltar à edição do plano
          </StyledExitPageModalButton>
          <StyledExitPageModalButton type="button" onClick={() => handleForceRedirect(blockedNextLocation)}>
            Sair mesmo assim
          </StyledExitPageModalButton>
        </StyledModalButtons>
      </StyledExitModal>
      <ModalGeneric
        hasHeader
        title="Lembre-se que..."
        isLoading={isSchedulePlanLoading}
        loadingMessage={'Agendando Plano ...'}
        icon={<ToastWarningIcon />}
        size={{ width: '587px' }}
        open={isSchedulePlanModalOpen}
        closeModal={() => setIsSchedulePlanModalOpen(false)}
      >
        <StyledModalParagraph>
          As atividades não baixadas serão substituídas pelas atividades atualizadas.
        </StyledModalParagraph>
        <StyledModalButtons>
          <StyledModalSchedulePlanButton type="button" onClick={() => handleSchedulePlan()}>
            <CalendarIcon />
            Agendar plano
          </StyledModalSchedulePlanButton>
        </StyledModalButtons>
      </ModalGeneric>
      <ModalGeneric open={isDeleteDraftModalOpen} closeModal={() => handleCloseDeleteDraftModal()}>
        <StyledModalBody
          style={{
            paddingTop: '16px',
            paddingBottom: '16px',
            paddingRight: '10px',
            paddingLeft: '10px',
          }}
        >
          <svg width="80" height="88" viewBox="0 0 80 88" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M1.75436e-05 30.1139V57.8454C-0.00416577 60.7046 0.739864 63.5143 2.15712 65.9914C3.57437 68.4686 5.61477 70.5256 8.07267 71.9552L31.9273 85.8209C34.3817 87.2485 37.1659 88 40 88C42.8341 88 45.6183 87.2485 48.0727 85.8209L71.9273 71.9552C74.3852 70.5256 76.4256 68.4686 77.8429 65.9914C79.2601 63.5143 80.0042 60.7046 80 57.8454V30.1139C79.9971 27.2617 79.2497 24.4603 77.8328 21.9909C76.4159 19.5216 74.3793 17.471 71.9273 16.0448L48.0727 2.17907C45.6183 0.751537 42.8341 0 40 0C37.1659 0 34.3817 0.751537 31.9273 2.17907L8.07267 16.2481C5.65006 17.6568 3.63231 19.6755 2.21715 22.1062C0.802002 24.5368 0.0379999 27.2962 1.75436e-05 30.1139Z"
              fill="#FFDBD6"
            />
            <path
              d="M54.8571 26.5H46.2857L45.5714 25.0938C45.2857 24.4688 44.6429 24 44.0714 24H35.8571C35.2857 24 34.5714 24.4688 34.3571 25.0938L33.7143 26.5H25.1429C24.5 26.5 24 27.125 24 27.75V30.25C24 30.9531 24.5 31.5 25.1429 31.5H54.8571C55.4286 31.5 56 30.9531 56 30.25V27.75C56 27.125 55.4286 26.5 54.8571 26.5ZM27.7857 60.4844C27.8571 62.4375 29.4286 64 31.2143 64H48.7143C50.5 64 52.0714 62.4375 52.1429 60.4844L53.7143 34H26.2857L27.7857 60.4844Z"
              fill="#EC6655"
            />
            <rect x="34" y="42" width="2" height="14" fill="#FFDBD6" />
            <rect x="39" y="42" width="2" height="14" fill="#FFDBD6" />
            <rect x="44" y="42" width="2" height="14" fill="#FFDBD6" />
          </svg>

          <h6>Descartar rascunho?</h6>
          <StyledDeleteDraftModalParagraph>
            Não será possível recuperar o rascunho depois.
          </StyledDeleteDraftModalParagraph>
          <StyledModalButtons>
            <StyledModalCancelButton type="button" onClick={() => handleCloseDeleteDraftModal()}>
              Cancelar
            </StyledModalCancelButton>
            <StyledModalDeleteDraftButton type="button" onClick={() => deleteDraft()}>
              Descartar
            </StyledModalDeleteDraftButton>
          </StyledModalButtons>
        </StyledModalBody>
      </ModalGeneric>
      <ModalGeneric
        size={{ width: '362px' }}
        open={isDeactivatePlanModalOpen}
        isLoading={isDeactivatePlanLoading}
        loadingMessage={'Desativando Plano ...'}
        hasHeader
        icon={<ToastWarningIcon />}
        title="Inativar plano?"
        closeModal={() => setIsDeactivatePlanModalOpen(false)}
      >
        <StyledModalParagraph>As atividades não baixadas serão excluídas.</StyledModalParagraph>
        <StyledModalButtons>
          <StyledModalCancelButton
            type="button"
            theme="lightOutline"
            onClick={() => setIsDeactivatePlanModalOpen(false)}
          >
            Cancelar
          </StyledModalCancelButton>
          <StyledModalDeactivatePlanButton type="button" onClick={() => deactivatePlan()}>
            Inativar plano
          </StyledModalDeactivatePlanButton>
        </StyledModalButtons>
      </ModalGeneric>
      <StyledDeleteActivityModal
        open={isDeleteActivityModalActive}
        closeModal={() => setIsDeleteActivityModalActive(false)}
      >
        <svg width="80" height="88" viewBox="0 0 80 88" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M1.75436e-05 30.1139V57.8454C-0.00416577 60.7046 0.739864 63.5143 2.15712 65.9914C3.57437 68.4686 5.61477 70.5256 8.07267 71.9552L31.9273 85.8209C34.3817 87.2485 37.1659 88 40 88C42.8341 88 45.6183 87.2485 48.0727 85.8209L71.9273 71.9552C74.3852 70.5256 76.4256 68.4686 77.8429 65.9914C79.2601 63.5143 80.0042 60.7046 80 57.8454V30.1139C79.9971 27.2617 79.2497 24.4603 77.8328 21.9909C76.4159 19.5216 74.3793 17.471 71.9273 16.0448L48.0727 2.17907C45.6183 0.751537 42.8341 0 40 0C37.1659 0 34.3817 0.751537 31.9273 2.17907L8.07267 16.2481C5.65006 17.6568 3.63231 19.6755 2.21715 22.1062C0.802002 24.5368 0.0379999 27.2962 1.75436e-05 30.1139Z"
            fill="#FFDBD6"
          />
          <path
            d="M54.8571 26.5H46.2857L45.5714 25.0938C45.2857 24.4688 44.6429 24 44.0714 24H35.8571C35.2857 24 34.5714 24.4688 34.3571 25.0938L33.7143 26.5H25.1429C24.5 26.5 24 27.125 24 27.75V30.25C24 30.9531 24.5 31.5 25.1429 31.5H54.8571C55.4286 31.5 56 30.9531 56 30.25V27.75C56 27.125 55.4286 26.5 54.8571 26.5ZM27.7857 60.4844C27.8571 62.4375 29.4286 64 31.2143 64H48.7143C50.5 64 52.0714 62.4375 52.1429 60.4844L53.7143 34H26.2857L27.7857 60.4844Z"
            fill="#EC6655"
          />
          <rect x="34" y="42" width="2" height="14" fill="#FFDBD6" />
          <rect x="39" y="42" width="2" height="14" fill="#FFDBD6" />
          <rect x="44" y="42" width="2" height="14" fill="#FFDBD6" />
        </svg>

        <StyledDeleteActivityModalHeader>Excluir atividade?</StyledDeleteActivityModalHeader>
        <StyledDeleteActivityModalParagraph>
          Não será mais possível executar ou recuperar essa atividade.
        </StyledDeleteActivityModalParagraph>
        <StyledDeleteActivityModalButtons>
          <StyledCloseDeleteActivityModal closeModal={() => setIsDeleteActivityModalActive(false)}>
            Cancelar
          </StyledCloseDeleteActivityModal>
          <StyledDeleteActivityButton onClick={() => handleRemoveActivity()}>
            Excluir atividade
          </StyledDeleteActivityButton>
        </StyledDeleteActivityModalButtons>
      </StyledDeleteActivityModal>
      <Toast toastList={toastList} autoDelete dismissTime={7000} />
      <AddActivityFormModal
        isAddActivityModalActive={isAddActivityModalActive}
        setGlobalComponentsContext={setGlobalComponentsContext}
        addActivity={addNewActivity}
        activeDraftPlanId={planId}
        createToast={createToast}
        setToastList={setToastList}
        setIsAddActivityModalActive={setIsAddActivityModalActive}
        companyId={headerFilters?.companies?.id}
      />
      <EditActivityFormModal
        isEditActivityModalActive={editActivityId}
        isDraft={fields.plan?.statusPlanoGeral === DRAFT_PLAN_STATUS}
        editActivityId={editActivityId}
        setGlobalComponentsContext={setGlobalComponentsContext}
        editActivity={handleEditActivity}
        planId={planId}
        createToast={createToast}
        setToastList={setToastList}
        setEditActivityId={setEditActivityId}
        companyId={headerFilters?.companies?.id}
      />
    </>
  );
};

export { EditGeneralPlanPage };
