import * as React from 'react';

import { LkpChart } from '../Chart';
import { ChartsParamsType } from '../types';
import { HeaderFiltersType } from 'context/GlobalFiltersContext/initialState';
import { useBoolean, useDebounce } from 'hooks';
import { fetcher } from 'services';
import { ChartRequiredBySystemType } from './types';
import { getChartOptions } from './options';
import { LkpChartTopFilter } from '../Filters';
import { isNumber } from 'fp-ts/lib/number';
import { getErrorMessageByStatusCode } from 'utils';
import { useParams } from 'react-router-dom';

export interface LkpNumberOfOccurrencesPerSystemProps {
  headerFilters: HeaderFiltersType;
}

const HTTP_PATH = '/ocorrencias-por-sistema';

export function LkpChartNumberOfOccurrencesPerSystem({
  headerFilters,
}: LkpNumberOfOccurrencesPerSystemProps): React.ReactElement {
  const { value: isLoading, setValue: setLoading } = useBoolean();
  const { value: isError, setValue: setIsError } = useBoolean();
  const [errorMessage, setErrorMessage] = React.useState<string>();
  const { dateFrom, dateTo } = useParams<{ dateFrom: string; dateTo: string }>();

  const [labels, setLabels] = React.useState<Array<string>>([]);
  const [series, setSeries] = React.useState<Array<number>>([]);
  const [paramsSystems, setParamsSystems] = React.useState<string>('');
  const [paramsStatus, setParamsStatus] = React.useState<string>('');
  const [params, setParams] = React.useState<ChartsParamsType>({
    Empresa: headerFilters?.companies?.id || 0,
    Sites: headerFilters?.units?.id || undefined,
    exibirMes: false,
    Inicio: dateFrom,
    Fim: dateTo,
  });

  const debouncedStartValue = useDebounce<string>(params?.Inicio || '', 500);
  const debouncedEnvValue = useDebounce<string>(params?.Fim || '', 500);
  const debouncedParamsSystemsValue = useDebounce<string>(paramsSystems || '', 500);
  const debouncedParamsStatusValue = useDebounce<string>(paramsStatus || '', 500);

  React.useEffect(() => {
    (async () => {
      await fetchChartData();
    })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedStartValue, debouncedEnvValue, debouncedParamsSystemsValue, debouncedParamsStatusValue]);

  const fetchChartData = async () => {
    setLoading(true);

    let _httpPath = HTTP_PATH;
    if (!!paramsSystems) _httpPath = `${_httpPath}?${paramsSystems}`;
    if (!!paramsSystems && !!paramsStatus) _httpPath = `${_httpPath}&${paramsStatus}`;
    if (!paramsSystems && !!paramsStatus) _httpPath = `${_httpPath}?${paramsStatus}`;

    const result = await fetcher
      .get<Array<ChartRequiredBySystemType>>(_httpPath, 'dashboard', { params })
      .catch(error => {
        const message = getErrorMessageByStatusCode(error, _httpPath);
        setErrorMessage(message);
        setIsError(true);
      });

    if (result) {
      setLabels(result.map(({ sistema }) => sistema || 'Sem sistema'));
      setSeries(result.map(({ quantidade }) => (isNumber(Number(quantidade)) ? Number(quantidade) : 0)));
    }

    setLoading(false);
  };

  const handleChangeStartDate = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value: Inicio } = event.target;
    setParams({ ...params, Inicio });
  };

  const handleChangeEndDate = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value: Fim } = event.target;
    setParams({ ...params, Fim });
  };

  return (
    <React.Fragment>
      <LkpChart
        type="donut"
        //height={446}
        series={series}
        //width={1700}
        title="Quantidade de ocorrências por sistema"
        companyId={headerFilters?.companies?.id || 0}
        options={getChartOptions({
          labels,
          colors: [
            '#00C0C7',
            '#5144D3',
            '#E8871A',
            '#DA3490',
            '#9089FA',
            '#47E26F',
            '#2780EB',
            '#6F38B1',
            '#DFBF03',
            '#CB6F10',
            '#268D6C',
            '#9BEC54',
            '#3366CC',
            '#DC3912',
            '#FF9900',
            '#109618',
            '#990099',
            '#0099C6',
            '#DD4477',
            '#66AA00',
            '#B82E2E',
            '#316395',
            '#994499',
            '#22AA99',
            '#AAAA11',
            '#6633CC',
            '#E67300',
            '#8B0707',
            '#CCCCCC',
            '#651067',
            '#329262',
          ],
        })}
        loading={isLoading}
        error={isError}
        errorMessage={errorMessage}
        onChartTryAgain={fetchChartData}
        filter={
          <LkpChartTopFilter
            onChangeStartDate={handleChangeStartDate}
            onChangeEndDate={handleChangeEndDate}
            onSelectSystems={setParamsSystems}
            onSelectOccurrences={setParamsStatus}
          />
        }
      />
    </React.Fragment>
  );
}
