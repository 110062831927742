import { useEffect, useState } from 'react';
import {
  Container,
  Content,
  StyleEquipamentInfo,
  StyleMaintenanceInfo,
  StyledBoxBorder,
  StyledBoxContent,
  StyledBoxLeft,
  StyledClientInfo,
  StyledEquipmentPhoto,
  StyledLocationBox,
  StyledLocationLogo,
  StyledLocationLogoContainer,
  StyledPreventiveMaintenance,
} from '../styled';

import imgPlaceholder from 'assets/images/placeholder_img.jpg';
import SocialLinks from '../components/socials';
import { ReactComponent as ChevronIcon } from 'assets/icons/chevron-right.svg';
import { useCookies } from 'react-cookie';
import { URLS } from 'constants/urls';
import { useHistory } from 'react-router-dom';

const Equipamento = ({ code }) => {
  const [data, setData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [addresses, setAddresses] = useState({});
  const setCookie = useCookies()[1];
  const history = useHistory();
  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const response = await fetch(
          `${process.env.REACT_APP_LIGHTHOUSE_FILTERS_API}/v1/qrcode/public?QrCode=${code}&IgnoreCounts=true`,
        );
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        setCookie(
          'qrcodeFilters',
          {
            site: { value: data?.site?.site, label: data?.site?.nome },
            equipamento: { value: data?.equipamento?.gid, label: data?.equipamento?.nomeFormatted },
          },
          { path: '/', domain: URLS.cookieDomain },
        );
        setData(data);

        const addressesResponse = await fetch(
          `${process.env.REACT_APP_LIGHTHOUSE_FILTERS_API}/v1/homepageempresa/${data.site.empresa}`,
        );
        const addressesData = await addressesResponse.json();
        setAddresses(addressesData);
      } catch (error) {
        console.error('Erro:', error);
        setError(error.toString());
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [code]);

  if (isLoading) {
    return <div>Carregando...</div>;
  }
  if (error) {
    return <div>Não autorizado</div>;
  }

  const socialLinks = {
    instagram: addresses.instagran || null,
    facebook: addresses.facebook || null,
    linkedin: addresses.linkedin || null,
    site: addresses.url || null,
  };
  const goTo = () => {
    const newWindow = window.open('', '_blank');
    newWindow.location.href = history.createHref({ pathname: '/login' });
  };
  return (
    <Container>
      <Content>
        <StyledBoxBorder>
          <StyledLocationLogoContainer>
            <StyledLocationLogo
              src={
                data.site?.empresaObj?.logomarcaPath
                  ? `${process.env.REACT_APP_URL_IMAGES}${data.site.empresaObj.logomarcaPath}`
                  : imgPlaceholder
              }
              alt="Logo Cliente"
            />
          </StyledLocationLogoContainer>
          <StyledClientInfo>
            <div>
              <h2>{data.site?.empresaObj?.nome || ''}</h2>
              <a href={'/clientes/' + addresses?.subdominio}>
                Saiba mais <ChevronIcon />
              </a>
            </div>
            <h4>Endereço</h4>
            <p>
              {addresses.endereco ? addresses.endereco + ',' : ' '}{' '}
              {addresses.numero ? addresses.numero?.replace(/\D/g, '') + '-' : ''}{' '}
              {addresses.cidadeObj?.nome ? addresses.cidadeObj.nome + ',' : ''}{' '}
              {addresses.cidadeObj?.estadoNome ? addresses.cidadeObj?.estadoNome : ''}
              {addresses.cep ? ' - ' + addresses.cep : ''}
            </p>
            {addresses.telefone ? (
              <>
                <h4>Contato</h4>
                <p>{addresses.telefone?.replace(/_$/, '')}</p>
              </>
            ) : null}
            <SocialLinks links={socialLinks} />
          </StyledClientInfo>
          <StyleEquipamentInfo>
            <h2>Equipamento</h2>
            <div>
              <StyledEquipmentPhoto
                src={
                  data.equipamento && data.equipamento.fotoPath
                    ? process.env.REACT_APP_URL_IMAGES + data.equipamento.fotoPath
                    : imgPlaceholder
                }
                alt="Foto do Equipamento"
              />

              <span>
                <span>
                  <h3>Nome do equipamento</h3>
                  <p>{data.equipamento?.nome || '-'}</p>
                </span>
                <span>
                  <h3>TAG</h3>
                  <p>{data.equipamento?.tag || '-'}</p>
                </span>
                <span>
                  <h3>Sistema</h3>
                  <p>{data.equipamento?.sistemaEmpresaObj?.nome || '-'}</p>
                </span>
              </span>
            </div>
          </StyleEquipamentInfo>
          <StyleMaintenanceInfo>
            <h2>Manutenção</h2>
            <h3>Possui ocorrências em aberto?</h3>
            <p>{data.qtdOcorrenciasPendentes ? 'Sim' : 'Não'}</p>
            <h3>
              Tem Atividades Preventivas realizadas nos <br /> últimos 30 dias?
            </h3>
            <p>{data.qtdAtividadesUltimoMes ? 'Sim' : 'Não'}</p>
            <button onClick={goTo}>
              ACESSAR LEANKEEP <span></span>
            </button>
          </StyleMaintenanceInfo>
        </StyledBoxBorder>
        <StyledBoxLeft>
          <StyledBoxContent>
            <h2>Especificações do equipamento</h2>
            <div>
              <span>
                <span>
                  <h3>Marca</h3>
                  <p>{data.equipamento?.marca || '-'}</p>
                </span>
                <span>
                  <h3>Modelo</h3>
                  <p>{data.equipamento?.modelo || '-'}</p>
                </span>
                <span>
                  <h3>Série</h3>
                  <p>{data.equipamento?.serie || '-'}</p>
                </span>
                <span>
                  <h3>Capacidade</h3>
                  <p>{data.equipamento?.capacidade || '-'}</p>
                </span>
              </span>

              <span>
                <span>
                  <h3>Corrente (A)</h3>
                  <p>{data.equipamento?.corrente || '-'}</p>
                </span>
                <span>
                  <h3>Tensão (V)</h3>
                  <p>{data.equipamento?.tensao || '-'}</p>
                </span>
                <span>
                  <h3>Potência</h3>
                  <p>{data.equipamento?.potencia || '-'}</p>
                </span>
                <span>
                  <h3>Informações adicionais</h3>
                  <p>{data.equipamento?.informacoesAdicionais || '-'}</p>
                </span>
              </span>
            </div>
          </StyledBoxContent>
          <StyledLocationBox>
            <h2>Local</h2>
            <h3>Unidade de manutenção</h3>
            <p>{data.site?.nome || '-'}</p>
            <h3>Grupo de Áreas</h3>
            <p>{data.equipamento?.areaObj?.grupoAreaObj?.agrupamento || '-'}</p>
            <h3>Subgrupo de Áreas</h3>
            <p>{data.equipamento?.areaObj?.subGrupoAreaObj?.nome || '-'}</p>
            <h3>Áreas</h3>
            <p>{data.equipamento?.areaObj?.nome || '-'}</p>
          </StyledLocationBox>
          <StyledPreventiveMaintenance>
            <div>
              <span className="firstSpan">
                <h2>Maximize a eficiência em manutenção preventiva</h2>
                <p>Descubra como a Leankeep pode revolucionar sua gestão de manutenção preventiva.</p>
                <button>Saiba mais</button>
              </span>
              <span className="secondSpan"></span>
            </div>
          </StyledPreventiveMaintenance>
        </StyledBoxLeft>
      </Content>
    </Container>
  );
};

export default Equipamento;
