import { colors } from 'assets/styled/tokens';
import styled, { keyframes } from 'styled-components';

const float = keyframes`
  0,
  100% {
    transform: scale(1);
    transform: scale3d(1, 1, 1);
  }

  50% {
    transform: scale(0.8);
    transform: scale3d(0.8, 0.8, 0.8);
  }
`;

export const StyledLoading = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 20;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  background-color: rgba(255, 255, 255, 0.98);

  img {
    max-width: 200px;
    height: auto;
    animation: ${float} 2s ease-in-out infinite;
  }
`;

const loadingAnimation = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

export const Loading = styled.div`
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
`;

export const LoadingInner = styled.div`
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid ${colors.primary};
  border-radius: 50%;
  animation: ${loadingAnimation} 2s ease-in-out infinite;
  border-color: ${colors.primary} transparent transparent transparent;

  &:nth-child(1) {
    animation-delay: -0.45s;
  }

  &:nth-child(2) {
    animation-delay: -0.3s;
  }

  &:nth-child(3) {
    animation-delay: -0.15s;
  }
}
`;
