import styled from 'styled-components';

import { fonts, colors } from 'assets/styled/tokens';
import closeIcon from 'assets/icons/close.svg';

export const StyledAboutModal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  transition: all 0.3s ease-in-out;
  opacity: ${props => (props.open ? '1' : '0')};
  visibility: ${props => (props.open ? 'visible' : 'hidden')};
`;

export const StyledAboutModalHeader = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 14px 20px;
  border-radius: 6px 6px 0 0;
  background-color: white;
`;

export const StyledAboutModalBody = styled.div`
  padding: 14px 20px;
  text-align: center;

  ul {
    margin-bottom: 24px;
    padding-bottom: 24px;
    border-bottom: 1px solid ${colors.gray};
    text-align: left;
  }

  p {
    margin: 16px 0;
  }

  a {
    color: ${colors.primary};
  }
`;

export const StyledAboutModalTitle = styled.h4`
  font-family: ${fonts.family.title};
  font-size: 16px;
  color: ${colors.primary};
`;

export const StyledAboutModalClose = styled.button`
  position: relative;
  margin: 0;
  padding: 0;
  border: 0;
  width: 14px;
  height: 14px;
  background: white url(${closeIcon}) no-repeat center/contain;
  border: none;
  border-radius: 2px;
  font-size: 0;
  color: transparent;
  text-indent: -9999px;
`;

export const StyledAboutModalInfo = styled.div`
  width: 80%;
  max-width: 400px;
  border-radius: 6px;
  background-color: white;
  box-shadow: 0px 1px 18px rgba(0, 0, 0, 0.3);
`;
