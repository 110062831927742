export const getCookie = name => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(';').shift();
};
export const deleteAllCookies = () => {
  var cookie = document.cookie.split(';');

  for (var i = 0; i < cookie.length; i++) {
    var chip = cookie[i],
      entry = chip.split('='),
      name = entry[0];

    document.cookie = name + '=; expires=Thu, 01 Jan 1970 00:00:01 GMT;';
  }
};
export const removeHeaderFilterCookies = removeCookie => {
  // remove header filter cookies
  const headerFilters = ['companies', 'units', 'unitGroups', 'unitSubgroups', 'states', 'cities', 'providers'];

  headerFilters.forEach(headerFilter => {
    removeCookie(headerFilter, { path: '/' });
  });
};
