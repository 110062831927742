import thumbnail1a from './assets/thumbnail-1-a.svg';
import thumbnail1b from './assets/thumbnail-1-b.svg';

import thumbnail2a from './assets/thumbnail-2-a.svg';
import thumbnail2b from './assets/thumbnail-2-b.svg';

import thumbnail3a from './assets/thumbnail-3-a.svg';
import thumbnail3b from './assets/thumbnail-3-b.svg';
import thumbnail3c from './assets/thumbnail-3-c.svg';

import thumbnail4 from './assets/thumbnail-4.svg';

const getFirstThumbnail = ({ showOccurrencesPictures, sla }) => {
  if (showOccurrencesPictures && sla) {
    return thumbnail1a;
  }

  return thumbnail1b;
};

const getSecondThumbnailnail = ({ correctionPictures }) => {
  if (correctionPictures) {
    return thumbnail2a;
  }

  return thumbnail2b;
};

const getThirdThumbnail = (costsAndMaterials, metricsForEachOccurrence) => {
  if (!metricsForEachOccurrence && !costsAndMaterials) {
    return undefined;
  }

  if (!metricsForEachOccurrence) {
    return thumbnail3b;
  }

  if (!costsAndMaterials) {
    return thumbnail3c;
  }

  return thumbnail3a;
};

const getFourthThumbnail = ({ summaryOfAllOccurrences }) => {
  if (summaryOfAllOccurrences) {
    return thumbnail4;
  }
};

const previewOccurrenceReportPdfFactory = ({ occurrences, corrections, metrics }) => {
  const result = [];

  const occurrencesThumbnail = occurrences && getFirstThumbnail(occurrences);

  const correctionsThumbnail = corrections && getSecondThumbnailnail(corrections);

  const correctionsAndMetricsThumbnail =
    corrections && metrics && getThirdThumbnail(corrections.costsAndMaterials, metrics.metricsForEachOccurrence);

  const metricsThumb = metrics && getFourthThumbnail(metrics);

  occurrencesThumbnail && result.push(occurrencesThumbnail);

  correctionsThumbnail && result.push(correctionsThumbnail);

  correctionsAndMetricsThumbnail && result.push(correctionsAndMetricsThumbnail);

  metricsThumb && result.push(metricsThumb);

  return result;
};

export { previewOccurrenceReportPdfFactory };
