import { ArrowToggle } from 'components/ArrowToggle';
import React from 'react';
import { TEAMS_TAB, TECHNICIANS_TAB, UNIT_MARKERS_TAB } from '../../constants';
import { FilterTab, FilterTabsContainer, FilterTabsHeader } from './styled';

const TabsHeader = ({ isCollapsed, activeTab, handleTabChange }) => {
  const isFaded = activeTab !== undefined;
  return (
    <FilterTabsHeader isCollapsed={isCollapsed}>
      <FilterTabsContainer>
        <FilterTab
          onClick={e => handleTabChange(e, TECHNICIANS_TAB)}
          isActive={activeTab === TECHNICIANS_TAB}
          isFaded={isFaded}
        >
          Técnicos
          <ArrowToggle isSorted={true} isSortedDesc={activeTab !== TECHNICIANS_TAB} />
        </FilterTab>
        <FilterTab onClick={e => handleTabChange(e, TEAMS_TAB)} isActive={activeTab === TEAMS_TAB} isFaded={isFaded}>
          Equipes
          <ArrowToggle isSorted={true} isSortedDesc={activeTab !== TEAMS_TAB} />
        </FilterTab>
        <FilterTab
          onClick={e => handleTabChange(e, UNIT_MARKERS_TAB)}
          isActive={activeTab === UNIT_MARKERS_TAB}
          isFaded={isFaded}
        >
          Unidades
          <ArrowToggle isSorted={true} isSortedDesc={activeTab !== UNIT_MARKERS_TAB} />
        </FilterTab>
      </FilterTabsContainer>
    </FilterTabsHeader>
  );
};

export default TabsHeader;
