import React, { useState, useContext, useEffect } from 'react';

import { useForm } from 'react-hook-form';
import { Link, useHistory } from 'react-router-dom';

import { PreAuthPage } from 'containers/PreAuthPage';

import { Button } from 'components/Button';
import { Loading } from 'components/Loading';
import { Input } from 'components/Form/subcomponents/Input';
import { ModalGeneric } from 'components/ModalGeneric';

import { AuthContext } from 'context/AuthContext';

import { AuthService } from 'services/main';

import leankeepLogo from 'assets/icons/leankeep/leankeep-facility-logo.svg';
import errorIcon from 'assets/icons/icon_block.svg';
import iconError503 from 'assets/icons/icon_error.svg';

import {
  StyledWelcome,
  StyledForm,
  StyledFormGroup,
  StyledFormLogin,
  StyledFormFooter,
  StyledForgetPasswordLink,
  StyledStayConnectedCheckbox,
  StyledTooltip,
  StyledModalBody,
  StyledModalButton,
  StyledP,
} from './styled';
import { parseJwt } from 'utils/parse';
import { getLocalAuthStorage } from 'utils/localStorage';
import { createGenericErrorToast } from 'utils/createToast';
import { Toast } from 'components/Toast';
import { useCookies } from 'react-cookie';
import { URLS } from 'constants/urls';

const Login = () => {
  const [toastList, setToastList] = useState([]);
  const { register, handleSubmit, setValue, getValues, setError, errors } = useForm();
  const authStorage = getLocalAuthStorage();
  const setCookie = useCookies(['authToken', 'refreshToken', 'lastLoginRoute'])[1];
  const { push } = useHistory();
  const { authContext, setAuthContext } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentModalInfo, setCurrentModalInfo] = useState('errorPermission');
  const modalInfos = {
    errorPermission: {
      icon: errorIcon,
      title: 'Usuário logado em outro dispositivo',
      text: (
        <>
          <p>
            Precisa de um usuário exclusivo para você?
            <br />
            Peça a um administrador ou fale conosco!
          </p>
        </>
      ),
    },
    errorServiceUnavailable: {
      icon: iconError503,
      title: 'Sua rede está com dificuldade de acessar o Leankeep',
      text: (
        <>
          <p>
            Entre em contato com o responsável pela rede e
            <br />
            verifique se o acesso ao *.lkp.app.br está liberado.
          </p>
        </>
      ),
    },
  };

  const handleLogin = async ({ login, password, stayLogged, expireCurrentSession }) => {
    setLoading(true);

    const response = await AuthService.login({
      login,
      password,
      withRefreshToken: stayLogged,
      stayConnected: stayLogged,
      expireCurrentSession: expireCurrentSession,
    });
    const { data } = response;
    if (response.status === 200) {
      await onLogin(response);
    }

    if (response.status === 401 && data.hasActiveSession) {
      // login and password are correct but there is an active session
      setCurrentModalInfo('errorPermission');
      setIsModalOpen(true);
    }

    if (data.message === 'Invalid user access! It may have been suspended or inactivated.' && !data.hasActiveSession) {
      // login and password are incorrect
      setError('login', {
        type: 'unauthorized',
        message: 'Usuario invalido',
      });

      setError('password', {
        type: 'unauthorized',
        message: 'Senha incorreta',
      });
      setValue('login', '');
      setValue('password', '');
    }

    if (data.authtoken && response.statusCode === 403) {
      // user with local network lock
      setCurrentModalInfo('errorServiceUnavailable');
      setIsModalOpen(true);
    }
    setLoading(false);
  };

  const onSubmit = async ({ login, password, stayLogged }) => {
    return await handleLogin({ login, password, stayLogged });
  };
  const onLogin = async res => {
    const {
      data: { user, ...data },
    } = res;

    try {
      setCookie('authToken', data.authToken.token, { path: '/', domain: URLS.cookieDomain });
      setCookie('refreshToken', data?.authToken?.refreshToken, { path: '/', domain: URLS.cookieDomain });
      const { utyp } = parseJwt(data.authToken.token);
      const { isSuper, isAdmin, isOperationalUser, isCommonUser } = {
        isSuper: parseInt(utyp) === 1,
        isAdmin: parseInt(utyp) === 2,
        isOperationalUser: parseInt(utyp) === 3,
        isCommonUser: parseInt(utyp) === 4,
      };
      const permissionType = isSuper ? 'super' : isAdmin ? 'admin' : 'user';
      const userType = isSuper
        ? 'Super-Usuário'
        : isAdmin
        ? 'Administrador'
        : isOperationalUser
        ? 'Operacional'
        : isCommonUser
        ? 'Chamado'
        : createGenericErrorToast(setToastList);
      const userResponse = await AuthService.getUser({ id: user?.id, token: data.authToken.token });
      user.fullName = userResponse.data.nome;
      const signUpDateUnix = Math.floor(new Date(userResponse.data.dataCadastro).getTime() / 1000);
      user.signUpDate = signUpDateUnix;
      setCookie(
        'chamadosAccess',
        { chamado: userResponse?.data?.filtrosDefault, userId: userResponse?.data?.usuario },
        { path: '/', domain: URLS.cookieDomain },
      );
      setCookie('lastLoginRoute', '/login', { path: '/', domain: URLS.cookieDomain });
      setAuthContext({
        ...authContext,
        isAuthenticated: true,
        statusCode: true,
        token: data?.authToken?.token,
        refreshToken: data?.authToken?.refreshToken,
        expiresIn: data?.authToken?.expiresIn,
        user: {
          id: user?.id,
          name: user?.name,
          permissionType: permissionType,
          type: userType,
          fullName: user?.fullName,
          signUpUnixDate: user?.signUpDate,
        },
      });
    } catch (e) {
      console.error(`userResponse err`);
      console.error(e);
      push('/logout');
    }
  };
  // TODO REFACTOR
  // login despite active session
  const forceLogin = async () => {
    const login = getValues('login');
    const password = getValues('password');
    const stayLogged = getValues('stayLogged');
    try {
      setLoading(true);
      // expire session = true
      const response = await AuthService.login({
        login,
        password,
        withRefreshToken: stayLogged,
        stayConnected: stayLogged,
        expireCurrentSession: true,
      });
      setIsModalOpen(false);
      await onLogin(response);
    } catch (e) {
      console.error(e);
      push('/logout');
    } finally {
      setLoading(false);
    }
  };
  const handleAccessWithOtherUser = () => {
    setValue('login', '');
    setValue('password', '');
    setIsModalOpen(false);
  };
  useEffect(() => {
    if (authStorage?.isAuthenticated && authStorage?.token) {
      const { utyp } = parseJwt(authStorage?.token);
      if (parseInt(utyp) === 4) {
        push('/portal-do-cliente');
        return null;
      }
      // if (process.env.NODE_ENV !== 'development') {
      //   //redirect only in production and homolog
      //   window.location.href = `${process.env.REACT_APP_LEGADO}/Security/SignIn.aspx?${authStorage.token}`;
      //   return null;
      // }
      else {
        push('/home');
        return null;
      }
    }
  }, [authStorage, push]);

  return (
    <PreAuthPage>
      <StyledForm onSubmit={handleSubmit(onSubmit)}>
        <img src={leankeepLogo} alt="Leankeep" />

        <StyledWelcome>
          <h3>Bom te receber de volta.</h3>
          <span>Informe seus dados abaixo</span>
        </StyledWelcome>

        <StyledFormLogin>
          <StyledFormGroup label="Login">
            <Input
              type="text"
              name="login"
              size="large"
              tabIndex={0}
              error={errors.login !== undefined}
              errormessage={errors.login && errors.login.message}
              ref={register({
                required: 'Este campo é obrigatório.',
              })}
            />
          </StyledFormGroup>
          <StyledFormGroup label="Senha" isPassword>
            <StyledForgetPasswordLink>
              <Link to="/conta/recuperar" tabIndex={-1}>
                Esqueceu sua senha?
              </Link>
            </StyledForgetPasswordLink>
            <Input
              type="password"
              name="password"
              size="large"
              tabIndex={0}
              isPassword
              error={errors.password !== undefined}
              errormessage={errors.password && errors.password.message}
              ref={register({
                required: 'Este campo é obrigatório.',
              })}
            />
          </StyledFormGroup>
          <StyledFormFooter>
            <StyledStayConnectedCheckbox
              name="stayLogged"
              text="Mantenha-me conectado(a)"
              defaultChecked={true}
              ref={register}
            />
            <StyledTooltip text="Sua conta estará sempre logada. Desmarque se este computador for compartilhado, por segurança." />

            <Button id="loginButton" type="submit">
              Entrar
            </Button>
          </StyledFormFooter>
        </StyledFormLogin>
      </StyledForm>
      <Toast toastList={toastList} autoDelete dismissTime={7000} />

      <>
        <ModalGeneric open={isModalOpen} closeModal={() => setIsModalOpen(false)}>
          <StyledModalBody>
            <img src={modalInfos[currentModalInfo].icon} alt="usuario invalido" />
            <h6>{modalInfos[currentModalInfo].title}</h6>
            {currentModalInfo === 'errorPermission' ? (
              <>
                <StyledModalButton>
                  <Button type="button" onClick={() => forceLogin()}>
                    Encerrar sessão ativa e acessar
                  </Button>
                  <Button type="button" onClick={() => handleAccessWithOtherUser()}>
                    Acessar com outro usuário
                  </Button>
                </StyledModalButton>
                {modalInfos[currentModalInfo].text}
              </>
            ) : (
              <>
                <StyledP>{modalInfos[currentModalInfo].text}</StyledP>
                <Button type="button" onClick={() => handleAccessWithOtherUser()}>
                  Voltar à página inicial
                </Button>
              </>
            )}
          </StyledModalBody>
        </ModalGeneric>
        {loading && <Loading />}
      </>
    </PreAuthPage>
  );
};

export { Login };
