import { AxiosGlobalFilters } from '../config';

export const ChamadoService = {
  getOccurences: ({
    dataInicio,
    dataFim,
    pageSize,
    pageIndex,
    empresa,
    UnidadeId,
    AreaId,
    EquipamentoId,
    Search,
    StatusIds,
    SolicitanteId,
  }) => {
    const url = `/v1/ocorrencias?DataInicio=${dataInicio}&DataTermino=${dataFim}&PAgeSize=${pageSize}&PageIndex=${pageIndex}`;
    const headers = {
      'content-type': 'application/json',
      EmpresaId: empresa,
    };

    if (UnidadeId !== undefined && UnidadeId !== null) {
      headers.UnidadeId = UnidadeId;
    }
    return AxiosGlobalFilters({
      method: 'GET',
      url,
      headers,
      params: {
        AreaId,
        EquipamentoId,
        Search,
        StatusIds,
        SolicitanteId,
      },
    });
  },
  getOcurrencesNumber: ({
    dataInicio,
    dataFim,
    empresa,
    UnidadeId,
    AreaId,
    EquipamentoId,
    Search,
    StatusIds,
    SolicitanteId,
  }) => {
    const url = `/v1/ocorrencias/count?DataInicio=${dataInicio}&DataTermino=${dataFim}`;
    const headers = {
      'content-type': 'application/json',
      EmpresaId: empresa,
    };

    if (UnidadeId !== undefined && UnidadeId !== null) {
      headers.UnidadeId = UnidadeId;
    }
    return AxiosGlobalFilters({
      method: 'GET',
      url,
      headers,
      params: {
        AreaId,
        EquipamentoId,
        Search,
        StatusIds,
        SolicitanteId,
      },
    });
  },
  getOcurrencesData: ({ anomaliaGid, empresa }) => {
    const url = `/v1/ocorrencias/${anomaliaGid}/toedit`;
    return AxiosGlobalFilters({
      method: 'GET',
      url,
      headers: {
        'content-type': 'application/json',
        EmpresaId: empresa,
      },
    });
  },
  getCorrections: ({ anomaliaGid, empresa }) => {
    const url = `/v3/correcoes?ocorrenciaId=${anomaliaGid}`;
    return AxiosGlobalFilters({
      method: 'GET',
      url,
      headers: {
        'content-type': 'application/json',
        EmpresaId: empresa,
      },
    });
  },
  getEquipments: ({ empresa, SiteId }) => {
    return AxiosGlobalFilters({
      method: 'GET',
      url: 'v1/equipamentos/ativos',
      params: {
        SiteId,
      },
      headers: {
        'content-type': 'application/json',
        EmpresaId: empresa,
      },
    });
  },
  getAreas: ({ empresa }) => {
    return AxiosGlobalFilters({
      method: 'GET',
      url: 'v1/areas/ativas',
      headers: {
        'content-type': 'application/json',
        EmpresaId: empresa,
      },
    });
  },
};
