import React, { useState, useEffect, createContext } from 'react';

import { localStorageKeys } from 'constants/localStorage';

import { initialState } from './initialState';

const PermissionContext = createContext(initialState);
PermissionContext.displayName = 'PermissionContext';

const PermissionContextProvider = ({ children }) => {
  const [state, setState] = useState(initialState);

  useEffect(() => {
    localStorage.setItem(localStorageKeys.permissionContext, JSON.stringify(state));
  }, [state]);
  return (
    <PermissionContext.Provider
      value={{
        permissionContext: state,
        setPermissionContext: setState,
      }}
    >
      {children}
    </PermissionContext.Provider>
  );
};

export { PermissionContext, PermissionContextProvider };
