const adapt = httpResponse => {
  const { response } = httpResponse;
  return {
    ...httpResponse,
    response,
  };
};

const checkAdapter = httpResponsePromise =>
  httpResponsePromise
    .then(
      resolve => adapt(resolve),
      reject => adapt(reject),
    )
    .catch(err => {
      return err;
    });

export { checkAdapter };
