import { AxiosReportLas } from '../config';

const PmocService = {
  validateLogin: login => {
    return AxiosReportLas({
      method: 'post',
      url: `/v1/Relatorios/ValidarEmailUsuario?login=${login}`,
      headers: {
        'content-type': 'application/json',
      },
    });
  },
  generatePDF: ({
    empresa,
    login,
    dataInicial,
    dataFinal,
    unidade,
    sistemas,
    planoAtividades,
    responsaveisTecnicos,
    ativos,
    exibirAtividadesNRealizadas,
    recomendacoesFalhas,
    documentosComplementares,
  }) => {
    return AxiosReportLas({
      method: 'post',
      responseType: 'blob',
      headers: {
        'content-type': 'application/json',

        EmpresaId: empresa,
      },
      url: `/v1/Relatorios/Pmoc`,
      data: {
        login,
        empresa,
        dataInicial,
        dataFinal,
        unidade,
        sistemas,
        ativos,
        exibirAtividadesNRealizadas,
        recomendacoesFalhas,
        documentosComplementares,
        planoAtividades,
        responsaveisTecnicos,
      },
    });
  },
  sendPdfByEmail: ({
    empresa,
    login,
    dataInicial,
    dataFinal,
    unidade,
    sistemas,
    planoAtividades,
    responsaveisTecnicos,
    ativos,
    exibirAtividadesNRealizadas,
    recomendacoesFalhas,
    documentosComplementares,
  }) => {
    return AxiosReportLas({
      method: 'post',
      headers: {
        'content-type': 'application/json',

        EmpresaId: empresa,
      },
      url: `/v1/Relatorios/Pmoc`,
      data: {
        login,
        empresa,
        dataInicial,
        dataFinal,
        unidade,
        sistemas,
        ativos,
        exibirAtividadesNRealizadas,
        recomendacoesFalhas,
        documentosComplementares,
        planoAtividades,
        responsaveisTecnicos,
      },
    });
  },
};

export { PmocService };
