import { localStorageKeys } from 'constants/localStorage';
const skeleton = {
  home: true,
  dashboard: true,
  planosGerais: true,
  relatorios: true,
  geolocalizacao: true,
};
const stored = JSON.parse(localStorage.getItem(localStorageKeys.permissionContext));

const initialState = stored || skeleton;

export { initialState, skeleton };
