import { AxiosReportLas, AxiosGlobalFilters } from '../config';
import qs from 'qs';
const CalendarServices = {
  validateLogin: login => {
    return AxiosReportLas({
      method: 'post',
      url: `/v1/Relatorios/ValidarEmailUsuario?login=${login}`,
    });
  },
  getActivitiesPlans: ({ companyId, unitId, systemIds }) => {
    return AxiosGlobalFilters({
      method: 'get',
      url: `/v1/planosatividades/ativos`,
      params: {
        empresaId: companyId,
        sistemasIds: systemIds,
        unidadeId: unitId,
      },
      paramsSerializer: params => {
        return qs.stringify(params, { arrayFormat: 'repeat' });
      },
    });
  },
  getPdfFile: ({ emailObj }) => {
    const {
      empresa,
      sites,
      inicio,
      fim,
      sistemas,
      planosAtividade,
      executores,
      areas,
      PeriodicidadeAtividades,
      descricaoAtividade,
      equipamentos,
    } = emailObj;
    return AxiosReportLas({
      method: 'post',
      url: '/v1/Relatorios/RelatorioAgendaAtividadesDiarioArquivo',
      data: {
        empresa,
        sites,
        inicio,
        fim,
        sistemas,
        planosAtividade,
        executores,
        descricaoAtividade,
        PeriodicidadeAtividades,
        areas,
        equipamentos,
      },
      headers: {
        'Content-Type': 'application/json',
      },
      responseType: 'blob',
    });
  },
  sendPdfByEmail: ({ emailObj }) => {
    const {
      empresa,
      sites,
      inicio,
      fim,
      sistemas,
      planosAtividade,
      executores,
      areas,
      PeriodicidadeAtividades,
      descricaoAtividade,
      equipamentos,
      tiposEquipamentos,
      login,
      email,
      gruposAreas,
      subGrupoAreas,
      statusAtividades,
    } = emailObj;
    return AxiosReportLas({
      method: 'post',
      url: '/v1/Relatorios/RelatorioAgendaAtividadesDiario',
      data: {
        empresa,
        sites,
        inicio,
        fim,
        gruposAreas,
        subGrupoAreas,
        sistemas,
        planosAtividade,
        executores,
        descricaoAtividade,
        PeriodicidadeAtividades,
        areas,
        equipamentos,
        tiposEquipamentos,
        Login: login,
        email,
        statusAtividades,
      },

      headers: {
        'Content-Type': 'application/json',
      },
    });
  },
};
export { CalendarServices };
