import styled from 'styled-components';

export const StyledClusterMarker = styled.div`
  position: relative;
  top: ${({ offsetTop }) => `-${offsetTop}px`};
`;
export const StyledClusterMarkerCount = styled.div`
  cursor: pointer;
  color: white;
  font-family: Roboto;
  font-weight: bold;
  font-size: 18px;
  line-height: 21px;
  z-index: 1;
  position: absolute;
  top: -3px;
  height: ${({ height }) => height + `px`};
  width: ${({ width }) => width + `px`};
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
`;
export const StyledClusterMarkerBgSvg = styled.svg`
  z-index: 0;
`;
