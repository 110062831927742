import React, { useRef, useState } from 'react';
import { NavLink } from 'react-router-dom';

import { StyledCategoryItem } from './styled';

const CategoryItem = ({ category }) => {
  const { link, label, isExternal, disabled, children, ...props } = category;
  const [isOpen, setIsOpen] = useState(false);
  const itemRef = useRef(null);

  const renderLink = ({ label, link, isExternal = false, isChildren = false }) => {
    const linkProps = {
      activeClassName: !isChildren ? 'active' : undefined,
      to: link,
    };

    if (isExternal) {
      return (
        <a href={link} rel="noopener noreferrer">
          {label}
        </a>
      );
    }

    if (link === '') {
      return <span>{label}</span>;
    }

    return <NavLink {...linkProps}>{label}</NavLink>;
  };

  return (
    <StyledCategoryItem
      key={label}
      hasChildren={children}
      ref={itemRef}
      disabled={disabled}
      isOpen={isOpen}
      onMouseLeave={() => setIsOpen(() => false)}
      onClick={() => {
        setIsOpen(true);
      }}
      {...props}
    >
      {link &&
        renderLink({
          label,
          link,
          isExternal,
          isChildren: false,
        })}
    </StyledCategoryItem>
  );
};

export { CategoryItem };
