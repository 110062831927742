/* istanbul ignore file */

import styled from 'styled-components';
import { medias } from 'assets/styled/tokens';

export const DashWrapper = styled.div<{ isLoading?: boolean }>`
  opacity: ${({ isLoading = false }) => (isLoading ? '0.5' : '1')};
  pointer-events: ${({ isLoading = false }) => (isLoading ? 'none' : 'all')};
  background: #f5f3f1;
`;

export const DashGrid = styled.div`
  display: grid;
  align-content: center;
  margin-right: -12px;
  margin-left: -12px;
`;

export const DashHalfGrid = styled(DashGrid)`
  grid-template-columns: 100%;

  @media screen and (min-width: ${medias.desktopSm}) {
    grid-template-columns: 50% 50%;
  }
`;

export const DashFullGrid = styled(DashGrid)`
  grid-template-columns: 100%;
`;

export const DashColumn = styled.div`
  padding: 12px;
`;

export const DashHeader = styled.header`
  display: flex;
  align-items: center;
  margin-bottom: 14px;
  min-height: 32px;
  position: relative;

  h1 {
    margin-right: 32px;
    font-size: 32px;
  }
`;
export const OldChartButton = styled.button`
  width: 215px;
  height: 32px;
  border-radius: 4px;
  border: 1px solid #f38a00;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 0.5px;
  text-align: left;
  color: #f38a00;
  background-color: #fff;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  position: absolute;
  right: 0;
`;
