import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import {
  StyledCheckboxDropdown,
  StyledCheckboxDropdownLabel,
  StyledCheckboxDropdownContainer,
  StyledCheckboxRowContainer,
  StyledDropdownContainer,
  StyledToggleDropdownButton,
  StyledCheckboxInput,
} from './styled';
const CheckboxDropdown = ({
  className = '',
  label,
  name,
  handleChange = () => {},
  defaultValue = false,
  dropdownComponents = <>Missing dropdown components</>,
}) => {
  const [isChecked, setIsChecked] = useState(defaultValue);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  useEffect(() => {
    if (isChecked) {
      setIsDropdownOpen(true);
    } else {
      setIsDropdownOpen(false);
    }
  }, [isChecked]);
  const handleCheckboxOnChange = e => {
    e?.preventDefault();
    setIsChecked(!isChecked);
    handleChange();
  };
  return (
    <StyledCheckboxDropdownContainer className={className}>
      <StyledCheckboxRowContainer isDropdownOpen={isDropdownOpen}>
        <StyledCheckboxInput
          onClick={e => handleCheckboxOnChange(e)}
          checked={isChecked}
          type="checkbox"
          name={name}
          readOnly
        />
        <StyledCheckboxDropdown isChecked={isChecked}>
          <span></span>
        </StyledCheckboxDropdown>
        <StyledCheckboxDropdownLabel for={name} onClick={() => handleCheckboxOnChange()}>
          {label}
        </StyledCheckboxDropdownLabel>

        {isChecked && (
          <StyledToggleDropdownButton isFlipped={isDropdownOpen} onClick={() => setIsDropdownOpen(!isDropdownOpen)} />
        )}
      </StyledCheckboxRowContainer>
      <StyledDropdownContainer isDropdownOpen={isDropdownOpen}>
        {isDropdownOpen && dropdownComponents}
      </StyledDropdownContainer>
    </StyledCheckboxDropdownContainer>
  );
};

CheckboxDropdown.propTypes = {
  text: PropTypes.string,
};

CheckboxDropdown.defaultProps = {
  text: '',
};

export { CheckboxDropdown };
