import React, { useContext } from 'react';

import { GlobalComponentsContext } from 'context/GlobalComponentsContext';

import leankeepLogo from 'assets/icons/leankeep/leankeep-facility-logo.svg';
import HamburgerIcon from 'assets/icons/hamburguer.svg';

import {
  StyledBackgroundEffect,
  StyledMenuMobile,
  StyledLogo,
  StyledMenuButton,
  StyledFilterButton,
  StyledHamburguer,
} from './styled';

const MobileNavbar = () => {
  const { globalComponentsContext, setGlobalComponentsContext } = useContext(GlobalComponentsContext);
  const { isFiltersOpen, isMobileMenuOpen } = globalComponentsContext;

  const handleMobileMenuOpen = () =>
    setGlobalComponentsContext(prevState => ({
      ...prevState,
      isMobileMenuOpen: !isMobileMenuOpen,
    }));

  const handleFiltersOpen = () =>
    setGlobalComponentsContext({
      ...globalComponentsContext,
      isFiltersOpen: !isFiltersOpen,
    });

  return (
    <>
      <StyledMenuMobile>
        <StyledMenuButton theme="white" size="small" onClick={handleMobileMenuOpen}>
          <StyledHamburguer src={HamburgerIcon} alt="Menu" onClick={handleMobileMenuOpen} />
          Menu
        </StyledMenuButton>
        <StyledLogo src={leankeepLogo} alt="Leankeep" />
        <StyledFilterButton theme="white" size="small" id="toggle_filters_button" onClick={handleFiltersOpen}>
          {isFiltersOpen ? '-' : '+'} FILTROS
        </StyledFilterButton>
      </StyledMenuMobile>
      <StyledBackgroundEffect isActive={isMobileMenuOpen} onClick={handleMobileMenuOpen} />
    </>
  );
};

export { MobileNavbar };
