import styled from 'styled-components';

import { DefaultInput } from 'assets/styled/sheets/components/Forms';

import eyeOpenIcon from 'assets/icons/password-open-eye.svg';
import eyeCloseIcon from 'assets/icons/password-close-eye.svg';
import { inputStyleFactory } from 'components/Form/shared/inputStyleFactory';
import { colors } from 'assets/styled/tokens';
import ReactInputMask from 'react-input-mask';

export const StyledMaskedInput = styled(ReactInputMask)`
  ${DefaultInput};
  ${props => inputStyleFactory(props.size)};
  border-color: ${props => props.haserror && colors.bittersweet};
`;

export const StyledIcon = styled.img`
  height: 10px;
  width: 10px;
  position: absolute;
  right: 8px;
  top: 50%;
  transform: translateY(-50%);
`;

export const StyledPasswordEye = styled.div`
  content: '';
  position: absolute;
  top: 12px;
  right: 12px;
  width: 22px;
  height: 19px;

  cursor: pointer;

  content: ${props => `url(${props.isEyeOpen ? eyeOpenIcon : eyeCloseIcon})`};
  transition: all 300ms;
`;
