import React, { useState, useEffect, useContext, useRef, useCallback } from 'react';

import { Controller, useForm } from 'react-hook-form';
import { Link, useHistory } from 'react-router-dom';

import { colors } from 'assets/styled/tokens';

import { FiltersService } from 'services/reports';
import { GeneralPlansService } from 'services/reports/endpoints/GeneralPlansService';
import { AxiosGlobalFilters } from 'services/main';
import { createGenericErrorToast, createSuccessToast, createToast, createTrashToast } from 'utils/createToast';

import { FiltersContext } from 'context/GlobalFiltersContext';
import { GlobalComponentsContext } from 'context/GlobalComponentsContext';

import { HeaderFiltersPage } from 'containers/HeaderFiltersPage';
//import { Menu } from 'components/Menu';
import { MobileNavbar } from 'components/MobileNavbar';
import { PageWrapper } from 'components/PageWrapper';
import { Toast } from 'components/Toast';
import { Loading } from 'components/Loading';
import { Select } from 'components/Form/subcomponents/Select';
import { PageHeader } from 'components/PageHeader';
import EditGeneralPlanActivityButton from 'components/Tables/GeneralPlans/EditGeneralPlanActivityButton';
import GeneralPlanActivities from 'components/Tables/GeneralPlanActivities';
import ActivitiesTableFrequencyCell from 'components/Tables/GeneralPlanActivities/ActivitiesTableFrequencyCell';
import ActivitiesTableDescriptionCell from 'components/Tables/GeneralPlanActivities/ActivitiesTableDescriptionCell';
// import ActivitiesTableMaterialCell from 'components/Tables/GeneralPlanActivities/ActivitiesTableMaterialCell';
import ActivitiesTableStartDateCell from 'components/Tables/GeneralPlanActivities/ActivitiesTableStartDateCell';
import { ModalGeneric } from 'components/ModalGeneric';

import {
  SubmitPlanInfoFormButton,
  StyledFormGroup,
  StyledPlanInfoHeader,
  StyledFormSectionNavbar,
  StyledFormSectionNavItem,
  StyledNavItemCheckmark,
  StyledDiscardDraftButton,
  StyledDraftOptionsContainer,
  StyledDeleteDraftModalParagraph,
  StyledModalButtons,
  StyledModalCancelButton,
  StyledModalDeleteDraftButton,
  StyledFormsContainer,
  StyledFormSection,
  StyledPlanInfoSection,
  StyledPlanInfoFormSection,
  StyledAddActivitiesButton,
  StyledModalBody,
  StyledFormsContainerWrapper,
  StyledTabHeaderContainer,
  StyledTabHeader,
  StyledPlanNameInput,
  StyledEmptyActivitiesHeader,
  StyledEmptyActivitiesContainer,
  StyledEmptyActivitiesButtonsRow,
  NextSectionArrow,
  StyledEmptyActivitiesIcon,
  StyledReturnFormStepButton,
  StyledAdvanceFirstFormButtonRow,
  StyledAdvancePlanInfoFormButton,
  StyledFormButtonsRow,
  StyledSchedulePlanSuccessContainer,
  StyledSchedulePlanSuccessIcon,
  StyledSchedulePlanSuccessHeader,
  StyledSchedulePlanSuccessSubHeader,
  StyledSchedulePlanSuccessButton,
} from './styled';

import AddActivityFormModal from '../AddActivityFormModal';
import EditActivityFormModal from './EditActivityFormModal';
import FormApplicationStep from './FormApplicationStep';
import { all } from 'utils/helpers/promises';
import { frequencyToPeriodicity, getFrequencyDescription } from '../utils';
import { NewMenu } from 'components/Menu/NewMenu';

const NewGeneralPlanPage = ({ pathname }) => {
  const fetchIdRef = useRef(0);
  const { push } = useHistory();
  const { headerFiltersContext } = useContext(FiltersContext);
  const { headerFilters } = headerFiltersContext;
  const { globalComponentsContext, setGlobalComponentsContext } = useContext(GlobalComponentsContext);
  const { isLoading } = globalComponentsContext;
  const [isDeleteDraftModalOpen, setIsDeleteDraftModalOpen] = useState(false);
  // const [activeDraftPlanId, setActiveDraftPlanId] = useState(120); // step 3 full id
  const [activeDraftPlanId, setActiveDraftPlanId] = useState(undefined);

  const [hasActivitiesUpdated, setHasActivitiesUpdated] = useState(undefined);
  const [isPlanInfoFormDirty, setIsPlanInfoFormDirty] = useState(false);
  const [isSchedulePlanSuccess, setIsSchedulePlanSuccess] = useState(false);
  const [isAddActivityModalActive, setIsAddActivityModalActive] = useState(false);
  const [editActivityId, setEditActivityId] = useState(undefined);
  // const [activeFormStep, setActiveFormStep] = useState(2);
  const [activeFormStep, setActiveFormStep] = useState(1);
  const [activitiesTablePageSize, setActivitiesTablePageSize] = useState(6);
  const [activitiesTablePageIndex, setActivitiesTablePageIndex] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [totalCount, setTotalCount] = useState(0);

  const [toastList, setToastList] = useState([]);
  const [activitiesTableData, setActivitiesTableData] = useState([]);
  const [finishedFormSteps, setFinishedFormSteps] = useState([]);
  const [fields, setFields] = useState({
    description: [],
    system: [],
    department: [],
    activities: [],
  });

  const columns = [
    {
      Header: 'Descrição',
      disableSortBy: true,
      accessor: 'descricao', // accessor is the "key" in the data
      Cell: props => <ActivitiesTableDescriptionCell {...props} />,
    },
    {
      Header: 'Frequência',
      accessor: 'frequency',
      disableSortBy: true,
      Cell: props => <ActivitiesTableFrequencyCell {...props} />,
    },
    {
      Header: 'Data / Hora início',
      accessor: 'startDateTime',
      disableSortBy: true,
      Cell: props => <ActivitiesTableStartDateCell {...props} />,
    },
    // {
    //   Header: 'Material',
    //   accessor: 'material',
    //   Cell: (props) => <ActivitiesTableMaterialCell {...props} />
    // },
    {
      Header: 'Categoria',
      disableSortBy: true,
      accessor: 'category',
    },

    {
      Header: 'Ação',
      accessor: 'atividadeGeral',
      disableSortBy: true,
      Cell: props => (
        <EditGeneralPlanActivityButton
          setEditActivityId={setEditActivityId}
          handleRemoveActivity={handleRemoveActivity}
          {...props}
        />
      ),
    },
  ];
  const {
    // handleSubmit,
    register,
    setValue,
    control,
    getValues,
    formState: { errors },
  } = useForm({
    defaultValues: {
      extraApplications: 'onlyMaintenanceUnits',
      planName: '',
      system: '',
      department: '',
    },
  });

  const handleRemoveActivity = useCallback(
    id => {
      GeneralPlansService.deletePlanActivity({
        planId: activeDraftPlanId,
        companyId: headerFilters?.companies?.id,
        activityId: id,
      })
        .then(() => {
          setHasActivitiesUpdated(true);
          createTrashToast(setToastList, 'Atividade excluída.');
        })
        .catch(() => {
          createGenericErrorToast(setToastList);
        });
    },
    [activeDraftPlanId, headerFilters?.companies?.id, setHasActivitiesUpdated],
  );
  const addNewActivity = async activity => {
    const {
      planId,
      category,
      description,
      isConformityActive,
      estimatedTime,
      frequency,
      isMeasurementRequired,
      medicoes,
      repeatEvery,
      priority,
      startTime,
      saturdayExcChecked,
      sundayExcChecked,
      specificDay,
      specificDays,
      holidaysExcChecked,
      startDate,
    } = activity;
    let startDateTime = startDate;
    const hours = startTime.split(':')[0];
    const minutes = startTime.split(':')[1];

    startDateTime.setUTCHours(hours);
    startDateTime.setMinutes(minutes);
    startDateTime = startDateTime.toISOString();

    const estimatedTimeHours = estimatedTime.split(':')[0];
    const estimatedTimeMinutes = estimatedTime.split(':')[1];

    let _estimatedTime = estimatedTimeHours * 60 + parseInt(estimatedTimeMinutes);
    let _specificDays;
    if (specificDays) {
      _specificDays = specificDays.map(day => ({ [day]: true }));
    } else {
      if (frequency === 'daily') {
        let singleSpecificDays = ['segunda', 'terca', 'quarta', 'quinta', 'sexta', 'sabado', 'domingo'];
        if (saturdayExcChecked === true) {
          _specificDays = singleSpecificDays.map(day => {
            if (day !== 'sabado') {
              return { [day]: true };
            } else {
              return { [day]: false };
            }
          });
        } else if (sundayExcChecked === true) {
          _specificDays = singleSpecificDays.map(day => {
            if (day !== 'domingo') {
              return { [day]: true };
            } else {
              return { [day]: false };
            }
          });
        } else {
          _specificDays = singleSpecificDays.map(day => ({ [day]: true }));
        }
      } else {
        _specificDays = [specificDay].map(day => ({ [day]: true }));
      }
    }

    let formattedData = {
      companyId: headerFilters?.companies?.id,
      planoGeral: planId,
      prioridade: priority,
      ...(editActivityId && { atividadeGeral: editActivityId }),
      descricao: description,
      categoria: category,
      tempoPrevisto: parseInt(_estimatedTime),
      inicio: startDateTime,
      periodicidade: frequencyToPeriodicity(frequency),
      multiplicador: repeatEvery ? parseInt(repeatEvery) : 1,
      conformidadeObrigatoriaBaixa: isConformityActive,
      medicaoObrigatoriaBaixa: isMeasurementRequired,
      // exc false when checkbox selected
      sabadoExc: saturdayExcChecked === true ? false : true,
      domingoExc: sundayExcChecked === true ? false : true,
      feriadosExc: holidaysExcChecked === true ? false : true,
      tipoValidacao: isConformityActive ? 1 : null,
    };
    if (medicoes[0]?.unidadeMedicao) {
      formattedData.medicoes = medicoes;
    }
    Object.assign(formattedData, ..._specificDays);

    return await GeneralPlansService.addGeneralPlanActivity(formattedData)
      .then(() => {
        setHasActivitiesUpdated(true);
      })
      .catch(e => console.error(e));

    // const activeCount = newActivities.length;
    // setTotalCount(activeCount);
    // setPageCount(Math.ceil(activeCount / activitiesTablePageSize));
  };

  const handleEditActivity = async activity => {
    await addNewActivity(activity)
      .then(() => {
        setHasActivitiesUpdated(true);
        createSuccessToast(setToastList, 'Edição concluída.');
      })
      .catch(e => {
        console.error(e);
        createGenericErrorToast(setToastList);
      });
  };

  const handleCreateGeneralPlan = async () => {
    setIsPlanInfoFormDirty(true);
    const hasError = validatePlanInfoForm();
    if (hasError) {
      return;
    }
    // create if plan hasn't been created
    if (!activeDraftPlanId) {
      try {
        const response = await GeneralPlansService.createPlan({
          name: getValues('planName'),
          companyId: headerFilters?.companies?.id,
          system: getValues('system'),
          department: getValues('department'),
        });
        const { data } = response;
        setActiveDraftPlanId(data.planoGeral);
        advanceFormStep();
      } catch (e) {
        setToastList(prevState => [
          ...prevState,
          createToast({
            type: 'error',
            message: 'Ocorreu um erro ao criar o plano. Tente novamente.',
          }),
        ]);
      }
    } else {
      advanceFormStep();
      // update if plan has already been created
    }
  };
  const validatePlanInfoForm = () => {
    const planName = getValues('planName');
    const system = getValues('system');
    const department = getValues('department');
    const requiredFields = [planName, system, department];
    const invalidFieldsCount = requiredFields.filter(element => element?.length === 0).length;

    if (!headerFilters?.companies?.id) {
      setToastList(prevState => [
        ...prevState,

        createToast({
          type: 'error',

          message: 'Uma empresa não foi selecionada nos filtros',
        }),
      ]);

      return true;
    } else if (invalidFieldsCount === 1) {
      setToastList(prevState => [
        ...prevState,

        createToast({
          type: 'error',

          message: 'Um campo obrigatório não foi preenchido.',
        }),
      ]);

      return true;
    } else if (invalidFieldsCount > 1) {
      setToastList(prevState => [
        ...prevState,

        createToast({
          type: 'error',

          message: 'Há campos obrigatórios não preenchidos.',
        }),
      ]);
      return true;
    }

    return false;
  };

  const populateFieldsStrategy = useCallback(async () => {
    const companyId = headerFilters?.companies?.id;
    // TODO REFACTOR TO REMOVE ALL THESE  COMPANYID CONDITIONALS
    if (companyId) {
      return await all([
        FiltersService.getSystems({
          companyId,
        }),
        FiltersService.getCompanyDepartments({ companyId }),
      ]).then(values => ({
        system: values[0].data.map(({ nome, sistemaEmpresa }) => ({
          label: nome,
          value: sistemaEmpresa,
        })),
        departments: values[1].data.map(({ nome, setor }) => ({
          label: nome,
          value: setor,
        })),
      }));
    }

    // eslint-disable-next-line
  }, [headerFilters?.companies?.id]);

  const populateFields = async () => {
    try {
      const fieldsData = await populateFieldsStrategy();
      setFields(prevState => ({
        ...prevState,
        ...fieldsData,
      }));
    } catch (err) {
      console.error(err);
      createGenericErrorToast(setToastList);
    }
  };

  const fetchData = async () => {
    setGlobalComponentsContext(prevState => ({
      ...prevState,
      isLoading: true,
    }));
    // await cleanFields();
    await populateFields();
    setGlobalComponentsContext(prevState => ({
      ...prevState,
      isLoading: false,
    }));
  };

  const isStepFinished = stepNumber => {
    if (finishedFormSteps.includes(stepNumber)) {
      return true;
    } else {
      return false;
    }
  };
  const returnFormStep = () => {
    setActiveFormStep(activeFormStep - 1);
  };
  const advanceFormStep = () => {
    if (activeFormStep === 0) {
    }
    if (activeFormStep === 3) {
      // const newPlanData = {
      //   planName: getValues('planName'),
      //   system: getValues('system'),
      //   department: getValues('department'),
      //   extraApplications: getValues('extraApplications'),
      //   savedPlanActivities: savedPlanActivities
      // };
    }

    if (activeFormStep <= 2) {
      let newFinishedFormSteps = finishedFormSteps;
      finishedFormSteps.push(activeFormStep);

      setFinishedFormSteps(newFinishedFormSteps);
      setActiveFormStep(activeFormStep + 1);
    }
  };

  const formatDate = datetime => {
    let date = new Date(datetime);
    let day = date.getDate();
    let _day = day < 10 ? '0' + day : day;
    let month = date.getMonth() + 1; //+1 pois no getMonth Janeiro começa com zero.
    let _month = month < 10 ? '0' + month : month;
    let _year = date.getFullYear();
    let _date = _day + '/' + _month + '/' + _year;
    let hour = date.getHours();
    let _hours = hour < 10 ? '0' + hour : hour;
    let minutes = date.getMinutes();
    let _minutes = minutes < 10 ? '0' + minutes : minutes;
    let formattedDatetime = `${_date} • ${_hours}:${_minutes}`;
    return formattedDatetime;
  };
  const fetchGeneralPlanActivities = useCallback(
    async ({ pageSize, sortBy }) => {
      // Give this fetch an ID
      const fetchId = ++fetchIdRef.current;

      let params = {
        planoGeral: 3,
        PageIndex: activitiesTablePageIndex + 1,
        PageSize: pageSize,
      };
      let headers;
      if (headerFilters?.companies?.id) {
        headers = {
          EmpresaId: headerFilters?.companies?.id,
        };
      } else {
        headers = undefined;
      }

      if (sortBy[0]?.id) {
        params = {
          ...params,
          'OrderBy.Column': sortBy[0].id,
          'OrderBy.Desc': sortBy[0].desc,
        };
      }

      // Only update the data if this is the latest fetch
      if (fetchId === fetchIdRef.current) {
        await all([
          AxiosGlobalFilters({
            method: 'get',
            url: `/v1/planosgerais/${activeDraftPlanId}/AtividadesGerais`,
            headers: headers,
            params: params,
          }),
          AxiosGlobalFilters({
            method: 'get',
            url: `/v1/planosgerais/${activeDraftPlanId}/AtividadesGerais/count`,
            headers: headers,
          }),
        ])
          .then(res => {
            const data = res[0].data;

            const adaptedData = data.map(plan => {
              return {
                ...plan,
                frequency: getFrequencyDescription(plan),
                startDateTime: formatDate(plan.inicio),
                category: plan.categoriaNome,
              };
            });

            setActivitiesTableData(adaptedData.sort());
            setPageCount(Math.ceil(res[1].data / pageSize));

            setTotalCount(res[1].data);
          })
          .catch(err => {
            console.error(err);
            setToastList(prevState => [
              ...prevState,
              createToast({
                type: 'error',
                message: 'Ocorreu um erro ao carregar os planos. Tente novamente.',
              }),
            ]);
          });
      }
    },
    // eslint-disable-next-line
    [
      headerFilters?.companies?.id,
      setGlobalComponentsContext,
      activeDraftPlanId,
      hasActivitiesUpdated,
      activitiesTablePageIndex,
    ],
  );
  const deleteDraft = async () => {
    try {
      const { data } = await GeneralPlansService.deletePlan({
        planId: activeDraftPlanId,
        companyId: headerFilters?.companies?.id,
      });

      if (data === 'Plano excluído com sucesso.') {
        createTrashToast(setToastList, 'Rascunho descartado.');
      } else {
        throw new Error('Unexpected backend status');
      }
    } catch (e) {
      console.error(e);
      createGenericErrorToast(setToastList);
    } finally {
      setIsDeleteDraftModalOpen(false);
      setTimeout(() => push('/atividades/planos-gerais'), 3000);
    }
  };
  useEffect(() => {
    fetchData();
    if (!headerFilters?.companies?.id) {
      push('/atividades/planos-gerais');
    }
    // eslint-disable-next-line
  }, [errors, headerFilters]);
  const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);
  return (
    <>
      <PageWrapper background={colors.pampas}>
        {isLoading && <Loading />}
        <MobileNavbar />
        {/* <Menu /> */}
        <NewMenu setIsSubMenuOpen={setIsSubMenuOpen} />
        <HeaderFiltersPage location={pathname} isSubMenuOpen={isSubMenuOpen}>
          <PageHeader title="Planos Gerais" />

          <StyledFormsContainerWrapper>
            <StyledTabHeaderContainer>
              <Link to="/atividades/planos-gerais">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="12" cy="12" r="11.5" stroke="#E5E5E5" />
                  <path d="M17 12H7" stroke="#BBBBBB" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                  <path
                    d="M11 8L7 12L11 16"
                    stroke="#BBBBBB"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </Link>
              <StyledTabHeader>Novo Plano Geral</StyledTabHeader>
              {isStepFinished(1) && !isSchedulePlanSuccess && (
                <StyledDraftOptionsContainer>
                  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="6" cy="6" r="6" fill="#BBBBBB" />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M5.53651 8.81249C5.28206 9.06246 4.85747 9.06246 4.60302 8.81249L2.19023 6.4422C1.93659 6.19303 1.93659 5.77838 2.19023 5.5292L2.71349 5.01517C2.96778 4.76536 3.37748 4.7652 3.63196 5.0147L5.07616 6.4209L8.36756 3.18747C8.622 2.93751 9.03206 2.93751 9.2865 3.18747L9.80976 3.70151C10.0634 3.95069 10.0634 4.36534 9.80976 4.61451L5.53651 8.81249Z"
                      fill="white"
                    />
                  </svg>
                  Rascunho salvo
                  <StyledDiscardDraftButton onClick={() => setIsDeleteDraftModalOpen(true)}>
                    <svg width="10" height="12" viewBox="0 0 10 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M1.43 10.9453C1.45232 11.5312 1.94196 12 2.5 12H7.47C8.02804 12 8.51768 11.5312 8.54 10.9453L9 4H1L1.43 10.9453Z"
                        fill="#BBBBBB"
                      />
                      <rect y="1" width="10" height="2" rx="0.5" fill="#BBBBBB" />
                      <path
                        d="M3.10845 0.269862C3.25094 0.0988672 3.46203 0 3.68461 0H6.31539C6.53797 0 6.74906 0.0988673 6.89155 0.269862L7.5 1H2.5L3.10845 0.269862Z"
                        fill="#BBBBBB"
                      />
                    </svg>
                    Descartar rascunho
                  </StyledDiscardDraftButton>
                </StyledDraftOptionsContainer>
              )}
            </StyledTabHeaderContainer>

            {isSchedulePlanSuccess ? (
              <StyledSchedulePlanSuccessContainer>
                <StyledSchedulePlanSuccessIcon></StyledSchedulePlanSuccessIcon>
                <StyledSchedulePlanSuccessHeader>
                  Estamos agendando seu plano de atividades
                </StyledSchedulePlanSuccessHeader>
                <StyledSchedulePlanSuccessSubHeader>
                  Isso pode demorar um pouco. Continue suas tarefas enquanto isso.
                </StyledSchedulePlanSuccessSubHeader>
                <StyledSchedulePlanSuccessButton onClick={() => push('/atividades/planos-gerais')} theme="white">
                  Voltar à tela de planos
                </StyledSchedulePlanSuccessButton>
              </StyledSchedulePlanSuccessContainer>
            ) : (
              <StyledFormsContainer>
                <StyledFormSectionNavbar>
                  <StyledFormSectionNavItem
                    isActive={activeFormStep === 1}
                    isFinished={isStepFinished(1)}
                    onClick={() => setActiveFormStep(1)}
                  >
                    <StyledNavItemCheckmark
                      isActive={isStepFinished(1)}
                      width="12"
                      height="12"
                      viewBox="0 0 12 12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle cx="6" cy="6" r="6" fill="#00A889" />
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M5.56548 8.81249C5.32693 9.06246 4.92888 9.06246 4.69033 8.81249L2.42834 6.4422C2.19055 6.19303 2.19055 5.77838 2.42834 5.5292L2.9189 5.01517C3.15729 4.76536 3.54138 4.7652 3.77996 5.0147L5.1339 6.4209L8.21958 3.18747C8.45813 2.93751 8.84255 2.93751 9.0811 3.18747L9.57165 3.70151C9.80944 3.95069 9.80944 4.36534 9.57165 4.61451L5.56548 8.81249Z"
                        fill="white"
                      />
                    </StyledNavItemCheckmark>
                    1. Dados do plano
                  </StyledFormSectionNavItem>
                  <NextSectionArrow
                    width="7"
                    height="12"
                    viewBox="0 0 7 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1 1L6 6L1 11"
                      stroke="#BBBBBB"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </NextSectionArrow>

                  <StyledFormSectionNavItem
                    isFinished={isStepFinished(2)}
                    isActive={activeFormStep === 2}
                    onClick={() => (isStepFinished(1) ? setActiveFormStep(2) : () => {})}
                  >
                    <StyledNavItemCheckmark
                      width="12"
                      height="12"
                      viewBox="0 0 12 12"
                      isActive={isStepFinished(2)}
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle cx="6" cy="6" r="6" fill="#00A889" />
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M5.56548 8.81249C5.32693 9.06246 4.92888 9.06246 4.69033 8.81249L2.42834 6.4422C2.19055 6.19303 2.19055 5.77838 2.42834 5.5292L2.9189 5.01517C3.15729 4.76536 3.54138 4.7652 3.77996 5.0147L5.1339 6.4209L8.21958 3.18747C8.45813 2.93751 8.84255 2.93751 9.0811 3.18747L9.57165 3.70151C9.80944 3.95069 9.80944 4.36534 9.57165 4.61451L5.56548 8.81249Z"
                        fill="white"
                      />
                    </StyledNavItemCheckmark>
                    2. Atividades
                  </StyledFormSectionNavItem>
                  <NextSectionArrow
                    width="7"
                    height="12"
                    viewBox="0 0 7 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1 1L6 6L1 11"
                      stroke="#BBBBBB"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </NextSectionArrow>
                  <StyledFormSectionNavItem
                    isFinished={isStepFinished(3)}
                    isActive={activeFormStep === 3}
                    onClick={() => (isStepFinished(1) && isStepFinished(2) ? setActiveFormStep(3) : () => {})}
                  >
                    <StyledNavItemCheckmark
                      width="12"
                      height="12"
                      viewBox="0 0 12 12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      isActive={isStepFinished(3)}
                    >
                      <circle cx="6" cy="6" r="6" fill="#00A889" />
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M5.56548 8.81249C5.32693 9.06246 4.92888 9.06246 4.69033 8.81249L2.42834 6.4422C2.19055 6.19303 2.19055 5.77838 2.42834 5.5292L2.9189 5.01517C3.15729 4.76536 3.54138 4.7652 3.77996 5.0147L5.1339 6.4209L8.21958 3.18747C8.45813 2.93751 8.84255 2.93751 9.0811 3.18747L9.57165 3.70151C9.80944 3.95069 9.80944 4.36534 9.57165 4.61451L5.56548 8.81249Z"
                        fill="white"
                      />
                    </StyledNavItemCheckmark>
                    3. Aplicação
                  </StyledFormSectionNavItem>
                </StyledFormSectionNavbar>

                <StyledPlanInfoSection isActive={activeFormStep === 1}>
                  <StyledPlanInfoFormSection>
                    <StyledPlanInfoHeader>Dados do plano</StyledPlanInfoHeader>
                    <Controller
                      render={props => (
                        <StyledPlanNameInput
                          {...props}
                          label="Nome do plano"
                          isRequired
                          type="text"
                          autoComplete="off"
                          name="planName"
                          maxLength="50"
                          error={isPlanInfoFormDirty && props.value.length === 0}
                          errormessage="Campo obrigatório"
                          size="small"
                          placeholder="Preenchimento obrigatório"
                          {...register('planName')}
                        />
                      )}
                      name="planName"
                      control={control}
                    />
                    <StyledFormGroup label="Sistema" isRequired>
                      <Controller
                        render={props => (
                          <Select
                            size="small"
                            {...props}
                            placeholder="Seleção obrigatória"
                            name="system"
                            options={fields?.system}
                            error={isPlanInfoFormDirty && props.value.length === 0}
                            errormessage="Campo obrigatório"
                            {...register('system')}
                          />
                        )}
                        name="system"
                        control={control}
                      />
                    </StyledFormGroup>
                    <StyledFormGroup label="Setor" isRequired>
                      <Controller
                        render={props => (
                          <Select
                            size="small"
                            {...props}
                            error={isPlanInfoFormDirty && props.value.length === 0}
                            errormessage="Campo obrigatório"
                            placeholder="Seleção obrigatória"
                            name="department"
                            options={fields?.departments}
                          />
                        )}
                        name="department"
                        control={control}
                      />
                    </StyledFormGroup>
                    <StyledAdvanceFirstFormButtonRow>
                      <StyledAdvancePlanInfoFormButton
                        onClick={() => handleCreateGeneralPlan()}
                        form="newPlan"
                        type="submit"
                      >
                        Avançar
                        <svg width="12" height="10" viewBox="0 0 12 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M1 5H11"
                            stroke="white"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M7 1L11 5L7 9"
                            stroke="white"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </StyledAdvancePlanInfoFormButton>
                    </StyledAdvanceFirstFormButtonRow>
                  </StyledPlanInfoFormSection>
                </StyledPlanInfoSection>
                <StyledFormSection isActive={activeFormStep === 2}>
                  <GeneralPlanActivities
                    setIsAddActivityModalActive={setIsAddActivityModalActive}
                    activitiesTablePageSize={activitiesTablePageSize}
                    setActivitiesTablePageSize={setActivitiesTablePageSize}
                    pageIndex={activitiesTablePageIndex}
                    setPageIndex={setActivitiesTablePageIndex}
                    setEditActivityId={setEditActivityId}
                    columns={columns}
                    data={activitiesTableData}
                    planId={activeDraftPlanId}
                    fetchData={fetchGeneralPlanActivities}
                    setToastList={setToastList}
                    hasActivitiesUpdated={hasActivitiesUpdated}
                    setHasActivitiesUpdated={setHasActivitiesUpdated}
                    loading={isLoading}
                    totalCount={totalCount}
                    pageCount={pageCount}
                  />
                  {activitiesTableData.length === 0 && (
                    <StyledEmptyActivitiesContainer>
                      <StyledEmptyActivitiesIcon
                        width="80"
                        height="88"
                        viewBox="0 0 80 88"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M1.75436e-05 30.1139V57.8454C-0.00416577 60.7046 0.739864 63.5143 2.15712 65.9914C3.57437 68.4686 5.61477 70.5256 8.07267 71.9552L31.9273 85.8209C34.3817 87.2485 37.1659 88 40 88C42.8341 88 45.6183 87.2485 48.0727 85.8209L71.9273 71.9552C74.3852 70.5256 76.4256 68.4686 77.8429 65.9914C79.2601 63.5143 80.0042 60.7046 80 57.8454V30.1139C79.9971 27.2617 79.2497 24.4603 77.8328 21.9909C76.4159 19.5216 74.3793 17.471 71.9273 16.0448L48.0727 2.17907C45.6183 0.751537 42.8341 0 40 0C37.1659 0 34.3817 0.751537 31.9273 2.17907L8.07267 16.2481C5.65006 17.6568 3.63231 19.6755 2.21715 22.1062C0.802002 24.5368 0.0379999 27.2962 1.75436e-05 30.1139Z"
                          fill="#EAE8E5"
                        />
                        <path
                          d="M61.7241 50.0833C62.4885 48.75 61.5712 47 60.1188 47H29.619C27.7844 47 26.1792 48.0833 25.3383 49.6667L19.8346 60C19.0702 61.3333 19.9875 63 21.4398 63H51.9397C53.7743 63 55.3795 62 56.2204 60.4167L61.7241 50.0833ZM29.619 44.3333H54.6915V40.3333C54.6915 38.1667 53.0098 36.3333 51.0224 36.3333H38.7919L33.8997 31H21.6692C19.6053 31 18 32.8333 18 35V58.25L23.2744 48.3333C24.5739 45.9167 26.9436 44.3333 29.619 44.3333Z"
                          fill="#AAAAAA"
                        />
                        <rect x="49" y="18" width="3" height="8" rx="1.5" fill="#AAAAAA" />
                        <rect x="66" y="32" width="3" height="8" rx="1.5" transform="rotate(90 66 32)" fill="#AAAAAA" />
                        <rect
                          x="60.6567"
                          y="21"
                          width="3"
                          height="8"
                          rx="1.5"
                          transform="rotate(45 60.6567 21)"
                          fill="#AAAAAA"
                        />
                      </StyledEmptyActivitiesIcon>

                      <StyledEmptyActivitiesHeader>
                        Adicione as atividades que devem ser executadas
                      </StyledEmptyActivitiesHeader>
                      <StyledEmptyActivitiesButtonsRow>
                        <StyledReturnFormStepButton
                          onClick={() => returnFormStep()}
                          form="newPlan"
                          type="submit"
                          theme="lightOutline"
                        >
                          <svg
                            width="12"
                            height="10"
                            viewBox="0 0 12 10"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M11 5H1"
                              stroke={colors.carbon}
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M5 1L1 5L5 9"
                              stroke={colors.carbon}
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                          Voltar
                        </StyledReturnFormStepButton>

                        <StyledAddActivitiesButton onClick={() => setIsAddActivityModalActive(true)}>
                          <svg
                            width="12"
                            height="12"
                            viewBox="0 0 12 12"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M1 6H11" stroke="white" strokeWidth="2" strokeLinecap="round" />
                            <path d="M6 1V11" stroke="white" strokeWidth="2" strokeLinecap="round" />
                          </svg>
                          Adicionar Atividade
                        </StyledAddActivitiesButton>
                      </StyledEmptyActivitiesButtonsRow>
                    </StyledEmptyActivitiesContainer>
                  )}
                  {activitiesTableData.length > 0 && (
                    <StyledFormButtonsRow>
                      <StyledReturnFormStepButton
                        onClick={() => returnFormStep()}
                        form="newPlan"
                        theme="lightOutline"
                        type="submit"
                      >
                        <svg width="12" height="10" viewBox="0 0 12 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M11 5H1"
                            stroke={colors.carbon}
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M5 1L1 5L5 9"
                            stroke={colors.carbon}
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                        Voltar
                      </StyledReturnFormStepButton>

                      <SubmitPlanInfoFormButton onClick={() => advanceFormStep()} form="newPlan" type="submit">
                        Avançar
                        <svg width="12" height="10" viewBox="0 0 12 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M1 5H11"
                            stroke="white"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M7 1L11 5L7 9"
                            stroke="white"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </SubmitPlanInfoFormButton>
                    </StyledFormButtonsRow>
                  )}
                </StyledFormSection>
                <FormApplicationStep
                  isActive={activeFormStep === 3}
                  planId={activeDraftPlanId}
                  companyId={headerFilters?.companies?.id}
                  getValues={getValues}
                  setIsSchedulePlanSuccess={setIsSchedulePlanSuccess}
                  setValue={setValue}
                  register={register}
                  control={control}
                  returnFormStep={returnFormStep}
                  errors={errors}
                  setToastList={setToastList}
                  createToast={createToast}
                  advanceFormStep={advanceFormStep}
                  setGlobalComponentsContext={setGlobalComponentsContext}
                />

                {/* </StyledApplicationForm> */}
              </StyledFormsContainer>
            )}
          </StyledFormsContainerWrapper>
        </HeaderFiltersPage>
      </PageWrapper>
      <ModalGeneric open={isDeleteDraftModalOpen} closeModal={() => setIsDeleteDraftModalOpen(false)}>
        <StyledModalBody>
          <svg width="80" height="88" viewBox="0 0 80 88" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M1.75436e-05 30.1139V57.8454C-0.00416577 60.7046 0.739864 63.5143 2.15712 65.9914C3.57437 68.4686 5.61477 70.5256 8.07267 71.9552L31.9273 85.8209C34.3817 87.2485 37.1659 88 40 88C42.8341 88 45.6183 87.2485 48.0727 85.8209L71.9273 71.9552C74.3852 70.5256 76.4256 68.4686 77.8429 65.9914C79.2601 63.5143 80.0042 60.7046 80 57.8454V30.1139C79.9971 27.2617 79.2497 24.4603 77.8328 21.9909C76.4159 19.5216 74.3793 17.471 71.9273 16.0448L48.0727 2.17907C45.6183 0.751537 42.8341 0 40 0C37.1659 0 34.3817 0.751537 31.9273 2.17907L8.07267 16.2481C5.65006 17.6568 3.63231 19.6755 2.21715 22.1062C0.802002 24.5368 0.0379999 27.2962 1.75436e-05 30.1139Z"
              fill="#FFDBD6"
            />
            <path
              d="M54.8571 26.5H46.2857L45.5714 25.0938C45.2857 24.4688 44.6429 24 44.0714 24H35.8571C35.2857 24 34.5714 24.4688 34.3571 25.0938L33.7143 26.5H25.1429C24.5 26.5 24 27.125 24 27.75V30.25C24 30.9531 24.5 31.5 25.1429 31.5H54.8571C55.4286 31.5 56 30.9531 56 30.25V27.75C56 27.125 55.4286 26.5 54.8571 26.5ZM27.7857 60.4844C27.8571 62.4375 29.4286 64 31.2143 64H48.7143C50.5 64 52.0714 62.4375 52.1429 60.4844L53.7143 34H26.2857L27.7857 60.4844Z"
              fill="#EC6655"
            />
            <rect x="34" y="42" width="2" height="14" fill="#FFDBD6" />
            <rect x="39" y="42" width="2" height="14" fill="#FFDBD6" />
            <rect x="44" y="42" width="2" height="14" fill="#FFDBD6" />
          </svg>

          <h6>Descartar rascunho?</h6>
          <StyledDeleteDraftModalParagraph>
            Não será possível recuperar o rascunho depois.
          </StyledDeleteDraftModalParagraph>
          <StyledModalButtons>
            <StyledModalCancelButton type="button" onClick={() => setIsDeleteDraftModalOpen(false)}>
              Cancelar
            </StyledModalCancelButton>
            <StyledModalDeleteDraftButton type="button" onClick={() => deleteDraft()}>
              Descartar
            </StyledModalDeleteDraftButton>
          </StyledModalButtons>
        </StyledModalBody>
      </ModalGeneric>
      <Toast toastList={toastList} autoDelete dismissTime={7000} />
      <AddActivityFormModal
        isAddActivityModalActive={isAddActivityModalActive}
        setGlobalComponentsContext={setGlobalComponentsContext}
        addActivity={addNewActivity}
        activeDraftPlanId={activeDraftPlanId}
        createToast={createToast}
        setToastList={setToastList}
        setIsAddActivityModalActive={setIsAddActivityModalActive}
        companyId={headerFilters?.companies?.id}
      />{' '}
      <EditActivityFormModal
        isEditActivityModalActive={editActivityId}
        editActivityId={editActivityId}
        setGlobalComponentsContext={setGlobalComponentsContext}
        editActivity={handleEditActivity}
        planId={activeDraftPlanId}
        createToast={createToast}
        setToastList={setToastList}
        setEditActivityId={setEditActivityId}
        companyId={headerFilters?.companies?.id}
      />
    </>
  );
};

export { NewGeneralPlanPage };
