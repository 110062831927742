import React, { useState, useMemo, useContext, useRef, useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import { colors } from 'assets/styled/tokens';
import { ReactComponent as ToastWarningIcon } from 'assets/icons/toast-warning-icon.svg';
import { AxiosGlobalFilters } from 'services/main';
import { createToast, createDarkSuccessToast, createTrashToast, createGenericErrorToast } from 'utils/createToast';
import { FiltersContext } from 'context/GlobalFiltersContext';
import { HeaderFiltersPage } from 'containers/HeaderFiltersPage';
//import { Menu } from 'components/Menu';
import { MobileNavbar } from 'components/MobileNavbar';
import { PageWrapper } from 'components/PageWrapper';
import { Toast } from 'components/Toast';

import { PageHeader } from 'components/PageHeader';
import GeneralPlansTable from 'components/Tables/GeneralPlans';
import GeneralPlanApplicationCell from 'components/Tables/GeneralPlans/GeneralPlanApplicationCell';
import GeneralPlanStatusCell from 'components/Tables/GeneralPlans/GeneralPlanStatusCell';
import GeneralPlanLastUpdateCell from 'components/Tables/GeneralPlans/GeneralPlanLastUpdateCell';
import EditGeneralPlanButton from 'components/Tables/GeneralPlans/EditGeneralPlanButton';

import {
  StyledAddPlanButton,
  StyledAddPlanButtonWrapper,
  StyledTableContainer,
  StyledTableInnerContainer,
  StyledTabHeader,
  StyledEmptyPlansHeader,
  StyledEmptyPlansAddPlanButton,
  StyledEmptyPlansContainer,
} from '../styled';
import {
  StyledDeleteDraftModalParagraph,
  StyledModalBody,
  StyledModalButtons,
  StyledModalCancelButton,
  StyledModalDeleteDraftButton,
} from '../NewGeneralPlanPage/styled';
import { ModalGeneric } from 'components/ModalGeneric';
import { StyledModalDeactivatePlanButton, StyledModalParagraph } from '../EditGeneralPlanPage/styled';
import { GlobalComponentsContext } from 'context/GlobalComponentsContext';
import { Loading } from 'components/Loading';
import { GeneralPlansService } from 'services/reports/endpoints/GeneralPlansService';
import { NewMenu } from 'components/Menu/NewMenu';

const ViewGeneralPlansPage = ({ pathname }) => {
  const { headerFiltersContext } = useContext(FiltersContext);
  const { globalComponentsContext, setGlobalComponentsContext } = useContext(GlobalComponentsContext);
  const { headerFilters } = headerFiltersContext;
  const { push } = useHistory();
  const [activeSearchQuery, setActiveSearchQuery] = useState(null);

  const [isDeleteDraftModalOpen, setIsDeleteDraftModalOpen] = useState(false);
  const [isSearchResultsEmpty, setIsSearchResultsEmpty] = useState(false);
  const [deleteDraftPlanId, setDeleteDraftPlanId] = useState(undefined);

  const [deactivatePlanId, setDeactivatePlanId] = useState(undefined);
  const [isTablePendingReload, setIsTablePendingReload] = useState(false);
  const [isDeactivatePlanModalOpen, setIsDeactivatePlanModalOpen] = useState(false);
  const [isDeactivatePlanLoading, setIsDeactivatePlanLoading] = useState(false);

  const [data, setData] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [pageIndex, setPageIndex] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const fetchIdRef = useRef(0);
  const { isLoading } = globalComponentsContext;
  const [isFirstMount, setIsFirstMount] = useState(true);

  const handleCloseDeleteDraftModal = () => {
    setDeleteDraftPlanId(undefined);
    setIsDeleteDraftModalOpen(false);
  };
  const handleCloseDeactivatePlanModal = () => {
    setDeactivatePlanId(undefined);
    setIsDeactivatePlanModalOpen(false);
  };

  const handleDeactivatePlan = id => {
    setDeactivatePlanId(id);
    setIsDeactivatePlanModalOpen(true);
  };
  const deactivatePlan = async () => {
    setIsDeactivatePlanLoading(true);

    try {
      const { data } = await GeneralPlansService.deactivatePlan({
        planId: deactivatePlanId,
        companyId: headerFilters?.companies?.id,
      });

      if (data === 'Plano atualizado com sucesso.') {
        createDarkSuccessToast(setToastList, 'O plano foi inativado.');
      } else {
        throw new Error('Unexpected backend status');
      }
      setIsTablePendingReload(true);
    } catch (e) {
      createGenericErrorToast(setToastList);
    } finally {
      setIsDeactivatePlanLoading(false);
      handleCloseDeactivatePlanModal();
    }
  };

  const deleteDraft = async () => {
    try {
      const { data } = await GeneralPlansService.deletePlan({
        planId: deleteDraftPlanId,
        companyId: headerFilters?.companies?.id,
      });

      if (data === 'Plano excluído com sucesso.') {
        createTrashToast(setToastList, 'Rascunho descartado.');
      } else {
        throw new Error('Unexpected backend status');
      }
      setIsTablePendingReload(true);
    } catch (e) {
      console.error(e);
      createGenericErrorToast(setToastList);
    } finally {
      handleCloseDeleteDraftModal();
    }
  };
  const handleDeleteDraft = planId => {
    setDeleteDraftPlanId(planId);
    setIsDeleteDraftModalOpen(true);
  };

  const columns = useMemo(
    () => [
      {
        Header: 'Plano',
        accessor: 'nome', // accessor is the "key" in the data
      },
      {
        Header: 'Atividades',
        accessor: 'qtdAtividades',
      },
      {
        Header: 'Unidades',
        accessor: 'qtdSites',
      },
      {
        Header: 'Aplicação',
        accessor: 'application',
        disableSortBy: true,
        Cell: props => <GeneralPlanApplicationCell {...props} />,
      },
      {
        Header: 'Status',
        accessor: 'statusPlanoGeral',
        id: '1',
        Cell: props => <GeneralPlanStatusCell {...props} />,
      },
      {
        Header: 'Última atualização',
        accessor: 'ultimaModificacao',
        isSorted: true,
        isSortedDesc: true,
        Cell: props => <GeneralPlanLastUpdateCell {...props} />,
      },
      {
        Header: 'Ação',
        accessor: 'planoGeral',

        disableSortBy: true,
        Cell: props => (
          <EditGeneralPlanButton
            planId={props.cell.value}
            companyId={headerFilters?.companies?.id}
            handleDeleteDraft={handleDeleteDraft}
            handleDeactivatePlan={handleDeactivatePlan}
            setToastList={setToastList}
          />
        ),
      },
    ],
    [headerFilters?.companies?.id],
  );

  const isPlansEmpty = useMemo(() => {
    return data.length === 0;
  }, [data]);

  const [toastList, setToastList] = useState([]);

  const fetchPlansTableData = useCallback(
    async ({ pageSize, sortBy }) => {
      // Give this fetch an ID
      const fetchId = ++fetchIdRef.current;
      let params = {
        ...(activeSearchQuery && { Nome: activeSearchQuery }),
        PageIndex: pageIndex + 1,
        PageSize: pageSize,
      };
      let headers;
      if (headerFilters?.companies?.id) {
        headers = {
          EmpresaId: headerFilters?.companies?.id,
        };
      } else {
        headers = undefined;
      }

      if (sortBy[0]?.id) {
        params = {
          ...params,
          'OrderBy.Column': sortBy[0].id === '1' ? 'Status' : sortBy[0].id,
          'OrderBy.Desc': sortBy[0].desc,
        };
      } else {
        params = {
          ...params,
          'OrderBy.Column': 'ultimaModificacao',
          'OrderBy.Desc': true,
        };
      }

      // Only update the data if this is the latest fetch
      if (fetchId === fetchIdRef.current) {
        if (isFirstMount) {
          setGlobalComponentsContext(prevState => ({
            ...prevState,
            isLoading: true,
          }));
          setIsFirstMount(false);
        }
        await Promise.all([
          AxiosGlobalFilters({
            method: 'get',
            url: `/v1/planosgerais`,
            headers: headers,
            params: params,
          }),
          AxiosGlobalFilters({
            method: 'get',
            url: `/v1/planosgerais/count`,
            headers: headers,
          }),
        ])
          .then(res => {
            const data = res[0].data;
            if (activeSearchQuery && data.length === 0) {
              setIsSearchResultsEmpty(true);
            } else {
              setIsSearchResultsEmpty(false);
            }
            const adaptedData = data.map(plan => {
              if (plan.aplicacaoTipoArea) {
                return {
                  ...plan,
                  application: {
                    type: `Áreas`,
                    subGroup: plan.aplicacaoTipoArea,
                  },
                };
              } else if (plan.aplicacaoTipoEquipamento) {
                return {
                  ...plan,
                  application: {
                    type: `Equipamentos`,
                    subGroup: plan.aplicacaoTipoEquipamento,
                  },
                };
              } else {
                return {
                  ...plan,
                  application: {
                    type: `Unidade de manutenção`,
                    planId: plan.planoGeral,
                    companyId: plan.empresa,
                    // subGroup:
                    //   plan.qtdAppSites === 1
                    //     ? "Test description"
                    //     : `${plan.qtdAppSites} Equipamentos`
                  },
                };
              }
            });

            setData(adaptedData);
            setPageCount(Math.ceil(res[1].data / pageSize));

            setTotalCount(res[1].data);
          })
          .catch(() => {
            setToastList(prevState => [
              ...prevState,
              createToast({
                type: 'error',
                message: 'Ocorreu um erro ao carregar os planos. Tente novamente.',
              }),
            ]);
          })
          .finally(() => {
            if (isLoading) {
              setGlobalComponentsContext(prevState => ({
                ...prevState,
                isLoading: false,
              }));
            }
          });
      }
    },
    [activeSearchQuery, headerFilters?.companies?.id, isFirstMount, isLoading, pageIndex, setGlobalComponentsContext],
  );
  const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);
  return (
    <>
      {isLoading && <Loading />}

      <PageWrapper background={colors.pampas}>
        <MobileNavbar />
        {/* <Menu /> */}
        <NewMenu setIsSubMenuOpen={setIsSubMenuOpen} />
        <HeaderFiltersPage location={pathname} isSubMenuOpen={isSubMenuOpen}>
          <PageHeader title="Planos Gerais" />

          <StyledTableContainer>
            <StyledTabHeader header="Planos">
              {!isPlansEmpty && (
                <StyledAddPlanButtonWrapper to="/atividades/planos-gerais/novo">
                  <StyledAddPlanButton size="small">
                    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M1 6H11" stroke={colors.white} strokeWidth="2" strokeLinecap="round" />
                      <path d="M6 1V11" stroke={colors.white} strokeWidth="2" strokeLinecap="round" />
                    </svg>
                    Novo plano geral
                  </StyledAddPlanButton>
                </StyledAddPlanButtonWrapper>
              )}
            </StyledTabHeader>
            <StyledTableInnerContainer>
              {!isLoading && isPlansEmpty && !isSearchResultsEmpty && (
                <StyledEmptyPlansContainer>
                  <svg width="80" height="88" viewBox="0 0 80 88" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M1.75436e-05 30.1139V57.8454C-0.00416577 60.7046 0.739864 63.5143 2.15712 65.9914C3.57437 68.4686 5.61477 70.5256 8.07267 71.9552L31.9273 85.8209C34.3817 87.2485 37.1659 88 40 88C42.8341 88 45.6183 87.2485 48.0727 85.8209L71.9273 71.9552C74.3852 70.5256 76.4256 68.4686 77.8429 65.9914C79.2601 63.5143 80.0042 60.7046 80 57.8454V30.1139C79.9971 27.2617 79.2497 24.4603 77.8328 21.9909C76.4159 19.5216 74.3793 17.471 71.9273 16.0448L48.0727 2.17907C45.6183 0.751537 42.8341 0 40 0C37.1659 0 34.3817 0.751537 31.9273 2.17907L8.07267 16.2481C5.65006 17.6568 3.63231 19.6755 2.21715 22.1062C0.802002 24.5368 0.0379999 27.2962 1.75436e-05 30.1139Z"
                      fill="#EAE8E5"
                    />
                    <path
                      d="M61.7241 50.0833C62.4885 48.75 61.5712 47 60.1188 47H29.619C27.7844 47 26.1792 48.0833 25.3383 49.6667L19.8346 60C19.0702 61.3333 19.9875 63 21.4398 63H51.9397C53.7743 63 55.3795 62 56.2204 60.4167L61.7241 50.0833ZM29.619 44.3333H54.6915V40.3333C54.6915 38.1667 53.0098 36.3333 51.0224 36.3333H38.7919L33.8997 31H21.6692C19.6053 31 18 32.8333 18 35V58.25L23.2744 48.3333C24.5739 45.9167 26.9436 44.3333 29.619 44.3333Z"
                      fill="#AAAAAA"
                    />
                    <rect x="49" y="18" width="3" height="8" rx="1.5" fill="#AAAAAA" />
                    <rect x="66" y="32" width="3" height="8" rx="1.5" transform="rotate(90 66 32)" fill="#AAAAAA" />
                    <rect
                      x="60.6567"
                      y="21"
                      width="3"
                      height="8"
                      rx="1.5"
                      transform="rotate(45 60.6567 21)"
                      fill="#AAAAAA"
                    />
                  </svg>

                  <StyledEmptyPlansHeader>Não tem nada por aqui. Vamos criar algo?</StyledEmptyPlansHeader>
                  <StyledEmptyPlansAddPlanButton onClick={() => push(`/atividades/planos-gerais/novo`)}>
                    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M1 6H11" stroke={colors.white} strokeWidth="2" strokeLinecap="round" />
                      <path d="M6 1V11" stroke={colors.white} strokeWidth="2" strokeLinecap="round" />
                    </svg>
                    Novo plano geral
                  </StyledEmptyPlansAddPlanButton>
                </StyledEmptyPlansContainer>
              )}
              <GeneralPlansTable
                isTablePendingReload={isTablePendingReload}
                setIsTablePendingReload={setIsTablePendingReload}
                columns={columns}
                data={data}
                setToastList={setToastList}
                fetchData={fetchPlansTableData}
                isSearchResultsEmpty={isSearchResultsEmpty}
                activeSearchQuery={activeSearchQuery}
                setActiveSearchQuery={setActiveSearchQuery}
                totalCount={totalCount}
                pageIndex={pageIndex}
                setPageIndex={setPageIndex}
                pageCount={pageCount}
              />
            </StyledTableInnerContainer>
          </StyledTableContainer>
        </HeaderFiltersPage>
      </PageWrapper>
      <Toast toastList={toastList} autoDelete dismissTime={7000} />
      <ModalGeneric
        size={{ width: '362px' }}
        open={isDeactivatePlanModalOpen}
        isLoading={isDeactivatePlanLoading}
        loadingMessage={'Desativando Plano ...'}
        hasHeader
        icon={<ToastWarningIcon />}
        title="Inativar plano?"
        closeModal={() => handleCloseDeactivatePlanModal()}
      >
        <StyledModalParagraph>As atividades não baixadas serão excluídas.</StyledModalParagraph>
        <StyledModalButtons>
          <StyledModalCancelButton theme="outline" type="button" onClick={() => handleCloseDeactivatePlanModal()}>
            Cancelar
          </StyledModalCancelButton>
          <StyledModalDeactivatePlanButton type="button" onClick={() => deactivatePlan()}>
            Inativar plano
          </StyledModalDeactivatePlanButton>
        </StyledModalButtons>
      </ModalGeneric>
      <ModalGeneric open={isDeleteDraftModalOpen} closeModal={() => handleCloseDeleteDraftModal()}>
        <StyledModalBody>
          <svg width="80" height="88" viewBox="0 0 80 88" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M1.75436e-05 30.1139V57.8454C-0.00416577 60.7046 0.739864 63.5143 2.15712 65.9914C3.57437 68.4686 5.61477 70.5256 8.07267 71.9552L31.9273 85.8209C34.3817 87.2485 37.1659 88 40 88C42.8341 88 45.6183 87.2485 48.0727 85.8209L71.9273 71.9552C74.3852 70.5256 76.4256 68.4686 77.8429 65.9914C79.2601 63.5143 80.0042 60.7046 80 57.8454V30.1139C79.9971 27.2617 79.2497 24.4603 77.8328 21.9909C76.4159 19.5216 74.3793 17.471 71.9273 16.0448L48.0727 2.17907C45.6183 0.751537 42.8341 0 40 0C37.1659 0 34.3817 0.751537 31.9273 2.17907L8.07267 16.2481C5.65006 17.6568 3.63231 19.6755 2.21715 22.1062C0.802002 24.5368 0.0379999 27.2962 1.75436e-05 30.1139Z"
              fill="#FFDBD6"
            />
            <path
              d="M54.8571 26.5H46.2857L45.5714 25.0938C45.2857 24.4688 44.6429 24 44.0714 24H35.8571C35.2857 24 34.5714 24.4688 34.3571 25.0938L33.7143 26.5H25.1429C24.5 26.5 24 27.125 24 27.75V30.25C24 30.9531 24.5 31.5 25.1429 31.5H54.8571C55.4286 31.5 56 30.9531 56 30.25V27.75C56 27.125 55.4286 26.5 54.8571 26.5ZM27.7857 60.4844C27.8571 62.4375 29.4286 64 31.2143 64H48.7143C50.5 64 52.0714 62.4375 52.1429 60.4844L53.7143 34H26.2857L27.7857 60.4844Z"
              fill="#EC6655"
            />
            <rect x="34" y="42" width="2" height="14" fill="#FFDBD6" />
            <rect x="39" y="42" width="2" height="14" fill="#FFDBD6" />
            <rect x="44" y="42" width="2" height="14" fill="#FFDBD6" />
          </svg>

          <h6>Descartar rascunho?</h6>
          <StyledDeleteDraftModalParagraph>
            Não será possível recuperar o rascunho depois.
          </StyledDeleteDraftModalParagraph>
          <StyledModalButtons>
            <StyledModalCancelButton type="button" onClick={() => handleCloseDeleteDraftModal()}>
              Cancelar
            </StyledModalCancelButton>
            <StyledModalDeleteDraftButton type="button" onClick={() => deleteDraft()}>
              Descartar
            </StyledModalDeleteDraftButton>
          </StyledModalButtons>
        </StyledModalBody>
      </ModalGeneric>
    </>
  );
};

export { ViewGeneralPlansPage };
