const { css } = require('styled-components');

const small = () => css`
  font-size: 12px;
  padding: 5px 8px;
  svg {
    width: 12px;
    height: 12px;
  }
  line-height: 14px;
  border: 1px solid invisible;
  border-radius: 3px;
`;

export { small };
