import React from 'react';
import PropTypes from 'prop-types';

import { StyledInputTooltip } from './styled';

function InputTooltip(props) {
  const { isVisible, message, radio } = props;

  return (
    <StyledInputTooltip isVisible={isVisible} radio={radio}>
      <span>{message}</span>
    </StyledInputTooltip>
  );
}

InputTooltip.propTypes = {
  isVisible: PropTypes.bool,
  message: PropTypes.string.isRequired,
};

InputTooltip.defaultProps = {
  isVisible: false,
  message: '',
};

export default InputTooltip;
