import React from 'react';
import { StyledEditGeneralPlanActivityButtonContainer, StyledOption, StyledOptionsContainer } from './styled';

import { ReactComponent as TripleCircle } from 'assets/icons/triple-circle.svg';

function EditGeneralPlanActivityButton(props) {
  const activityId = props.cell.value;
  const handleAction = action => {
    if (action === 'edit') {
      props.setEditActivityId(activityId);
    } else if (action === 'delete') {
      props.handleRemoveActivity(activityId);
    }
  };
  return (
    <StyledEditGeneralPlanActivityButtonContainer>
      <TripleCircle />
      <StyledOptionsContainer
        defaultValue="none"
        onChange={e => {
          e.persist();
          handleAction(e.target.value);
        }}
      >
        <StyledOption style={{ display: 'none' }} value="none"></StyledOption>
        <StyledOption value="edit">Editar</StyledOption>
        <StyledOption value="delete">Excluir</StyledOption>
      </StyledOptionsContainer>
    </StyledEditGeneralPlanActivityButtonContainer>
  );
}

export default EditGeneralPlanActivityButton;
