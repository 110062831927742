import * as React from 'react';

import { HeaderFiltersType } from 'context/GlobalFiltersContext/initialState';
import { LkpChart } from '../Chart';
import { useBoolean, useDebounce } from 'hooks';
import { ChartSeriesType, ChartsParamsType } from '../types';
import { LkpChartTopFilter } from '../Filters';
import { fetcher } from 'services';
import { ChartComparisonBetweenWeeksType } from './types';
import { isNumber } from 'fp-ts/lib/number';
import { getChartOptions } from './options';
import { getErrorMessageByStatusCode, groupBy } from 'utils';
import { useParams } from 'react-router-dom';

export interface LkpChartComparisonBetweenWeeksProps {
  headerFilters: HeaderFiltersType;
}

const HTTP_PATH = '/atividades-por-status-por-periodo';

const DEFAULT_SERIES = [
  { name: 'Programadas', data: [] },
  { name: 'Realizado', data: [] },
  { name: 'Não Realizado', data: [] },
  { name: 'Baixado Automaticamente', data: [] },
  { name: 'Aguardando Aprovação', data: [] },
];

export function LkpChartComparisonBetweenWeeks({
  headerFilters,
}: LkpChartComparisonBetweenWeeksProps): React.ReactElement {
  const { value: isLoading, setValue: setLoading } = useBoolean();
  const { value: isError, setValue: setIsError } = useBoolean();
  const [errorMessage, setErrorMessage] = React.useState<string>();
  const { dateFrom, dateTo } = useParams<{ dateFrom: string; dateTo: string }>();

  const [series, setSeries] = React.useState<Array<ChartSeriesType>>(DEFAULT_SERIES);
  const [periods, setPeriods] = React.useState<Array<string>>([]);
  const [params, setParams] = React.useState<ChartsParamsType>({
    Empresa: headerFilters?.companies?.id || 0,
    Sites: headerFilters?.units?.id,
    Inicio: dateFrom,
    Fim: dateTo,
  });

  const debouncedStartValue = useDebounce<string>(params?.Inicio || '', 500);
  const debouncedEnvValue = useDebounce<string>(params?.Fim || '', 500);

  React.useEffect(() => {
    (async () => {
      await fetchChartData();
    })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedStartValue, debouncedEnvValue]);

  const fetchChartData = async () => {
    setLoading(true);

    const result = await fetcher
      .get<Array<ChartComparisonBetweenWeeksType>>(HTTP_PATH, 'dashboard', { params })
      .catch(error => {
        const message = getErrorMessageByStatusCode(error, HTTP_PATH);
        setErrorMessage(message);
        setIsError(true);
      });

    if (result) {
      const _groupStatus = groupBy<ChartComparisonBetweenWeeksType>(result, d => d.status);
      const _series = _groupStatus.map(({ name, data }) => {
        const _qttData: number[] = [];
        data.forEach(({ quantidade }) => {
          const _num = isNumber(Number(quantidade)) ? Number(quantidade) : 0;
          _qttData.push(_num);
        });
        return { name, data: _qttData };
      });
      setSeries(_series);

      const _groupPeriods = groupBy<ChartComparisonBetweenWeeksType>(result, d => d.periodo);
      setPeriods(_groupPeriods.map(({ name }) => name));
    }

    setLoading(false);
  };

  const handleChangeStartDate = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value: Inicio } = event.target;
    setParams({ ...params, Inicio });
  };

  const handleChangeEndDate = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value: Fim } = event.target;
    setParams({ ...params, Fim });
  };

  return (
    <LkpChart
      title="Atividades - Comparativo entre semanas"
      companyId={headerFilters?.companies?.id || 0}
      loading={isLoading}
      error={isError}
      errorMessage={errorMessage}
      series={series}
      //width={1700}
      onChartTryAgain={fetchChartData}
      options={getChartOptions(periods, {
        legend: {
          position: 'bottom',
        },
        colors: ['#00C0C7', '#5144D3', '#E8871A', '#DA3490', '#9089FA', '#47E26F', '#2780EB'],
        dataLabels: {
          enabled: true,
          formatter: function () {
            return ``;
          },
        },
      })}
      filter={<LkpChartTopFilter onChangeStartDate={handleChangeStartDate} onChangeEndDate={handleChangeEndDate} />}
    />
  );
}
