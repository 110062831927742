const companies = 'companies';
const unitGroups = 'unitGroups';
const unitSubgroups = 'unitSubgroups';
const units = 'units';
const states = 'states';
const cities = 'cities';
const teams = 'teams';
const providers = 'providers';

const filtersToUnmark = {
  companies: [companies, unitGroups, unitSubgroups, units, states, cities, teams, providers],
  unitGroups: [unitGroups, unitSubgroups, units],
  unitSubgroups: [unitSubgroups, units],
  units: [units],
  states: [states, cities, units],
  cities: [cities, units],
  teams: [teams],
  providers: [providers],
};

export { companies, unitGroups, unitSubgroups, units, states, cities, teams, providers, filtersToUnmark };
