import * as React from 'react';

import { HeaderFiltersType } from 'context/GlobalFiltersContext/initialState';
import { LkpChart } from '../Chart';
import { useBoolean, useDebounce } from 'hooks';
import { fetcher } from 'services';
import { ChartComparativeSystemBetweenMonthsType } from './types';
import { ChartSeriesType, ChartsParamsType, ChartUpdate, UpdateParamsType } from '../types';
import { getErrorMessageByStatusCode, groupBy, minutesToHours } from 'utils';
import { LkpChartTopFilter } from '../Filters';
import { isNumber } from 'fp-ts/lib/number';
import { useParams } from 'react-router-dom';
import { createToast } from '../createToast';
import { getOnlyHoursYaxis } from 'utils';
import { COMPARATIVE_WEEKS_OPTIONS } from './options';

export interface LkpChartComparativeSystemBetweenMonthsProps {
  headerFilters: HeaderFiltersType;
  setToastList: any;
}

const HTTP_PATH = '/horas-por-periodo-por-sistema';

export function LkpChartComparativeSystemBetweenMonths({
  headerFilters,
  setToastList,
}: LkpChartComparativeSystemBetweenMonthsProps): React.ReactElement {
  const { value: isLoading, setValue: setLoading } = useBoolean();
  const { value: isError, setValue: setIsError } = useBoolean();
  const [errorMessage, setErrorMessage] = React.useState<string>();
  const { dateFrom, dateTo } = useParams<{ dateFrom: string; dateTo: string }>();
  const [lastUpdateMessage, setLastUpdateMessage] = React.useState();
  const [series, setSeries] = React.useState<Array<ChartSeriesType>>([]);
  const [period, setPeriods] = React.useState<Array<string>>([]);
  const [paramsSystems, setParamsSystems] = React.useState<string>('');
  const [params, setParams] = React.useState<ChartsParamsType>({
    Empresa: headerFilters?.companies?.id || 0,
    Sites: headerFilters?.units?.id,
    Inicio: dateFrom,
    Fim: dateTo,
  });
  const [updateParamns, setUpdateParamns] = React.useState<UpdateParamsType>({
    Empresa: headerFilters?.companies?.id || 0,
    Inicio: dateFrom,
    Fim: dateTo,
  });
  const debouncedStartValue = useDebounce<string>(params?.Inicio || '', 500);
  const debouncedEnvValue = useDebounce<string>(params?.Fim || '', 500);
  const debouncedParamsSystemsValue = useDebounce<string>(paramsSystems || '', 500);

  React.useEffect(() => {
    (async () => {
      await fetchChartData();
      await LastUpdate();
    })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedStartValue, debouncedEnvValue, debouncedParamsSystemsValue]);
  const LastUpdate = async () => {
    const _httpPath2 = `/ultima-atualizacao-dm-horas-tecnicas-por-sistema?Empresa=${updateParamns.Empresa}&Inicio=${updateParamns.Inicio}&Fim=${updateParamns.Fim}`;
    const result = await fetcher.get(_httpPath2, 'dashboard').catch(err => {
      const message = getErrorMessageByStatusCode(err, _httpPath2);
      setErrorMessage(message);
      setIsError(true);
    });
    setLastUpdateMessage(result);
    const newToast = createToast({
      type: 'updating',
      message: 'Atualizando dados. Aguarde alguns minutos e recarregue a página para atualizar o gráfico.',
    });
    setToastList(prevState => [...prevState, newToast]);
  };
  const UpdateChart = async () => {
    const _httpPath1 = `/atualizar-dm-horas-tecnicas-por-sistema?Empresa=${updateParamns.Empresa}&Inicio=${updateParamns.Inicio}&Fim=${updateParamns.Fim}`;
    await fetcher.get<ChartUpdate>(_httpPath1, 'dashboard').catch(err => {
      const message = getErrorMessageByStatusCode(err, _httpPath1);
      setErrorMessage(message);
      setIsError(true);
    });
    await LastUpdate();
  };
  const fetchChartData = async () => {
    setLoading(true);
    let _httpPath = HTTP_PATH;
    if (!!paramsSystems) _httpPath = `${_httpPath}?${paramsSystems}`;

    const result = await fetcher
      .get<Array<ChartComparativeSystemBetweenMonthsType>>(_httpPath, 'dashboard', {
        params,
      })
      .catch(error => {
        const message = getErrorMessageByStatusCode(error, _httpPath);
        setErrorMessage(message);
        setIsError(true);
      });

    if (result) {
      const _groupSistema = groupBy<ChartComparativeSystemBetweenMonthsType>(result, d => d.sistema);
      const _series = _groupSistema.map(({ name, data }) => {
        const _hours: number[] = [];
        data.forEach(({ horas }) => {
          const _num = isNumber(Number(horas)) ? Number(horas) : 0;
          _hours.push(_num);
        });
        return { name, data: _hours };
      });
      setSeries(_series);

      const _groupPeriods = groupBy<ChartComparativeSystemBetweenMonthsType>(result, d => d.periodo);
      setPeriods(_groupPeriods.map(({ name }) => name));
    }

    setLoading(false);
  };

  const handleSelectSystems = (selectedSystems: string) => {
    setParamsSystems(selectedSystems);
  };

  const handleChangeStartDate = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value: Inicio } = event.target;
    setParams({ ...params, Inicio });
    setUpdateParamns({ ...params, Inicio });
  };

  const handleChangeEndDate = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value: Fim } = event.target;
    setParams({ ...params, Fim });
    setUpdateParamns({ ...params, Fim });
  };

  return (
    <React.Fragment>
      <LkpChart
        title="Horas Técnicas Investidas por sistema comparativo entre meses"
        companyId={headerFilters?.companies?.id || 0}
        series={series}
        error={isError}
        errorMessage={errorMessage}
        onChartTryAgain={fetchChartData}
        chartUpdate={UpdateChart}
        lastUpdateMessage={lastUpdateMessage}
        lastUpdate={LastUpdate}
        //setParams={setParams}
        isTecHour={true}
        loading={isLoading}
        //width={1700}
        options={{
          chart: {
            type: 'bar',
            height: 430,
            toolbar: {
              show: false,
            },
          },
          xaxis: { categories: period },
          yaxis: {
            ...COMPARATIVE_WEEKS_OPTIONS.yaxis,

            labels: {
              formatter: function (val) {
                return `${getOnlyHoursYaxis(val)}h`;
              },
            },
          },
          legend: {
            position: 'right',
          },
          colors: [
            '#00C0C7',
            '#5144D3',
            '#E8871A',
            '#DA3490',
            '#9089FA',
            '#47E26F',
            '#2780EB',
            '#6F38B1',
            '#DFBF03',
            '#CB6F10',
            '#268D6C',
            '#9BEC54',
            '#3366CC',
            '#DC3912',
            '#FF9900',
            '#109618',
            '#990099',
            '#0099C6',
            '#DD4477',
            '#66AA00',
            '#B82E2E',
            '#316395',
            '#994499',
            '#22AA99',
            '#AAAA11',
            '#6633CC',
            '#E67300',
            '#8B0707',
            '#CCCCCC',
            '#651067',
            '#329262',
          ],
          dataLabels: {
            enabled: true,
            formatter: function () {
              return ``;
            },
          },
          tooltip: {
            y: {
              formatter: function (value) {
                return minutesToHours(+value);
              },
            },
          },
        }}
        filter={
          <LkpChartTopFilter
            onChangeStartDate={handleChangeStartDate}
            onChangeEndDate={handleChangeEndDate}
            onSelectSystems={handleSelectSystems}
          />
        }
      />
    </React.Fragment>
  );
}
