import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { StyledChartTooltip } from './styled';

function ChartTooltip(props) {
  const { isVisible, message, chart } = props;
  const [isHovering, setIsHovering] = useState(false);
  const handleMouseOver = () => {
    setIsHovering(true);
  };
  const handleMouseOut = () => {
    setIsHovering(false);
  };
  const handleTooltip = () => {
    if (chart) {
      return isHovering;
    } else {
      return isVisible;
    }
  };
  return (
    <StyledChartTooltip
      onMouseOver={handleMouseOver}
      onMouseOut={handleMouseOut}
      isVisible={handleTooltip}
      chart={chart}
    >
      <span>{message}</span>
    </StyledChartTooltip>
  );
}

ChartTooltip.propTypes = {
  isVisible: PropTypes.bool,
  message: PropTypes.string.isRequired,
};

ChartTooltip.defaultProps = {
  isVisible: false,
  message: '',
  chart: false,
};

export default ChartTooltip;
