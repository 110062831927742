import styled from 'styled-components';
import { fonts, colors, medias } from 'assets/styled/tokens';

import { devices } from 'assets/styled/tokens/devices';

export const StyledLogin = styled.div`
  width: 100%;
  height: 100vh;
  background: ${colors.pampas};
  display: flex;
`;

export const StyledLoginContainer = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  overflow: auto;
  @media screen and (max-width: ${medias.tablet}) {
    flex-direction: column;
  }
`;

export const StyledLoginIntro = styled.div`
  display: none;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  background-color: ${colors.carbon};

  @media ${devices.tabletLandscape} {
    display: flex;
    width: 50.7vw;
  }
`;

const CarouselImage = styled.svg`
  width: 70%;
  height: 70%;
  @media ${devices.screenWide} {
    width: 100%;
    height: 100%;
  }
`;

export const CarouselSlide1TopImage = styled(CarouselImage)`
  transform: translateY(-30px);
  @media ${devices.screenWide} {
    transform: translateY(-60px);
  }
`;
export const CarouselSlide1BottomImage = styled(CarouselImage)`
  transform: translateX(127px);

  @media ${devices.screenWide} {
    transform: translateX(127px);
  }
`;

export const StyledLoginWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100%;
  justify-content: space-between;
  overflow: hidden;
`;

export const StyledForm = styled.form`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  max-width: 410px;
  height: 100%;
  margin: 0 auto;

  img {
    width: 201px;
    margin: 0 auto 3.12rem 0;
  }

  button {
    width: 100%;
    transition: all 0.4s ease-in-out;

    &:hover {
      color: ${colors.tundora};
      background-color: ${colors.caramel};
    }
  }
`;

export const StyledLoginMain = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 5;
  max-width: 75%;
  align-self: center;
  h2 {
    width: 320px;
    margin-bottom: 25px;
    font-family: ${fonts.family.title};
    font-weight: 600;
    font-size: 27px;
    line-height: 32px;
    color: ${colors.white};
  }
  svg {
    width: 278px;
    margin-bottom: 31px;
  }
  path {
    fill: ${colors.white};
  }
  @media ${devices.screen} {
    max-width: 100%;
  }
`;

export const StyledLoginCard = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

export const StyledLoginContent = styled.div`
  position: relative;
  background-color: ${colors.primary};
  border-radius: 15px;
  width: 100%;
  max-width: 460px;
  display: flex;
  flex-direction: row;
  box-shadow: -14px 10px 17px -4px rgba(0, 0, 0, 0.31);

  div {
    max-width: 55%;
    padding-top: 25px;
    padding-left: 30px;
    padding-bottom: 25px;

    h6 {
      margin-top: 0;
      margin-bottom: 16px;
      font-family: ${fonts.family.title};
      font-size: 16px;
      color: ${colors.white};
      text-align: start;
    }

    p {
      font-family: ${fonts.family.text};
      font-size: 16px;
      font-weight: 400;
      color: ${colors.white};
    }
  }

  img {
    margin-left: 10px;
    position: absolute;
    right: -15px;
    bottom: 0;
    height: auto;
    width: calc(100% - 162px);
    max-width: 240px;
  }

  @media screen and (min-width: ${medias.desktopMd}) {
    width: 90%;
  }
`;

export const StyledHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding-top: 27px;
  padding-right: 32px;

  span {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: ${colors.gray};

    a {
      color: ${colors.primary};
      border: 1px solid ${colors.primary};
      box-sizing: border-box;
      border-radius: 3px;
      padding: 7px 12px;
      margin-left: 5px;

      :hover {
        text-decoration: none;
      }
    }
  }

  @media screen and (max-width: ${medias.mobile}) {
    padding-top: 21px;
    padding-right: 18px;
  }
`;

export const StyledFooter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0 24px;
  margin-bottom: 15px;
  h6 {
    font-size: 12px;
    line-height: 14px;
    font-weight: normal;
    letter-spacing: 0px;
    color: ${colors.gray};
  }

  span {
    display: none;
    font-size: 14px;
    color: ${colors.gray};
    line-height: 16.4px;
    font-weight: normal;

    a {
      color: ${colors.primary};
      font-weight: bold;

      :hover {
        text-decoration: none;
      }
    }

    @media screen and (min-width: ${medias.mobile}) {
      display: initial;
    }
  }
  @media ${devices.screen} {
    margin-bottom: 15px;
    position: static;
  }
`;
