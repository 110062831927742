import styled from 'styled-components';

export const MultiCheckboxContainer = styled.div`
  background: #ffffff;
  border: 1px solid #e5e5e5;
  border-radius: 4px;
  display: grid;
`;
export const CheckboxRow = styled.div`
  //padding: 10px 0 10px 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  //pointer-events: auto;
  cursor: pointer;
  :nth-child(1),
  :nth-child(2),
  :nth-child(3),
  :nth-child(4),
  :nth-child(5) {
    border-bottom: 1px solid #e5e5e5;
    height: 32px;
  }
  :last-child {
    height: 32px;
  }
  /* :hover {
    cursor: pointer;
  } */
`;
