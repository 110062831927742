/* istanbul ignore file */

import styled from 'styled-components';
import { colors } from 'assets/styled/tokens';

export const FilterTabsHeader = styled.nav`
  display: inline-flex;
  position: absolute;
  left: 0;
  z-index: 2;
  width: ${({ isCollapsed }) => (isCollapsed ? `auto` : `400px`)};
  border-bottom: 1px solid ${colors.lightGray};
  border-radius: ${({ isCollapsed }) => (isCollapsed ? `8px` : `0`)};
  border-bottom-width: ${({ isCollapsed }) => (isCollapsed ? `0` : `1px`)};
`;
export const FilterTabsContainer = styled.div`
  display: flex;

  box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.2);
  background: white;
  border-radius: 0 0 8px 0;
`;
export const FilterTab = styled.button`
  padding: 0;
  width: 133px;
  height: 40px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-family: Blinker;
  font-weight: 600;
  font-size: 18px;
  border-bottom: ${({ isActive }) => (isActive ? '2px solid black' : '1px solid white')};
  margin-bottom: -1px;
  line-height: 19px;
  color: ${({ isActive, isFaded }) => (isFaded && !isActive ? colors.mediumGray : colors.carbon)};
  &:not(:last-child) {
    border-right: 1px solid ${colors.whiteGray};
  }
  &:last-child {
    border-bottom-right-radius: ${({ isActive }) => (isActive ? '0' : '8px')};
  }
`;
