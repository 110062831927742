import React from 'react';
// import React, { useEffect, useContext } from "react";
import { initialState, reducer } from './reducer';
// import { FiltersContext } from "context/FiltersContext";
// import { HeaderFilterService } from "services/main";

const GeolocalizeStore = React.createContext([[], () => {}]);
GeolocalizeStore.displayName = 'GeolocalizeStore';

export const useGeolocalizeStore = () => React.useContext(GeolocalizeStore);

export const GeolocalizeStoreProvider = ({ children }) => {
  const [state, dispatch] = React.useReducer(reducer, initialState);
  // const { headerFiltersContext } = useContext(FiltersContext);
  // const { headerFilters } = headerFiltersContext;

  // const companyId = headerFilters?.companies?.id;
  // useEffect(() => {
  //   if(state.unitMarkersFilters.unitId) {

  //     HeaderFilterService.unit(companyId, state.unitMarkersFilters.unitId)
  //   }

  // }, [])
  return <GeolocalizeStore.Provider value={[state, dispatch]}>{children}</GeolocalizeStore.Provider>;
};
