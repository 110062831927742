import { format, sub } from 'date-fns';
import jwt from 'jwt-decode';
import { getLocalAuthStorage } from 'utils/localStorage';

const { user, token } = getLocalAuthStorage();

const ENABLED_COMPANIES = [0, 4969];

const getUserCompanyId = () => {
  if (token) {
    const { comps } = jwt(token);

    if (comps) {
      const [company] = JSON.parse(comps);

      return company?.Id;
    }
  }

  return 0;
};
const getUserCompanyIdForDash = token => {
  if (token) {
    const { comps } = jwt(token);
    if (comps) {
      const [company] = JSON.parse(comps);
      return company?.Id;
    }
  }
  return 0;
};

const chooseDash = token => {
  let companyId = getUserCompanyIdForDash(token);
  if (companyId === 4969) {
    return `/dashboard/${startDate}/${endDate}`;
  } else {
    return `/dashboard-geral/${startDate}/${endDate}`;
  }
};
//const isCommonUser = user?.permissionType === `user`;
export const endDate = format(new Date(), 'yyyy-MM-dd'); // today's date
export const startDate = format(sub(new Date(), { months: 1 }), 'yyyy-MM-dd');
const companyId = getUserCompanyId();
const pages = {
  first: [
    {
      label: 'Home',
      //link: `${process.env.REACT_APP_LEGADO}/Empresas/Home.aspx`,
      link: '/home',
      //isExternal: true,
    },
    {
      label: 'Dashboard',
      link: companyId === 4969 ? `/dashboard/${startDate}/${endDate}` : `/dashboard-geral/${startDate}/${endDate}`,
      disabled: !ENABLED_COMPANIES.includes(getUserCompanyId()),
    },
    {
      label: 'Geolocalização',
      link: `/geolocalizacao`,
      disabled: user?.permissionType !== 'administrator',
    },

    {
      label: 'Agenda',
      link: `${process.env.REACT_APP_LEGADO}/Empresas/Agenda.aspx`,
      isExternal: true,
    },
    {
      label: 'Ocorrências',
      children: [
        {
          label: 'Painel de ocorrências',
          link: `https://reports.leankeep.com/Anomalias/ShowAnomaliasTablePage.aspx`,
          isExternal: true,
        },
        {
          label: 'Lista de Ocorrências',
          link: `https://reports.leankeep.com/Anomalias/ShowListaAnomaliasPage.aspx`,
          isExternal: true,
        },
        {
          label: 'Ocorrências Típicas',
          link: `${process.env.REACT_APP_LEGADO}/Anomalias/ShowAnomaliasTipicasPage.aspx`,
          isExternal: true,
        },
      ],
    },
    {
      label: 'Atividades',
      children: [
        {
          label: 'Planos de Atividades',
          link: '',
        },
        {
          label: 'Planos Gerais',
          link: '/atividades/planos-gerais',
          disabled: user?.permissionType != 'administrador',
        },
        {
          label: 'Planos de Atividades',
          link: 'https://reports.leankeep.com/Atividades/ShowPlanosAtividadesPage.aspx?',
          isExternal: true,
        },
        {
          label: 'Atividades Agendadas',
          link: 'https://reports.leankeep.com/Atividades/ShowPlanosAtividadesPage.aspx?',
          isExternal: true,
        },
        {
          label: 'Atividades por Aplicação',
          link: `${process.env.REACT_APP_LEGADO}/Atividades/ShowAtividadesAplicacaoPage.aspx`,
          isExternal: true,
        },
        {
          label: 'Aplicações por Planos',
          link: 'https://reports.leankeep.com/Atividades/ShowAplicacoesPlanoPage.aspx',
          isExternal: true,
        },
      ],
    },
    {
      label: 'Relatórios',
      link: '/relatorios',
    },
    {
      label: 'Materiais',
      children: [
        {
          label: 'Materiais',
          link: 'https://reports.leankeep.com/Materiais/ShowMateriaisPage.aspx',
          isExternal: true,
        },
        {
          label: 'Solicitações de materiais',
          link: `${process.env.REACT_APP_LEGADO}/https://app.leankeep.com/SolicitacoesMateriais/SolicitacoesMateriaisPage.aspx`,
          isExternal: true,
        },
        {
          label: 'Movimentação',
          link: `${process.env.REACT_APP_LEGADO}/Movimentacoes/MovimentacaoPage.aspx`,
          isExternal: true,
        },
        {
          label: 'Almoxarifado',
          link: `${process.env.REACT_APP_LEGADO}https://app.leankeep.com/Almoxarifados/ShowAlmoxarifadosTablePage.aspx`,
          isExternal: true,
        },
      ],
    },
    {
      label: 'Cadastros',
      children: [
        {
          label: 'Usuários',
          link: `${process.env.REACT_APP_LEGADO}/Usuarios/ShowUsuariosTablePage.aspx`,
          isExternal: true,
        },
        {
          label: 'Empresas',
          link: `${process.env.REACT_APP_LEGADO}/Empresas/ShowEmpresasPage.aspx`,
          isExternal: true,
        },
        {
          label: 'Unidades de manutenção',
          link: `${process.env.REACT_APP_LEGADO}/Sites/ShowSitesTablePage.aspx`,
          isExternal: true,
        },
        {
          label: 'Áreas',
          link: `${process.env.REACT_APP_LEGADO}/Areas/ShowAreasTablePage.aspx`,
          isExternal: true,
        },
        {
          label: 'Sistemas',
          link: `${process.env.REACT_APP_LEGADO}/Sistemas/ShowSistemasEmpresaPage.aspx`,
          isExternal: true,
        },
        ,
        {
          label: 'Equipamentos',
          link: 'https://reports.leankeep.com/Equipamentos/ShowEquipamentosTablePage.aspx',
          isExternal: true,
        },
        {
          label: 'Centro de custos',
          link: `${process.env.REACT_APP_LEGADO}/CentrosCustos/ShowCentrosCustosPage.aspx`,
          isExternal: true,
        },
        ,
        {
          label: 'Meus Fornecedores',
          link: `${process.env.REACT_APP_LEGADO}/Fornecedores/ShowFornecedoresPage.aspx`,
          isExternal: true,
        },
      ],
    },
    {
      label: 'Documentos',
      link: `${process.env.REACT_APP_LEGADO}/Documentos/ShowDocumentosPage.aspx`,
      isExternal: true,
    },
    {
      label: 'Configurações',
      children: [
        {
          label: 'Geral',
          link: `${process.env.REACT_APP_LEGADO}/Configuracoes/ShowConfiguracoesPage.aspx`,
          disabled: user?.permissionType != 'admininistrador',
          isExternal: true,
        },
        {
          label: 'Parâmetros de avisos',
          link: `${process.env.REACT_APP_LEGADO}/Avisos/ShowAvisosParametrosPage.aspx`,
          isExternal: true,
        },
        {
          label: 'Cadastro de SLA',
          link: `${process.env.REACT_APP_LEGADO}/Slas/ShowSlasPage.aspx`,
          isExternal: true,
        },
      ],
    },
  ],
};

export { pages, chooseDash };
