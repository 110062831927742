export const getValidNumberValue = (value?: number | null) => {
  if (!value || value === 0) {
    return null;
  }

  return value;
};

export const getValidStringValue = (value?: number | string | null) => {
  if (!value || value === 0 || value === '') {
    return null;
  }

  return value;
};
