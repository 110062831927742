import { colors } from 'assets/styled/tokens';
import styled from 'styled-components';

export const StyledActivitiesTableFrequencyCell = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 12px;
  line-height: 14px;
`;
export const StyledApplicationType = styled.span`
  font-weight: 400;
  color: ${colors.gray};
`;
export const StyledCellDescription = styled.div`
  color: ${colors.gray};
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  display: inline-block;
`;
