import {
  DRAFT_PLAN_STATUS,
  INACTIVE_PLAN_STATUS,
  SCHEDULED_PLAN_STATUS,
  SCHEDULING_PLAN_STATUS,
} from 'pages/Activities/GeneralPlans/constants';
import React from 'react';
import { StyledScheduledPlanStatusPill, StyledInactivePlanStatusPill, StyledDraftPlanStatusPill } from './styled';
function GeneralPlanStatusCell(props) {
  return <GeneralPlanStatusBadge type={props.cell.value} />;
}
export const GeneralPlanStatusBadge = ({ type }) => {
  switch (type) {
    case INACTIVE_PLAN_STATUS:
      return <StyledInactivePlanStatusPill>Inativo</StyledInactivePlanStatusPill>;
    case SCHEDULED_PLAN_STATUS:
      return <StyledScheduledPlanStatusPill>Agendado</StyledScheduledPlanStatusPill>;
    case SCHEDULING_PLAN_STATUS:
      return <StyledDraftPlanStatusPill>Agendando</StyledDraftPlanStatusPill>;
    case DRAFT_PLAN_STATUS:
      return <StyledDraftPlanStatusPill>Rascunho</StyledDraftPlanStatusPill>;
    default:
      return <></>;
  }
};
export default GeneralPlanStatusCell;
