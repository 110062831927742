import React from 'react';
import { StyledClusterMarker, StyledClusterMarkerCount, StyledClusterMarkerBgSvg } from './styled';
import { Marker } from '@urbica/react-map-gl';

const markerWidth = pointCount => {
  let width = (pointCount?.toString().length - 1) * 16 + 40;
  return pointCount === 10 ? width - 16 : width;
};
const markerHeight = pointCount => {
  let width = (pointCount?.toString().length - 1) * 16 + 48;
  return pointCount === 10 ? width - 16 : width;
};
const offsetTop = pointCount => {
  return (pointCount.toString().length - 1) * 16 + 40;
};
const ClusterMarker = ({ longitude, latitude, pointCount, onClick }) => {
  return (
    <Marker longitude={longitude} latitude={latitude}>
      <StyledClusterMarker offsetTop={offsetTop(pointCount)}>
        <StyledClusterMarkerBgSvg
          width={markerWidth(pointCount)}
          height={markerHeight(pointCount)}
          viewBox="0 0 40 48"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <mask id="path-1-inside-1_1007_1801" fill="white">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M40 20V4C40 1.79086 38.2091 0 36 0H20H4C1.79086 0 0 1.79086 0 4V20V36C0 38.2091 1.79086 40 4 40H10.3043C13.1806 42.7903 16.4884 45.4461 20 48C23.5116 45.4461 26.8194 42.7903 29.6957 40H36C38.2091 40 40 38.2091 40 36V20Z"
            />
          </mask>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M40 20V4C40 1.79086 38.2091 0 36 0H20H4C1.79086 0 0 1.79086 0 4V20V36C0 38.2091 1.79086 40 4 40H10.3043C13.1806 42.7903 16.4884 45.4461 20 48C23.5116 45.4461 26.8194 42.7903 29.6957 40H36C38.2091 40 40 38.2091 40 36V20Z"
            fill="#5144D3"
          />
          <path
            d="M10.3043 40L11.0006 39.2822L10.7097 39H10.3043V40ZM20 48L19.4118 48.8087L20 49.2365L20.5882 48.8087L20 48ZM29.6957 40V39H29.2903L28.9993 39.2822L29.6957 40ZM39 4V20H41V4H39ZM36 1C37.6569 1 39 2.34315 39 4H41C41 1.23858 38.7614 -1 36 -1V1ZM20 1H36V-1H20V1ZM4 1H20V-1H4V1ZM1 4C1 2.34315 2.34315 1 4 1V-1C1.23858 -1 -1 1.23858 -1 4H1ZM1 20V4H-1V20H1ZM1 36V20H-1V36H1ZM4 39C2.34315 39 1 37.6569 1 36H-1C-1 38.7614 1.23858 41 4 41V39ZM10.3043 39H4V41H10.3043V39ZM9.60805 40.7178C12.5295 43.5519 15.8774 46.2383 19.4118 48.8087L20.5882 47.1913C17.0993 44.6539 13.8317 42.0287 11.0006 39.2822L9.60805 40.7178ZM20.5882 48.8087C24.1226 46.2383 27.4705 43.5519 30.392 40.7178L28.9993 39.2822C26.1683 42.0287 22.9007 44.6539 19.4118 47.1913L20.5882 48.8087ZM36 39H29.6957V41H36V39ZM39 36C39 37.6569 37.6569 39 36 39V41C38.7614 41 41 38.7614 41 36H39ZM39 20V36H41V20H39Z"
            fill="black"
            fillOpacity="0.4"
            mask="url(#path-1-inside-1_1007_1801)"
          />
        </StyledClusterMarkerBgSvg>
        <StyledClusterMarkerCount width={markerWidth(pointCount)} height={markerHeight(pointCount)} onClick={onClick}>
          {pointCount}
        </StyledClusterMarkerCount>
      </StyledClusterMarker>
    </Marker>
  );
};
export default ClusterMarker;
