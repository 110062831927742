import styled from 'styled-components';

export const StyledInfoLink = styled.a`
  display: flex;
  align-items: center;
  color: #3a97d4;
  height: 16px;
  margin-bottom: 8px;
  font-size: 12px;
  svg {
    fill: #3a97d4;
  }
  span {
    margin: 0 6px;
  }
`;
