import styled from 'styled-components';

export const StyledTechnicianAvatar = styled.div`
  margin-bottom: 15px;
  img {
    border-radius: 100%;
    overflow: hidden;
  }
  z-index: ${({ zIndex }) => zIndex};
`;

export const StyledAvatarImageContainer = styled.div`
  position: relative;
  width: 32px;
  height: 32px;
  border-radius: 100%;
  overflow: hidden;
  img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

export const StyledTechnicianAvatarImage = styled.img`
  width: 40px;
  height: auto;
`;
