/* istanbul ignore file */

import styled from 'styled-components';
import { fonts, colors, medias } from 'assets/styled/tokens';
import { FormGroup } from 'components/Form/subcomponents/FormGroup';

export const StyledWelcome = styled.div`
  width: 100%;
  margin-bottom: 1.56rem;

  h3 {
    font-weight: 500;
    font-size: 20px;
    line-height: 20px;
    margin-bottom: 9px;
  }

  span {
    font-size: 14px;
    line-height: 20px;
  }
`;

export const StyledForm = styled.form`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  max-width: 410px;
  width: 100%;
  height: 100%;
  margin: 0 auto;

  img {
    display: none;
    max-width: 15rem;
    margin: 0 auto 3.12rem 0;
  }

  button {
    margin-top: 18px;
    width: 100%;
    transition: all 0.4s ease-in-out;

    &:hover {
      color: ${colors.tundora};
      background-color: ${colors.caramel};
    }
  }

  p {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: ${colors.gray};

    a {
      color: ${colors.primary};
      text-decoration-line: underline;
    }
  }

  @media screen and (max-width: ${medias.desktopSm}) {
    padding: 0 43px;
    img {
      display: initial;
    }
  }
`;
export const StyledFormGroup = styled(FormGroup)`
  margin-bottom: 16px;
`;
export const StyledFormLogin = styled.div`
  display: block;
  width: 100%;
  min-width: 295px;

  @media screen and (min-width: ${medias.tablet}) {
    input {
      width: 408px;
    }
  }
`;

export const StyledFormFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 30px;

  button {
    max-width: 30%;
  }
`;

export const StyledError = styled.div`
  display: block;
  font-size: 8px;
  margin-top: 6px;

  p {
    font-family: ${fonts.family.text};
    color: ${colors.cinnabar};
  }
`;

export const StyledModalBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  padding-bottom: 21px;

  img {
    max-width: 150px;
    margin-bottom: 32px;
  }

  h6 {
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    margin-bottom: 32px;
  }

  p,
  span {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    margin-bottom: 32px;
    text-align: center;

    a {
      color: ${colors.primary};
      font-weight: bold;
    }
  }

  button {
    padding: 7px 32px;
  }

  @media screen and (min-width: ${medias.tablet}) {
    padding-right: 35px;
    padding-left: 35px;
  }
`;
